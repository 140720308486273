import { Auth } from "@/common/utils/Auth";
import {
  Textfield,
  Request,
  MultiSelect,
  Time,
  Date as FormDate,
  Checkbox,
  Selection
} from "@/forms/ViewModelFormTypes";
import { WorkOrder } from "../services/WorkOrderService";

export type FormType =
  | Textfield
  | MultiSelect
  | Checkbox
  | FormDate
  | Selection;

export interface Section {
  name: string;
  fields: { [key: string]: Field & FormType };
}

export interface Field {
  suffix: string;
  prefix: string;
  component: string;
  multiple: boolean;
  name: string;
}

export class CreateWorkOrderViewModel {
  public workOrder?: WorkOrder = undefined;
  public goBack: boolean = false;
  public activeTabIndex = 0;
  public createdWorkOrder: string = "";
  public showSetToCheckDialog: boolean = false;
  public showRejectionReasonDialog: boolean = false;
  public plantRemarks: string = "";
  public workOrderPrice: number | null = null;
  public plantContract: any;
  public workOrderRemarks: string = "";

  public generalSection: { [key: string]: Field & Textfield } = {};
  public sections: Section[] = [];
  public parametersSection: { [key: string]: Field & Textfield } = {};

  public tabs = {
    headers: ["Mängel", "Arbeitsdaten", "Kundenunterschrift"],
    components: ["DefectsTableTab", "GeneralTab", "SignatureTab"]
  };

  public plantSet = false;
  public plantId: string = "";
  public plantType: string = "";
  public plantIndex: string = "";
  public flatOperators: any[] = [];

  public priceHkEditable = false;
  public workOrderId: string = "";
  public workOrderHK: number = 0;
  public workOrderProcessingState: string = "OPEN";
  public estimatedTime: number = 0;

  public rejectionReason = "";

  public getWorkOrderLoading = true;
  public getWorkOrderError = "";

  public error = "";
  public loading: boolean = true;

  public formStep: number = 1;
  public generalDataStepCompleted: boolean = false;
  public defectsDataStepCompleted: boolean = false;

  public openDefectsDataStepButtonDisabled: boolean = true;
  public openGeneralDataStepButtonDisabled: boolean = true;
  public openSketchDataStepButtonDisabled: boolean = true;
  public createWorkOrderButtonDisabled: boolean = true;
  public allValid = !(
    this.openDefectsDataStepButtonDisabled ||
    this.openGeneralDataStepButtonDisabled ||
    this.openSketchDataStepButtonDisabled ||
    this.createWorkOrderButtonDisabled
  );

  public createWorkOrderRequest: Request = { loading: false, error: "" };

  public usedTime: Time = { value: 0, error: "", text: "" };
  public doneTasks: MultiSelect = {
    selected: [],
    error: "",
    items: []
  };
  public usedMaterial: MultiSelect = {
    selected: [],
    error: "",
    items: []
  };

  public defects: any[] = [];
  public workOrderDate: Date = new Date();
  public workOrderNoPriceTab = false;

  public cannotTakeSample: Checkbox = { error: "", value: false };
  public substituteDate: FormDate = {
    error: "",
    value: "",
    text: "",
    label: "Datum für neuen Termin"
  };

  public sampleLocation: Selection = { error: "", items: [], selected: "" };
  public sampleTimeFrom: Time = { error: "", value: "", text: "" };
  public sampleTime = "";
  public sampleWeather: Textfield = { error: "", value: "" };
  public sampleTemperature: Textfield = { error: "", value: "" };
  public sampleIntakeEnabled: Checkbox = { error: "", value: false };
  public sampleIntake: Selection = { error: "", items: [], selected: "Kein" };
  public sampleTaker = "";

  public plantStatus: Selection = {
    error: "",
    selected: "",
    items: [
      {
        text: "In Ordnung",
        value: "ok"
      },
      {
        text: "Nicht in Ordnung",
        value: "notOk"
      }
    ]
  };

  public suggestedActions: MultiSelect = {
    selected: [],
    error: "",
    items: []
  };

  public cannotTakeSignature: Checkbox = { error: "", value: false };
  public signature: Textfield = { value: "", error: "" };

  public get showSampleTab() {
    return this.workOrder?.sampleNeeded;
  }

  public get showPriceTab() {
    return Auth.hasPermission("approveWorkOrders") && !this.workOrderNoPriceTab;
  }
}
