import { TableHeader, Selection } from "@/forms/ViewModelFormTypes";

export class DefectsTableViewModel {
  // Data
  public defects: DefectData[] = [];
  public newDefect: Selection = { items: [], selected: "", error: "" };
  public oldComments = [];
  // Table Headers
  public defectsHeaders: TableHeader[] = [
    {
      text: "Mängel",
      value: "message"
    },
    {
      text: "Anmerkung?",
      value: "isComment",
      align: "right",
      width: "70px"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "right",
      width: "70px"
    }
  ];
  public defectsTabletHeaders: TableHeader[] = [
    {
      text: "Mängel",
      value: "message"
    },
    {
      text: "Anmerkung?",
      value: "isComment",
      align: "right",
      width: "70px"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "right",
      width: "70px"
    }
  ];
  public defectsMobileHeaders: TableHeader[] = [
    {
      text: "Mängel",
      value: "message"
    },
    {
      text: "Anmerkung?",
      value: "isComment",
      align: "right",
      width: "70px"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "right",
      width: "70px"
    }
  ];

  public commentsHeaders: TableHeader[] = [
    {
      text: "Bisherige Anmerkungen",
      value: "message"
    }
  ];
  public commentsTabletHeaders: TableHeader[] = [
    {
      text: "Bisherige Anmerkungen",
      value: "message"
    }
  ];
  public commentsMobileHeaders: TableHeader[] = [
    {
      text: "Bisherige Anmerkungen",
      value: "message"
    }
  ];
}

export interface DefectData {
  id: number;
  message: string;
  isComment: boolean;
}
