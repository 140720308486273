import { IUserRoleDetailPresenter } from "../controllers/UserRoleDetailController";
import { UserRoleDetailViewModel } from "../vms/UserRoleDetailViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { PermissionList, Permission } from "../services/PermissionService";
import { ArrayUtils } from "@/utils/ArrayUtils";
import { UserRole } from "../services/UserRoleService";
import { UserRoleForm } from "../forms/UserRoleForm";

export class UserRoleDetailPresenter implements IUserRoleDetailPresenter {
  public form: UserRoleForm;

  public constructor(private vm: UserRoleDetailViewModel) {
    this.initStaticTexts();
    this.updateDynamicTexts();

    this.form = new UserRoleForm(this.vm, this.formValidated);
    this.form.init();
  }

  public set loadAllPermissionsResponse(
    response: FormResponse<PermissionList>
  ) {
    this.vm.loadPageRequest = response;

    if (response.success) {
      this.processPermissionData(response.data);
    }

    this.updateDynamicTexts();
  }

  public set loadUserRoleResponse(response: FormResponse<UserRole>) {
    this.vm.loadPageRequest = response;

    if (response.success) {
      this.initUserRole(response.data);
      this.vm.deleteRoleButtonVisible = true;
    }

    this.updateDynamicTexts();
  }

  public set saveUserRoleResponse(response: FormResponse<string>) {
    this.vm.saveRoleRequest = response;

    if (response.success) {
      this.vm.savedRoleId = response.data;
    }
  }

  public set deleteUserRoleResponse(response: FormResponse<string>) {
    this.vm.deleteRoleRequest = response;

    if (response.success) {
      this.vm.deletedRoleId = response.data;
    }
  }

  // Data
  public get userRoleId(): string {
    return this.vm.userRoleId;
  }

  public get userRole() {
    return {
      id: this.userRoleId,
      name: this.vm.name.value,
      permissionIds: this.vm.permissionGroups
        .map(group => group.selected)
        .flat(1)
    };
  }

  // Input
  public permissionsChanged(permissions: string[], index: number) {
    if (index < this.vm.permissionGroups.length) {
      this.vm.permissionGroups[index].selected = permissions;
    }
  }

  private initStaticTexts() {
    this.vm.name.label = "Name";
    this.vm.deleteRoleButtonText = "Rolle löschen";
    this.vm.deleteRoleConfirmTitle = "Rolle wirklich löschen?";
    this.vm.deleteRoleConfirmText =
      "Keine Angst, Sie können die Rolle nur löschen, wenn sie keinem Mitarbeiter mehr zugeordnet ist.";
  }

  private updateDynamicTexts() {
    if (!!this.vm.userRoleId) {
      this.vm.title = "Rolle bearbeiten";
      this.vm.saveRoleButtonText = "Rolle speichern";
    } else {
      this.vm.title = "Rolle erstellen";
      this.vm.saveRoleButtonText = "Rolle erstellen";
    }
  }

  private processPermissionData(permissions: PermissionList) {
    const groupedPermissions = ArrayUtils.groupBy(
      permissions.permissions,
      "domain"
    );

    this.vm.permissionGroups = Object.entries(groupedPermissions).map(
      ([domain, perms]) => ({
        label: domain,
        error: "",
        loading: false,
        selected: [],
        items: (perms as Permission[]).map((perm: any) => ({
          value: perm.id,
          text: perm.label
        }))
      })
    );
  }

  private formValidated(context: any, valid: boolean) {
    context.saveRoleButtonDisabled = !valid;
  }

  private initUserRole(role: UserRole) {
    this.vm.userRoleId = role.id;
    this.form.setFieldValue("name", role.name);

    role.permissions.forEach(perm =>
      this.selectPermission(perm.domain, perm.id)
    );
  }

  private selectPermission(domain: string, id: string) {
    const permGroup = this.vm.permissionGroups.find(
      group => group.label === domain
    );

    if (!!permGroup) {
      permGroup.selected.push(id);
    }
  }
}
