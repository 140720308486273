




















































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Textfield from "@/components/form/Textfield.vue";
import IconButton from "@/components/basic/IconButton.vue";
import Datepicker from "@/components/form/DatePicker.vue";

import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { PlantGraphQLService } from "@/plant/services/PlantGraphQLService";
import { Plant } from "@/plant/services/PlantService";
import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { DateUtils } from "../../../utils/DateUtils";

import { Textfield as TextField } from "@/forms/ViewModelFormTypes";
import { Date as CustomDate } from "@/forms/ViewModelFormTypes";
import { Auth } from "../../../common/utils/Auth";
import StorageKeys from "../../../common/utils/StorageKeys";

@Component<PlantProtocolData>({
  components: {
    Card,
    Grid,
    Row,
    Column,
    Textfield,
    IconButton,
    Datepicker
  },
  props: ["plant", "plantRequest", "element"]
})
export default class PlantProtocolData extends Vue {
  public protocol: TextField = {
    label: "Protokoll",
    value: "",
    loading: false,
    error: ""
  };

  public protocoldate: CustomDate = {
    label: "Protokoll Datum",
    value: DateUtils.dateOnly(new Date()),
    text: DateUtils.formatDate(new Date()),
    loading: false,
    error: ""
  };

  private plant!: Plant;
  private editMode = false;
  private loading = false;

  private connection = new AxiosGraphQLConnection(
    new AxiosLogger(new ConsoleLogger())
  );
  private plantService = new PlantGraphQLService(this.connection);

  public updateDate(event: any) {
    this.protocoldate.value = event;
    this.protocoldate.text = DateUtils.formatDate(event);
  }

  private retry(event: any) {
    this.$emit("retry", event);
  }

  private changeEditMode() {
    this.editMode = !this.editMode;
    if (!this.editMode) {
      this.updatePlantProtocol();
    }
  }

  private updatePlantProtocol() {
    if (!!this.protocol.value) {
      this.loading = true;

      this.plant.protocol = `${this.plant.protocol}\n${this.protocoldate.text} ${this.protocol.value}`;

      const request = this.plantService.updatePlantProtocol(
        this.plant.id,
        this.protocol.value,
        this.protocoldate.text
      );

      this.protocol.value = "";

      FormRequestHandler.handle(
        request,
        response => {
          if (!response.loading && !response.error) {
            this.retry(this.plant);
            this.loading = false;
            this.editMode = false;
          }
        },
        "update-plant-failed"
      );
    }
  }

  private canEdit() {
    return Auth.hasPermission(StorageKeys.editPlantAndOperatorPermission);
  }
}
