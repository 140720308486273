import { DashboardViewModel } from "../vms/DashboardViewModel";
import { IDashboardPresenter } from "../controllers/DashboardController";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { InitPresenter } from "./InitPresenter";

export class DashboardPresenter extends InitPresenter<DashboardViewModel>
  implements IDashboardPresenter {
  public constructor(vm: DashboardViewModel, storage: SimpleStringStorage) {
    super(vm, storage);
    this.initStaticTexts();
  }

  public set reload(reload: boolean) {
    this.vm.reload = reload;
  }

  private initStaticTexts() {
    this.vm.title = "Dashboard";
  }
}
