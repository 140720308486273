



















































































































































































































import Vue from "vue";
import Component from "vue-class-component";

import VpiCalculationForm, {
  VpiCalculationFormData
} from "./VpiCalculationForm.vue";

import InfoDialog from "@/components/basic/InfoDialog.vue";

import Page from "@/components/layout/Page.vue";
import Container from "@/components/layout/Container.vue";
import Section from "@/components/layout/Section.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import Datatable from "@/components/collection/Datatable.vue";

import Textfield from "@/components/form/Textfield.vue";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { PlantGraphQLService } from "@/plant/services/PlantGraphQLService";
import { Numberfield, TableHeader } from "@/forms/ViewModelFormTypes";
import { Plant, Plants, VpiCalculation } from "@/plant/models/Plants";
import { VpiStorageHandler } from "@/storage/storageHandlers/VpiStorageHandler";
import { Dictionary } from "@/datastructures/Dictionary";

import { VpiCorrectionForm as Form } from "./VpiCorrectionForm";

import * as ExcelJS from "exceljs";
import { StringUtils } from "@/utils/StringUtils";

@Component<VpiCalculationView>({
  components: {
    VpiCalculationForm,
    InfoDialog,
    Page,
    Container,
    Section,
    Grid,
    Row,
    Column,
    Datatable,
    Textfield
  }
})
export default class VpiCalculationView extends Vue {
  protected service = new PlantGraphQLService(
    new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
  );

  protected plants: Plants = new Plants();
  protected input: VpiCalculationFormData = {};
  protected submitted = false;
  protected loading = false;
  protected calculations: Dictionary<VpiCalculation> = {};
  protected editedCalculations: Dictionary<VpiCalculation> = {};
  protected acceptedPlants: string[] = [];
  protected editedPlant: Plant = new Plant();
  protected focusField: string = "";
  protected form = new Form(this, this.onInput);
  protected tabPressed = false;
  protected errorMessage = "";

  protected headers: TableHeader[] = [
    {
      text: "Anlage",
      value: "index",
      width: "0"
    },
    {
      text: "Anlagenstandort",
      value: "location"
    },
    {
      text: "Wartung",
      value: "contractPrice",
      width: "0",
      align: "center"
    },
    {
      text: "Probe",
      value: "samplePrice",
      width: "0",
      align: "center"
    },
    {
      text: "Basisindex",
      value: "baseIndex",
      width: "0",
      align: "center"
    },
    {
      text: "Startindex",
      value: "startIndex",
      width: "0",
      align: "center"
    },
    {
      text: "Index",
      value: "indexChange",
      width: "0",
      align: "center"
    },
    {
      text: "Erhöhung",
      value: "increasePercent",
      width: "0",
      align: "center"
    },
    {
      text: "",
      value: "actions",
      width: "0",
      align: "center"
    }
  ];

  protected newContractCost: Numberfield = {
    label: "Wartung (neu)",
    value: 0,
    error: ""
  };

  protected newSampleCost: Numberfield = {
    label: "Probe (neu)",
    value: 0,
    error: ""
  };

  protected startIndex: Numberfield = {
    label: "Startindex",
    value: 0,
    error: ""
  };

  protected get filteredPlants() {
    return this.plants.get({
      addressCountry: this.input.country,
      addressState: this.input.state
    });
  }

  protected get loaded() {
    return this.submitted && !this.loading;
  }

  protected hasChange(id: string, field?: keyof VpiCalculation): boolean {
    if (this.isAccepted(id)) {
      return false;
    }

    if (!field) {
      return (
        this.hasChange(id, "newContractCost") ||
        this.hasChange(id, "newSampleCost") ||
        this.hasChange(id, "startIndex")
      );
    }

    return (
      this.editedCalculations[id] &&
      this.editedCalculations[id][field] !== this.calculations[id][field]
    );
  }

  protected getCalculation(id: string): VpiCalculation | undefined {
    return this.editedCalculations[id] ?? this.calculations[id];
  }

  protected isAccepted(id: string): boolean {
    return this.acceptedPlants.includes(id);
  }

  protected async load(data: VpiCalculationFormData) {
    const reload =
      this.input.threshold !== data.threshold ||
      this.input.baseIndex !== data.baseIndex ||
      this.input.targetIndex !== data.targetIndex;

    this.submitted = true;

    if (reload && data.baseIndex && data.threshold && data.targetIndex) {
      this.loading = true;

      this.plants = await this.service.getPlantsForVpiCalculation(
        data.baseIndex,
        data.threshold,
        data.targetIndex
      );

      this.calculations = {};
      this.editedCalculations = {};
      this.acceptedPlants = [];

      for (const plant of this.plants.get()) {
        this.calculations[plant.id] = plant.plantContract.calculateNewIndex(
          data.targetIndex ?? 0
        );
      }

      this.loading = false;
    }

    this.input = data;
  }

  protected resolveVpi(vpi: string): string {
    return VpiStorageHandler.getVpiLabel(vpi);
  }

  protected editRow(plant: Plant, field: string) {
    if (this.editedPlant !== plant && !this.isAccepted(plant.id)) {
      this.editedPlant = plant;

      this.form.setFieldValue(
        "newContractCost",
        this.getCalculation(plant.id)?.newContractCost?.toString() ?? "0"
      );
      this.form.setFieldValue(
        "newSampleCost",
        this.getCalculation(plant.id)?.newSampleCost?.toString() ?? "0"
      );
      this.form.setFieldValue(
        "startIndex",
        this.getCalculation(plant.id)?.startIndex?.toString() ?? "0"
      );

      this.focusField = field;
    }
  }

  protected changeField(fieldName: string, value: string) {
    this.form.setFieldValue(fieldName, value);

    const currentCalculation = this.getCalculation(this.editedPlant.id);

    if (currentCalculation) {
      Vue.set(
        this.editedCalculations,
        this.editedPlant.id,
        currentCalculation.applyValues({
          newContractCost: this.newContractCost.value,
          newSampleCost: this.newSampleCost.value,
          startIndex: this.startIndex.value
        })
      );
    }
  }

  protected async processVpi(plant: Plant) {
    const calculation = this.getCalculation(plant.id);

    if (calculation) {
      try {
        if (!this.isAccepted(plant.id)) {
          await this.service.updateContractPrices(plant.id, calculation);

          this.acceptedPlants.push(plant.id);

          if (plant === this.editedPlant) {
            this.editNextPlant();
          }
        } else {
          await this.service.updateContractPrices(
            plant.id,
            plant.plantContract.calculateNewIndex(
              plant.plantContract.currentIndex
            )
          );

          this.acceptedPlants = this.acceptedPlants.filter(
            id => plant.id !== id
          );
        }
      } catch (e) {
        this.errorMessage = StringUtils.unpackErrorMessage(e);
      }
    }
  }

  protected undo(plant: Plant) {
    Vue.delete(this.editedCalculations, plant.id);

    if (plant === this.editedPlant) {
      this.clearEdit();
    }
  }

  protected remove(plant: Plant) {
    this.plants.remove(plant);

    Vue.delete(this.calculations, plant.id);
    Vue.delete(this.editedCalculations, plant.id);
    this.acceptedPlants = this.acceptedPlants.filter(id => plant.id !== id);
  }

  protected clearEdit() {
    this.editedPlant = new Plant();
    this.focusField = "";
  }

  protected goToPlant(plant: Plant) {
    const routeData = this.$router.resolve({
      name: "plant",
      params: { plantId: plant.id }
    });
    window.open(routeData.href, "_blank");
  }

  protected goToPlantContract(plant: Plant) {
    const routeData = this.$router.resolve({
      name: "plant-contract",
      params: { plantId: plant.id }
    });
    window.open(routeData.href, "_blank");
  }

  protected created() {
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
  }

  protected destroyed() {
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
  }

  protected onKeyDown(e: KeyboardEvent) {
    this.tabPressed = e.code === "Tab";
  }

  protected onKeyUp(e: KeyboardEvent) {
    this.tabPressed = false;
  }

  protected lostFocusOfLastField() {
    if (this.tabPressed) {
      this.tabPressed = false;

      this.editNextPlant();
    }
  }

  protected editNextPlant() {
    const currentPlantIndex = this.filteredPlants.indexOf(this.editedPlant);

    if (currentPlantIndex >= 0) {
      let nextPlantIndex = currentPlantIndex + 1;
      let nextPlant: Plant | undefined;

      do {
        nextPlant =
          nextPlantIndex < this.filteredPlants.length
            ? this.filteredPlants[nextPlantIndex]
            : undefined;

        nextPlantIndex++;
      } while (nextPlant !== undefined && this.isAccepted(nextPlant.id));

      if (nextPlant) {
        this.editRow(nextPlant, "newContractCost");
      } else {
        this.clearEdit();
      }
    }
  }

  protected styleRow(plant: Plant) {
    if (this.acceptedPlants.includes(plant.id)) {
      return "blue-grey lighten-3";
    }
  }

  protected async exportExcel() {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Indexberechnung");

    sheet.getColumn(1).width = 10 * 1.1;
    sheet.getColumn(2).width = 61.5 * 1.1;
    sheet.getColumn(3).width = 13.5 * 1.1;
    sheet.getColumn(4).width = 13.5 * 1.1;
    sheet.getColumn(5).width = 13.5 * 1.1;
    sheet.getColumn(6).width = 13.5 * 1.1;
    sheet.getColumn(7).width = 13.5 * 1.1;
    sheet.getColumn(8).width = 13.5 * 1.1;

    sheet.getColumn(1).alignment = { vertical: "top" };
    sheet.getColumn(2).alignment = { vertical: "top", wrapText: true };
    sheet.getColumn(3).alignment = { vertical: "top" };
    sheet.getColumn(4).alignment = { vertical: "top" };
    sheet.getColumn(5).alignment = { vertical: "top", horizontal: "center" };
    sheet.getColumn(6).alignment = { vertical: "top" };
    sheet.getColumn(7).alignment = { vertical: "top" };
    sheet.getColumn(8).alignment = { vertical: "top", horizontal: "right" };

    const headerRow = sheet.getRow(1);
    headerRow.values = [
      "Anlage",
      "Anlagenstandort",
      "Wartung",
      "Probe",
      "Basisindex",
      "Aktueller Index",
      "Neuer Index",
      "Erhöhung"
    ];
    headerRow.getCell(3).style = { alignment: { horizontal: "center" } };
    headerRow.getCell(4).style = { alignment: { horizontal: "center" } };
    headerRow.getCell(5).style = { alignment: { horizontal: "center" } };
    headerRow.getCell(6).style = { alignment: { horizontal: "center" } };
    headerRow.getCell(7).style = { alignment: { horizontal: "center" } };
    headerRow.getCell(8).style = { alignment: { horizontal: "center" } };
    headerRow.font = { size: 12, bold: true };

    let rowIndex = 2;
    for (const plant of this.filteredPlants.filter(
      p => !this.isAccepted(p.id)
    )) {
      const row = sheet.getRow(rowIndex);

      row.values = [
        plant.index,
        `${plant.mainOperator?.label}\n${plant.address}`,
        plant.plantContract.maintenanceCost,
        plant.plantContract.priceSample,
        this.resolveVpi(plant.plantContract.baseIndex),
        plant.plantContract.currentIndex,
        this.input.targetIndex,
        `${this.getCalculation(plant.id)?.increasePercent} %`
      ];

      row.height = 40;

      rowIndex++;
    }

    workbook.xlsx.writeBuffer({}).then(xls64 => {
      // build anchor tag and attach file (works in chrome)
      const a = document.createElement("a");
      const data = new Blob([xls64], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });

      const url = URL.createObjectURL(data);
      a.href = url;
      a.download = "export.xlsx";
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    });
  }

  protected onInput(_: VpiCalculationView, __: boolean) {
    // Not used
  }
}
