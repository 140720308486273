import { GraphQLConnection } from "@/gateways/graphql/GraphQLConnection";

export interface LoginPayload {
  userId: string;
  validUntil: Date;
  permissions: string[];
  token: string;
}

export interface AuthenticationService {
  login(username: string, password: string): Promise<LoginPayload>;
}

export class AuthenticationGraphQLService implements AuthenticationService {
  public constructor(private connection: GraphQLConnection) {}

  public async login(
    username: string,
    password: string
  ): Promise<LoginPayload> {
    const result = await this.connection.query(
      "login",
      { username, password },
      ["userId", "validUntil", "permissions", "token"]
    );

    return {
      userId: result.data.userId,
      validUntil: new Date(result.data.validUntil),
      permissions: result.data.permissions,
      token: result.data.token
    };
  }
}
