



































import Vue from "vue";

export default Vue.extend({
  name: "SignaturePad",
  methods: {
    onEnd() {
      this.$emit("drawEnd", (this.$refs.signaturePad as any).saveSignature());
    },
    undo() {
      (this.$refs.signaturePad as any).undoSignature();
      this.$emit("drawEnd", (this.$refs.signaturePad as any).saveSignature());
    },
    clear() {
      (this.$refs.signaturePad as any).clearSignature();
      this.$emit("drawEnd", (this.$refs.signaturePad as any).saveSignature());
    },
    init() {
      const signaturePad = this.$refs.signaturePad as any;
      if (this.$props.signature) {
        signaturePad.fromDataURL(this.$props.signature);
      }
      if (this.disabled) {
        signaturePad.lockSignaturePad();
      }
    }
  },
  props: {
    signature: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: "Bitte hier unterschreiben:"
    },
    width: {
      type: String,
      default: undefined
    }
  },
  watch: {
    signature(signature: string) {
      this.$nextTick(() => {
        this.init();
        (this.$refs.signaturePad as any).resizeCanvas();
      });
    }
  },
  mounted() {
    this.init();
  }
});
