export default {
  settingsTables: "settings-tables",
  plantDataFilters: "plant-data-filters",
  userId: "user-id",
  permissions: "permissions",
  token: "token",
  validUntil: "valid-until",
  lastChangeTime: "last-change-time",

  editTourPlanPermission: "editTourPlan",
  approveTourPlanPermission: "approveTourPlan",
  approveWorkOrdersPermission: "approveWorkOrders",
  editPlantAndOperatorPermission: "editPlantAndOperator"
};
