import { GraphQLConnection } from "@/gateways/graphql/GraphQLConnection";
import { DateUtils } from "@/utils/DateUtils";

export class TimeTrackingDayGraphQLService implements TimeTrackingDayService {
  public constructor(private connection: GraphQLConnection) {}

  public async getFilteredDays(
    employeeId: string,
    dateFrom: string,
    dateTo: string
  ): Promise<TimeTrackingDay[]> {
    // const startDate = new Date(dateFrom);
    // const endDate = new Date(dateTo);
    const dates: string[] = [];
    dates.push(dateFrom);
    dates.push(dateTo);

    // while (startDate < endDate) {
    //   dates.push(DateUtils.toISOString(startDate));
    //   startDate.setDate(startDate.getDate() + 1);
    // }

    const result = await this.connection.query(
      "getFilteredDays",
      { employeeId, dates },
      [
        "id",
        "date",
        "startTime",
        "endTime",
        "breakDuration",
        "employeeId",
        {
          name: "entries",
          fields: [
            "maintenanceDriveDuration",
            "maintenanceWorkDuration",
            "maintenanceBonus",
            "maintenanceRegiments",
            "othersDriveDuration",
            "othersWorkDuration",
            "othersBonus",
            "othersRegiments",
            "plantIndex",
            "operator",
            "doneTasks"
          ]
        }
      ]
    );

    return result.data.map((day: any) => this.parseDay(day));
  }

  public async upsertTimeTrackingDay(props: any): Promise<string> {
    const result = await this.connection.mutation(
      "upsertTimeTrackingDay",
      {
        input: props
      },
      []
    );

    return result.data;
  }

  public async saveXLS(
    employeeId: string,
    filterDateFrom: string,
    filterDateTo: string
  ) {
    const result = await this.connection.query(
      "saveTimeTrackingAsXLS",
      {
        input: {
          employeeId,
          filterDateFrom,
          filterDateTo
        }
      },
      []
    );
    return result.data;
  }

  private parseDay(rawDay: any) {
    const timeTrackingDay: TimeTrackingDay = rawDay;
    timeTrackingDay.date = DateUtils.getDate(rawDay.date);
    return timeTrackingDay;
  }
}

export interface TimeTrackingDayService {
  getFilteredDays(
    employeeId: string,
    dateFrom: string,
    dateTo: string
  ): Promise<TimeTrackingDay[]>;
  upsertTimeTrackingDay(props: any): Promise<string>;
  saveXLS(
    employeeId: string,
    filterDateFrom: string,
    filterDateTo: string
  ): Promise<any>;
}

export interface TimeTrackingDay {
  id?: string;
  date: Date;
  startTime?: string;
  endTime?: string;
  breakDuration?: string;
  employeeId?: string;
  entries?: TimeTrackingDayEntry[];
}

export interface TimeTrackingDayEntry {
  maintenanceDriveDuration?: number;
  maintenanceWorkDuration?: number;
  maintenanceBonus?: number;
  maintenanceRegiments?: number;
  othersDriveDuration?: number;
  othersWorkDuration?: number;
  othersBonus?: number;
  othersRegiments?: number;
  plantIndex?: number;
  operator?: string;
  doneTasks?: string;
}
