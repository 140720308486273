import { PaginatedList } from "@/datastructures/PaginatedList";
import { FormResponse } from "@/forms/FormResponse";
import { IStoreItemCardPresenter } from "../controllers/StoreItemCardController";
import { StoreItem } from "../services/StoreItemGraphQLService";
import { StoreItemCardViewModel } from "../vms/StoreItemCardViewModel";

export class StoreItemCardPresenter implements IStoreItemCardPresenter {
  public constructor(private vm: StoreItemCardViewModel) {}

  public set tableRequest(response: FormResponse<PaginatedList<any>>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.items = response.data.items;
      this.vm.totalItems = response.data.totalCount;
    }
  }

  public get pageNumber() {
    return this.vm.pageNumber;
  }

  public set pageNumber(pageNumber: number) {
    this.vm.pageNumber = pageNumber;
  }

  public get itemsPerPage() {
    return this.vm.itemsPerPage;
  }

  public get options() {
    return this.vm.options;
  }

  public set options(options: any) {
    this.vm.options = options;
  }
}
