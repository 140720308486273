import { LocalStorage } from "@/storage/LocalStorage";

export class TourWeekStorage {
  public static save(tourweek: any) {
    this.localStorage.set("Tourenwoche", JSON.stringify(tourweek));
  }

  public static load(): any {
    if (this.localStorage.has("Tourenwoche")) {
      return JSON.parse(this.localStorage.get("Tourenwoche"));
    }
  }

  private static localStorage = new LocalStorage();
}
