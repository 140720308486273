import SystemKeys from "@/common/utils/SystemKeys";
import { StorageHandler } from "./StorageHandler";

export class TimerStorageHandler extends StorageHandler {
  public static getTimers() {
    if (!this.storage.has(SystemKeys.timerTableKey)) {
      this.storage.set(SystemKeys.timerTableKey, JSON.stringify({}));
    }
    return JSON.parse(this.storage.get(SystemKeys.timerTableKey));
  }

  public static start(workOrderId: string) {
    const timers = this.getTimers();
    timers[workOrderId] = Date.now().toString();
    this.storage.set(SystemKeys.timerTableKey, JSON.stringify(timers));
  }

  public static stop(workOrderId: string) {
    const timers = this.getTimers();
    delete timers[workOrderId];
    this.storage.set(SystemKeys.timerTableKey, JSON.stringify(timers));
  }

  public static getValue(workOrderId: string) {
    const timers = this.getTimers();
    return parseInt(timers[workOrderId], 10);
  }
}
