










































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationProvider } from "vee-validate";

import { StringUtils } from "@/utils/StringUtils";
import { DateUtils } from "@/utils/DateUtils";

@Component<ValidatedTimepicker>({
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: Object
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    icon: {
      type: String,
      default: "access_time"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    allowedMinutes: {
      type: Array,
      default() {
        return undefined;
      }
    },
    allowedHours: {
      type: Array,
      default() {
        return undefined;
      }
    },
    textFieldStyle: {
      type: Object,
      default: undefined
    },
    format: {
      type: String,
      default: "24hr"
    }
  },
  watch: {
    value(newVal) {
      this.parseInputTime(newVal);
    },
    innerValue(newVal) {
      this.$emit("input", {
        text: newVal,
        value: DateUtils.timeToNumber(newVal)
      });
    }
  }
})
export default class ValidatedTimepicker extends Vue {
  private expanded = false;
  private innerValue = "";

  private created() {
    if (this.$props.value) {
      this.parseInputTime(this.$props.value);
    }
  }

  private get timePickerFormat() {
    return this.$props.format === "12hr" ? "ampm" : this.$props.format;
  }

  private get timePickerStyle() {
    return {
      "--button-display": this.$props.format === "12hr" ? "none" : "block"
    };
  }

  private parseInputTime(input: { value: string; text: string }) {
    this.innerValue = input.text;
  }

  private chooseTime() {
    if (this.innerValue) {
      this.expanded = false;
    }
  }
}
