import { FormResponse } from "@/forms/FormResponse";
import { Report } from "@/storage/storageHandlers/ReportStorageHandler";
import { IReportTabPresenter } from "../controllers/ReportTabController";
import { ReportTabViewModel } from "../vms/ReportTabViewModel";

export class ReportTabPresenter implements IReportTabPresenter {
  public constructor(private vm: ReportTabViewModel) {}

  public get cannotTakeSample() {
    return this.vm.cannotTakeSample;
  }

  public set cannotTakeSample(cannotTakeSample: boolean) {
    this.vm.cannotTakeSample = !!cannotTakeSample;
  }

  public get inspectionOnly() {
    return this.vm.inspectionOnly;
  }

  public set inspectionOnly(inspectionOnly: boolean) {
    this.vm.inspectionOnly = !!inspectionOnly;
  }

  public get substituteDate() {
    return this.vm.substituteDate.value;
  }

  public set substituteDate(substituteDate: string) {
    this.vm.substituteDate.value = substituteDate;
  }

  public set reportResponse(report: FormResponse<Report>) {
    this.vm.reportLoading = report.loading;
    this.vm.reportError = report.error;

    if (!report.loading && !report.error) {
      this.vm.report = report.data;
    }
  }
}
