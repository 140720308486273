import { InitController, IInitPresenter } from "./InitController";
import { SystemTableService } from "@/system/services/SystemTableService";

export class DashboardController extends InitController<IDashboardPresenter> {
  public constructor(
    presenter: IDashboardPresenter,
    systemTableService: SystemTableService
  ) {
    super(presenter, systemTableService);
  }

  public async init(action?: string) {
    await this.loadSettingsTables();
    await this.loadSettings();

    if (action === "reload") {
      this.presenter.reload = true;
    }
  }
}

// tslint:disable-next-line: no-empty-interface
export interface IDashboardPresenter extends IInitPresenter {
  reload: boolean;
}
