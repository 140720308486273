import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { PlantParameter } from "@/storage/storageHandlers/ParameterStorageHandler";
import { IWorkOrderService } from "../services/WorkOrderService";

export class ParametersAccordionController {
  public constructor(
    private presenter: IParametersAccordionPresenter,
    private workOrderService: IWorkOrderService,
    private plantId: string
  ) {}

  public async mounted(parameters: any) {
    if (this.plantId) {
      FormRequestHandler.handle(
        this.workOrderService.getPlantParameters(this.plantId),
        response =>
          this.presenter.plantParametersResponse(response, parameters),
        "Konnte Anlagen-Parameter nicht laden!"
      );
    }
  }
}

export interface IParametersAccordionPresenter {
  plantParametersResponse(
    response: FormResponse<PlantParameter[]>,
    parametersFromWorkOrder: any
  ): void;
}
