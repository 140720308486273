























































import Vue from "vue";
import Component from "vue-class-component";

import Container from "@/components/layout/Container.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Textarea from "@/components/form/Textarea.vue";
import IconButton from "@/components/basic/IconButton.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import { Textfield } from "../../forms/ViewModelFormTypes";

@Component<CancelAppointmentDialog>({
  components: {
    Container,
    Column,
    Row,
    Textarea,
    IconButton,
    RequestButton
  },
  props: {
    canDelete: Boolean,
    big: {
      type: Boolean,
      default: false
    }
  }
})
export default class CancelAppointmentDialog extends Vue {
  private open = false;
  private changedReason: Textfield = {
    value: "",
    error: "",
    label: "Kommentar"
  };

  private reasonChanged(newValue: string) {
    this.changedReason.value = newValue;
  }

  private confirm(cancellationReason?: string) {
    this.open = false;
    this.$emit("confirm", {
      cancellationReason
    });
  }
}
