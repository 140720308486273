import {
  IWorkOrderService,
  WorkOrder,
  Pagination
} from "../services/WorkOrderService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { DateUtils } from "@/utils/DateUtils";
import { TableHeader } from "@/forms/ViewModelFormTypes";
import { Plant, IPlantService } from "@/plant/services/PlantService";
import { EventHandler } from "@/common/utils/EventHandler";
import { OnlineCheckerFactory } from "@/common/utils/OnlineCheckerFactory";
import { Auth } from "@/common/utils/Auth";
import StorageKeys from "@/common/utils/StorageKeys";
import { FormResponse } from "@/forms/FormResponse";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { StorageHolder } from "@/storage/StorageHolder";
import { OrderByClause, SortOrder } from "@/gateways/graphql/GraphQLConnection";

export class WorkOrderListController {
  private workOrderStorage = StorageHolder.WorkOrderStorage;
  private plantStorage = StorageHolder.PlantStorage;
  private id = "";

  public constructor(
    private presenter: IWorkOrderListPresenter,
    private workOrderServices: IWorkOrderService,
    private plantService: IPlantService
  ) {}

  public mounted() {
    if (!Auth.hasPermission(StorageKeys.approveWorkOrdersPermission)) {
      this.id = Auth.userId;
    }

    this.loadTab(0);

    EventHandler.addEvent(this, this.storeData, 30000, false);
  }

  public get canApproveWorkOrders() {
    return Auth.hasPermission(StorageKeys.approveWorkOrdersPermission);
  }

  public async loadTab(activeTabIndex: number) {
    this.presenter.activeTabIndex = activeTabIndex;

    let request: Promise<PaginatedList<WorkOrder>>;
    let handleResponse: FormResponse<PaginatedList<WorkOrder>>;
    const order: OrderByClause[] = [];
    switch (activeTabIndex) {
      case 0:
        this.presenter.openWorkOrdersLoading = true;
        for (
          let i = 0;
          i < this.presenter.openWorkOrdersOptions.sortBy.length;
          i++
        ) {
          order.push({
            field: this.presenter.openWorkOrdersOptions.sortBy[i],
            order: this.presenter.openWorkOrdersOptions.sortDesc[i]
              ? SortOrder.DESC
              : SortOrder.ASC
          });
        }
        request = this.workOrderServices.getAllOpenWorkOrders(
          this.id,
          this.presenter.searchText,
          this.presenter.openWorkOrdersPagination,
          order
        );

        handleResponse = await FormRequestHandler.handle(
          request,
          response => (this.presenter.getOpenWorkOrdersResponse = response),
          "get-work-orders-failed"
        );
        this.presenter.openWorkOrdersLoading = false;
        break;
      case 1:
        this.presenter.toCheckWorkOrdersLoading = true;
        for (
          let i = 0;
          i < this.presenter.toCheckWorkOrdersOptions.sortBy.length;
          i++
        ) {
          order.push({
            field: this.presenter.toCheckWorkOrdersOptions.sortBy[i],
            order: this.presenter.toCheckWorkOrdersOptions.sortDesc[i]
              ? SortOrder.DESC
              : SortOrder.ASC
          });
        }
        request = this.workOrderServices.getAllToCheckWorkOrders(
          this.id,
          this.presenter.searchText,
          this.presenter.toCheckWorkOrdersPagination,
          order
        );
        handleResponse = await FormRequestHandler.handle(
          request,
          response => (this.presenter.getToCheckWorkOrdersResponse = response),
          "get-work-orders-failed"
        );
        this.presenter.toCheckWorkOrdersLoading = false;
        break;
      case 2:
        this.presenter.finishedWorkOrdersLoading = true;
        for (
          let i = 0;
          i < this.presenter.finishedWorkOrdersOptions.sortBy.length;
          i++
        ) {
          order.push({
            field: this.presenter.finishedWorkOrdersOptions.sortBy[i],
            order: this.presenter.finishedWorkOrdersOptions.sortDesc[i]
              ? SortOrder.DESC
              : SortOrder.ASC
          });
        }
        request = this.workOrderServices.getAllFinishedWorkOrders(
          this.id,
          this.presenter.searchText,
          this.presenter.finishedWorkOrdersPagination,
          order
        );
        handleResponse = await FormRequestHandler.handle(
          request,
          response => (this.presenter.getFinishedWorkOrdersResponse = response),
          "get-work-orders-failed"
        );
        this.presenter.finishedWorkOrdersLoading = false;
        break;
      case 3:
        this.presenter.toInvoiceWorkOrdersLoading = true;
        for (
          let i = 0;
          i < this.presenter.toInvoiceWorkOrdersOptions.sortBy.length;
          i++
        ) {
          order.push({
            field: this.presenter.toInvoiceWorkOrdersOptions.sortBy[i],
            order: this.presenter.toInvoiceWorkOrdersOptions.sortDesc[i]
              ? SortOrder.DESC
              : SortOrder.ASC
          });
        }
        request = this.workOrderServices.getAllToInvoiceWorkOrders(
          this.id,
          this.presenter.searchText,
          this.presenter.toInvoiceWorkOrdersPagination,
          order
        );
        handleResponse = await FormRequestHandler.handle(
          request,
          response =>
            (this.presenter.getToInvoiceWorkOrdersResponse = response),
          "get-work-orders-failed"
        );
        this.presenter.toInvoiceWorkOrdersLoading = false;
        break;
      case 4:
        this.presenter.archivedWorkOrdersLoading = true;
        for (
          let i = 0;
          i < this.presenter.archivedWorkOrdersOptions.sortBy.length;
          i++
        ) {
          order.push({
            field: this.presenter.archivedWorkOrdersOptions.sortBy[i],
            order: this.presenter.archivedWorkOrdersOptions.sortDesc[i]
              ? SortOrder.DESC
              : SortOrder.ASC
          });
        }
        request = this.workOrderServices.getAllArchivedWorkOrders(
          this.id,
          this.presenter.searchText,
          this.presenter.archivedWorkOrdersPagination,
          order
        );
        handleResponse = await FormRequestHandler.handle(
          request,
          response => (this.presenter.getArchivedWorkOrdersResponse = response),
          "get-work-orders-failed"
        );
        this.presenter.archivedWorkOrdersLoading = false;
        break;
    }
  }

  public setWorkOrderToCheck(workOrderId: string) {
    const request = this.workOrderServices.setWorkOrderToCheck(workOrderId);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.setWorkOrderToCheckResponse = response),
      "invoice-work-order-failed"
    );
  }

  public setWorkOrderFinished(workOrderId: string) {
    const request = this.workOrderServices.setWorkOrderFinished(workOrderId);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.setWorkOrderFinishedResponse = response),
      "invoice-work-order-failed"
    );
  }

  public invoiceWorkOrder(workOrderId: string) {
    const request = this.workOrderServices.invoiceWorkOrder(workOrderId);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.invoiceWorkOrderResponse = response),
      "invoice-work-order-failed"
    );
  }

  public archiveWorkOrder(workOrderId: string) {
    const request = this.workOrderServices.archiveWorkOrder(workOrderId);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.archiveWorkOrderResponse = response),
      "archive-work-order-failed"
    );
  }

  public cancelWorkOrder(workOrderId: string, cancellationReason: string) {
    const request = this.workOrderServices.cancelWorkOrder(
      workOrderId,
      cancellationReason
    );

    FormRequestHandler.handle(
      request,
      response => (this.presenter.cancelWorkOrderResponse = response),
      "cancel-work-order-failed"
    );
  }

  public parseAddress(workOrder: WorkOrder) {
    return `${workOrder.addressStreet}, ${workOrder.addressZip} ${workOrder.addressCity}, ${workOrder.addressCountry}`;
  }

  public parseDate(workOrder: WorkOrder) {
    return DateUtils.formatDateTime(
      workOrder.dateSentToApproval ?? workOrder.plannedDate
    );
  }

  public getLastDownloadDate() {
    return DateUtils.formatDateTime(this.workOrderStorage.timestamp);
  }

  public getOpenWorkOrdersHeaders(defaultHeaders: TableHeader[]) {
    const displayStatusColumn = this.presenter.openWorkOrders.some(
      wo => wo.processingState === "REJECTED" || wo.syncing
    );
    return displayStatusColumn
      ? [this.presenter.statusColumn, ...defaultHeaders]
      : defaultHeaders;
  }

  public async storeData(force: boolean = false) {
    if (OnlineCheckerFactory.isOnline) {
      if (
        force ||
        this.workOrderStorage.timestamp + 1000 * 60 * 10 < new Date().getTime()
      ) {
        const openWorkOrders = await this.workOrderServices.getAllOpenWorkOrdersForServiceEngineer(
          Auth.userId
        );

        this.workOrderStorage.storeWorkOrders(openWorkOrders);
        this.workOrderStorage.save();

        const plants: Plant[] = [];
        const promises = [];
        for (const workOrder of openWorkOrders.filter(wo => !!wo.plantId)) {
          promises.push(
            this.plantService
              .getPlantById(workOrder.plantId)
              .then(result => plants.push(result))
          );
        }

        await Promise.all(promises);

        this.plantStorage.plants = plants;

        this.plantStorage.save();
      }
    }
  }

  public searchWorkOrders(searchText: string) {
    this.presenter.searchText = searchText;
    this.presenter.openWorkOrdersPagination.pageNumber = 1;
    this.presenter.toCheckWorkOrdersPagination.pageNumber = 1;
    this.presenter.finishedWorkOrdersPagination.pageNumber = 1;
    this.presenter.toInvoiceWorkOrdersPagination.pageNumber = 1;
    this.presenter.archivedWorkOrdersPagination.pageNumber = 1;

    this.loadTab(this.presenter.activeTabIndex);
  }

  public openWorkOrdersPageChanged(event: any) {
    this.presenter.openWorkOrdersPagination.pageNumber = event;
    this.loadTab(0);
  }

  public toCheckWorkOrdersPageChanged(event: any) {
    this.presenter.toCheckWorkOrdersPagination.pageNumber = event;
    this.loadTab(1);
  }

  public finishedWorkOrdersPageChanged(event: any) {
    this.presenter.finishedWorkOrdersPagination.pageNumber = event;
    this.loadTab(2);
  }

  public toInvoiceWorkOrdersPageChanged(event: any) {
    this.presenter.toInvoiceWorkOrdersPagination.pageNumber = event;
    this.loadTab(3);
  }

  public archivedWorkOrdersPageChanged(event: any) {
    this.presenter.archivedWorkOrdersPagination.pageNumber = event;
    this.loadTab(4);
  }

  public openWorkOrdersSorted(options: any) {
    this.presenter.openWorkOrdersOptions = options;
    this.loadTab(0);
  }

  public toCheckWorkOrdersSorted(options: any) {
    this.presenter.toCheckWorkOrdersOptions = options;
    this.loadTab(1);
  }

  public finishedWorkOrdersSorted(options: any) {
    this.presenter.finishedWorkOrdersOptions = options;
    this.loadTab(2);
  }

  public toInvoiceWorkOrdersSorted(options: any) {
    this.presenter.toInvoiceWorkOrdersOptions = options;
    this.loadTab(3);
  }

  public archivedWorkOrdersSorted(options: any) {
    this.presenter.archivedWorkOrdersOptions = options;
    this.loadTab(4);
  }
}

export interface IWorkOrderListPresenter {
  statusColumn: TableHeader;
  activeTabIndex: number;

  searchText: string;

  openWorkOrdersLoading: boolean;
  toCheckWorkOrdersLoading: boolean;
  finishedWorkOrdersLoading: boolean;
  toInvoiceWorkOrdersLoading: boolean;
  archivedWorkOrdersLoading: boolean;

  openWorkOrders: WorkOrder[];
  toCheckWorkOrders: WorkOrder[];
  finishedWorkOrders: WorkOrder[];

  openWorkOrdersPagination: Pagination;
  toCheckWorkOrdersPagination: Pagination;
  finishedWorkOrdersPagination: Pagination;
  toInvoiceWorkOrdersPagination: Pagination;
  archivedWorkOrdersPagination: Pagination;

  openWorkOrdersOptions: any;
  toCheckWorkOrdersOptions: any;
  finishedWorkOrdersOptions: any;
  toInvoiceWorkOrdersOptions: any;
  archivedWorkOrdersOptions: any;

  getOpenWorkOrdersResponse: FormResponse<PaginatedList<WorkOrder>>;
  getToCheckWorkOrdersResponse: FormResponse<PaginatedList<WorkOrder>>;
  getFinishedWorkOrdersResponse: FormResponse<PaginatedList<WorkOrder>>;
  getToInvoiceWorkOrdersResponse: FormResponse<PaginatedList<WorkOrder>>;
  getArchivedWorkOrdersResponse: FormResponse<PaginatedList<WorkOrder>>;

  setWorkOrderToCheckResponse: FormResponse<string>;
  setWorkOrderFinishedResponse: FormResponse<string>;
  invoiceWorkOrderResponse: FormResponse<string>;
  archiveWorkOrderResponse: FormResponse<string>;
  cancelWorkOrderResponse: FormResponse<string>;
}
