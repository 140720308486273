import { Form, FormFieldType } from "@/forms/Form";

export class VpiCalculationForm extends Form {
  protected definition = {
    threshold: {
      type: FormFieldType.Number,
      required: true
    },
    baseIndex: { type: FormFieldType.Selection, required: true },
    targetIndex: { type: FormFieldType.Number, required: true },
    country: { type: FormFieldType.Selection, required: false },
    state: { type: FormFieldType.Selection, required: false }
  };
}
