import { Auth } from "@/common/utils/Auth";
import { Permission } from "@/common/utils/Permissions";
import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { OrderByClause, SortOrder } from "@/gateways/graphql/GraphQLConnection";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { ItemTemplateService } from "../services/ItemTemplateService";
import { Store } from "../services/StoreGraphQLService";
import { StoreItem } from "../services/StoreItemGraphQLService";
import { StoreItemService } from "../services/StoreItemService";
import { StoreService } from "../services/StoreService";

export class StoreItemsListController {
  public constructor(
    private presenter: IStoreItemsListPresenter,
    private storeItemService: StoreItemService,
    private storeService: StoreService,
    private itemTemplateService: ItemTemplateService
  ) {}

  public mounted() {
    FormRequestHandler.handle(
      this.storeService.getAllStores(),
      response => (this.presenter.getStoresResponse = response),
      "Konnte Lagerorte nicht laden!"
    );

    FormRequestHandler.handle(
      this.itemTemplateService.getAllItemTemplates(),
      response => (this.presenter.getItemTemplatesResponse = response),
      "Konnte Artikel nicht laden!"
    );

    this.getItems();
  }

  public showDeleteEditButtons(item: any) {
    return (
      Auth.hasPermission(Permission.EditStoreCoreData) ||
      item.store.employeeId === Auth.userId
    );
  }

  public tableSorted(options: any) {
    this.presenter.options = options;

    if (!this.presenter.mounting) {
      this.getItems();
    }
  }

  public pageChanged(newPageNumber: number) {
    this.presenter.pageNumber = newPageNumber;

    if (!this.presenter.mounting) {
      this.getItems();
    }
  }

  public getItems() {
    const order: OrderByClause[] = [];
    for (let i = 0; i < this.presenter.options.sortBy.length; i++) {
      order.push({
        field: this.presenter.options.sortBy[i],
        order: this.presenter.options.sortDesc[i]
          ? SortOrder.DESC
          : SortOrder.ASC
      });
    }

    const page = new Page(
      this.presenter.itemsPerPage,
      this.presenter.pageNumber
    );

    FormRequestHandler.handle(
      this.storeItemService.getAllStoreItemsPaginated(
        page,
        order,
        this.presenter.search,
        this.presenter.storeFilter.selected,
        this.presenter.itemTemplateFilter.selected
      ),
      response => (this.presenter.tableRequest = response),
      "Konnte Tabelle nicht laden!"
    );
  }

  public deleteMinimalAmount(id: string) {
    FormRequestHandler.handle(
      this.storeItemService.deleteStoreItem(id),
      response => {
        this.presenter.deleteRequest = response;

        if (!response.error && !response.loading) {
          this.tableSorted(this.presenter.options);
        }
      },
      "Konnte Tabelle nicht laden!"
    );
  }
}

export interface IStoreItemsListPresenter {
  mounting: boolean;
  getStoresResponse: FormResponse<Store[]>;
  getItemTemplatesResponse: FormResponse<ItemTemplate[]>;

  storeFilter: {
    selected: string[];
    items: SelectionItem[];
  };
  itemTemplateFilter: {
    selected: string[];
    items: SelectionItem[];
  };

  tableRequest: FormResponse<PaginatedList<StoreItem>>;
  pageNumber: number;
  itemsPerPage: number;
  options: any;
  search: string;

  deleteRequest: FormResponse<string>;
}
