import { Report } from "@/storage/storageHandlers/ReportStorageHandler";

export class ReportAccordionController {
  public constructor(private presenter: IReportAccordionPresenter) {}

  public mounted(report: Report, reportData: any) {
    this.presenter.report = report;
    this.presenter.reportData = reportData;
  }
}

export interface IReportAccordionPresenter {
  report: Report;
  reportData: any;
}
