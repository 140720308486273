



















































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import TruncatedText from "@/components/layout/TruncatedText.vue";

import IconButton from "@/components/basic/IconButton.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import Textfield from "@/components/form/Textfield.vue";
import Select from "@/components/form/Select.vue";
import TimeTrackingDatatable from "@/components/collection/TimeTrackingDatatable.vue";

import { TimeTrackingCardViewModel } from "@/employee/vms/TimeTrackingCardViewModel";
import { TimeTrackingCardPresenter } from "@/employee/presenters/TimeTrackingCardPresenter";
import { TimeTrackingCardController } from "@/employee/controllers/TimeTrackingCardController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";

import { DateUtils } from "../../utils/DateUtils";
import { StringUtils } from "../../utils/StringUtils";
import { PlantGraphQLService } from "../../plant/services/PlantGraphQLService";
import { TimeTrackingDayGraphQLService } from "../services/TimeTrackingDayService";

@Component<TimeTrackingCard>({
  components: {
    Card,
    Container,
    Column,
    Row,
    IconButton,
    RequestButton,
    Textfield,
    Select,
    TimeTrackingDatatable,
    TruncatedText
  },
  props: {
    day: Object,
    employeeId: String
  },
  watch: {
    "this.$props.day"() {
      this.controller?.mounted(this.day, this.employeeId);
    }
  }
})
export default class TimeTrackingCard extends Vue {
  private vm = new TimeTrackingCardViewModel();
  private controller?: TimeTrackingCardController;
  private day!: any;
  private employeeId!: string;

  private get cssProperties() {
    return {
      "--primary-color": this.$vuetify.theme.currentTheme.secondary
    };
  }

  private initController(): TimeTrackingCardController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new TimeTrackingCardController(
      new TimeTrackingCardPresenter(this.vm),
      new PlantGraphQLService(connection),
      new TimeTrackingDayGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.day, this.employeeId);
  }

  private weekDay(date: string | Date) {
    return DateUtils.weekdayToString(date);
  }

  private timeToString(num: number) {
    return StringUtils.toStringWithLeadingZeros(num);
  }

  private round(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }
}
