export abstract class OnlineChecker {
  protected callbacks: CustomCallback[] = [];
  protected mIsOnline?: boolean;
  protected interval?: NodeJS.Timeout = undefined;
  protected isOnlineChanged = false;

  public init() {
    this.startLoop();
  }

  public addCallback(
    context: any,
    callbackFunction: () => void,
    onlyOnce: boolean = false,
    offlineCallback: boolean = false
  ) {
    this.callbacks.push({
      context,
      callbackFunction,
      onlyOnce,
      offlineCallback
    });
  }

  public async startLoop() {
    await this.loop();

    if (!this.interval) {
      this.interval = setInterval(async () => await this.loop(), 10000);
    }
  }

  public stopLoop() {
    if (!!this.interval) {
      clearInterval(this.interval);
    }
  }

  public get isOnline() {
    if (this.mIsOnline === undefined) {
      return !!navigator && navigator.onLine;
    } else {
      return this.mIsOnline;
    }
  }

  protected abstract async checkIfOnline(): Promise<void>;

  private async loop() {
    await this.checkIfOnline();

    if (this.isOnlineChanged) {
      for (let i = this.callbacks.length - 1; i >= 0; i--) {
        const callback = this.callbacks[i];

        if (!callback.offlineCallback && !this.mIsOnline) {
          continue;
        }

        const boundFun = callback.callbackFunction.bind(callback.context);
        boundFun();
        if (callback.onlyOnce) {
          this.callbacks.splice(i, 1);
        }
      }
    }
  }
}

interface CustomCallback {
  context: any;
  callbackFunction: () => void;
  onlyOnce?: boolean;
  offlineCallback?: boolean;
}
