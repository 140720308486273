
































import Vue from "vue";
import Component from "vue-class-component";

import SideBar from "@/components/layout/SideBar.vue";
import RightSideBar from "@/components/layout/RightSideBar.vue";
import Header from "@/components/layout/Header.vue";

import UpdateMixin from "@/components/mixins/UpdateMixin";

import { LayoutViewModel } from "@/common/vms/LayoutViewModel";
import { LayoutPresenter } from "@/common/presenters/LayoutPresenter";
import { LayoutController } from "@/common/controllers/LayoutController";
import { LocalStorage } from "../../storage/LocalStorage";

import { EventHandler } from "../utils/EventHandler";

@Component<Layout>({
  mixins: [UpdateMixin],
  components: {
    SideBar,
    RightSideBar,
    Header
  },
  props: {
    source: String,
    showRightSideBarButton: Boolean
  },
  watch: {
    "vm.search"(searchText: string) {
      if (searchText) {
        if (
          /^[0-9]+$/.test(searchText) ||
          searchText.length >= 3 ||
          searchText.length === 0
        ) {
          this.$router.push({ name: "search", params: { searchText } });
        }
        (this.$refs.header as any).clearSearch();
        this.vm.search = "";
      }
    },
    // tslint:disable-next-line
    $route(to, from) {
      EventHandler.removeLocalEvents();
    }
  }
})
export default class Layout extends UpdateMixin {
  private vm = new LayoutViewModel();
  private controller: any;

  public created() {
    this.controller = this.createController();
  }

  private mounted() {
    this.controller.init();
  }

  private createController(): LayoutController {
    return new LayoutController(
      new LayoutPresenter(this.vm, new LocalStorage())
    );
  }

  private get isMobile(): boolean {
    return !this.$vuetify.breakpoint.lgAndUp;
  }

  private emitSidebar() {
    this.$emit("rightSideBarButtonClicked");
  }
}
