import { CachedService } from "./CachedService";
import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { WorkOrderService } from "@/report/services/WorkOrderService";
import { PlantService } from "@/plant/services/PlantService";
import { OnlineCheckerFactory } from "../utils/OnlineCheckerFactory";
import { EventHandler } from "../utils/EventHandler";
// import { StoreItemService } from "@/store/services/StoreItemService";
// import { StoreMovementService } from "@/store/services/StoreMovementService";
// import { ItemTemplateService } from "@/store/services/ItemTemplateService";
// import { StoreService } from "@/store/services/StoreService";

export class ServiceFacade {
  private standardInterval = 1000 * 60;
  private serviceHolders: ServiceHolder[];

  constructor() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    const plantService = new PlantService(connection);
    const workOrderService = new WorkOrderService(connection);
    // const storeItemService = new StoreItemService(connection);
    // const storeMovementService = new StoreMovementService(connection);
    // const itemTemplateService = new ItemTemplateService(connection);
    // const storeService = new StoreService(connection);
    this.serviceHolders = [
      {
        service: workOrderService,
        serviceArgument: { subsequentService: plantService }
      }
      // { service: plantService },
      // { service: storeItemService },
      // { service: storeMovementService },
      // { service: itemTemplateService },
      // { service: storeService }
    ];

    EventHandler.addEvent(this, this.download, 15000, false);
    EventHandler.addEvent(this, this.upload, 5000, false);
    OnlineCheckerFactory.addCallback(this, this.upload, false, false);
  }

  public upload() {
    if (OnlineCheckerFactory.isOnline) {
      for (const serviceHolder of this.serviceHolders) {
        serviceHolder.service.upload();
      }
    }
  }

  public async download() {
    if (OnlineCheckerFactory.isOnline) {
      const timeInMillis = new Date().getTime();
      for (const serviceHolder of this.serviceHolders) {
        const interval = !!serviceHolder.intervalInMs
          ? serviceHolder.intervalInMs
          : this.standardInterval;

        if (serviceHolder.service.timestamp + interval <= timeInMillis) {
          serviceHolder.service.download(serviceHolder.serviceArgument);
        }
      }
    }
  }
}

export interface ServiceHolder {
  service: CachedService;
  serviceArgument?: ServiceArgument;
  intervalInMs?: number;
}

export interface ServiceArgument {
  subsequentService?: CachedService;
  ids?: string[];
}
