import { Page } from "@/datastructures/Page";
import {
  GraphQLConnection,
  OrderByClause
} from "@/gateways/graphql/GraphQLConnection";
import { ItemTemplate } from "./ItemTemplateGraphQLService";
import { IStoreMovementService } from "./StoreMovementService";

export class StoreMovementGraphQLService implements IStoreMovementService {
  public constructor(private connection: GraphQLConnection) {}

  public async getStoreMovementsForOverviewPaginated(
    page: Page,
    order: OrderByClause[],
    search: string,
    storeFilter: string[],
    itemTemplateFilter: string[],
    isPlant = false
  ) {
    const request = await this.connection.queryPaginated(
      "storeMovementsPaginated",
      page.itemsPerPage,
      page.page,
      [
        "id",
        "date",
        "employeeId",
        {
          name: "employee",
          fields: ["lastname"]
        },
        "direction",
        "sourceId",
        {
          name: "source",
          fields: ["name"]
        },
        "storeId",
        "plantIndex",
        {
          name: "store",
          fields: ["name"]
        },
        "destinationType",
        "itemTemplateId",
        {
          name: "itemTemplate",
          fields: ["name"]
        },
        "amount",
        "deliveryWay"
      ],
      search,
      [
        "direction",
        "employee.lastname",
        "source.name",
        "store.name",
        "itemTemplate.name",
        "amount"
      ],
      order,
      {
        storeFilter,
        itemTemplateFilter
      }
    );

    return {
      items: request.data,
      totalCount: request.count
    };
  }

  public async getStoreMovementById(id: string) {
    const request = await this.connection.query("storeMovement", { id }, [
      "id",
      "date",
      "employeeId",
      {
        name: "employee",
        fields: ["lastname"]
      },
      "direction",
      "sourceId",
      {
        name: "source",
        fields: ["name"]
      },
      "storeId",
      "plantIndex",
      {
        name: "store",
        fields: ["name"]
      },
      "destinationType",
      "itemTemplateId",
      {
        name: "itemTemplate",
        fields: ["name"]
      },
      "amount",
      "deliveryWay"
    ]);

    return request.data;
  }

  public async createStoreMovement(input: any) {
    const request = await this.connection.mutation(
      "createStoreMovement",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async exportStoreMovements() {
    const request = await this.connection.query("exportStoreMovements", {}, []);

    return request.data;
  }

  public async updateStoreMovement(input: any) {
    const request = await this.connection.mutation(
      "updateStoreMovement",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async deleteStoreMovement(id: string) {
    const request = await this.connection.mutation(
      "deleteStoreMovement",
      { id },
      []
    );

    return request.data;
  }
}

export interface StoreMovement {
  id: string;
  date: string;
  employeeId: string;
  direction: string;
  sourceId?: string;
  storeId: string;
  plantIndex?: number;
  destinationType: string;
  itemTemplateId: string;
  itemTemplate: ItemTemplate;
  amount: number;
  deliveryWay: string;
  comment: string;
}
