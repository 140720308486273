


































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import IconButton from "@/components/basic/IconButton.vue";
import Datatable from "@/components/collection/Datatable.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

import { MultiUploadViewModel } from "@/plant/vms/MultiUploadViewModel";
import { MultiUploadPresenter } from "@/plant/presenters/MultiUploadPresenter";
import { MultiUploadController } from "@/plant/controllers/MultiUploadController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { PlantGraphQLService } from "../services/PlantGraphQLService";

@Component<MultiUpload>({
  components: {
    Page,
    Card,
    Container,
    Column,
    Row,

    IconButton,
    Datatable,
    RequestButton
  }
})
export default class MultiUpload extends Vue {
  private dummyValue: File[] = [];

  private vm = new MultiUploadViewModel();
  private controller?: MultiUploadController;

  private initController(): MultiUploadController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new MultiUploadController(
      new MultiUploadPresenter(this.vm),
      new PlantGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private addDocuments(documents: File[]) {
    this.controller?.addDocuments(documents);

    this.dummyValue = [];
  }
}
