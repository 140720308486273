import CreateWorkOrderView from "@/report/views/CreateWorkOrderView.vue";
import WorkOrderView from "@/report/views/WorkOrderView.vue";
import WorkOrderList from "@/report/views/WorkOrderList.vue";
import WorkOrderMetaListView from "@/report/views/WorkOrderMetaList.vue";
import ServiceEngineerWeeklyScheduleView from "@/report/views/ServiceEngineerWeeklySchedule.vue";
import SendReportToLabView from "@/report/views/SendReportToLabView.vue";
import SendReportToBhView from "@/report/views/SendReportToBhView.vue";
import { Permission } from "@/common/utils/Permissions";

export default [
  {
    path: "/workOrders",
    name: "work-orders",
    component: WorkOrderList,
    meta: {
      permission: Permission.EditWorkOrders
    }
  },
  {
    path: "/workOrders/create",
    name: "create-work-order",
    component: CreateWorkOrderView,
    meta: { rightSideBar: true, permission: Permission.EditWorkOrders }
  },
  {
    path: "/workOrders/:workOrderId?",
    name: "work-order",
    component: WorkOrderView,
    meta: { rightSideBar: true, permission: Permission.EditWorkOrders }
  },
  {
    path: "/workOrderMeta",
    name: "work-order-meta",
    component: WorkOrderMetaListView,
    meta: {
      permission: Permission.SendReports
    }
  },
  {
    path: "/workOrderMeta/mailLab/:week?",
    name: "mail-lab",
    component: SendReportToLabView,
    meta: {
      permission: Permission.SendReports
    }
  },
  {
    path: "/workOrderMeta/mailBh/:week?",
    name: "mail-bh",
    component: SendReportToBhView,
    meta: {
      permission: Permission.SendReports
    }
  },
  {
    path: "/serviceEngineer/:serviceEngineerId?/WeeklySchedule/",
    name: "service-engineer-weekly-schedule",
    component: ServiceEngineerWeeklyScheduleView,
    meta: {
      permission: Permission.EditWorkOrders,
      userIdParam: "serviceEngineerId"
    }
  }
];
