import { LocalStorage } from "../LocalStorage";
import { SelectionItem } from "@/forms/ViewModelFormTypes";

export abstract class StorageHandler {
  public static storage = new LocalStorage();

  public static getName(table: string, id: string): string {
    if (this.storage.has(table)) {
      const value = this.storage.findValue(table, id);
      return value ? value.values.Bezeichnung : id;
    }

    return id;
  }

  protected static buildTopLevelSelection(
    table: string,
    textParam: string,
    valueParam?: string,
    customCallback?: (field: any) => any
  ): SelectionItem[] {
    if (this.storage.has(table)) {
      const fields = JSON.parse(this.storage.get(table));

      if (!!customCallback) {
        return fields.map((field: any) => customCallback(field));
      } else {
        return fields.map((field: any) => ({
          text: field.values[textParam],
          value: !!valueParam ? field.values[valueParam] : field.id
        }));
      }
    }
    return [];
  }
}
