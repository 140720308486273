import {
  GraphQLConnection,
  OrderByClause
} from "@/gateways/graphql/GraphQLConnection";
import { PlantParameter } from "@/storage/storageHandlers/ParameterStorageHandler";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Page } from "@/datastructures/Page";
import { WorkOrder, IWorkOrderService, Pagination } from "./WorkOrderService";
import { DateUtils } from "@/utils/DateUtils";

export class WorkOrderGraphQLService implements IWorkOrderService {
  public static reportDataFieldFields = ["name", "value", "enabled"];

  public static get WorkOrderFields() {
    return [
      "id",
      "processingState",
      "estimatedTime",
      "usedTime",
      "tasksDone",
      "materials",
      "defects",
      "comments",
      "cannotTakeSignature",
      "signature",
      "addressStreet",
      "addressZip",
      "addressCity",
      "addressState",
      "addressCountry",
      "addressLng",
      "addressLat",
      "plannedDate",
      "plantId",
      "plantIndex",
      "plantType",
      "sampleNeeded",
      "reason",
      "serviceEngineerId",
      "serviceEngineerName",
      "price",
      "hk",
      "remarks",
      "createdAt",
      "updatedAt",
      "alreadySent",
      "rejectionReason",
      "dateSentToApproval",
      "plantAddress",
      "operatorPhoneNumber",
      "operatorName",
      "operatorAddress",
      "requestedBy",
      "billing",
      "kilometers",
      "offerRequested",
      {
        name: "reportData",
        fields: [
          {
            name: "plantSizes",
            fields: WorkOrderGraphQLService.reportDataFieldFields
          },
          {
            name: "sections",
            fields: [
              "name",
              "enabled",
              {
                name: "fields",
                fields: WorkOrderGraphQLService.reportDataFieldFields
              }
            ]
          },
          {
            name: "sampleData",
            fields: [
              "cannotTakeSample",
              "substituteDate",
              "inspectionOnly",
              "sampleLocation",
              "sampleTime",
              "sampleWeather",
              "sampleTemperature",
              "sampleIntakeEnabled",
              "sampleIntake",
              "sampleTaker",
              "plantStatus",
              "suggestedActions"
            ]
          },
          {
            name: "parameters",
            fields: ["enabled", { name: "parameters", fields: ["id", "value"] }]
          },
          {
            name: "parametersIntake",
            fields: ["enabled", { name: "parameters", fields: ["id", "value"] }]
          }
        ]
      }
    ];
  }

  public constructor(private connection: GraphQLConnection) {}

  public async getAllWorkOrders(id: string): Promise<WorkOrder[]> {
    const response = await this.connection.query(
      "getAllWorkOrders",
      { id },
      WorkOrderGraphQLService.WorkOrderFields
    );
    return response.data.map((el: any) => this.parseRawWorkOrder(el));
  }

  public async getWorkOrderProcessingStates(ids: string[]): Promise<any> {
    const response = await this.connection.query(
      "getWorkOrderProcessingStates",
      { ids },
      ["id", "processingState"]
    );
    return response.data;
  }

  public async getAllOpenWorkOrdersForServiceEngineer(
    serviceEngineerId: string
  ): Promise<WorkOrder[]> {
    const response = await this.connection.query(
      "getAllOpenWorkOrdersForServiceEngineer",
      { serviceEngineerId },
      WorkOrderGraphQLService.WorkOrderFields
    );
    return response.data.map((el: any) => this.parseRawWorkOrder(el));
  }

  public async getAllOpenWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    const response = await this.connection.queryPaginated(
      "getAllOpenWorkOrders",
      pagination.itemsPerPage,
      pagination.pageNumber,
      [...WorkOrderGraphQLService.WorkOrderFields, "operatorName"],
      "",
      [],
      order,
      { id, search }
    );

    return {
      items: response.data.map((el: any) => this.parseRawWorkOrder(el)),
      totalCount: response.count
    };
  }

  public async getAllToCheckWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    const response = await this.connection.queryPaginated(
      "getAllToCheckWorkOrders",
      pagination.itemsPerPage,
      pagination.pageNumber,
      [...WorkOrderGraphQLService.WorkOrderFields, "operatorName"],
      "",
      [],
      order,
      { id, search }
    );
    return {
      items: response.data.map((el: any) => this.parseRawWorkOrder(el)),
      totalCount: response.count
    };
  }

  public async getAllFinishedWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    const response = await this.connection.queryPaginated(
      "getAllFinishedWorkOrders",
      pagination.itemsPerPage,
      pagination.pageNumber,
      [
        ...WorkOrderGraphQLService.WorkOrderFields,
        "operatorName",
        "reportPdfPath",
        "workOrderPdfPath"
      ],
      "",
      [],
      order,
      { id, search }
    );
    return {
      items: response.data.map((el: any) => this.parseRawWorkOrder(el)),
      totalCount: response.count
    };
  }

  public async getAllToInvoiceWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    const response = await this.connection.queryPaginated(
      "getAllToInvoiceWorkOrders",
      pagination.itemsPerPage,
      pagination.pageNumber,
      [
        ...WorkOrderGraphQLService.WorkOrderFields,
        "operatorName",
        "reportPdfPath",
        "workOrderPdfPath"
      ],
      "",
      [],
      order,
      { id, search }
    );
    return {
      items: response.data.map((el: any) => this.parseRawWorkOrder(el)),
      totalCount: response.count
    };
  }

  public async getAllArchivedWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    const response = await this.connection.queryPaginated(
      "getAllArchivedWorkOrders",
      pagination.itemsPerPage,
      pagination.pageNumber,
      [
        ...WorkOrderGraphQLService.WorkOrderFields,
        "operatorName",
        "reportPdfPath",
        "workOrderPdfPath"
      ],
      "",
      [],
      order,
      { id, search }
    );
    return {
      items: response.data.map((el: any) => this.parseRawWorkOrder(el)),
      totalCount: response.count
    };
  }

  public async setWorkOrderToCheck(workOrderId: string): Promise<string> {
    const response = await this.connection.mutation(
      "setWorkOrderToCheck",
      { input: { id: workOrderId } },
      []
    );

    return response.data;
  }

  public async setWorkOrderFinished(workOrderId: string): Promise<string> {
    const response = await this.connection.mutation(
      "setWorkOrderFinished",
      { input: { id: workOrderId } },
      []
    );

    return response.data;
  }

  public async invoiceWorkOrder(workOrderId: string): Promise<string> {
    const response = await this.connection.mutation(
      "invoiceWorkOrder",
      { input: { id: workOrderId } },
      []
    );

    return response.data;
  }

  public async archiveWorkOrder(workOrderId: string): Promise<string> {
    const response = await this.connection.mutation(
      "archiveWorkOrder",
      { input: { id: workOrderId } },
      []
    );

    return response.data;
  }

  public async cancelWorkOrder(
    workOrderId: string,
    cancellationReason: string
  ): Promise<string> {
    const response = await this.connection.mutation(
      "cancelWorkOrder",
      { input: { id: workOrderId, cancellationReason } },
      []
    );

    return response.data;
  }

  public async getAllWorkOrdersPaginated(
    page: Page,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    const request = await this.connection.queryPaginated(
      "getAllWorkOrdersPaginated",
      page.itemsPerPage,
      page.page,
      [
        "id",
        "processingState",
        "plannedDate",
        "workOrderPdfPath",
        "reportPdfPath",
        "hasLabReport",
        "plantIndex",
        "tasksDone",
        "alreadySent"
      ],
      "",
      [],
      order
    );

    return {
      items: request.data,
      totalCount: request.count
    };
  }

  public async getAllFinishedWorkOrdersWithoutLabReport(
    week?: string
  ): Promise<WorkOrder[]> {
    const request = await this.connection.query(
      "getAllFinishedWorkOrdersWithoutLabReport",
      { week },
      [
        "id",
        "plannedDate",
        "workOrderPdfPath",
        "reportPdfPath",
        "plantIndex",
        "tasksDone",
        "fileSize"
      ]
    );

    return request.data;
  }

  public async getAllFinishedWorkOrdersWithLabReport(
    week?: string
  ): Promise<WorkOrder[]> {
    const request = await this.connection.query(
      "getAllFinishedWorkOrdersWithLabReport",
      { week },
      [
        "id",
        "plannedDate",
        "workOrderPdfPath",
        "reportPdfPath",
        "plantIndex",
        "tasksDone",
        "fileSize"
      ]
    );

    return request.data;
  }

  public async sendReportsToLab(input: any): Promise<string> {
    const request = await this.connection.mutation(
      "sendReportsToLab",
      { input },
      []
    );

    return request.data;
  }

  public async sendReportsToBh(ids: string[]): Promise<any> {
    const request = await this.connection.mutation(
      "sendReportsToBh",
      { input: { ids } },
      ["hasFiles", "link"]
    );

    return request.data;
  }

  public async getAllWorkOrdersWithReport(): Promise<WorkOrder[]> {
    const request = await this.connection.query(
      "getAllWorkOrdersWithReport",
      {},
      [
        "id",
        "processingState",
        "plannedDate",
        "workOrderPdfPath",
        "reportPdfPath",
        "hasLabReport",
        "plantIndex",
        "tasksDone",
        "alreadySent",
        "sentToLab"
      ]
    );

    return request.data;
  }

  public async getAllWorkOrdersWithReportPaginated(
    page: Page,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    const request = await this.connection.queryPaginated(
      "getAllWorkOrdersWithReportPaginated",
      page.itemsPerPage,
      page.page,
      [
        "id",
        "processingState",
        "plannedDate",
        "workOrderPdfPath",
        "reportPdfPath",
        "hasLabReport",
        "plantIndex",
        "tasksDone",
        "alreadySent",
        "sentToLab"
      ],
      "",
      [],
      order
    );

    return {
      items: request.data,
      totalCount: request.count
    };
  }

  public async getWorkOrderById(workOrderId: string): Promise<WorkOrder> {
    const response = await this.connection.query(
      "getWorkOrderById",
      {
        id: workOrderId
      },
      WorkOrderGraphQLService.WorkOrderFields
    );

    const workOrderDto = response.data;

    const materialsDto = workOrderDto.materials;
    let materials = {};
    if (
      materialsDto &&
      Array.isArray(materialsDto) &&
      materialsDto.length > 0
    ) {
      materials = JSON.parse(materialsDto[0]);
    }

    return { ...response.data, materials };
  }

  public async getAllAtLeastFinishedWorkOrdersByPlantId(
    plantId: string,
    pagination: Pagination
  ): Promise<PaginatedList<WorkOrder>> {
    const response = await this.connection.queryPaginated(
      "getAllAtLeastFinishedWorkOrdersByPlantId",
      pagination.itemsPerPage,
      pagination.pageNumber,
      WorkOrderGraphQLService.WorkOrderFields,
      "",
      [],
      undefined,
      {
        plantId
      }
    );
    return {
      items: response.data,
      totalCount: response.count
    };
  }

  public async getAllWorkOrdersByPlantId(
    plantId: string
  ): Promise<WorkOrder[]> {
    const response = await this.connection.query(
      "getAllWorkOrdersByPlantId",
      {
        plantId
      },
      WorkOrderGraphQLService.WorkOrderFields
    );
    return response.data;
  }

  public async getWorkOrdersInDateRange(
    serviceEngineerId: string,
    dateFrom: string,
    dateTo: string
  ): Promise<WorkOrder[]> {
    const response = await this.connection.query(
      "getWorkOrdersInDateRange",
      {
        serviceEngineerId,
        dateFrom,
        dateTo
      },
      WorkOrderGraphQLService.WorkOrderFields
    );

    return response.data;
  }

  public async upsertWorkOrder(
    id: string,
    processingState: string,
    serviceEngineerId: string,
    usedTime: number,
    tasksDone: string[],
    materials: string,
    defects: string[],
    comments: string[],
    cannotTakeSignature: boolean,
    signature: string,
    reportData: any,
    operatorData: any,
    plantData: any,
    price: number | null,
    hk: number,
    updatedAt: string | null
  ): Promise<string> {
    const response = await this.connection.mutation(
      "upsertWorkOrder",
      {
        input: {
          id,
          processingState,
          serviceEngineerId,
          usedTime,
          tasksDone,
          materials,
          defects,
          comments,
          cannotTakeSignature,
          signature,
          reportData,
          operatorData,
          ...plantData,
          price,
          hk,
          updatedAt
        }
      },
      ["error", "stack"]
    );
    return response.data.id;
  }

  public async acceptWorkOrder(
    id: string,
    price: number,
    hk: number,
    doneTasks: string[]
  ): Promise<string> {
    const response = await this.connection.mutation(
      "acceptWorkOrder",
      { input: { id, price, doneTasks, hk } },
      []
    );
    return response.data;
  }

  public async rejectWorkOrder(
    id: string,
    price: number,
    hk: number,
    doneTasks: string[],
    reason: string
  ): Promise<string> {
    const response = await this.connection.mutation(
      "rejectWorkOrder",
      { input: { id, price, hk, doneTasks, reason } },
      []
    );
    return response.data;
  }

  public async getPlantParameters(plantId: string): Promise<PlantParameter[]> {
    if (!plantId) {
      return [];
    }

    const response = await this.connection.query(
      "getPlantParameters",
      { id: plantId },
      ["value", "parameterId"]
    );
    return response.data?.map((param: any) => this.parseParameter(param));
  }

  public async uploadLabReport(
    workOrderId: string,
    file: File
  ): Promise<string> {
    const request = await this.connection.mutation(
      "uploadLabReport",
      {
        input: {
          workOrderId
        }
      },
      [],
      file
    );

    return request.data;
  }

  public async sendWorkOrders(workOrderId?: string): Promise<any> {
    const request = await this.connection.mutation(
      "sendWorkOrders",
      { input: { workOrderId } },
      ["hasFiles", "link"]
    );

    return request.data;
  }

  public async exportEmptyDataSheet(): Promise<string> {
    const request = await this.connection.mutation(
      "makeEmptyPlantDataSheet",
      {},
      []
    );

    return request.data;
  }

  private parseRawWorkOrder(workOrderRaw: any): WorkOrder {
    const parsedWorkOrder = workOrderRaw;
    parsedWorkOrder.plannedDate = DateUtils.getDate(workOrderRaw.plannedDate);
    parsedWorkOrder.createdAt = DateUtils.getDate(workOrderRaw.createdAt);
    parsedWorkOrder.updatedAt = DateUtils.getDate(workOrderRaw.updatedAt);
    parsedWorkOrder.dateSentToApproval = workOrderRaw.dateSentToApproval
      ? DateUtils.getDate(workOrderRaw.dateSentToApproval)
      : undefined;

    const materialsDto = parsedWorkOrder.materials;
    if (
      materialsDto &&
      Array.isArray(materialsDto) &&
      materialsDto.length > 0
    ) {
      parsedWorkOrder.materials = JSON.parse(materialsDto[0]);
    }

    return parsedWorkOrder;
  }

  private parseParameter(parameter: any): PlantParameter {
    return {
      id: parameter.parameterId,
      value: parameter.value
    };
  }
}
