import {
  UserRoleService,
  UserRole,
  UserRoleInput
} from "../services/UserRoleService";
import {
  PermissionService,
  PermissionList
} from "../services/PermissionService";
import { FormResponse } from "@/forms/FormResponse";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { UserRoleForm } from "../forms/UserRoleForm";
import { EnvironmentUtils } from "@/utils/EnvironmentUtils";

export class UserRoleDetailController {
  public constructor(
    private presenter: IUserRoleDetailPresenter,
    private userRoleService: UserRoleService,
    private permissionService: PermissionService
  ) {}

  public async init(id: string) {
    await this.loadAllPermissions();
    EnvironmentUtils.execOnNextTick(() => this.loadUserRole(id));
  }

  // Actions
  public saveRoleButtonClicked() {
    let request = null;
    if (!!this.presenter.userRoleId) {
      request = this.userRoleService.updateUserRole(this.presenter.userRole);
    } else {
      request = this.userRoleService.createUserRole(this.presenter.userRole);
    }

    FormRequestHandler.handle(
      request,
      response => (this.presenter.saveUserRoleResponse = response),
      "Rolle konnte nicht gespeichert werden"
    );
  }

  public deleteRoleButtonClicked() {
    if (!!this.presenter.userRoleId) {
      const request = this.userRoleService.deleteUserRole(
        this.presenter.userRoleId
      );

      FormRequestHandler.handle(
        request,
        response => (this.presenter.deleteUserRoleResponse = response),
        "Rolle konnte nicht gelöscht werden"
      );
    }
  }

  // Input
  public nameChanged(name: string) {
    this.presenter.form.setFieldValue("name", name);
  }

  public permissionChanged(permissions: string[], index: number) {
    this.presenter.permissionsChanged(permissions, index);
  }

  private loadAllPermissions() {
    const request = this.permissionService.getAllPermissions();

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadAllPermissionsResponse = response),
      "Berechtigungen konnten nicht geladen werden"
    );

    return request;
  }

  private loadUserRole(id: string) {
    if (!!id) {
      const request = this.userRoleService.getUserRole(id);

      FormRequestHandler.handle(
        request,
        response => (this.presenter.loadUserRoleResponse = response),
        "Rolle konnte nicht geladen werden"
      );
    }
  }
}

export interface IUserRoleDetailPresenter {
  // Forms
  form: UserRoleForm;

  // Data
  userRoleId: string;
  userRole: UserRoleInput;

  // Responses
  loadAllPermissionsResponse: FormResponse<PermissionList>;
  loadUserRoleResponse: FormResponse<UserRole>;
  saveUserRoleResponse: FormResponse<string>;
  deleteUserRoleResponse: FormResponse<string>;

  // Method
  permissionsChanged(permissions: string[], index: number): void;
}
