
















































































import Vue from "vue";
import Component from "vue-class-component";

import IconButton from "@/components/basic/IconButton.vue";

@Component<HoverableDatatable>({
  components: {
    IconButton
  },
  props: {
    headers: {
      type: Array,
      default() {
        return [];
      }
    },
    tabletHeaders: {
      type: Array,
      default() {
        return [];
      }
    },
    mobileHeaders: {
      type: Array,
      default() {
        return [];
      }
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    loading: {
      type: Boolean,
      default: false
    },
    externalPagination: {
      type: Boolean,
      default: true
    }
  }
})
export default class HoverableDatatable extends Vue {
  private get tableHeaders() {
    return this.$vuetify.breakpoint.lgAndUp
      ? this.$props.headers
      : this.$vuetify.breakpoint.xsOnly
      ? this.$props.mobileHeaders.map((header: any) => {
          const mobileKey = "mobile";
          header[mobileKey] = true;
          return header;
        })
      : this.$props.tabletHeaders.length > 0
      ? this.$props.tabletHeaders.map((header: any) => {
          const tabletKey = "tablet";
          header[tabletKey] = true;
          return header;
        })
      : this.$props.headers;
  }
}
