
















































































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import Subtitle from "@/components/layout/Subtitle.vue";

import RequestButton from "@/components/basic/RequestButton.vue";

import Textfield from "@/components/form/Textfield.vue";
import Select from "@/components/form/Select.vue";
import TimePicker from "@/components/form/TimePicker.vue";

import { TourDataViewModel } from "@/tourPlanner/vms/TourDataViewModel";
import { TourDataPresenter } from "@/tourPlanner/presenters/TourDataPresenter";
import { TourDataController } from "@/tourPlanner/controllers/TourDataController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { ServiceEngineerGraphQLService } from "../services/ServiceEngineerGraphQLService";
import { EnvironmentUtils } from "../../utils/EnvironmentUtils";

@Component<TourDataView>({
  components: {
    Card,
    Container,
    Column,
    Grid,
    Row,
    Subtitle,

    RequestButton,
    TimePicker,

    Textfield,
    Select
  },
  props: {
    serviceEngineerId: {
      type: String,
      required: true
    }
  },
  watch: {
    serviceEngineerId() {
      this.init();
    }
  }
})
export default class TourDataView extends Vue {
  private vm = new TourDataViewModel();
  private controller?: TourDataController;

  private serviceEngineerId!: string;

  private initController(): TourDataController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new TourDataController(
      new TourDataPresenter(this.vm),
      new ServiceEngineerGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.init();
  }

  private init() {
    this.controller?.mounted(this.serviceEngineerId);
  }

  private get mobile() {
    return EnvironmentUtils.isMobile();
  }
}
