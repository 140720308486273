import { WorkOrderGeneralDataForm } from "@/forms/workOrder/WorkOrderGeneralDataForm";
import { WorkOrderDefectsDataForm } from "@/forms/workOrder/WorkOrderDefectsDataForm";
import { WorkOrderSketchDataForm as WorkOrderSignatureDataForm } from "@/forms/workOrder/WorkOrderSketchDataForm";
import { FormResponse } from "@/forms/FormResponse";
import { IWorkOrderService, WorkOrder } from "../services/WorkOrderService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { Form } from "@/forms/Form";
import { WorkOrderReportDataForm } from "@/forms/workOrder/WorkOrderReportDataForm";
import { PlantParameter } from "@/storage/storageHandlers/ParameterStorageHandler";
import { EmployeeService, Employee } from "@/employee/services/EmployeeService";
import { Auth } from "@/common/utils/Auth";
import { Date as FormDate } from "@/forms/ViewModelFormTypes";
import StorageKeys from "@/common/utils/StorageKeys";
import { DateUtils } from "@/utils/DateUtils";
import { ReportTabController } from "./ReportTabController";
import { GeneralTabController } from "./GeneralTabController";
import { SignatureTabController } from "./SignatureTabController";
import { Permission } from "@/common/utils/Permissions";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { OperatorDataTabController } from "./OperatorDataTabController";
import { PlantDataTabController } from "./PlantDataTabController";

export class CreateWorkOrderController {
  public constructor(
    private presenter: ICreateWorkOrderPresenter,
    private workOrderService: IWorkOrderService,
    private employeeService: EmployeeService
  ) {}

  public async mounted(workOrderId: string) {
    this.presenter.generalDataForm.setFieldValue("usedTime", "00:00");

    if (!!workOrderId) {
      this.presenter.workOrderId = workOrderId;
      const workOrder = await this.workOrderService.getWorkOrderById(
        workOrderId
      );

      FormRequestHandler.handle(
        this.workOrderService.getWorkOrderById(workOrderId),
        response => (this.presenter.getWorkOrderResponse = response),
        `Konnte Arbeitsauftrag mit Id ${workOrderId} nicht laden!`
      );
      if (!workOrder.serviceEngineerName) {
        try {
          this.presenter.serviceEngineer = await this.employeeService.getEmployee(
            workOrder.serviceEngineerId
          );
        } catch (exception) {
          // tslint:disable-next-line: no-console
          console.error(exception);
          this.presenter.sampleTaker = "";
        }
      } else {
        this.presenter.sampleTaker = workOrder.serviceEngineerName;
      }

      this.presenter.estimatedTime = workOrder.estimatedTime;
      this.presenter.workOrderPrice = workOrder.price;
      this.presenter.workOrderHk = workOrder.hk;
      this.presenter.workOrderRemarks = workOrder.remarks;

      this.presenter.workOrderProcessingState = workOrder.processingState;
      this.presenter.generalDataForm.setFieldValue(
        "usedTime",
        workOrder.usedTime.toString()
      );
      this.presenter.generalDataForm.setFieldValue(
        "doneTasks",
        workOrder.tasksDone as any
      );
      this.presenter.generalDataForm.setFieldValue(
        "usedMaterial",
        workOrder.materials as any
      );
      this.parseDefects(workOrder.defects, workOrder.comments);
      this.cannotTakeSignatureChanged(workOrder.cannotTakeSignature);
      this.presenter.signatureDataForm.setFieldValue(
        "signature",
        workOrder.signature
      );
      if (workOrder.cannotTakeSignature) {
        this.presenter.createWorkOrderButtonDisabled = false;
      }
      this.presenter.initReport(workOrder, []);
      this.presenter.plantId = workOrder.plantId;
      this.presenter.plantType = workOrder.plantType;

      this.presenter.loading = false;
    } else {
      this.presenter.getWorkOrderResponse = {
        loading: false,
        error: "",
        success: true,
        data: {
          id: "",
          processingState: "OPEN",
          estimatedTime: 0,
          usedTime: 0,
          tasksDone: [],
          materials: {},
          defects: [],
          comments: [],
          cannotTakeSignature: false,
          signature: "",
          addressStreet: "",
          addressZip: "",
          addressCity: "",
          addressCountry: "",
          addressState: "",
          addressLat: 0,
          addressLng: 0,
          plannedDate: new Date(),
          reportData: {},
          plantType: "",
          plantIndex: 0,
          plantId: "",
          sampleNeeded: false,
          reason: "",
          serviceEngineerId: "",
          serviceEngineerName: "",
          price: 0,
          hk: 0,
          remarks: "",
          dateSentToApproval: undefined,
          createdAt: new Date(),
          updatedAt: new Date()
        }
      };

      this.presenter.loading = false;
    }
  }

  public plantLoaded(plant: any) {
    this.presenter.plantIndex = plant.index;
    if (!!plant.operators && plant.operators.length > 0) {
      this.presenter.operatorIds = plant.operators.map((op: any) => op.id);
    }

    this.presenter.plantContract = plant.plantContract;

    this.presenter.plantRemarks = plant.remarks;

    if (
      this.presenter.workOrderProcessingState !== "OPEN" &&
      !!this.presenter.workOrderId &&
      (this.presenter.workOrderPrice === undefined ||
        this.presenter.workOrderPrice === null)
    ) {
      let maintenanceCost = 0;
      let priceSample = 0;
      if (
        plant.plantContract.hasContract &&
        !plant.plantContract.contractQuitted
      ) {
        maintenanceCost = plant.plantContract.maintenanceCost || 0;
        priceSample =
          this.presenter.cannotTakeSample || !plant.sampleNeeded
            ? 0
            : plant.plantContract.priceSample;
      }
      this.presenter.workOrderPrice = this.calculateWorkOrderPrice(
        maintenanceCost,
        priceSample
      );
    }
  }

  public calculateWorkOrderPrice(contractPrice: number, sample = 0): number {
    return contractPrice + sample;
  }

  public get stateToCheckOrRejected() {
    return (
      this.presenter.workOrderProcessingState === "TO_CHECK" ||
      this.presenter.workOrderProcessingState === "REJECTED"
    );
  }

  public changeWorkOrderPrice(event: any) {
    this.presenter.workOrderPrice = event;
  }

  public changeWorkOrderHk(event: any) {
    this.presenter.workOrderHk = event;
  }

  public operatorLoaded(operator: any) {
    this.presenter.setFlatOperator(operator);
  }

  public editEnabled(): boolean {
    return (
      this.presenter.workOrderProcessingState === "OPEN" ||
      this.presenter.workOrderProcessingState === "REJECTED"
    );
  }

  public canChangeDoneTasks(): boolean {
    return (
      this.editEnabled() ||
      (this.presenter.workOrderProcessingState === "TO_CHECK" &&
        Auth.hasPermission(Permission.ApproveWorkOrders))
    );
  }

  public noButton(): boolean {
    return (
      // this.presenter.workOrderProcessingState === "FINISHED" ||
      this.presenter.workOrderProcessingState === "TO_INVOICE" ||
      this.presenter.workOrderProcessingState === "ARCHIVED"
    );
  }

  public hasApprovePermission(): boolean {
    return Auth.hasPermission(StorageKeys.approveWorkOrdersPermission);
  }

  public showAcceptRejectButtons(): boolean {
    return (
      this.hasApprovePermission() &&
      this.presenter.workOrderProcessingState === "TO_CHECK"
    );
  }

  public parseEstimatedTime() {
    const hours = Math.floor(this.presenter.estimatedTime / 60);
    const minutes = this.presenter.estimatedTime % 60;
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
  }

  public usedTimeChanged(time: number): void {
    this.presenter.generalDataForm.setFieldValue("usedTime", time.toString());
  }

  public timePlusClicked() {
    const time =
      parseInt(this.presenter.generalDataForm.getFieldValue("usedTime"), 10) +
      30;
    this.presenter.generalDataForm.setFieldValue("usedTime", time.toString());
  }

  public timeMinusClicked() {
    let time = parseInt(
      this.presenter.generalDataForm.getFieldValue("usedTime"),
      10
    );
    if (time > 30) {
      time -= 30;
    } else {
      time = 0;
    }
    this.presenter.generalDataForm.setFieldValue("usedTime", time.toString());
  }

  public doneTaskSelected(doneTask: string): void {
    this.presenter.generalDataForm.setFieldValue("doneTasks", doneTask);
  }

  public usedMaterialSelected(usedMaterial: string): void {
    this.presenter.generalDataForm.setFieldValue("usedMaterial", usedMaterial);
  }

  public nextClicked() {
    window.scrollTo(0, 0);
    this.presenter.activeTabIndex = this.presenter.activeTabIndex + 1;
  }

  public backClicked() {
    window.scrollTo(0, 0);
    this.presenter.activeTabIndex--;
  }

  public newDefectsTextChanged(newDefects: any) {
    this.presenter.defectsDataForm.setFieldValue("defects", newDefects);
    this.presenter.defects.push(newDefects);
  }

  public setField(sectionIndex: number, fieldName: string, value: any) {
    const subform = this.presenter.reportDataForm.findSubformByName(
      "section" + sectionIndex
    );
    subform?.setFieldValue(fieldName, value);
  }

  public cannotTakeSampleChanged(newValue: boolean) {
    this.presenter.cannotTakeSample = newValue;
  }

  public cannotTakeSignatureChanged(newValue: boolean) {
    this.presenter.cannotTakeSignature = newValue;
  }

  public substituteDateChanged(newValue: string) {
    if (!!newValue) {
      this.presenter.substituteDate.value = newValue;
      this.presenter.substituteDate.text = DateUtils.format(newValue);
    }
  }

  public sampleLocationChanged(newValue: string) {
    this.presenter.reportDataForm.setFieldValue("sampleLocation", newValue);
  }

  public sampleTimeFromChanged(newValue: string) {
    this.presenter.reportDataForm.setFieldValue("sampleTimeFrom", newValue);
  }

  public sampleWeatherChanged(newValue: string) {
    this.presenter.reportDataForm.setFieldValue("sampleWeather", newValue);
  }

  public sampleTemperatureChanged(newValue: string) {
    this.presenter.reportDataForm.setFieldValue("sampleTemperature", newValue);
  }

  public sampleIntakeChanged(newValue: string) {
    this.presenter.reportDataForm.setFieldValue("sampleIntake", newValue);
  }

  public plantStatusChanged(newValue: string) {
    this.presenter.reportDataForm.setFieldValue("plantStatus", newValue);
  }

  public suggestedActionsChanged(newValue: string) {
    this.presenter.reportDataForm.setFieldValue("suggestedActions", newValue);
  }

  public setSignature(signatureData: any) {
    if (!signatureData.isEmpty) {
      this.presenter.signatureDataForm.setFieldValue(
        "signature",
        signatureData.data
      );
    }
  }

  public createWorkOrderButtonClicked() {
    this.presenter.showSetToCheckDialog = true;
  }

  public acceptWorkOrderButtonClicked(
    generalTabController?: GeneralTabController
  ) {
    const price = this.parseFloat(this.presenter.workOrderPrice);
    const hk = this.parseFloat(this.presenter.workOrderHk);

    const request = this.workOrderService.acceptWorkOrder(
      this.presenter.workOrderId,
      price,
      hk,
      generalTabController!.data.doneTasks
    );
    FormRequestHandler.handle(
      request,
      response => (this.presenter.createWorkOrderResponse = response),
      "accept-work-order-failed"
    );
  }

  public rejectWorkOrderButtonClicked() {
    this.presenter.showRejectionReasonDialog = true;
  }

  public rejectWorkOrderAcceptButtonClicked(
    generalTabController?: GeneralTabController
  ) {
    const price = this.parseFloat(this.presenter.workOrderPrice);
    const hk = this.parseFloat(this.presenter.workOrderHk);

    const request = this.workOrderService.rejectWorkOrder(
      this.presenter.workOrderId,
      price,
      hk,
      generalTabController!.data.doneTasks,
      this.presenter.rejectionReason
    );
    FormRequestHandler.handle(
      request,
      response => (this.presenter.createWorkOrderResponse = response),
      "reject-work-order-failed"
    );
  }

  public async createWorkOrder(
    setToCheck: boolean,
    seId?: string,
    reportTabController?: ReportTabController,
    generalTabController?: GeneralTabController,
    signatureTabController?: SignatureTabController,
    operatorDataTabController?: OperatorDataTabController,
    plantDataTabController?: PlantDataTabController
  ) {
    this.presenter.showSetToCheckDialog = false;

    const price = !!this.presenter.workOrderPrice
      ? this.parseFloat(this.presenter.workOrderPrice)
      : 0;
    const hk = this.parseFloat(this.presenter.workOrderHk);

    let processingState = "OPEN";
    if (setToCheck) {
      processingState = "TO_CHECK";
    }
    if (this.presenter.workOrderProcessingState === "FINISHED") {
      if (setToCheck) {
        processingState = "TO_INVOICE";
      } else {
        processingState = "FINISHED";
      }
    }

    let serviceEngineerId = seId;
    if (!serviceEngineerId) {
      serviceEngineerId = Auth.userId;
    }

    const request = this.workOrderService.upsertWorkOrder(
      this.presenter.workOrderId,
      processingState,
      serviceEngineerId,
      generalTabController!.data.usedTime,
      generalTabController!.data.doneTasks,
      generalTabController!.data.usedMaterial,
      this.presenter.defects.filter(el => !el.isComment).map(el => el.message),
      this.presenter.defects.filter(el => el.isComment).map(el => el.message),
      signatureTabController!.data.cannotTakeSignature,
      signatureTabController!.data.signature,
      reportTabController?.data,
      operatorDataTabController?.data,
      plantDataTabController?.data,
      price,
      hk,
      null
    );

    FormRequestHandler.handle(
      request,
      response => (this.presenter.createWorkOrderResponse = response),
      "create-work-order-failed"
    );
  }

  public gatherValues(items: any[]): string[] {
    const toReturn = [];
    for (const item of items) {
      if (typeof item === "string") {
        toReturn.push(item);
      } else {
        toReturn.push(item.text);
      }
    }
    return toReturn;
  }

  public getFieldIfExists(form: Form, name: string) {
    if (form.fieldExists(name)) {
      return form.getFieldValue(name);
    }
    return "";
  }

  public countryName(countryId: string) {
    return CountriesStorageHandler.getCountryNameFromId(countryId);
  }

  public setGeneralField(fieldName: string, value: any) {
    const subform = this.presenter.reportDataForm.findSubformByName(
      "plantSizes"
    );
    subform?.setFieldValue(fieldName, value);
  }

  public setParameterField(fieldName: string, value: any) {
    const subform = this.presenter.reportDataForm.findSubformByName(
      "parameters"
    );
    subform?.setFieldValue(fieldName, value);
  }

  private parseFloat(value: any) {
    let parsed = 0;
    if (value !== undefined && value !== null) {
      parsed = parseFloat(value.toString());
    }

    return parsed;
  }

  private parseDefects(defects: string[], comments: string[]) {
    let index = 0;
    const parsedDefects = [];
    if (defects) {
      for (const defect of defects) {
        this.newDefectsTextChanged({
          message: defect,
          isComment: false,
          id: index++
        });
      }
    }

    const parsedComments = [];
    if (defects) {
      for (const comment of comments) {
        this.newDefectsTextChanged({
          message: comment,
          isComment: true,
          id: index++
        });
      }
    }
  }
}

export interface ICreateWorkOrderPresenter {
  getWorkOrderResponse: FormResponse<WorkOrder>;

  serviceEngineer: Employee;
  error: string;
  loading: boolean;
  plantId: string;
  plantType: string;
  plantIndex: string;
  operatorIds: string[];
  workOrderId: string;
  workOrderHk: number;
  estimatedTime: number;
  workOrderProcessingState: string;
  activeTabIndex: number;
  showSetToCheckDialog: boolean;
  showRejectionReasonDialog: boolean;
  workOrderPrice: number | null;
  plantRemarks: string;
  substituteDate: FormDate;
  plantContract: any;
  workOrderRemarks: string;

  cannotTakeSample: boolean;
  cannotTakeSignature: boolean;

  generalDataStepCompleted: boolean;
  defectsDataStepCompleted: boolean;

  generalDataForm: WorkOrderGeneralDataForm;
  defectsDataForm: WorkOrderDefectsDataForm;
  reportDataForm: WorkOrderReportDataForm;
  signatureDataForm: WorkOrderSignatureDataForm;

  createWorkOrderResponse: FormResponse<string>;

  openGeneralDataStepButtonDisabled: boolean;
  createWorkOrderButtonDisabled: boolean;
  defects: any[];

  rejectionReason: string;

  reportData: any;
  sampleTaker: string;

  initReport(workOrder: any, parameters: PlantParameter[]): void;
  setFlatOperator(operator: any): void;
}
