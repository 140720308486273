import { IChangeTourDayPropsDialogPresenter } from "../controllers/ChangeTourDayPropsDialogController";
import { ChangeTourDayPropsDialogViewModel } from "../vms/ChangeTourDayPropsDialogViewModel";
import { TourDayPropsForm } from "../forms/TourDayPropsForm";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";

export class ChangeTourDayPropsDialogPresenter
  implements IChangeTourDayPropsDialogPresenter {
  public propsForm: TourDayPropsForm;

  public constructor(private vm: ChangeTourDayPropsDialogViewModel) {
    this.initSelections();

    this.propsForm = new TourDayPropsForm(this.vm, this.setPropsFormValid);
    this.propsForm.init();
  }

  public setPropsFormValid(context: any, valid: boolean) {
    context.acceptButtonDisabled = !valid;
  }

  private initSelections() {
    this.vm.startLocationCountry.items = CountriesStorageHandler.getAllCountries();
    this.vm.endLocationCountry.items = CountriesStorageHandler.getAllCountries();
  }
}
