import { LocalStorage } from "@/storage/LocalStorage";
import SystemKeys from "@/common/utils/SystemKeys";
import { Plant } from "../services/PlantService";

export class PlantStorage {
  private localStorage = new LocalStorage();

  private itemsToSave: PlantsToSave = {
    timestamp: 0,
    plants: []
  };

  constructor() {
    this.load();
  }

  public set plants(plants: Plant[]) {
    this.itemsToSave.plants = plants.map(p => ({ changed: false, entity: p }));
    this.itemsToSave.timestamp = new Date().getTime();
  }

  public get plants() {
    return this.itemsToSave.plants.map(fe => fe.entity);
  }

  public get timestamp() {
    return this.itemsToSave.timestamp;
  }

  public save() {
    this.localStorage.set(
      SystemKeys.storedPlantsKey,
      JSON.stringify(this.itemsToSave)
    );
  }

  public getPlantById(id: string) {
    this.load();
    const flaggedEntity = this.itemsToSave.plants.find(
      fe => fe.entity.id === id
    );

    if (!flaggedEntity) {
      throw new Error(
        `Konnte Anlage mit ID: ${id} nicht aus dem lokalen Speicher laden!`
      );
    }

    return flaggedEntity.entity;
  }

  public updatePlant(plant: Plant) {
    const flaggedEntity = this.itemsToSave.plants.find(
      fe => fe.entity.id === plant.id
    );

    if (!flaggedEntity) {
      throw new Error(
        `Konnte Arbeitsauftrag mit ID: ${plant.id} nicht aus dem lokalen Speicher laden!`
      );
    }

    flaggedEntity.changed = true;
    flaggedEntity.entity = plant;

    this.save();
  }

  public getToSyncPlants() {
    this.load();
    return this.itemsToSave.plants.filter(fe => fe.changed);
  }

  private load() {
    const entries = this.localStorage.get(SystemKeys.storedPlantsKey);
    if (!!entries) {
      this.itemsToSave = JSON.parse(entries);
    }
  }
}

interface PlantsToSave {
  timestamp: number;
  plants: Array<FlaggedEntity<Plant>>;
}

interface FlaggedEntity<T> {
  changed: boolean;
  entity: T;
}
