





























































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import TimePicker from "@/components/form/TimePicker.vue";
import Textfield from "@/components/form/Textfield.vue";
import Select from "@/components/form/Select.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

import { ChangeTourDayPropsDialogViewModel } from "@/tourPlanner/vms/ChangeTourDayPropsDialogViewModel";
import { ChangeTourDayPropsDialogPresenter } from "@/tourPlanner/presenters/ChangeTourDayPropsDialogPresenter";
import { ChangeTourDayPropsDialogController } from "@/tourPlanner/controllers/ChangeTourDayPropsDialogController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";

@Component<ChangeTourDayPropsDialogView>({
  components: {
    Card,
    Container,
    Column,
    Row,
    TimePicker,
    Textfield,
    Select,
    RequestButton
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    startTime: String,
    startLocationStreet: String,
    startLocationZip: String,
    startLocationCity: String,
    startLocationCountry: String,
    endLocationStreet: String,
    endLocationZip: String,
    endLocationCity: String,
    endLocationCountry: String,
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class ChangeTourDayPropsDialogView extends Vue {
  private vm = new ChangeTourDayPropsDialogViewModel();
  private controller?: ChangeTourDayPropsDialogController;

  private initController(): ChangeTourDayPropsDialogController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new ChangeTourDayPropsDialogController(
      new ChangeTourDayPropsDialogPresenter(this.vm)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(
      this.$props.startTime,
      this.$props.startLocationStreet,
      this.$props.startLocationZip,
      this.$props.startLocationCity,
      this.$props.startLocationCountry,
      this.$props.endLocationStreet,
      this.$props.endLocationZip,
      this.$props.endLocationCity,
      this.$props.endLocationCountry
    );
  }

  private confirm() {
    this.vm.dialogOpen = false;
    this.$emit("confirm", {
      startTime: this.vm.startTime.value,
      startLocationStreet: this.vm.startLocationStreet.value,
      startLocationZip: this.vm.startLocationZip.value,
      startLocationCity: this.vm.startLocationCity.value,
      startLocationCountry: this.vm.startLocationCountry.selected,
      endLocationStreet: this.vm.endLocationStreet.value,
      endLocationZip: this.vm.endLocationZip.value,
      endLocationCity: this.vm.endLocationCity.value,
      endLocationCountry: this.vm.endLocationCountry.selected
    });
  }
}
