import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import Quagga from "@ericblade/quagga2";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { ItemTemplateService } from "../services/ItemTemplateService";

export class BarcodeScannerController {
  public constructor(
    private presenter: IBarcodeScannerPresenter,
    private itemTemplateService: ItemTemplateService
  ) {}

  public mounted() {
    Quagga.init(
      {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: "#quagga"
        },
        decoder: {
          readers: ["code_39_reader"]
        }
      },
      (err: string) => {
        if (err) {
          // tslint:disable-next-line: no-console
          console.error(err);
          return;
        }
        Quagga.start();

        Quagga.onDetected(data => {
          if (data.codeResult && data.codeResult.code) {
            Quagga.pause();

            FormRequestHandler.handle(
              this.itemTemplateService.getItemTemplateByItemNumber(
                parseInt(data.codeResult.code.substr(0, 7), 10).toString()
              ),
              response => (this.presenter.getItemTemplateResponse = response),
              "Konnte Artikel nicht laden!"
            );
          }
        });
      }
    );
  }

  public close() {
    Quagga.stop();
  }
}

export interface IBarcodeScannerPresenter {
  getItemTemplateResponse: FormResponse<ItemTemplate>;
}
