import SystemKeys from "@/common/utils/SystemKeys";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { StorageHandler } from "./StorageHandler";

export class DeliveryWayStorageHandler extends StorageHandler {
  public static getAllDeliveryWays(): SelectionItem[] {
    if (this.storage.has(SystemKeys.deliveryWayTableKey)) {
      const fields = JSON.parse(
        this.storage.get(SystemKeys.deliveryWayTableKey)
      );

      return fields?.map((field: any) => ({
        text: field[SystemKeys.values][SystemKeys.deliveryWayNameKey],
        value: field[SystemKeys.id]
      }));
    }

    return [];
  }
}
