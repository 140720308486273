import { DateUtils } from "@/utils/DateUtils";
import { TourWeek, ITourWeekService } from "../services/TourWeekGraphQLService";
import {
  IServiceEngineerService,
  ServiceEngineer
} from "../services/ServiceEngineerGraphQLService";
import { FormResponse } from "@/forms/FormResponse";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { Auth } from "@/common/utils/Auth";
import StorageKeys from "@/common/utils/StorageKeys";

export class ServiceEngineerListController {
  public constructor(
    private presenter: IServiceEngineerListPresenter,
    private engineerService: IServiceEngineerService,
    private tourWeekService: ITourWeekService
  ) {}

  public mounted() {
    this.getServiceEngineers();
  }

  public canEdit() {
    return Auth.hasPermission(StorageKeys.editTourPlanPermission);
  }

  public getServiceEngineers() {
    const request = this.engineerService.getServiceEngineers();
    FormRequestHandler.handle(
      request,
      response => (this.presenter.engineersResponse = response),
      "get-engineers-failes"
    );
  }

  public expanded(args: any) {
    if (args.value && !this.presenter.weeksList[args.item.id]) {
      const request = this.tourWeekService.getTourWeeksByServiceEngineerId(
        args.item.id
      );
      FormRequestHandler.handle(
        request,
        response => {
          this.presenter.setWeeks(args.item.id, response);
        },
        "get-tour-weeks-failes"
      );
    }
  }

  public calculateKW(date: Date): string {
    return "KW " + DateUtils.weekNumber(date);
  }

  public formateDate(date: string): string {
    return DateUtils.format(date);
  }

  public formateState(data: string): string {
    switch (data) {
      case "STARTED":
        return "In Planung";
      case "NOT_VALIDATED":
        return "Zu Prüfen";
      case "FINISHED":
        return "Planung abgeschlossen";

      default:
        return "Unbekannt";
    }
  }

  public shouldShowValidateChip(tourweeks: TourWeek[]): boolean {
    return tourweeks.some(
      tourweek => tourweek.processingState === "NOT_VALIDATED"
    );
  }

  public filterDateforCurrent(tourweeks: TourWeek[]): TourWeek[] {
    return tourweeks.filter(
      tourweek => new Date(tourweek.weekNumber) >= new Date()
    );
  }

  public filterDateforStarted(tourweeks: TourWeek[]): TourWeek[] {
    return tourweeks.filter(
      tourweek => tourweek.processingState !== "FINISHED"
    );
  }

  public filterForServiceEngineer(
    tourweeks: TourWeek[],
    id: string
  ): TourWeek[] {
    if (tourweeks.length > 0) {
      return tourweeks.filter(tourweek => tourweek.serviceEngineerId === id);
    } else {
      return [];
    }
  }

  public filterTourWeeks(tourweeks: TourWeek[], id: string): TourWeek[] {
    tourweeks = this.filterForServiceEngineer(tourweeks, id);
    tourweeks = this.filterDateforStarted(tourweeks);
    return tourweeks;
  }

  public selectedYearChanged(newYear: string) {
    this.presenter.selectedYear = newYear;
  }

  public weekDateChanged(newDate: string) {
    this.presenter.timeFrameItems = newDate;
  }
}

export interface IServiceEngineerListPresenter {
  selectedYear: string;
  timeFrameItems: string;
  engineersResponse: FormResponse<ServiceEngineer[]>;
  weeksList: { [id: string]: TourWeek[] };
  setWeeks(
    serviceEngineerId: string,
    weeksResponse: FormResponse<TourWeek[]>
  ): void;
}
