






import Vue from "vue";
import Component from "vue-class-component";

@Component<Subtitle>({
  props: {
    align: {
      type: String,
      default: "left"
    }
  }
})
export default class Subtitle extends Vue {}
