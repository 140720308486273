import { Auth } from "@/common/utils/Auth";
import { FormResponse } from "@/forms/FormResponse";
import { DateUtils } from "@/utils/DateUtils";
import { ICreateSupplierOrderPresenter } from "../controllers/CreateSupplierOrderController";
import { SupplierOrder } from "../services/SupplierGraphQLService";
import { CreateSupplierOrderViewModel } from "../vms/CreateSupplierOrderViewModel";

export class CreateSupplierOrderPresenter
  implements ICreateSupplierOrderPresenter {
  public itemTemplateDict: { [key: string]: any } = {};
  public supplierId = "";

  public constructor(private vm: CreateSupplierOrderViewModel) {}

  public set readonly(readonly: boolean) {
    this.vm.readonly = readonly;
  }

  public get readonly() {
    return this.vm.readonly;
  }

  public set visibleView(visibleView: boolean) {
    this.vm.visibleView = visibleView;
  }

  public set getSupplierResponse(response: FormResponse<any>) {
    if (!response.loading && !response.error) {
      this.vm.supplierName = response.data.firstName
        ? `${response.data.lastName.toLocaleUpperCase()}, ${
            response.data.firstName
          }`
        : response.data.company;
    }
  }

  public set getSupplierOrderResponse(response: FormResponse<SupplierOrder>) {
    if (!response.loading && !response.error) {
      this.vm.supplierOrderItems = response.data.items.map((el: any) => ({
        itemTemplateId: el.itemTemplateId,
        amount: el.amount || "0"
      }));
    }
  }

  public set getItemTemplatesResponse(response: FormResponse<any>) {
    if (!response.loading && !response.error) {
      for (const itemTemplate of response.data.itemTemplates) {
        this.itemTemplateDict[itemTemplate.id] = itemTemplate;
      }

      this.vm.itemTemplates.items = response.data.itemTemplates.map(
        (el: any) => ({
          text: el.name,
          value: el.id,
          hk: el.hk,
          vk: el.vk
        })
      );
    }
  }

  public itemTemplateSelected(itemTemplateId: string) {
    this.vm.supplierOrderItems.push({
      itemTemplateId,
      amount: 0
    });

    const itemTemplateIndex = this.vm.itemTemplates.items.findIndex(
      el => el.value === itemTemplateId
    );
    this.vm.itemTemplates.items.splice(itemTemplateIndex, 1);
  }

  public get data() {
    const data: any = {
      date: DateUtils.dateOnly(new Date()),
      supplierId: this.supplierId,
      employeeId: Auth.userId,
      items: this.vm.supplierOrderItems.map(el => ({
        itemTemplateId: el.itemTemplateId,
        amount: parseInt(el.amount.toString(), 10)
      }))
    };

    return data;
  }

  public set createSupplierOrderResponse(response: FormResponse<string>) {
    this.vm.loading = response.loading;
    this.vm.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.visibleView = false;
    }
  }
}
