













































































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import Card from "@/components/layout/Card.vue";
import Container from "@/components/layout/Container.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import PlantSearch from "@/components/layout/PlantSearch.vue";
import OperatorSearch from "@/components/layout/OperatorSearch.vue";

import { TableHeader } from "@/forms/ViewModelFormTypes";
import Pagination from "@/components/basic/Pagination.vue";

import { SearchViewModel } from "../vms/SearchViewModel";
import { SearchPresenter } from "../presenters/SearchPresenter";
import { SearchController } from "../controllers/SearchController";
// import { SearchInteractor } from "../interactors/SearchInteractor";

import { OperatorGraphQLService } from "../../plant/services/OperatorGraphQLService";
import { PlantGraphQLService } from "../../plant/services/PlantGraphQLService";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";

@Component<SearchView>({
  components: {
    Page,
    Section,
    Card,
    Container,
    Row,
    Column,
    Pagination,
    PlantSearch,
    OperatorSearch
  },
  watch: {
    "plantVm.items"() {
      if (!this.operatorVm.searchRequest.loading) {
        if (
          this.plantVm.items.length + this.operatorVm.items.length === 1 &&
          this.operatorVm.pageNumber === 1 &&
          this.plantVm.pageNumber === 1
        ) {
          if (this.plantVm.items.length === 1) {
            this.goToPlant(this.plantVm.items[0].id);
          } else {
            this.goToOperator(this.operatorVm.items[0].id);
          }
        }
      }
    },
    "operatorVm.items"() {
      if (!this.plantVm.searchRequest.loading) {
        if (
          this.plantVm.items.length + this.operatorVm.items.length === 1 &&
          this.plantVm.pageNumber === 1 &&
          this.operatorVm.pageNumber === 1
        ) {
          if (this.plantVm.items.length === 1) {
            this.goToPlant(this.plantVm.items[0].id);
          } else {
            this.goToOperator(this.operatorVm.items[0].id);
          }
        }
      }
    }
  }
})
export default class SearchView extends Vue {
  private plantHeaders: TableHeader[] = [
    { text: "ID", value: "id", sortable: false },
    { text: "Anlage & Standort", value: "plant", sortable: false }
  ];
  private operatorHeaders: TableHeader[] = [
    { text: "ID", value: "id", sortable: false },
    { text: "Name & Adresse", value: "operator", sortable: false }
  ];
  private plantVm = new SearchViewModel();
  private operatorVm = new SearchViewModel();
  private tab = null;

  private get operatorController() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );

    return new SearchController(
      new SearchPresenter(this.operatorVm),
      new OperatorGraphQLService(connection)
    );
  }

  private get plantController() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );

    return new SearchController(
      new SearchPresenter(this.plantVm),
      new PlantGraphQLService(connection)
    );
  }

  private get searchText() {
    return this.$route.params.searchText;
  }

  private mounted() {
    if (!/^[0-9]+$/.test(this.searchText)) {
      this.operatorController.init(this.searchText);
    }
    this.plantController.init(this.searchText);
  }

  private goToPlant(plantId: string) {
    this.$router.push({ name: "plant", params: { plantId } });
  }

  private goToOperator(operatorId: string) {
    this.$router.push({ name: "operator", params: { operatorId } });
  }
}
