import {
  Employee,
  EmployeeGraphQLService
} from "../services/EmployeeGraphQLService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { Store } from "../services/StoreGraphQLService";
import { StoreItemService } from "../services/StoreItemService";
import { StoreMovementService } from "../services/StoreMovementService";
import { ItemTemplateService } from "../services/ItemTemplateService";
import { StoreService } from "../services/StoreService";
import { PlantGraphQLService } from "@/plant/services/PlantGraphQLService";
import { StoreMovement } from "../services/StoreMovementGraphQLService";

export class CreateStoreMovementController {
  private storeMovementId?: string;

  public constructor(
    private presenter: ICreateStoreMovementPresenter,
    private storeMovementService: StoreMovementService,
    private itemTemplateService: ItemTemplateService,
    private storeService: StoreService,
    private employeeService: EmployeeGraphQLService,
    private storeItemService: StoreItemService,
    private plantService: PlantGraphQLService
  ) {}

  public async mounted(
    itemTemplateId: string,
    plantIndex: string,
    storeMovementId?: string
  ) {
    FormRequestHandler.handle(
      this.employeeService.getAllEmployees(),
      response => (this.presenter.getEmployeesResponse = response),
      "Konnte Mitarbeiter nicht laden!"
    );

    FormRequestHandler.handle(
      this.itemTemplateService.getAllItemTemplates(),
      response => (this.presenter.getItemTemplatesResponse = response),
      "Konnte Artikel nicht laden!"
    );

    FormRequestHandler.handle(
      this.storeService.getAllStores(),
      response => (this.presenter.getStoresResponse = response),
      "Konnte Lagerorte nicht laden!"
    );

    if (itemTemplateId) {
      this.presenter.itemTemplate = itemTemplateId;
    }

    if (plantIndex) {
      this.presenter.plantIndex = plantIndex;
      this.plantInserted(plantIndex);
    }

    if (storeMovementId) {
      this.storeMovementId = storeMovementId;

      FormRequestHandler.handle(
        this.storeMovementService.getStoreMovementById(storeMovementId),
        response => {
          this.presenter.getStoreMovementResponse = response;

          if (!response.loading && !response.error) {
            this.presenter.visibleView = true;
          }
        },
        "Konnte Lagerort nicht laden!"
      ).then(response => {
        if (response.data.destinationType === "PLANT") {
          this.plantInserted(response.data.storeId);
        }
        this.loadCurrentAmount();
        this.loadCurrentSourceAmount();
      });
    } else {
      this.presenter.visibleView = true;
    }
  }

  public closeOverlay() {
    this.presenter.visibleView = false;
  }

  public plantInserted(plantIndex: string) {
    if (!plantIndex) {
      this.presenter.clearPlant();
      return;
    }
    FormRequestHandler.handle(
      this.plantService.getPlantByIndex(parseInt(plantIndex, 10)),
      response => (this.presenter.getPlantAddressResponse = response),
      `Konnte Anlage mit Nummer ${plantIndex} nicht laden!`
    );
  }

  public itemTemplateSelected() {
    this.loadCurrentAmount();
    this.loadCurrentSourceAmount();
  }

  public loadCurrentAmount() {
    if (this.presenter.itemTemplate && this.presenter.store) {
      FormRequestHandler.handle(
        this.storeItemService.getStoreItemAmount(
          this.presenter.store,
          this.presenter.itemTemplate
        ),
        response => (this.presenter.getCurrentAmountResponse = response),
        "Konnte momentane Menge nicht laden!"
      );
    }
  }

  public loadCurrentSourceAmount() {
    if (this.presenter.itemTemplate && this.presenter.source) {
      FormRequestHandler.handle(
        this.storeItemService.getStoreItemAmount(
          this.presenter.source,
          this.presenter.itemTemplate
        ),
        response => (this.presenter.getCurrentSourceAmountResponse = response),
        "Konnte momentane Menge nicht laden!"
      );
    }
  }

  public createStoreMovementButtonClicked() {
    if (this.storeMovementId) {
      const data = { id: this.storeMovementId, ...this.presenter.data };
      FormRequestHandler.handle(
        this.storeMovementService.updateStoreMovement(data),
        response => (this.presenter.createStoreMovementResponse = response),
        "Could not update storeMovement!"
      );
    } else {
      FormRequestHandler.handle(
        this.storeMovementService.createStoreMovement(this.presenter.data),
        response => (this.presenter.createStoreMovementResponse = response),
        "Could not create storeMovement!"
      );
    }
  }
}

export interface ICreateStoreMovementPresenter {
  visibleView: boolean;

  source: string;
  store: string;
  itemTemplate: string;
  plantIndex: string;

  getEmployeesResponse: FormResponse<Employee[]>;
  getStoresResponse: FormResponse<Store[]>;
  getItemTemplatesResponse: FormResponse<ItemTemplate[]>;
  getStoreMovementResponse: FormResponse<StoreMovement>;

  getPlantAddressResponse: FormResponse<any>;
  getCurrentSourceAmountResponse: FormResponse<string>;
  getCurrentAmountResponse: FormResponse<string>;

  data: any;
  createStoreMovementResponse: FormResponse<string>;

  clearPlant(): void;
}
