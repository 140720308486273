import { PlantGroupStorageHandler } from "@/storage/storageHandlers/PlantGroupStorageHandler";
import { PlantSize } from "../vms/PlantSizesAccordionViewModel";

export class PlantSizesAccordionController {
  public constructor(private presenter: IPlantSizesAccordionPresenter) {}

  public mounted(plantType: string, plantSizes: PlantSize[]) {
    const plantSizeIds = PlantGroupStorageHandler.getPlantSizesFromType(
      plantType
    );

    const parsedPlantSizes = [];

    for (const plantSizeId of plantSizeIds) {
      const plantSize = PlantGroupStorageHandler.getSizeFromId(plantSizeId);

      if (!!plantSize && plantSize.compare) {
        let value = plantSize.value;

        if (plantSizes) {
          const plantSizeIndex = plantSizes.findIndex(
            p => p.name === plantSize.name
          );
          if (plantSizeIndex > -1) {
            value = plantSizes[plantSizeIndex].value;
          }
        }

        parsedPlantSizes.push({
          id: plantSize.id,
          suffix: plantSize.unit,
          name: plantSize.name,
          value
        });
      }
    }

    this.presenter.plantSizes = parsedPlantSizes;
  }
}

export interface IPlantSizesAccordionPresenter {
  plantSizes: PlantSize[];
}
