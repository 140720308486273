import { SelectionItem } from "@/forms/ViewModelFormTypes";

export class CreateSupplierViewModel {
  public visibleView = false;

  public salutation: { selected: string; items: SelectionItem[] } = {
    selected: "",
    items: []
  };
  public company = "";
  public firstName = "";
  public lastName = "";
  public email = "";
  public website = "";

  public street = "";
  public zip = "";
  public city = "";
  public country: { selected: string; items: SelectionItem[] } = {
    selected: "",
    items: []
  };
  public state: { selected: string; items: SelectionItem[] } = {
    selected: "",
    items: []
  };

  public phoneNumbers: PhoneNumberInput[] = [];

  public uid = "";
  public targetPayment = "";
  public skontoPercent = "";
  public skontoDays = "";

  public loading = false;
  public error = "";
}

interface PhoneNumberInput {
  areaCode: { selected: string | string[]; items: SelectionItem[] };
  phoneNumber: string;
  isStandard: boolean;
}
