export type OperatorsProps = OperatorProps[];
type OperatorProps = Partial<Operator>;

export class Operators {
  private readonly operators: Operator[];

  public constructor(operators?: OperatorsProps) {
    this.operators = operators?.map(o => new Operator(o)) ?? [];
  }

  public get first(): Operator | undefined {
    return this.operators.length > 0 ? this.operators[0] : undefined;
  }

  public get(): Operator[] {
    return this.operators;
  }
}

export class Operator {
  public readonly firstName?: string;
  public readonly lastName?: string;
  public readonly company?: string;

  public constructor(props?: OperatorProps) {
    this.firstName = props?.firstName;
    this.lastName = props?.lastName;
    this.company = props?.company;
  }

  public get label(): string {
    if (this.company) {
      return this.company;
    } else if (this.firstName && this.lastName) {
      return `${this.lastName.toUpperCase()} ${this.firstName}`;
    }

    return this.lastName?.toUpperCase() ?? this.firstName ?? "Unbenannt";
  }
}
