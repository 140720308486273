

























import Vue from "vue";
import Component from "vue-class-component";

import Pagination from "@/components/basic/Pagination.vue";
import Datatable from "@/components/collection/Datatable.vue";

@Component<PaginatedDatatable>({
  components: {
    Pagination,
    Datatable
  },
  props: {
    value: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    totalItems: {
      type: Number,
      required: true
    },
    headers: {
      type: Array,
      default() {
        return [];
      }
    },
    tabletHeaders: {
      type: Array,
      default() {
        return [];
      }
    },
    mobileHeaders: {
      type: Array,
      default() {
        return [];
      }
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class PaginatedDatatable extends Vue {}
