import {
  IWeeklySchedulePresenter,
  ResponseWithArgs
} from "../controllers/WeeklyScheduleController";
import { WeeklyScheduleViewModel } from "../vms/WeeklyScheduleViewModel";
import { Selection } from "@/forms/ViewModelFormTypes";
import { TourWeek, TourDay } from "../services/TourWeekGraphQLService";
import { ServiceEngineer } from "../services/ServiceEngineerGraphQLService";
import { FormResponse } from "@/forms/FormResponse";
import { PlantGroupStorageHandler } from "@/storage/storageHandlers/PlantGroupStorageHandler";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { Appointment } from "../services/AppointmentGraphQLService";

export class WeeklySchedulePresenter implements IWeeklySchedulePresenter {
  public constructor(private vm: WeeklyScheduleViewModel) {}

  public set visibleView(visible: boolean) {
    this.vm.visibleView = visible;
    if (!visible) {
      setTimeout(() => (this.vm.goBack = true), 300);
    } else {
      this.vm.loading = false;
    }
  }

  public set viewRouteMode(viewRouteMode: boolean) {
    this.vm.viewRouteMode = viewRouteMode;
  }

  public get viewRouteMode(): boolean {
    return this.vm.viewRouteMode;
  }

  public get selectedAppointments() {
    return this.vm.selectedAppointments;
  }

  public set selectedAppointments(selectedAppointments: any[]) {
    this.vm.selectedAppointments = selectedAppointments;
  }

  public get timeFrameItems() {
    return this.vm.timeFrameItems;
  }

  public set timeFrameItems(timeFrameItems: Selection) {
    this.vm.timeFrameItems = timeFrameItems;
  }

  public get serviceEngineers() {
    return this.vm.serviceEngineers;
  }

  public set serviceEngineers(serviceEngineers: ServiceEngineer[]) {
    this.vm.serviceEngineers = serviceEngineers;
  }

  public get serviceEngineersViewList() {
    return this.vm.serviceEngineersViewList;
  }

  public set serviceEngineersViewList(serviceEngineersViewList: Selection) {
    this.vm.serviceEngineersViewList = serviceEngineersViewList;
  }

  public get showServiceEngineerOverwriteDialog(): boolean {
    return this.vm.showServiceEngineerOverwriteDialog;
  }

  public set showServiceEngineerOverwriteDialog(
    showServiceEngineerOverwriteDialog: boolean
  ) {
    this.vm.showServiceEngineerOverwriteDialog = showServiceEngineerOverwriteDialog;
  }

  public get tourWeek(): TourWeek {
    return this.vm.tourWeek;
  }

  public set tourWeek(tourWeek: TourWeek) {
    this.vm.tourWeek = tourWeek;
    this.vm.generateWorkOrdersButtonDisabled =
      tourWeek.processingState === "STARTED";
    for (const tourDay of tourWeek.tourDays) {
      for (const appointment of tourDay.appointments) {
        const app = appointment as any;
        if (!!app.plant) {
          app.plant.group = PlantGroupStorageHandler.getGroupNameFromId(
            app.plant.group
          );
          app.plant.type = PlantGroupStorageHandler.getTypeNameFromId(
            app.plant.type
          );
        }
        if (!!app.addressCountry) {
          app.addressCountry = CountriesStorageHandler.getCountryNameFromId(
            app.addressCountry
          );
        }
      }
    }
  }

  public get finishedRouteRequests(): number {
    return this.vm.finishedRouteRequests;
  }

  public set finishedRouteRequests(finishedRouteRequests: number) {
    this.vm.finishedRouteRequests = finishedRouteRequests;
  }

  public set loading(loading: boolean) {
    this.vm.loading = loading;
  }

  public set error(error: string) {
    this.vm.error = error;
  }

  public set finishTourWeekResponse(response: FormResponse<string>) {
    this.vm.finishTourWeekRequest = response;

    if (response.success) {
      this.vm.tourWeekFinished = true;
    }
  }

  public set setNotValidatedTourWeekResponse(response: FormResponse<string>) {
    this.vm.setNotValidatedTourWeekRequest = response;

    if (response.success) {
      this.vm.tourWeekNotValidated = true;
    }
  }

  public set unselectAppointmentResponse(response: FormResponse<string>) {
    this.handleResponse(response, id => {
      const index = this.vm.selectedAppointments.findIndex(
        app => app.id === id
      );
      this.vm.selectedAppointments.splice(index, 1);
    });
  }

  public set requestTourWeekRoutesResponse(response: FormResponse<any[]>) {
    if (response.error) {
      const dayIndex = parseInt(response.error.split(":")[0], 10);
      if (dayIndex !== undefined && !isNaN(dayIndex)) {
        const day = [
          "Montag",
          "Dienstag",
          "Mittwoch",
          "Donnerstag",
          "Freitag",
          "Samstag",
          "Sonntag"
        ][dayIndex];
        this.vm.routeError =
          "Fehler bei der Berechnung der Route für <strong>" +
          day +
          "</strong>: Start-, End- oder Terminadresse ungültig.";
      }
    }

    this.handleResponse(response, routes => {
      this.vm.viewRouteMode = true;
      for (const route of routes) {
        const tourDay = this.vm.tourWeek.tourDays.find(
          (td: any) => td.index === route.tourDayIndex
        );
        tourDay.directionsResponse = route.output;

        for (let i = route.output.routes[0].legs.length - 1; i >= 0; i--) {
          const leg = route.output.routes[0].legs[i];
          if (leg.duration.value > 0) {
            tourDay.appointments.splice(i, 0, {
              duration: leg.duration.value,
              distance: leg.distance.value
            });
          }
        }
      }
    });
  }

  public set replaceTourDayResponse(
    responseWithArgs: ResponseWithArgs<number>
  ) {
    this.handleResponse(responseWithArgs.response, index => {
      const args = responseWithArgs.args;
      const tourDay = this.vm.tourWeek.tourDays.find(
        (td: any) => td.index === index
      );
      tourDay.startTime = args.startTime;
      tourDay.startLocation.street = args.startLocationStreet;
      tourDay.startLocation.zip = args.startLocationZip;
      tourDay.startLocation.city = args.startLocationCity;
      tourDay.startLocation.country = args.startLocationCountry;
      tourDay.endLocation.street = args.endLocationStreet;
      tourDay.endLocation.zip = args.endLocationZip;
      tourDay.endLocation.city = args.endLocationCity;
      tourDay.endLocation.country = args.endLocationCountry;
    });
  }

  public set upsertTourWeekResponse(response: FormResponse<string>) {
    if (!response.loading) {
      this.vm.tourWeek.id = response.data;
    }

    this.vm.initialTourWeekLoading = response.loading;
  }

  public set updateAppointmentReasonDurationResponse(
    responseWithArgs: ResponseWithArgs<string>
  ) {
    this.handleResponse(responseWithArgs.response, () => {
      const appointment = responseWithArgs.args.appointment;
      appointment.reason = responseWithArgs.args.reason;
      appointment.duration = responseWithArgs.args.duration;
      appointment.remarks = responseWithArgs.args.remarks;
    });
  }

  public set removeAppointmentFromTourDaysResponse(
    responseWithArgs: ResponseWithArgs<string>
  ) {
    this.handleResponse(responseWithArgs.response, () => {
      const args = responseWithArgs.args;
      const index = args.tourDay.appointments.findIndex(
        (a: any) => a.id === args.appointment.id
      );
      this.vm.selectedAppointments.push(args.appointment);
      args.tourDay.appointments.splice(index, 1);
    });
  }

  public set cancelAppointmentResponse(
    responseWithArgs: ResponseWithArgs<string>
  ) {
    this.handleResponse(responseWithArgs.response, () => {
      const args = responseWithArgs.args;
      const index = args.tourDay.appointments.findIndex(
        (a: any) => a.id === args.appointment.id
      );
      args.tourDay.appointments.splice(index, 1);
    });
  }

  public set getAllSelectedAppointmentsByTourPlannerResponse(
    response: FormResponse<Appointment[]>
  ) {
    this.handleResponse(
      response,
      appointments => (this.vm.selectedAppointments = appointments)
    );
  }

  public set getServiceEngineersResponse(
    response: FormResponse<ServiceEngineer[]>
  ) {
    this.handleResponse(response, serviceEngineers => {
      this.vm.serviceEngineers = serviceEngineers;
      this.vm.serviceEngineersViewList.items = [];
      for (const serviceEngineer of serviceEngineers) {
        this.vm.serviceEngineersViewList.items.push({
          text: `${serviceEngineer.firstName} ${serviceEngineer.lastName}`,
          value: serviceEngineer.id
        });
      }
    });
  }

  private handleResponse<T>(
    response: FormResponse<T>,
    action: (data: T) => void,
    errorAction?: (message: string) => void
  ) {
    this.vm.loading = true;

    if (!response.loading) {
      if (response.success) {
        action(response.data);
      } else if (!!errorAction) {
        errorAction(response.error);
      }

      this.vm.loading = false;
    }
  }
}
