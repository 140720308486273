import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { OrderByClause } from "@/gateways/graphql/GraphQLConnection";
import { StorageHolder } from "@/storage/StorageHolder";
import { StoreItem } from "./StoreItemGraphQLService";
import { IStoreItemService } from "./StoreItemService";

export class StoreItemLocalService implements IStoreItemService {
  private storeItemStorage = StorageHolder.StoreItemStorage;

  public get timestamp() {
    return this.storeItemStorage.timestamp;
  }

  public set storeItems(storeItems: StoreItem[]) {
    this.storeItemStorage.storeItems = storeItems;
    this.storeItemStorage.save();
  }

  public save() {
    this.storeItemStorage.save();
  }

  public getAllStoreItemsPaginated(
    page: Page,
    order: OrderByClause[],
    search: string,
    storeFilter: string[],
    itemTemplateFilter: string[]
  ): Promise<PaginatedList<StoreItem>> {
    let filteredItems = this.storeItemStorage.storeItems;
    if (storeFilter && storeFilter.length > 0) {
      filteredItems = filteredItems.filter(
        fi => storeFilter.findIndex(s => s === fi.storeId) >= 0
      );
    }

    if (itemTemplateFilter && itemTemplateFilter.length > 0) {
      filteredItems = filteredItems.filter(
        fi => itemTemplateFilter.findIndex(it => it === fi.itemTemplateId) >= 0
      );
    }

    const slicedItems = filteredItems.slice(
      page.itemsPerPage * (page.page - 1),
      page.itemsPerPage * page.page
    );

    return Promise.resolve({
      items: slicedItems,
      totalCount: filteredItems.length
    });
  }

  public getStoreItems(itemIds: string[]): Promise<StoreItem[]> {
    const items: StoreItem[] = [];
    for (const id of itemIds) {
      const itemIndex = this.storeItemStorage.storeItems.findIndex(
        el => el.id === id || el.itemTemplateId === id
      );
      if (itemIndex >= 0) {
        items.push(this.storeItemStorage.storeItems[itemIndex]);
      }
    }
    return Promise.resolve(items);
  }

  public getAllStoreItemsByEmployee(employeeId: string): Promise<StoreItem[]> {
    const storeHolder = StorageHolder.StoreStorage;
    const storeIndex = storeHolder.stores.findIndex(
      el => el.employeeId === employeeId
    );
    if (storeIndex < 0) {
      throw new Error("Could not find store!");
    }
    const store = storeHolder.stores[storeIndex];
    return this.getAllStoreItemsByStore(store.id);
  }

  public getAllStoreItemsByStore(storeId: string): Promise<StoreItem[]> {
    return Promise.resolve(
      this.storeItemStorage.storeItems.filter(si => si.storeId === storeId)
    );
  }

  public getAllStoreItemsByPlant(plantId: string): Promise<StoreItem[]> {
    throw new Error("Not available in offline mode");
  }

  public getStoreItemAmount(
    storeId: string,
    itemTemplateId: string
  ): Promise<string> {
    const index = this.storeItemStorage.storeItems.findIndex(
      si => si.storeId === storeId && si.itemTemplate.id === itemTemplateId
    );
    if (index >= 0) {
      return Promise.resolve(this.storeItemStorage.storeItems[index].amount);
    }

    return Promise.resolve("0");
  }

  public getStoreItemById(id: string): Promise<StoreItem> {
    throw new Error("Not available in offline mode");
  }

  public updateStoreItem(input: any): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public createStoreItem(input: any): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public deleteStoreItem(id: string): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public exportStoreItems(): Promise<any> {
    throw new Error("Not available in offline mode");
  }

  public getToSyncStoreItems() {
    return this.storeItemStorage.getToSyncStoreItems();
  }
}
