import {
  AuthenticationGraphQLService,
  LoginPayload
} from "../interactors/AuthenticationService";
import { LoginForm } from "../forms/LoginForm";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import StorageKeys from "../utils/StorageKeys";

export class LoginController {
  public constructor(
    private presenter: ILoginPresenter,
    private authentication: AuthenticationGraphQLService,
    private storage: SimpleStringStorage
  ) {}

  public init() {
    // Init
  }

  public loginFieldChanged(fieldname: string, value: string) {
    this.presenter.loginForm.setFieldValue(fieldname, value);
  }

  public loginButtonClicked() {
    const loginData = this.presenter.loginForm.getData();
    const request = this.authentication.login(
      loginData.username,
      loginData.password
    );

    FormRequestHandler.handle(
      request,
      response => {
        if (response.success) {
          this.storeLoginData(response.data);
        }
        this.presenter.loginResponse = response;
      },
      "Login nicht möglich"
    );
  }

  private storeLoginData(data: LoginPayload) {
    this.storage.set(StorageKeys.userId, data.userId);
    this.storage.set(StorageKeys.permissions, JSON.stringify(data.permissions));
    this.storage.set(StorageKeys.validUntil, data.validUntil.toISOString());
    this.storage.set(StorageKeys.token, data.token);
  }
}

export interface ILoginPresenter {
  loginForm: LoginForm;

  loginResponse: FormResponse<LoginPayload>;
}
