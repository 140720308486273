import { MapElementFactory } from "vue2-google-maps";

export default MapElementFactory({
  name: "infoWindowMWL",
  ctr: () => google.maps.InfoWindow,
  //// The following is optional, but necessary if the constructor takes multiple arguments
  //// e.g. for GroundOverlay
  // ctrArgs: (options, otherProps) => [options],
  events: ["domready", "closeclick", "content_changed"],

  // Mapped Props will automatically set up
  //   this.$watch('propertyName', (v) => instance.setPropertyName(v))
  //
  // If you specify `twoWay`, then it also sets up:
  //   google.maps.event.addListener(instance, 'propertyName_changed', () => {
  //     this.$emit('propertyName_changed', instance.getPropertyName())
  //   })
  //
  // If you specify `noBind`, then neither will be set up. You should manually
  // create your watchers in `afterCreate()`.
  mappedProps: {
    options: {
      type: Object,
      required: false,
      default() {
        return {};
      }
    },
    position: {
      type: Object,
      twoWay: true
    },
    zIndex: {
      type: Number,
      twoWay: true
    }
    //// If you have a property that comes with a `_changed` event,
    //// you can specify `twoWay` to automatically bind the event, e.g. Map's `zoom`:
    // zoom: {type: Number, twoWay: true}
  },
  // Inject function
  inject: {
    $markerWithLabelPromise: {
      default: null
    }
  },
  // Any other properties you want to bind. Note: Must be in Object notation
  props: {
    opened: {
      type: Boolean,
      default: true
    }
  },
  // Actions you want to perform before creating the object instance using the
  // provided constructor (for example, you can modify the `options` object).
  // If you return a promise, execution will suspend until the promise resolves
  mounted() {
    const el = this.$refs.flyawayMWL;
    el.parentNode.removeChild(el);
  },

  beforeCreate(options) {
    options.content = this.$refs.flyawayMWL;

    if (this.$markerWithLabelPromise) {
      delete options.position;
      return this.$markerWithLabelPromise.then(mo => {
        this.$markerWithLabelObject = mo;
        return mo;
      });
    }
  },

  methods: {
    _openInfoWindow() {
      if (this.opened) {
        if (this.$markerWithLabelObject !== null) {
          this.$infoWindowMWLObject.open(
            this.$map,
            this.$markerWithLabelObject
          );
        } else {
          this.$infoWindowMWLObject.open(this.$map);
        }
      } else {
        this.$infoWindowMWLObject.close();
      }
    }
  },

  afterCreate() {
    this._openInfoWindow();
    this.$watch("opened", () => {
      this._openInfoWindow();
    });
  }
});
