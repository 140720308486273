















































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import ServerDatatable from "@/components/collection/ServerDatatable.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import IconButton from "@/components/basic/IconButton.vue";
import DeleteDialog from "@/components/basic/DeleteDialog.vue";

import { SupplierViewModel } from "@/store/vms/SupplierViewModel";
import { SupplierPresenter } from "@/store/presenters/SupplierPresenter";
import { SupplierController } from "@/store/controllers/SupplierController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import {
  Supplier,
  SupplierGraphQLService
} from "@/store/services/SupplierGraphQLService";
import { RawLocation } from "vue-router";

@Component<SupplierView>({
  components: {
    Page,
    Section,
    ServerDatatable,
    Card,
    Container,
    IconButton,
    Column,
    Row,
    DeleteDialog
  }
})
export default class SupplierView extends Vue {
  private vm = new SupplierViewModel();
  private controller?: SupplierController = undefined;

  private isDeleteDialogShown = false;
  private toDeleteSupplier: any = {};

  private initController(): SupplierController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new SupplierController(
      new SupplierPresenter(this.vm),
      new SupplierGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }

  private orderButtonClicked(supplierId?: string) {
    const route: RawLocation = { name: "supplier-order-create" };
    if (supplierId) {
      route.params = { supplierId };
    }

    this.$router.push(route);
  }

  private addSupplierButtonClicked(supplierId?: string) {
    const route: RawLocation = { name: "supplier-create" };
    if (supplierId) {
      route.params = { supplierId };
    }

    this.$router.push(route);
  }

  private showDeleteDialog(supplier: Supplier) {
    this.toDeleteSupplier = supplier;
    this.isDeleteDialogShown = true;
  }

  private deleteSupplier() {
    this.controller?.deleteSupplier(this.toDeleteSupplier.id);
    this.isDeleteDialogShown = false;
  }
}
