import { CachedService } from "@/common/services/CachedService";
import { ServiceArgument } from "@/common/services/ServiceFacade";
import { OnlineCheckerFactory } from "@/common/utils/OnlineCheckerFactory";
import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import {
  GraphQLConnection,
  OrderByClause
} from "@/gateways/graphql/GraphQLConnection";
import {
  ItemTemplate,
  ItemTemplateGraphQLService
} from "./ItemTemplateGraphQLService";
import { ItemTemplateLocalService } from "./ItemTemplateLocalService";

export class ItemTemplateService
  implements IItemTemplateService, CachedService {
  private onlineService: ItemTemplateGraphQLService;
  private offlineService: ItemTemplateLocalService;
  private service: IItemTemplateService;

  constructor(connection: GraphQLConnection) {
    this.offlineService = new ItemTemplateLocalService();
    this.onlineService = new ItemTemplateGraphQLService(connection);

    if (OnlineCheckerFactory.isOnline) {
      this.service = this.onlineService;
    } else {
      this.service = this.offlineService;
    }
  }

  public get timestamp() {
    return this.offlineService.timestamp;
  }

  public async getAllItemTemplates(): Promise<ItemTemplate[]> {
    this.updateOnlineState();
    return this.service.getAllItemTemplates();
  }

  public async getAllItemTemplatesPaginated(
    page: Page,
    order: OrderByClause[],
    search: string
  ): Promise<PaginatedList<ItemTemplate>> {
    this.updateOnlineState();
    return this.service.getAllItemTemplatesPaginated(page, order, search);
  }

  public async getAllItemTemplatesBySupplier(
    supplierId: string
  ): Promise<ItemTemplate[]> {
    this.updateOnlineState();
    return this.service.getAllItemTemplatesBySupplier(supplierId);
  }

  public async getItemTemplateById(id: string): Promise<ItemTemplate> {
    this.updateOnlineState();
    return this.service.getItemTemplateById(id);
  }

  public async getItemTemplateByItemNumber(
    itemNumber: string
  ): Promise<ItemTemplate> {
    this.updateOnlineState();
    return this.service.getItemTemplateByItemNumber(itemNumber);
  }

  public async updateItemTemplate(input: any): Promise<string> {
    this.updateOnlineState();
    return this.service.updateItemTemplate(input);
  }

  public async createItemTemplate(input: any): Promise<string> {
    this.updateOnlineState();
    return this.service.createItemTemplate(input);
  }

  public async deleteItemTemplate(id: string): Promise<string> {
    this.updateOnlineState();
    return this.service.deleteItemTemplate(id);
  }

  public async download(arg?: ServiceArgument): Promise<void> {
    let itemTemplates: ItemTemplate[] = [];
    if (!arg || !arg.ids) {
      itemTemplates = await this.onlineService.getAllItemTemplates();
    } else {
      const promises = [];
      for (const id of arg.ids) {
        promises.push(
          this.onlineService
            .getItemTemplateById(id)
            .then(result => itemTemplates.push(result))
        );
      }

      await Promise.all(promises);
    }

    this.offlineService.itemTemplates = itemTemplates;
  }

  public upload(): void {
    for (const itemTemplateFlaggedEntity of this.offlineService.getToSyncStoreItems()) {
      const itemTemplate = itemTemplateFlaggedEntity.entity;
      this.onlineService.updateItemTemplate(itemTemplate);
      itemTemplateFlaggedEntity.changed = false;
    }
    this.offlineService.save();
  }

  private updateOnlineState() {
    if (OnlineCheckerFactory.isOnline) {
      this.service = this.onlineService;
    } else {
      this.service = this.offlineService;
    }
  }
}

export interface IItemTemplateService {
  getAllItemTemplates(): Promise<ItemTemplate[]>;
  getAllItemTemplatesPaginated(
    page: Page,
    order: OrderByClause[],
    search: string
  ): Promise<PaginatedList<ItemTemplate>>;
  getAllItemTemplatesBySupplier(supplierId: string): Promise<ItemTemplate[]>;
  getItemTemplateById(id: string): Promise<ItemTemplate>;
  getItemTemplateByItemNumber(itemNumber: string): Promise<ItemTemplate>;
  updateItemTemplate(input: any): Promise<string>;
  createItemTemplate(input: any): Promise<string>;
  deleteItemTemplate(id: string): Promise<string>;
}
