import Dashboard from "@/views/Dashboard.vue";
import CreateLooseAppointmentView from "@/tourPlanner/views/CreateLooseAppointmentView.vue";
import WeeklySchedule from "@/tourPlanner/views/WeeklySchedule.vue";
import TourDayMap from "@/tourPlanner/views/TourDayMap.vue";
import ServiceEngineerList from "@/tourPlanner/views/ServiceEngineerList.vue";
import AppointmentMap from "@/tourPlanner/views/AppointmentMap.vue";
import { Permission } from "@/common/utils/Permissions";

export default [
  {
    path: "/appointment/",
    name: "appointment",
    component: Dashboard,
    children: [
      {
        path: "create",
        name: "loose-appointment-create",
        component: CreateLooseAppointmentView
      }
    ]
  },
  {
    path: "/weeklySchedule/service-engineer/:serviceEngineerId?/week/:weekDate",
    name: "weekly-schedule",
    component: WeeklySchedule,
    props: true,
    meta: {
      rightSideBar: true,
      permission: Permission.ShowTourPlan,
      userIdParam: "serviceEngineerId",
      skipPermission: Permission.EditTourPlan
    }
  },
  {
    path: "/tourDayMap/",
    name: "tour-day-map",
    component: TourDayMap,
    props: true,
    meta: {
      permission: Permission.ShowTourPlan
    }
  },
  {
    path: "/planning-overview/",
    name: "planning-overview",
    component: ServiceEngineerList,
    meta: {
      permission: Permission.EditTourPlan
    }
  },
  {
    path: "/map/service-engineer/:serviceEngineerId?/week/:weekDate",
    name: "appointment-map",
    component: AppointmentMap,
    props: true,
    meta: {
      permission: Permission.EditTourPlan
    }
  }
];
