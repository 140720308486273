import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { ItemTemplateStorageHandler } from "@/storage/storageHandlers/ItemTemplateStorageHandler";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { ItemTemplateService } from "../services/ItemTemplateService";
import { SupplierGraphQLService } from "../services/SupplierGraphQLService";

export class CreateItemTemplateController {
  private itemTemplateId?: string;

  public constructor(
    private presenter: ICreateItemTemplatePresenter,
    private itemTemplateService: ItemTemplateService,
    private supplierService: SupplierGraphQLService
  ) {}

  public async mounted(itemTemplateId?: string) {
    this.presenter.type = {
      selected: [],
      items: ItemTemplateStorageHandler.getAllItemTemplateTypes()
    };

    this.presenter.group = {
      selected: [],
      items: ItemTemplateStorageHandler.getAllItemTemplateGroups()
    };

    this.presenter.unit = {
      selected: [],
      items: ItemTemplateStorageHandler.getAllItemTemplateUnits()
    };

    FormRequestHandler.handle(
      this.supplierService.getSupplierNames(),
      response => {
        this.presenter.getSuppliersResponse = response;

        if (!response.loading && !response.error) {
          this.presenter.visibleView = true;
        }
      },
      "Konnte Artikel nicht laden!"
    );

    if (itemTemplateId) {
      this.itemTemplateId = itemTemplateId;

      FormRequestHandler.handle(
        this.itemTemplateService.getItemTemplateById(itemTemplateId),
        response => {
          this.presenter.getItemTemplateResponse = response;

          if (!response.loading && !response.error) {
            this.presenter.visibleView = true;
          }
        },
        "Konnte Artikel nicht laden!"
      );
    } else {
      this.presenter.visibleView = true;
    }
  }

  public closeOverlay() {
    this.presenter.visibleView = false;
  }

  public createItemTemplateButtonClicked() {
    if (this.itemTemplateId) {
      const data = { id: this.itemTemplateId, ...this.presenter.data };

      FormRequestHandler.handle(
        this.itemTemplateService.updateItemTemplate(data),
        response => (this.presenter.createItemTemplateResponse = response),
        "Could not create itemTemplate!"
      );
    } else {
      FormRequestHandler.handle(
        this.itemTemplateService.createItemTemplate(this.presenter.data),
        response => (this.presenter.createItemTemplateResponse = response),
        "Could not create itemTemplate!"
      );
    }
  }
}

export interface ICreateItemTemplatePresenter {
  visibleView: boolean;

  getSuppliersResponse: FormResponse<any[]>;
  getItemTemplateResponse: FormResponse<ItemTemplate>;

  type: { selected: string | string[]; items: SelectionItem[] };
  group: { selected: string | string[]; items: SelectionItem[] };
  unit: { selected: string | string[]; items: SelectionItem[] };
  data: any;
  createItemTemplateResponse: FormResponse<string>;
}
