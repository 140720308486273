import { ICreateLooseAppointmentPresenter } from "../controllers/CreateLooseAppointmentController";
import { CreateLooseAppointmentViewModel } from "@/tourPlanner/vms/CreateLooseAppointmentViewModel";
import { CountryList, Country } from "@/data/settings";
import { Form } from "@/forms/Form";
import { AppointmentForm } from "@/forms/appointment/AppointmentForm";
import { FormResponse } from "@/forms/FormResponse";
import { Plant } from "@/plant/services/PlantService";
import { LocalStorage } from "@/storage/LocalStorage";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { Auth } from "@/common/utils/Auth";
import { CustomerServiceReportStorageHandler } from "@/storage/storageHandlers/CustomerServiceReportStorageHandler";

export class CreateLooseAppointmentPresenter
  implements ICreateLooseAppointmentPresenter {
  public appointmentForm: AppointmentForm;

  public constructor(
    private vm: CreateLooseAppointmentViewModel,
    private storage: LocalStorage,
    private plant: Plant
  ) {
    this.appointmentForm = new AppointmentForm(
      this.vm,
      this.setAppointmentFormValid
    );

    this.appointmentForm.init();

    this.initSelections();

    if (!!plant) {
      this.initByPlant();
    }
  }

  public set visibleView(visible: boolean) {
    this.vm.visibleView = visible;
    if (!visible) {
      setTimeout(() => (this.vm.goBack = true), 300);
    }
  }

  public get usesDifferentAddressThanPlantAddress() {
    return this.vm.differentAddress.value;
  }

  public set differentAddress(value: boolean) {
    this.vm.differentAddress.value = value;
  }

  public set countries(countryList: CountryList) {
    this.vm.country.items = Form.generateSelectionList(countryList);
  }

  public set selectedCountry(country: Country) {
    this.vm.state.items = Form.generateSelectionList(country.states);
  }

  public set addressStepCompleted(completed: boolean) {
    this.vm.addressStepCompleted = completed;
    this.vm.formStep = completed ? 2 : 1;
  }

  public set createAppointmentResponse(response: FormResponse<string>) {
    this.vm.createAppointmentRequest = response;

    if (!response.loading && !response.error) {
      this.vm.createdAppointment = response.data;
    }
  }

  public setAddressFormValid(context: any, valid: boolean) {
    context.openOtherStepButtonDisabled = !valid;
  }

  public setAppointmentFormValid(context: any, valid: boolean) {
    context.createAppointmenttButtonDisabled = !valid;
  }

  public get appointmentProps() {
    const reasons = [];
    for (const reason of this.vm.reason.selected) {
      if (typeof reason === "string") {
        reasons.push(reason);
      } else if (typeof reason === "object") {
        let toAdd = (reason as any).text;
        if ((reason as any).value.additionalTextField) {
          toAdd += `: ${(reason as any).value.additionalText}`;
        }
        reasons.push(toAdd);
      }
    }
    const retObj: any = {
      date: this.vm.date.value,
      addressAddition: this.vm.addressRemarks,
      addressStreet: this.vm.street,
      addressZip: this.vm.zip,
      addressCity: this.vm.city,
      addressCountry: this.vm.country.selected,
      addressState: this.vm.state.selected,
      addressLat:
        this.usesDifferentAddressThanPlantAddress || !this.plant
          ? null
          : this.plant.addressLat,
      addressLng:
        this.usesDifferentAddressThanPlantAddress || !this.plant
          ? null
          : this.plant.addressLng,
      reason: reasons.join(", "),
      duration: this.vm.duration.value,
      tourPlannerId: Auth.userId,
      plantId: !!this.plant ? this.plant.id : null,
      sampleNeeded: this.vm.sampleNeeded.value,
      processingState: !!this.plant ? "OPEN" : "SELECTED"
    };

    if (!this.plant) {
      retObj.operatorPhoneNumber = this.vm.phoneNumber;
      retObj.plantType = `${this.vm.plantType.selected} ${this.vm.plantTypeAdditionalText}`;
      retObj.operatorName = this.vm.company
        ? this.vm.company
        : `${this.vm.firstName} ${this.vm.lastName.toUpperCase()}`;
      retObj.operatorAddress = this.parseAddress(
        this.vm.operatorAddressStreet,
        this.vm.operatorAddressZip,
        this.vm.operatorAddressCity,
        this.vm.operatorAddressCountry.selected,
        this.vm.operatorAddressState.selected
      );
      retObj.requestedBy = this.vm.requestedBy;
    }

    return retObj;
  }

  public countrySelected(value: string): void {
    this.vm.state.items = CountriesStorageHandler.getStatesFromCountry(value);
  }

  public operatorAddressCountrySelected(value: string): void {
    this.vm.operatorAddressState.items = CountriesStorageHandler.getStatesFromCountry(
      value
    );
  }

  private initByPlant() {
    if (!!this.plant.addressRemarks) {
      this.vm.addressRemarks = this.plant.addressRemarks;
    }
    this.vm.street = this.plant.addressStreet;
    this.vm.zip = this.plant.addressZip;
    this.vm.city = this.plant.addressCity;
    this.vm.country.selected = this.plant.addressCountry;
    this.countrySelected(this.vm.country.selected);
    this.vm.state.selected = this.plant.addressState;

    this.appointmentForm.setFieldValue(
      "sampleNeeded",
      this.plant.sampleNeeded ? "true" : "false"
    );
  }

  private initSelections() {
    this.vm.operatorAddressCountry.items = CountriesStorageHandler.getAllCountries();
    this.vm.country.items = CountriesStorageHandler.getAllCountries();
    this.vm.reason.items = CustomerServiceReportStorageHandler.getAllReasons();
    this.vm.plantType.items = CustomerServiceReportStorageHandler.getAllPlantTypes();
  }

  private parseAddress(
    street: string,
    zip: string,
    city: string,
    country: string,
    state: string
  ) {
    const parsedCountry = CountriesStorageHandler.getCountryNameFromId(country);
    return `${street}\n${zip} ${city}\n${state}\n${parsedCountry}`;
  }
}
