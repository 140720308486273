






























































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationProvider } from "vee-validate";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import Datatable from "@/components/collection/Datatable.vue";
import Combobox from "@/components/form/Combobox.vue";

import { DefectsTableViewModel } from "@/report/vms/DefectsTableViewModel";
import { DefectsTablePresenter } from "@/report/presenters/DefectsTablePresenter";
import { DefectsTableController } from "@/report/controllers/DefectsTableController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";

@Component<DefectsTableView>({
  components: {
    ValidationProvider,
    Card,
    Container,
    Column,
    Row,
    Datatable,
    Combobox
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    "vm.defects"(newVal) {
      (this.$refs.vprovider as any).validate(newVal);
    }
  }
})
export default class DefectsTableView extends Vue {
  private vm = new DefectsTableViewModel();
  private items!: any[];

  private get controller(): DefectsTableController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new DefectsTableController(new DefectsTablePresenter(this.vm));
  }

  private mounted() {
    this.controller.mounted(this.items);
  }
}
