import { IOperatorService, Operator } from "../services/OperatorGraphQLService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";

export class OperatorDataController {
  public constructor(
    private presenter: IOperatorDataPresenter,
    private operatorService: IOperatorService
  ) {}

  public mounted(operatorId: string) {
    const request = this.operatorService.getOperatorById(operatorId);
    FormRequestHandler.handle(
      request,
      response => {
        this.presenter.getOperatorResponse = response;
      },
      "get-operator-failed"
    );
  }

  public countryName(countryId: string) {
    return CountriesStorageHandler.getCountryNameFromId(countryId);
  }

  public areaCode(countryId: string) {
    return CountriesStorageHandler.getAreaCodeById(countryId);
  }

  public formateCompanyToName(
    firstName: string,
    lastName: string,
    company: string
  ) {
    if (!!firstName || !!lastName) {
      return `${firstName} ${lastName}`;
    } else {
      return company?.replace("\n", "<br />") || "";
    }
  }

  public phoneRemarks(remarks: string) {
    if (!!remarks) {
      return `(${remarks})`;
    } else {
      return "";
    }
  }
}

export interface IOperatorDataPresenter {
  operator: Operator;
  getOperatorResponse: FormResponse<Operator>;
}
