import { Request, TableHeader } from "@/forms/ViewModelFormTypes";
import { SearchResult } from "../services/SearchService";

export class SearchViewModel {
  public searchText = "";
  public itemsPerPage = 10;
  public pageNumber = 1;

  public searchRequest: Request = { loading: false, error: "" };

  public items: SearchResult[] = [];
  public totalItems: number = 10;

  public otherItems: SearchResult[] = [];

  public plantHeaders: TableHeader[] = [
    { text: "#", value: "index", sortable: false },
    { text: "Gruppe", value: "extra", sortable: false },
    { text: "Standort", value: "address", sortable: false }
  ];
  public operatorHeaders: TableHeader[] = [
    { text: "Name", value: "extra", sortable: false },
    { text: "Adresse", value: "address", sortable: false }
  ];
}
