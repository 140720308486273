import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { IPlantService, PlantContract } from "../services/PlantService";
import { ContractForm } from "../forms/ContractForm";
import { FormResponse } from "@/forms/FormResponse";

export class PlantContractController {
  public constructor(
    private presenter: IPlantContractPresenter,
    private plantId: string,
    private plantService: IPlantService
  ) {
    this.getExistingContract();
  }

  public mounted() {
    this.presenter.visibleView = true;
  }

  public closeButtonClicked(): void {
    this.presenter.visibleView = false;
  }

  public hasContractChanged(newValue: boolean) {
    this.presenter.contractForm.setFieldValue(
      "hasContract",
      newValue ? "true" : "false"
    );
  }

  public dateChanged(newValue: string) {
    this.presenter.contractForm.setFieldValue("date", newValue);
  }

  public doneByChanged(newValue: string) {
    this.presenter.contractForm.setFieldValue("doneBy", newValue);
  }

  public maintenanceNumberChanged(newValue: string) {
    this.presenter.contractForm.setFieldValue("maintenanceNumber", newValue);
  }

  public maintenanceCostChanged(newValue: string) {
    this.presenter.contractForm.setFieldValue("maintenanceCost", newValue);
  }

  public priceSampleChanged(newValue: string) {
    this.presenter.contractForm.setFieldValue("priceSample", newValue);
  }

  public baseIndexChanged(newValue: string) {
    this.presenter.contractForm.setFieldValue("baseIndex", newValue);
  }

  public startIndexChanged(newValue: string) {
    this.presenter.contractForm.setFieldValue("startIndex", newValue);
  }

  public currentIndexChanged(newValue: string) {
    this.presenter.contractForm.setFieldValue("currentIndex", newValue);
  }

  public dueDateChanged(newValue: string) {
    this.presenter.contractForm.setFieldValue("dueDate", newValue);
  }

  public firstAppointmentChanged(newValue: string) {
    this.presenter.contractForm.setFieldValue("firstAppointment", newValue);
  }

  public maintenanceIntervalTextChanged(newValue: string) {
    this.presenter.contractForm.setFieldValue("maintenanceInterval", newValue);
  }

  public contractQuittedChanged(newValue: boolean) {
    this.presenter.contractForm.setFieldValue(
      "contractQuitted",
      newValue ? "true" : "false"
    );
  }

  public quitDateChanged(newValue: string) {
    this.presenter.contractForm.setFieldValue("quitDate", newValue);
  }

  public remarksChanged(newValue: string) {
    this.presenter.contractForm.setFieldValue("remarks", newValue);
  }

  public createContractButtonClicked() {
    const request = this.plantService.upsertContract(
      this.plantId,
      this.presenter.contract
    );
    FormRequestHandler.handle(
      request,
      response => (this.presenter.createContractResponse = response),
      "upsert-contract-failed"
    );
  }

  private getExistingContract() {
    const request = this.plantService.getPlantContractByPlantId(this.plantId);
    FormRequestHandler.handle(
      request,
      response => (this.presenter.existingContractResponse = response),
      "upsert-contract-failed"
    );
  }
}

export interface IPlantContractPresenter {
  visibleView: boolean;
  contractForm: ContractForm;
  contract: any;
  createContractResponse: FormResponse<string>;
  existingContractResponse: FormResponse<PlantContract>;
}
