import { StorageHandler } from "./StorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import SystemKeys from "@/common/utils/SystemKeys";

export class VpiStorageHandler extends StorageHandler {
  public static getAllVpis(): SelectionItem[] {
    return super.buildTopLevelSelection(
      SystemKeys.vpiTableKey,
      SystemKeys.vpiNameKey
    );
  }

  public static getVpiLabel(id: string): string {
    return super.getName(SystemKeys.vpiTableKey, id);
  }
}
