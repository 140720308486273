import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import {
  GraphQLConnection,
  OrderByClause
} from "@/gateways/graphql/GraphQLConnection";

export class StoreGraphQLService {
  public constructor(private connection: GraphQLConnection) {}

  public async getAllStores(): Promise<Store[]> {
    const request = await this.connection.query("stores", {}, [
      "id",
      "employeeId",
      "name",
      "description"
    ]);

    return request.data;
  }

  public async getAllStoresPaginated(
    page: Page,
    order: OrderByClause[],
    search: string
  ): Promise<PaginatedList<Store>> {
    const request = await this.connection.queryPaginated(
      "storesPaginated",
      page.itemsPerPage,
      page.page,
      [
        "id",
        "employeeId",
        "name",
        "description",
        {
          name: "employee",
          fields: ["lastname"]
        }
      ],
      search,
      ["name", "employee.lastname"],
      order
    );

    return {
      items: request.data,
      totalCount: request.count
    };
  }

  public async getStoreById(id: string) {
    const request = await this.connection.query(
      "store",
      {
        id
      },
      ["id", "employeeId", "name", "description"]
    );

    return request.data;
  }

  public async updateStore(input: any) {
    const request = await this.connection.mutation(
      "updateStore",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async createStore(input: any) {
    const request = await this.connection.mutation(
      "createStore",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async deleteStore(id: string) {
    const request = await this.connection.mutation(
      "deleteStore",
      {
        id
      },
      []
    );

    return request.data;
  }
}

export interface Store {
  id: string;
  employeeId: string;
  name: string;
  description: string;
}
