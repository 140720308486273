






import Vue from "vue";
import Component from "vue-class-component";

@Component<Subtitle>({})
export default class Subtitle extends Vue {}
