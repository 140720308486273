















import Vue from "vue";
import Component from "vue-class-component";

@Component<InfoDialog>({
  props: {
    title: {
      type: String,
      default: "Titel"
    },
    confirmText: {
      type: String,
      default: "OK"
    },
    value: {
      type: Boolean,
      default: false
    }
  }
})
export default class InfoDialog extends Vue {
  private confirm() {
    this.$emit("confirm");
  }
}
