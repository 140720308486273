import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { ItemTemplateService } from "../services/ItemTemplateService";
import {
  MaterialRequest,
  MaterialRequestGraphQLService
} from "../services/MaterialRequestGraphQLService";

export class CreateMaterialRequestController {
  public constructor(
    private presenter: ICreateMaterialRequestPresenter,
    private materialRequestService: MaterialRequestGraphQLService,
    private itemTemplateService: ItemTemplateService
  ) {}

  public mounted(materialRequestId?: string) {
    this.presenter.readonly = !!materialRequestId;

    FormRequestHandler.handle(
      this.itemTemplateService.getAllItemTemplates(),
      response => (this.presenter.getItemTemplatesResponse = response),
      "Konnte Artikel nicht laden!"
    );

    if (materialRequestId) {
      FormRequestHandler.handle(
        this.materialRequestService.getMaterialRequestById(materialRequestId),
        response => {
          this.presenter.getMaterialRequestResponse = response;

          if (!response.loading && !response.error) {
            this.presenter.visibleView = true;
          }
        },
        "Konnte Materialanforderung nicht laden!"
      );
    } else {
      this.presenter.visibleView = true;
    }
  }

  public closeOverlay() {
    this.presenter.visibleView = false;
  }

  public itemTemplateSelected(selected: SelectionItem) {
    this.presenter.itemTemplateSelected(selected);
  }

  public createMaterialRequestButtonClicked() {
    FormRequestHandler.handle(
      this.materialRequestService.createMaterialRequest(this.presenter.data),
      response => (this.presenter.createMaterialRequestResponse = response),
      "Konnte Materialanforderung nicht erstellen!"
    );
  }
}

export interface ICreateMaterialRequestPresenter {
  visibleView: boolean;
  readonly: boolean;

  itemTemplateDict: { [key: string]: string };

  getMaterialRequestResponse: FormResponse<MaterialRequest>;
  getItemTemplatesResponse: FormResponse<ItemTemplate[]>;

  data: any;
  createMaterialRequestResponse: FormResponse<string>;

  itemTemplateSelected(selected: SelectionItem): void;
}
