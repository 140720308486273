var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{attrs:{"title":"Indexberechnung","actionText":_vm.submitted ? 'Excel-Download' : '',"actionIcon":"cloud_download"},on:{"action":function($event){return _vm.exportExcel()}}},[_c('Section',{attrs:{"color":_vm.submitted ? 'white' : 'transparent'}},[_c('VpiCalculationForm',{attrs:{"dense":_vm.submitted},on:{"submit":function($event){return _vm.load($event)}}})],1),(_vm.loaded)?_c('Section',{staticClass:"mt-4"},[_c('Datatable',{attrs:{"headers":_vm.headers,"items":_vm.filteredPlants,"itemClass":_vm.styleRow,"height":"calc(100vh - 300px)","fixedHeader":""},on:{"row-clicked":function($event){return _vm.editRow($event, 'newContractCost')}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ma-0 pointer",on:{"click":function($event){return _vm.goToPlant(item)}}},[_vm._v(" "+_vm._s(item.index)+" ")])]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ma-0 font-weight-bold pointer",class:_vm.editedPlant === item ? 'primary--text' : 'black--text',on:{"click":function($event){return _vm.goToPlantContract(item)}}},[_vm._v(" "+_vm._s(item.mainOperator.label)+" ")]),_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(item.address))])]}},{key:"item.contractPrice",fn:function(ref){
var item = ref.item;
return [(!_vm.isAccepted(item.id))?_c('p',{staticClass:"ma-0 text-no-wrap"},[_vm._v(" Alt: "),_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.plantContract.maintenanceCostStr))])]):_vm._e(),(_vm.isAccepted(item.id) || _vm.editedPlant !== item)?_c('p',{staticClass:"ma-0 text-no-wrap",class:((!_vm.isAccepted(item.id)
                ? 'green--text pointer'
                : 'font-weight-bold') + " " + (_vm.hasChange(item.id, 'newContractCost') ? 'font-weight-bold' : '')),on:{"click":function($event){$event.stopPropagation();return _vm.editRow(item, 'newContractCost')}}},[(!_vm.isAccepted(item.id))?_c('span',[_vm._v("Neu: ")]):_vm._e(),_vm._v(_vm._s(_vm.getCalculation(item.id).newContractCostStr)+" ")]):_c('Textfield',{staticStyle:{"width":"100px"},attrs:{"value":_vm.newContractCost,"autofocus":_vm.focusField === 'newContractCost',"extraFlat":"","number":"","backgroundColor":"blue-grey lighten-4"},on:{"change":function($event){return _vm.changeField('newContractCost', $event)},"focus":function($event){return $event.target.select()}}})]}},{key:"item.samplePrice",fn:function(ref){
            var item = ref.item;
return [(item.plantContract.priceSample > 0 && !_vm.isAccepted(item.id))?_c('p',{staticClass:"ma-0 text-no-wrap"},[_vm._v(" Alt: "),_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.plantContract.priceSampleStr))])]):_vm._e(),(
            _vm.getCalculation(item.id).newSampleCost > 0 && _vm.editedPlant !== item
          )?_c('p',{staticClass:"ma-0 text-no-wrap",class:((!_vm.isAccepted(item.id)
                ? 'green--text pointer'
                : 'font-weight-bold') + " " + (_vm.hasChange(item.id, 'newSampleCost') ? 'font-weight-bold' : '')),on:{"click":function($event){$event.stopPropagation();return _vm.editRow(item, 'newSampleCost')}}},[(!_vm.isAccepted(item.id))?_c('span',[_vm._v("Neu: ")]):_vm._e(),_vm._v(_vm._s(_vm.getCalculation(item.id).newSampleCostStr)+" ")]):_vm._e(),(_vm.editedPlant === item)?_c('Textfield',{staticStyle:{"width":"100px"},attrs:{"value":_vm.newSampleCost,"extraFlat":"","number":"","autofocus":_vm.focusField === 'newSampleCost',"backgroundColor":"blue-grey lighten-4"},on:{"change":function($event){return _vm.changeField('newSampleCost', $event)},"focus":function($event){return $event.target.select()}}}):_vm._e()]}},{key:"item.baseIndex",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.resolveVpi(item.plantContract.baseIndex)))])]}},{key:"item.startIndex",fn:function(ref){
            var item = ref.item;
return [(_vm.editedPlant !== item)?_c('span',{staticClass:"text-no-wrap",class:((!_vm.isAccepted(item.id) ? 'green--text pointer' : '') + " " + (_vm.hasChange(item.id, 'startIndex') ? 'font-weight-bold' : '')),on:{"click":function($event){$event.stopPropagation();return _vm.editRow(item, 'startIndex')}}},[_vm._v(_vm._s(_vm.getCalculation(item.id).startIndex))]):_c('Textfield',{staticStyle:{"width":"100px"},attrs:{"value":_vm.startIndex,"extraFlat":"","number":"","autofocus":_vm.focusField === 'startIndex',"backgroundColor":"blue-grey lighten-4"},on:{"change":function($event){return _vm.changeField('startIndex', $event)},"focus":function($event){return $event.target.select()},"blur":function($event){return _vm.lostFocusOfLastField()}}})]}},{key:"item.indexChange",fn:function(ref){
            var item = ref.item;
return [(!_vm.isAccepted(item.id))?_c('p',{staticClass:"ma-0 text-no-wrap"},[_vm._v(" Alt: "),_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(_vm._s(item.plantContract.currentIndex.toFixed(2)))])]):_vm._e(),_c('p',{staticClass:"ma-0 text-no-wrap",class:_vm.isAccepted(item.id) ? 'font-weight-bold' : ''},[(!_vm.isAccepted(item.id))?_c('span',[_vm._v("Neu: ")]):_vm._e(),_vm._v(_vm._s(_vm.input.targetIndex.toFixed(2))+" ")])]}},{key:"item.increasePercent",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.calculations[item.id].increasePercent.toFixed(2))+" %")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"ma-0 primary--text text-decoration-underline font-weight-bold pointer",on:{"click":function($event){$event.stopPropagation();return _vm.processVpi(item)}}},[_vm._v(" "+_vm._s(_vm.isAccepted(item.id) ? "Rückgängig" : "Speichern")+" ")]),(_vm.isAccepted(item.id))?_c('p',{staticClass:"ma-0 mt-1 secondary--text text-decoration-underline font-weight-bold pointer",on:{"click":function($event){$event.stopPropagation();return _vm.remove(item)}}},[_vm._v(" Ausblenden ")]):(_vm.hasChange(item.id))?_c('p',{staticClass:"ma-0 mt-1 grey--text text-decoration-underline font-weight-bold pointer",on:{"click":function($event){$event.stopPropagation();return _vm.undo(item)}}},[_vm._v(" Zurücks. ")]):_vm._e()]}}],null,true)})],1):_vm._e(),_c('div',{staticStyle:{"opacity":"0"}},[_c('Textfield')],1),_c('InfoDialog',{attrs:{"title":"Fehler","value":!!_vm.errorMessage},on:{"confirm":function($event){_vm.errorMessage = ''}}},[_vm._v(_vm._s(_vm.errorMessage))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }