import SystemKeys from "@/common/utils/SystemKeys";
import { LocalStorage } from "@/storage/LocalStorage";

export class StoreMovementStorage {
  private localStorage = new LocalStorage();

  private itemsToSave: StoreMovementsToSave = {
    timestamp: 0,
    storeMovements: []
  };

  constructor() {
    const entries = this.localStorage.get(SystemKeys.storedStoreMovementsKey);
    if (!!entries) {
      this.itemsToSave = JSON.parse(entries);
    }
  }

  public set storeMovements(storeMovements: any[]) {
    this.itemsToSave.storeMovements = storeMovements.map(sm => ({
      changed: false,
      entity: sm
    }));
    this.itemsToSave.timestamp = new Date().getTime();
  }

  public get storeMovements() {
    return this.itemsToSave.storeMovements.map(sm => {
      return sm.entity;
    });
  }

  public get timestamp() {
    return this.itemsToSave.timestamp;
  }

  public add(input: any) {
    this.itemsToSave.storeMovements.push({
      changed: true,
      entity: input
    });
    this.save();
  }

  public save() {
    this.localStorage.set(
      SystemKeys.storedStoreMovementsKey,
      JSON.stringify(this.itemsToSave)
    );
  }

  public getToSyncStoreMovements() {
    this.load();
    return this.itemsToSave.storeMovements.filter(fe => fe.changed);
  }

  private load() {
    const entries = this.localStorage.get(SystemKeys.storedStoreMovementsKey);
    if (!!entries) {
      this.itemsToSave = JSON.parse(entries);
    }
  }
}

interface StoreMovementsToSave {
  timestamp: number;
  storeMovements: Array<FlaggedEntity<any>>;
}

interface FlaggedEntity<T> {
  changed: boolean;
  entity: T;
}
