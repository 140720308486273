var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.element.value)?_c('Card',{attrs:{"title":_vm.element.label,"loading":_vm.plantRequest.loading,"error":_vm.plantRequest.error,"small":""},on:{"retry":function($event){return _vm.retry($event)}}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"x-small":"","text":"","icon":"","color":"white"},on:{"click":function($event){return _vm.addStoreMovementsButtonClicked()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("add")])],1)],1),_c('server-datatable',{attrs:{"headers":_vm.vm.headers,"mobileHeaders":_vm.vm.headers,"tabletHeaders":_vm.vm.headers,"itemsPerPage":_vm.vm.itemsPerPage,"items":_vm.vm.items,"totalItems":_vm.vm.totalItems,"value":_vm.vm.pageNumber,"options":_vm.vm.options,"multisort":true,"loading":_vm.vm.loading,"dense":""},on:{"sort":function($event){return _vm.controller.tableSorted($event)},"input":function($event){return _vm.controller.pageChanged($event)}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.controller.parseDate(item.date))+" ")]}},{key:"item.store",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.controller.parseStore(item))+" ")]}},{key:"item.deliveryWay",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.controller.parseDeliveryWay(item.deliveryWay))+" ")]}},{key:"item.itemName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.itemTemplate ? item.itemTemplate.name : item.itemTemplateId)+" ")]}}],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }