import SystemKeys from "@/common/utils/SystemKeys";
import { LocalStorage } from "@/storage/LocalStorage";
import { Store } from "../services/StoreGraphQLService";

export class StoreStorage {
  private localStorage = new LocalStorage();

  private itemsToSave: StoresToSave = {
    timestamp: 0,
    stores: []
  };

  constructor() {
    const entries = this.localStorage.get(SystemKeys.storedStoresKey);
    if (!!entries) {
      this.itemsToSave = JSON.parse(entries);
    }
  }

  public set stores(stores: Store[]) {
    this.itemsToSave.stores = stores.map(s => ({
      changed: false,
      entity: s
    }));
    this.itemsToSave.timestamp = new Date().getTime();
  }

  public get stores() {
    return this.itemsToSave.stores.map(s => {
      return s.entity;
    });
  }

  public get timestamp() {
    return this.itemsToSave.timestamp;
  }

  public save() {
    this.localStorage.set(
      SystemKeys.storedStoresKey,
      JSON.stringify(this.itemsToSave)
    );
  }

  public getToSyncStores() {
    this.load();
    return this.itemsToSave.stores.filter(fe => fe.changed);
  }

  private load() {
    const entries = this.localStorage.get(SystemKeys.storedStoresKey);
    if (!!entries) {
      this.itemsToSave = JSON.parse(entries);
    }
  }
}

interface StoresToSave {
  timestamp: number;
  stores: Array<FlaggedEntity<Store>>;
}

interface FlaggedEntity<T> {
  changed: boolean;
  entity: T;
}
