export class Address {
  public id: string;
  public addressAddition: string = "";
  public street: string = "";
  public zip: string = "";
  public city: string = "";
  public country: string = "";
  public state: string = "";

  public constructor(id = "") {
    this.id = id;
  }
}
