import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { IPlantService, Plant } from "../services/PlantService";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import StorageKeys from "@/common/utils/StorageKeys";

export class PlantController {
  public constructor(
    private id: string,
    private presenter: IPlantPresenter,
    private plantService: IPlantService,
    private storage: SimpleStringStorage
  ) {}

  public init() {
    this.loadPreferences();
    this.getPlantData();
  }

  public getPlantData() {
    const request = this.plantService.getPlantById(this.id);
    FormRequestHandler.handle(
      request,
      response => {
        this.presenter.plantResponse = response;
        if (!!response.data.addressStreet) {
          this.presenter.location = {
            lat: response.data.addressLat,
            lng: response.data.addressLng
          };
        }
      },
      "get-plant-failed"
    );
  }

  public savePreferences() {
    this.storage.set(
      StorageKeys.plantDataFilters,
      JSON.stringify(
        this.presenter.filters.map(el => ({ label: el.label, value: el.value }))
      )
    );
  }

  public loadPreferences() {
    if (this.storage.has(StorageKeys.plantDataFilters)) {
      for (const entry of JSON.parse(
        this.storage.get(StorageKeys.plantDataFilters)
      )) {
        const index = this.presenter.filters.findIndex(
          el => el.label === entry.label
        );
        if (index >= 0) {
          this.presenter.filters[index].value = entry.value;
        }
      }
    }
  }

  public formateCompanyToName(
    firstName: string,
    lastName: string,
    company: string
  ) {
    if (
      (!!firstName && !!lastName) ||
      (!!firstName && !lastName) ||
      (!firstName && !!lastName)
    ) {
      return `${firstName} ${lastName}`;
    } else {
      return company.split("\n")[0];
    }
  }
}

export interface IPlantPresenter {
  plantResponse: FormResponse<Plant>;
  location: any;
  filters: any[];
}
