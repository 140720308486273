






























































import Vue from "vue";
import Component from "vue-class-component";

import IconButton from "@/components/basic/IconButton.vue";

@Component<Datatable>({
  components: {
    IconButton
  },
  props: {
    headers: {
      type: Array,
      default() {
        return [];
      }
    },
    tabletHeaders: {
      type: Array,
      default() {
        return [];
      }
    },
    mobileHeaders: {
      type: Array,
      default() {
        return [];
      }
    },
    fixedHeader: {
      type: Boolean,
      default: false
    },
    height: {
      type: [String, Number],
      default: undefined
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    loading: {
      type: Boolean,
      default: false
    },
    itemClass: {
      type: [String, Function]
    }
  }
})
export default class Datatable extends Vue {
  private get tableHeaders() {
    return this.$vuetify.breakpoint.lgAndUp
      ? this.$props.headers
      : this.$vuetify.breakpoint.xsOnly
      ? this.$props.mobileHeaders.map((header: any) => {
          const mobileKey = "mobile";
          header[mobileKey] = true;
          return header;
        })
      : this.$props.tabletHeaders.length > 0
      ? this.$props.tabletHeaders.map((header: any) => {
          const tabletKey = "tablet";
          header[tabletKey] = true;
          return header;
        })
      : this.$props.headers;
  }

  public rowClicked(event: any) {
    this.$emit("row-clicked", event);
  }
}
