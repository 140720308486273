import { ICreateWorkOrderPresenter } from "../controllers/CreateWorkOrderController";
import { WorkOrderGeneralDataForm } from "@/forms/workOrder/WorkOrderGeneralDataForm";
import { WorkOrderDefectsDataForm } from "@/forms/workOrder/WorkOrderDefectsDataForm";
import { WorkOrderReportDataForm } from "@/forms/workOrder/WorkOrderReportDataForm";
import { WorkOrderSketchDataForm } from "@/forms/workOrder/WorkOrderSketchDataForm";
import {
  CreateWorkOrderViewModel,
  Section
} from "../vms/CreateWorkOrderViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { PlantGroupStorageHandler } from "@/storage/storageHandlers/PlantGroupStorageHandler";
import { DynamicForm } from "@/forms/DynamicForm";
import { FormFieldType } from "@/forms/Form";
import {
  Textfield,
  MultiSelect,
  Checkbox,
  Date as DateType,
  Selection
} from "@/forms/ViewModelFormTypes";
import { DateUtils } from "@/utils/DateUtils";
import {
  ParameterStorageHandler,
  PlantParameter
} from "@/storage/storageHandlers/ParameterStorageHandler";
import { WorkOrder } from "../services/WorkOrderService";
import { Auth } from "@/common/utils/Auth";
import { Employee } from "@/employee/services/EmployeeService";
import { MaterialStorageHandler } from "@/storage/storageHandlers/MaterialStorageHandler";
import { TaskStorageHandler } from "@/storage/storageHandlers/TasksStorageHandler";

export class CreateWorkOrderPresenter implements ICreateWorkOrderPresenter {
  public generalDataForm: WorkOrderGeneralDataForm;
  public defectsDataForm: WorkOrderDefectsDataForm;
  public reportDataForm: WorkOrderReportDataForm;
  public signatureDataForm: WorkOrderSketchDataForm;

  public constructor(private vm: CreateWorkOrderViewModel) {
    this.generalDataForm = new WorkOrderGeneralDataForm(
      this.vm,
      this.setWorkOrderGeneralFormValid
    );
    this.defectsDataForm = new WorkOrderDefectsDataForm(
      this.vm,
      this.setWorkOrderDefectsFormValid
    );
    this.reportDataForm = new WorkOrderReportDataForm(
      this.vm,
      this.setWorkOrderReportFormValid
    );
    this.signatureDataForm = new WorkOrderSketchDataForm(
      this.vm,
      this.setWorkOrderSketchFormValid
    );

    this.generalDataForm.init();
    this.defectsDataForm.init();
    this.reportDataForm.init();
    this.signatureDataForm.init();

    this.vm.usedMaterial.items = MaterialStorageHandler.getAllMaterials().map(
      el => ({
        text: el.text,
        value: el.text
      })
    );
    this.vm.doneTasks.items = TaskStorageHandler.getAllTasks().map(el => ({
      text: el.text,
      value: el.text
    }));
  }

  public set getWorkOrderResponse(response: FormResponse<WorkOrder>) {
    this.vm.getWorkOrderLoading = response.loading;
    this.vm.getWorkOrderError = response.error;

    if (!response.loading && !response.error) {
      this.vm.workOrder = response.data;
      if (!response.data.plantId) {
        this.vm.tabs.headers.splice(0, 0, "Anlagendaten");
        this.vm.tabs.components.splice(0, 0, "PlantDataTab");
        this.vm.tabs.headers.splice(0, 0, "Betreiberdaten");
        this.vm.tabs.components.splice(0, 0, "OperatorDataTab");
      }
    }
  }

  public initReport(workOrder: WorkOrder, parameters: PlantParameter[]) {
    this.vm.workOrder = workOrder;
    this.vm.workOrderNoPriceTab =
      workOrder.processingState === "OPEN" ||
      workOrder.processingState === "REJECTED" ||
      workOrder.processingState === "TO_CHECK";
    if (
      Auth.hasPermission("approveWorkOrders") &&
      !this.vm.workOrderNoPriceTab
    ) {
      this.vm.tabs.headers.splice(2, 0, "Arbeitsauftragspreise");
      this.vm.tabs.components.splice(2, 0, "WorkOrderPriceTab");
    }

    if (workOrder.processingState === "FINISHED") {
      this.vm.priceHkEditable = true;
    }

    if (!workOrder.sampleNeeded) {
      return;
    }

    this.vm.tabs.headers.unshift("Prüfbericht");
    this.vm.tabs.components.unshift("ReportTab");

    this.vm.workOrderDate = workOrder.plannedDate;

    this.vm.allValid = !(
      this.vm.openDefectsDataStepButtonDisabled ||
      this.vm.openGeneralDataStepButtonDisabled ||
      this.vm.openSketchDataStepButtonDisabled ||
      this.vm.createWorkOrderButtonDisabled
    );
  }

  public set error(error: string) {
    this.vm.error = error;
  }

  public set loading(loading: boolean) {
    this.vm.loading = loading;
  }

  public get loading() {
    return this.vm.loading;
  }

  public set serviceEngineer(employee: Employee) {
    this.vm.sampleTaker = `${employee.firstname} ${employee.lastname}`;
  }

  public set sampleTaker(name: string) {
    this.vm.sampleTaker = name;
  }

  public set cannotTakeSample(enabled: boolean) {
    this.vm.cannotTakeSample.value = enabled;
    this.vm.openDefectsDataStepButtonDisabled = !enabled;
    this.vm.allValid = !(
      this.vm.openDefectsDataStepButtonDisabled ||
      this.vm.openGeneralDataStepButtonDisabled ||
      this.vm.openSketchDataStepButtonDisabled ||
      this.vm.createWorkOrderButtonDisabled
    );
  }

  public get cannotTakeSample(): boolean {
    return this.vm.cannotTakeSample.value;
  }

  public set cannotTakeSignature(enabled: boolean) {
    this.vm.cannotTakeSignature.value = enabled;
    this.vm.createWorkOrderButtonDisabled = !enabled;
    this.vm.allValid = !(
      this.vm.openDefectsDataStepButtonDisabled ||
      this.vm.openGeneralDataStepButtonDisabled ||
      this.vm.openSketchDataStepButtonDisabled ||
      this.vm.createWorkOrderButtonDisabled
    );
  }

  public get cannotTakeSignature(): boolean {
    return this.vm.cannotTakeSignature.value;
  }

  public set createWorkOrderButtonDisabled(value: boolean) {
    this.vm.createWorkOrderButtonDisabled = value;
    this.vm.allValid = !(
      this.vm.openDefectsDataStepButtonDisabled ||
      this.vm.openGeneralDataStepButtonDisabled ||
      this.vm.openSketchDataStepButtonDisabled ||
      this.vm.createWorkOrderButtonDisabled
    );
  }

  public get rejectionReason(): string {
    return this.vm.rejectionReason;
  }

  public get substituteDate(): DateType {
    return this.vm.substituteDate;
  }

  public set substituteDate(date: DateType) {
    this.vm.substituteDate = date;
  }

  public get contract(): any {
    return this.vm.plantContract;
  }

  public set contract(contract: any) {
    this.vm.plantContract = contract;
  }

  public get workOrderId(): string {
    return this.vm.workOrderId;
  }
  public set workOrderId(id: string) {
    this.vm.workOrderId = id;
  }

  public get plantRemarks(): string {
    return this.vm.plantRemarks;
  }

  public set plantRemarks(remarks: string) {
    this.vm.plantRemarks = remarks;
  }

  public get workOrderPrice(): number | null {
    return this.vm.workOrderPrice;
  }

  public set workOrderPrice(workOrderPrice: number | null) {
    this.vm.workOrderPrice = workOrderPrice;
  }

  public get plantContract(): any {
    return this.vm.plantContract;
  }

  public set plantContract(contract: any) {
    this.vm.plantContract = contract;
  }

  public get workOrderHk(): number {
    return this.vm.workOrderHK;
  }

  public set workOrderHk(workOrderHk: number) {
    this.vm.workOrderHK = workOrderHk;
  }

  public get workOrderProcessingState(): string {
    return this.vm.workOrderProcessingState;
  }

  public set workOrderProcessingState(workOrderProcessingState: string) {
    this.vm.workOrderProcessingState = workOrderProcessingState;
  }

  public set plantType(plantType: string) {
    this.vm.plantType = plantType;
  }

  public set plantId(plantId: string) {
    this.vm.plantId = plantId;
    if (!!plantId) {
      this.vm.plantSet = true;
    }
  }

  public set plantIndex(plantIndex: string) {
    this.vm.plantIndex = plantIndex;
  }

  public set operatorIds(operatorIds: string[]) {
    this.vm.flatOperators = [];
    for (const id of operatorIds) {
      this.vm.flatOperators.push({ id });
    }
  }

  public setFlatOperator(operator: any) {
    const foundOpIndex = this.vm.flatOperators.findIndex(
      fo => fo.id === operator.id
    );
    if (foundOpIndex >= 0) {
      this.vm.flatOperators.splice(foundOpIndex, 1);
      this.vm.flatOperators.push({ id: operator.id, index: operator.index });
    }
  }

  public get estimatedTime(): number {
    return this.vm.estimatedTime;
  }

  public set estimatedTime(estimatedTime: number) {
    this.vm.estimatedTime = estimatedTime;
  }

  public set activeTabIndex(activeTabIndex: number) {
    this.vm.activeTabIndex = activeTabIndex;
  }

  public get activeTabIndex(): number {
    return this.vm.activeTabIndex;
  }

  public get showSetToCheckDialog(): boolean {
    return this.vm.showSetToCheckDialog;
  }

  public set showSetToCheckDialog(showSetToCheckDialog: boolean) {
    this.vm.showSetToCheckDialog = showSetToCheckDialog;
  }

  public set showRejectionReasonDialog(showRejectionReasonDialog: boolean) {
    this.vm.showRejectionReasonDialog = showRejectionReasonDialog;
  }

  public set generalDataStepCompleted(completed: boolean) {
    this.vm.generalDataStepCompleted = completed;
    this.vm.formStep = completed ? 2 : 1;
  }

  public set defectsDataStepCompleted(completed: boolean) {
    this.vm.defectsDataStepCompleted = completed;
    this.vm.formStep = completed ? 3 : 2;
  }

  public setWorkOrderReportFormValid(context: any, valid: boolean) {
    context.openDefectsDataStepButtonDisabled = !valid;
    context.allValid = !(
      context.openDefectsDataStepButtonDisabled ||
      context.openGeneralDataStepButtonDisabled ||
      context.openSketchDataStepButtonDisabled ||
      context.createWorkOrderButtonDisabled
    );
  }

  public setWorkOrderDefectsFormValid(context: any, valid: boolean) {
    context.openGeneralDataStepButtonDisabled = !valid;
    context.allValid = !(
      context.openDefectsDataStepButtonDisabled ||
      context.openGeneralDataStepButtonDisabled ||
      context.openSketchDataStepButtonDisabled ||
      context.createWorkOrderButtonDisabled
    );
  }

  public setWorkOrderGeneralFormValid(context: any, valid: boolean) {
    context.openSketchDataStepButtonDisabled = !valid;
    context.allValid = !(
      context.openDefectsDataStepButtonDisabled ||
      context.openGeneralDataStepButtonDisabled ||
      context.openSketchDataStepButtonDisabled ||
      context.createWorkOrderButtonDisabled
    );
  }

  public setWorkOrderSketchFormValid(context: any, valid: boolean) {
    context.createWorkOrderButtonDisabled = !valid;
    context.allValid = !(
      context.openDefectsDataStepButtonDisabled ||
      context.openGeneralDataStepButtonDisabled ||
      context.openSketchDataStepButtonDisabled ||
      context.createWorkOrderButtonDisabled
    );
  }

  public set createWorkOrderResponse(response: FormResponse<string>) {
    this.vm.createWorkOrderRequest = response;

    if (!response.loading && !response.error) {
      this.vm.createdWorkOrder = response.data;
    }
  }

  public set defects(defects: any[]) {
    this.vm.defects = defects;
  }

  public get defects(): any[] {
    return this.vm.defects;
  }

  public set openGeneralDataStepButtonDisabled(value: boolean) {
    this.vm.openGeneralDataStepButtonDisabled = value;
  }

  public get reportData(): any {
    return {
      plantSizes: this.parseGeneralSection(),
      sections: this.parseSections(),
      sampleData: this.parseSampleSection(),
      parameters: this.parseParametersSection()
    };
  }

  public set workOrderRemarks(remarks: string) {
    this.vm.workOrderRemarks = remarks;
  }

  // private initPlantSizes(plantType: string) {
  //   const plantSizeIds = PlantGroupStorageHandler.getPlantSizesFromType(
  //     plantType
  //   );

  //   const definition: { [key: string]: any } = {};
  //   for (const plantSizeId of plantSizeIds) {
  //     const plantSize = PlantGroupStorageHandler.getSizeFromId(plantSizeId);

  //     if (!!plantSize && plantSize.compare) {
  //       definition[plantSize.id] = { required: true, type: FormFieldType.Text };
  //       this.vm.generalSection[plantSize.id] = {
  //         suffix: plantSize.unit,
  //         prefix: "",
  //         multiple: false,
  //         component: "Textfield",
  //         name: plantSize.name,
  //         value: plantSize.value,
  //         error: ""
  //       };
  //     }
  //   }
  //   const subform = new DynamicForm(
  //     definition,
  //     this.vm.generalSection,
  //     () => undefined,
  //     undefined,
  //     this.vm,
  //     "plantSizes"
  //   );
  //   subform.init();
  //   this.reportDataForm.addSubForm(subform);
  // }

  // private initParameters(parametersRaw: PlantParameter[]) {
  //   const definition: { [key: string]: any } = {};
  //   const parameters = parametersRaw
  //     .map(p => ParameterStorageHandler.getParameterFromId(p.id, p.value))
  //     .filter(p => p.toCheck);
  //   for (const parameter of parameters) {
  //     if (!!parameter) {
  //       definition[parameter.id] = { required: true, type: FormFieldType.Text };
  //       this.vm.parametersSection[parameter.id] = {
  //         suffix: parameter.unit ?? "",
  //         prefix: "",
  //         multiple: false,
  //         component: "Textfield",
  //         name: parameter.name ?? "",
  //         value: "",
  //         error: ""
  //       };
  //     }
  //   }
  //   const subform = new DynamicForm(
  //     definition,
  //     this.vm.parametersSection,
  //     () => undefined,
  //     undefined,
  //     this.vm,
  //     "parameters"
  //   );
  //   subform.init();
  //   this.reportDataForm.addSubForm(subform);
  // }

  // private addReportToDefinitions(report: any) {
  //   for (
  //     let sectionIndex = 0;
  //     sectionIndex < report.sections.length;
  //     sectionIndex++
  //   ) {
  //     const section = report.sections[sectionIndex];
  //     const definition: { [key: string]: any } = {};

  //     for (let i = 0; i < section.fields.length; i++) {
  //       const field = section.fields[i];
  //       const type = this.findType(field.type);

  //       definition["field" + i] = {
  //         required: type !== FormFieldType.Checkbox,
  //         type
  //       };
  //     }

  //     const subform = new DynamicForm(
  //       definition,
  //       this.vm.sections[sectionIndex].fields,
  //       () => undefined,
  //       this.reportDataForm,
  //       this.vm,
  //       "section" + sectionIndex
  //     );
  //     subform.init();
  //     this.reportDataForm.addSubForm(subform);
  //   }
  //   this.vm.openDefectsDataStepButtonDisabled = true;
  // }

  // private addReportToView(report: any) {
  //   const vmSections: Section[] = [];

  //   for (const section of report.sections) {
  //     const vmSection: Section = { name: section.name, fields: {} };

  //     for (let i = 0; i < section.fields.length; i++) {
  //       const field = section.fields[i];
  //       const type = this.findType(field.type);

  //       const viewModelFormType = this.findViewModelFormTypeByFormFieldType(
  //         type,
  //         field.name
  //       );

  //       if (
  //         type === FormFieldType.MultiSelect ||
  //         type === FormFieldType.Selection
  //       ) {
  //         (viewModelFormType as MultiSelect).items = field.options.map(
  //           (el: any, index: number) => ({
  //             text: el.name,
  //             value: el.name
  //           })
  //         );
  //       }
  //       const baseFields = {
  //         prefix: field.prefix,
  //         suffix: field.suffix,
  //         component: this.findComponentFromType(field.type),
  //         multiple: type === FormFieldType.MultiSelect,
  //         name: field.name
  //       };
  //       vmSection.fields["field" + i] = { ...baseFields, ...viewModelFormType };
  //     }

  //     vmSections.push(vmSection);
  //   }

  //   this.vm.sections = vmSections;
  // }

  // private findComponentFromType(type: string): string {
  //   switch (type) {
  //     case "Text":
  //       return "Textfield";
  //     case "Checkbox":
  //       return "SimpleCheckbox";
  //     case "Multiselect":
  //       return "Select";
  //     case "Dropdown":
  //       return "Select";
  //     case "Combobox":
  //       return "Combobox";
  //     case "Date":
  //       return "DatePicker";
  //     case "Month":
  //       return "MonthPicker";
  //     default:
  //       return "Textfield";
  //   }
  // }

  // private findType(type: string): FormFieldType {
  //   switch (type) {
  //     case "Text":
  //       return FormFieldType.Text;
  //     case "Checkbox":
  //       return FormFieldType.Checkbox;
  //     case "Multiselect":
  //       return FormFieldType.MultiSelect;
  //     case "Dropdown":
  //       return FormFieldType.Selection;
  //     case "Combobox":
  //       return FormFieldType.Selection;
  //     case "Date":
  //       return FormFieldType.Date;
  //     case "Month":
  //       return FormFieldType.Date;
  //     default:
  //       return FormFieldType.Text;
  //   }
  // }

  // private findViewModelFormTypeByFormFieldType(
  //   type: FormFieldType,
  //   name: string
  // ) {
  //   switch (type) {
  //     case FormFieldType.Text:
  //       return { value: "", error: "" } as Textfield;
  //     case FormFieldType.Checkbox:
  //       return { value: false, error: "" } as Checkbox;
  //     case FormFieldType.MultiSelect:
  //       return { selected: [], error: "", items: [] } as MultiSelect;
  //     case FormFieldType.Selection:
  //       return { selected: "", error: "", items: [] } as Selection;
  //     case FormFieldType.Date:
  //       const date = new Date();
  //       return {
  //         text: DateUtils.formatDate(date),
  //         value: DateUtils.dateOnly(date),
  //         error: ""
  //       } as DateType;
  //     default:
  //       return { value: "", error: "" } as Textfield;
  //   }
  // }

  private parseGeneralSection() {
    return Object.keys(this.vm.generalSection).map(key => ({
      name: key,
      value: this.vm.generalSection[key].value
    }));
  }

  private parseSections() {
    const toReturn = [];

    for (const section of this.vm.sections) {
      const fields = [];
      for (const fieldKey of Object.keys(section.fields)) {
        const field = section.fields[fieldKey] as any;
        const parsedField = {
          name: field.name,
          suffix: field.suffix,
          prefix: field.prefix,
          value: ""
        };
        if (field.component === "Select" || field.component === "Combobox") {
          if (!!field.selected.text) {
            parsedField.value = field.selected.text;
          } else {
            parsedField.value = field.selected;
          }
        } else if (field.component === "DatePicker") {
          parsedField.value = DateUtils.format(field.value, "DD.MM.YYYY");
        } else if (field.component === "SimpleCheckbox") {
          parsedField.value = field.value ? "true" : "false";
        } else {
          parsedField.value = field.value;
        }
        fields.push(parsedField);
      }
      toReturn.push({ name: section.name, fields });
    }

    return toReturn;
  }

  private parseSampleSection() {
    let sampleTime = this.vm.sampleTime;
    if (this.vm.workOrderNoPriceTab) {
      const dateTill = DateUtils.getDate(this.vm.workOrderDate);
      dateTill.setHours(0, 0, 0, 0);
      dateTill.setMinutes(DateUtils.timeToNumber(this.vm.sampleTimeFrom.text));
      dateTill.setMinutes(
        dateTill.getMinutes() + parseInt(this.vm.usedTime.value.toString(), 10)
      );

      sampleTime = DateUtils.format(this.vm.workOrderDate, "DD.MM.YYYY");
      sampleTime += ` ${this.vm.sampleTimeFrom.text}`;
      sampleTime += `-${DateUtils.format(dateTill, "HH:mm")}`;
    }

    const sampleLocationSelected = this.vm.sampleLocation.selected as any;
    let sampleLocation = "";
    if (!!sampleLocationSelected.text) {
      sampleLocation = sampleLocationSelected.text;
    } else {
      sampleLocation = sampleLocationSelected;
    }

    const sampleIntakeSelected = this.vm.sampleIntake.selected as any;
    let sampleIntake = "";
    if (!!sampleIntakeSelected.text) {
      sampleIntake = sampleIntakeSelected.text;
    } else {
      sampleIntake = sampleIntakeSelected;
    }

    return {
      cannotTakeSample: this.vm.cannotTakeSample.value,
      substituteDate: this.vm.substituteDate.value,
      sampleLocation,
      sampleTime,
      sampleWeather: this.vm.sampleWeather.value,
      sampleTemperature: this.vm.sampleTemperature.value,
      sampleIntake,
      sampleTaker: this.vm.sampleTaker,
      plantStatus: this.vm.plantStatus.selected,
      suggestedActions: this.vm.suggestedActions.selected
    };
  }

  private parseParametersSection() {
    return Object.keys(this.vm.parametersSection).map(key => ({
      id: key,
      value: this.vm.parametersSection[key].value
    }));
  }

  // private initReportValues(reportData: any) {
  //   if (!!reportData.plantSizes) {
  //     const plantSizesSubform = this.reportDataForm.findSubformByName(
  //       "plantSizes"
  //     );

  //     if (!!plantSizesSubform) {
  //       for (const plantSize of reportData.plantSizes) {
  //         plantSizesSubform.setFieldValue(plantSize.name, plantSize.value);
  //       }
  //     }
  //   }

  //   if (!!reportData.sections && this.vm.sections.length > 0) {
  //     for (const rawSection of reportData.sections) {
  //       const sectionIndex = this.vm.sections.findIndex(
  //         section => section.name === rawSection.name
  //       );
  //       const sectionSubform = this.reportDataForm.findSubformByName(
  //         "section" + sectionIndex
  //       );
  //       if (!sectionSubform) {
  //         continue;
  //       }

  //       for (const rawField of rawSection.fields) {
  //         for (const fieldKey of Object.keys(
  //           this.vm.sections[sectionIndex].fields
  //         )) {
  //           const field = this.vm.sections[sectionIndex].fields[fieldKey];
  //           if (field.name === rawField.name) {
  //             sectionSubform.setFieldValue(fieldKey, rawField.value);
  //             break;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   if (!!reportData.sampleData) {
  //     this.vm.cannotTakeSample.value = reportData.sampleData.cannotTakeSample;
  //     if (!!reportData.sampleData.substituteDate) {
  //       this.vm.substituteDate.value = reportData.sampleData.substituteDate;
  //       this.vm.substituteDate.text = DateUtils.format(
  //         reportData.sampleData.substituteDate
  //       );
  //     }
  //     this.reportDataForm.setFieldValue(
  //       "sampleLocation",
  //       reportData.sampleData.sampleLocation
  //     );
  //     this.vm.sampleTimeFrom.text = DateUtils.parseDateFromTillFromReport(
  //       reportData.sampleData.sampleTime
  //     );
  //     this.vm.sampleTimeFrom.value = DateUtils.parseDateFromTillFromReport(
  //       reportData.sampleData.sampleTime
  //     );
  //     this.vm.sampleTime = reportData.sampleData.sampleTime;
  //     this.reportDataForm.setFieldValue(
  //       "sampleWeather",
  //       reportData.sampleData.sampleWeather
  //     );
  //     this.reportDataForm.setFieldValue(
  //       "sampleTemperature",
  //       reportData.sampleData.sampleTemperature
  //     );
  //     this.reportDataForm.setFieldValue(
  //       "sampleIntake",
  //       reportData.sampleData.sampleIntake
  //     );
  //     this.reportDataForm.setFieldValue(
  //       "plantStatus",
  //       reportData.sampleData.plantStatus
  //     );
  //     this.reportDataForm.setFieldValue(
  //       "suggestedActions",
  //       reportData.sampleData.suggestedActions
  //     );
  //   }

  //   if (!!reportData.parameters) {
  //     const parametersSubform = this.reportDataForm.findSubformByName(
  //       "parameters"
  //     );
  //     if (!!parametersSubform) {
  //       for (const parameter of reportData.parameters) {
  //         parametersSubform?.setFieldValue(parameter.id, parameter.value);
  //       }
  //     }
  //   }

  //   this.reportDataForm.validateForm();

  //   if (reportData?.sampleData?.cannotTakeSample) {
  //     this.vm.openDefectsDataStepButtonDisabled = false;
  //   }
  // }
}
