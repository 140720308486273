import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { ItemTemplateService } from "../services/ItemTemplateService";
import { Store } from "../services/StoreGraphQLService";
import { StoreItem } from "../services/StoreItemGraphQLService";
import { StoreItemService } from "../services/StoreItemService";
import { StoreService } from "../services/StoreService";

export class CreateStoreItemController {
  private storeItemId?: string;

  public constructor(
    private presenter: ICreateStoreItemPresenter,
    private storeItemService: StoreItemService,
    private itemTemplateService: ItemTemplateService,
    private storeService: StoreService
  ) {}

  public async mounted(storeItemId?: string) {
    FormRequestHandler.handle(
      this.storeService.getAllStores(),
      response => {
        this.presenter.getStoresResponse = response;

        if (!response.loading && !response.error) {
          this.presenter.visibleView = true;
        }
      },
      "Konnte Lagerorte nicht laden!"
    );

    FormRequestHandler.handle(
      this.itemTemplateService.getAllItemTemplates(),
      response => {
        this.presenter.getItemTemplatesResponse = response;

        if (!response.loading && !response.error) {
          this.presenter.visibleView = true;
        }
      },
      "Konnte Artikel nicht laden!"
    );

    if (storeItemId) {
      this.storeItemId = storeItemId;

      FormRequestHandler.handle(
        this.storeItemService.getStoreItemById(storeItemId),
        response => {
          this.presenter.getStoreItemResponse = response;

          if (!response.loading && !response.error) {
            this.presenter.visibleView = true;
          }
        },
        "Konnte Lagerort nicht laden!"
      );
    } else {
      this.presenter.visibleView = true;
    }
  }

  public closeOverlay() {
    this.presenter.visibleView = false;
  }

  public createStoreItemButtonClicked() {
    if (this.storeItemId) {
      const data = { id: this.storeItemId, ...this.presenter.data };

      FormRequestHandler.handle(
        this.storeItemService.updateStoreItem(data),
        response => (this.presenter.createStoreItemResponse = response),
        "Could not update storeItem!"
      );
    } else {
      FormRequestHandler.handle(
        this.storeItemService.createStoreItem(this.presenter.data),
        response => (this.presenter.createStoreItemResponse = response),
        "Could not create storeItem!"
      );
    }
  }
}

export interface ICreateStoreItemPresenter {
  visibleView: boolean;

  getStoresResponse: FormResponse<Store[]>;
  getItemTemplatesResponse: FormResponse<ItemTemplate[]>;
  getStoreItemResponse: FormResponse<StoreItem>;

  data: any;
  createStoreItemResponse: FormResponse<string>;
}
