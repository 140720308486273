import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import {
  GraphQLConnection,
  OrderByClause
} from "@/gateways/graphql/GraphQLConnection";
import { Supplier } from "./SupplierGraphQLService";

export class ItemTemplateGraphQLService {
  public constructor(private connection: GraphQLConnection) {}

  public async getAllItemTemplates(): Promise<ItemTemplate[]> {
    const request = await this.connection.query("itemTemplates", {}, [
      "id",
      "itemNumber",
      "typeId",
      "groupId",
      "name",
      "dimensions",
      "unitId",
      "hk",
      "vk",
      {
        name: "suppliers",
        fields: ["id", "firstName", "lastName", "company"]
      }
    ]);

    return request.data.map((el: any) => this.parseItemTemplate(el));
  }

  public async getAllItemTemplatesPaginated(
    page: Page,
    order: OrderByClause[],
    search: string
  ): Promise<PaginatedList<ItemTemplate>> {
    const request = await this.connection.queryPaginated(
      "itemTemplatesPaginated",
      page.itemsPerPage,
      page.page,
      [
        "id",
        "itemNumber",
        "typeId",
        "groupId",
        "name",
        "dimensions",
        "unitId",
        "hk",
        "vk",
        {
          name: "suppliers",
          fields: ["id", "firstName", "lastName", "company"]
        }
      ],
      search,
      ["itemNumber", "name", "dimensions"],
      order
    );

    return {
      items: request.data.map((el: any) => this.parseItemTemplate(el)),
      totalCount: request.count
    };
  }

  public async getAllItemTemplatesBySupplier(
    supplierId: string
  ): Promise<ItemTemplate[]> {
    const request = await this.connection.query(
      "itemTemplatesBySupplier",
      { supplierId },
      [
        "id",
        "itemNumber",
        "typeId",
        "groupId",
        "name",
        "dimensions",
        "unitId",
        "hk",
        "vk",
        {
          name: "suppliers",
          fields: ["id", "firstName", "lastName", "company"]
        }
      ]
    );

    return request.data.map((el: any) => this.parseItemTemplate(el));
  }

  public async getItemTemplateById(id: string) {
    const request = await this.connection.query(
      "itemTemplate",
      {
        id
      },
      [
        "id",
        "itemNumber",
        "typeId",
        "groupId",
        "name",
        "dimensions",
        "unitId",
        "hk",
        "vk",
        {
          name: "suppliers",
          fields: ["id", "firstName", "lastName", "company"]
        }
      ]
    );

    return this.parseItemTemplate(request.data);
  }

  public async getItemTemplateByItemNumber(itemNumber: string) {
    const request = await this.connection.query(
      "itemTemplateByItemNumber",
      {
        itemNumber
      },
      [
        "id",
        "itemNumber",
        "typeId",
        "groupId",
        "name",
        "dimensions",
        "unitId",
        "hk",
        "vk",
        {
          name: "suppliers",
          fields: ["id", "firstName", "lastName", "company"]
        }
      ]
    );

    return this.parseItemTemplate(request.data);
  }

  public async updateItemTemplate(input: any) {
    const request = await this.connection.mutation(
      "updateItemTemplate",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async createItemTemplate(input: any) {
    const request = await this.connection.mutation(
      "createItemTemplate",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async deleteItemTemplate(id: string) {
    const request = await this.connection.mutation(
      "deleteItemTemplate",
      {
        id
      },
      []
    );

    return request.data;
  }

  private parseItemTemplate(raw: any) {
    return {
      ...raw,
      suppliers:
        raw.suppliers?.map((el: any) => ({
          name:
            el.company || `${el.lastName.toLocaleUpperCase()}, ${el.firstName}`,
          ...el
        })) || []
    };
  }
}

export interface ItemTemplate {
  id: string;
  itemNumber: number;
  typeId: string;
  groupId: string;
  name: string;
  dimensions: string;
  unitId: string;
  hk: number;
  vk: number;
  suppliers: Supplier[];
}
