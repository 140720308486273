import { Employee } from "@/employee/services/EmployeeService";
import { FormResponse } from "@/forms/FormResponse";
import { DeliveryWayStorageHandler } from "@/storage/storageHandlers/DeliveryWayStorageHandler";
import { DateUtils } from "@/utils/DateUtils";
import { ICreateStoreMovementPresenter } from "../controllers/CreateStoreMovementController";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { Store } from "../services/StoreGraphQLService";
import { StoreMovement } from "../services/StoreMovementGraphQLService";
import { CreateStoreMovementViewModel } from "../vms/CreateStoreMovementViewModel";

export class CreateStoreMovementPresenter
  implements ICreateStoreMovementPresenter {
  public constructor(private vm: CreateStoreMovementViewModel) {
    this.initSelections();
  }

  public set visibleView(visibleView: boolean) {
    this.vm.visibleView = visibleView;
  }

  public set getEmployeesResponse(response: FormResponse<Employee[]>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.employee.items = response.data.map(el => ({
        text: `${el.lastname.toLocaleUpperCase()}, ${el.firstname}`,
        value: el.id
      }));
    }
  }

  public set getStoresResponse(response: FormResponse<Store[]>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.store.items = response.data.map(el => ({
        text: el.name,
        value: el.id
      }));
      this.vm.source.items = response.data.map(el => ({
        text: el.name,
        value: el.id
      }));
    }
  }

  public set getItemTemplatesResponse(response: FormResponse<ItemTemplate[]>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.itemTemplate.items = response.data.map(el => ({
        text: el.name,
        value: el.id
      }));
    }
  }

  public set getStoreMovementResponse(response: FormResponse<StoreMovement>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.direction.selected =
        response.data.direction === "KORREKTUR" ? "KORREKTUR" : "UMBUCHUNG";
      this.vm.employee.selected = response.data.employeeId;
      this.vm.date = {
        value: response.data.date,
        text: DateUtils.format(response.data.date)
      };
      this.vm.source.selected = response.data.sourceId;
      if (response.data.destinationType === "PLANT") {
        this.vm.plantIndex = response.data.storeId;
      } else {
        this.vm.store.selected = response.data.storeId;
      }
      this.vm.deliveryWay.selected = response.data.deliveryWay;
      this.vm.itemTemplate.selected = response.data.itemTemplateId;
      this.vm.amount = response.data.amount.toString();
      this.vm.comment = response.data.comment;
    }
  }

  public set getPlantAddressResponse(response: FormResponse<any>) {
    this.vm.plantAddressLoading = response.loading;
    this.vm.plantAddressError = response.error;

    if (!response.loading && !response.error) {
      this.vm.plant = response.data;
      this.vm.plantAddress = `${response.data.addressStreet}, ${response.data.addressZip} ${response.data.addressCity}`;
    } else if (response.error) {
      this.vm.plantAddress = "";
    }
  }

  public set getCurrentAmountResponse(response: FormResponse<string>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.currentAmount = response.data;
    }
  }

  public set getCurrentSourceAmountResponse(response: FormResponse<string>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.currentSourceAmount = response.data;
    }
  }

  public get source() {
    return this.vm.source.selected || "";
  }

  public get store() {
    return this.vm.store.selected || "";
  }

  public get itemTemplate() {
    return this.vm.itemTemplate.selected;
  }

  public set itemTemplate(itemTemplate: string) {
    this.vm.itemTemplate.selected = itemTemplate;
  }

  public set plantIndex(plantIndex: string) {
    this.vm.plantIndex = plantIndex;
  }

  public get data(): any {
    let itemTemplateId = this.vm.itemTemplate.selected;
    if ((this.vm.itemTemplate.selected as any).value) {
      itemTemplateId = (this.vm.itemTemplate.selected as any).value;
    }

    return {
      date: this.vm.date.value,
      employeeId: this.vm.employee.selected,
      direction: this.vm.direction.selected,
      sourceId: this.vm.source.selected,
      destinationType: this.vm.plant && this.vm.plant.index ? "PLANT" : "STORE",
      storeId:
        this.vm.plant && this.vm.plant.id
          ? this.vm.plant.id.toString()
          : this.vm.store.selected,
      plantIndex:
        this.vm.plant && this.vm.plant.index
        ? this.vm.plant.index
          : undefined,
      deliveryWay:
        this.vm.plant && this.vm.plant.index
          ? this.vm.deliveryWay.selected
          : undefined,
      itemTemplateId,
      amount: parseInt(this.vm.amount, 10),
      comment: this.vm.comment
    };
  }

  public set createStoreMovementResponse(response: FormResponse<string>) {
    this.vm.loading = response.loading;
    this.vm.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.visibleView = false;
    }
  }

  public clearPlant() {
    this.vm.plant = "";
    this.vm.plantAddress = "";
  }

  private initSelections() {
    this.vm.deliveryWay.items = DeliveryWayStorageHandler.getAllDeliveryWays();
  }
}
