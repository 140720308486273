import {
  Request,
  Textfield,
  Numberfield,
  Date,
  Selection
} from "@/forms/ViewModelFormTypes";

export class EmployeeDetailViewModel {
  // Data
  public employeeId: string = "";
  public showEmployeeList: boolean = false;
  public employeeActive: boolean = true;

  // Requests
  public loadPageRequest: Request = { loading: false, error: "" };
  public deleteEmployeeRequest: Request = { loading: false, error: "" };
  public reactivateEmployeeRequest: Request = { loading: false, error: "" };
  public saveEmployeeRequest: Request = { loading: false, error: "" };

  // States
  public saveEmployeeButtonDisabled: boolean = true;
  public deleteEmployeeButtonVisible: boolean = false;

  // Input
  public abbreviation: Textfield = {
    label: "",
    value: "",
    loading: false,
    error: ""
  };
  public salutation: Selection = {
    label: "",
    selected: "",
    loading: false,
    error: "",
    items: []
  };
  public firstname: Textfield = {
    label: "",
    value: "",
    loading: false,
    error: ""
  };
  public lastname: Textfield = {
    label: "",
    value: "",
    loading: false,
    error: ""
  };
  public email: Textfield = {
    label: "",
    value: "",
    loading: false,
    error: ""
  };
  public svnr: Numberfield = {
    label: "",
    value: 0,
    loading: false,
    error: ""
  };
  public birthday: Date = {
    label: "",
    value: "",
    text: "",
    loading: false,
    error: ""
  };
  public dateOfJoining: Date = {
    label: "",
    value: "",
    text: "",
    loading: false,
    error: ""
  };

  public address: Textfield = {
    label: "",
    value: "",
    loading: false,
    error: ""
  };
  public zip: Textfield = {
    label: "",
    value: "",
    loading: false,
    error: ""
  };
  public city: Textfield = {
    label: "",
    value: "",
    loading: false,
    error: ""
  };
  public country: Selection = {
    label: "",
    selected: "",
    loading: false,
    error: "",
    items: []
  };
  public state: Selection = {
    label: "",
    selected: "",
    loading: false,
    error: "",
    items: []
  };

  public username: Textfield = {
    label: "",
    value: "",
    loading: false,
    error: ""
  };
  public password: Textfield = {
    label: "",
    value: "",
    loading: false,
    error: ""
  };
  public role: Selection = {
    label: "",
    selected: "",
    loading: false,
    error: "",
    items: []
  };
  public signature: string = "";

  public phoneNumbers: EmployeePhoneNumber[] = [];

  // Texts
  public title: string = "";

  public personalDataTitle: string = "";
  public addressTitle: string = "";
  public phoneNumbersTitle: string = "";
  public userDataTitle: string = "";
  public signatureTitle: string = "";

  public signatureDescription: string = "";
  public deleteEmployeeButtonText: string = "";
  public reactivateEmployeeButtonText: string = "";
  public saveEmployeeButtonText: string = "";
  public deleteEmployeeConfirmTitle: string = "";
  public deleteEmployeeConfirmText: string = "";
  public reactivateEmployeeConfirmTitle: string = "";
  public reactivateEmployeeConfirmText: string = "";

  public addPhoneNumberButtonText: string = "";
  public deletePhoneNumberButtonText: string = "";
}

interface EmployeePhoneNumber {
  areaCode: Selection;
  number: Textfield;
  annotation: Textfield;
}
