export class ItemTemplateViewModel {
  public itemsPerPage = 25;
  public headers = [
    { text: "Nummer", value: "itemNumber" },
    { text: "Artikelart", value: "type" },
    { text: "Artikelgruppe", value: "group" },
    { text: "Bezeichnung", value: "name" },
    { text: "Abmessung", value: "dimensions" },
    { text: "Lieferant/en", value: "suppliers" },
    { text: "", value: "actions", align: "right" }
  ];
  public items: any[] = [];
  public totalItems = 0;
  public options = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: this.itemsPerPage,
    multiSort: true,
    mustSort: false,
    sortBy: ["itemNumber"],
    sortDesc: [false]
  };
  public loading = true;
  public pageNumber = 1;
  public search = "";
}
