import { LocalStorage } from "@/storage/LocalStorage";
import StorageKeys from "./StorageKeys";

const storage = new LocalStorage();

export class Auth {
  public static get token() {
    return storage.get(StorageKeys.token);
  }

  public static get userId() {
    return storage.get(StorageKeys.userId);
  }

  public static get permissions() {
    return storage.get(StorageKeys.permissions);
  }

  public static hasPermission(permission: string): boolean {
    const permissions = JSON.parse(this.permissions);
    return permissions.some((p: string) => p === permission);
  }

  public static hasSystemPermission(systemTable: string): boolean {
    return this.hasPermission("edit-" + systemTable);
  }
}
