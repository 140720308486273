





































































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import ValidatedSwitch from "@/components/form/vee-validate/ValidatedSwitch.vue";

import SignaturePad from "@/components/basic/SignaturePad.vue";

import { SignatureTabViewModel } from "@/report/vms/SignatureTabViewModel";
import { SignatureTabPresenter } from "@/report/presenters/SignatureTabPresenter";
import { SignatureTabController } from "@/report/controllers/SignatureTabController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import GeneralTab from "./GeneralTab.vue";

@Component<SignatureTab>({
  components: {
    ValidationObserver,
    ValidationProvider,

    Row,
    Column,
    Container,
    RequestButton,
    ValidatedSwitch,

    SignaturePad
  },
  props: ["parentController", "parentVm", "cannotTakeSignature", "signature"],
  watch: {
    "vm.signature"(newVal) {
      if (newVal && newVal !== "data:,") {
        if (this.$refs.vprovider) {
          (this.$refs.vprovider as any).validate(newVal);
        }
      } else {
        (this.$refs.vprovider as any).validate("");
      }
    }
  }
})
export default class SignatureTab extends Vue {
  public controller?: SignatureTabController;
  private vm = new SignatureTabViewModel();

  private initController(): SignatureTabController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new SignatureTabController(new SignatureTabPresenter(this.vm));
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(
      this.$props.cannotTakeSignature,
      this.$props.signature
    );
  }
}
