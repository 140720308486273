var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"appointment-list-item",class:[_vm.cardColor],staticStyle:{"left":"0","right":"0"},style:({ 
    position: _vm.absolute ? 'absolute' : 'relative',
    top: _vm.absolute ? _vm.plannedDateToTop(_vm.appointment.plannedDate) : _vm.startTimeToTop()
  })},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var hover = ref.hover;
return [(_vm.loading)?_c('div',{style:({
        padding: '10px 15px',
        position: 'relative',
        minHeight: hover
          ? _vm.getAppointmentHeight(_vm.appointment.duration) + 'px'
          : undefined,
        height: hover
          ? undefined
          : _vm.getAppointmentHeight(_vm.appointment.duration) + 'px'
      })},[_c('v-progress-circular',{staticStyle:{"left":"50%","top":"50%","transform":"translate(-50%, -50%)"},attrs:{"indeterminate":"","color":"white"}})],1):_c('Container',{attrs:{"margin":0,"fluid":""}},[_c('Row',{staticClass:"tooltip",class:[_vm.isDrive ? 'drive-time' : ''],style:({
          padding: '10px 15px',
          position: 'relative',
          minHeight: hover
            ? _vm.getAppointmentHeight(_vm.appointment.duration) + 'px'
            : undefined,
          height: hover
            ? undefined
            : _vm.getAppointmentHeight(_vm.appointment.duration) + 'px',
          overflowY: 'hidden'
        })},[_vm._t("default",null,{"hover":hover}),(_vm.showTooltip)?_c('div',{staticClass:"tooltiptext"},[_c('div',[_vm._v(_vm._s(_vm.tooltipText))])]):_vm._e()],2)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }