import { SelectionItem } from "@/forms/ViewModelFormTypes";

export class StoreMovementsViewModel {
  public itemsPerPage = 25;
  public headers = [
    { text: "Datum", value: "date" },
    { text: "Mitarbeiter", value: "employee.lastname" },
    { text: "Buchungsart", value: "direction" },
    { text: "Quelle", value: "source.name" },
    { text: "Lager", value: "store" },
    { text: "Artikel", value: "itemName" },
    { text: "Menge", value: "amount" },
    { text: "", value: "actions", align: "right" }
  ];
  public items: any[] = [];
  public totalItems = 0;
  public options = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: this.itemsPerPage,
    multiSort: true,
    mustSort: false,
    sortBy: ["date"],
    sortDesc: [true]
  };
  public loading = true;
  public pageNumber = 1;
  public search = "";
  public storeFilter: {
    selected: string[];
    items: SelectionItem[];
  } = {
    selected: [],
    items: []
  };
  public itemTemplateFilter: {
    selected: string[];
    items: SelectionItem[];
  } = {
    selected: [],
    items: []
  };
}
