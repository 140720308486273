import { FormResponse } from "@/forms/FormResponse";
import Quagga from "@ericblade/quagga2";
import VueRouter from "vue-router";
import { IBarcodeScannerPresenter } from "../controllers/BarcodeScannerController";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { BarcodeScannerViewModel } from "../vms/BarcodeScannerViewModel";

export class BarcodeScannerPresenter implements IBarcodeScannerPresenter {
  public constructor(
    private vm: BarcodeScannerViewModel,
    private router: VueRouter
  ) {}

  public set getItemTemplateResponse(response: FormResponse<ItemTemplate>) {
    if (!response.loading && !response.error) {
      this.router.push({
        name: "store-movement-create",
        params: { itemTemplateId: response.data.id }
      });
    }

    if (response.error) {
      Quagga.start();
    }
  }
}
