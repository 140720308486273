import SystemKeys from "../../common/utils/SystemKeys";
import { StorageHandler } from "./StorageHandler";

export class DistrictAuthorityStorageHandler extends StorageHandler {
  public static getAllDistrictAuthorities() {
    if (this.storage.has(SystemKeys.districtAuthorityTableKey)) {
      const fields = JSON.parse(
        this.storage.get(SystemKeys.districtAuthorityTableKey)
      );

      return fields?.map((field: any) => ({
        text: field[SystemKeys.values][SystemKeys.districtAuthorityNameKey],
        value: field[SystemKeys.id]
      }));
    }

    return [];
  }

  public static getAllDisitrictAuthoritiesById(districtId: string): string {
    if (this.storage.has(SystemKeys.districtAuthorityTableKey)) {
      const fields = JSON.parse(
        this.storage.get(SystemKeys.districtAuthorityTableKey)
      );

      const districtFields = fields?.map((field: any) => ({
        text: field[SystemKeys.values][SystemKeys.districtAuthorityNameKey],
        value: field[SystemKeys.id]
      }));

      for (const districtField of districtFields) {
        if (districtField.value === districtId) {
          return districtField.text;
        }
      }
      return districtFields;
    }
    return districtId;
  }

  public static getDistrictAuthorityNameById(districtId: string): string {
    if (this.storage.has(SystemKeys.districtAuthorityTableKey)) {
      const value = this.storage.findValue(
        SystemKeys.districtAuthorityTableKey,
        districtId
      );
      return value
        ? value.values[SystemKeys.districtAuthorityNameKey]
        : districtId;
    }

    return districtId;
  }
}
