var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Grid',_vm._l((_vm.fields),function(field,key){return _c('Row',{key:key,attrs:{"align-center":""}},[_c('Column',{attrs:{"grow":""}},[(field.type === 'list')?[_c('p',{staticClass:"title mt-4 mb-0"},[_vm._v(_vm._s(field.name))]),(field.fields.length > 0)?_c('Accordion',{staticClass:"mt-2 mb-5",attrs:{"items":field.fields,"connected":"","draggable":true},on:{"position-moved":function($event){return _vm.positionMoved([key], [], $event)}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('h4',[_vm._v(_vm._s(_vm.getValueOfFirstField(item, index)))])]}},{key:"content",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('SystemEntryField',{attrs:{"fields":item,"addLineButtonText":_vm.addLineButtonText,"deleteLineButtonText":_vm.deleteLineButtonText},on:{"fieldChanged":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.fieldChanged.apply(void 0, [ [key, index] ].concat( argsArray ))},"lineAdded":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.addLine.apply(void 0, [ [key, index] ].concat( argsArray ))},"lineDeleted":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.deleteLine.apply(void 0, [ [key, index] ].concat( argsArray ))},"position-moved":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.positionMoved.apply(void 0, [ [key, index] ].concat( argsArray ))}}})]}},{key:"actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('IconButton',{attrs:{"icon":"delete","color":"error","filled":"","tooltipText":_vm.deleteLineButtonText},on:{"click":function($event){return _vm.deleteLine([key], [], index)}}})]}}],null,true)}):_c('p',{staticClass:"my-2"},[_vm._v("Noch keine "+_vm._s(field.name)+" vorhanden")]),_c('Row',[_c('Column',{attrs:{"shrink":""}},[_c('RequestButton',{attrs:{"text":field.name + ' hinzufügen'},on:{"click":function($event){return _vm.addLine([key], [])}}})],1)],1)]:(field.type === 'selection')?[_c('Select',{attrs:{"value":field},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.fieldChanged.apply(void 0, [ [key], [] ].concat( argsArray ))}}})]:(field.type === 'boolean')?[_c('Checkbox',{attrs:{"value":field},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.fieldChanged.apply(void 0, [ [key], [] ].concat( argsArray ))}}})]:[_c('Textfield',{attrs:{"value":field,"number":field.type === 'number',"hideDetails":""},on:{"change":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.fieldChanged.apply(void 0, [ [key], [] ].concat( argsArray ))}}})]],2),(field.info)?_c('Column',{attrs:{"shrink":""}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("info")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(field.info))])])],1):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }