














import Vue from "vue";

export default Vue.extend({
  name: "Column",
  inheritAttrs: false,
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: ""
    },
    borderRadius: {
      type: String,
      default: "0"
    }
  }
});
