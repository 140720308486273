import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { OnlineCheckerFactory } from "../utils/OnlineCheckerFactory";
import { EventHandler } from "../utils/EventHandler";

export class LogoutController {
  public static logout(storage: SimpleStringStorage) {
    storage.clear([
      "plant-data-filters",
      "Anlagen",
      "Arbeitsaufträge",
      "Tourenwoche"
    ]);
    OnlineCheckerFactory.stop();
    EventHandler.removeAllEvents();
  }

  public constructor(
    private presenter: ILogoutPresenter,
    private storage: SimpleStringStorage
  ) {}

  public mounted() {
    this.logout();
  }

  private logout() {
    LogoutController.logout(this.storage);
    this.presenter.loggedOut = true;
  }
}

export interface ILogoutPresenter {
  loggedOut: boolean;
}
