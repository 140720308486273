var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',{attrs:{"margin":0,"fluid":""}},[_c('Row',[(_vm.vm.error)?_c('Column',{staticStyle:{"border-radius":"20px","background-color":"white","text-align":"center"},attrs:{"xs12":"","mx-4":"","py-4":""}},[_vm._v(_vm._s(_vm.vm.error))]):(_vm.vm.loading || _vm.loading)?_c('Column',{staticStyle:{"border-radius":"20px","background-color":"white","text-align":"center"},attrs:{"xs12":"","mx-4":"","py-4":""}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1):_c('Column',{staticStyle:{"border-radius":"20px","background-color":"white"},attrs:{"xs12":"","mx-4":"","pa-4":""}},[_c('ScheduleHeader',{attrs:{"showChangeTourDayPropsButton":_vm.showChangeTourDayPropsButton,"viewRouteMode":_vm.viewRouteMode,"tourWeek":_vm.tourWeek},on:{"openTourDayMap":function($event){return _vm.openTourDayMap($event)},"editTourDayPropsConfirmed":function($event){return _vm.$emit('editTourDayPropsConfirmed', $event)}}}),(!!_vm.tourWeek && !!_vm.tourWeek.tourDays && !_vm.vm.loading)?_c('Row',{staticClass:"calendar mb-4"},[_c('Column',{attrs:{"xs1":""}},_vm._l((24 - _vm.weekStartHour),function(n){return _c('div',{key:("h-" + n),staticClass:"calendar-time-parent"},[_c('div',{staticClass:"calendar-time body-2 font-weight-light"},[_vm._v(" "+_vm._s(_vm.indexAsTime(n + _vm.weekStartHour))+" ")])])}),0),_vm._l((_vm.tourWeek.tourDays),function(tourDay,tourDayIndex){return _c('Column',{key:("td-" + tourDayIndex),staticStyle:{"position":"relative"},attrs:{"md4":""}},[_vm._l((24 - _vm.weekStartHour),function(n){return _c('div',{key:((tourDay.id) + "-" + n),class:[
              n === 1 ? 'calendar-first-cell' : 'calendar-cell',
              !!tourDay.error ? 'error--bg' : ''
            ],style:({
              background: !!tourDay.error
                ? _vm.$vuetify.theme.currentTheme.error + 'B0'
                : ''
            })},[(
                _vm.showStartHourLine &&
                  n ===
                    _vm.controller.parseTime(tourDay.startTime)[0] +
                      1 -
                      _vm.weekStartHour
              )?_c('div',{staticClass:"calendar-start-time primary--text",style:({
                top:
                  _vm.controller.getHeightInPercentFromMinutes(
                    tourDay.startTime
                  ) + '%',
                borderTop: '2px solid'
              })}):_vm._e()])}),_c('Draggable',{staticClass:"list-group",style:({
              textAlign: 'left',
              position: 'absolute',
              top: '0px',
              height: _vm.controller.getDraggableHeight(tourDay.startTime) + 'px',
              cursor: _vm.controller.getCursor(tourDay, _vm.draggable, _vm.viewRouteMode)
            }),attrs:{"list":tourDay.appointments,"group":"selection","disabled":_vm.absolute ||
                !_vm.draggable ||
                _vm.viewRouteMode ||
                tourDay.date <= new Date() ||
                tourDay.appointments.some(function (ap) { return ap.loading; })},on:{"change":function($event){return _vm.$emit('tourDayChanged', { tourDay: tourDay, event: $event })}}},_vm._l((tourDay.appointments),function(appointment,appointmentIndex){return _c('ScheduleCard',{key:("a-" + appointmentIndex),style:({ cursor: _vm.appointmentClickable ? 'pointer' : 'auto' }),attrs:{"color":_vm.coloredCards
                  ? _vm.getCardColor(appointment.processingState)
                  : appointment.customColor
                  ? appointment.customColor
                  : 'primary',"appointment":appointment,"showTooltip":!!appointment.distance,"tooltipText":!!appointment.distance
                  ? _vm.parseDurationToString(appointment.duration)
                  : '',"draggable":!(
                  _vm.absolute ||
                  !_vm.draggable ||
                  _vm.viewRouteMode ||
                  tourDay.date <= new Date()
                ),"startTime":tourDay.startTime,"weekStartHour":_vm.weekStartHour,"isDrive":!!appointment.distance,"absolute":_vm.absolute},scopedSlots:_vm._u([{key:"default",fn:function(hover){return [(!appointment.distance)?_c('ScheduleAppointmentCard',{attrs:{"showButtons":!_vm.viewRouteMode &&
                      tourDay.date > new Date() &&
                      _vm.showAppointmentCardButtons,"appointment":appointment,"appointmentCommentEditable":_vm.appointmentCommentEditable,"hover":_vm.isMobile
                      ? _vm.expandedAppointments[appointment.id]
                      : hover.hover,"color":_vm.coloredCards
                      ? _vm.getCardColor(appointment.processingState)
                      : 'primary'},on:{"cancel":function($event){return _vm.$emit('removeAppointmentFromTourDays', {
                      tourDay: tourDay,
                      appointmentIndex: appointmentIndex,
                      cancellationReason: $event.cancellationReason
                    })},"edit":function($event){return _vm.$emit('editAppointmentPropsConfirmed', $event)},"openAppointment":function($event){return _vm.openAppointment(appointment.id)}}}):_c('div',[_vm._v("Fahrzeit")])]}}],null,true)})}),1)],2)})],2):_vm._e()],1)],1),_vm._l((_vm.vm.components),function(component,index){return _c(component.component,{key:("p-" + index),tag:"component",attrs:{"tourDay":component.props}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }