import { TypeUtils } from "./TypeUtils";

export class StringUtils {
  public static isString(value: any): value is string {
    return (
      value !== undefined &&
      value !== null &&
      (typeof value === "string" || value instanceof String)
    );
  }

  public static isJsonObjString(value: string) {
    try {
      JSON.parse(value);
    } catch (e) {
      return false;
    }
    return true;
  }

  public static toStringWithLeadingZeros(value: number) {
    return value < 10 ? "0" + value : value.toString();
  }

  public static toPriceString(price: number) {
    return `${price.toFixed(2)} €`;
  }

  public static pad(
    num: number | string,
    size: number,
    separator?: string
  ): string {
    return (
      Array(size)
        .fill("0")
        .join(separator) + num
    ).substr(-size);
  }

  public static capitalizeFirstLetter(text: string) {
    return this.capitalizeAtPosition(text, 0);
  }

  public static capitalizeAtPosition(text: string, pos: number) {
    if (text.length > pos) {
      text =
        text.slice(0, Math.max(0, pos - 1)) +
        text[pos].toUpperCase() +
        text.slice(pos + 1);
    }

    return text;
  }

  public static unpackErrorMessage(e: any): string {
    if (TypeUtils.isString(e)) {
      return e;
    } else if (TypeUtils.isError(e)) {
      return e.message;
    } else if (e.message && TypeUtils.isString(e.message)) {
      return e.message;
    } else {
      return "Fehler";
    }
  }

  public static escape_iso_8859_2(s: string) {
    let out = "";

    for (let i = 0; i < s.length; i++) {
      const c = s.charCodeAt(i);
      let part = s.charAt(i);

      if (
        c === 32 ||
        (c >= 48 && c <= 57) ||
        (c >= 65 && c <= 90) ||
        (c >= 97 && c <= 122)
      ) {
        // do not encode numbers and letters
      } else {
        switch (c) {
          case 10:
            part = "%0A";
            break;
          case 13:
            part = "%0D";
            break;
          case 260:
            part = "%A1";
            break; // Ą
          case 728:
            part = "%A2";
            break; // ˘
          case 321:
            part = "%A3";
            break; // Ł
          case 317:
            part = "%A5";
            break; // Ľ
          case 346:
            part = "%A6";
            break; // Ś
          case 352:
            part = "%A9";
            break; // Š
          case 350:
            part = "%AA";
            break; // Ş
          case 356:
            part = "%AB";
            break; // Ť
          case 377:
            part = "%AC";
            break; // Ź
          case 381:
            part = "%AE";
            break; // Ž
          case 379:
            part = "%AF";
            break; // Ż
          case 261:
            part = "%B1";
            break; // ą
          case 731:
            part = "%B2";
            break; // ˛
          case 322:
            part = "%B3";
            break; // ł
          case 318:
            part = "%B5";
            break; // ľ
          case 347:
            part = "%B6";
            break; // ś
          case 711:
            part = "%B7";
            break; // ˇ
          case 353:
            part = "%B9";
            break; // š
          case 351:
            part = "%BA";
            break; // ş
          case 357:
            part = "%BB";
            break; // ť
          case 378:
            part = "%BC";
            break; // ź
          case 733:
            part = "%BD";
            break; // ˝
          case 382:
            part = "%BE";
            break; // ž
          case 380:
            part = "%BF";
            break; // ż
          case 340:
            part = "%C0";
            break; // Ŕ
          case 258:
            part = "%C3";
            break; // Ă
          case 313:
            part = "%C5";
            break; // Ĺ
          case 262:
            part = "%C6";
            break; // Ć
          case 268:
            part = "%C8";
            break; // Č
          case 280:
            part = "%CA";
            break; // Ę
          case 282:
            part = "%CC";
            break; // Ě
          case 270:
            part = "%CF";
            break; // Ď
          case 272:
            part = "%D0";
            break; // Đ
          case 323:
            part = "%D1";
            break; // Ń
          case 327:
            part = "%D2";
            break; // Ň
          case 336:
            part = "%D5";
            break; // Ő
          case 344:
            part = "%D8";
            break; // Ř
          case 366:
            part = "%D9";
            break; // Ů
          case 368:
            part = "%DB";
            break; // Ű
          case 354:
            part = "%DE";
            break; // Ţ
          case 341:
            part = "%E0";
            break; // ŕ
          case 259:
            part = "%E3";
            break; // ă
          case 314:
            part = "%E5";
            break; // ĺ
          case 263:
            part = "%E6";
            break; // ć
          case 269:
            part = "%E8";
            break; // č
          case 281:
            part = "%EA";
            break; // ę
          case 283:
            part = "%EC";
            break; // ě
          case 271:
            part = "%EF";
            break; // ď
          case 273:
            part = "%F0";
            break; // đ
          case 324:
            part = "%F1";
            break; // ń
          case 328:
            part = "%F2";
            break; // ň
          case 337:
            part = "%F5";
            break; // ő
          case 345:
            part = "%F8";
            break; // ř
          case 367:
            part = "%F9";
            break; // ů
          case 369:
            part = "%FB";
            break; // ű
          case 355:
            part = "%FE";
            break; // ţ
          case 729:
            part = "%FF";
            break; // ˙
          default:
            part = "%" + c.toString(16).toUpperCase();
        }
      }
      out += part;
    }
    return out;
  }
}
