import { Textfield, Selection, Checkbox } from "@/forms/ViewModelFormTypes";

export class CreateOperatorPhoneNumberViewModel {
  public areaCode: Selection = { selected: "", error: "", items: [] };
  public phoneNumber: Textfield = { value: "", error: "" };
  public phoneAddition: Textfield = {
    value: "",
    error: "",
    label: "Nummernzusatz"
  };
  public isStandard: Checkbox = {
    value: false,
    error: "",
    label: "Als Standard setzen"
  };
}
