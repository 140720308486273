import { Form, FormFieldType } from "@/forms/Form";

export class PlantDataForm extends Form {
  public definition = {
    group: { required: true, type: FormFieldType.Selection },
    type: { required: true, type: FormFieldType.Selection },
    // maintenanceInterval: {
    //   required: true,
    //   type: FormFieldType.Number,
    //   greaterThan: 0
    // },
    sampleNeeded: { required: false, type: FormFieldType.Checkbox }
    // firstDate: { required: false, type: FormFieldType.Date },
  };
}
