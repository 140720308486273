import { OnlineCheckerFactory } from "@/common/utils/OnlineCheckerFactory";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { IPlantService } from "@/plant/services/PlantService";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import {
  IServiceEngineerService,
  ServiceEngineer
} from "@/tourPlanner/services/ServiceEngineerGraphQLService";
import { ITourWeekService } from "@/tourPlanner/services/TourWeekGraphQLService";
import { DateUtils } from "@/utils/DateUtils";
import { IWorkOrderService } from "../services/WorkOrderService";
import { TourWeekStorage } from "../utils/TourWeekStorage";

export class ServiceEngineerWeeklyScheduleController {
  public constructor(
    private presenter: IServiceEngineerWeeklySchedulePresenter,
    serviceEngineerId: string,
    private workOrderService: IWorkOrderService,
    private serviceEngineerService: IServiceEngineerService,
    private tourWeekService: ITourWeekService,
    private plantService: IPlantService
  ) {
    this.presenter.currentServiceEngineer = serviceEngineerId;
    if (this.isOnline) {
      FormRequestHandler.handle(
        this.serviceEngineerService.getServiceEngineers(),
        response => (this.presenter.serviceEngineersResponse = response),
        "get-service-engineers-failed"
      );
    }
  }

  public mounted() {
    this.loadTourWeek();
  }

  public get isOnline() {
    return OnlineCheckerFactory.isOnline;
  }

  public serviceEngineerSelected(newServiceEngineer: string) {
    this.presenter.currentServiceEngineer = newServiceEngineer;
    this.loadTourWeek();
  }

  public timeFrameItemSelected(newDate: string) {
    this.presenter.timeFrameItem = newDate;
    this.loadTourWeek();
  }

  public async print(week: string, serviceEngineer: string, tourWeek: any) {
    // Get HTML to print from element
    const el = document.getElementById("print");
    if (el) {
      // Get all stylesheets HTML
      const stylesHtml = `
      <style>
        table {
          font-size: 11px;
          font: Arial;
        }
        .tableborder {
          border: 0px solid black;
          border-collapse: collapse;
        }
        .bordered {
          border: 1px solid black;
          border-collapse: collapse;
        }
        .cell {
          height: 14px;
          width: 248px;
        }
        .bold {
          font-weight: bold;
        }
        .colored {
          background: lightgray;
        }
      </style>
      `;

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=900,height=700,toolbar=0,scrollbars=0,status=0"
      );
      if (WinPrint) {
        const headers = [];
        const rows: string[][] = [[], [], [], [], []];
        for (let i = 0; i < 5; i++) {
          const day = [
            "Montag",
            "Dienstag",
            "Mittwoch",
            "Donnerstag",
            "Freitag"
          ][i];
          const date = new Date(tourWeek.weekNumber);
          date.setDate(date.getDate() + i);
          headers.push(
            `<th class="bordered colored">${day}<br/>${DateUtils.formatDate(
              date
            )}</th>`
          );
          const tourDayIndex = tourWeek.tourDays.findIndex(
            (td: any) => td && td.index === i
          );

          if (tourDayIndex >= 0) {
            const tourDay = tourWeek.tourDays[tourDayIndex];
            for (const appointment of tourDay.appointments.filter(
              (a: any) => !!a.id
            )) {
              let phoneNumber = "";
              if (appointment.plant?.operators?.length > 0) {
                const plant = await this.plantService.getPlantById(
                  appointment.plant.id
                );
                if (plant.contactPersons.length > 0) {
                  const firstContactPerson = plant.contactPersons[0];
                  if (firstContactPerson.phoneNumbers.length > 0) {
                    const standardPhoneNumber =
                      firstContactPerson.phoneNumbers.filter(
                        cp => cp.isStandard
                      )[0] || firstContactPerson.phoneNumbers[0];
                    const areaCodeRaw = standardPhoneNumber.areaCode;
                    const parsedAreaCode = CountriesStorageHandler.getAreaCodeById(
                      areaCodeRaw
                    );
                    let name = "";
                    if (firstContactPerson.company) {
                      name = firstContactPerson.company;
                    } else {
                      name = firstContactPerson.lastName.toLocaleUpperCase();
                      if (firstContactPerson.firstName) {
                        name += `, ${firstContactPerson.firstName}`;
                      }
                    }
                    phoneNumber = `${parsedAreaCode} ${standardPhoneNumber.phoneNumber} (${name})`;
                  }
                }
              }
              let plantIndex = "";
              if (appointment.plant && appointment.plant.index) {
                plantIndex = appointment.plant.index.toString();
              }

              rows[i].push(
                `<td class="bordered cell bold">
              <div style="float: right; padding-left: 5px;">${plantIndex}</div>
              <div>${appointment.reason}</div></td>`
              );
              if (appointment.operatorName) {
                rows[i].push(
                  `<td class="bordered cell bold">${appointment.operatorName}</td>`
                );
              }
              rows[i].push(
                `<td class="bordered cell">${appointment.addressStreet}<br/>${appointment.addressZip} ${appointment.addressCity}</td>`
              );
              if (phoneNumber) {
                rows[i].push(
                  `<td class="bordered cell">${phoneNumber || ""}</td>`
                );
              }
              rows[i].push(
                `<td class="bordered cell">${appointment.remarks || ""}</td>`
              );
            }
          }
        }

        let html = `<table class="tableborder"><tr>`;
        for (const header of headers) {
          html += header;
        }
        html += "</tr>";
        for (let i = 0; i < Math.max(...rows.map(r => r.length)); i++) {
          html += `<tr>`;
          for (let j = 0; j < 5; j++) {
            if (i < rows[j].length) {
              html += rows[j][i];
            } else {
              html += `<td class="cell"></td>`;
            }
          }
          html += "</tr>";
        }
        html += "</table>";

        WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              <title>
                Wochenplan: ${week} - ${serviceEngineer}
              </title>
              ${stylesHtml}
            </head>
            <body>
              <div class="v-application">
                ${html}
              </div>
            </body>
          </html>`);

        WinPrint.document.close();
        WinPrint.focus();
        setTimeout(() => {
          WinPrint.print();
          WinPrint.close();
        }, 500);
      }
    }
  }

  public async loadTourWeek() {
    if (this.isOnline) {
      const dateFrom = new Date(this.presenter.timeFrameItem);
      const dateTo = new Date(dateFrom);
      dateTo.setDate(dateTo.getDate() + 5);
      const request = this.tourWeekService.requestTourWeekRoutesByWeekNumberAndServiceEngineer(
        DateUtils.toISOString(dateFrom),
        this.presenter.currentServiceEngineer
      );
      FormRequestHandler.handle(
        request,
        response => (this.presenter.response = response),
        "Konnte Woche nicht laden"
      );
      const appointmentIds = (await request).tourDays
        .flatMap((td: any) => td.appointments.flatMap((a: any) => a.id))
        .filter((el: any) => !!el);
      const processingStateRequest = this.workOrderService.getWorkOrderProcessingStates(
        appointmentIds
      );
      FormRequestHandler.handle(
        processingStateRequest,
        response => (this.presenter.processingStatesResponse = response),
        "Konnte ProcessingStates nicht laden"
      );
    } else {
      FormRequestHandler.handle(
        Promise.resolve(TourWeekStorage.load()),
        response => (this.presenter.response = response),
        "Konnte Woche nicht laden"
      );
      this.presenter.colorsLoading = false;
    }
  }
}

export interface IServiceEngineerWeeklySchedulePresenter {
  response: FormResponse<any>;
  processingStatesResponse: FormResponse<any>;
  timeFrameItem: string;
  currentServiceEngineer: string;
  serviceEngineersResponse: FormResponse<ServiceEngineer[]>;

  colorsLoading: boolean;
}
