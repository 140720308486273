import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { DateUtils } from "@/utils/DateUtils";
import { IWorkOrderService, WorkOrder } from "../services/WorkOrderService";

export class SendReportToBhController {
  public constructor(
    private presenter: ISendReportToBhPresenter,
    private workOrderService: IWorkOrderService
  ) {}

  public mounted(week?: string) {
    FormRequestHandler.handle(
      this.workOrderService.getAllFinishedWorkOrdersWithLabReport(week),
      response => (this.presenter.getReportsResponse = response),
      "Konnte Arbeitsaufträge nicht laden!"
    );
  }

  public parseDate(date: string) {
    return DateUtils.format(date);
  }

  public parseFileSize(fileSize: number) {
    if (fileSize > 1000000) {
      return `${(fileSize / 1000000).toFixed(2)} MB`;
    } else if (fileSize > 1000) {
      return `${(fileSize / 1000).toFixed(2)} KB`;
    } else {
      return `${fileSize} B`;
    }
  }

  public weekNumber(date: string) {
    return `KW ${DateUtils.weekNumber(date)} / ${DateUtils.getDate(
      date
    ).getFullYear()}`;
  }

  public removeTableItem(id: string) {
    this.presenter.removeTableItem(id);
  }

  public sendReports() {
    FormRequestHandler.handle(
      this.workOrderService.sendReportsToBh(this.presenter.ids),
      response => (this.presenter.sendReportsResponse = response),
      "Konnte Berichte nicht senden!"
    );
  }
}

export interface ISendReportToBhPresenter {
  getReportsResponse: FormResponse<WorkOrder[]>;
  sendReportsResponse: FormResponse<any>;
  ids: string[];

  removeTableItem(id: string): void;
}
