


































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedTextarea from "@/components/form/vee-validate/ValidatedTextarea.vue";
import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";
import ValidatedDatepicker from "@/components/form/vee-validate/ValidatedDatepicker.vue";
import ValidatedAutocomplete from "@/components/form/vee-validate/ValidatedAutocomplete.vue";
import ValidatedCombobox from "@/components/form/vee-validate/ValidatedCombobox.vue";

import RequestButton from "@/components/basic/RequestButton.vue";

import { CreateStoreMovementViewModel } from "@/store/vms/CreateStoreMovementViewModel";
import { CreateStoreMovementPresenter } from "@/store/presenters/CreateStoreMovementPresenter";
import { CreateStoreMovementController } from "@/store/controllers/CreateStoreMovementController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { EmployeeGraphQLService } from "../services/EmployeeGraphQLService";
import { StoreMovementService } from "../services/StoreMovementService";
import { StoreItemService } from "../services/StoreItemService";
import { ItemTemplateService } from "../services/ItemTemplateService";
import { StoreService } from "../services/StoreService";
import { PlantGraphQLService } from "@/plant/services/PlantGraphQLService";

@Component<CreateStoreMovementView>({
  components: {
    ValidationObserver,
    Card,
    Container,
    Column,
    Row,
    ValidatedTextfield,
    ValidatedTextarea,
    ValidatedSelect,
    ValidatedDatepicker,
    ValidatedAutocomplete,
    ValidatedCombobox,
    RequestButton
  },
  watch: {
    "vm.visibleView"(newValue) {
      if (!newValue) {
        setTimeout(() => {
          this.$emit("destroyed");
          this.$destroy();
          if (
            !this.$props?.storeMovementId &&
            this.$router.currentRoute.name === "store-movement-create"
          ) {
            this.$nextTick(() => this.$router.back());
          }
        }, 500);
      }
    }
  },
  props: {
    storeMovementId: String
  }
})
export default class CreateStoreMovementView extends Vue {
  private vm = new CreateStoreMovementViewModel();
  private controller?: CreateStoreMovementController;

  private formStep = 1;

  private initController(): CreateStoreMovementController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreateStoreMovementController(
      new CreateStoreMovementPresenter(this.vm),
      new StoreMovementService(connection),
      new ItemTemplateService(connection),
      new StoreService(connection),
      new EmployeeGraphQLService(connection),
      new StoreItemService(connection),
      new PlantGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(
      this.$route.params.itemTemplateId,
      this.$route.params.plantIndex,
      this.$props.storeMovementId
    );
    this.vm.isEdit = !!this.$props.storeMovementId;
  }
}
