import { Request } from "@/forms/ViewModelFormTypes";
import { TourDataVMEntity } from "./entities/TourDataVMEntity";

export class TourDataViewModel {
  // Data
  public tourData: TourDataVMEntity = new TourDataVMEntity();

  // States
  public tourDataSaved = false;

  // Texts
  public saveTourDataButtonText = "";

  // Requests
  public loadTourDataRequest: Request = { loading: false, error: "" };
  public saveTourDataRequest: Request = { loading: false, error: "" };
}
