export class OperatorDataTabController {
  public constructor(private presenter: IOperatorDataTabPresenter) {}

  public mounted(workOrder: any) {
    this.presenter.data = workOrder;
  }

  public operatorAddressCountrySelected(country: string) {
    this.presenter.operatorAddressCountrySelected(country);
  }

  public get data() {
    return this.presenter.data;
  }
}

export interface IOperatorDataTabPresenter {
  data: any;

  operatorAddressCountrySelected(value: string): void;
}
