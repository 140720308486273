import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { StoreItemService } from "../services/StoreItemService";

export class StoreItemsExport {
  public static async export() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    const storeItemsService = new StoreItemService(connection);
    const downloadLink = await storeItemsService.exportStoreItems();

    const link = document.createElement("a");
    link.setAttribute("href", downloadLink);
    link.setAttribute("download", "download");
    link.setAttribute("target", "_blank");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
