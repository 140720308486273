import { IOperatorDataPresenter } from "../controllers/OperatorDataController";
import { OperatorDataViewModel } from "../vms/OperatorDataViewModel";
import { Operator } from "../services/OperatorGraphQLService";
import { FormResponse } from "@/forms/FormResponse";

export class OperatorDataPresenter implements IOperatorDataPresenter {
  public constructor(private vm: OperatorDataViewModel) {}

  public get operator(): Operator {
    return this.vm.operator;
  }

  public set operator(operator: Operator) {
    this.vm.operator = operator;
  }

  public set getOperatorResponse(response: FormResponse<Operator>) {
    this.vm.operator = response.loading;
    this.vm.error = response.error;

    if (response.success) {
      this.vm.operator = response.data;
    }
  }
}
