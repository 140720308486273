import { TableHeader, Request } from "@/forms/ViewModelFormTypes";

export class UserRoleListViewModel {
  // Data
  public addNewRole: boolean = false;
  public userRoleToEdit: string = "";
  public userRoles: UserRoleRowViewModel[] = [];

  // Requests
  public loadUserRolesRequest: Request = { loading: false, error: "" };

  // Texts
  public title: string = "";
  public addNewRoleButtonText: string = "";
  public editUserRoleButtonText: string = "";

  public headers: TableHeader[] = [
    {
      text: "Name",
      value: "name",
      width: "100%"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "right"
    }
  ];

  public tabletHeaders: TableHeader[] = [
    {
      text: "Name",
      value: "name",
      width: "100%"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "right"
    }
  ];

  public mobileHeaders: TableHeader[] = [
    {
      text: "Name",
      value: "name",
      width: "100%"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "right"
    }
  ];
}

interface UserRoleRowViewModel {
  id: string;
  name: string;
}
