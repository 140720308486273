



































































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import Card from "@/components/layout/Card.vue";
import ExpandableCard from "@/components/layout/ExpandableCard.vue";
import AlertButton from "@/components/layout/AlertButton.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import GoogleMap from "@/components/google/GoogleMap.vue";
import GoogleMapMarker from "@/components/google/GoogleMapMarker.vue";
import PlantOperatorCard from "@/plant/views/PlantOperatorCard.vue";

import PlantContactPersonData from "@/plant/views/plantData/PlantContactPersonData.vue";
import PlantData from "@/plant/views/plantData/PlantData.vue";
import PlantDocumentsData from "@/plant/views/plantData/PlantDocumentsData.vue";
import PlantInstallationPartsData from "@/plant/views/plantData/PlantInstallationPartsData.vue";
import PlantLimitsData from "@/plant/views/plantData/PlantLimitsData.vue";
import PlantMaintenanceContractData from "@/plant/views/plantData/PlantMaintenanceContractData.vue";
import PlantWorkOrdersData from "@/report/views/PlantWorkOrdersData.vue";
import PlantAppointmentsCard from "@/tourPlanner/views/new/PlantAppointmentsCard.vue";
import PlantOperatorsData from "@/plant/views/plantData/PlantOperatorsData.vue";
import PlantPartsData from "@/plant/views/plantData/PlantPartsData.vue";
import PlantPrintTemplatesData from "@/plant/views/plantData/PlantPrintTemplatesData.vue";
import PlantRemarksData from "@/plant/views/plantData/PlantRemarksData.vue";
import PlantProtocolData from "@/plant/views/plantData/PlantProtocolData.vue";
import StoreItemCardView from "@/store/views/StoreItemCardView.vue";

import { LocalStorage } from "../../storage/LocalStorage";
import { Plant } from "@/entities/Plant";
import { PlantGraphQLGateway } from "@/gateways/graphql/PlantGraphQLGateway";
import { PlantInteractor } from "@/secretary/interactors/PlantInteractor";
import { PlantPresenter } from "@/plant/presenters/PlantPresenter";
import { PlantController } from "@/plant/controllers/PlantController";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { PlantViewModel } from "../vms/PlantViewModel";
import { PlantGraphQLService } from "../services/PlantGraphQLService";
import { Auth } from "../../common/utils/Auth";
import StorageKeys from "../../common/utils/StorageKeys";

@Component<PlantView>({
  components: {
    Page,
    Section,
    Card,
    ExpandableCard,
    AlertButton,
    Grid,
    Row,
    Column,
    GoogleMap,
    GoogleMapMarker,
    PlantContactPersonData,
    PlantData,
    PlantDocumentsData,
    PlantInstallationPartsData,
    PlantLimitsData,
    PlantMaintenanceContractData,
    PlantWorkOrdersData,
    PlantAppointmentsCard,
    PlantOperatorsData,
    PlantPartsData,
    PlantPrintTemplatesData,
    PlantRemarksData,
    PlantProtocolData,
    PlantOperatorCard,
    StoreItemCardView,
    SearchOperatorView: () => import("@/plant/views/SearchOperatorView.vue")
  },
  props: ["plantId"]
})
export default class PlantView extends Vue {
  private plantId!: string;
  private vm = new PlantViewModel();
  private storage = new LocalStorage();
  private addOperatorComponents: any[] = [];

  private get controller() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new PlantController(
      this.plantId,
      new PlantPresenter(this.vm),
      new PlantGraphQLService(connection),
      new LocalStorage()
    );
  }

  protected get actionText() {
    if (
      this.vm.plant &&
      this.vm.plant.id &&
      this.vm.plant.index &&
      this.vm.plant.operators &&
      this.vm.plant.operators.length > 0
    ) {
      return this.vm.plant.index?.toString() === this.vm.plant.id?.toString()
        ? "Altsystem"
        : "";
    } else {
      return "";
    }
  }

  protected openPlantInOldSystem() {
    if (
      !this.vm.plant ||
      !this.vm.plant.operators ||
      this.vm.plant.operators.length === 0
    ) {
      return;
    }

    const plantId = this.vm.plant.id;
    const operatorId = this.vm.plant.operators[0].id;
    const url = `http://portal.vavpic.at/facilities/data/show/customer/${operatorId}/id/${plantId}`;

    window.open(url, "_blank");
  }

  private mounted() {
    this.controller.init();
  }

  private addAppointmentButtonClicked() {
    this.$router.push({
      name: "appointment-create",
      params: { plantId: this.plantId }
    });
  }

  private addOperator() {
    this.addOperatorComponents.push({ component: "SearchOperatorView" });
  }

  private addOperatorFinished() {
    this.addOperatorComponents = [];
    this.controller.getPlantData();
  }

  private canEdit() {
    return Auth.hasPermission(StorageKeys.editPlantAndOperatorPermission);
  }
}
