import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { OrderByClause, SortOrder } from "@/gateways/graphql/GraphQLConnection";
import { DateUtils } from "@/utils/DateUtils";
import { SupplierGraphQLService } from "../services/SupplierGraphQLService";

export class SupplierOrderOverviewController {
  public constructor(
    private presenter: ISupplierOrderOverviewPresenter,
    private supplierService: SupplierGraphQLService
  ) {}

  public mounted() {
    FormRequestHandler.handle(
      this.supplierService.getSupplierNames(),
      response => (this.presenter.getSuppliersResponse = response),
      "Konnte Lagerorte nicht laden!"
    );

    this.getItems();
  }

  public parseDate(date: string) {
    return DateUtils.format(date);
  }

  public tableSorted(options: any) {
    this.presenter.options = options;

    if (!this.presenter.mounting) {
      this.getItems();
    }
  }

  public pageChanged(newPageNumber: number) {
    this.presenter.pageNumber = newPageNumber;

    if (!this.presenter.mounting) {
      this.getItems();
    }
  }

  private getItems() {
    const order: OrderByClause[] = [];
    for (let i = 0; i < this.presenter.options.sortBy.length; i++) {
      order.push({
        field: this.presenter.options.sortBy[i],
        order: this.presenter.options.sortDesc[i]
          ? SortOrder.DESC
          : SortOrder.ASC
      });
    }

    const page = new Page(
      this.presenter.itemsPerPage,
      this.presenter.pageNumber
    );

    FormRequestHandler.handle(
      this.supplierService.getAllSupplierOrdersPaginated(
        page,
        order,
        this.presenter.search,
        this.presenter.supplierFilter.selected
      ),
      response => (this.presenter.tableRequest = response),
      "Konnte Tabelle nicht laden!"
    );
  }
}

export interface ISupplierOrderOverviewPresenter {
  mounting: boolean;
  getSuppliersResponse: FormResponse<any[]>;
  tableRequest: FormResponse<PaginatedList<any>>;

  supplierFilter: {
    selected: string[];
    items: SelectionItem[];
  };

  pageNumber: number;
  itemsPerPage: number;
  options: any;
  search: string;
}
