import { Employee } from "@/employee/services/EmployeeService";
import { FormResponse } from "@/forms/FormResponse";
import { Report } from "@/storage/storageHandlers/ReportStorageHandler";
import { DateUtils } from "@/utils/DateUtils";
import { ISampleAccordionPresenter } from "../controllers/SampleAccordionController";
import { SampleAccordionViewModel } from "../vms/SampleAccordionViewModel";

export class SampleAccordionPresenter implements ISampleAccordionPresenter {
  public constructor(private vm: SampleAccordionViewModel) {}

  public set sampleData(sampleData: any) {
    this.vm.plantStatus.selected = sampleData.plantStatus;
    this.vm.sampleIntakeEnabled = sampleData.sampleIntakeEnabled;
    this.vm.sampleIntake.selected = sampleData.sampleIntake;
    this.vm.sampleLocation.selected = sampleData.sampleLocation;
    this.vm.sampleTaker = sampleData.sampleTaker;
    this.vm.sampleTemperature = sampleData.sampleTemperature;
    this.vm.sampleTime = sampleData.sampleTime;
    this.vm.sampleWeather.selected = sampleData.sampleWeather;
    this.vm.suggestedActions.selected = sampleData.suggestedActions;
    if (sampleData.sampleTime) {
      const time = DateUtils.parseDateFromTillFromReport(sampleData.sampleTime);
      this.vm.sampleTimeFrom = { value: time, text: time };
    }
  }

  public set report(report: Report) {
    this.vm.sampleLocation.items = report.sampleLocation;
    this.vm.sampleWeather.items = report.sampleWeather;
    this.vm.sampleIntake.items = report.sampleIntake;
    this.vm.suggestedActions.items = report.suggestedActions;
  }

  public set serviceEngineerResponse(
    serviceEngineerResponse: FormResponse<Employee>
  ) {
    if (!serviceEngineerResponse.error || !serviceEngineerResponse.loading) {
      if (serviceEngineerResponse.data) {
        this.vm.sampleTaker = `${serviceEngineerResponse.data.firstname} ${serviceEngineerResponse.data.lastname}`;
      } else {
        this.vm.sampleTaker = "";
      }
    }
  }
}
