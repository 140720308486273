








































































































import Vue from "vue";
import Component from "vue-class-component";

import Accordion from "@/components/layout/Accordion.vue";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedCombobox from "@/components/form/vee-validate/ValidatedCombobox.vue";
import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";
import ValidatedSwitch from "@/components/form/vee-validate/ValidatedSwitch.vue";
import ValidatedDatepicker from "@/components/form/vee-validate/ValidatedDatepicker.vue";
import ValidatedMonthpicker from "@/components/form/vee-validate/ValidatedMonthpicker.vue";

import { ReportAccordionViewModel } from "@/report/vms/ReportAccordionViewModel";
import { ReportAccordionPresenter } from "@/report/presenters/ReportAccordionPresenter";
import { ReportAccordionController } from "@/report/controllers/ReportAccordionController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";

@Component<ReportAccordion>({
  components: {
    Accordion,

    Container,
    Column,
    Row,

    ValidatedTextfield,
    ValidatedSwitch,
    ValidatedSelect,
    ValidatedCombobox,
    ValidatedDatepicker,
    ValidatedMonthpicker
  },
  props: {
    plantType: {
      type: String
    },
    editEnabled: {
      type: Boolean
    },
    report: {
      type: Object
    },
    reportData: {
      type: Object
    },
    shown: Boolean
  }
})
export default class ReportAccordion extends Vue {
  public vm = new ReportAccordionViewModel();
  private controller?: ReportAccordionController;

  private initController(): ReportAccordionController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new ReportAccordionController(new ReportAccordionPresenter(this.vm));
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$props.report, this.$props.reportData);
  }
}
