export class SignatureTabController {
  public constructor(private presenter: ISignatureTabPresenter) {}

  public mounted(cannotTakeSignature: boolean, signature: string) {
    this.presenter.cannotTakeSignature = cannotTakeSignature;
    this.presenter.signature = signature;
  }

  public get data() {
    return {
      cannotTakeSignature: this.presenter.cannotTakeSignature,
      signature: this.presenter.signature
    };
  }

  public setSignature(signatureData: any) {
    if (signatureData.data) {
      this.presenter.signature = signatureData.data;
    } else {
      this.presenter.signature = signatureData;
    }
  }
}

export interface ISignatureTabPresenter {
  cannotTakeSignature: boolean;
  signature: string;
}
