import { IEmployeeListPresenter } from "../controllers/EmployeeListController";
import { EmployeeListViewModel } from "../vms/EmployeeListViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { EmployeeTableRows } from "../services/EmployeeService";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";

export class EmployeeListPresenter implements IEmployeeListPresenter {
  public constructor(private vm: EmployeeListViewModel) {
    this.initStaticTexts();
  }

  public set addNewEmployee(add: boolean) {
    this.vm.addNewEmployee = add;
  }

  public set employeeToEdit(id: string) {
    this.vm.employeeToEdit = id;
  }

  public set selectedTourDataEmployee(employeeId: string) {
    this.vm.tourDataDialogVisible = !!employeeId;
    this.vm.selectedTourDataEmployee = employeeId;
  }

  public set loadEmployeesResponse(response: FormResponse<EmployeeTableRows>) {
    this.vm.loadEmployeesRequest = response;

    if (response.success) {
      this.vm.employees = response.data.map(employee => ({
        id: employee.id,
        abbreviation: employee.abbreviation,
        name: employee.firstname + " " + employee.lastname,
        phoneNumber:
          CountriesStorageHandler.getAreaCodeById(employee.areaCode) +
          " " +
          employee.phoneNumber,
        active: employee.active
      }));
    }
  }

  private initStaticTexts() {
    this.vm.title = "Mitarbeiter";
    this.vm.addNewEmployeeButtonText = "Neuer Mitarbeiter";
    this.vm.editEmployeeButtonText = "Mitarbeiter bearbeiten";
    this.vm.editTourDataButtonText = "Tourdaten bearbeiten";
  }
}
