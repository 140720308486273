var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"onExpandTable",style:(_vm.cssProperties)},[_c('v-data-table',{attrs:{"hide-default-footer":"","dense":_vm.dense,"hide-default-header":!!_vm.titleInsteadHeaders,"headers":_vm.headers,"items":_vm.plants,"mobile-breakpoint":0,"show-select":_vm.showSelect,"show-expand":_vm.showExpand,"value":_vm.plants.filter(function (el) { return _vm.selected.some(function (sel) { return sel === el.id; }); })},on:{"item-selected":function($event){return _vm.itemSelected($event)},"item-expanded":function($event){return _vm.expanded($event)},"click:row":function($event){return _vm.rowClicked($event)},"toggle-select-all":function($event){return _vm.allSelected($event)}},scopedSlots:_vm._u([(!!_vm.titleInsteadHeaders)?{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":headers.length}},[_vm._v(_vm._s(_vm.titleInsteadHeaders))])])])]}}:null,{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.street)+", "+_vm._s(item.zip)+" "+_vm._s(item.city)+" - "+_vm._s(_vm.parseCountry(item.country))+" ")]}},{key:"item.extra",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.parseGroupAndType(item.extra)))]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td'),_c('td',{attrs:{"colspan":headers.length - 1}},[_c('OperatorSearch',{staticClass:"elevation-1",attrs:{"titleInsteadHeaders":"Betreiber der Anlage","dense":true,"hideHeaders":true,"headers":headers,"operators":_vm.expandedItems.filter(function (el) { return el.otherId === item.id; })},on:{"row-clicked":function($event){return _vm.subRowClicked($event)}}})],1)]}}],null,true)}),(!!_vm.plantsPage)?_c('Pagination',{attrs:{"value":_vm.plantsPage,"itemsPerPage":_vm.plantsPerPage,"totalItems":_vm.totalPlants},on:{"input":function($event){return _vm.pageSwitched($event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }