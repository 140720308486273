import { Auth } from "@/common/utils/Auth";
import { FormResponse } from "@/forms/FormResponse";
import { DateUtils } from "@/utils/DateUtils";
import { ICreateStockTakingPresenter } from "../controllers/CreateStockTakingController";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { StockTaking } from "../services/StockTakingGraphQLService";
import { Store } from "../services/StoreGraphQLService";
import { StoreItem } from "../services/StoreItemGraphQLService";
import { CreateStockTakingViewModel } from "../vms/CreateStockTakingViewModel";

export class CreateStockTakingPresenter implements ICreateStockTakingPresenter {
  public itemTemplateDict: { [key: string]: string } = {};
  public stockTakingId = "";
  private date = "";

  public constructor(private vm: CreateStockTakingViewModel) {}

  public set readonly(readonly: boolean) {
    this.vm.readonly = readonly;
  }

  public get readonly() {
    return this.vm.readonly;
  }

  public set visibleView(visibleView: boolean) {
    this.vm.visibleView = visibleView;
  }

  public set getStockTakingResponse(response: FormResponse<any>) {
    if (!response.loading && !response.error) {
      this.vm.storeFilter.selected = response.data.storeId;
      this.vm.storeFilter.items = [
        { text: response.data.store.name, value: response.data.storeId }
      ];

      this.vm.stockTakingItems = response.data.items.map((el: any) => ({
        itemTemplateId: el.itemTemplateId,
        reportedAmount: el.reportedAmount || "0",
        storedAmount: el.storedAmount || "0"
      }));

      this.date = response.data.date;
    }
  }

  public set getStoresResponse(response: FormResponse<Store[]>) {
    if (!response.loading && !response.error) {
      this.vm.storeFilter.items = response.data.map(el => ({
        text: el.name,
        value: el.id
      }));
    }
  }

  public set getItemTemplatesResponse(response: FormResponse<ItemTemplate[]>) {
    if (!response.loading && !response.error) {
      for (const itemTemplate of response.data) {
        this.itemTemplateDict[itemTemplate.id] = itemTemplate.name;
      }

      this.vm.itemTemplates.items = response.data.map(el => ({
        text: el.name,
        value: el.id
      }));
    }
  }

  public set getStoreItemsResponse(response: FormResponse<StoreItem[]>) {
    if (!response.loading && !response.error) {
      this.vm.itemTemplates.items = [];

      // tslint:disable-next-line: forin
      for (const key in this.itemTemplateDict) {
        this.vm.itemTemplates.items.push({
          value: key,
          text: this.itemTemplateDict[key]
        });
      }

      this.vm.stockTakingItems = response.data.map(el => {
        const itemTemplateIndex = this.vm.itemTemplates.items.findIndex(
          template => template.value === el.itemTemplateId
        );
        if (itemTemplateIndex >= 0) {
          this.vm.itemTemplates.items.splice(itemTemplateIndex, 1);
        }

        return {
          itemTemplateId: el.itemTemplateId,
          reportedAmount: "",
          storedAmount: el.amount || "0"
        };
      }) as any[];
    }
  }

  public itemTemplateSelected(itemTemplateId: string) {
    this.vm.stockTakingItems.push({
      itemTemplateId,
      reportedAmount: 0,
      storedAmount: "0"
    } as any);

    const itemTemplateIndex = this.vm.itemTemplates.items.findIndex(
      el => el.value === itemTemplateId
    );
    this.vm.itemTemplates.items.splice(itemTemplateIndex, 1);
  }

  public get data() {
    const data: any = {
      date: this.date ? this.date : DateUtils.dateOnly(new Date()),
      storeId: this.vm.storeFilter.selected,
      employeeId: Auth.userId,
      items: this.vm.stockTakingItems.map(el => ({
        itemTemplateId: el.itemTemplateId,
        storedAmount: parseInt(el.storedAmount.toString(), 10),
        reportedAmount: parseInt(el.reportedAmount.toString(), 10)
      }))
    };

    if (this.readonly) {
      data.id = this.stockTakingId;
    }

    return data;
  }

  public set createStockTakingResponse(response: FormResponse<string>) {
    this.vm.loading = response.loading;
    this.vm.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.visibleView = false;
    }
  }
}
