

































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import PaginatedDatatable from "@/components/collection/PaginatedDatatable.vue";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { FormRequestHandler } from "../../forms/FormRequestHandler";
import { Plant } from "@/plant/services/PlantService";
import { DateUtils } from "@/utils/DateUtils";
import { WorkOrderGraphQLService } from "../services/WorkOrderGraphQLService";
import { Auth } from "../../common/utils/Auth";
import SystemKeys from "../../common/utils/SystemKeys";
import StorageKeys from "../../common/utils/StorageKeys";
import { TableHeader } from "../../forms/ViewModelFormTypes";

@Component<PlantWorkOrdersData>({
  components: {
    Card,
    Grid,
    Row,
    Column,
    PaginatedDatatable,
    CreateLooseAppointmentView: () =>
      import("@/tourPlanner/views/CreateLooseAppointmentView.vue")
  },
  props: ["plant", "plantRequest", "element"],
  watch: {
    plant() {
      this.loadWorkOrders();
    }
  }
})
export default class PlantWorkOrdersData extends Vue {
  private plant!: Plant;
  private workOrders: any[] = [];
  private workOrderHeaders: TableHeader[] = [
    { text: "Datum", value: "date", sortable: true, width: "20%" },
    { text: "Leistungsbeschreibung", value: "tasks", width: "50%" },
    { text: "Service-Techniker", value: "serviceEngineerName" },
    { text: "Dauer", value: "usedTime" }
  ];
  private pagination = {
    itemsPerPage: 10,
    pageNumber: 1,
    totalItems: 10
  };

  private connection = new AxiosGraphQLConnection(
    new AxiosLogger(new ConsoleLogger())
  );
  private workOrderService = new WorkOrderGraphQLService(this.connection);

  private mounted() {
    if (!!this.plant.id) {
      this.loadWorkOrders();
    }

    if (Auth.hasPermission(StorageKeys.approveWorkOrdersPermission)) {
      this.workOrderHeaders.splice(
        2,
        0,
        { text: "Preis", value: "price", sortable: true },
        { text: "HK", value: "hk", sortable: true }
      );
    }
  }

  private retry(event: any) {
    this.$emit("retry", event);
  }

  private pageChanged(page: number) {
    this.pagination.pageNumber = page;
    this.loadWorkOrders();
  }

  private getDate(item: any) {
    if (!!item.dateSentToApproval) {
      return DateUtils.format(item.dateSentToApproval, "DD.MM.YYYY");
    }
    if (!!item.plannedDate) {
      return DateUtils.format(item.plannedDate, "DD.MM.YYYY");
    }
    return DateUtils.format(item.date, "DD.MM.YYYY");
  }

  private getDuration(item: any) {
    return `${(item.usedTime / 60).toFixed(2)} h`;
  }

  private loadWorkOrders() {
    const request = this.workOrderService.getAllAtLeastFinishedWorkOrdersByPlantId(
      this.plant.id,
      this.pagination
    );
    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading && !response.error) {
          this.workOrders = response.data.items;
          this.pagination.totalItems = response.data.totalCount;
        }
      },
      "get-work-orders-failed"
    );
  }

  private getTasks(tasks: string[]) {
    if (tasks && tasks.length > 0) {
      return tasks.join("; ");
    }
    return "";
  }

  private openWorkOrder(event: any) {
    this.$router.push({
      name: "work-order",
      params: { workOrderId: event.id }
    });
  }
}
