














































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import Textfield from "@/components/form/Textfield.vue";
import CheckboxGroup from "@/components/form/CheckboxGroup.vue";

import RequestButton from "@/components/basic/RequestButton.vue";

import { UserRoleDetailController } from "@/employee/controllers/UserRoleDetailController";
import { UserRoleDetailPresenter } from "@/employee/presenters/UserRoleDetailPresenter";
import { UserRoleDetailViewModel } from "@/employee/vms/UserRoleDetailViewModel";
import { GraphQLUserRoleService } from "../services/UserRoleService";
import { GraphQLPermissionService } from "../services/PermissionService";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";

@Component<UserRoleDetailView>({
  components: {
    Container,
    Column,
    Row,
    Page,
    Card,
    Section,
    Textfield,
    CheckboxGroup,
    RequestButton
  },
  watch: {
    "vm.savedRoleId"(id: string) {
      if (!!id) {
        this.$router.push({ name: "user-roles" });
      }
    },
    "vm.deletedRoleId"(id: string) {
      if (!!id) {
        this.$router.push({ name: "user-roles" });
      }
    }
  }
})
export default class UserRoleDetailView extends Vue {
  private vm = new UserRoleDetailViewModel();
  private controller: any;

  private get id() {
    return this.$route.params.id;
  }

  private created() {
    this.controller = this.createController();
  }

  private mounted() {
    this.controller.init(this.id);
  }

  private createController(): UserRoleDetailController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new UserRoleDetailController(
      new UserRoleDetailPresenter(this.vm),
      new GraphQLUserRoleService(connection),
      new GraphQLPermissionService(connection)
    );
  }
}
