import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import {
  GraphQLConnection,
  OrderByClause
} from "@/gateways/graphql/GraphQLConnection";

export class MaterialRequestGraphQLService {
  public constructor(private connection: GraphQLConnection) {}

  public async getAllMaterialRequestsPaginated(
    page: Page,
    order: OrderByClause[],
    search: string
  ): Promise<PaginatedList<MaterialRequest>> {
    const request = await this.connection.queryPaginated(
      "materialRequestsPaginated",
      page.itemsPerPage,
      page.page,
      [
        "id",
        "date",
        "employeeId",
        {
          name: "employee",
          fields: ["firstname", "lastname"]
        },
        {
          name: "items",
          fields: ["name", "amount"]
        },
        "done"
      ],
      search,
      ["employee.firstname", "employee.lastname"],
      order
    );

    return {
      items: request.data,
      totalCount: request.count
    };
  }

  public async getMaterialRequestById(id: string) {
    const request = await this.connection.query(
      "materialRequest",
      {
        id
      },
      [
        "id",
        "date",
        "employeeId",
        {
          name: "items",
          fields: ["name", "amount"]
        }
      ]
    );

    return request.data;
  }

  public async setMaterialRequestDone(id: string) {
    const request = await this.connection.mutation(
      "setMaterialRequestDone",
      {
        id
      },
      []
    );

    return request.data;
  }

  public async updateMaterialRequest(input: any) {
    const request = await this.connection.mutation(
      "updateMaterialRequest",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async createMaterialRequest(input: any) {
    const request = await this.connection.mutation(
      "createMaterialRequest",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async deleteMaterialRequest(id: string) {
    const request = await this.connection.mutation(
      "deleteMaterialRequest",
      {
        id
      },
      []
    );

    return request.data;
  }
}

export interface MaterialRequest {
  id: string;
  date: string;
  employeeId: string;
  items: MaterialRequestItem[];
  done: boolean;
}

export interface MaterialRequestItem {
  name: string;
  amount: number;
}
