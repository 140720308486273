



























import Vue from "vue";

import ConfirmDialog from "@/components/basic/ConfirmDialog.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

export default Vue.extend({
  name: "LoadingContainer",
  inheritAttrs: false,
  components: {
    ConfirmDialog,
    Row,
    Column
  },
  props: {
    margin: {
      type: Number,
      default: 3
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    }
  }
});
