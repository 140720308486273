import { FormResponse } from "@/forms/FormResponse";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { MultiSelect } from "@/forms/ViewModelFormTypes";
import { DateUtils } from "@/utils/DateUtils";
import { AxiosGoogleMapsConnection } from "@/gateways/AxiosGoogleMapsConnection";
import {
  IAppointmentService,
  Appointment
} from "../services/AppointmentGraphQLService";
import { mapIcons } from "@/data/settings";
import { Auth } from "@/common/utils/Auth";
import { AppointmentMapFilterForm } from "../forms/AppointmentMapFilterForm";

export class AppointmentMapController {
  public secondsToWait = 1;
  // tslint:disable-next-line: no-empty
  private endOfInputTimer: NodeJS.Timeout = setTimeout(() => {}, 0);
  private countDown = 0;

  private google: any;
  private map: any;

  public constructor(
    private presenter: IAppointmentMapPresenter,
    private googleMapsConnection: AxiosGoogleMapsConnection,
    private appointmentService: IAppointmentService
  ) {}

  public mounted(filters?: string) {
    this.presenter.filterForm.setFieldValue(
      "startDate",
      DateUtils.toISOString(DateUtils.getFirstOfYear())
    );

    this.presenter.filterForm.setFieldValue(
      "endDate",
      DateUtils.toISOString(DateUtils.endOfMonth(new Date().getMonth()))
    );

    this.presenter.selectedTourPlannerId = this.getCurrentlyLoggedInUser();
    const request = this.googleMapsConnection.requestCoordinates(
      this.presenter.center
    );
    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading) {
          this.presenter.centerAddress = response.data;
        }
      },
      "get-coordinates-failed"
    );

    if (filters) {
      const filtersObj = JSON.parse(filters);
      this.startDateChanged(filtersObj.startDate.value);
      this.endDateChanged(filtersObj.endDate.value);
      this.showAppointmentsChanged(filtersObj.showAppointments);
      this.showMaintenancesChanged(filtersObj.showMaintenances);
      for (const state of filtersObj.states) {
        this.stateSelected(state);
      }
    }
  }

  public mapInitialized(map: any) {
    this.map = map;

    if (this.google) {
      this.makeMarkers();
    }
  }

  public googleInitialized(google: any) {
    this.google = google;

    if (this.map) {
      this.makeMarkers();
    }
  }

  public startDateChanged(newDate: string) {
    this.presenter.filterForm.setFieldValue("startDate", newDate);
    this.startTimer();
  }

  public endDateChanged(newDate: string) {
    this.presenter.filterForm.setFieldValue("endDate", newDate);
    this.startTimer();
  }

  public showMaintenancesChanged(isTicked: boolean) {
    this.presenter.showMaintenances = isTicked;
    this.makeMarkers(true);
  }

  public showAppointmentsChanged(isTicked: boolean) {
    this.presenter.showAppointments = isTicked;
    this.makeMarkers(true);
  }

  public centerTextChanged(newCenter: string) {
    const request = this.googleMapsConnection.requestAddress(newCenter);
    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading) {
          this.presenter.center = {
            lat: response.data.lat,
            lng: response.data.lng
          };
        }
      },
      "get-location-failed"
    );
  }

  public locationDragged(location: any) {
    this.getCenterAddress(location.latLng.lat(), location.latLng.lng());
  }

  public stateSelected(state: any) {
    if (Array.isArray(state)) {
      this.presenter.states.selected = state;
    } else {
      if (this.presenter.states.selected.indexOf(state) > -1) {
        this.presenter.states.selected.splice(
          this.presenter.states.selected.indexOf(state),
          1
        );
      } else {
        this.presenter.states.selected.push(state);
      }
    }

    this.startTimer();
  }

  public formatDate(date: string) {
    return DateUtils.format(date);
  }

  public appointmentSelected(appointmentId: string) {
    if (this.presenter.selectedTourPlannerId !== "") {
      const request = this.appointmentService.selectAppointment(
        this.presenter.selectedTourPlannerId,
        appointmentId
      );
      FormRequestHandler.handle(
        request,
        response => {
          if (!response.loading && !response.error) {
            this.presenter.removeMarker(appointmentId);
            this.makeMarkers();
          }
        },
        `Konnte Appointment mit Id ${appointmentId} nicht auswählen.`
      );
    }
  }

  public removeFromSelectionClicked(index: number) {
    if (this.presenter.selectedTourPlannerId !== "") {
      const selectedAppointment = this.presenter.selectedAppointments[index];
      const request = this.appointmentService.unselectAppointment(
        selectedAppointment.id
      );
      FormRequestHandler.handle(
        request,
        response => {
          if (!response.loading && !response.error) {
            this.presenter.selectedAppointments.splice(index, 1);
            this.makeMarkers();
          }
        },
        "unselect-appointment-failed"
      );
    }
  }

  public getCurrentlyLoggedInUser() {
    return Auth.userId;
  }

  // 0 = green
  // 1 = red
  // 2 = yellow
  public getIconUrl(appointment: Appointment) {
    let iconUrlIndex = 0;
    const todayBeforeTwoWeeks = new Date();
    todayBeforeTwoWeeks.setDate(todayBeforeTwoWeeks.getDate() - 14);
    todayBeforeTwoWeeks.setHours(0, 0, 0, 0);
    const dateToCheck = new Date(appointment.date);
    dateToCheck.setHours(0, 0, 0, 0);
    if (dateToCheck < todayBeforeTwoWeeks) {
      iconUrlIndex = 2;
    } else if (appointment.type === "MAINTENANCE") {
      iconUrlIndex = 1;
      // this.plant = appointment.plant;
    } else if (
      appointment.type === "LOOSE" ||
      appointment.type === "SPONTANEOUS"
    ) {
      iconUrlIndex = 0;
    }
    return mapIcons[iconUrlIndex];
  }

  private async makeMarkers(reload = false) {
    if (this.google && this.map) {
      this.getAlreadySelectedAppointments();

      if (reload) {
        this.presenter.clearAllMarkers();
      }

      const types: string[] = [];
      if (this.presenter.showMaintenances) {
        types.push("MAINTENANCE");
        types.push("SPONTANEOUS");
      }
      if (this.presenter.showAppointments) {
        types.push("LOOSE");
      }

      this.presenter.map = this.map;
      this.presenter.google = this.google;

      const request = this.appointmentService.getAppointmentsForMap(
        this.presenter.startDate,
        this.presenter.endDate,
        types,
        this.presenter.states.selected
      );

      FormRequestHandler.handle(
        request,
        response => (this.presenter.getMapAppointmentsResponse = response),
        "Konnte Appointments nicht laden!"
      );
    }
  }

  private getAlreadySelectedAppointments() {
    const request = this.appointmentService.getAllSelectedAppointmentsByTourPlanner(
      this.presenter.selectedTourPlannerId
    );

    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading) {
          this.presenter.selectedAppointments = response.data;
        }
      },
      "get-selected-appointments-failed"
    );
  }

  private getCenterAddress(lat: number, lng: number) {
    const request = this.googleMapsConnection.requestCoordinates({ lat, lng });
    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading) {
          this.presenter.centerAddress = response.data;
        }
      },
      "get-coordinates-failed"
    );
  }

  private startTimer() {
    clearInterval(this.endOfInputTimer);
    this.countDown = this.secondsToWait;
    this.endOfInputTimer = setInterval(() => {
      this.countDown--;
      if (this.countDown <= 0) {
        clearInterval(this.endOfInputTimer);
        this.makeMarkers(true);
      }
    }, 1000);
  }
}

export interface IAppointmentMapPresenter {
  filterForm: AppointmentMapFilterForm;
  google: any;
  map: any;
  getMapAppointmentsResponse: FormResponse<MapAppointment[]>;
  selectedAppointments: Appointment[];
  center: object;
  centerAddress: string;

  showMaintenances: boolean;
  showAppointments: boolean;
  startDate: string;
  endDate: string;
  states: MultiSelect;

  selectedTourPlannerId: string;

  removeMarker(appointmentId: string): void;
  clearAllMarkers(): void;
}

export interface MapAppointment extends google.maps.Marker {
  id: string;
  position: {
    lat: number;
    lng: number;
  };
  infoWindowContent: MapAppointmentInfoWindowContent;
  icon: string;
}

interface MapAppointmentInfoWindowContent {
  plantIndex?: number;
  plantGroup?: string;
  plantType?: string;
  operators: string[];
  address: string;
  date: string;
  type: string;
  reason: string;
}
