












































































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import PlantSearch from "@/components/layout/PlantSearch.vue";

import Textfield from "@/components/form/Textfield.vue";
import SimpleCheckbox from "@/components/form/SimpleCheckbox.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

import { SearchViewModel } from "@/common/vms/SearchViewModel";
import { SearchPresenter } from "@/common/presenters/SearchPresenter";
import { SearchController } from "@/common/controllers/SearchController";
import { SearchInteractor } from "@/common/interactors/SearchInteractor";

import { LocalStorage } from "../../storage/LocalStorage";
import { CreateOperatorViewModel } from "@/plant/vms/CreateOperatorViewModel";
import { CreateOperatorPresenter } from "@/plant/presenters/CreateOperatorPresenter";
import { CreateOperatorController } from "@/plant/controllers/CreateOperatorController";
import { OperatorInteractor } from "@/secretary/interactors/OperatorInteractor";

import { OperatorGraphQLGateway } from "@/gateways/graphql/OperatorGraphQLGateway";
import { OperatorGraphQLService } from "@/plant/services/OperatorGraphQLService";
import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";

import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { PlantGraphQLGateway } from "@/gateways/graphql/PlantGraphQLGateway";
import { PlantGraphQLService } from "../services/PlantGraphQLService";

@Component<CreateOperatorView>({
  components: {
    Container,
    Column,
    Row,
    Textfield,
    SimpleCheckbox,
    RequestButton,
    PlantSearch,
    CreatePlantView: () => import("@/plant/views/CreatePlantView.vue")
  },
  props: {
    showPlantSearch: {
      type: Boolean,
      default() {
        return true;
      }
    },
    operatorId: {
      type: String,
      default() {
        return "";
      }
    }
  },
  watch: {
    "vm.goBack"() {
      this.vm.visibleView = false;
      this.$destroy();
      if (this.$router.currentRoute.name === "operator-create") {
        this.$router.back();
      }
    },
    "vm.createdOperator"() {
      this.vm.visibleView = false;
      this.$emit("finished");
      this.$destroy();
      if (this.$router.currentRoute.name === "operator-create") {
        this.$router.push({
          name: "operator",
          params: { operatorId: this.vm.createdOperator }
        });
      }
    }
  }
})
export default class CreateOperatorView extends Vue {
  private vm = new CreateOperatorViewModel();
  private step = 1;
  private searchVm = new SearchViewModel();
  private components: any[] = [];
  private showPlantSearch!: boolean;

  private get searchText() {
    return this.$route.params.searchText;
  }

  private get searchController() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new SearchController(
      new SearchPresenter(this.searchVm),
      new PlantGraphQLService(connection)
    );
  }

  private get controller(): CreateOperatorController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreateOperatorController(
      new CreateOperatorPresenter(this.vm, new LocalStorage()),
      new OperatorGraphQLService(connection)
    );
  }

  private mounted() {
    this.controller.mounted(this.$props.operatorId);
    if (this.showPlantSearch) {
      this.searchController.init(this.searchText);
    }
  }

  private goToPlantCreate() {
    this.components.push("CreatePlantView");
  }
}
