






import Vue from "vue";
import Component from "vue-class-component";

@Component<Dialog>({
  props: {
    value: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: String,
      default: "80%"
    }
  }
})
export default class Dialog extends Vue {
  private emitInput(value: boolean) {
    this.$emit("input", value);
  }
}
