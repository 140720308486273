import { Form, FormFieldType } from "@/forms/Form";

export class WorkOrderReportDataForm extends Form {
  public definition = {
    sampleLocation: { required: false, type: FormFieldType.Selection },
    sampleWeather: { required: false, type: FormFieldType.Text },
    sampleTemperature: { required: false, type: FormFieldType.Text },
    sampleIntake: { required: false, type: FormFieldType.Selection },
    sampleTimeFrom: { required: false, type: FormFieldType.Time },

    plantStatus: { required: false, type: FormFieldType.Selection },

    suggestedActions: { required: false, type: FormFieldType.Text }
  };
}
