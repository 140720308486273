




























import Vue from "vue";
import Component from "vue-class-component";

@Component<Textfield>({
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: "", error: "" };
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: ""
    },
    password: {
      type: Boolean,
      default: false
    },
    number: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    },
    extraFlat: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: undefined
    }
  }
})
export default class Textfield extends Vue {
  private get type(): string {
    return this.$props.password
      ? "password"
      : this.$props.number
      ? "number"
      : "text";
  }
}
