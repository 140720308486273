import { PlantViewModel } from "../vms/PlantViewModel";
import { IPlantPresenter } from "../controllers/PlantController";
import { FormResponse } from "@/forms/FormResponse";
import { Plant } from "../services/PlantService";
import { Checkbox } from "@/forms/ViewModelFormTypes";
import StorageKeys from "@/common/utils/StorageKeys";
import { Auth } from "@/common/utils/Auth";
import { ParameterStorageHandler } from "@/storage/storageHandlers/ParameterStorageHandler";
import SystemKeys from "@/common/utils/SystemKeys";
import { LocalStorage } from "@/storage/LocalStorage";
import { DateUtils } from "@/utils/DateUtils";

export class PlantPresenter implements IPlantPresenter {
  private storage = new LocalStorage();

  public constructor(private vm: PlantViewModel) {
    if (Auth.hasPermission(StorageKeys.approveWorkOrdersPermission)) {
      this.vm.filters.splice(5, 0, this.vm.maintenanceContractCheckbox);
    }
  }

  public set plantResponse(response: FormResponse<Plant>) {
    this.vm.plantRequest = response;

    if (!response.loading && !response.error) {
      if (response.data.id === "-99") {
        this.vm.noSuchPlant = true;
      } else {
        this.vm.plant = response.data;

        let parameters = response.data.parameters.map(param =>
          ParameterStorageHandler.getParameterFromId(
            param.parameterId,
            param.value
          )
        );
        parameters = parameters.sort((a, b) =>
          !!a.name && !!b.name ? a.name.localeCompare(b.name) : 0
        );
        this.vm.plant.parameters = parameters;

        this.vm.plant.plantInstallationParts = this.parsePlantInstallationParts(
          this.vm.plant.plantInstallationParts
        );

        this.vm.plant.plantParts = this.parsePlantParts(
          this.vm.plant.plantParts
        );
      }
    }
  }

  public set location(location: any) {
    this.vm.location = location;
  }

  public get filters(): any[] {
    return this.vm.filters;
  }

  public set filters(filters: any[]) {
    this.vm.filters = filters;
  }

  private parsePlantInstallationParts(plantInstallationParts: any[]) {
    return plantInstallationParts.map(param => ({
      ...param,
      name: this.getPlantInstallationPartNameById(param.parameterId)
    }));
  }

  private getPlantInstallationPartNameById(plantPartId: string) {
    if (this.storage.has(SystemKeys.plantInstallationPartsTableKey)) {
      const entries = JSON.parse(
        this.storage.get(SystemKeys.plantInstallationPartsTableKey)
      );
      const index = entries.findIndex((el: any) => el.id === plantPartId);

      if (index >= 0) {
        return entries[index].values[SystemKeys.plantInstallationPartsNameKey];
      }
    }
  }

  private parsePlantParts(plantParts: any[]) {
    return plantParts.map(param => ({
      ...param,
      name: this.getPlantPartNameById(param.parameterId)
    }));
  }

  private getPlantPartNameById(plantPartId: string) {
    if (this.storage.has(SystemKeys.plantPartsTableKey)) {
      const entries = JSON.parse(
        this.storage.get(SystemKeys.plantPartsTableKey)
      );
      const index = entries.findIndex((el: any) => el.id === plantPartId);

      if (index >= 0) {
        return entries[index].values[SystemKeys.plantPartsNameKey];
      }
    }
  }
}
