import { LocalStorage } from "@/storage/LocalStorage";
import SystemKeys from "@/common/utils/SystemKeys";
import { WorkOrder } from "../services/WorkOrderService";
import { DateUtils } from "@/utils/DateUtils";

export class WorkOrderStorage {
  private localStorage = new LocalStorage();

  private itemsToSave: WorkOrdersToSave = {
    timestamp: 0,
    workOrders: []
  };

  constructor() {
    const entries = this.localStorage.get(SystemKeys.storedWorkOrdersKey);
    if (!!entries) {
      this.itemsToSave = JSON.parse(entries);
    }
  }

  public set workOrders(workOrders: WorkOrder[]) {
    this.itemsToSave.workOrders = workOrders.map(wo => ({
      changed: false,
      entity: wo
    }));
    this.itemsToSave.timestamp = new Date().getTime();
  }

  public get workOrders() {
    return this.itemsToSave.workOrders.map(fe => {
      const wo = fe.entity;
      wo.plannedDate = DateUtils.getDate(fe.entity.plannedDate);
      wo.createdAt = DateUtils.getDate(fe.entity.createdAt);
      wo.updatedAt = DateUtils.getDate(fe.entity.updatedAt);
      wo.syncing = fe.changed;
      return wo;
    });
  }

  public get timestamp() {
    return this.itemsToSave.timestamp;
  }

  public storeWorkOrders(workOrdersToStore: WorkOrder[]) {
    const unsyncedWorkOrders = this.itemsToSave.workOrders.filter(
      wo => wo.changed
    );

    const upToDateWorkOrdersToStore = workOrdersToStore.filter(
      wo =>
        !unsyncedWorkOrders.find(unsyncedWo => unsyncedWo.entity.id === wo.id)
    );

    this.workOrders = upToDateWorkOrdersToStore;
    this.itemsToSave.workOrders.push(...unsyncedWorkOrders);
  }

  public save() {
    this.localStorage.set(
      SystemKeys.storedWorkOrdersKey,
      JSON.stringify(this.itemsToSave)
    );
  }

  public getWorkOrderById(id: string) {
    const flaggedEntity = this.itemsToSave.workOrders.find(
      fe => fe.entity.id === id
    );

    if (!flaggedEntity) {
      throw new Error("Could not find WorkOrder with id: " + id);
    }

    flaggedEntity.entity.syncing = flaggedEntity.changed;

    return flaggedEntity.entity;
  }

  public updateWorkOrder(
    id: string,
    processingState: string,
    serviceEngineerId: string,
    usedTime: number,
    tasksDone: string[],
    materials: string,
    defects: string[],
    comments: string[],
    cannotTakeSignature: boolean,
    signature: string,
    reportData: any,
    operatorData: any,
    plantData: any,
    price: number | null,
    hk: number
  ) {
    const flaggedEntity = this.itemsToSave.workOrders.find(
      fe => fe.entity.id === id
    );

    if (!flaggedEntity) {
      // tslint:disable-next-line:no-console
      console.error("Could not find WorkOrder with id: " + id);
      return;
    }

    flaggedEntity.changed = true;

    const workOrder = flaggedEntity.entity;
    workOrder.processingState = processingState;
    workOrder.serviceEngineerId = serviceEngineerId;
    workOrder.usedTime = usedTime;
    workOrder.tasksDone = tasksDone;
    workOrder.materials = JSON.parse(materials);
    workOrder.defects = defects;
    workOrder.comments = comments;
    workOrder.cannotTakeSignature = cannotTakeSignature;
    workOrder.signature = signature;
    workOrder.reportData = reportData;
    workOrder.price = price;
    workOrder.hk = hk;
    workOrder.updatedAt = new Date();
    if (processingState === "TO_CHECK" && !workOrder.dateSentToApproval) {
      workOrder.dateSentToApproval = new Date();
    }
    flaggedEntity.operatorData = operatorData;
    flaggedEntity.plantData = plantData;

    this.save();
  }

  public getToSyncWorkOrders() {
    this.load();
    return this.itemsToSave.workOrders.filter(fe => fe.changed);
  }

  public setWorkOrderChanged(id: string, changed: boolean) {
    const index = this.itemsToSave.workOrders.findIndex(
      el => el.entity.id === id
    );
    if (index >= 0) {
      this.itemsToSave.workOrders[index].changed = changed;
      this.save();
    }
  }

  private load() {
    const entries = this.localStorage.get(SystemKeys.storedWorkOrdersKey);
    if (!!entries) {
      this.itemsToSave = JSON.parse(entries);
    }
  }
}

interface WorkOrdersToSave {
  timestamp: number;
  workOrders: Array<FlaggedEntity<WorkOrder>>;
}

interface FlaggedEntity<T> {
  changed: boolean;
  entity: T;
  operatorData?: any;
  plantData?: any;
}
