import SystemKeys from "@/common/utils/SystemKeys";
import { StorageHandler } from "./StorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";

export class DefectsStorageHandler extends StorageHandler {
  public static getAllDefects(useIdValues: boolean = true): SelectionItem[] {
    const nameKey = useIdValues ? undefined : SystemKeys.defectsNameKey;

    return this.buildTopLevelSelection(
      SystemKeys.defectsTableKey,
      SystemKeys.defectsNameKey,
      nameKey
    );
  }
}
