export default {
  id: "id",
  values: "values",

  countriesTableKey: "Land",
  countriesNameKey: "Bezeichnung",
  countriesAreaCodeKey: "Vorwahl",

  statesTableKey: "Bundesländer",
  statesNameKey: "Bezeichnung",

  itemTemplateTypeTableKey: "Artikelart",
  itemTemplateTypeNameKey: "Bezeichnung",

  itemTemplateGroupTableKey: "Artikelgruppe",
  itemTemplateGroupNameKey: "Bezeichnung",

  itemTemplateUnitTableKey: "Artikeleinheit",
  itemTemplateUnitNameKey: "Bezeichnung",

  salutationsTableKey: "Anrede",
  salutationsLongFormKey: "Langform",
  salutationsShortFormKey: "Kurzform",

  groupsTableKey: "Anlagengruppe",
  groupsNameKey: "Bezeichnung",

  typesTableKey: "Anlagentyp",
  typesNameKey: "Bezeichnung",
  typesSizeKey: "Anlagengrößen",
  typesReportKey: "Prüfbericht",

  reportsTableKey: "Prüfbericht",
  reportsNameKey: "Bezeichnung",
  reportsSampleLocationKey: "Probenahmestellen",
  reportsSampleWeatherKey: "Wetter",
  reportsSampleIntakeKey: "Zuläufe",
  reportsSampleSuggestedActionsKey: "Empfohlene Maßnahmen",
  reportsSectionKey: "Abschnitte",
  reportsSectionNameKey: "Bezeichnung",
  reportsSectionEnabledKey: "Abfrage auf Vorhandensein",
  reportsFieldKey: "Felder",
  reportsFieldNameKey: "Bezeichnung",
  reportsFieldPrefixKey: "Prefix",
  reportsFieldSuffixKey: "Suffix",
  reportsFieldEnabledKey: "Abfrage auf Vorhandensein",
  reportsFieldTypeKey: "Type",
  reportsOptionKey: "Optionen",
  reportsOptionNameKey: "Bezeichnung",

  sizesTableKey: "Anlagengröße",
  sizesNameKey: "Bezeichnung",
  sizesUnitKey: "Einheit",
  sizesCompareKey: "Vergleichen",

  parametersTableKey: "Parameter",
  parametersNameKey: "Bezeichnung",
  parametersToCheckKey: "Vorortprüfung",
  parametersUnitKey: "Einheit",
  parametersLimitKey: "Limit",
  parametersTypeKey: "Typ",
  parametersOptionsKey: "Optionen",
  parametersOptionsNameKey: "Bezeichnung",

  plantPartsTableKey: "Anlagenteil",
  plantPartsNameKey: "Bezeichnung",

  plantInstallationPartsTableKey: "Einbauteil",
  plantInstallationPartsNameKey: "Bezeichnung",

  districtAuthorityTableKey: "Bezirkshauptmannschaft",
  districtAuthorityNameKey: "Bezeichnung",

  materialTableKey: "Material",
  materialNameKey: "Bezeichnung",

  taskTableKey: "Durchgeführte Arbeit",
  taskNameKey: "Bezeichnung",

  defectsTableKey: "Mangel",
  defectsNameKey: "Bezeichnung",

  vpiTableKey: "VPI",
  vpiNameKey: "Bezeichnung",

  salesPartnerTableKey: "Vertriebspartner",
  salesPartnerNameKey: "Bezeichnung",

  storedWorkOrdersKey: "Arbeitsaufträge",
  storedPlantsKey: "Anlagen",
  storedStoreItemsKey: "Artikel",
  storedStoreMovementsKey: "Artikelbewegungen",
  storedStoresKey: "Lagerorte",
  storedItemTemplatesKey: "Artikelvorlagen",

  labsTableKey: "Labor",
  labsMailKey: "E-Mail-Adresse",

  timerTableKey: "Timer",

  customerServiceReasonsTableKey: "Kdb-Grund",
  customerServiceReasonsNameKey: "Bezeichnung",
  customerServiceReasonsAdditionalFieldKey: "Zusätzliches Textfeld",

  customerServicePlantTypeTableKey: "Kdb-Anlagentyp",
  customerServicePlantTypeNameKey: "Bezeichnung",

  deliveryWayTableKey: "Zustellart",
  deliveryWayNameKey: "Bezeichnung"
};
