<template>
  <v-dialog
    v-model="this.visibleView"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    style="overflow: hidden;"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <Container :margin="0" fluid fill-height>
          <Row align-center>
            <Column shrink ml-2>
              <v-btn icon dark @click="close()">
                <v-icon>close</v-icon>
              </v-btn>
            </Column>
            <Column shrink ml-4>
              <v-toolbar-title>Tagesroute</v-toolbar-title>
            </Column>
            <Column grow></Column>
          </Row>
        </Container>
      </v-toolbar>
      <div class="mapParent" id="mapParent">
        <GoogleMap
          class="fullMap"
          :center="{ lat: this.lat, lng: this.lng }"
          :zoom="8"
        >
          <DirectionsRenderer
            :directions="tourDay.directionsResponse"
            :options="{ suppressMarkers: true, suppressInfoWindows: true }"
          ></DirectionsRenderer>
          <GoogleMapPolyline :path="tourDay.polyLine"></GoogleMapPolyline>
          <GoogleMapMarkerWithLabel
            v-for="(appointment, index) in appointments.filter(
              el => !!el.addressLat
            )"
            :key="`m-${index}`"
            :position="{
              lat: appointment.addressLat,
              lng: appointment.addressLng
            }"
            :icon="{ url: getIconUrl(appointment) }"
            :label-content="(index + 1).toString()"
            label-class="appointment-label"
            :clickable="true"
            @click="appointment.infoWindowShown = true"
          >
            <GMMWLInfoWindow
              :opened="appointment.infoWindowShown"
              @closeclick="appointment.infoWindowShown = false"
            >
              {{ parseAddress(appointment) }}
            </GMMWLInfoWindow>
          </GoogleMapMarkerWithLabel>
          <GoogleMapMarker
            :icon="{ url: blueMarkerIcon }"
            :position="this.startPosition"
          ></GoogleMapMarker>
          <GoogleMapMarker
            :icon="{ url: blueMarkerIcon }"
            :position="this.endPosition"
          ></GoogleMapMarker>
        </GoogleMap>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import GoogleMap from "@/components/google/GoogleMap.vue";
import GoogleMapMarker from "@/components/google/GoogleMapMarker.vue";
import GoogleMapMarkerWithLabel from "@/components/google/GoogleMapMarkerWithLabel.vue";
import GMMWLInfoWindow from "@/components/google/GMMWLInfoWindow.vue";
import GoogleMapPolyline from "@/components/google/GoogleMapPolyline.vue";

import { TourDay } from "@/tourPlanner/services/TourWeekGraphQLService.ts";
import { mapIcons, blueMarkerIcon } from "@/data/settings.ts";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";

export default Vue.extend({
  name: "TourDayMap",
  components: {
    Container,
    Column,
    Row,
    GoogleMap,
    GoogleMapMarker,
    GoogleMapMarkerWithLabel,
    GMMWLInfoWindow,
    GoogleMapPolyline
  },
  props: {
    tourDay: {
      required: true
    }
  },
  data() {
    return {
      blueMarkerIcon,
      lat: 46.787163,
      lng: 14.38111,
      visibleView: false,
      startPosition: { lat: 46.787163, lng: 14.38111 },
      endPosition: { lat: 46.787163, lng: 14.38111 },
      appointments: []
    };
  },
  methods: {
    close() {
      this.visibleView = false;
      this.$emit("closed");
      this.$destroy();
    },
    getIconUrl(appointment) {
      if (appointment.type === "LOOSE") {
        return mapIcons[0];
      } else {
        if (new Date(appointment.date) <= new Date()) {
          return mapIcons[2];
        } else {
          return mapIcons[1];
        }
      }
    },
    parseAddress(appointment) {
      return `${appointment.addressStreet}, ${appointment.addressZip} ${appointment.addressCity}, ${appointment.addressCountry}`;
    }
  },
  created() {
    Vue.$gmapApiPromiseLazy().then(() => {
      if (!!this.tourDay.directionsResponse) {
        this.tourDay.directionsResponse.request = {
          travelMode: "DRIVING"
        };

        for (const route of this.tourDay.directionsResponse.routes) {
          if (route.bounds.southwest?.lat) {
            route.bounds = {
              south: route.bounds.southwest.lat,
              west: route.bounds.southwest.lng,
              north: route.bounds.northeast.lat,
              east: route.bounds.northeast.lng
            };
          }

          for (const leg of route.legs) {
            for (const step of leg.steps) {
              step.path = google.maps.geometry.encoding.decodePath(
                step.polyline.points
              );
            }
          }
        }

        const lastLeg =
          this.tourDay.directionsResponse.routes[0].legs.length - 1;
        this.startPosition = this.tourDay.directionsResponse.routes[0].legs[0].start_location;
        this.endPosition = this.tourDay.directionsResponse.routes[0].legs[
          lastLeg
        ].end_location;
      }

      this.appointments = this.$props.tourDay.appointments.map(a => ({
        ...a,
        addressCountry: CountriesStorageHandler.getCountryNameFromId(
          a.addressCountry
        ),
        infoWindowShown: false
      }));
    });

    this.visibleView = true;
  }
});
</script>

<style scoped>
.mapParent {
  position: absolute;
  top: 64px;
  bottom: 0px;
  right: 0px;
  left: 0px;
}
.fullMap {
  width: 100%;
  height: 100%;
}
</style>

<style>
.appointment-label {
  transform: translate(-4px, -28px);
}
</style>
