import { IUserRoleListPresenter } from "../controllers/UserRoleListController";
import { UserRoleListViewModel } from "../vms/UserRoleListViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { UserRoleList } from "../services/UserRoleService";

export class UserRoleListPresenter implements IUserRoleListPresenter {
  public constructor(private vm: UserRoleListViewModel) {
    this.initStaticTexts();
  }

  // Data
  public set addNewRole(add: boolean) {
    this.vm.addNewRole = add;
  }
  public set userRoleToEdit(id: string) {
    this.vm.userRoleToEdit = id;
  }

  // Responses
  public set loadUserRolesResponse(response: FormResponse<UserRoleList>) {
    this.vm.loadUserRolesRequest = response;

    if (response.success) {
      this.vm.userRoles = response.data.roles;
    }
  }

  private initStaticTexts() {
    this.vm.title = "Rollen";
    this.vm.editUserRoleButtonText = "Rolle bearbeiten";
    this.vm.addNewRoleButtonText = "Neue Rolle erstellen";
  }
}
