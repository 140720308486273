import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { SalutationStorageHandler } from "@/storage/storageHandlers/SalutationStorageHandler";
import {
  Supplier,
  SupplierGraphQLService
} from "../services/SupplierGraphQLService";

export class CreateSupplierController {
  private supplierId?: string;

  public constructor(
    private presenter: ICreateSupplierPresenter,
    private supplierService: SupplierGraphQLService
  ) {}

  public mounted(supplierId?: string) {
    this.presenter.areaCodeItems = CountriesStorageHandler.getAreaCodes();

    this.presenter.salutation = {
      selected: "",
      items: SalutationStorageHandler.getAllSalutationLongForms()
    };

    this.presenter.country = {
      selected: "",
      items: CountriesStorageHandler.getAllCountries()
    };

    if (supplierId) {
      this.supplierId = supplierId;

      FormRequestHandler.handle(
        this.supplierService.getSupplierById(supplierId),
        response => {
          this.presenter.getSupplierResponse = response;

          if (!response.loading && !response.error) {
            this.presenter.visibleView = true;
          }
        },
        "Konnte Lieferanten nicht laden!"
      );
    } else {
      this.presenter.visibleView = true;
    }
  }

  public closeOverlay() {
    this.presenter.visibleView = false;
  }

  public countrySelected(country: any) {
    this.presenter.state = {
      selected: "",
      items: CountriesStorageHandler.getStatesFromCountry(country)
    };
  }

  public createSupplierButtonClicked() {
    if (this.supplierId) {
      const data = { id: this.supplierId, ...this.presenter.data };

      FormRequestHandler.handle(
        this.supplierService.updateSupplier(data),
        response => (this.presenter.createSupplierResponse = response),
        "Could not create supplier!"
      );
    } else {
      FormRequestHandler.handle(
        this.supplierService.createSupplier(this.presenter.data),
        response => (this.presenter.createSupplierResponse = response),
        "Could not create supplier!"
      );
    }
  }

  public addPhoneDataRowButtonClicked() {
    this.presenter.addPhoneNumber();
  }

  public removePhoneDataRowButtonClicked(index: number) {
    this.presenter.removePhoneNumber(index);
  }
}

export interface ICreateSupplierPresenter {
  visibleView: boolean;

  getSupplierResponse: FormResponse<Supplier>;

  areaCodeItems: SelectionItem[];
  salutation: { selected: string; items: SelectionItem[] };
  country: { selected: string; items: SelectionItem[] };
  state: { selected: string; items: SelectionItem[] };
  data: any;
  createSupplierResponse: FormResponse<string>;

  addPhoneNumber(): void;
  removePhoneNumber(index: number): void;
}
