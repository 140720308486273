import { IWorkOrderService, WorkOrder } from "../services/WorkOrderService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { DateUtils } from "@/utils/DateUtils";

export class WorkOrderMetaListController {
  public constructor(
    private presenter: IWorkOrderMetaListPresenter,
    private workOrderService: IWorkOrderService
  ) {}

  public mounted() {
    this.getWorkOrders();
  }

  public parseDate(date: string) {
    return DateUtils.format(date);
  }

  public weekNumber(date: string) {
    return DateUtils.weekNumber(date);
  }

  public filterWorkOrders(week: string) {
    this.presenter.week = week;
  }

  public withoutLabReportsFirst(checked: boolean) {
    if (checked) {
      this.presenter.options = {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: true,
        mustSort: false,
        sortBy: ["hasLabReport", "plannedDate"],
        sortDesc: [false, false]
      };
    } else {
      this.presenter.options = {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: true,
        mustSort: false,
        sortBy: ["plannedDate"],
        sortDesc: [true]
      };
    }
  }

  public uploadLabReport(workOrder: any, file: File) {
    const request = this.workOrderService.uploadLabReport(workOrder.id, file);
    FormRequestHandler.handle(
      request,
      response => {
        this.presenter.uploadLabReportResponse = response;

        if (!response.loading && !response.error) {
          this.getWorkOrders();
        }
      },
      "upload-lab-report-failed"
    );
  }

  public sendClicked(workOrderId?: string) {
    const request = this.workOrderService.sendWorkOrders(workOrderId);

    FormRequestHandler.handle(
      request,
      response => {
        this.presenter.sendReportsResponse = response;

        if (!response.loading && !response.error) {
          this.getWorkOrders();
        }
      },
      "send-reports-failed"
    );
  }

  public getWorkOrders() {
    const request = this.workOrderService.getAllWorkOrdersWithReport();
    FormRequestHandler.handle(
      request,
      response => (this.presenter.getWorkOrdersResponse = response),
      "could-not-get-work-orders"
    );
  }
}

export interface IWorkOrderMetaListPresenter {
  getWorkOrdersResponse: FormResponse<WorkOrder[]>;
  uploadLabReportResponse: FormResponse<string>;
  options: any;
  sendReportsResponse: FormResponse<any>;
  week: string;
}
