var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"elevation-4",style:({ borderRadius: _vm.totalBorderRadius })},[_c('v-tabs',{class:_vm.color,style:({ borderRadius: _vm.topBorderRadius }),attrs:{"value":_vm.activeTabIndex,"active-class":"active-tab","grow":""},on:{"change":function($event){return _vm.$emit('activeTabChanged', $event)}}},_vm._l((_vm.tabs),function(tab,index){return _c('v-tab',{key:("t-" + index),style:({
        '--primary-color': _vm.$vuetify.theme.currentTheme.primary,
        'border-top-left-radius':
          (index === 0 ? _vm.borderRadius + 'px' : '0px') + ' !important',
        borderTopRightRadius:
          (index === _vm.tabs.length - 1 ? _vm.borderRadius + 'px' : '0px') +
          ' !important'
      })},[_vm._v(_vm._s(tab))])}),1),_c('v-tabs-items',{staticClass:"pa-4 white",style:({ borderRadius: _vm.bottomBorderRadius }),attrs:{"value":_vm.activeTabIndex,"touchless":""},on:{"change":function($event){return _vm.$emit('activeTabChanged', _vm.activeTabIndex)}}},[(_vm.loading)?_c('Row',{attrs:{"justify-center":""}},[_c('Column',{attrs:{"shrink":""}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":""}})],1)],1):(!!_vm.error)?_c('Row',{attrs:{"justify-center":""}},[_c('Column',{attrs:{"shrink":""}},[_c('Row',{attrs:{"column":"","align-center":""}},[_c('Column',{attrs:{"shrink":""}},[_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.error))])]),_c('Column',{attrs:{"shrink":""}},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.emitRetry()}}},[_vm._v("Nochmal versuchen?")])],1)],1)],1)],1):_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }