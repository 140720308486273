import { TourDayPropsForm } from "../forms/TourDayPropsForm";

export class ChangeTourDayPropsDialogController {
  public constructor(private presenter: IChangeTourDayPropsDialogPresenter) {}

  public mounted(
    startTime: string,
    startLocationStreet: string,
    startLocationZip: string,
    startLocationCity: string,
    startLocationCountry: string,
    endLocationStreet: string,
    endLocationZip: string,
    endLocationCity: string,
    endLocationCountry: string
  ) {
    this.presenter.propsForm.setFieldValue("startTime", startTime);
    this.presenter.propsForm.setFieldValue(
      "startLocationStreet",
      startLocationStreet
    );
    this.presenter.propsForm.setFieldValue(
      "startLocationZip",
      startLocationZip
    );
    this.presenter.propsForm.setFieldValue(
      "startLocationCity",
      startLocationCity
    );
    this.presenter.propsForm.setFieldValue(
      "startLocationCountry",
      startLocationCountry
    );
    this.presenter.propsForm.setFieldValue(
      "endLocationStreet",
      endLocationStreet
    );
    this.presenter.propsForm.setFieldValue("endLocationZip", endLocationZip);
    this.presenter.propsForm.setFieldValue("endLocationCity", endLocationCity);
    this.presenter.propsForm.setFieldValue(
      "endLocationCountry",
      endLocationCountry
    );
  }

  public timeChanged(newValue: any) {
    this.presenter.propsForm.setFieldValue("startTime", newValue);
  }

  public startLocationStreetChanged(newValue: string) {
    this.presenter.propsForm.setFieldValue("startLocationStreet", newValue);
  }

  public startLocationZipChanged(newValue: string) {
    this.presenter.propsForm.setFieldValue("startLocationZip", newValue);
  }

  public startLocationCityChanged(newValue: string) {
    this.presenter.propsForm.setFieldValue("startLocationCity", newValue);
  }

  public startLocationCountryChanged(newValue: string) {
    this.presenter.propsForm.setFieldValue("startLocationCountry", newValue);
  }

  public endLocationStreetChanged(newValue: string) {
    this.presenter.propsForm.setFieldValue("endLocationStreet", newValue);
  }

  public endLocationZipChanged(newValue: string) {
    this.presenter.propsForm.setFieldValue("endLocationZip", newValue);
  }

  public endLocationCityChanged(newValue: string) {
    this.presenter.propsForm.setFieldValue("endLocationCity", newValue);
  }

  public endLocationCountryChanged(newValue: string) {
    this.presenter.propsForm.setFieldValue("endLocationCountry", newValue);
  }
}

export interface IChangeTourDayPropsDialogPresenter {
  propsForm: TourDayPropsForm;
}
