export class OperatorDataViewModel {
  public operator: any = {};
  public error = "";

  public get hasPhoneNumberOrEmail() {
    return this.hasPhoneNumber || this.hasEmail;
  }

  public get hasPhoneNumber() {
    return this.operator.phoneNumbers && this.operator.phoneNumbers.length > 0;
  }

  public get hasEmail() {
    return this.operator.eMail;
  }

  public get name() {
    return this.operator.company
      ? this.operator.company
      : `${this.operator.lastName}, ${this.operator.firstName}`;
  }
}
