import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import {
  GraphQLConnection,
  OrderByClause
} from "@/gateways/graphql/GraphQLConnection";
import { ItemTemplate } from "./ItemTemplateGraphQLService";

export class StoreItemGraphQLService {
  public constructor(private connection: GraphQLConnection) {}

  public async getAllStoreItems(): Promise<StoreItem[]> {
    const request = await this.connection.query("storeItems", {}, [
      "id",
      "minimalAmount",
      "amount",
      "storeId",
      "plantIndex",
      {
        name: "itemTemplate",
        fields: ["id", "name", "itemNumber", "dimensions"]
      },
      {
        name: "store",
        fields: ["name"]
      }
    ]);

    return request.data;
  }

  public async getAllStoreItemsPaginated(
    page: Page,
    order: OrderByClause[],
    search: string,
    storeFilter: string[],
    itemTemplateFilter: string[]
  ): Promise<PaginatedList<StoreItem>> {
    const request = await this.connection.queryPaginated(
      "storeItemsPaginated",
      page.itemsPerPage,
      page.page,
      [
        "id",
        "minimalAmount",
        "amount",
        "storeId",
        "plantIndex",
        {
          name: "itemTemplate",
          fields: ["id", "name", "itemNumber", "dimensions"]
        },
        {
          name: "store",
          fields: ["name", "employeeId"]
        }
      ],
      search,
      [
        "itemTemplate.name",
        "minimalAmount",
        "amount",
        "store.name",
        "itemTemplate.itemNumber",
        "itemTemplate.dimensions"
      ],
      order,
      {
        storeFilter,
        itemTemplateFilter
      }
    );

    return {
      items: request.data,
      totalCount: request.count
    };
  }

  public async getStoreItems(itemIds: string[]): Promise<StoreItem[]> {
    const request = await this.connection.query(
      "storeItemsByIds",
      {
        itemIds
      },
      [
        "id",
        "amount",
        "itemTemplateId",
        {
          name: "itemTemplate",
          fields: ["id", "name", "itemNumber", "dimensions"]
        }
      ]
    );

    return request.data;
  }

  public async getAllStoreItemsByEmployee(
    employeeId: string
  ): Promise<StoreItem[]> {
    const request = await this.connection.query(
      "storeItemsByEmployeeId",
      {
        employeeId
      },
      [
        "id",
        "amount",
        "itemTemplateId",
        {
          name: "itemTemplate",
          fields: ["id", "name", "itemNumber", "dimensions"]
        }
      ]
    );

    return request.data;
  }

  public async getAllStoreItemsByStore(storeId: string) {
    const request = await this.connection.query(
      "storeItemsByStoreId",
      {
        storeId
      },
      ["id", "amount", "itemTemplateId"]
    );

    return request.data;
  }

  public async getAllStoreItemsByPlant(plantId: string) {
    const request = await this.connection.query(
      "storeItemsByPlantId",
      {
        plantId
      },
      [
        "id",
        "amount",
        "itemTemplateId",
        {
          name: "itemTemplate",
          fields: ["id", "name", "itemNumber", "dimensions"]
        }
      ]
    );

    return request.data;
  }

  public async getStoreItemAmount(storeId: string, storeItemId: string) {
    const request = await this.connection.query(
      "getStoreItemAmount",
      {
        storeItemId,
        storeId
      },
      []
    );

    return request.data;
  }

  public async getStoreItemById(id: string) {
    const request = await this.connection.query(
      "storeItem",
      {
        id
      },
      [
        "id",
        "minimalAmount",
        "amount",
        "storeId",
        "plantIndex",
        "itemTemplateId",
        {
          name: "itemTemplate",
          fields: ["name", "itemNumber", "dimensions"]
        }
      ]
    );

    return request.data;
  }

  public async updateStoreItem(input: any) {
    const request = await this.connection.mutation(
      "updateStoreItem",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async createStoreItem(input: any) {
    const request = await this.connection.mutation(
      "createStoreItem",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async deleteStoreItem(id: string) {
    const request = await this.connection.mutation(
      "deleteStoreItem",
      {
        id
      },
      []
    );

    return request.data;
  }

  public async exportStoreItems() {
    const request = await this.connection.query("exportStoreItems", {}, []);

    return request.data;
  }
}

export interface StoreItem {
  id: string;
  itemTemplateId: string;
  itemTemplate: ItemTemplate;
  minimalAmount: string;
  amount: string;
  storeId: string;
}
