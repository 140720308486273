import { Auth } from "../utils/Auth";
import StorageKeys from "../utils/StorageKeys";

export class ScheduleController {
  public constructor(private presenter: ISchedulePresenter) {}

  public mounted() {
    // Init
  }

  public canEdit() {
    return Auth.hasPermission(StorageKeys.editTourPlanPermission);
  }

  public parseTime(time: string): number[] {
    return time.split(":").map(el => parseInt(el, 10));
  }

  public getHeightInPercentFromMinutes(time: string) {
    // -1 so it is aligned with the top line, if minutes is 0
    return (parseInt(time.split(":")[1], 10) / 60) * 100 - 1;
  }

  public getFirstAppointmentTop(time: string) {
    return (
      (this.parseTime(time)[0] - 4) * 72 +
      ((this.getHeightInPercentFromMinutes(time) + 2) / 100) * 72
    );
  }

  public getDraggableHeight(time: string) {
    return 19 * 72 - this.getFirstAppointmentTop(time);
  }

  public getCursor(tourDay: any, draggable: boolean, viewRouteMode: boolean) {
    if (!this.canEdit() || !draggable) {
      return "auto";
    } else if (viewRouteMode || tourDay.date <= new Date()) {
      return "not-allowed";
    } else {
      return "move";
    }
  }

  public parseDurationToString(duration: number) {
    const durationInMinutes = duration / 60;
    return `${Math.floor(durationInMinutes / 60)} h ${Math.round(
      durationInMinutes % 60
    )} min`;
  }
}

export interface ISchedulePresenter {
  init(): void;
}
