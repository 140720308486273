import { UserRoleService, UserRoleList } from "../services/UserRoleService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";

export class UserRoleListController {
  public constructor(
    private presenter: IUserRoleListPresenter,
    private service: UserRoleService
  ) {}

  public init() {
    this.loadUserRoles();
  }

  public addNewRoleButtonClicked() {
    this.presenter.addNewRole = true;
  }

  public editUserRoleButtonClicked(id: string) {
    this.presenter.userRoleToEdit = id;
  }

  private loadUserRoles() {
    const request = this.service.getUserRoleList();

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadUserRolesResponse = response),
      "User Rollen konnten nicht geladen werden"
    );
  }
}

export interface IUserRoleListPresenter {
  addNewRole: boolean;
  userRoleToEdit: string;

  // Responses
  loadUserRolesResponse: FormResponse<UserRoleList>;
}
