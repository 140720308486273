import SystemKeys from "@/common/utils/SystemKeys";
import { StorageHandler } from "./StorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";

export class PlantGroupStorageHandler extends StorageHandler {
  public static getAllGroups(): SelectionItem[] {
    if (this.storage.has(SystemKeys.groupsTableKey)) {
      const groups = JSON.parse(this.storage.get(SystemKeys.groupsTableKey));

      return groups?.map((group: any) => ({
        text: group[SystemKeys.values][SystemKeys.groupsNameKey],
        value: group[SystemKeys.id]
      }));
    }

    return [];
  }

  public static getTypesFromGroup(groupId: string): SelectionItem[] {
    if (this.storage.has(SystemKeys.typesTableKey)) {
      const table = JSON.parse(this.storage.get(SystemKeys.typesTableKey));

      const types = table.filter(
        (type: any) =>
          type[SystemKeys.values][SystemKeys.groupsTableKey] === groupId
      );
      return types?.map((type: any) => ({
        text: type[SystemKeys.values][SystemKeys.typesNameKey],
        value: type[SystemKeys.id]
      }));
    }

    return [];
  }

  public static getPlantSizesFromType(typeId: string): string[] {
    if (typeId && this.storage.has(SystemKeys.typesTableKey)) {
      const value = this.storage.findValue(SystemKeys.typesTableKey, typeId);
      if (value) {
        const typeSizes = value[SystemKeys.values][SystemKeys.typesSizeKey];

        if (!!typeSizes) {
          return typeSizes.map((el: any) => el[SystemKeys.sizesTableKey]);
        }
      }
    }

    return [];
  }

  public static getGroupNameFromId(groupId: string): string {
    if (this.storage.has(SystemKeys.groupsTableKey)) {
      const value = this.storage.findValue(SystemKeys.groupsTableKey, groupId);
      return value
        ? value[SystemKeys.values][SystemKeys.groupsNameKey]
        : groupId;
    }

    return groupId;
  }

  public static getTypeNameFromId(typeId: string): string {
    if (this.storage.has(SystemKeys.typesTableKey)) {
      const value = this.storage.findValue(SystemKeys.typesTableKey, typeId);
      return value ? value[SystemKeys.values][SystemKeys.typesNameKey] : typeId;
    }

    return typeId;
  }

  public static getTypeReportIdFromId(typeId: string) {
    if (this.storage.has(SystemKeys.typesTableKey)) {
      const value = this.storage.findValue(SystemKeys.typesTableKey, typeId);
      return value
        ? value[SystemKeys.values][SystemKeys.typesReportKey]
        : typeId;
    }
  }

  public static getSizeFromId(sizeId: string) {
    if (this.storage.has(SystemKeys.sizesTableKey)) {
      const size = this.storage.findValue(SystemKeys.sizesTableKey, sizeId);
      return {
        id: sizeId,
        name: size[SystemKeys.values][SystemKeys.sizesNameKey],
        unit: size[SystemKeys.values][SystemKeys.sizesUnitKey],
        compare: size[SystemKeys.values][SystemKeys.sizesCompareKey],
        value: ""
      };
    }

    return undefined;
  }
}
