



























































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import DeleteDialog from "@/components/basic/DeleteDialog.vue";
import HoverableDatatable from "@/components/collection/HoverableDatatable.vue";

import { FormRequestHandler } from "@/forms/FormRequestHandler";

import { AxiosGraphQLConnection } from "../../../gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "../../../logging/AxiosLogger";
import { ConsoleLogger } from "../../../logging/ConsoleLogger";
import { PlantGraphQLService } from "../../services/PlantGraphQLService";
import { Plant, PlantDocument } from "../../services/PlantService";
import { DateUtils } from "../../../utils/DateUtils";
import { Auth } from "../../../common/utils/Auth";
import StorageKeys from "../../../common/utils/StorageKeys";

@Component<PlantDocumentsData>({
  components: {
    Card,
    Grid,
    Row,
    Column,

    HoverableDatatable,
    DeleteDialog,

    CreatePlantDocumentView: () => import("../CreatePlantDocumentView.vue"),
    SendDocumentView: () => import("../SendDocumentView.vue")
  },
  props: ["plant", "plantRequest", "element"]
})
export default class PlantDocumentsData extends Vue {
  private plant!: Plant;
  private components: any[] = [];
  private showFileDialog = false;
  private loading = false;
  private toDeletePlantDocument: any = {};
  private isDeleteDialogShown = false;
  private plantDocumentHeaders = [
    { text: "Name", value: "name", sortable: true },
    { text: "Hochgeladen am", value: "uploadedAt", sortable: false },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "right",
      width: "120px"
    }
  ];

  private connection = new AxiosGraphQLConnection(
    new AxiosLogger(new ConsoleLogger())
  );
  private plantService = new PlantGraphQLService(this.connection);

  private retry(event?: any) {
    this.components = [];
    this.$emit("retry", event);
  }

  private clearComponents() {
    this.components = [];
  }

  private addDocumentButtonClicked() {
    this.components.push({ component: "CreatePlantDocumentView" });
  }

  private editPlantDocumentsButtonClicked(plantDocument: PlantDocument) {
    this.components.push({
      component: "CreatePlantDocumentView",
      plantDocument
    });
  }

  private sendPlantDocumentButtonClicked(plantDocument: PlantDocument) {
    this.components.push({
      component: "SendDocumentView",
      plantDocument
    });
  }

  private showDeleteDialog(plantDocument: PlantDocument) {
    this.toDeletePlantDocument = plantDocument;
    this.isDeleteDialogShown = true;
  }

  private deletePlantDocumentButtonClicked() {
    this.loading = true;
    const request = this.plantService.removePlantDocument(
      this.plant.id,
      this.toDeletePlantDocument.name,
      false
    );
    FormRequestHandler.handle(
      request,
      response => {
        this.isDeleteDialogShown = false;
        if (!response.loading && !response.error) {
          this.toDeletePlantDocument = {};
          this.retry();
          this.loading = false;
        }
      },
      "update-plant-failed"
    );
  }

  private formatDate(date: Date) {
    return DateUtils.format(date);
  }

  private downloadFile(doc: PlantDocument) {
    window.open(doc.path, "_blank");
  }

  private canEdit() {
    return Auth.hasPermission(StorageKeys.editPlantAndOperatorPermission);
  }
}
