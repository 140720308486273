






















































































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import Datatable from "@/components/collection/Datatable.vue";
import Select from "@/components/form/Select.vue";
import YearPicker from "@/components/basic/YearPicker.vue";
import IconButton from "@/components/basic/IconButton.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

import { ServiceEngineerListViewModel } from "@/tourPlanner/vms/ServiceEngineerListViewModel";
import { ServiceEngineerListPresenter } from "@/tourPlanner/presenters/ServiceEngineerListPresenter";
import { ServiceEngineerListController } from "@/tourPlanner/controllers/ServiceEngineerListController";

import { ServiceEngineerViewModel } from "../vms/ServiceEngineerViewModel";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { ServiceEngineerGraphQLService } from "../services/ServiceEngineerGraphQLService";
import {
  TourWeek,
  TourWeekGraphQLService
} from "../services/TourWeekGraphQLService";

import { DateUtils } from "../../utils/DateUtils";

@Component<ServiceEngineerListView>({
  components: {
    Page,
    Card,
    Container,
    Column,
    Row,
    Datatable,
    Select,
    YearPicker,
    IconButton,
    RequestButton
  }
})
export default class ServiceEngineerListView extends Vue {
  private vm = new ServiceEngineerListViewModel();

  private get controller(): ServiceEngineerListController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new ServiceEngineerListController(
      new ServiceEngineerListPresenter(this.vm),
      new ServiceEngineerGraphQLService(connection),
      new TourWeekGraphQLService(connection)
    );
  }

  private mounted() {
    this.controller.mounted();
  }

  private updated() {
    if (!!document.getElementById("currentWeek")) {
      document.getElementById("currentWeek")?.scrollIntoView(true);
    }
  }

  private goToMap(serviceEngineerId: string, date: string, state: string) {
    this.$router.push({
      name:
        !state || state === "STARTED" ? "appointment-map" : "weekly-schedule",
      params: { serviceEngineerId, weekDate: DateUtils.dateOnly(date) }
    });
  }

  private goToSchedule(serviceEngineerId: string, date: string) {
    this.$router.push({
      name: "weekly-schedule",
      params: { serviceEngineerId, weekDate: DateUtils.dateOnly(date) }
    });
  }
}
