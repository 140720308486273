


































import Vue from "vue";
import Component from "vue-class-component";

import IconButton from "@/components/basic/IconButton.vue";
import Pagination from "@/components/basic/Pagination.vue";
import {
  OrderByClause,
  SortOrder
} from "../../gateways/graphql/GraphQLConnection";
@Component<ServerDatatable>({
  components: {
    IconButton,
    Pagination
  },
  props: {
    value: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    totalItems: {
      type: Number,
      required: true
    },
    headers: {
      type: Array,
      default() {
        return [];
      }
    },
    tabletHeaders: {
      type: Array,
      default() {
        return [];
      }
    },
    mobileHeaders: {
      type: Array,
      default() {
        return [];
      }
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    multisort: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
export default class ServerDatatable extends Vue {
  private set opts(newValue: any) {
    this.$emit("sort", newValue);
  }
  private get opts() {
    return this.$props.options;
  }
  private get tableHeaders() {
    return this.$vuetify.breakpoint.lgAndUp
      ? this.$props.headers
      : this.$vuetify.breakpoint.xsOnly
      ? this.$props.mobileHeaders.map((header: any) => {
          const mobileKey = "mobile";
          header[mobileKey] = true;
          return header;
        })
      : this.$props.tabletHeaders.length > 0
      ? this.$props.tabletHeaders.map((header: any) => {
          const tabletKey = "tablet";
          header[tabletKey] = true;
          return header;
        })
      : this.$props.headers;
  }

  public rowClicked(event: any) {
    this.$emit("row-clicked", event);
  }
}
