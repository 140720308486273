import { IPlantService, Plant } from "../services/PlantService";
import { DateUtils } from "@/utils/DateUtils";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { PartStorageHandler } from "@/storage/storageHandlers/PartStorageHandler";
import { PlantGroupStorageHandler } from "@/storage/storageHandlers/PlantGroupStorageHandler";
import { DistrictAuthorityStorageHandler } from "@/storage/storageHandlers/DistrictAuthorityStorageHandler";

export class PlantDataController {
  public constructor(
    private presenter: IPlantDataPresenter,
    private plantService: IPlantService
  ) {}

  public mounted(plantId: string) {
    const request = this.plantService.getPlantById(plantId);
    FormRequestHandler.handle(
      request,
      response => {
        this.presenter.getPlantResponse = response;
      },
      "get-plant-failed"
    );
  }

  public parseDate(date: Date) {
    return !!date ? DateUtils.format(date) : null;
  }

  public changeDraggable() {
    this.presenter.markerDraggable = !this.presenter.markerDraggable;
    if (!this.presenter.markerDraggable) {
      this.updatePlant(this.presenter.plant);
    }
  }

  public getDaName(id: string) {
    return DistrictAuthorityStorageHandler.getDistrictAuthorityNameById(id);
  }

  public changeAddress(event: any) {
    this.presenter.plant.addressLat = event.latLng.lat();
    this.presenter.plant.addressLng = event.latLng.lng();
  }

  public addressAccessRemarksTextChanged(event: any) {
    this.presenter.addressAccessRemarks = event;
  }

  public group(groupId: string) {
    return PlantGroupStorageHandler.getGroupNameFromId(groupId);
  }

  public type(typeId: string) {
    return PlantGroupStorageHandler.getTypeNameFromId(typeId);
  }

  public areaCode(countryId: string) {
    return CountriesStorageHandler.getAreaCodeById(countryId);
  }

  public phoneRemarks(remarks: string) {
    if (!!remarks) {
      return `(${remarks})`;
    } else {
      return "";
    }
  }

  public parsePlantPart(amount: string, name: string, description: string) {
    return `${amount}x ${name}${description ? ": " + description : ""}`;
  }

  public countryName(countryId: string) {
    return CountriesStorageHandler.getCountryNameFromId(countryId);
  }

  public plantPartName(parameterId: string) {
    return PartStorageHandler.getPlantPartNameById(parameterId);
  }

  public installationPartName(parameterId: string) {
    return PartStorageHandler.getPlantInstallationPartNameById(parameterId);
  }

  public updatePlant(plant: Plant) {
    const request = this.plantService.updatePlant(plant);
    FormRequestHandler.handle(
      request,
      response => {
        this.presenter.updatePlantResponse = response;
      },
      "get-plant-failed"
    );
  }
}

export interface IPlantDataPresenter {
  plant: Plant;
  markerDraggable: boolean;
  getPlantResponse: FormResponse<Plant>;
  updatePlantResponse: FormResponse<string>;

  addressAccessRemarks: string;
}
