import {
  Selection,
  Textfield,
  Date,
  Request,
  Checkbox,
  Numberfield
} from "@/forms/ViewModelFormTypes";

export class PlantContractViewModel {
  public visibleView: boolean = false;
  public goBack: boolean = false;

  public createContractButtonDisabled = true;
  public createdContract: string = "";

  public createContractRequest: Request = { loading: false, error: "" };

  public hasContract: Checkbox = {
    value: false,
    error: "",
    label: "Wartungsvertrag"
  };

  public date: Date = {
    text: "",
    value: "",
    error: "",
    label: "Abschlussdatum"
  };
  public doneBy: Selection = {
    selected: "",
    error: "",
    items: [],
    label: "Abgeschlossen von"
  };
  public maintenanceNumber: Textfield = {
    value: "",
    error: "",
    label: "Wartungsvertragsnummer"
  };
  public maintenanceCost: Numberfield = {
    value: 0,
    error: "",
    label: "Wartungsbetrag"
  };
  public priceSample: Numberfield = {
    value: 0,
    error: "",
    label: "Preis Probe"
  };

  public baseIndex: Selection = {
    selected: "",
    error: "",
    items: [],
    label: "Basisindex"
  };
  public startIndex: Numberfield = { value: 0, error: "", label: "Startindex" };
  public currentIndex: Numberfield = {
    value: 0,
    error: "",
    label: "Aktueller Index"
  };
  public dueDate: Textfield = {
    value: "",
    error: "",
    label: "Fälligkeit Paragraph 134"
  };
  public firstAppointment: Date = {
    value: "",
    text: "",
    error: "",
    label: "Erster Wartungstermin"
  };
  public maintenanceInterval: Numberfield = {
    value: 0,
    error: "",
    label: "Wartungsintervall"
  };

  public contractQuitted: Checkbox = {
    value: false,
    error: "",
    label: "Vertrag gekündigt"
  };

  public quitDate: Date = {
    text: "",
    value: "",
    error: "",
    label: "Kündigungsdatum"
  };
  public remarks: Textfield = { value: "", error: "", label: "Anmerkungen" };
}
