























































































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";
import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";

import RequestButton from "@/components/basic/RequestButton.vue";

import { CreateStockTakingViewModel } from "@/store/vms/CreateStockTakingViewModel";
import { CreateStockTakingPresenter } from "@/store/presenters/CreateStockTakingPresenter";
import { CreateStockTakingController } from "@/store/controllers/CreateStockTakingController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { StockTakingGraphQLService } from "../services/StockTakingGraphQLService";
import { StoreItemService } from "../services/StoreItemService";
import { StoreService } from "../services/StoreService";
import { ItemTemplateService } from "../services/ItemTemplateService";

@Component<CreateStockTakingView>({
  components: {
    ValidationObserver,
    Card,
    Container,
    Column,
    Row,
    ValidatedSelect,
    ValidatedTextfield,
    RequestButton
  },
  watch: {
    "vm.visibleView"(newValue) {
      if (!newValue) {
        setTimeout(() => {
          this.$destroy();
          if (this.$router.currentRoute.name === "stock-taking") {
            this.$nextTick(() => this.$router.back());
          }
        }, 500);
      }
    }
  }
})
export default class CreateStockTakingView extends Vue {
  private vm = new CreateStockTakingViewModel();
  private controller?: CreateStockTakingController;

  private initController(): CreateStockTakingController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreateStockTakingController(
      new CreateStockTakingPresenter(this.vm),
      new StockTakingGraphQLService(connection),
      new StoreService(connection),
      new StoreItemService(connection),
      new ItemTemplateService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$route.params.stockTakingId);
  }
}
