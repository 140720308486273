var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{attrs:{"title":"Wochenplanungs-Übersicht"}},[_c('router-view'),_c('Container',{attrs:{"margin":0,"fluid":""}},[_c('Row',[_c('Column',{attrs:{"xs12":"","px-4":"","my-1":""}},[_c('Datatable',{staticStyle:{"padding":"20px","border-radius":"20px"},attrs:{"loading":_vm.vm.loadingEngineers,"hide-default-footer":"","dense":true,"headers":_vm.vm.engineersHeaders,"items":_vm.vm.engineerList,"mobile-breakpoint":0,"show-expand":true},on:{"expanded":function($event){return _vm.controller.expanded($event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.serviceEngineer.firstName)+" "+_vm._s(item.serviceEngineer.lastName))]),(!!item.tourWeeks && _vm.controller.shouldShowValidateChip(item.tourWeeks))?_c('span',[_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"red","outlined":"","small":""}},[_vm._v("Zu prüfen")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{staticStyle:{"border-radius":"20px !important"},attrs:{"top":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"primary"}},on),[_c('v-icon',[_vm._v("remove_red_eye")])],1)]}}],null,true)},[_c('Card',{attrs:{"small":true,"hideTitle":true,"noPadding":true,"borderRadius":0}},[_c('Container',{attrs:{"margin":0,"fluid":""}},[_c('Row',{attrs:{"align-content-center":"","align-center":"","px-4":"","pb-2":"","pt-4":""}},[_c('Column',{attrs:{"grow":""}},[_vm._v("Ausgewähltes Jahr:")]),_c('Column',{attrs:{"shrink":""}},[_c('YearPicker',{attrs:{"maxYear":(new Date().getFullYear() + 5).toString(),"value":_vm.vm.selectedYear},on:{"input":function($event){return _vm.controller.selectedYearChanged($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var year = ref.year;
return [_c('RequestButton',{staticStyle:{"float":"right"},attrs:{"outlined":false,"text":year,"width":"88px"}})]}}],null,true),model:{value:(_vm.vm.selectedYear),callback:function ($$v) {_vm.$set(_vm.vm, "selectedYear", $$v)},expression:"vm.selectedYear"}})],1)],1),_c('Row',{attrs:{"px-4":"","py-2":""}},[_c('Select',{attrs:{"auto":"","color":"primary","value":_vm.vm.timeFrameItems,"hideDetails":true},on:{"change":function($event){return _vm.controller.weekDateChanged($event)}}})],1),_c('Row',{attrs:{"px-4":"","pb-4":"","pt-2":""}},[_c('Column',{attrs:{"grow":""}}),(_vm.controller.canEdit())?_c('Column',{attrs:{"shrink":""}},[_c('IconButton',{attrs:{"icon":"map","tooltipText":"Zur Übersichtskarte"},on:{"click":function($event){return _vm.goToMap(item.id, _vm.vm.timeFrameItems.selected)}}})],1):_vm._e(),_c('Column',{attrs:{"shrink":"","px-2":""}},[_c('IconButton',{attrs:{"icon":"table_chart","tooltipText":"Zum Wochenplan"},on:{"click":function($event){return _vm.goToSchedule(item.id, _vm.vm.timeFrameItems.selected)}}})],1)],1)],1)],1)],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('td'),_c('td',{attrs:{"colspan":headers.length - 1}},[_c('Datatable',{attrs:{"loading":_vm.vm.loadingWeek,"dense":true,"hideHeaders":true,"headers":_vm.vm.weekHeaders,"items":_vm.vm.weeksList[item.id]},on:{"row-clicked":function($event){return _vm.goToMap($event.serviceEngineerId, $event.weekNumber, $event.processingState)}},scopedSlots:_vm._u([{key:"item.weekNumbercalculated",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.controller.calculateKW(new Date(item.weekNumber))))]}},{key:"item.weekNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.controller.formateDate(item.weekNumber)))]}},{key:"item.processingState",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[item.processingState === 'NOT_VALIDATED'? 'red--text':'']},[_vm._v(_vm._s(_vm.controller.formateState(item.processingState)))])]}}],null,true)})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }