import { StorageHandler } from "./StorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import SystemKeys from "@/common/utils/SystemKeys";

export class SalutationStorageHandler extends StorageHandler {
  public static getAllSalutationLongForms(): SelectionItem[] {
    return this.buildTopLevelSelection(
      SystemKeys.salutationsTableKey,
      SystemKeys.salutationsLongFormKey
    );
  }

  public static getShortSalutationById(salultationId: string): string {
    if (this.storage.has(SystemKeys.salutationsTableKey)) {
      const value = this.storage.findValue(
        SystemKeys.salutationsTableKey,
        salultationId
      );
      return value
        ? value.values[SystemKeys.salutationsShortFormKey]
        : salultationId;
    }

    return salultationId;
  }
}
