import { Form, FormFieldType } from "@/forms/Form";
import regex from "@/data/regex";

export class EmployeePhoneNumberForm extends Form {
  protected definition = {
    areaCode: { type: FormFieldType.Selection, required: true },
    number: {
      type: FormFieldType.Text,
      required: true,
      regex: regex.phoneRegex,
      error: "Ungültige Telefonnummer"
    },
    annotation: { type: FormFieldType.Text, required: false }
  };
}
