var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',[_c('Row',{attrs:{"wrap":""}},[_c('Column',{attrs:{"xs12":""}},[_c('strong',[_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.controller.formateCompanyToName(
              _vm.vm.operator.firstName,
              _vm.vm.operator.lastName,
              _vm.vm.operator.company
            )
          )}})])]),(!!_vm.vm.operator.address)?_c('Column',{attrs:{"xs12":""}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.vm.operator.address.street))]),_c('p',[_vm._v(_vm._s(_vm.vm.operator.address.zip)+" "+_vm._s(_vm.vm.operator.address.city))])]):_vm._e(),_c('Column',{attrs:{"xs12":""}},[_c('span',[_c('strong',[_vm._v("Telefonnummern")])])]),_vm._l((_vm.vm.operator.phoneNumbers),function(phoneNumber,cppnIndex){return _c('Column',{key:("cppn-" + cppnIndex),attrs:{"xs12":""}},[_c('p',{staticClass:"mb-0",style:({
          fontWeight: phoneNumber.isStandard ? 'bold' : 'normal'
        })},[_vm._v(" "+_vm._s(_vm.controller.areaCode(phoneNumber.areaCode))+" "+_vm._s(phoneNumber.phoneNumber)+" "+_vm._s(_vm.controller.phoneRemarks(phoneNumber.remarks))+" ")])])}),_c('Column',{attrs:{"xs3":""}},[_c('span',[_c('strong',[_vm._v("E-Mail:")])])]),_c('Column',{attrs:{"xs9":""}},[_c('span',[_vm._v(_vm._s(_vm.vm.operator.eMail))])]),_c('Column',{attrs:{"xs12":""}},[_c('RequestButton',{staticClass:"pt-3",attrs:{"outlined":false,"text":"Zum Betreiber","block":""},on:{"click":function($event){return _vm.goToOperator()}}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }