import { Form, FormFieldType } from "@/forms/Form";
import regex from "@/data/regex";

export class PhoneDataSubForm extends Form {
  public definition = {
    areaCode: { required: true, type: FormFieldType.Selection },
    phoneNumber: {
      required: true,
      type: FormFieldType.Text,
      regex: regex.phoneRegex
    },
    phoneAddition: { required: false, type: FormFieldType.Text },
    isStandard: { required: false, type: FormFieldType.Checkbox }
  };
}
