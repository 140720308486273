var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('Container',{attrs:{"margin":0,"grid-list-md":""}},[_c('Row',{attrs:{"align-center":""}},[_c('Column',{attrs:{"xs3":""}},[_vm._v("Kunde nicht vor Ort")]),_c('Column',{attrs:{"xs1":""}},[_c('validated-switch',{attrs:{"disabled":!_vm.parentController.editEnabled(),"rules":"required","vid":"cannotTakeSignature","inset":"","background-color":"white","color":"primary"},model:{value:(_vm.vm.cannotTakeSignature),callback:function ($$v) {_vm.$set(_vm.vm, "cannotTakeSignature", $$v)},expression:"vm.cannotTakeSignature"}})],1)],1),(!_vm.vm.cannotTakeSignature)?_c('Row',{attrs:{"mt-2":""}},[_c('Column',{attrs:{"grow":""}}),_c('Column',{attrs:{"shrink":""}},[_c('ValidationProvider',{ref:"vprovider",attrs:{"rules":"required_if:cannotTakeSignature,false"}},[_c('SignaturePad',{attrs:{"height":"300px","width":"600px","disabled":!_vm.parentController.editEnabled(),"signature":_vm.vm.signature},on:{"drawEnd":function($event){return _vm.controller.setSignature($event)}}})],1)],1),_c('Column',{attrs:{"grow":""}})],1):_vm._e(),_c('Row',{attrs:{"wrap":""}},[_c('Column',{attrs:{"xs12":"","md3":""}},[_c('v-btn',{attrs:{"color":"primary","outlined":"","block":""},on:{"click":function($event){return _vm.parentController.backClicked()}}},[_vm._v(_vm._s(_vm.$t("back")))])],1),_c('Column',{attrs:{"grow":""}}),(
          !_vm.parentController.editEnabled() &&
            !_vm.parentController.noButton() &&
            _vm.parentController.showAcceptRejectButtons()
        )?_c('Column',{attrs:{"shrink":""}},[_c('RequestButton',{attrs:{"outlined":false,"color":"error","text":"Ablehnen","loading":_vm.parentVm.createWorkOrderRequest.loading,"error":_vm.parentVm.createWorkOrderRequest.error},on:{"click":function($event){return _vm.parentController.rejectWorkOrderButtonClicked()}}})],1):_vm._e(),(
          !_vm.parentController.editEnabled() &&
            !_vm.parentController.noButton() &&
            _vm.parentController.showAcceptRejectButtons()
        )?_c('Column',{attrs:{"shrink":""}},[_c('RequestButton',{attrs:{"outlined":false,"text":"Annehmen","loading":_vm.parentVm.createWorkOrderRequest.loading,"error":_vm.parentVm.createWorkOrderRequest.error},on:{"click":function($event){return _vm.$emit('accept-work-order')}}})],1):_vm._e(),(
          !_vm.parentController.showAcceptRejectButtons() &&
            !_vm.parentController.noButton()
        )?_c('Column',{attrs:{"xs12":"","md3":""}},[_c('RequestButton',{attrs:{"outlined":false,"block":"","text":!!_vm.parentVm.workOrderId ? 'Speichern' : _vm.$t('create'),"loading":_vm.parentVm.createWorkOrderRequest.loading,"error":_vm.parentVm.createWorkOrderRequest.error},on:{"click":function($event){return _vm.parentController.createWorkOrderButtonClicked()}}})],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }