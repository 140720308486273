

















import Vue from "vue";
import Layout from "@/common/views/Layout.vue";
import Container from "@/components/layout/Container.vue";

export default Vue.extend({
  name: "App",
  components: {
    Layout,
    Container
  },
  data() {
    return {
      isSideBarShown: false
    };
  },
  methods: {
    rightSideBarButtonClicked() {
      this.isSideBarShown = !this.isSideBarShown;
    }
  }
});
