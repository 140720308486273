














































































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import LoadingContainer from "@/components/layout/LoadingContainer.vue";

import Accordion from "@/components/layout/Accordion.vue";
import PlantSizesAccordion from "./reportTab/PlantSizesAccordion.vue";
import ParametersAccordion from "./reportTab/ParametersAccordion.vue";
import ReportAccordion from "./reportTab/ReportAccordion.vue";
import SampleAccordion from "./reportTab/SampleAccordion.vue";

import ValidatedSwitch from "@/components/form/vee-validate/ValidatedSwitch.vue";
import ValidatedDatepicker from "@/components/form/vee-validate/ValidatedDatepicker.vue";

import { ReportTabViewModel } from "@/report/vms/ReportTabViewModel";
import { ReportTabPresenter } from "@/report/presenters/ReportTabPresenter";
import { ReportTabController } from "@/report/controllers/ReportTabController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";

@Component<ReportTab>({
  components: {
    ValidationObserver,

    Row,
    Column,
    LoadingContainer,

    Accordion,
    PlantSizesAccordion,
    ParametersAccordion,
    ReportAccordion,
    SampleAccordion,

    ValidatedSwitch,
    ValidatedDatepicker
  },
  props: [
    "parentController",
    "parentVm",
    "reportData",
    "plantType",
    "plantId",
    "serviceEngineerId",
    "serviceEngineerName"
  ]
})
export default class ReportTab extends Vue {
  public controller?: ReportTabController;
  private vm = new ReportTabViewModel();

  private initController(): ReportTabController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );

    return new ReportTabController(
      new ReportTabPresenter(this.vm),
      this.$props.parentVm,
      this.$props.plantType
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(
      (this.$refs.plantSizesAccordion as PlantSizesAccordion).vm,
      (this.$refs.parametersAccordion as ParametersAccordion).vm,
      (this.$refs.parametersAccordionIntake as ParametersAccordion).vm,
      (this.$refs.reportAccordion as ReportAccordion).vm,
      (this.$refs.sampleAccordion as SampleAccordion).vm,
      this.$props.reportData
    );
  }
}
