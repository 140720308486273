import { ISystemTablesOverviewPresenter } from "../controllers/SystemTablesOverviewController";
import { SystemTablesOverviewViewModel } from "../vms/SystemTablesOverviewViewModel";

export class SystemTablesOverviewPresenter
  implements ISystemTablesOverviewPresenter {
  public constructor(private vm: SystemTablesOverviewViewModel) {}

  public set settingsDict(settingsDict: { [key: string]: any[] }) {
    this.vm.settingsDict = settingsDict;
  }
}
