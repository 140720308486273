export class ArrayUtils {
  public static groupBy(data: any[], key: string) {
    return data.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  public static isArray(v: any): v is any[] {
    return Array.isArray(v);
  }

  public static partition<K, T>(items: T[], key: (item: T) => K) {
    const result: Map<K, T[]> = new Map();

    items.forEach(item => {
      const mapKey = key(item);
      const mapVal: T[] = result.get(mapKey) || [];

      mapVal.push(item);
      result.set(mapKey, mapVal);
    });

    return result;
  }
}
