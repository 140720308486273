





































import Vue from "vue";
import Component from "vue-class-component";

@Component<TimePicker>({
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    label: {
      type: String,
      default() {
        return this.$t("time");
      }
    },
    icon: {
      type: String,
      default: "access_time"
    },
    outlined: {
      type: Boolean,
      default: true
    },
    allowedMinutes: {
      type: Array,
      default() {
        return undefined;
      }
    },
    allowedHours: {
      type: Array,
      default() {
        return undefined;
      }
    },
    fontSize: {
      type: String,
      default: ""
    },
    minWidth: {
      type: String,
      default: "290px"
    },
    format: {
      type: String,
      default: "24hr"
    }
  },
  watch: {
    value() {
      this.time = this.parseTimeFromNumber(this.$props.value);
    }
  }
})
export default class TimePicker extends Vue {
  private expanded = false;
  private time: string = "";

  private mounted() {
    this.time = this.parseTimeFromNumber(this.$props.value);
  }

  private get timePickerFormat() {
    return this.$props.format === "12hr" ? "ampm" : this.$props.format;
  }

  private get timePickerStyle() {
    return {
      "--button-display": this.$props.format === "12hr" ? "none" : "block"
    };
  }

  private hourClicked(hour: string) {
    this.time = (parseInt(hour, 10) < 10 ? "0" + hour : hour) + ":00";
  }

  private chooseTime(time: string) {
    this.expanded = false;
    this.emitInput(this.parseTimeFromString(time));
  }

  private emitInput(time: number) {
    this.$emit("input", time);
    this.$emit("change", time);
  }

  private parseTimeFromNumber(time: number) {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
  }

  private parseTimeFromString(timeString: string) {
    const splitTime = timeString.split(":");
    let time = parseInt(splitTime[0], 10) * 60;
    time += parseInt(splitTime[1], 10);
    return time;
  }
}
