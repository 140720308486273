var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"title":"Materialanforderungen"}},[_c('router-view'),_c('Section',{staticClass:"ma-2"},[_c('Container',[_c('Row',{attrs:{"align-center":""}},[_c('Column',{attrs:{"grow":""}}),_c('Column',{attrs:{"px-2":"","xs12":"","md6":""}},[_c('v-text-field',{attrs:{"label":"Suche"},on:{"input":function($event){return _vm.controller.getItems()}},model:{value:(_vm.vm.search),callback:function ($$v) {_vm.$set(_vm.vm, "search", $$v)},expression:"vm.search"}})],1)],1),_c('Row',[_c('Column',[_c('server-datatable',{attrs:{"headers":_vm.vm.headers,"mobileHeaders":_vm.vm.headers,"tabletHeaders":_vm.vm.headers,"itemsPerPage":_vm.vm.itemsPerPage,"items":_vm.vm.items,"totalItems":_vm.vm.totalItems,"value":_vm.vm.pageNumber,"options":_vm.vm.options,"multisort":true,"loading":_vm.vm.loading},on:{"sort":function($event){return _vm.controller.tableSorted($event)},"input":function($event){return _vm.controller.pageChanged($event)}},scopedSlots:_vm._u([{key:"item.done",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.done ? "check" : "clear"))])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.controller.parseDate(item.date))+" ")]}},{key:"item.employeeName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.employee.lastname) + ", " + (item.employee.firstname)))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('IconButton',{staticClass:"px-1",attrs:{"icon":"visibility","tooltipText":"Öffnen"},on:{"click":function($event){return _vm.openMaterialRequest(item)}}}),(!item.done)?_c('IconButton',{staticClass:"px-1",attrs:{"icon":"check","tooltipText":"Als erledigt markieren"},on:{"click":function($event){return _vm.controller.setMaterialRequestDone(item.id)}}}):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }