



















































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";
import ValidatedSwitch from "@/components/form/vee-validate/ValidatedSwitch.vue";

import RequestButton from "@/components/basic/RequestButton.vue";

import { CreateItemTemplateViewModel } from "@/store/vms/CreateItemTemplateViewModel";
import { CreateItemTemplatePresenter } from "@/store/presenters/CreateItemTemplatePresenter";
import { CreateItemTemplateController } from "@/store/controllers/CreateItemTemplateController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { SupplierGraphQLService } from "../services/SupplierGraphQLService";
import { ItemTemplateService } from "../services/ItemTemplateService";

@Component<CreateItemTemplateView>({
  components: {
    ValidationObserver,
    Card,
    Container,
    Column,
    Row,
    ValidatedTextfield,
    ValidatedSelect,
    ValidatedSwitch,
    RequestButton
  },
  props: {
    itemTemplateId: String
  },
  watch: {
    "vm.visibleView"(newValue) {
      if (!newValue) {
        setTimeout(() => {
          this.$emit("destroyed");
          this.$destroy();
        }, 500);
      }
    }
  }
})
export default class CreateItemTemplateView extends Vue {
  private vm = new CreateItemTemplateViewModel();
  private controller?: CreateItemTemplateController;

  private formStep = 1;

  private initController(): CreateItemTemplateController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreateItemTemplateController(
      new CreateItemTemplatePresenter(this.vm),
      new ItemTemplateService(connection),
      new SupplierGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$props.itemTemplateId);
  }
}
