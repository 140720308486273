import { FormResponse } from "./FormResponse";

export class FormRequestHandler {
  public static async handle<TResponse>(
    request: Promise<TResponse>,
    responseCallback: (response: FormResponse<TResponse>) => void,
    errorSlug: string
  ) {
    const formResponse = new FormResponse<TResponse>();

    formResponse.loading = true;
    formResponse.error = "";
    responseCallback(formResponse);

    await request
      .then(response => {
        formResponse.data = response;
        formResponse.loading = false;
      })
      .catch(reason => {
        formResponse.error = reason.message || errorSlug;
        formResponse.loading = false;
      });

    responseCallback(formResponse);
    return formResponse;
  }
}
