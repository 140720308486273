import {
  Request,
  Textfield,
  Selection,
  Checkbox,
  Numberfield
} from "@/forms/ViewModelFormTypes";

export class SystemTableEntryViewModel {
  // Data
  public entryId: string = "";
  public tableId: string = "";
  public savedEntry: string = "";
  public deletedEntry: string = "";

  // Texts
  public title: string = "";
  public deleteEntryButtonText: string = "";
  public saveEntryButtonText: string = "";
  public addLineButtonText: string = "";
  public deleteLineButtonText: string = "";

  // Responses
  public loadPageRequest: Request = { loading: false, error: "" };
  public saveEntryRequest: Request = { loading: false, error: "" };
  public deleteEntryRequest: Request = { loading: false, error: "" };

  // States
  public saveEntryButtonDisabled: boolean = true;
  public deleteEntryButtonVisible: boolean = false;

  // Form
  public fields: EntryFields = {};
}

export interface EntryFields {
  [key: string]: Field;
}

export interface EntryList {
  name: string;
  type: string;
  info: string;
  fields: EntryFields[];
}

type EntryField = (Textfield | Selection | Checkbox | Numberfield) & EntryList;

type Field = EntryField | EntryList;
