























































































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import ServerDatatable from "@/components/collection/ServerDatatable.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import IconButton from "@/components/basic/IconButton.vue";
import DeleteDialog from "@/components/basic/DeleteDialog.vue";
import CreateStoreMovementView from "@/store/views/CreateStoreMovementView.vue";

import { StoreMovementsViewModel } from "@/store/vms/StoreMovementsViewModel";
import { StoreMovementsPresenter } from "@/store/presenters/StoreMovementsPresenter";
import { StoreMovementsController } from "@/store/controllers/StoreMovementsController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { StoreMovementService } from "../services/StoreMovementService";
import { StoreService } from "../services/StoreService";
import { ItemTemplateService } from "../services/ItemTemplateService";
import { StoreMovement } from "../services/StoreMovementGraphQLService";

@Component<StoreMovementsView>({
  components: {
    Page,
    Section,
    ServerDatatable,
    Card,
    Container,
    IconButton,
    Column,
    Row,
    DeleteDialog,
    CreateStoreMovementView
  }
})
export default class StoreMovementsView extends Vue {
  private vm = new StoreMovementsViewModel();
  private controller?: StoreMovementsController = undefined;

  private isDeleteDialogShown = false;
  private toDeleteStoreMovement: any = {};
  private components: any[] = [];

  private initController(): StoreMovementsController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new StoreMovementsController(
      new StoreMovementsPresenter(this.vm),
      new StoreMovementService(connection),
      new StoreService(connection),
      new ItemTemplateService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(
      this.$route.params.storeId,
      this.$route.params.itemTemplateId
    );
  }

  // private addStoreMovementsButtonClicked() {
  //   const route: RawLocation = { name: "store-movement-create-overlay" };

  //   this.$router.push(route);
  // }

  private addStoreMovementsButtonClicked(storeMovementId?: string) {
    this.components.push({ id: storeMovementId });
  }

  private clearComponents() {
    this.controller?.getMovements();
    this.components = [];
  }

  private showDeleteDialog(storeMovement: StoreMovement) {
    this.toDeleteStoreMovement = {
      id: storeMovement.id,
      name: storeMovement.itemTemplate.name,
      date: this.controller?.parseDate(storeMovement.date),
      amount: storeMovement.amount
    };
    this.isDeleteDialogShown = true;
  }

  private deleteStoreMovement() {
    this.controller?.deleteStoreMovement(this.toDeleteStoreMovement.id);
    this.isDeleteDialogShown = false;
  }
}
