import Router from "@/router";

export interface Dictionary<T> {
  [key: string]: T;
}

export interface CountryList extends Dictionary<Country> {}
export interface StateList extends Dictionary<State> {}
export interface PlantGroupList extends Dictionary<PlantGroup> {}
export interface PlantTypeList extends Dictionary<PlantType> {}
export interface DegreeList extends Dictionary<Degree> {}
export interface AreaCodeList extends Dictionary<AreaCode> {}

export interface Country {
  value: string;
  text: string;
  states: StateList;
}

export interface State {
  value: string;
  text: string;
}

export interface PlantGroup {
  value: string;
  text: string;
  types: PlantTypeList;
}

export interface PlantType {
  value: string;
  text: string;
}

export interface Degree {
  value: string;
  text: string;
  preceding: boolean;
}

export interface AreaCode {
  value: string;
  text: string;
}

export const states: StateList = {
  upperAustria: {
    text: Router
      ? Router.app.$t("country.austria.upper_austria").toString()
      : "Oberösterreich",
    value: "upperAustria"
  },
  styria: {
    text: Router
      ? Router.app.$t("country.austria.styria").toString()
      : "Steiermark",
    value: "styria"
  },
  lowerAustria: {
    text: Router
      ? Router.app.$t("country.austria.lower_austria").toString()
      : "Niederösterreich",
    value: "lowerAustria"
  },
  vienna: {
    text: Router ? Router.app.$t("country.austria.vienna").toString() : "Wien",
    value: "vienna"
  },
  burgenland: {
    text: Router
      ? Router.app.$t("country.austria.burgenland").toString()
      : "Burgenland",
    value: "burgenland"
  },
  carintha: {
    text: Router
      ? Router.app.$t("country.austria.carintha").toString()
      : "Kärnten",
    value: "carintha"
  },
  salzburg: {
    text: Router
      ? Router.app.$t("country.austria.salzburg").toString()
      : "Salzburg",
    value: "salzburg"
  },
  tyrol: {
    text: Router ? Router.app.$t("country.austria.tyrol").toString() : "Tirol",
    value: "tyrol"
  },
  vorarlberg: {
    text: Router
      ? Router.app.$t("country.austria.vorarlberg").toString()
      : "Vorarlberg",
    value: "vorarlberg"
  }
};

export const countries: CountryList = {
  austria: {
    text: Router
      ? Router.app.$t("country.austria.name").toString()
      : "Österreich",
    value: "austria",
    states: {
      upperAustria: {
        text: Router
          ? Router.app.$t("country.austria.upper_austria").toString()
          : "Oberösterreich",
        value: "upperAustria"
      },
      styria: {
        text: Router
          ? Router.app.$t("country.austria.styria").toString()
          : "Steiermark",
        value: "styria"
      },
      lowerAustria: {
        text: Router
          ? Router.app.$t("country.austria.lower_austria").toString()
          : "Niederösterreich",
        value: "lowerAustria"
      },
      vienna: {
        text: Router
          ? Router.app.$t("country.austria.vienna").toString()
          : "Wien",
        value: "vienna"
      },
      burgenland: {
        text: Router
          ? Router.app.$t("country.austria.burgenland").toString()
          : "Burgenland",
        value: "burgenland"
      },
      carintha: {
        text: Router
          ? Router.app.$t("country.austria.carintha").toString()
          : "Kärnten",
        value: "carintha"
      },
      salzburg: {
        text: Router
          ? Router.app.$t("country.austria.salzburg").toString()
          : "Salzburg",
        value: "salzburg"
      },
      tyrol: {
        text: Router
          ? Router.app.$t("country.austria.tyrol").toString()
          : "Tirol",
        value: "tyrol"
      },
      vorarlberg: {
        text: Router
          ? Router.app.$t("country.austria.vorarlberg").toString()
          : "Vorarlberg",
        value: "vorarlberg"
      }
    }
  }
};

export const plantGroups: PlantGroupList = {
  oilSeparator: {
    value: "oilSeparator",
    text: "Ölabscheider",
    types: {
      SMA: {
        value: "SMA",
        text: "SMA"
      },
      ASI: {
        value: "ASI",
        text: "ASI"
      },
      BA: {
        value: "BA",
        text: "BA"
      }
    }
  }
};

export const degrees: DegreeList = {
  dr: {
    text: "Dr.",
    value: "dr",
    preceding: true
  },
  phd: {
    text: "PhD",
    value: "phd",
    preceding: false
  },
  ma: {
    text: "MA",
    value: "ma",
    preceding: false
  },
  msc: {
    text: "MSc",
    value: "msc",
    preceding: false
  },
  ba: {
    text: "BA",
    value: "ba",
    preceding: false
  }
};

export const areaCodes: AreaCodeList = {
  austria: {
    text: "+43 (Austria)",
    value: "+43"
  },
  germany: {
    text: "+49 (Germany)",
    value: "+49"
  }
};

export const mapIcons: string[] = [
  "https://maps.google.com/mapfiles/ms/icons/green.png",
  "https://maps.google.com/mapfiles/ms/icons/red.png",
  "https://maps.google.com/mapfiles/ms/icons/yellow.png"
];

export const blueMarkerIcon: string =
  "https://maps.google.com/mapfiles/ms/icons/blue-dot.png";
