





































import Vue from "vue";
import Component from "vue-class-component";

@Component<DatePicker>({
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default() {
        return { value: "", text: "", label: "Datum", error: "" };
      }
    },
    icon: {
      type: String,
      default: "event"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    birthday: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    extraDense: {
      type: Boolean,
      default: false
    },
    allowedDates: {
      type: Function,
      default: null
    }
  },
  watch: {
    expanded(val: boolean) {
      if (val && this.$props.birthday) {
        setTimeout(() => {
          const picker = this.$refs.picker as any;
          picker.activePicker = "YEAR";
        });
      }
    }
  }
})
export default class DatePicker extends Vue {
  private expanded = false;

  private chooseDate(date: string) {
    this.expanded = false;
    this.emitInput(date);
  }

  private emitInput(date: string) {
    this.$emit("input", date);
    this.$emit("change", date);
  }

  private get maxDate() {
    return this.$props.birthday
      ? new Date().toISOString().substr(0, 10)
      : undefined;
  }

  private mounted() {
    // console.log(this.$props.value);
  }
}
