import { IMultiUploadPresenter } from "../controllers/MultiUploadController";
import {
  MultiUploadViewModel,
  MultiUploadPlantDocument
} from "../vms/MultiUploadViewModel";

export class MultiUploadPresenter implements IMultiUploadPresenter {
  public constructor(private vm: MultiUploadViewModel) {}

  public set loading(loading: boolean) {
    this.vm.loading = loading;
  }

  public set error(error: string) {
    this.vm.error = error;
  }

  public get documents() {
    return this.vm.documents;
  }

  public set documents(documents: MultiUploadPlantDocument[]) {
    this.vm.documents = documents;
  }
}
