import {
  Appointment,
  IAppointmentService
} from "../services/AppointmentGraphQLService";
import { EventHandler } from "@/common/utils/EventHandler";
import Vue from "vue";

// tslint:disable: member-ordering
export class AppointmentHandler {
  private retryInterval = 10000;
  private appointments: AppointmentFunction[] = [];

  public constructor(private appointmentService: IAppointmentService) {
    EventHandler.addEvent(this, this.retry, this.retryInterval, true);
  }

  public async addFunction(
    appointment: Appointment,
    tourWeekId: string | null,
    tourDayIndex: number | null,
    plannedDate: string | null,
    callback: (appointment: Appointment) => void
  ) {
    const updateFunction = this.appointmentService
      .updateAppointmentTourDayIndex;
    appointment.updateFunction = updateFunction.bind(
      this.appointmentService,
      appointment.id,
      tourWeekId,
      tourDayIndex,
      plannedDate
    );

    try {
      await appointment.updateFunction!();
    } catch (error) {
      this.appointments.push({ appointment, callback });
    }
  }

  public async retry() {
    if (this.appointments.length === 0) {
      return;
    }

    for (let i = this.appointments.length - 1; i >= 0; i--) {
      const appointment = this.appointments[i].appointment;

      try {
        await appointment.updateFunction!();

        Vue.set(appointment, "customColor", undefined);
        appointment.updateSuccess = true;
        Vue.set(appointment, "loading", false);

        // this.appointments[i].callback(appointment);
        this.appointments.splice(i, 1);
      } catch (error) {
        continue;
      }
    }
  }
}

interface AppointmentFunction {
  appointment: Appointment;
  callback: (appointment: Appointment) => void;
}
