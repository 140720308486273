

















import Vue from "vue";
import Component from "vue-class-component";

@Component<SimpleCheckbox>({
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: false, error: "" };
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  }
})
export default class SimpleCheckbox extends Vue {
  private get val() {
    return !!this.$props.value.value;
  }

  private emitChange(value: boolean) {
    this.$emit("change", !!value);
  }
}
