import { OnlineChecker } from "./OnlineChecker";
import { IsOnlineOnlineChecker } from "./IsOnlineOnlineChecker";

export class OnlineCheckerFactory {
  public static instance?: OnlineChecker = undefined;

  public static create(context?: any, initialCallbacks?: Array<() => void>) {
    if (!this.instance) {
      this.instance = new IsOnlineOnlineChecker();
      if (!!initialCallbacks) {
        for (const callback of initialCallbacks) {
          this.addCallback(context, callback, true, true);
        }
      }
      this.instance.init();
    }
  }

  public static get isOnline() {
    return !this.instance || this.instance.isOnline;
  }

  public static addCallback(
    context: any,
    callbackFunction: () => void,
    onlyOnce: boolean = false,
    executeAlsoOffline: boolean = false
  ) {
    if (!!this.instance) {
      this.instance.addCallback(
        context,
        callbackFunction,
        onlyOnce,
        executeAlsoOffline
      );
    }
  }

  public static start() {
    if (!!this.instance) {
      this.instance.startLoop();
    }
  }

  public static stop() {
    if (!!this.instance) {
      this.instance.stopLoop();
    }
  }
}
