var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.lat && _vm.lng)?_c('GoogleMap',{staticStyle:{"width":"100%","height":"300px"},attrs:{"options":{
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false
    },"center":{ lat: _vm.lat, lng: _vm.lng },"zoom":16}},[_c('GoogleMapMarker',{attrs:{"position":{ lat: _vm.lat, lng: _vm.lng },"clickable":true,"draggable":_vm.draggable},on:{"dragend":function($event){return _vm.$emit('dragend', $event)}}},[(_vm.draggable)?_c('GoogleMapInfoWindow',{attrs:{"options":{ disableAutoPan: true }},model:{value:(_vm.infoWindowShown),callback:function ($$v) {_vm.infoWindowShown=$$v},expression:"infoWindowShown"}},[_vm._v("Bitte den Marker verschieben, um den genauen Standort anzugeben.")]):_vm._e()],1)],1):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }