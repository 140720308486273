
































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import TruncatedText from "@/components/layout/TruncatedText.vue";

import IconButton from "@/components/basic/IconButton.vue";

import Datatable from "@/components/collection/Datatable.vue";

import { SystemTableListController } from "@/system/controllers/SystemTableListController";
import { SystemTableListPresenter } from "@/system/presenters/SystemTableListPresenter";
import { SystemTableListViewModel } from "@/system/vms/SystemTableListViewModel";
import { GraphQLSystemTableService } from "../services/SystemTableService";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { LocalStorage } from "../../storage/LocalStorage";
import { SystemTableUtils } from "../services/SystemTableUtils";

@Component<SystemTableListView>({
  components: {
    Page,
    Section,
    TruncatedText,
    IconButton,
    Datatable
  },
  watch: {
    "vm.addNewEntry"(add: boolean) {
      if (add) {
        this.$router.push({
          name: "system-table-entry",
          params: { tableId: this.tableId }
        });
      }
    },
    "vm.entryToEdit"(id: string) {
      this.$router.push({
        name: "system-table-entry",
        params: { tableId: this.tableId, entryId: id }
      });
    }
  }
})
export default class SystemTableListView extends Vue {
  private vm = new SystemTableListViewModel();
  private controller: any;

  private get tableId(): string {
    return this.$route.params.tableId;
  }

  private created() {
    this.controller = this.createController();
  }

  private mounted() {
    this.controller.init(this.tableId);
  }

  private createController(): SystemTableListController {
    const storage = new LocalStorage();

    return new SystemTableListController(
      new SystemTableListPresenter(
        this.vm,
        storage,
        new SystemTableUtils(storage)
      ),
      new GraphQLSystemTableService(
        new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
      )
    );
  }
}
