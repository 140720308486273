


























































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import { DateUtils } from "@/utils/DateUtils";

@Component<ScheduleCard>({
  components: {
    Card,
    Container,
    Column,
    Row
  },
  props: {
    appointment: Object,
    showTooltip: {
      type: Boolean,
      default: true
    },
    tooltipText: {
      type: String,
      default: ""
    },
    isDrive: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: true
    },
    startTime: {
      type: String,
      default: "08:00"
    },
    absolute: {
      type: Boolean,
      default: false
    },
    weekStartHour: {
      type: Number,
      default: 7
    },
    color: {
      type: String,
      default: "primary"
    }
  },
  watch: {
    "appointment.customColor"(newValue: any) {
      if (!!newValue) {
        this.cardColor = newValue;
      } else {
        this.cardColor = "primary";
      }
    },
    "appointment.loading"(newValue: boolean) {
      this.loading = newValue;
    }
  }
})
export default class ScheduleCard extends Vue {
  private cardColor = "primary";
  private loading = false;

  public mounted() {
    this.cardColor = this.$props.color;
    this.loading = this.$props.appointment.loading;
  }

  public getAppointmentHeight(duration: number) {
    let parsedDuration = duration / 60;
    if (this.$props.isDrive) {
      parsedDuration /= 60;
    }
    return parsedDuration * 71;
  }

  public plannedDateToTop(plannedDate: string) {
    const date = DateUtils.getDate(plannedDate);
    let top = 0;
    top += (date.getHours() - this.$props.weekStartHour) * 72;
    top += (date.getMinutes() / 60) * 72;

    return top + "px";
  }

  public startTimeToTop() {
    const time = this.$props.startTime.split(":");
    let top = 0;
    top += (parseInt(time[0], 10) - this.$props.weekStartHour) * 72;
    top += (parseInt(time[1], 10) / 60) * 72;

    return top + "px";
  }
}
