import { ITourDataPresenter } from "../controllers/TourDataController";
import { TourDataViewModel } from "../vms/TourDataViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { ServiceEngineer } from "../services/ServiceEngineerGraphQLService";
import { TourDataVMEntity } from "../vms/entities/TourDataVMEntity";

export class TourDataPresenter implements ITourDataPresenter {
  public constructor(private vm: TourDataViewModel) {
    this.initStaticTexts();
  }

  public set loadTourDataResponse(response: FormResponse<ServiceEngineer>) {
    this.vm.loadTourDataRequest = response;

    if (response.success) {
      this.vm.tourData = TourDataVMEntity.from(response.data);
    }
  }

  public set saveTourDataResponse(response: FormResponse<string>) {
    this.vm.saveTourDataRequest = response;
    this.vm.tourDataSaved = response.success;
  }

  private initStaticTexts() {
    this.vm.saveTourDataButtonText = "Speichern";
  }
}
