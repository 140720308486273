import {
  SystemTableService,
  SystemTableStructure,
  SystemTableEntries
} from "../services/SystemTableService";
import { FormResponse } from "@/forms/FormResponse";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { EnvironmentUtils } from "@/utils/EnvironmentUtils";
import {
  InitController,
  IInitPresenter
} from "@/common/controllers/InitController";

export class SystemTableListController extends InitController<
  ISystemTableListPresenter
> {
  public constructor(
    presenter: ISystemTableListPresenter,
    systemTableService: SystemTableService
  ) {
    super(presenter, systemTableService);
  }

  public async init(tableId: string) {
    await this.loadTableStructure(tableId);
    EnvironmentUtils.execOnNextTick(
      async () => await this.loadEntries(tableId)
    );
    await this.loadSettingsTables();
    await this.loadSettings();
  }

  public addEntryButtonClicked() {
    this.presenter.addNewEntry = true;
  }

  public editSystemEntryButtonClicked(entryId: string) {
    this.presenter.entryToEdit = entryId;
  }

  private loadTableStructure(tableId: string) {
    const request = this.systemTableService.getTableStructure(tableId);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadTableStructureResponse = response),
      "Tabellenstruktur konnte nicht geladen werden"
    );

    return request;
  }

  private loadEntries(tableId: string) {
    const request = this.systemTableService.getAllTableEntries(tableId);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadTableEntriesResponse = response),
      "Tabellenstruktur konnte nicht geladen werden"
    );

    return request;
  }
}

export interface ISystemTableListPresenter extends IInitPresenter {
  // Data
  addNewEntry: boolean;
  entryToEdit: string;

  // Responses
  loadTableStructureResponse: FormResponse<SystemTableStructure>;
  loadTableEntriesResponse: FormResponse<SystemTableEntries>;
}
