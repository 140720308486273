

































































































































import Vue from "vue";
import Component from "vue-class-component";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Grid from "@/components/layout/Grid.vue";

import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import PlantDataCard from "@/plant/views/PlantDataCard.vue";

import Page from "@/components/layout/Page.vue";
import ExpandableCard from "@/components/layout/ExpandableCard.vue";

import OperatorDocumentsData from "@/plant/views/OperatorDocumentsData.vue";

import { OperatorViewModel } from "@/plant/vms/OperatorViewModel";
import { OperatorViewPresenter } from "@/plant/presenters/OperatorViewPresenter";
import { OperatorViewController } from "@/plant/controllers/OperatorViewController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";

import { OperatorGraphQLService } from "../services/OperatorGraphQLService";
import { SalutationStorageHandler } from "@/storage/storageHandlers/SalutationStorageHandler";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { Auth } from "../../common/utils/Auth";
import StorageKeys from "../../common/utils/StorageKeys";

@Component<OperatorView>({
  components: {
    Card,
    Container,
    Column,
    Row,
    Page,
    Grid,
    PlantDataCard,
    ExpandableCard,
    OperatorDocumentsData,

    CreateOperatorView: () => import("@/plant/views/CreateOperatorView.vue")
  }
})
export default class OperatorView extends Vue {
  private vm = new OperatorViewModel();
  private controller?: OperatorViewController;
  private components: any[] = [];

  private get operatorId() {
    return this.$route.params.operatorId;
  }

  private initController(): OperatorViewController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new OperatorViewController(
      new OperatorViewPresenter(this.vm),
      new OperatorGraphQLService(connection),
      this.operatorId
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    if (this.$route.name !== "operator-create") {
      this.controller?.init();
    }
  }

  private editOperator() {
    this.components.push("CreateOperatorView");
  }

  private parseSalutation(salutationId: string) {
    return SalutationStorageHandler.getShortSalutationById(salutationId);
  }

  private parseAreaCode(countryId: string) {
    return CountriesStorageHandler.getAreaCodeById(countryId);
  }

  private reload() {
    this.components = [];
    this.controller?.getOperatorById();
  }

  private canEdit() {
    return Auth.hasPermission(StorageKeys.editPlantAndOperatorPermission);
  }
}
