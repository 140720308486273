import * as bwipjs from "bwip-js";

export class BarcodeGenerator {
  public static downloadBarcode(
    itemNumber: string,
    name?: string,
    dimensions?: string
  ) {
    const barcodeCanvas = document.createElement("canvas");

    const maxPixelWidth = 316;
    const maxPixelHeight = 201;

    bwipjs.toCanvas(barcodeCanvas, {
      bcid: "code39",
      text: itemNumber,
      includetext: true,
      textxalign: "center",
      height: 15,
      scale: 2
    });

    document.body.appendChild(barcodeCanvas);

    const textCanvas = document.createElement("canvas");
    const textCtx = textCanvas.getContext("2d");
    if (textCtx) {
      textCanvas.width = maxPixelWidth;
      textCanvas.height = maxPixelHeight;

      textCtx.fillStyle = "white";
      textCtx.fillRect(0, 0, textCanvas.width, textCanvas.height);

      const barcodeWidth = maxPixelWidth * 0.8;
      const barcodeHeight = 115; // maxPixelHeight * 0.7;
      textCtx.drawImage(barcodeCanvas, 3, 3, barcodeWidth, barcodeHeight);

      textCtx.fillStyle = "black";
      textCtx.font = Math.floor(maxPixelHeight * 0.08).toString() + "px Arial";
      textCtx.fillText(itemNumber, barcodeWidth + 5, barcodeHeight / 2);

      if (name) {
        textCtx.fillText(name, 5, barcodeHeight + 10 + 15);
      }
      if (dimensions) {
        textCtx.fillText(
          dimensions,
          5,
          barcodeHeight + 10 + 15 + (name ? 25 : 0)
        );
      }
    }
    document.body.appendChild(textCanvas);

    const link = document.createElement("a");
    link.setAttribute("href", textCanvas.toDataURL());
    link.setAttribute("download", name ? `Barcode ${name}.png` : "Barcode.png");
    link.setAttribute("target", "_blank");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    document.body.removeChild(barcodeCanvas);
    document.body.removeChild(textCanvas);
  }
}
