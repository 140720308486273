import {
  IServiceEngineerService,
  ServiceEngineer
} from "../services/ServiceEngineerGraphQLService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { TourDataVMEntity } from "../vms/entities/TourDataVMEntity";

export class TourDataController {
  public constructor(
    private presenter: ITourDataPresenter,
    private engineerService: IServiceEngineerService
  ) {}

  public mounted(serviceEngineerId: string) {
    this.loadTourData(serviceEngineerId);
  }

  public saveTourDataButtonClicked(tourData: TourDataVMEntity) {
    const input = tourData.toTourDataInput();
    const request = this.engineerService.updateTourData(input);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.saveTourDataResponse = response),
      "Tourdaten konnten nicht geändert werden"
    );
  }

  private loadTourData(serviceEngineerId: string) {
    if (!serviceEngineerId) {
      return;
    }

    const request = this.engineerService.getServiceEngineerById(
      serviceEngineerId
    );

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadTourDataResponse = response),
      "Servicetechniker konnte nicht geladen werden"
    );
  }
}

export interface ITourDataPresenter {
  // Responses
  loadTourDataResponse: FormResponse<ServiceEngineer>;
  saveTourDataResponse: FormResponse<string>;
}
