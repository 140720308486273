import { Auth } from "@/common/utils/Auth";
import StorageKeys from "@/common/utils/StorageKeys";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";

export class SystemTablesOverviewController {
  public constructor(
    private presenter: ISystemTablesOverviewPresenter,
    private storage: SimpleStringStorage
  ) {}

  public mounted() {
    const settingsTablesAsString = this.storage.get(StorageKeys.settingsTables);

    if (!settingsTablesAsString) {
      return;
    }

    const tables = JSON.parse(settingsTablesAsString);
    const items: any[] = tables.map((t: any) => ({
      text: t.labelPlural,
      id: t.id,
      visible: Auth.hasSystemPermission(t.id),
      group: t.group || "Sonstige"
    }));

    const groups = items
      .map(el => el.group)
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .sort((a, b) => a.localeCompare(b));

    const settingsDict: { [key: string]: any[] } = {};

    for (const group of groups) {
      settingsDict[group] = items.filter(
        el => el.group === group && el.visible
      );
    }

    this.presenter.settingsDict = settingsDict;
  }
}

export interface ISystemTablesOverviewPresenter {
  settingsDict: { [key: string]: any[] };
}
