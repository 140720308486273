import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { SupplierOrder } from "../services/SupplierGraphQLService";

export class SupplierOrderOverviewViewModel {
  public itemsPerPage = 25;
  public search = "";

  public supplierFilter: {
    selected: string[];
    items: SelectionItem[];
  } = {
    selected: [],
    items: []
  };

  public headers = [
    { text: "Datum", value: "date" },
    { text: "Lieferant", value: "supplierName" },
    { text: "Bestellt von", value: "employeeName" }
  ];
  public items: SupplierOrder[] = [];
  public totalItems = 0;
  public options = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: this.itemsPerPage,
    multiSort: true,
    mustSort: false,
    sortBy: [],
    sortDesc: []
  };
  public loading = true;
  public pageNumber = 1;
}
