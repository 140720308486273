import { Numberfield, Textfield } from "@/forms/ViewModelFormTypes";

export class TimeTrackingDataViewModel {
  public maintenanceDriveDuration: Numberfield = { value: 0, error: "" };
  public maintenanceWorkDuration: Numberfield = { value: 0, error: "" };
  public maintenanceBonus: Numberfield = { value: 0, error: "" };
  public maintenanceRegiments: Numberfield = { value: 0, error: "" };
  public othersDriveDuration: Numberfield = { value: 0, error: "" };
  public othersWorkDuration: Numberfield = { value: 0, error: "" };
  public othersBonus: Numberfield = { value: 0, error: "" };
  public othersRegiments: Numberfield = { value: 0, error: "" };
  public plantIndex: Numberfield = { value: 0, error: "" };
  public operator: any = { value: "", error: "", loading: false };
  public doneTasks: Textfield = { value: "", error: "" };
}
