import { FormResponse } from "@/forms/FormResponse";
import { ICreateStorePresenter } from "../controllers/CreateStoreController";
import { Employee } from "../services/EmployeeGraphQLService";
import { Store } from "../services/StoreGraphQLService";
import { CreateStoreViewModel } from "../vms/CreateStoreViewModel";

export class CreateStorePresenter implements ICreateStorePresenter {
  public constructor(private vm: CreateStoreViewModel) {}

  public set visibleView(visibleView: boolean) {
    this.vm.visibleView = visibleView;
  }

  public set getStoreResponse(response: FormResponse<Store>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.name = response.data.name;
      this.vm.description = response.data.description;
      this.vm.employee.selected = response.data.employeeId;
    }
  }

  public set getEmployeesResponse(response: FormResponse<Employee[]>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.employee.items = response.data.map(el => ({
        text: `${el.lastname.toLocaleUpperCase()}, ${el.firstname}`,
        value: el.id
      }));
    }
  }

  public get data(): any {
    return {
      employeeId: this.vm.employee.selected,
      name: this.vm.name,
      description: this.vm.description
    };
  }

  public set createStoreResponse(response: FormResponse<string>) {
    this.vm.loading = response.loading;
    this.vm.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.visibleView = false;
    }
  }
}
