import { Form, FormFieldType } from "@/forms/Form";

export class TourDayPropsForm extends Form {
  protected definition = {
    startTime: { required: true, type: FormFieldType.Time },

    startLocationStreet: { required: true, type: FormFieldType.Text },
    startLocationZip: { required: true, type: FormFieldType.Text },
    startLocationCity: { required: true, type: FormFieldType.Text },
    startLocationCountry: { required: true, type: FormFieldType.Selection },

    endLocationStreet: { required: true, type: FormFieldType.Text },
    endLocationZip: { required: true, type: FormFieldType.Text },
    endLocationCity: { required: true, type: FormFieldType.Text },
    endLocationCountry: { required: true, type: FormFieldType.Selection }
  };
}
