import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { SupplierOrderItem } from "../services/SupplierGraphQLService";

export class CreateSupplierOrderViewModel {
  public readonly = false;
  public visibleView = false;

  public loading = false;
  public error = "";

  public supplierName = "";
  public supplierOrderItems: SupplierOrderItem[] = [];

  public itemTemplates: {
    selected: string;
    items: SelectionItem[];
  } = {
    selected: "",
    items: []
  };
}
