import { IPlantDataPresenter } from "../controllers/PlantDataController";
import { PlantDataViewModel } from "../vms/PlantDataViewModel";
import { Plant } from "../services/PlantService";
import { FormResponse } from "@/forms/FormResponse";
import { ParameterStorageHandler } from "@/storage/storageHandlers/ParameterStorageHandler";

export class PlantDataPresenter implements IPlantDataPresenter {
  public set getPlantResponse(response: FormResponse<Plant>) {
    // this.vm.plant = response.loading;
    this.vm.error = response.error;

    if (response.success) {
      const plant = response.data;

      if (!!plant.parameters && plant.parameters.length > 0) {
        this.vm.plantParameters = [];
        for (const param of plant.parameters) {
          const parameter = ParameterStorageHandler.getParameterFromId(
            param.parameterId
          );
          parameter.value = param.value;
          this.vm.plantParameters.push(parameter);
        }
      }

      if (!!plant.addressAccessRemarks) {
        this.vm.addressAccessRemarksText.value = plant.addressAccessRemarks;
      }
      this.vm.plant = plant;
    }
  }

  public constructor(private vm: PlantDataViewModel) {}

  public set plant(plant: Plant) {
    this.vm.plant = plant;
  }

  public get plant(): Plant {
    return this.vm.plant;
  }

  public set markerDraggable(newValue: boolean) {
    this.vm.markerDraggable = newValue;
  }

  public get markerDraggable() {
    return this.vm.markerDraggable;
  }

  public set addressAccessRemarks(newText: string) {
    this.vm.addressAccessRemarksText.value = newText;
    this.plant.addressAccessRemarks = newText;
  }

  public set updatePlantResponse(response: FormResponse<string>) {
    // this.vm.plant = response.loading;
    this.vm.error = response.error;

    if (response.success) {
      /* this.vm.plant = response.data;
      if (!!this.plant.districtAuthority) {
        this.vm.plant.districtAuthority = DistrictAuthorityStorageHandler.getDistrictAuthorityNameById(
          this.plant.districtAuthority
        );
      } */
    }
  }
}
