

















































import Vue from "vue";
import Component from "vue-class-component";
import Draggable from "vuedraggable";

@Component<Accordion>({
  components: {
    Draggable
  },
  props: {
    value: {
      type: Number,
      default: undefined
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    connected: {
      type: Boolean,
      default: false
    },
    eager: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    }
  }
})
export default class Accordion extends Vue {
  private selectedItem = undefined;

  private stateChanged(item: any, test: any) {
    if (item !== this.selectedItem) {
      this.$emit("open", item);
      this.selectedItem = item;
    } else {
      this.$emit("close", item);
      this.selectedItem = undefined;
    }
  }
}
