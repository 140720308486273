import { SelectionItem } from "@/forms/ViewModelFormTypes";

export class GeneralTabViewModel {
  public usedTime = { value: 0, text: "00:00" };
  public doneTasks: { selected: string[]; items: SelectionItem[] } = {
    items: [],
    selected: []
  };
  public usedMaterial: {
    selected: Array<{ value: string; text: string; amount: number }>;
    items: SelectionItem[];
  } = {
    items: [],
    selected: []
  };
  public usedMaterialLocalStorage: SelectionItem[] = [];
  public usedMaterialStore: SelectionItem[] = [];
}
