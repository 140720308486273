















import Vue from "vue";
import Component from "vue-class-component";

import Checkbox from "@/components/form/Checkbox.vue";

@Component<CheckboxGroup>({
  components: {
    Checkbox
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          label: "Label",
          error: "",
          loading: false,
          items: [],
          selected: []
        };
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class CheckboxGroup extends Vue {
  private checkboxChanged(checked: boolean, expectedValue: string) {
    const newResult = this.$props.value.selected.filter(
      (val: any) => val !== expectedValue
    );
    if (checked) {
      newResult.push(expectedValue);
    }

    this.$emit("change", newResult);
  }

  private get items() {
    const val = this.$props.value;
    return val.items.map((item: any) => ({
      label: item.text,
      value: item.value,
      selected: !!val.selected.find((v: any) => v === item.value),
      error: "",
      loading: val.loading
    }));
  }
}
