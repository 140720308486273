




















































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ServerDatatable from "@/components/collection/ServerDatatable.vue";
import IconButton from "@/components/basic/IconButton.vue";

import { StoreItemCardViewModel } from "@/store/vms/StoreItemCardViewModel";
import { StoreItemCardPresenter } from "@/store/presenters/StoreItemCardPresenter";
import { StoreItemCardController } from "@/store/controllers/StoreItemCardController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { StoreMovementGraphQLService } from "../services/StoreMovementGraphQLService";

@Component<StoreItemCardView>({
  components: {
    Card,
    ServerDatatable,
    Container,
    Column,
    Row,
    IconButton
  },
  props: ["plant", "plantRequest", "element"],
  watch: {
    plant(newValue: any) {
      this.controller?.mounted(newValue.id);
    }
  }
})
export default class StoreItemCardView extends Vue {
  private vm = new StoreItemCardViewModel();
  private controller?: StoreItemCardController;

  private initController(): StoreItemCardController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new StoreItemCardController(
      new StoreItemCardPresenter(this.vm),
      new StoreMovementGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$props.plant.id);
  }

  private addStoreMovementsButtonClicked() {
    const route = {
      name: "store-movement-create",
      params: { plantIndex: this.$props.plant.index }
    };

    this.$router.push(route);
  }
}
