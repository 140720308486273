













































import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import ConfirmDialog from "@/components/basic/ConfirmDialog.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

@Component<Page>({
  components: {
    Row,
    Column,
    ConfirmDialog,
    RequestButton
  },
  props: {
    title: {
      type: String,
      default: "Titel"
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    },
    actionText: {
      type: String,
      default: ""
    },
    actionIcon: {
      type: String,
      default: ""
    }
  }
})
export default class Page extends Vue {
  private emitAction() {
    this.$emit("action");
  }
}
