















































































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import ServerDatatable from "@/components/collection/ServerDatatable.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import Section from "@/components/layout/Section.vue";
import IconButton from "@/components/basic/IconButton.vue";

import { WorkOrderMetaListViewModel } from "@/report/vms/WorkOrderMetaListViewModel";
import { WorkOrderMetaListPresenter } from "@/report/presenters/WorkOrderMetaListPresenter";
import { WorkOrderMetaListController } from "@/report/controllers/WorkOrderMetaListController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { WorkOrder, WorkOrderService } from "../services/WorkOrderService";

@Component<WorkOrderMetaListView>({
  components: {
    Page,
    Card,
    Container,
    Column,
    Row,
    ServerDatatable,
    Checkbox,
    RequestButton,
    Section,
    IconButton
  },
  watch: {
    "vm.reload"(newValue: any) {
      if (newValue) {
        this.vm.loading = true;
        this.controller?.getWorkOrders();
      }
    }
  }
})
export default class WorkOrderMetaListView extends Vue {
  private vm = new WorkOrderMetaListViewModel();
  private controller?: WorkOrderMetaListController;

  private initController(): WorkOrderMetaListController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new WorkOrderMetaListController(
      new WorkOrderMetaListPresenter(this.vm),
      new WorkOrderService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }

  private openTab(url: string) {
    if (!!url) {
      window.open(url, "_blank");
    }
  }

  private openFileDialog(workOrder: WorkOrder) {
    this.vm.currentWorkOrder = workOrder;
    (this.$refs.fileInput as any).click();
  }

  private goToSendReportToLab() {
    this.$router.push({
      name: "mail-lab",
      params: { week: this.vm.week.selected }
    });
  }

  private goToSendReportToBh() {
    this.$router.push({
      name: "mail-bh",
      params: { week: this.vm.week.selected }
    });
  }
}
