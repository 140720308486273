
















import Vue from "vue";
import Component from "vue-class-component";
import { ValidationProvider } from "vee-validate";

@Component<ValidatedCombobox>({
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    value: {
      default() {
        return [];
      }
    },
    outlined: {
      type: Boolean,
      default: true
    },
    emitOnBlur: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  }
})
export default class ValidatedCombobox extends Vue {
  private innerValue?: string[];

  private created() {
    if (this.$props.value) {
      this.innerValue = this.$props.value;
    } else {
      this.innerValue = [];
    }
  }

  private blurEmit(event: any) {
    if (this.$props.emitOnBlur) {
      this.$emit("input", event.srcElement.value);
    }
  }
}
