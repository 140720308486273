






import Vue from "vue";
export default Vue.extend({
  name: "GoogleMap",
  inheritAttrs: false,
  mounted() {
    (this.$refs.mapElement as any).$mapPromise.then(() => {
      this.$emit("get-map", (this.$refs.mapElement as any).$mapObject);
    });
    (this as any).$gmapApiPromiseLazy().then(() => {
      this.$emit("get-google", google);
    });
  }
});
