import { StorageHandler } from "./StorageHandler";
import SystemKeys from "@/common/utils/SystemKeys";
import { FormResponse } from "@/forms/FormResponse";

export class ReportStorageHandler extends StorageHandler {
  public static getReportObjectFromReportId(
    reportId: string
  ): FormResponse<Report> {
    const response = {
      data: {} as Report,
      error: "",
      loading: true,
      success: true
    };
    if (!reportId) {
      response.error = "Anlagentyp hat noch keinen Prüfbericht zugeordnet!";
    }

    if (this.storage.has(SystemKeys.reportsTableKey)) {
      const rawReport = this.storage.findValue(
        SystemKeys.reportsTableKey,
        reportId
      );

      if (!!rawReport) {
        const report: Report = this.parseReport(rawReport);

        response.data = report;
      } else {
        response.error = "Could not find report with id: " + reportId;
        response.success = false;
      }
    } else {
      response.error =
        "Could not find table with name: " + SystemKeys.reportsTableKey;
      response.success = false;
    }

    response.loading = false;
    return response;
  }

  private static parseReport(rawReport: any): Report {
    const report: Report = {
      name: rawReport[SystemKeys.values][SystemKeys.reportsNameKey],
      sampleLocation: rawReport[SystemKeys.values][
        SystemKeys.reportsSampleLocationKey
      ]?.map((el: any) => el[SystemKeys.reportsNameKey]),
      sampleWeather: rawReport[SystemKeys.values][
        SystemKeys.reportsSampleWeatherKey
      ]?.map((el: any) => el[SystemKeys.reportsNameKey]),
      sampleIntake: rawReport[SystemKeys.values][
        SystemKeys.reportsSampleIntakeKey
      ]?.map((el: any) => el[SystemKeys.reportsNameKey]),
      suggestedActions: rawReport[SystemKeys.values][
        SystemKeys.reportsSampleSuggestedActionsKey
      ]?.map((el: any) => el[SystemKeys.reportsNameKey]),
      sections: []
    };
    if (
      !rawReport[SystemKeys.values] ||
      !rawReport[SystemKeys.values][SystemKeys.reportsSectionKey]
    ) {
      return report;
    }

    for (const rawSection of rawReport[SystemKeys.values][
      SystemKeys.reportsSectionKey
    ]) {
      report.sections.push(this.parseSection(rawSection));
    }

    return report;
  }

  private static parseSection(rawSection: any): Section {
    const section: Section = {
      name: rawSection[SystemKeys.reportsSectionNameKey],
      checkAvailability: rawSection[SystemKeys.reportsSectionEnabledKey],
      fields: []
    };

    for (const rawField of rawSection[SystemKeys.reportsFieldKey]) {
      section.fields.push(this.parseField(rawField));
    }

    return section;
  }

  private static parseField(rawField: any): Field {
    const field: Field = {
      name: rawField[SystemKeys.reportsFieldNameKey],
      prefix: rawField[SystemKeys.reportsFieldPrefixKey],
      suffix: rawField[SystemKeys.reportsFieldSuffixKey],
      checkAvailability: rawField[SystemKeys.reportsFieldEnabledKey],
      type: rawField[SystemKeys.reportsFieldTypeKey],
      options: []
    };

    for (const rawOption of rawField[SystemKeys.reportsOptionKey]) {
      field.options.push(this.parseOption(rawOption));
    }

    return field;
  }

  private static parseOption(rawOption: any): Option {
    return {
      name: rawOption[SystemKeys.reportsOptionNameKey]
    };
  }
}

export interface Report {
  name: string;
  sampleLocation: string[];
  sampleWeather: string[];
  sampleIntake: string[];
  suggestedActions: string[];
  sections: Section[];
}

interface Section {
  name: string;
  checkAvailability: boolean;
  fields: Field[];
}

interface Field {
  name: string;
  prefix: string;
  suffix: string;
  checkAvailability: boolean;
  type: string;
  options: Option[];
}

interface Option {
  name: string;
}
