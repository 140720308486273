export interface GraphQLConnection {
  query(
    name: string,
    params: object,
    fields: FieldValue[]
  ): Promise<GraphQLResponse>;

  mutation(
    name: string,
    params: object,
    fields?: FieldValue[],
    file?: File
  ): Promise<GraphQLResponse>;

  queryPaginated(
    name: string,
    count: number,
    page: number,
    fields: FieldValue[],
    search: string,
    searchFields: string[],
    orderBy?: OrderByClause[],
    params?: object
  ): Promise<PaginatedGraphQLResponse>;
}

export type FieldValue = string | FieldList;
export interface FieldList {
  name: string;
  fragment?: string;
  fields: FieldValue[];
}

export interface OrderByClause {
  field: string;
  order: SortOrder;
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC"
}

export function isFieldList(val: any): val is FieldList {
  return val.name !== undefined;
}

export interface GraphQLResponse {
  data: any;
  errors: any;
}

export interface PaginatedGraphQLResponse extends GraphQLResponse {
  count: number;
}
