import { StorageHandler } from "./StorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import SystemKeys from "@/common/utils/SystemKeys";

export class SalesPartnerStorageHandler extends StorageHandler {
  public static getAllSalesPartners(): SelectionItem[] {
    return super.buildTopLevelSelection(
      SystemKeys.salesPartnerTableKey,
      SystemKeys.salesPartnerNameKey
    );
  }
}
