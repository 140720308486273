




















































import Vue from "vue";
import Component from "vue-class-component";

import MarkerClusterer from "@googlemaps/markerclustererplus";
import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";
import { PlantGroupStorageHandler } from "@/storage/storageHandlers/PlantGroupStorageHandler";

@Component<AppointmentMapMarker>({
  props: ["appointment", "marker", "map", "google", "oms", "clusterer"]
})
export default class AppointmentMapMarker extends Vue {
  public clusterer!: MarkerClusterer;
  public oms!: OverlappingMarkerSpiderfier;
  public marker!: any;

  public hidden = true;

  public get infoWindowContent() {
    return this.$props.appointment.infoWindowContent;
  }

  public get plantGroup() {
    return PlantGroupStorageHandler.getGroupNameFromId(
      this.infoWindowContent.plantGroup
    );
  }

  public get plantType() {
    return PlantGroupStorageHandler.getTypeNameFromId(
      this.infoWindowContent.plantType
    );
  }

  public mounted() {
    const infoWindow = new this.$props.google.maps.InfoWindow({
      content: this.$el
    });

    this.marker.addListener("click", () => this.openInfoWindow(infoWindow));

    this.openInfoWindow(infoWindow);
  }

  private openInfoWindow(infoWindow: any) {
    this.hidden = false;
    infoWindow.open(this.$props.map, this.marker);
  }
}
