
























































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";

import RequestButton from "@/components/basic/RequestButton.vue";

import { CreateStoreViewModel } from "@/store/vms/CreateStoreViewModel";
import { CreateStorePresenter } from "@/store/presenters/CreateStorePresenter";
import { CreateStoreController } from "@/store/controllers/CreateStoreController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { EmployeeGraphQLService } from "../services/EmployeeGraphQLService";
import { StoreService } from "../services/StoreService";

@Component<CreateStoreView>({
  components: {
    ValidationObserver,
    Card,
    Container,
    Column,
    Row,
    ValidatedTextfield,
    ValidatedSelect,
    RequestButton
  },
  watch: {
    "vm.visibleView"(newValue) {
      if (!newValue) {
        setTimeout(() => {
          this.$destroy();
          if (this.$router.currentRoute.name === "store-create") {
            this.$nextTick(() => this.$router.back());
          }
        }, 500);
      }
    }
  }
})
export default class CreateStoreView extends Vue {
  private vm = new CreateStoreViewModel();
  private controller?: CreateStoreController;

  private formStep = 1;

  private initController(): CreateStoreController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreateStoreController(
      new CreateStorePresenter(this.vm),
      new StoreService(connection),
      new EmployeeGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$route.params.storeId);
  }
}
