import { render, staticRenderFns } from "./VpiCalculationView.vue?vue&type=template&id=f06a9eac&scoped=true&"
import script from "./VpiCalculationView.vue?vue&type=script&lang=ts&"
export * from "./VpiCalculationView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f06a9eac",
  null
  
)

export default component.exports