import { Selection } from "@/forms/ViewModelFormTypes";

export class OperatorDataTabViewModel {
  public name = "";
  public firstName = "";
  public lastName = "";
  public company = "";
  public phoneNumber = "";

  public operatorAddress = "";
  public operatorAddressStreet = "";
  public operatorAddressZip = "";
  public operatorAddressCity = "";
  public operatorAddressCountry: Selection = {
    selected: "",
    error: "",
    items: []
  };
  public operatorAddressState: Selection = {
    selected: "",
    error: "",
    items: []
  };

  public requestedBy = "";

  public offerRequested = false;
  public billing: Selection = {
    selected: "",
    error: "",
    items: [
      { text: "laut Angebot Vavpic Umweltservice", value: "offer" },
      { text: "laut Arbeitsaufwand vor Ort", value: "hours" }
    ]
  };
  public kilometers = 0;
}
