<template>
  <div class="onExpandTable" :style="cssProperties">
    <v-data-table
      hide-default-footer
      :dense="dense"
      :hide-default-header="!!titleInsteadHeaders"
      :headers="headers"
      :items="plants"
      :mobile-breakpoint="0"
      :show-select="showSelect"
      :show-expand="showExpand"
      :value="plants.filter(el => selected.some(sel => sel === el.id))"
      @item-selected="itemSelected($event)"
      @item-expanded="expanded($event)"
      @click:row="rowClicked($event)"
      @toggle-select-all="allSelected($event)"
    >
      <template v-if="!!titleInsteadHeaders" v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th :colspan="headers.length">{{ titleInsteadHeaders }}</th>
          </tr>
        </thead>
      </template>
      <template v-slot:item.address="{ item }">
        {{ item.street }}, {{ item.zip }} {{ item.city }} -
        {{ parseCountry(item.country) }}
      </template>
      <template v-slot:item.extra="{ item }">{{ parseGroupAndType(item.extra) }}</template>
      <template v-slot:expanded-item="{ item, headers }">
        <td></td>
        <td :colspan="headers.length - 1">
          <OperatorSearch
            class="elevation-1"
            titleInsteadHeaders="Betreiber der Anlage"
            :dense="true"
            :hideHeaders="true"
            :headers="headers"
            :operators="expandedItems.filter(el => el.otherId === item.id)"
            @row-clicked="subRowClicked($event)"
          ></OperatorSearch>
        </td>
      </template>
    </v-data-table>
    <Pagination
      v-if="!!plantsPage"
      :value="plantsPage"
      :itemsPerPage="plantsPerPage"
      :totalItems="totalPlants"
      @input="pageSwitched($event)"
    ></Pagination>
  </div>
</template>

<script>
import Pagination from "@/components/basic/Pagination.vue";
import { PlantGroupStorageHandler } from "../../storage/storageHandlers/PlantGroupStorageHandler";
import { CountriesStorageHandler } from "../../storage/storageHandlers/CountriesStorageHandler";

export default {
  name: "PlantSearch",
  components: {
    Pagination,
    OperatorSearch: () => import("@/components/layout/OperatorSearch.vue")
  },
  props: {
    plantsPage: {
      type: Number
    },
    plantsPerPage: {
      type: Number
    },
    totalPlants: {
      type: Number
    },
    plants: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    titleInsteadHeaders: {
      type: String,
      default: ""
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    showExpand: {
      type: Boolean,
      default: false
    },
    expandedItems: {
      type: Array,
      default() {
        return [];
      }
    },
    dense: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    pageSwitched(event) {
      this.$emit("page-switched", event);
    },
    itemSelected(event) {
      if (this.showSelect) {
        this.$emit("item-selected", event);
      }
    },
    allSelected(event) {
      if (this.showSelect) {
        this.$emit("all-selected", event.items);
      }
    },
    expanded(event) {
      if (event.value) {
        this.$emit("expanded", event.item.id);
      }
    },
    rowClicked(event) {
      this.$emit("row-clicked", event);
    },
    subRowClicked(event) {
      this.$emit("sub-row-clicked", event);
    },
    parseCountry(countryId) {
      return CountriesStorageHandler.getCountryNameFromId(countryId);
    },
    parseGroupAndType(extra) {
      const splitExtra = extra.split(" - ");
      return `${PlantGroupStorageHandler.getGroupNameFromId(
        splitExtra[0]
      )} - ${PlantGroupStorageHandler.getTypeNameFromId(splitExtra[1])}`;
    }
  },
  computed: {
    cssProperties() {
      return {
        "--primary-color": this.$vuetify.theme.currentTheme.primary,
        "--primary-color-light": this.$vuetify.theme.currentTheme.primary + "90"
      };
    }
  }
};
</script>

<style scoped>
.onExpandTable >>> .v-data-table__expanded.v-data-table__expanded__row {
  background-color: var(--primary-color-light) !important;
  color: white;
}
.onExpandTable >>> .v-data-table__expanded.v-data-table__expanded__content {
  background-color: var(--primary-color-light) !important;
}
</style>
