














































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import Select from "@/components/form/Select.vue";
import DatePicker from "@/components/form/DatePicker.vue";

import TimeTrackingCard from "./TimeTrackingCard.vue";

import { TimeTrackingViewModel } from "@/employee/vms/TimeTrackingViewModel";
import { TimeTrackingPresenter } from "@/employee/presenters/TimeTrackingPresenter";
import { TimeTrackingController } from "@/employee/controllers/TimeTrackingController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { EmployeeGraphQLService } from "../services/EmployeeService";
import { TimeTrackingDayGraphQLService } from "../services/TimeTrackingDayService";

@Component<TimeTrackingView>({
  components: {
    Page,
    Card,
    Container,
    Column,
    Row,
    Select,
    DatePicker,

    TimeTrackingCard
  }
})
export default class TimeTrackingView extends Vue {
  private vm = new TimeTrackingViewModel();
  private controller?: TimeTrackingController;

  private initController(): TimeTrackingController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new TimeTrackingController(
      new TimeTrackingPresenter(this.vm),
      new EmployeeGraphQLService(connection),
      new TimeTrackingDayGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }
}
