import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { AppointmentForm } from "@/forms/appointment/AppointmentForm";
import { IAppointmentService } from "../services/AppointmentGraphQLService";
import { FormResponse } from "@/forms/FormResponse";

export class CreateLooseAppointmentController {
  public constructor(
    private presenter: ICreateLooseAppointmentPresenter,
    private services: IAppointmentService
  ) {}

  public mounted() {
    this.presenter.visibleView = true;
  }

  public closeButtonClicked() {
    this.presenter.visibleView = false;
  }

  public completeAddressStepButtonClicked(): void {
    this.presenter.addressStepCompleted = true;
  }

  public operatorAddressCountrySelected(country: string) {
    this.presenter.operatorAddressCountrySelected(country);
  }

  public enableDifferentAddress(enabled: boolean) {
    this.presenter.differentAddress = enabled;
  }

  public countrySelected(country: string) {
    this.presenter.countrySelected(country);
  }

  public dateChanged(date: string) {
    this.presenter.appointmentForm.setFieldValue("date", date);
  }

  public reasonTextChanged(reason: string) {
    this.presenter.appointmentForm.setFieldValue("reason", reason);
  }

  public durationChanged(duration: string) {
    this.presenter.appointmentForm.setFieldValue("duration", duration);
  }

  public sampleNeededChanged(sampleNeeded: boolean) {
    this.presenter.appointmentForm.setFieldValue(
      "sampleNeeded",
      sampleNeeded ? "true" : "false"
    );
  }

  public closeOtherStepButtonClicked() {
    this.presenter.addressStepCompleted = false;
  }

  public createAppointmentButtonClicked() {
    const request = this.services.createLooseAppointment(
      this.presenter.appointmentProps
    );
    FormRequestHandler.handle(
      request,
      response => (this.presenter.createAppointmentResponse = response),
      "create-plant-failed"
    );
  }
}

export interface ICreateLooseAppointmentPresenter {
  visibleView: boolean;
  addressStepCompleted: boolean;
  differentAddress: boolean;

  appointmentForm: AppointmentForm;

  appointmentProps: any;
  createAppointmentResponse: FormResponse<string>;

  countrySelected(value: string): void;
  operatorAddressCountrySelected(value: string): void;
}
