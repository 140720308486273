import { SelectionItem } from "@/forms/ViewModelFormTypes";

export class CreateStoreViewModel {
  public visibleView = false;

  public name = "";
  public description = "";
  public employee: { selected: string; items: SelectionItem[] } = {
    selected: "",
    items: []
  };

  public loading = false;
  public error = "";
}
