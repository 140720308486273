export interface Section {
  name: string;
  checkAvailability: boolean;
  enabled: boolean;
  fields: { [key: string]: Field };
}

export interface Field {
  suffix: string;
  prefix: string;
  component: string;
  multiple: boolean;
  name: string;
  checkAvailability: boolean;
  enabled: boolean;
  value?: any;
  items?: Array<{ text: string; value: any }>;
}

export class ReportAccordionViewModel {
  public sections: Section[] = [];
}
