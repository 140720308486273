import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Page } from "@/datastructures/Page";
import { ISearchService, SearchResult } from "../services/SearchService";

export class SearchController {
  private secondstoWait = 1;
  private countDown = 0;
  // tslint:disable-next-line: no-empty
  private endOfInputTimer: NodeJS.Timeout = setTimeout(() => {}, 0);

  public constructor(
    private presenter: ISearchPresenter,
    private searchService: ISearchService
  ) {}

  public init(searchText: string) {
    this.presenter.searchText = searchText;
    this.pageSwitched(1);
  }

  public retrySearch() {
    this.pageSwitched(this.presenter.pageNumber);
  }

  public pageSwitched(pageNumber: number) {
    this.presenter.pageNumber = pageNumber;
    this.search(this.presenter.itemsPerPage, pageNumber);
  }

  public search(itemsPerPage: number, pageNumber: number) {
    const searchText = this.presenter.searchText;
    const page = new Page(itemsPerPage, pageNumber);
    const request = this.searchService.search(searchText, page);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.searchResponse = response),
      "search-failed"
    );
  }

  public changedFilterString(
    newValue: string) {
    if (/^[0-9]+$/.test(newValue) || newValue.length >= 3 || newValue.length === 0) {
      clearInterval(this.endOfInputTimer);
      this.countDown = this.secondstoWait;
      this.endOfInputTimer = setInterval(() => {
        this.countDown--;
        if (this.countDown <= 0) {
          clearInterval(this.endOfInputTimer);
          this.search(this.presenter.itemsPerPage, this.presenter.pageNumber);
        }
      }, 1000);
    }
  }

  public findOther(id: string) {
    const request = this.searchService.findOther(id);

    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading && !response.error) {
          response.data.forEach((el: any) => (el.otherId = id));
          this.presenter.searchOtherResponse = response;
        }
      },
      "search-failed"
    );
  }
}

export interface ISearchPresenter {
  searchText: string;
  itemsPerPage: number;
  pageNumber: number;

  searchResponse: FormResponse<PaginatedList<SearchResult>>;
  searchOtherResponse: FormResponse<SearchResult[]>;
}
