import { Supplier } from "../services/SupplierGraphQLService";

export class SupplierViewModel {
  public itemsPerPage = 25;
  public headers = [
    { text: "ID", value: "index" },
    { text: "Lieferant", value: "name" },
    { text: "Adresse", value: "address" },
    { text: "Standardtelefonnummer", value: "standardPhoneNumber" },
    { text: "", value: "actions", align: "right" }
  ];
  public items: Supplier[] = [];
  public totalItems = 0;
  public options = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: this.itemsPerPage,
    multiSort: true,
    mustSort: false,
    sortBy: ["index"],
    sortDesc: [false]
  };
  public loading = true;
  public pageNumber = 1;
  public search = "";
}
