import { DefectData } from "../vms/DefectsTableViewModel";
import { Selection } from "@/forms/ViewModelFormTypes";

export class DefectsTableController {
  private itemIndex = 0;

  public constructor(private presenter: IDefectsTablePresenter) {}

  public mounted(items: any[]) {
    this.presenter.defects = items;
    this.itemIndex =
      Math.max(...this.presenter.defects.map(el => el.id), 0) + 1;
  }

  public changeDefect(value: any) {
    if (!value) {
      return;
    }
    if (!value.text) {
      this.presenter.newDefect.selected = value;
    } else {
      this.presenter.newDefect.selected = value.text;
    }
  }

  public addDefect(key: any) {
    if (
      !!this.presenter.newDefect.selected &&
      this.presenter.newDefect.selected.length > 0
    ) {
      this.presenter.defects.push(this.createDefect());
      this.presenter.newDefect.selected = "";
    }
  }

  public createDefect() {
    return {
      message: this.presenter.newDefect.selected,
      isComment: false,
      id: this.itemIndex++
    };
  }

  public delete(id: number) {
    const index = this.presenter.defects.findIndex(el => el.id === id);
    if (index >= 0) {
      this.presenter.defects.splice(index, 1);
    }
  }
}

export interface IDefectsTablePresenter {
  defects: DefectData[];
  newDefect: Selection;
}
