import { ISearchPresenter } from "../controllers/SearchController";
import { SearchViewModel } from "../vms/SearchViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { SearchResult } from "../services/SearchService";

export class SearchPresenter implements ISearchPresenter {
  public constructor(private vm: SearchViewModel) {}

  public get itemsPerPage() {
    return this.vm.itemsPerPage;
  }

  public get pageNumber() {
    return this.vm.pageNumber;
  }

  public set pageNumber(page: number) {
    this.vm.pageNumber = page;
  }

  public get searchText() {
    return this.vm.searchText;
  }

  public set searchText(searchText: string) {
    this.vm.searchText = searchText;
  }

  public set searchResponse(
    response: FormResponse<PaginatedList<SearchResult>>
  ) {
    this.vm.searchRequest.loading = response.loading;
    this.vm.searchRequest.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.items = response.data.items;
      this.vm.totalItems = response.data.totalCount;
    }
  }

  public set searchOtherResponse(
    response: FormResponse<any[]>
  ) {
    if (!response.loading && !response.error && !!response.data.length) {
      const otherId = response.data[0].otherId;
      this.vm.otherItems = this.vm.otherItems.filter((el: any) => el.otherId !== otherId);
      this.vm.otherItems.push(...response.data);
    }
  }
}
