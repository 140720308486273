


































































































































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Card from "@/components/layout/Card.vue";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import Dialog from "@/components/layout/Dialog.vue";
import IconButton from "@/components/basic/IconButton.vue";
import RightSideBar from "@/components/layout/RightSideBar.vue";
import WeeklyScheduleRightMenu from "./WeeklyScheduleRightMenu.vue";

import Schedule from "@/common/views/Schedule.vue";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosGoogleMapsConnection } from "@/gateways/AxiosGoogleMapsConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";

import { WeeklyScheduleController } from "@/tourPlanner/controllers/WeeklyScheduleController";
import { WeeklySchedulePresenter } from "@/tourPlanner/presenters/WeeklySchedulePresenter";
import { WeeklyScheduleViewModel } from "@/tourPlanner/vms/WeeklyScheduleViewModel";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";

import { AppointmentGraphQLService } from "../services/AppointmentGraphQLService";
import { TourPlannerGraphQLService } from "../services/TourPlannerGraphQLService";
import { ServiceEngineerGraphQLService } from "../services/ServiceEngineerGraphQLService";
import {
  TourWeekGraphQLService,
  TourDay
} from "../services/TourWeekGraphQLService";
import { DateUtils } from "../../utils/DateUtils";

@Component<WeeklySchedule>({
  components: {
    Container,
    Column,
    Row,
    Page,
    Card,
    IconButton,
    RightSideBar,
    WeeklyScheduleRightMenu,
    Schedule,
    Dialog,
    TourDayMap: () => import("@/tourPlanner/views/TourDayMap.vue"),
    CreateLooseAppointmentView: () =>
      import("@/tourPlanner/views/CreateLooseAppointmentView.vue")
  },
  props: {
    serviceEngineerId: {
      type: String,
      required: true
    },
    weekDate: {
      type: String,
      required: true
    },
    rightSideBarShown: {
      type: Boolean
    },
    filters: {
      type: String
    }
  },
  watch: {
    "vm.goBack"() {
      this.vm.visibleView = false;
      this.$router.back();
    },
    "vm.tourWeekFinished"(finished: boolean) {
      if (finished) {
        this.$router.push({ name: "planning-overview" });
      }
    },
    "vm.tourWeekNotValidated"(finished: boolean) {
      if (finished) {
        this.$router.push({ name: "planning-overview" });
      }
    }
  }
})
export default class WeeklySchedule extends Vue {
  private vm = new WeeklyScheduleViewModel();
  private serviceEngineerId!: string;
  private weekDate!: string;
  private viewedTourDay?: any = null;

  private get controller(): WeeklyScheduleController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new WeeklyScheduleController(
      new WeeklySchedulePresenter(this.vm),
      new AppointmentGraphQLService(connection),
      new TourWeekGraphQLService(connection),
      new ServiceEngineerGraphQLService(connection)
    );
  }

  private get cssProperties() {
    return {
      "--primary-color": this.$vuetify.theme.currentTheme.primary
    };
  }

  private get showSidebar() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  private get sidebarShown() {
    return this.$props.rightSideBarShown;
  }

  private set sidebarShown(newValue: boolean) {
    this.$emit("sidebar-shown-changed", newValue);
  }

  public async mounted() {
    await this.controller.mounted(this.serviceEngineerId, this.weekDate);
  }

  private propsChanged() {
    this.vm.showServiceEngineerOverwriteDialog = false;
    this.$router.push({
      name: "weekly-schedule",
      params: {
        serviceEngineerId: this.vm.serviceEngineersViewList.selected,
        weekDate: DateUtils.dateOnly(this.vm.timeFrameItems.selected)
      }
    });
  }

  private switchToServiceEngineerList() {
    this.$router.push({
      name: "planning-overview"
    });
  }

  private backToMap() {
    this.$router.push({
      name: "appointment-map",
      params: {
        serviceEngineerId: this.vm.serviceEngineersViewList.selected,
        weekDate: DateUtils.dateOnly(this.vm.timeFrameItems.selected),
        filters: this.$props.filters
      }
    });
  }

  private openTourDayMap(tourDayIndex: number) {
    this.viewedTourDay = this.vm.tourWeek.tourDays[tourDayIndex];
  }
}
