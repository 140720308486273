
























































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";
import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";

import RequestButton from "@/components/basic/RequestButton.vue";

import { CreateMaterialRequestViewModel } from "@/store/vms/CreateMaterialRequestViewModel";
import { CreateMaterialRequestPresenter } from "@/store/presenters/CreateMaterialRequestPresenter";
import { CreateMaterialRequestController } from "@/store/controllers/CreateMaterialRequestController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { MaterialRequestGraphQLService } from "../services/MaterialRequestGraphQLService";
import { ItemTemplateService } from "../services/ItemTemplateService";

@Component<CreateMaterialRequestView>({
  components: {
    ValidationObserver,
    Card,
    Container,
    Column,
    Row,
    ValidatedSelect,
    ValidatedTextfield,
    RequestButton
  },
  watch: {
    "vm.visibleView"(newValue) {
      if (!newValue) {
        setTimeout(() => {
          this.$destroy();
          if (this.$router.currentRoute.name === "material-request") {
            this.$nextTick(() => this.$router.back());
          }
        }, 500);
      }
    }
  }
})
export default class CreateMaterialRequestView extends Vue {
  private vm = new CreateMaterialRequestViewModel();
  private controller?: CreateMaterialRequestController;

  private initController(): CreateMaterialRequestController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreateMaterialRequestController(
      new CreateMaterialRequestPresenter(this.vm),
      new MaterialRequestGraphQLService(connection),
      new ItemTemplateService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$route.params.materialRequestId);
  }
}
