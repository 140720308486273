import { FormResponse } from "@/forms/FormResponse";
import { ICreateStoreItemPresenter } from "../controllers/CreateStoreItemController";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { Store } from "../services/StoreGraphQLService";
import { StoreItem } from "../services/StoreItemGraphQLService";
import { CreateStoreItemViewModel } from "../vms/CreateStoreItemViewModel";

export class CreateStoreItemPresenter implements ICreateStoreItemPresenter {
  public constructor(private vm: CreateStoreItemViewModel) {}

  public set visibleView(visibleView: boolean) {
    this.vm.visibleView = visibleView;
  }

  public set getStoresResponse(response: FormResponse<Store[]>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.store.items = response.data.map(s => ({
        text: s.name,
        value: s.id
      }));
    }
  }

  public set getItemTemplatesResponse(response: FormResponse<ItemTemplate[]>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.itemTemplate.items = response.data.map(s => ({
        text: s.name,
        value: s.id
      }));
    }
  }

  public set getStoreItemResponse(response: FormResponse<StoreItem>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.itemTemplate.selected = response.data.itemTemplateId;
      this.vm.store.selected = response.data.storeId;
      this.vm.minimalAmount = response.data.minimalAmount;
    }
  }

  public get data(): any {
    return {
      itemTemplateId: this.vm.itemTemplate.selected,
      storeId: this.vm.store.selected,
      minimalAmount: parseInt(this.vm.minimalAmount, 10) || undefined
    };
  }

  public set createStoreItemResponse(response: FormResponse<string>) {
    this.vm.loading = response.loading;
    this.vm.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.visibleView = false;
    }
  }
}
