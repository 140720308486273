























































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Caption from "@/components/layout/Caption.vue";

import Textfield from "@/components/form/Textfield.vue";
import Select from "@/components/form/Select.vue";
import DatePicker from "@/components/form/DatePicker.vue";

import RequestButton from "@/components/basic/RequestButton.vue";
import IconButton from "@/components/basic/IconButton.vue";
import SignaturePad from "@/components/basic/SignaturePad.vue";

import { EmployeeDetailController } from "@/employee/controllers/EmployeeDetailController";
import { EmployeeDetailPresenter } from "@/employee/presenters/EmployeeDetailPresenter";
import { EmployeeDetailViewModel } from "@/employee/vms/EmployeeDetailViewModel";
import { GraphQLUserRoleService } from "../services/UserRoleService";
import { GraphQLPermissionService } from "../services/PermissionService";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { LocalStorage } from "../../storage/LocalStorage";
import { EmployeeGraphQLService } from "../services/EmployeeService";

@Component<EmployeeDetailView>({
  components: {
    Column,
    Row,
    Grid,
    Page,
    Card,
    Section,
    Caption,
    Textfield,
    Select,
    DatePicker,
    RequestButton,
    IconButton,
    SignaturePad
  },
  watch: {
    "vm.showEmployeeList"(show: boolean) {
      if (show) {
        this.$router.push({ name: "employees" });
      }
    }
  }
})
export default class EmployeeDetailView extends Vue {
  private vm = new EmployeeDetailViewModel();
  private controller: any;

  private get id() {
    return this.$route.params.id;
  }

  private created() {
    this.controller = this.createController();
  }

  private mounted() {
    this.controller.init(this.id);
  }

  private createController(): EmployeeDetailController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new EmployeeDetailController(
      new EmployeeDetailPresenter(this.vm, new LocalStorage()),
      new EmployeeGraphQLService(connection),
      new GraphQLUserRoleService(connection)
    );
  }
}
