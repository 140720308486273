import { CachedService } from "@/common/services/CachedService";
import { ServiceArgument } from "@/common/services/ServiceFacade";
import { OnlineCheckerFactory } from "@/common/utils/OnlineCheckerFactory";
import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import {
  GraphQLConnection,
  OrderByClause
} from "@/gateways/graphql/GraphQLConnection";
import { StoreItem, StoreItemGraphQLService } from "./StoreItemGraphQLService";
import { StoreItemLocalService } from "./StoreItemLocalService";

export class StoreItemService implements IStoreItemService, CachedService {
  private onlineService: StoreItemGraphQLService;
  private offlineService: StoreItemLocalService;
  private service: IStoreItemService;

  constructor(connection: GraphQLConnection) {
    this.offlineService = new StoreItemLocalService();
    this.onlineService = new StoreItemGraphQLService(connection);

    if (OnlineCheckerFactory.isOnline) {
      this.service = this.onlineService;
    } else {
      this.service = this.offlineService;
    }
  }

  public get timestamp() {
    return this.offlineService.timestamp;
  }

  public async getAllStoreItemsPaginated(
    page: Page,
    order: OrderByClause[],
    search: string,
    storeFilter: string[],
    itemTemplateFilter: string[]
  ): Promise<PaginatedList<StoreItem>> {
    this.updateOnlineState();
    return this.service.getAllStoreItemsPaginated(
      page,
      order,
      search,
      storeFilter,
      itemTemplateFilter
    );
  }

  public async getStoreItems(itemIds: string[]): Promise<StoreItem[]> {
    this.updateOnlineState();
    return this.service.getStoreItems(itemIds);
  }

  public async getAllStoreItemsByEmployee(
    employeeId: string
  ): Promise<StoreItem[]> {
    this.updateOnlineState();
    return this.service.getAllStoreItemsByEmployee(employeeId);
  }

  public async getAllStoreItemsByStore(storeId: string): Promise<StoreItem[]> {
    this.updateOnlineState();
    return this.service.getAllStoreItemsByStore(storeId);
  }

  public async getAllStoreItemsByPlant(plantId: string): Promise<StoreItem[]> {
    this.updateOnlineState();
    return this.service.getAllStoreItemsByPlant(plantId);
  }

  public async getStoreItemAmount(
    storeId: string,
    itemTemplateId: string
  ): Promise<string> {
    this.updateOnlineState();
    return this.service.getStoreItemAmount(storeId, itemTemplateId);
  }

  public async getStoreItemById(id: string): Promise<StoreItem> {
    this.updateOnlineState();
    return this.service.getStoreItemById(id);
  }

  public async updateStoreItem(input: any): Promise<string> {
    this.updateOnlineState();
    return this.service.updateStoreItem(input);
  }

  public async createStoreItem(input: any): Promise<string> {
    this.updateOnlineState();
    return this.service.createStoreItem(input);
  }

  public async deleteStoreItem(id: string): Promise<string> {
    this.updateOnlineState();
    return this.service.deleteStoreItem(id);
  }

  public async exportStoreItems(): Promise<any> {
    this.updateOnlineState();
    return this.service.exportStoreItems();
  }

  public async download(arg?: ServiceArgument): Promise<void> {
    let storeItems: StoreItem[] = [];
    if (!arg || !arg.ids) {
      storeItems = await this.onlineService.getAllStoreItems();
    } else {
      const promises = [];
      for (const id of arg.ids) {
        promises.push(
          this.onlineService
            .getStoreItemById(id)
            .then(result => storeItems.push(result))
        );
      }

      await Promise.all(promises);
    }

    this.offlineService.storeItems = storeItems;
  }

  public upload() {
    for (const storeItemFlaggedEntity of this.offlineService.getToSyncStoreItems()) {
      const storeItem = storeItemFlaggedEntity.entity;
      this.onlineService.updateStoreItem({
        id: storeItem.id,
        amount: storeItem.amount
      });
      storeItemFlaggedEntity.changed = false;
    }
    this.offlineService.save();
  }

  private updateOnlineState() {
    if (OnlineCheckerFactory.isOnline) {
      this.service = this.onlineService;
    } else {
      this.service = this.offlineService;
    }
  }
}

export interface IStoreItemService {
  getAllStoreItemsPaginated(
    page: Page,
    order: OrderByClause[],
    search: string,
    storeFilter: string[],
    itemTemplateFilter: string[]
  ): Promise<PaginatedList<StoreItem>>;
  getStoreItems(itemIds: string[]): Promise<StoreItem[]>;
  getAllStoreItemsByEmployee(employeeId: string): Promise<StoreItem[]>;
  getAllStoreItemsByStore(storeId: string): Promise<StoreItem[]>;
  getAllStoreItemsByPlant(plantId: string): Promise<StoreItem[]>;
  getStoreItemAmount(storeId: string, storeItemId: string): Promise<string>;
  getStoreItemById(id: string): Promise<StoreItem>;
  updateStoreItem(input: any): Promise<string>;
  createStoreItem(input: any): Promise<string>;
  deleteStoreItem(id: string): Promise<string>;
  exportStoreItems(): Promise<any>;
}
