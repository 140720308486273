import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { ItemTemplateService } from "../services/ItemTemplateService";
import {
  StockTaking,
  StockTakingGraphQLService
} from "../services/StockTakingGraphQLService";
import { Store } from "../services/StoreGraphQLService";
import { StoreItem } from "../services/StoreItemGraphQLService";
import { StoreItemService } from "../services/StoreItemService";
import { StoreService } from "../services/StoreService";

export class CreateStockTakingController {
  public constructor(
    private presenter: ICreateStockTakingPresenter,
    private stockTakingService: StockTakingGraphQLService,
    private storeService: StoreService,
    private storeItemService: StoreItemService,
    private itemTemplateService: ItemTemplateService
  ) {}

  public mounted(stockTakingId?: string) {
    this.presenter.readonly = !!stockTakingId;

    FormRequestHandler.handle(
      this.itemTemplateService.getAllItemTemplates(),
      response => (this.presenter.getItemTemplatesResponse = response),
      "Konnte Artikel nicht laden!"
    );

    if (stockTakingId) {
      this.presenter.stockTakingId = stockTakingId;
      FormRequestHandler.handle(
        this.stockTakingService.getStockTakingById(stockTakingId),
        response => {
          this.presenter.getStockTakingResponse = response;

          if (!response.loading && !response.error) {
            this.presenter.visibleView = true;
          }
        },
        "Konnte Inventur nicht laden!"
      );
    } else {
      FormRequestHandler.handle(
        this.storeService.getAllStores(),
        response => (this.presenter.getStoresResponse = response),
        "Konnte Lagerorte nicht laden!"
      );

      this.presenter.visibleView = true;
    }
  }

  public closeOverlay() {
    this.presenter.visibleView = false;
  }

  public storeSelected(storeId: string) {
    if (!this.presenter.readonly) {
      FormRequestHandler.handle(
        this.storeItemService.getAllStoreItemsByStore(storeId),
        response => (this.presenter.getStoreItemsResponse = response),
        "Konnte Artikel nicht laden!"
      );
    }
  }

  public getTemplateName(id: string) {
    return this.presenter.itemTemplateDict[id];
  }

  public itemTemplateSelected(itemTemplateId: string) {
    this.presenter.itemTemplateSelected(itemTemplateId);
  }

  public createStockTakingButtonClicked() {
    FormRequestHandler.handle(
      this.stockTakingService.createStockTaking(this.presenter.data),
      response => (this.presenter.createStockTakingResponse = response),
      "Could not create stock taking!"
    );
  }

  public updateStockTakingButtonClicked() {
    FormRequestHandler.handle(
      this.stockTakingService.updateStockTaking(this.presenter.data),
      response => (this.presenter.createStockTakingResponse = response),
      "Could not update stock taking!"
    );
  }
}

export interface ICreateStockTakingPresenter {
  visibleView: boolean;
  readonly: boolean;

  itemTemplateDict: { [key: string]: string };
  stockTakingId: string;

  getStockTakingResponse: FormResponse<StockTaking>;
  getStoresResponse: FormResponse<Store[]>;
  getItemTemplatesResponse: FormResponse<ItemTemplate[]>;
  getStoreItemsResponse: FormResponse<StoreItem[]>;

  data: any;
  createStockTakingResponse: FormResponse<string>;

  itemTemplateSelected(itemTemplateId: string): void;
}
