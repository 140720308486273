


































































































































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import ChangeAppointmentPropsDialog from "@/tourPlanner/views/ChangeAppointmentPropsDialog.vue";
import CancelAppointmentDialog from "@/tourPlanner/views/CancelAppointmentDialog.vue";
import WorkOrderMap from "@/report/views/WorkOrderMap.vue";

import { DateUtils } from "@/utils/DateUtils";
import { CountriesStorageHandler } from "../../../storage/storageHandlers/CountriesStorageHandler";
import { EnvironmentUtils } from "@/utils/EnvironmentUtils";

@Component<ScheduleAppointmentCard>({
  components: {
    Card,
    Container,
    Column,
    Row,

    ChangeAppointmentPropsDialog,
    CancelAppointmentDialog,
    WorkOrderMap
  },
  props: {
    appointment: Object,
    showButtons: {
      type: Boolean,
      default: true
    },
    hover: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
    appointmentCommentEditable: {
      type: Boolean,
      default: true
    }
  }
})
export default class ScheduleAppointmentCard extends Vue {
  private components: any[] = [];
  private showInfo = false;

  private get isMobile() {
    return EnvironmentUtils.isMobile();
  }

  public parseAddress(appointment: any) {
    const country = CountriesStorageHandler.getCountryNameFromId(
      appointment.addressCountry
    );
    return `${appointment.addressStreet},\n${appointment.addressZip} ${appointment.addressCity},\n${country}`;
  }

  public formatDate(date: string) {
    return DateUtils.format(date);
  }

  public openAppointmentMap() {
    // this.components.push({
    //   key: this.$props.appointment.id,
    //   component: "WorkOrderMap"
    // });
  }

  public parseGroupType(appointment: any) {
    let toReturn = "";
    if (!!appointment.plant.group) {
      toReturn += appointment.plant.group;
    }

    if (!!appointment.plant.group && !!appointment.plant.type) {
      toReturn += " / ";
    }

    if (!!appointment.plant.type) {
      toReturn += appointment.plant.type;
    }

    return toReturn;
  }
}
