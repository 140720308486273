import { Auth } from "@/common/utils/Auth";
import { Permission } from "@/common/utils/Permissions";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { MaterialStorageHandler } from "@/storage/storageHandlers/MaterialStorageHandler";
import { TaskStorageHandler } from "@/storage/storageHandlers/TasksStorageHandler";
import { StoreItem } from "@/store/services/StoreItemGraphQLService";
import { StoreItemService } from "@/store/services/StoreItemService";
import { DateUtils } from "@/utils/DateUtils";
import { StringUtils } from "@/utils/StringUtils";

export class GeneralTabController {
  public constructor(
    private presenter: IGeneralTabPresenter,
    private storeItemService: StoreItemService
  ) {}

  public mounted(
    editEnabled: boolean,
    usedTime: number,
    material: { [key: string]: number },
    tasksDone: string[]
  ) {
    this.presenter.initialUsedMaterial = material;
    const materialItems = MaterialStorageHandler.getAllMaterials().map(el => ({
      text: el.text,
      value: el.text
    }));
    this.presenter.usedMaterialLocalStorage = materialItems;
    let getStoreItemsResponse: Promise<StoreItem[]>;
    if (editEnabled && !Auth.hasPermission(Permission.ApproveWorkOrders)) {
      getStoreItemsResponse = this.storeItemService.getAllStoreItemsByEmployee(
        Auth.userId
      );
    } else {
      getStoreItemsResponse = this.storeItemService.getStoreItems(
        Object.keys(material)
      );
    }
    FormRequestHandler.handle(
      getStoreItemsResponse,
      response => (this.presenter.getUsedMaterialResponse = response),
      "Konnte Materialien nicht laden!"
    );

    const taskItems = TaskStorageHandler.getAllTasks().map(el => ({
      text: el.text,
      value: el.text
    }));
    const taskSelected = [...tasksDone];
    const doneTasks = {
      items: taskItems,
      selected: taskSelected
    };
    this.presenter.doneTasks = { ...doneTasks };

    this.presenter.usedTime = {
      value: usedTime,
      text: DateUtils.timeToString(usedTime)
    };
  }

  public timerStopClicked(time: number) {
    this.presenter.usedTimeValueOnly = time / 1000 / 60;
  }

  public timeMinusClicked() {
    this.presenter.changeUsedTime(-30);
  }

  public timePlusClicked() {
    this.presenter.changeUsedTime(30);
  }

  public usedMaterialChanged(newUsedMaterials: any[]) {
    this.presenter.selectedUsedMaterial = newUsedMaterials.map(el =>
      el.value ? el : { text: el, value: el, amount: 1 }
    );
  }

  public get data(): {
    usedTime: number;
    doneTasks: string[];
    usedMaterial: string;
  } {
    const material: { [key: string]: number } = {};
    for (const mat of this.presenter.usedMaterial.selected) {
      material[mat.value] = mat.amount;
    }

    return {
      usedTime: this.presenter.usedTime.value,
      doneTasks: this.presenter.doneTasks.selected,
      usedMaterial: JSON.stringify(material)
    };
  }
}

export interface IGeneralTabPresenter {
  usedMaterial: {
    selected: Array<{ value: string; text: string; amount: number }>;
    items: SelectionItem[];
  };
  doneTasks: { selected: string[]; items: SelectionItem[] };
  usedTime: { value: number; text: string };
  usedTimeValueOnly: number;
  usedMaterialLocalStorage: SelectionItem[];
  getUsedMaterialResponse: FormResponse<StoreItem[]>;
  selectedUsedMaterial: Array<{ value: string; amount: number }>;
  initialUsedMaterial: { [key: string]: number };

  changeUsedTime(changedAmount: number): void;
}
