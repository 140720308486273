import { PaginatedList } from "@/datastructures/PaginatedList";
import { FormResponse } from "@/forms/FormResponse";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { StringUtils } from "@/utils/StringUtils";
import { IStoreItemsListPresenter } from "../controllers/StoreItemsListController";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { Store } from "../services/StoreGraphQLService";
import { StoreItem } from "../services/StoreItemGraphQLService";
import { StoreItemsListViewModel } from "../vms/StoreItemsListViewModel";

export class StoreItemsListPresenter implements IStoreItemsListPresenter {
  public mounting = true;

  public constructor(private vm: StoreItemsListViewModel) {}

  public set getStoresResponse(response: FormResponse<Store[]>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.storeFilter.items = response.data.map(el => ({
        text: el.name,
        value: el.id
      }));
    }
  }

  public set getItemTemplatesResponse(response: FormResponse<ItemTemplate[]>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.itemTemplateFilter.items = response.data.map(el => ({
        text: el.name,
        value: el.id
      }));
    }
  }

  public set tableRequest(response: FormResponse<PaginatedList<StoreItem>>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.items = response.data.items;
      this.vm.totalItems = response.data.totalCount;

      this.mounting = false;
    }
  }

  public get pageNumber() {
    return this.vm.pageNumber;
  }

  public set pageNumber(pageNumber: number) {
    this.vm.pageNumber = pageNumber;
  }

  public get itemsPerPage() {
    return this.vm.itemsPerPage;
  }

  public get options() {
    return this.vm.options;
  }

  public set options(options: any) {
    this.vm.options = options;
  }

  public get search() {
    return this.vm.search;
  }

  public set deleteRequest(response: FormResponse<string>) {
    this.vm.loading = response.loading;
  }

  public get storeFilter() {
    return this.vm.storeFilter;
  }

  public set storeFilter(storeFilter: {
    selected: string[];
    items: SelectionItem[];
  }) {
    this.vm.storeFilter = storeFilter;
  }

  public get itemTemplateFilter() {
    return this.vm.itemTemplateFilter;
  }

  public set itemTemplateFilter(itemTemplateFilter: {
    selected: string[];
    items: SelectionItem[];
  }) {
    this.vm.itemTemplateFilter = itemTemplateFilter;
  }
}
