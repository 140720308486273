





























































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Draggable from "vuedraggable";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ScheduleHeader from "@/common/views/schedule/ScheduleHeader.vue";
import ScheduleAppointmentCard from "@/common/views/schedule/ScheduleAppointmentCard.vue";
import ScheduleCard from "@/common/views/schedule/ScheduleCard.vue";

import { ScheduleViewModel } from "@/common/vms/ScheduleViewModel";
import { SchedulePresenter } from "@/common/presenters/SchedulePresenter";
import { ScheduleController } from "@/common/controllers/ScheduleController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { EnvironmentUtils } from "@/utils/EnvironmentUtils";

@Component<ScheduleView>({
  components: {
    Draggable,
    Card,
    Container,
    Column,
    Row,

    ScheduleHeader,
    ScheduleCard,
    ScheduleAppointmentCard,
    TourDayMap: () => import("@/tourPlanner/views/TourDayMap.vue")
  },
  props: {
    tourWeek: Object,
    showChangeTourDayPropsButton: {
      type: Boolean,
      default: true
    },
    draggable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    viewRouteMode: {
      type: Boolean,
      default: true
    },
    absolute: {
      type: Boolean,
      default: true
    },
    showAppointmentCardButtons: {
      type: Boolean,
      default: true
    },
    coloredCards: {
      type: Boolean,
      default: true
    },
    weekStartHour: {
      type: Number,
      default: 6
    },
    showStartHourLine: {
      type: Boolean,
      default: true
    },
    appointmentClickable: {
      type: Boolean,
      default: false
    },
    appointmentCommentEditable: {
      type: Boolean,
      default: true
    }
  }
})
export default class ScheduleView extends Vue {
  private vm = new ScheduleViewModel();
  private controller?: ScheduleController;
  private expandedAppointments: { [key: string]: boolean } = {};

  private get isMobile() {
    return EnvironmentUtils.isMobile();
  }

  private initController(): ScheduleController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new ScheduleController(new SchedulePresenter(this.vm));
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }

  private indexAsTime(index: number) {
    return (index - 1 < 10 ? "0" + (index - 1) : index - 1) + ":00";
  }

  private parseDurationToString(duration: number) {
    const durationInMinutes = duration / 60;
    return `Fahrzeit: ${Math.floor(durationInMinutes / 60)} h ${Math.round(
      durationInMinutes % 60
    )} min`;
  }

  private openTourDayMap(tourDayIndex: number) {
    this.$emit("open-tour-day-map", tourDayIndex);
    // this.vm.components.push({
    //   component: "TourDayMap",
    //   props: this.$props.tourWeek.tourDays[tourDayIndex]
    // });
  }

  private getCardColor(processingState: string) {
    switch (processingState) {
      case "OPEN":
        return "primary";
      case "TO_CHECK":
        return "grey";
      case "REJECTED":
        return "red";
      case "FINISHED":
      case "TO_INVOICE":
      case "ARCHIVED":
        return "green";
      default:
        return "primary";
    }
  }

  private openAppointment(id: string) {
    if (this.$props.absolute || !this.$props.draggable) {
      if (!this.isMobile || this.expandedAppointments[id]) {
        this.$emit("openAppointment", id);
      } else {
        for (const key of Object.keys(this.expandedAppointments)) {
          this.expandedAppointments[key] = false;
        }
        this.expandedAppointments[id] = true;
      }
    }
  }
}
