
























































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";
import ValidatedSwitch from "@/components/form/vee-validate/ValidatedSwitch.vue";

import RequestButton from "@/components/basic/RequestButton.vue";

import { CreateSupplierViewModel } from "@/store/vms/CreateSupplierViewModel";
import { CreateSupplierPresenter } from "@/store/presenters/CreateSupplierPresenter";
import { CreateSupplierController } from "@/store/controllers/CreateSupplierController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { SupplierGraphQLService } from "../services/SupplierGraphQLService";

@Component<CreateSupplierView>({
  components: {
    ValidationObserver,
    Card,
    Container,
    Column,
    Row,
    ValidatedTextfield,
    ValidatedSelect,
    ValidatedSwitch,
    RequestButton
  },
  watch: {
    "vm.visibleView"(newValue) {
      if (!newValue) {
        setTimeout(() => {
          this.$destroy();
          if (this.$router.currentRoute.name === "supplier-create") {
            this.$nextTick(() => this.$router.back());
          }
        }, 500);
      }
    }
  }
})
export default class CreateSupplierView extends Vue {
  private vm = new CreateSupplierViewModel();
  private controller?: CreateSupplierController;

  private formStep = 1;

  private initController(): CreateSupplierController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreateSupplierController(
      new CreateSupplierPresenter(this.vm),
      new SupplierGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$route.params.supplierId);
  }
}
