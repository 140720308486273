import {
  Selection,
  Date,
  Textfield,
  Numberfield,
  Checkbox,
  Request,
  MultiSelect
} from "@/forms/ViewModelFormTypes";

export class CreatePlantViewModel {
  public visibleView: boolean = false;
  public goBack: boolean = false;
  public createdPlant: string = "";

  public formStep: number = 1;
  public addressStepCompleted: boolean = false;
  public plantDataStepCompleted: boolean = false;

  public openPlantDataStepButtonDisabled: boolean = true;
  public openOtherStepButtonDisabled: boolean = true;
  public createPlantButtonDisabled: boolean = true;
  // public firstDateVisible: boolean = false;

  public createPlantRequest: Request = { loading: false, error: "" };

  public id = "";

  public addressRemarks: Textfield = { value: "", error: "" };
  public street: Textfield = { value: "", error: "" };
  public zip: Textfield = { value: "", error: "" };
  public city: Textfield = { value: "", error: "" };
  public country: Selection = { selected: "", error: "", items: [] };
  public state: Selection = { selected: "", error: "", items: [] };

  public group: Selection = { selected: "", error: "", items: [] };
  public type: Selection = {
    label: "Anlagentyp",
    selected: "",
    error: "",
    items: [],
    noDataText: "Bitte Anlagengruppe wählen"
  };
  public plantSizes: any[] = [];
  public maintenanceInterval: Numberfield = { value: 0, error: "" };
  public sampleNeeded: Checkbox = { value: false, error: "" };
  // public firstDate: Date = { value: "", error: "", text: "" };

  public districtAuthority: Selection = {
    label: "Bezirkshauptmannschaft",
    selected: "",
    error: "",
    items: []
  };
  public daNumber: Textfield = { value: "", error: "" };
  public remark: Textfield = { value: "", error: "" };
  public operators: MultiSelect = { selected: [], error: "", items: [] };
}
