import { Form, FormDefinition } from "./Form";

export class DynamicForm extends Form {
  public constructor(
    protected definition: FormDefinition,
    fieldContext: any,
    validatedEvent: (context: any, valid: boolean) => void,
    parentForm?: Form,
    formContext?: any,
    name?: string
  ) {
    super(fieldContext, validatedEvent, parentForm, formContext, name);
  }
}
