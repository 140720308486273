import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { WorkOrderGraphQLService } from "../services/WorkOrderGraphQLService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";

export class EmptyDataSheetExport {
  public static async export() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    const workOrderService = new WorkOrderGraphQLService(connection);

    const request = workOrderService.exportEmptyDataSheet();

    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading && !response.error) {
          window.open(response.data, "_blank");
        }
      },
      "create-work-order-pdf-failed"
    );
  }
}
