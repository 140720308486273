




















































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import Schedule from "@/common/views/Schedule.vue";
import IconButton from "@/components/basic/IconButton.vue";

import Select from "@/components/form/Select.vue";

import { ServiceEngineerWeeklyScheduleViewModel } from "@/report/vms/ServiceEngineerWeeklyScheduleViewModel";
import { ServiceEngineerWeeklySchedulePresenter } from "@/report/presenters/ServiceEngineerWeeklySchedulePresenter";
import { ServiceEngineerWeeklyScheduleController } from "@/report/controllers/ServiceEngineerWeeklyScheduleController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { TourWeekGraphQLService } from "../../tourPlanner/services/TourWeekGraphQLService";
import { WorkOrderService } from "../services/WorkOrderService";
import { ServiceEngineerGraphQLService } from "@/tourPlanner/services/ServiceEngineerGraphQLService";
import { PlantService } from "@/plant/services/PlantService";

@Component<ServiceEngineerWeeklyScheduleView>({
  components: {
    Page,
    Card,
    Container,
    Column,
    Row,
    Schedule,
    Select,
    IconButton,
    TourDayMap: () => import("@/tourPlanner/views/TourDayMap.vue")
  }
})
export default class ServiceEngineerWeeklyScheduleView extends Vue {
  private vm = new ServiceEngineerWeeklyScheduleViewModel();
  private controller?: ServiceEngineerWeeklyScheduleController;

  private initController(): ServiceEngineerWeeklyScheduleController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new ServiceEngineerWeeklyScheduleController(
      new ServiceEngineerWeeklySchedulePresenter(this.vm),
      this.$route.params.serviceEngineerId,
      new WorkOrderService(connection),
      new ServiceEngineerGraphQLService(connection),
      new TourWeekGraphQLService(connection),
      new PlantService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }

  private openWorkOrder(id: string) {
    this.$router.push({ name: "work-order", params: { workOrderId: id } });
  }

  private print() {
    const week = this.vm.timeFrameItems.items.find(
      tw => tw.value === this.vm.timeFrameItems.selected
    )?.text;
    const serviceEngineer = this.vm.serviceEngineers.items.find(
      se => se.value === this.vm.serviceEngineers.selected
    )?.text;

    this.controller?.print(week!, serviceEngineer!, this.vm.tourWeek);
  }
}
