export interface MultiUploadPlantDocument {
  plantId: string;
  documentName: string;
  file: File;
}

export class MultiUploadViewModel {
  public loading = false;
  public error = "";

  public documents: MultiUploadPlantDocument[] = [];
  public headers = [
    { text: "Anlagennummer", value: "plantId" },
    { text: "Dokument", value: "documentName" },
    { text: "", value: "actions", align: "right" }
  ];
}
