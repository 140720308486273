

















import Vue from "vue";
import Component from "vue-class-component";

@Component<CustomDialog>({
  props: {
    title: {
      type: String,
      default: "Titel"
    },
    confirmText: {
      type: String,
      default: "OK"
    },
    denyText: {
      type: String,
      default: "Abbrechen"
    },
    skipText: {
      type: String,
      default: "Überspringen"
    },
    value: {
      type: Boolean,
      default: false
    }
  }
})
export default class CustomDialog extends Vue {
  private confirm() {
    this.$emit("confirm");
  }

  private deny() {
    this.$emit("deny");
  }

  private skip() {
    this.$emit("skip");
  }
}
