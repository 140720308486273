import { Dictionary } from "@/datastructures/Dictionary";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { FormResponse } from "@/forms/FormResponse";
import { IItemTemplatePresenter } from "../controllers/ItemTemplateController";
import { ItemTemplateViewModel } from "../vms/ItemTemplateViewModel";

export class ItemTemplatePresenter implements IItemTemplatePresenter {
  public mounting = true;

  public groupDictionary: Dictionary<string> = {};
  public typeDictionary: Dictionary<string> = {};

  public constructor(private vm: ItemTemplateViewModel) {}

  public set tableRequest(response: FormResponse<PaginatedList<any>>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.items = response.data.items.map(el => ({
        ...el,
        group: this.groupDictionary[el.groupId] || el.groupId,
        type: this.typeDictionary[el.typeId] || el.typeId
      }));
      this.vm.totalItems = response.data.totalCount;

      this.mounting = false;
    }
  }

  public get pageNumber() {
    return this.vm.pageNumber;
  }

  public set pageNumber(pageNumber: number) {
    this.vm.pageNumber = pageNumber;
  }

  public get itemsPerPage() {
    return this.vm.itemsPerPage;
  }

  public get options() {
    return this.vm.options;
  }

  public set options(options: any) {
    this.vm.options = options;
  }

  public set deleteRequest(response: FormResponse<string>) {
    this.vm.loading = response.loading;
  }

  public get search() {
    return this.vm.search;
  }
}
