













import Vue from "vue";
import Component from "vue-class-component";
import { ValidationProvider } from "vee-validate";

@Component<ValidatedSelect>({
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    value: {}
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  }
})
export default class ValidatedSelect extends Vue {
  private innerValue: string[] = [];

  private created() {
    if (this.$props.value) {
      this.innerValue = this.$props.value;
    }
  }
}
