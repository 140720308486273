import SystemKeys from "@/common/utils/SystemKeys";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { StorageHandler } from "./StorageHandler";

export class LabsStorageHandler extends StorageHandler {
  public static getAllLabs(): SelectionItem[] {
    if (this.storage.has(SystemKeys.labsTableKey)) {
      const fields = JSON.parse(this.storage.get(SystemKeys.labsTableKey));

      return fields?.map((field: any) => ({
        text: field[SystemKeys.values][SystemKeys.labsMailKey],
        value: field[SystemKeys.id]
      }));
    }

    return [];
  }

  public static getLabsMailAddresses(): string[] {
    if (this.storage.has(SystemKeys.labsTableKey)) {
      const fields = JSON.parse(this.storage.get(SystemKeys.labsTableKey));

      return fields?.map(
        (field: any) => field[SystemKeys.values][SystemKeys.labsMailKey]
      );
    }

    return [];
  }
}
