




























import Vue from "vue";
import Component from "vue-class-component";
import { gmapApi } from "vue2-google-maps";
import { DateUtils } from "../../utils/DateUtils";

@Component<GoogleMapMarker>({
  name: "GoogleMapMarker",
  inheritAttrs: false,
  props: {
    index: Number,
    date: {
      type: Date,
      default: null
    },
    plantIndex: {
      type: String,
      default: ""
    },
    position: {
      type: Object,
      default: () => {
        return { lat: 0, lng: 0 };
      }
    },
    map: {
      type: Object
    }
  }
})
export default class GoogleMapMarker extends Vue {
  public tooltipShown = false;
  public tooltipLeft = 0;
  public tooltipTop = 0;
  public map!: any;
  public position!: any;

  public get google() {
    return gmapApi;
  }

  public mounted() {
    (this.$refs.mapMarker as any).$markerPromise.then(() => {
      const marker = (this.$refs.mapMarker as any).$markerObject;
      this.$emit("getMarker", {
        marker,
        index: this.$props.index
      });
    });
  }

  private click() {
    this.$emit("click");
  }

  private dragend(event: any) {
    this.$emit("dragend", event);
  }

  private mouseover(event: any) {
    if (!!this.map) {
      this.fromLatLngToPoint();
      this.tooltipShown = true;
    }
    this.$emit("mouseover", event);
  }

  private mouseout(event: any) {
    if (!!this.map) {
      this.tooltipShown = false;
    }
    this.$emit("mouseover", event);
  }

  private fromLatLngToPoint() {
    const topRight = this.map
      .getProjection()
      .fromLatLngToPoint(this.map.getBounds().getNorthEast());
    const bottomLeft = this.map
      .getProjection()
      .fromLatLngToPoint(this.map.getBounds().getSouthWest());
    const scale = Math.pow(2, this.map.getZoom());
    const worldPoint = this.map
      .getProjection()
      .fromLatLngToPoint(
        new google.maps.LatLng(this.position.lat, this.position.lng)
      );
    this.tooltipLeft = (worldPoint.x - bottomLeft.x) * scale + 180;
    this.tooltipTop = (worldPoint.y - topRight.y) * scale + 100;
  }

  private parseDate(date: Date) {
    return DateUtils.formatDate(date);
  }
}
