import { Form, FormFieldType } from "@/forms/Form";

export class PersonDataForm extends Form {
  public definition = {
    degrees: { required: false, type: FormFieldType.MultiSelect },
    salutation: { required: true, type: FormFieldType.Selection },
    firstName: { required: false, type: FormFieldType.Text },
    lastName: { required: false, type: FormFieldType.Text },
    company: { required: false, type: FormFieldType.Text },
    mail: { required: false, type: FormFieldType.Text },
    website: { required: false, type: FormFieldType.Text }
  };
}
