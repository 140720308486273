import { TableHeader } from "@/forms/ViewModelFormTypes";
import { TourWeek } from "../services/TourWeekGraphQLService";
import { Selection } from "../../forms/ViewModelFormTypes";
import { ServiceEngineerViewModel } from "./ServiceEngineerViewModel";

export class ServiceEngineerListViewModel {
  public loadingEngineers = true;
  public loadingWeek = false;
  public engineerList: ServiceEngineerViewModel[] = [];
  public selectedYear = new Date().getFullYear().toString();
  public engineersHeaders: TableHeader[] = [
    { text: "Name", value: "name", sortable: true },
    { text: "Aktionen", value: "actions", width: "5%" }
  ];

  public weeksList: { [id: string]: TourWeek[] } = {};

  public weekHeaders: TableHeader[] = [
    { text: "KW", value: "weekNumbercalculated", sortable: true },
    { text: "Datum", value: "weekNumber", sortable: true },
    { text: "Status", value: "processingState", sortable: true }
  ];

  public timeFrameItems: Selection = {
    selected: "",
    error: "",
    items: [],
    label: "Kalenderwochen"
  };
}
