import { GraphQLConnection } from "@/gateways/graphql/GraphQLConnection";
import { ISearchService, SearchResult } from "@/common/services/SearchService";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Page } from "@/datastructures/Page";
import {
  Plant,
  IPlantService,
  PlantContactPerson,
  PlantParameter,
  PlantPart,
  PlantDocumentPackage,
  PlantContract
} from "./PlantService";
import { DateUtils } from "@/utils/DateUtils";
import { Plants, VpiCalculation } from "../models/Plants";

export class PlantGraphQLService implements IPlantService, ISearchService {
  public static get PlantFields() {
    return [
      "id",
      "index",
      "addressRemarks",
      "addressStreet",
      "addressZip",
      "addressCity",
      "addressState",
      "addressCountry",
      "addressAccessRemarks",
      "addressLat",
      "addressLng",
      "group",
      "type",
      "maintenanceInterval",
      "sampleNeeded",
      "districtAuthority",
      "districtAuthorityNumber",
      "remarks",
      "plantSizes",
      "protocol",
      {
        name: "contactPersons",
        fields: [
          "id",
          "salutation",
          "firstName",
          "lastName",
          "company",
          "eMail",
          {
            name: "phoneNumbers",
            fields: ["areaCode", "phoneNumber", "isStandard", "remarks"]
          }
        ]
      },
      {
        name: "parameters",
        fields: ["value", "parameterId"]
      },
      {
        name: "plantParts",
        fields: ["amount", "description", "parameterId", "index"]
      },
      {
        name: "plantInstallationParts",
        fields: ["amount", "description", "parameterId", "index"]
      },
      {
        name: "plantDocuments",
        fields: ["name", "description", "uploadedAt", "path", "immutable"]
      },
      {
        name: "plantContract",
        fields: [
          "hasContract",
          "date",
          "doneBy",
          "maintenanceNumber",
          "maintenanceInterval",
          "contractQuitted",
          "quitDate",
          "remarks",
          "maintenanceCost",
          "priceSample",
          "currentIndex",
          "baseIndex",
          "startIndex"
        ]
      }
    ];
  }

  public constructor(private connection: GraphQLConnection) {}

  public async getPlantById(id: string): Promise<Plant> {
    const fields: any[] = PlantGraphQLService.PlantFields;
    fields.push({
      name: "operators",
      fields: ["id", "firstName", "lastName", "company", "eMail"]
    });
    const request = await this.connection.query(
      "getPlantById",
      {
        id
      },
      fields
    );

    return this.parsePlant(request.data);
  }

  public async getPlantByIndex(index: number): Promise<Plant> {
    const fields: any[] = PlantGraphQLService.PlantFields;
    fields.push({
      name: "operators",
      fields: ["id", "firstName", "lastName", "company", "eMail"]
    });
    const request = await this.connection.query(
      "getPlantByIndex",
      {
        index
      },
      fields
    );

    return this.parsePlant(request.data);
  }

  public async search(
    searchText: string,
    page: Page
  ): Promise<PaginatedList<SearchResult>> {
    const request = await this.connection.queryPaginated(
      "searchPlants",
      page.itemsPerPage,
      page.page,
      [
        "id",
        "index",
        "group",
        "type",
        "addressStreet",
        "addressZip",
        "addressCity",
        "addressCountry"
      ],
      searchText,
      []
    );

    return {
      items: request.data.map((el: any) => ({
        id: el.id,
        index: el.index,
        extra: el.group + " - " + el.type,
        street: el.addressStreet,
        zip: el.addressZip,
        city: el.addressCity,
        country: el.addressCountry
      })),
      totalCount: request.count
    };
  }

  public async findOther(id: string): Promise<SearchResult[]> {
    const request = await this.connection.query(
      "findOperatorsByPlantId",
      {
        id
      },
      [
        "id",
        "index",
        "firstName",
        "lastName",
        "company",
        "addressStreet",
        "addressZip",
        "addressCity",
        "addressCountry"
      ]
    );

    return request.data.map((el: any) => ({
      id: el.id,
      index: el.index,
      extra: el.lastName ? el.firstName + " " + el.lastName : el.company,
      street: el.addressStreet,
      zip: el.addressZip,
      city: el.addressCity,
      country: el.addressCountry
    }));
  }

  public async getPlantsForVpiCalculation(
    vpi: string,
    thresholdPercent: number,
    targetIndex: number
  ): Promise<Plants> {
    const request = await this.connection.query(
      "findPlantsForVpiCalculation",
      {
        vpi,
        thresholdPercent,
        targetIndex
      },
      [
        ...PlantGraphQLService.PlantFields,
        {
          name: "operators",
          fields: ["id", "firstName", "lastName", "company", "eMail"]
        }
      ]
    );

    return new Plants(request.data.map((d: any) => this.parsePlant(d)));
  }

  public async createPlant(plant: Plant): Promise<string> {
    const input: any = {
      addressRemarks: plant.addressRemarks,
      addressStreet: plant.addressStreet,
      addressZip: plant.addressZip,
      addressCity: plant.addressCity,
      addressState: plant.addressState,
      addressCountry: plant.addressCountry,
      group: plant.group,
      type: plant.type,
      plantSizes: plant.plantSizes,
      // maintenanceInterval: plant.maintenanceInterval,
      sampleNeeded: plant.sampleNeeded,
      // firstDate: plant.firstDate?.toISOString(),
      districtAuthority: plant.districtAuthority,
      districtAuthorityNumber: plant.districtAuthorityNumber,
      remarks: plant.remarks,
      operatorIds: plant.operatorIds
    };

    if (!!plant.id) {
      input.id = plant.id;
    }

    const request = await this.connection.mutation(
      "upsertPlant",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async updatePlant(plant: Plant): Promise<string> {
    const request = await this.connection.mutation(
      "upsertPlant",
      {
        input: {
          id: plant.id,
          addressRemarks: plant.addressRemarks,
          addressStreet: plant.addressStreet,
          addressZip: plant.addressZip,
          addressCity: plant.addressCity,
          addressState: plant.addressState,
          addressCountry: plant.addressCountry,
          addressAccessRemarks: plant.addressAccessRemarks,
          addressLat: plant.addressLat,
          addressLng: plant.addressLng,
          group: plant.group,
          type: plant.type,
          // maintenanceInterval: plant.maintenanceInterval,
          sampleNeeded: plant.sampleNeeded,
          districtAuthority: plant.districtAuthority,
          districtAuthorityNumber: plant.districtAuthorityNumber,
          remarks: plant.remarks,
          operatorIds: plant.operatorIds,
          contactPersons: plant.contactPersons,
          protocol: plant.protocol
        }
      },
      []
    );

    return request.data;
  }

  public async addPlantContactPerson(
    plant: Plant,
    contactPerson: PlantContactPerson
  ): Promise<string> {
    const contactPersons = [...plant.contactPersons];
    if (!contactPerson.id) {
      contactPersons.push(contactPerson);
    } else {
      const index = contactPersons.findIndex(el => el.id === contactPerson.id);

      if (index >= 0) {
        contactPersons.splice(index, 1, contactPerson);
      }
    }
    const request = await this.connection.mutation(
      "upsertPlant",
      {
        input: {
          id: plant.id,
          contactPersons
        }
      },
      []
    );

    return request.data;
  }

  public async removePlantContactPerson(
    plant: Plant,
    contactPerson: PlantContactPerson
  ): Promise<string> {
    if (!!contactPerson.id) {
      const index = plant.contactPersons.findIndex(
        el => el.id === contactPerson.id
      );

      if (index >= 0) {
        plant.contactPersons.splice(index, 1);
      }
    }
    const request = await this.connection.mutation(
      "upsertPlant",
      {
        input: {
          id: plant.id,
          contactPersons: plant.contactPersons
        }
      },
      []
    );

    return request.data;
  }

  public async addPlantParameter(
    plant: Plant,
    parameter: PlantParameter
  ): Promise<string> {
    const parameters = [...plant.parameters];
    const index = parameters.findIndex(
      el => el.parameterId === parameter.parameterId
    );

    if (index >= 0) {
      parameters.splice(index, 1, parameter);
    } else {
      parameters.push(parameter);
    }

    const request = await this.connection.mutation(
      "upsertPlant",
      {
        input: {
          id: plant.id,
          parameters
        }
      },
      []
    );

    return request.data;
  }

  public async addPlantParameters(
    plant: Plant,
    parameters: PlantParameter[]
  ): Promise<string> {
    const existingParameters = [...plant.parameters];

    for (const parameter of parameters) {
      const index = existingParameters.findIndex(
        el => el.parameterId === parameter.parameterId
      );

      if (index >= 0) {
        existingParameters.splice(index, 1, parameter);
      } else {
        existingParameters.push(parameter);
      }
    }

    const request = await this.connection.mutation(
      "upsertPlant",
      {
        input: {
          id: plant.id,
          parameters: existingParameters
        }
      },
      []
    );

    return request.data;
  }

  public async removePlantParameter(
    plant: Plant,
    parameter: PlantParameter
  ): Promise<string> {
    const parameters: PlantParameter[] = [];
    const parameterId = parameter.parameterId || (parameter as any).id;

    for (const plantParameter of plant.parameters) {
      const plantParameterId =
        plantParameter.parameterId || (plantParameter as any).id;
      if (plantParameterId === parameterId) {
        continue;
      }

      if (!plantParameter.parameterId && (plantParameter as any).id) {
        parameters.push({
          parameterId: (plantParameter as any).id,
          value: plantParameter.value
        });
      } else {
        parameters.push(plantParameter);
      }
    }

    const request = await this.connection.mutation(
      "upsertPlant",
      {
        input: {
          id: plant.id,
          parameters
        }
      },
      []
    );

    return request.data;
  }

  public async addPlantPart(
    plant: Plant,
    plantPart: PlantPart
  ): Promise<string> {
    let plantParts = [...plant.plantParts];
    const index = plantParts.findIndex(
      el => el.parameterId === plantPart.parameterId
    );

    if (index >= 0) {
      plantParts.splice(index, 1, plantPart);
    } else {
      plantParts.push(plantPart);
    }

    plantParts = this.parsePlantParts(plantParts);

    const request = await this.connection.mutation(
      "upsertPlant",
      {
        input: {
          id: plant.id,
          plantParts
        }
      },
      []
    );

    return request.data;
  }

  public async removePlantPart(
    plant: Plant,
    plantPart: PlantPart
  ): Promise<string> {
    if (!!plantPart.parameterId) {
      const index = plant.plantParts.findIndex(
        el => el.parameterId === plantPart.parameterId
      );

      if (index >= 0) {
        plant.plantParts.splice(index, 1);
      }
    }

    const plantParts = this.parsePlantParts(plant.plantParts);

    const request = await this.connection.mutation(
      "upsertPlant",
      {
        input: {
          id: plant.id,
          plantParts
        }
      },
      []
    );

    return request.data;
  }

  public async addPlantInstallationPart(
    plant: Plant,
    plantPart: PlantPart
  ): Promise<string> {
    let plantInstallationParts = [...plant.plantInstallationParts];
    const index = plantInstallationParts.findIndex(
      el => el.parameterId === plantPart.parameterId
    );

    if (index >= 0) {
      plantInstallationParts.splice(index, 1, plantPart);
    } else {
      plantInstallationParts.push(plantPart);
    }

    plantInstallationParts = this.parsePlantParts(plantInstallationParts);

    const request = await this.connection.mutation(
      "upsertPlant",
      {
        input: {
          id: plant.id,
          plantInstallationParts
        }
      },
      []
    );

    return request.data;
  }

  public async removePlantInstallationPart(
    plant: Plant,
    plantPart: PlantPart
  ): Promise<string> {
    if (!!plantPart.parameterId) {
      const index = plant.plantInstallationParts.findIndex(
        el => el.parameterId === plantPart.parameterId
      );

      if (index >= 0) {
        plant.plantInstallationParts.splice(index, 1);
      }
    }

    const plantInstallationParts = this.parsePlantParts(
      plant.plantInstallationParts
    );
    const request = await this.connection.mutation(
      "upsertPlant",
      {
        input: {
          id: plant.id,
          plantInstallationParts
        }
      },
      []
    );

    return request.data;
  }

  public async uploadPlantDocument(
    foreignId: string,
    isOperator: boolean,
    document: PlantDocumentPackage
  ) {
    const request = await this.connection.mutation(
      "uploadPlantDocument",
      {
        input: {
          foreignId,
          name: document.name,
          description: document.description,
          isOperator,
          toDatabase: true
        }
      },
      [],
      document.file
    );

    return request.data;
  }

  public async removePlantDocument(
    foreignId: string,
    documentName: string,
    isOperator: boolean
  ) {
    const request = await this.connection.mutation(
      "deletePlantDocument",
      {
        input: {
          foreignId,
          name: documentName,
          isOperator
        }
      },
      []
    );

    return request.data;
  }

  public async updatePlantRemarks(plant: Plant): Promise<string> {
    const request = await this.connection.mutation(
      "upsertPlant",
      {
        input: {
          id: plant.id,
          remarks: plant.remarks
        }
      },
      []
    );

    return request.data;
  }

  public async updatePlantProtocol(
    id: string,
    protocol: string,
    date: string
  ): Promise<string> {
    const request = await this.connection.mutation(
      "addProtocolLine",
      {
        input: {
          id,
          protocol,
          date
        }
      },
      []
    );

    return request.data;
  }

  public async upsertContract(plantId: string, contract: PlantContract) {
    const request = await this.connection.mutation(
      "upsertPlantContract",
      {
        input: {
          plantId,
          hasContract: contract.hasContract,
          date: contract.date,
          doneBy: contract.doneBy,
          maintenanceNumber: contract.maintenanceNumber,
          maintenanceCost: contract.maintenanceCost,
          priceSample: contract.priceSample,
          baseIndex: contract.baseIndex,
          startIndex: contract.startIndex,
          currentIndex: contract.currentIndex,
          dueDate: contract.dueDate,
          firstAppointment: contract.firstAppointment,
          maintenanceInterval: contract.maintenanceInterval,
          contractQuitted: contract.contractQuitted,
          quitDate: contract.quitDate,
          remarks: contract.remarks
        }
      },
      []
    );

    return request.data;
  }

  public async getPlantContractByPlantId(
    plantId: string
  ): Promise<PlantContract> {
    const request = await this.connection.query(
      "getPlantById",
      {
        id: plantId
      },
      [
        {
          name: "plantContract",
          fields: [
            "hasContract",
            "date",
            "doneBy",
            "maintenanceNumber",
            "maintenanceCost",
            "priceSample",
            "baseIndex",
            "startIndex",
            "currentIndex",
            "dueDate",
            "firstAppointment",
            "maintenanceInterval",
            "contractQuitted",
            "quitDate",
            "remarks"
          ]
        }
      ]
    );

    return request.data.plantContract;
  }

  public async updateOperators(plant: Plant, operatorIds: string[]) {
    const request = await this.connection.mutation(
      "upsertPlant",
      {
        input: {
          id: plant.id,
          operatorIds
        }
      },
      []
    );

    return request.data;
  }

  public async exportPlants(): Promise<string> {
    const request = await this.connection.query("exportPlants", {}, []);

    return request.data;
  }

  public async createReportTemplatePdf(plantId: string) {
    const request = await this.connection.mutation(
      "makeReportPrintTemplate",
      {
        input: {
          id: plantId
        }
      },
      []
    );

    return request.data;
  }

  public async createWorkOrderTemplatePdf(plantId: string) {
    const request = await this.connection.mutation(
      "makeWorkOrderPrintTemplate",
      {
        input: {
          id: plantId
        }
      },
      []
    );

    return request.data;
  }

  public async createFolderFrontPagePdf(plantId: string) {
    const request = await this.connection.mutation(
      "makePlantFolderFrontPage",
      {
        input: {
          id: plantId
        }
      },
      []
    );

    return request.data;
  }

  public async createDataSheetPdf(plantId: string) {
    const request = await this.connection.mutation(
      "makePlantDataSheet",
      {
        input: {
          id: plantId
        }
      },
      []
    );

    return request.data;
  }

  public async sendDocuments(
    mailAddress: string[],
    subject: string,
    text: string,
    documentPaths: string[],
    plantId: string
  ) {
    const request = await this.connection.mutation(
      "sendDocuments",
      {
        input: {
          mailAddress,
          subject,
          text,
          documentPaths,
          plantId
        }
      },
      []
    );

    return request.data;
  }

  public async updateContractPrices(
    id: string,
    calculation: VpiCalculation
  ): Promise<string> {
    const request = await this.connection.mutation(
      "updateContractPrices",
      {
        input: {
          id,
          targetIndex: calculation.targetIndex,
          maintenancePrice: calculation.newContractCost,
          samplePrice: calculation.newSampleCost,
          startIndex: calculation.startIndex
        }
      },
      []
    );

    return request.data;
  }

  private parsePlant(raw: any): Plant {
    const plant: Plant = raw;

    if (!!plant.plantDocuments && plant.plantDocuments.length > 0) {
      for (const document of plant.plantDocuments) {
        document.uploadedAt = new Date(
          parseInt(document.uploadedAt as any, 10)
        );
      }
    }

    if (!!plant.plantParts) {
      plant.plantParts.sort((a, b) => a.index - b.index);
    }

    if (!!plant.plantInstallationParts) {
      plant.plantInstallationParts.sort((a, b) => a.index - b.index);
    }

    return plant;
  }

  private parsePlantParts(plantParts: PlantPart[]): any[] {
    const toReturn: any[] = [];
    for (let i = 0; i < plantParts.length; i++) {
      toReturn.push({
        amount: plantParts[i].amount,
        parameterId: plantParts[i].parameterId,
        description: plantParts[i].description,
        index: i
      });
    }

    return toReturn;
  }
}
