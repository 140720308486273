import { ITimeTrackingPresenter } from "../controllers/TimeTrackingController";
import { TimeTrackingViewModel, Week } from "../vms/TimeTrackingViewModel";
import { DateUtils } from "@/utils/DateUtils";
import { FormResponse } from "@/forms/FormResponse";

export class TimeTrackingPresenter implements ITimeTrackingPresenter {
  public constructor(private vm: TimeTrackingViewModel) {
    this.makeTimeFrameItems();
  }

  public set getEmployeesResponse(response: FormResponse<any[]>) {
    this.vm.filterEmployee.error = response.error;
    this.isLoading(response.loading);

    if (!response.error && !response.loading) {
      this.vm.filterEmployee.items = response.data.map(val => ({
        text: `${val.firstname} ${val.lastname}`,
        value: val.id
      }));
    }
  }

  public set getFilteredDaysResponse(response: FormResponse<any[]>) {
    this.isLoading(response.loading);

    if (!response.loading && !response.error) {
      for (const week of this.vm.weeks) {
        for (const rawDay of response.data) {
          const foundDayIndex = week.days.findIndex(
            day =>
              DateUtils.toISOString(rawDay.date) ===
              DateUtils.toISOString(day.date)
          );

          if (foundDayIndex > -1) {
            const foundDay = week.days.splice(foundDayIndex, 1)[0];

            for (const key of Object.keys(rawDay)) {
              (foundDay as any)[key] = rawDay[key];
            }

            week.days.push(foundDay);
            week.days.sort((a, b) => a.date.getTime() - b.date.getTime());
          }
        }
      }
    }
  }

  public set xlsResultResponse(newValue: FormResponse<any>) {
    this.vm.filterEmployee.error = newValue.error;
    this.isLoading(newValue.loading);

    if (!newValue.error && !newValue.loading) {
      window.open(newValue.data, "_blank");
    }
  }

  public set filterEmployee(newValue: string) {
    this.vm.filterEmployee.selected = newValue;
  }

  public get filterEmployee() {
    return this.vm.filterEmployee.selected;
  }

  public set filterWeekNumber(newValue: string) {
    this.vm.filterWeekNumber.selected = newValue;
  }

  public get filterWeekNumber() {
    return this.vm.filterWeekNumber.selected;
  }

  public set filterDateFrom(newValue: string) {
    this.vm.filterDateFrom.value = newValue;
    this.vm.filterDateFrom.text = DateUtils.format(newValue);
  }

  public get filterDateFrom() {
    return this.vm.filterDateFrom.value;
  }

  public set filterDateTo(newValue: string) {
    this.vm.filterDateTo.value = newValue;
    this.vm.filterDateTo.text = DateUtils.format(newValue);
  }

  public get filterDateTo() {
    return this.vm.filterDateTo.value;
  }

  public set weeks(weeks: Week[]) {
    this.vm.weeks = weeks;
  }

  private isLoading(loading: boolean) {
    this.vm.loading += loading ? 1 : -1;
  }

  private makeTimeFrameItems() {
    const now = DateUtils.removeTimeStamp(new Date());
    let date = new Date(now);
    date.setFullYear(date.getFullYear() - 1);

    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    date.setDate(diff);

    const endDate = new Date(now);
    endDate.setFullYear(endDate.getFullYear() + 1);

    const items = [];
    const selected = DateUtils.dateOnly(
      DateUtils.weekMondayFromDate(new Date())
    );
    while (date < endDate) {
      const nextWeekDate = new Date(date);
      nextWeekDate.setDate(nextWeekDate.getDate() + 4);
      const item = `KW ${DateUtils.weekNumber(date)}/${date.getFullYear()}`;
      items.push({ text: item, value: DateUtils.dateOnly(date) });
      nextWeekDate.setDate(nextWeekDate.getDate() + 3);
      date = nextWeekDate;
    }

    this.vm.filterWeekNumber = { items, selected, error: "" };
  }
}
