import { TableHeader } from "@/forms/ViewModelFormTypes";

export class SendReportToLabViewModel {
  public mailAddress: { selected: string[]; items: string[] } = {
    items: [],
    selected: []
  };
  public subject = "Prüfberichte";
  public text =
    "Sehr geehrte Damen und Herren,\r\n\r\n" +
    "im Anhang übermitteln wir Ihnen die Prüfberichte zu Ihrer weiteren Verwendung.";

  public tableLoading = false;
  public tableHeaders: TableHeader[] = [
    { text: "Anlagennummer", value: "plantIndex", width: "140px" },
    {
      text: "Kalenderwoche",
      value: "weekNumber",
      width: "140px",
      sortable: false
    },
    { text: "Datum", value: "plannedDate" },
    {
      text: "Dateigröße",
      value: "fileSize"
    },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "right",
      width: "160px"
    }
  ];
  public tableItems: any[] = [];

  public requestLoading = false;
  public requestError = "";
  public finished = false;
}
