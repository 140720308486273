import { IServiceEngineerListPresenter } from "../controllers/ServiceEngineerListController";
import { ServiceEngineerListViewModel } from "../vms/ServiceEngineerListViewModel";
import { DateUtils } from "@/utils/DateUtils";
import { Selection } from "../../forms/ViewModelFormTypes";
import { FormResponse } from "@/forms/FormResponse";
import { ServiceEngineer } from "../services/ServiceEngineerGraphQLService";
import { TourWeek } from "../services/TourWeekGraphQLService";

export class ServiceEngineerListPresenter
  implements IServiceEngineerListPresenter {
  public constructor(private vm: ServiceEngineerListViewModel) {
    vm.timeFrameItems = this.makeTimeFrameItems(vm.selectedYear);
  }

  public setWeeks(
    serviceEngineerId: string,
    weeksResponse: FormResponse<TourWeek[]>
  ) {
    if (!weeksResponse.loading && !weeksResponse.error) {
      this.vm.weeksList[serviceEngineerId] = weeksResponse.data;
      this.vm.weeksList[serviceEngineerId].sort(
        (a, b) =>
          a.processingState.localeCompare(b.processingState) &&
          b.weekNumber.localeCompare(a.weekNumber)
      );
    }
    this.vm.loadingWeek = weeksResponse.loading;
  }

  public set engineersResponse(
    engineersResponse: FormResponse<ServiceEngineer[]>
  ) {
    if (!engineersResponse.loading && !engineersResponse.error) {
      const parsedEngineers = engineersResponse.data.map(el => ({
        id: el.id,
        tourWeeks: [],
        serviceEngineer: el
      }));
      this.vm.engineerList = parsedEngineers;
    }
    this.vm.loadingEngineers = engineersResponse.loading;
  }

  public set selectedYear(selectedYear: string) {
    this.vm.selectedYear = selectedYear;
    this.vm.timeFrameItems = this.makeTimeFrameItems(selectedYear);
  }

  public set timeFrameItems(newDate: string) {
    this.vm.timeFrameItems.selected = newDate;
  }

  public get weeksList() {
    return this.vm.weeksList;
  }

  private makeTimeFrameItems(selectedYear: string): Selection {
    const year = parseInt(selectedYear, 10);

    const now = new Date();
    now.setFullYear(year);

    let date = DateUtils.weekMondayFromDate(new Date(year, 0));

    const endDate = new Date(year, 11, 31);

    const items = [];
    let selected = "";
    while (date < endDate) {
      const nextWeekDate = new Date(date);
      nextWeekDate.setDate(nextWeekDate.getDate() + 4);
      const item = `KW ${DateUtils.weekNumber(
        date
      )}/${date.getFullYear()} (${DateUtils.formatDate(
        date
      )} - ${DateUtils.formatDate(nextWeekDate)})`;
      if ((date > now || nextWeekDate > now) && !selected) {
        selected = DateUtils.removeTimeStamp(date).toISOString();
      }
      items.push({ text: item, value: date.toISOString() });
      nextWeekDate.setDate(nextWeekDate.getDate() + 3);
      date = nextWeekDate;
    }

    return { selected, items, error: "" };
  }
}
