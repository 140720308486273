<template>
  <div>
    <div ref="flyawayMWL">
      <!-- so named because it will go away to another component -->
      <slot name="labelContent"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default (x => {
  return x.default || x;
  // tslint:disable-next-line
})(require("./MarkerWithLabel.js"));
</script>
