import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { OrderByClause } from "@/gateways/graphql/GraphQLConnection";
import { StorageHolder } from "@/storage/StorageHolder";
import { Store } from "./StoreGraphQLService";
import { IStoreService } from "./StoreService";

export class StoreLocalService implements IStoreService {
  private storesStorage = StorageHolder.StoreStorage;

  public get timestamp() {
    return this.storesStorage.timestamp;
  }

  public set stores(stores: Store[]) {
    this.storesStorage.stores = stores;
    this.storesStorage.save();
  }

  public save() {
    this.storesStorage.save();
  }

  public async getAllStores(): Promise<Store[]> {
    return this.storesStorage.stores;
  }

  public async getAllStoresPaginated(
    page: Page,
    order: OrderByClause[],
    search: string
  ): Promise<PaginatedList<Store>> {
    throw new Error("Not available in offline mode");
  }

  public async getStoreById(id: string): Promise<Store> {
    throw new Error("Not available in offline mode");
  }

  public async updateStore(input: any): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public async createStore(input: any): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public async deleteStore(id: string): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public getToSyncStores() {
    return this.storesStorage.getToSyncStores();
  }
}
