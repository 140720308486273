import { Form, FormFieldType } from "@/forms/Form";

export class TourDataForm extends Form {
  protected definition = {
    startTimeMonday: { required: true, type: FormFieldType.Time },
    startTimeTuesday: { required: true, type: FormFieldType.Time },
    startTimeWednesday: { required: true, type: FormFieldType.Time },
    startTimeThursday: { required: true, type: FormFieldType.Time },
    startTimeFriday: { required: true, type: FormFieldType.Time },

    startLocationStreet: { required: true, type: FormFieldType.Text },
    startLocationZip: { required: true, type: FormFieldType.Text },
    startLocationCity: { required: true, type: FormFieldType.Text },
    startLocationCountry: { required: true, type: FormFieldType.Selection },

    endLocationStreet: { required: true, type: FormFieldType.Text },
    endLocationZip: { required: true, type: FormFieldType.Text },
    endLocationCity: { required: true, type: FormFieldType.Text },
    endLocationCountry: { required: true, type: FormFieldType.Selection }
  };
}
