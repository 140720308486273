import {
  OrderByClause,
  GraphQLConnection
} from "@/gateways/graphql/GraphQLConnection";
import { PlantParameter } from "@/storage/storageHandlers/ParameterStorageHandler";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Page } from "@/datastructures/Page";
import { WorkOrderGraphQLService } from "./WorkOrderGraphQLService";
import { WorkOrderLocalService } from "./WorkOrderLocalService";
import { OnlineCheckerFactory } from "@/common/utils/OnlineCheckerFactory";
import { CachedService } from "@/common/services/CachedService";
import { ServiceArgument } from "@/common/services/ServiceFacade";
import { Auth } from "@/common/utils/Auth";
import { DateUtils } from "@/utils/DateUtils";

export class WorkOrderService implements IWorkOrderService, CachedService {
  private onlineService: WorkOrderGraphQLService;
  private offlineService: WorkOrderLocalService;
  private service: IWorkOrderService;

  constructor(connection: GraphQLConnection) {
    this.offlineService = new WorkOrderLocalService();
    this.onlineService = new WorkOrderGraphQLService(connection);

    if (OnlineCheckerFactory.isOnline) {
      this.service = this.onlineService;
    } else {
      this.service = this.offlineService;
    }
  }

  public get timestamp() {
    return this.offlineService.timestamp;
  }

  public getAllWorkOrders(id: string): Promise<WorkOrder[]> {
    this.updateOnlineState();
    return this.service.getAllWorkOrders(id);
  }

  public getWorkOrderProcessingStates(ids: string[]): Promise<any> {
    this.updateOnlineState();
    return this.service.getWorkOrderProcessingStates(ids);
  }

  public getAllOpenWorkOrdersForServiceEngineer(
    userId: string
  ): Promise<WorkOrder[]> {
    this.updateOnlineState();
    return this.service.getAllOpenWorkOrdersForServiceEngineer(userId);
  }

  public async getAllOpenWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    const localWorkOrders = await this.offlineService.getAllLocalWorkOrders();

    if (OnlineCheckerFactory.isOnline) {
      const workOrders = await this.service.getAllOpenWorkOrders(
        id,
        search,
        pagination,
        order
      );

      for (const workOrder of workOrders.items) {
        const localWorkOrder = localWorkOrders.find(
          wo => wo.id === workOrder.id
        );

        if (localWorkOrder) {
          workOrder.syncing = localWorkOrder.syncing;
        }
      }

      return workOrders;
    } else {
      return this.offlineService.getAllOpenWorkOrders(
        id,
        search,
        pagination,
        order
      );
    }
  }

  public getAllToCheckWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    this.updateOnlineState();
    return this.service.getAllToCheckWorkOrders(id, search, pagination, order);
  }

  public getAllFinishedWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    this.updateOnlineState();
    return this.service.getAllFinishedWorkOrders(id, search, pagination, order);
  }

  public getAllToInvoiceWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    this.updateOnlineState();
    return this.service.getAllToInvoiceWorkOrders(
      id,
      search,
      pagination,
      order
    );
  }

  public getAllArchivedWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    this.updateOnlineState();
    return this.service.getAllArchivedWorkOrders(id, search, pagination, order);
  }

  public getAllWorkOrdersPaginated(
    page: Page,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    this.updateOnlineState();
    return this.service.getAllWorkOrdersPaginated(page, order);
  }

  public getAllFinishedWorkOrdersWithoutLabReport(
    week?: string
  ): Promise<WorkOrder[]> {
    this.updateOnlineState();
    return this.service.getAllFinishedWorkOrdersWithoutLabReport(week);
  }

  public getAllFinishedWorkOrdersWithLabReport(
    week?: string
  ): Promise<WorkOrder[]> {
    this.updateOnlineState();
    return this.service.getAllFinishedWorkOrdersWithLabReport(week);
  }

  public sendReportsToLab(input: any): Promise<string> {
    this.updateOnlineState();
    return this.service.sendReportsToLab(input);
  }

  public sendReportsToBh(workOrderIds: string[]): Promise<any> {
    this.updateOnlineState();
    return this.service.sendReportsToBh(workOrderIds);
  }

  public getAllWorkOrdersWithReport(): Promise<WorkOrder[]> {
    this.updateOnlineState();
    return this.service.getAllWorkOrdersWithReport();
  }

  public getAllWorkOrdersWithReportPaginated(
    page: Page,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>> {
    this.updateOnlineState();
    return this.service.getAllWorkOrdersWithReportPaginated(page, order);
  }

  public async getWorkOrderById(workOrderId: string): Promise<WorkOrder> {
    try {
      const localWorkOrder = await this.offlineService.getWorkOrderById(
        workOrderId
      );

      return localWorkOrder;
    } catch {
      return this.service.getWorkOrderById(workOrderId);
    }
  }

  public getWorkOrdersInDateRange(
    serviceEngineerId: string,
    dateFrom: string,
    dateTo: string
  ): Promise<WorkOrder[]> {
    this.updateOnlineState();
    return this.service.getWorkOrdersInDateRange(
      serviceEngineerId,
      dateFrom,
      dateTo
    );
  }

  public async upsertWorkOrder(
    id: string,
    processingState: string,
    serviceEngineerId: string,
    usedTime: number,
    tasksDone: string[],
    materials: string,
    defects: string[],
    comments: string[],
    cannotTakeSignature: boolean,
    signature: string,
    reportData: any,
    operatorData: any,
    plantData: any,
    price: number | null,
    hk: number
  ): Promise<string> {
    this.updateOnlineState();

    const allLocalWorkOrders = await this.offlineService.getAllLocalWorkOrders();
    const hasWorkOrderInLocalStorage = allLocalWorkOrders.some(
      wo => wo.id === id
    );

    const service =
      (processingState === "OPEN" || processingState === "TO_CHECK") &&
      hasWorkOrderInLocalStorage
        ? this.offlineService
        : this.onlineService;

    return service.upsertWorkOrder(
      id,
      processingState,
      serviceEngineerId,
      usedTime,
      tasksDone,
      materials,
      defects,
      comments,
      cannotTakeSignature,
      signature,
      reportData,
      operatorData,
      plantData,
      price,
      hk,
      null
    );
  }

  public acceptWorkOrder(
    id: string,
    price: number,
    hk: number,
    doneTasks: string[]
  ): Promise<string> {
    this.updateOnlineState();
    return this.service.acceptWorkOrder(id, price, hk, doneTasks);
  }

  public rejectWorkOrder(
    id: string,
    price: number,
    hk: number,
    doneTasks: string[],
    reason: string
  ): Promise<string> {
    this.updateOnlineState();
    return this.service.rejectWorkOrder(id, price, hk, doneTasks, reason);
  }

  public async getPlantParameters(plantId: string): Promise<any> {
    try {
      const parameters = await this.offlineService.getPlantParameters(plantId);

      return parameters;
    } catch {
      return this.service.getPlantParameters(plantId);
    }
  }

  public uploadLabReport(workOrderId: string, file: File): Promise<string> {
    this.updateOnlineState();
    return this.service.uploadLabReport(workOrderId, file);
  }

  public setWorkOrderToCheck(workOrderId: string): Promise<string> {
    this.updateOnlineState();
    return this.service.setWorkOrderToCheck(workOrderId);
  }

  public setWorkOrderFinished(workOrderId: string): Promise<string> {
    this.updateOnlineState();
    return this.service.setWorkOrderFinished(workOrderId);
  }

  public invoiceWorkOrder(workOrderId: string): Promise<string> {
    this.updateOnlineState();
    return this.service.invoiceWorkOrder(workOrderId);
  }

  public archiveWorkOrder(workOrderId: string): Promise<string> {
    this.updateOnlineState();
    return this.service.archiveWorkOrder(workOrderId);
  }

  public cancelWorkOrder(
    workOrderId: string,
    cancellationReason: string
  ): Promise<string> {
    this.updateOnlineState();
    return this.service.cancelWorkOrder(workOrderId, cancellationReason);
  }

  public sendWorkOrders(workOrderId?: string): Promise<any> {
    this.updateOnlineState();
    return this.service.sendWorkOrders(workOrderId);
  }

  public async download(arg?: ServiceArgument) {
    let workOrders: WorkOrder[] = [];
    if (!arg || !arg.ids) {
      workOrders = await this.onlineService.getAllWorkOrders(Auth.userId);
    } else {
      const promises = [];
      for (const id of arg.ids) {
        promises.push(
          this.onlineService
            .getWorkOrderById(id)
            .then(result => workOrders.push(result))
        );
      }

      await Promise.all(promises);
    }

    this.offlineService.workOrders = workOrders;
    const storedWorkOrders = await this.offlineService.getAllWorkOrders();

    arg?.subsequentService?.download({
      ids: storedWorkOrders.filter(wo => !!wo.plantId).map(wo => wo.plantId)
    });
  }

  public upload() {
    for (const workOrderFlaggedEntity of this.offlineService.getToSyncWorkOrders()) {
      const workOrder = workOrderFlaggedEntity.entity;
      this.onlineService.upsertWorkOrder(
        workOrder.id,
        workOrder.processingState,
        workOrder.serviceEngineerId,
        workOrder.usedTime,
        workOrder.tasksDone,
        JSON.stringify(workOrder.materials),
        workOrder.defects,
        workOrder.comments,
        workOrder.cannotTakeSignature,
        workOrder.signature,
        workOrder.reportData,
        workOrderFlaggedEntity.operatorData,
        workOrderFlaggedEntity.plantData,
        workOrder.price,
        workOrder.hk,
        DateUtils.getDate(workOrder.updatedAt).toISOString()
      );
      workOrderFlaggedEntity.changed = false;
    }
    this.offlineService.save();
  }

  private updateOnlineState() {
    if (OnlineCheckerFactory.isOnline) {
      this.service = this.onlineService;
    } else {
      this.service = this.offlineService;
    }
  }
}

export interface IWorkOrderService {
  getAllOpenWorkOrdersForServiceEngineer(userId: string): Promise<WorkOrder[]>;
  getAllWorkOrders(id: string): Promise<WorkOrder[]>;
  getWorkOrderProcessingStates(ids: string[]): Promise<any>;
  getAllOpenWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>>;
  getAllToCheckWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>>;
  getAllFinishedWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>>;
  getAllToInvoiceWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>>;
  getAllArchivedWorkOrders(
    id: string,
    search: string,
    pagination: Pagination,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>>;
  getAllWorkOrdersPaginated(
    page: Page,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>>;
  getAllFinishedWorkOrdersWithoutLabReport(week?: string): Promise<WorkOrder[]>;
  getAllFinishedWorkOrdersWithLabReport(week?: string): Promise<WorkOrder[]>;
  sendReportsToLab(input: any): Promise<string>;
  sendReportsToBh(workOrderIds: string[]): Promise<any>;
  getAllWorkOrdersWithReport(): Promise<WorkOrder[]>;
  getAllWorkOrdersWithReportPaginated(
    page: Page,
    order: OrderByClause[]
  ): Promise<PaginatedList<WorkOrder>>;
  getWorkOrderById(workOrderId: string): Promise<WorkOrder>;
  getWorkOrdersInDateRange(
    serviceEngineerId: string,
    dateFrom: string,
    dateTo: string
  ): Promise<WorkOrder[]>;
  upsertWorkOrder(
    id: string,
    processingState: string,
    serviceEngineerId: string,
    usedTime: number,
    tasksDone: string[],
    materials: string,
    defects: string[],
    comments: string[],
    cannotTakeSignature: boolean,
    signature: string,
    reportData: any,
    operatorData: any,
    plantData: any,
    price: number | null,
    hk: number,
    updatedAt: string | null
  ): Promise<string>;
  acceptWorkOrder(
    id: string,
    price: number,
    hk: number,
    doneTasks: string[]
  ): Promise<string>;
  rejectWorkOrder(
    id: string,
    price: number,
    hk: number,
    doneTasks: string[],
    reason: string
  ): Promise<string>;
  getPlantParameters(plantId: string): Promise<PlantParameter[]>;
  uploadLabReport(workOrderId: string, file: File): Promise<string>;
  setWorkOrderToCheck(workOrderId: string): Promise<string>;
  setWorkOrderFinished(workOrderId: string): Promise<string>;
  invoiceWorkOrder(workOrderId: string): Promise<string>;
  archiveWorkOrder(workOrderId: string): Promise<string>;
  cancelWorkOrder(
    workOrderId: string,
    cancellationReason: string
  ): Promise<string>;
  sendWorkOrders(workOrderId?: string): Promise<any>;
}

export interface WorkOrder {
  id: string;
  processingState: string;
  estimatedTime: number;
  usedTime: number;
  tasksDone: string[];
  materials: { [key: string]: number };
  defects: string[];
  comments: string[];
  cannotTakeSignature: boolean;
  signature: string;
  addressStreet: string;
  addressZip: string;
  addressCity: string;
  addressCountry: string;
  addressState: string;
  addressLat: number;
  addressLng: number;
  plannedDate: Date;
  reportData: any;
  plantType: string;
  plantIndex: number;
  plantId: string;
  sampleNeeded: boolean;
  reason: string;
  serviceEngineerId: string;
  serviceEngineerName: string;
  price: number | null;
  hk: number;
  remarks: string;
  dateSentToApproval: Date | undefined;
  createdAt: Date;
  updatedAt: Date;
  syncing?: boolean;
}

export interface Pagination {
  itemsPerPage: number;
  pageNumber: number;
  totalItems: number;
}
