import StoreItemsListView from "./views/StoreItemsListView.vue";
import StoreMovementsView from "./views/StoreMovementsView.vue";
import ItemTemplateView from "./views/ItemTemplateView.vue";
import StoreView from "./views/StoreView.vue";
import SupplierView from "./views/SupplierView.vue";
import StockTakingOverviewView from "./views/StockTakingOverviewView.vue";
import MaterialRequestOverviewView from "./views/MaterialRequestOverviewView.vue";
import BarcodeScannerView from "./views/BarcodeScannerView.vue";
import SupplierOrderOverviewView from "./views/SupplierOrderOverviewView.vue";

import CreateSupplierView from "./views/CreateSupplierView.vue";
import CreateStoreView from "./views/CreateStoreView.vue";
import CreateStoreMovementView from "./views/CreateStoreMovementView.vue";
import CreateStockTakingView from "./views/CreateStockTakingView.vue";
import CreateMaterialRequestView from "./views/CreateMaterialRequestView.vue";
import CreateSupplierOrderView from "./views/CreateSupplierOrderView.vue";

export default [
  {
    path: "/store/list",
    name: "store-list",
    component: StoreItemsListView
  },
  {
    path: "/store/movements/:storeId?/:itemTemplateId?",
    name: "store-movements",
    component: StoreMovementsView
  },
  {
    path: "/store/movements/create/:itemTemplateId?/:plantIndex?",
    name: "store-movement-create",
    component: CreateStoreMovementView
  },
  {
    path: "/item-templates",
    name: "item-templates",
    component: ItemTemplateView
  },
  {
    path: "/stores",
    name: "stores",
    component: StoreView,
    children: [
      {
        path: "create",
        name: "store-create",
        component: CreateStoreView
      }
    ]
  },
  {
    path: "/store-suppliers",
    name: "store-suppliers",
    component: SupplierView,
    children: [
      {
        path: "create",
        name: "supplier-create",
        component: CreateSupplierView
      }
    ]
  },
  {
    path: "/stock-taking/:stockTakingId?",
    name: "stock-taking",
    component: CreateStockTakingView
  },
  {
    path: "/stock-taking-overview",
    name: "stock-taking-overview",
    component: StockTakingOverviewView
  },
  {
    path: "/material-request/:materialRequestId?",
    name: "material-request",
    component: CreateMaterialRequestView
  },
  {
    path: "/material-request-overview",
    name: "material-request-overview",
    component: MaterialRequestOverviewView
  },
  {
    path: "/barcode-scanner",
    name: "barcode-scanner",
    component: BarcodeScannerView
  },
  {
    path: "/supplier-order-overview",
    name: "supplier-order-overview",
    component: SupplierOrderOverviewView
  },
  {
    path: "/supplier-order-create/:supplierId/:supplierOrderId?",
    name: "supplier-order-create",
    component: CreateSupplierOrderView
  }
];
