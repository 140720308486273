import { EmployeeDetailViewModel } from "../vms/EmployeeDetailViewModel";
import { IEmployeeDetailPresenter } from "../controllers/EmployeeDetailController";
import { ObjectUtils } from "@/utils/ObjectUtils";
import { EmployeeForm } from "../forms/EmployeeForm";
import { EmployeePhoneNumberForm } from "../forms/EmployeePhoneNumberForm";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { FormResponse } from "@/forms/FormResponse";
import { UserRoleList } from "../services/UserRoleService";
import { Employee } from "../services/EmployeeService";
import { Auth } from "@/common/utils/Auth";

export class EmployeeDetailPresenter implements IEmployeeDetailPresenter {
  public employeeForm: EmployeeForm;
  public phoneNumberForms: EmployeePhoneNumberForm[] = [];

  public constructor(
    private vm: EmployeeDetailViewModel,
    private storage: SimpleStringStorage
  ) {
    this.employeeForm = new EmployeeForm(this.vm, this.formValidated);
    this.employeeForm.addFieldChangedListener(
      "country",
      this.countrySelected,
      this
    );
    this.employeeForm.init();

    this.initSelections();
    this.initStaticTexts();
    this.updateDynamicTexts();
  }

  public set loadUserRolesResponse(response: FormResponse<UserRoleList>) {
    this.vm.loadPageRequest = response;

    if (response.success) {
      this.vm.role.items = response.data.roles.map(role => ({
        text: role.name,
        value: role.id
      }));
    }

    this.updateDynamicTexts();
  }

  public set loadEmployeeResponse(response: FormResponse<Employee>) {
    this.vm.loadPageRequest = response;

    if (response.success) {
      this.vm.deleteEmployeeButtonVisible = response.data.id !== Auth.userId;
      this.employee = response.data;
    }

    this.updateDynamicTexts();
  }

  public set saveEmployeeResponse(response: FormResponse<string>) {
    this.vm.saveEmployeeRequest = response;

    if (response.success) {
      this.vm.showEmployeeList = true;
    }

    this.updateDynamicTexts();
  }

  public set deleteEmployeeResponse(response: FormResponse<string>) {
    this.vm.deleteEmployeeRequest = response;

    if (response.success) {
      this.vm.showEmployeeList = true;
    }

    this.updateDynamicTexts();
  }

  public set reactivateEmployeeResponse(response: FormResponse<string>) {
    this.vm.reactivateEmployeeRequest = response;

    if (response.success) {
      this.vm.showEmployeeList = true;
    }

    this.updateDynamicTexts();
  }

  public addPhoneNumber() {
    const newPhoneNumber = ObjectUtils.deepCopy(basePhoneNumber);
    const newForm = new EmployeePhoneNumberForm(
      newPhoneNumber,
      this.phoneNumberFormValidated,
      this.employeeForm
    );
    newForm.init();

    this.vm.phoneNumbers.push(newPhoneNumber);
    this.phoneNumberForms.push(newForm);
    this.employeeForm.addSubForm(newForm);
    this.employeeForm.validateForm();
  }

  public deletePhoneNumber(index: number) {
    this.vm.phoneNumbers.splice(index, 1);
    this.phoneNumberForms.splice(index, 1);
    this.employeeForm.removeSubFormByIndex(index);
    this.employeeForm.validateForm();
  }

  public set signature(signature: string) {
    this.vm.signature = signature;
  }

  public get employee(): Employee {
    return {
      id: this.vm.employeeId,
      active: this.vm.employeeActive,
      abbreviation: this.vm.abbreviation.value,
      salutation: this.vm.salutation.selected,
      firstname: this.vm.firstname.value,
      lastname: this.vm.lastname.value,
      email: this.vm.email.value,
      svnr: this.vm.svnr.value.toString(),
      birthday: this.vm.birthday.value,
      dateOfJoining: this.vm.dateOfJoining.value,
      address: this.vm.address.value,
      zip: this.vm.zip.value,
      city: this.vm.city.value,
      country: this.vm.country.selected,
      state: this.vm.state.selected,
      username: this.vm.username.value,
      password: this.vm.password.value,
      phoneNumbers: this.vm.phoneNumbers.map(num => ({
        areaCode: num.areaCode.selected,
        phoneNumber: num.number.value,
        annotation: num.annotation.value
      })),
      roleId: this.vm.role.selected,
      signature: this.vm.signature
    };
  }

  public set employee(data: Employee) {
    this.vm.employeeId = data.id;
    this.vm.employeeActive = data.active;
    this.employeeForm.setFieldValue("abbreviation", data.abbreviation);
    this.employeeForm.setFieldValue("salutation", data.salutation);
    this.employeeForm.setFieldValue("firstname", data.firstname);
    this.employeeForm.setFieldValue("lastname", data.lastname);
    this.employeeForm.setFieldValue("email", data.email);
    this.employeeForm.setFieldValue("svnr", data.svnr);
    this.employeeForm.setFieldValue("birthday", data.birthday);
    this.employeeForm.setFieldValue("dateOfJoining", data.dateOfJoining);

    this.employeeForm.setFieldValue("address", data.address);
    this.employeeForm.setFieldValue("zip", data.zip);
    this.employeeForm.setFieldValue("city", data.city);
    this.employeeForm.setFieldValue("country", data.country);
    this.employeeForm.setFieldValue("state", data.state);
    this.employeeForm.setFieldValue("role", data.roleId);

    this.vm.signature = data.signature;

    data.phoneNumbers.map((num, index) => {
      this.addPhoneNumber();
      const newNumberForm = this.phoneNumberForms[index];
      newNumberForm.setFieldValue("areaCode", num.areaCode);
      newNumberForm.setFieldValue("number", num.phoneNumber);
      newNumberForm.setFieldValue("annotation", num.annotation);
    });
  }

  private formValidated(context: any, valid: boolean) {
    context.saveEmployeeButtonDisabled = !valid;
  }

  private phoneNumberFormValidated(context: any, valid: boolean) {
    // Validation logic
  }

  private countrySelected(context: any, value: string) {
    const selectedCountry = JSON.parse(context.storage.get("Land")).find(
      (country: any) => country.id === value
    );

    if (!!selectedCountry) {
      context.vm.state.items = selectedCountry.values.Bundesländer.map(
        (state: any) => ({
          text: state.Bezeichnung,
          value: state.Bezeichnung
        })
      );
    }
  }

  private initSelections() {
    if (this.storage.has("Anrede")) {
      const salutations = JSON.parse(this.storage.get("Anrede"));
      this.vm.salutation.items = salutations.map((salutation: any) => ({
        text: salutation.values.Langform,
        value: salutation.id
      }));
    }

    if (this.storage.has("Land")) {
      const countries = JSON.parse(this.storage.get("Land"));

      this.vm.country.items = countries.map((country: any) => ({
        text: country.values.Bezeichnung,
        value: country.id
      }));

      basePhoneNumber.areaCode.items = countries.map((country: any) => ({
        text: country.values.Vorwahl,
        value: country.id
      }));
    }
  }

  private initStaticTexts() {
    this.vm.deleteEmployeeButtonText = "Löschen";
    this.vm.reactivateEmployeeButtonText = "Wiederherstellen";
    this.vm.personalDataTitle = "Personendaten";
    this.vm.addressTitle = "Wohnort";
    this.vm.phoneNumbersTitle = "Telefonnummern";
    this.vm.userDataTitle = "Login-Daten";
    this.vm.signatureTitle = "Unterschrift";

    this.vm.abbreviation.label = "Kürzel";
    this.vm.salutation.label = "Anrede";
    this.vm.firstname.label = "Vorname";
    this.vm.lastname.label = "Nachname";
    this.vm.email.label = "Email";
    this.vm.svnr.label = "SVNR";
    this.vm.birthday.label = "Geburtsdatum";
    this.vm.dateOfJoining.label = "Eintrittsdatum";

    this.vm.address.label = "Adresse";
    this.vm.zip.label = "PLZ";
    this.vm.city.label = "Ort";
    this.vm.country.label = "Land";
    this.vm.state.label = "Bundesland";

    this.vm.username.label = "Username";
    this.vm.password.label = "Passwort";
    this.vm.role.label = "Rolle";

    this.vm.signatureDescription = "Bitte unterschreiben:";

    this.vm.addPhoneNumberButtonText = "Nummer hinzufügen";
    this.vm.deletePhoneNumberButtonText = "Nummer entfernen";
    this.vm.deleteEmployeeConfirmTitle = "Mitarbeiter wirklich löschen?";
    this.vm.deleteEmployeeConfirmText =
      "Wenn Sie den Mitarbeiter löschen, werden alle offenen Termine des Mitarbeiters wieder zurückgesetzt. Diese müssen in der Tourenplanung einem anderen Mitarbeiter erneut zugewiesen werden.";
    this.vm.reactivateEmployeeConfirmTitle =
      "Mitarbeiter wirklich wiederherstellen?";
    this.vm.reactivateEmployeeConfirmText =
      "Wollen Sie den Mitarbeiter wirklich wiederherstellen?";
  }

  private updateDynamicTexts() {
    this.vm.title = !!this.vm.employeeId
      ? "Mitarbeiter bearbeiten"
      : "Mitarbeiter erstellen";
    this.vm.saveEmployeeButtonText = !!this.vm.employeeId
      ? "Speichern"
      : "Erstellen";
  }
}

const basePhoneNumber = {
  areaCode: {
    label: "Vorwahl",
    error: "",
    selected: "",
    loading: false,
    items: []
  },
  number: {
    label: "Nummer",
    error: "",
    value: "",
    loading: false
  },
  annotation: {
    label: "Anmerkung",
    error: "",
    value: "",
    loading: false
  }
};
