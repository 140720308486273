import { Auth } from "@/common/utils/Auth";
import { Permission } from "@/common/utils/Permissions";
import { Selection } from "@/forms/ViewModelFormTypes";

export class ServiceEngineerWeeklyScheduleViewModel {
  public loading = true;
  public colorsLoading = true;

  public tourWeek: any = {};
  public timeFrameItems: Selection = {
    label: "Kalenderwochen",
    selected: "",
    error: "",
    items: []
  };
  public serviceEngineers: Selection = {
    label: "Service-Techniker",
    selected: "",
    error: "",
    items: []
  };
  public viewedTourDay: any = null;

  public get canSelectServiceEngineers() {
    return Auth.hasPermission(Permission.ApproveWorkOrders);
  }
}
