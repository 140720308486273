






















import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { PlantGraphQLService } from "@/plant/services/PlantGraphQLService";
import { Plant } from "@/plant/services/PlantService";
import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { Auth } from "../../../common/utils/Auth";
import StorageKeys from "../../../common/utils/StorageKeys";

@Component<PlantRemarksData>({
  components: {
    Card,
    Grid,
    Row,
    Column
  },
  props: ["plant", "plantRequest", "element"]
})
export default class PlantRemarksData extends Vue {
  private editMode = false;
  private loading = false;
  private plant!: Plant;

  private connection = new AxiosGraphQLConnection(
    new AxiosLogger(new ConsoleLogger())
  );
  private plantService = new PlantGraphQLService(this.connection);

  private retry(event: any) {
    this.$emit("retry", event);
  }

  private changeEditMode() {
    this.editMode = !this.editMode;
    if (!this.editMode) {
      this.updatePlantRemarks();
    }
  }

  private updatePlantRemarks() {
    this.loading = true;
    const request = this.plantService.updatePlantRemarks(this.plant);
    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading && !response.error) {
          this.retry(this.plant);
          this.loading = false;
          this.editMode = false;
        }
      },
      "update-plant-failed"
    );
  }

  private canEdit() {
    return Auth.hasPermission(StorageKeys.editPlantAndOperatorPermission);
  }
}
