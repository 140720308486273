import {
  Textfield,
  Numberfield,
  TableHeader
} from "@/forms/ViewModelFormTypes";
import { TimeTrackingDataViewModel } from "./TimeTrackingDataViewModel";

export class TimeTrackingCardViewModel {
  public expanded = false;
  public loading = false;

  public id: string = "";
  public date: string = "";
  public startTime: Textfield = {
    value: "",
    error: "",
    placeholder: "HH:MM",
    label: "Startzeit"
  };

  public endTime: Textfield = {
    value: "",
    error: "",
    placeholder: "HH:MM",
    label: "Endzeit"
  };
  public breakDuration = {
    value: 0.0,
    error: "",
    label: "Pausendauer in Stunden",
    items: [0.5, 1.0, 1.5, 2.0, 2.5, 3.0]
  };

  public maintenanceDataHeader: TableHeader[] = [
    { text: "Fahrt", value: "maintenanceDriveDuration" },
    { text: "Arbeit", value: "maintenanceWorkDuration" },
    { text: "Prämie", value: "maintenanceBonus" },
    { text: "Diäten", value: "maintenanceRegiments" }
  ];
  public othersDataHeader: TableHeader[] = [
    { text: "Fahrt", value: "othersDriveDuration" },
    { text: "Arbeit", value: "othersWorkDuration" },
    { text: "Prämie", value: "othersBonus" },
    { text: "Diäten", value: "othersRegiments" }
  ];
  public workOrderDataHeader: TableHeader[] = [
    { text: "Anlagennummer", value: "plantId", width: "100px" },
    { text: "Kunde", value: "operator", width: "200px" },
    { text: "Tätigkeiten", value: "doneTasks" },
    { text: "", value: "actions" }
  ];

  public data: TimeTrackingDataViewModel[] = [];
}
