import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { IOperatorDataTabPresenter } from "../controllers/OperatorDataTabController";
import { OperatorDataTabViewModel } from "../vms/OperatorDataTabViewModel";

export class OperatorDataTabPresenter implements IOperatorDataTabPresenter {
  public constructor(private vm: OperatorDataTabViewModel) {
    this.initSelections();
  }

  public operatorAddressCountrySelected(value: string): void {
    this.vm.operatorAddressState.items = CountriesStorageHandler.getStatesFromCountry(
      value
    );
  }

  public set data(workOrder: any) {
    if (workOrder) {
      this.vm.name = workOrder.operatorName;
      this.vm.phoneNumber = workOrder.operatorPhoneNumber;
      this.vm.operatorAddress = workOrder.operatorAddress;
      this.vm.requestedBy = workOrder.requestedBy;
      this.vm.billing.selected = workOrder.billing;
      this.vm.kilometers = workOrder.kilometers?.toString() || "";
      this.vm.offerRequested = workOrder.offerRequested;
    }
  }

  public get data() {
    const parsedCountry = CountriesStorageHandler.getCountryNameFromId(
      this.vm.operatorAddressCountry.selected
    );

    let operatorName = this.vm.name;
    if (!operatorName) {
      if (this.vm.company) {
        operatorName = this.vm.company;
      } else {
        operatorName = `${this.vm.lastName.toUpperCase()}, ${
          this.vm.firstName
        }`;
      }
    }

    return {
      operatorName,
      operatorPhoneNumber: this.vm.phoneNumber,
      operatorAddress:
        this.vm.operatorAddress ??
        `${this.vm.operatorAddressStreet}\n${this.vm.operatorAddressZip} ${this.vm.operatorAddressCity}\n${this.vm.operatorAddressState.selected}\n${parsedCountry}`,
      requestedBy: this.vm.requestedBy,
      billing: (this.vm.billing.selected as any).text
        ? (this.vm.billing.selected as any).text
        : this.vm.billing.selected,
      kilometers: parseFloat(this.vm.kilometers.toString()),
      offerRequested: this.vm.offerRequested
    };
  }

  private initSelections() {
    this.vm.operatorAddressCountry.items = CountriesStorageHandler.getAllCountries();
  }
}
