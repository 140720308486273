import { Auth } from "@/common/utils/Auth";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { DateUtils } from "@/utils/DateUtils";

export class CreateStoreMovementViewModel {
  public visibleView = false;
  public isEdit = false;

  public direction: { selected: string; items: SelectionItem[] } = {
    selected: "UMBUCHUNG",
    items: [
      { text: "Umbuchung", value: "UMBUCHUNG" },
      { text: "Korrektur", value: "KORREKTUR" }
    ]
  };

  public employee: { selected: string; items: SelectionItem[] } = {
    selected: Auth.userId,
    items: []
  };
  public date = {
    value: new Date().toISOString().substr(0, 10),
    text: DateUtils.format(new Date())
  };

  public source: { selected?: string; items: SelectionItem[] } = {
    selected: undefined,
    items: []
  };
  public store: { selected: string; items: SelectionItem[] } = {
    selected: "",
    items: []
  };
  public plant: any = {};
  public plantIndex = "";
  public plantAddressLoading = false;
  public plantAddressError = "";
  public plantAddress = "";
  public deliveryWay: {
    selected: string;
    items: SelectionItem[];
  } = {
    selected: "DIRECT",
    items: [
      { text: "Direktübergabe", value: "DIRECT" },
      { text: "Post", value: "MAIL" }
    ]
  };
  public itemTemplate: {
    selected: string;
    items: SelectionItem[];
  } = {
    selected: "",
    items: []
  };
  public currentAmount = "0";
  public currentSourceAmount = "0";

  public amount = "";
  public comment = "";

  public loading = false;
  public error = "";
}
