
























































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import ServerDatatable from "@/components/collection/ServerDatatable.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import { SupplierOrderOverviewViewModel } from "@/store/vms/SupplierOrderOverviewViewModel";
import { SupplierOrderOverviewPresenter } from "@/store/presenters/SupplierOrderOverviewPresenter";
import { SupplierOrderOverviewController } from "@/store/controllers/SupplierOrderOverviewController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { SupplierGraphQLService } from "../services/SupplierGraphQLService";

@Component<SupplierOrderOverviewView>({
  components: {
    Page,
    Section,
    ServerDatatable,
    Card,
    Row,
    Column,
    Container
  }
})
export default class SupplierOrderOverviewView extends Vue {
  private vm = new SupplierOrderOverviewViewModel();
  private controller?: SupplierOrderOverviewController;

  private initController(): SupplierOrderOverviewController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new SupplierOrderOverviewController(
      new SupplierOrderOverviewPresenter(this.vm),
      new SupplierGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }

  private openSupplierOrder(item: any) {
    this.$router.push({
      name: "supplier-order-create",
      params: { supplierId: item.supplierId, supplierOrderId: item.id }
    });
  }
}
