






































import Vue from "vue";
import Component from "vue-class-component";

import Accordion from "@/components/layout/Accordion.vue";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";

import { PlantSizesAccordionViewModel } from "@/report/vms/PlantSizesAccordionViewModel";
import { PlantSizesAccordionPresenter } from "@/report/presenters/PlantSizesAccordionPresenter";
import { PlantSizesAccordionController } from "@/report/controllers/PlantSizesAccordionController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";

@Component<PlantSizesAccordion>({
  components: {
    Accordion,
    Container,
    Column,
    Row,
    ValidatedTextfield
  },
  props: {
    plantType: {
      type: String
    },
    editEnabled: {
      type: Boolean
    },
    plantSizes: {
      type: Array
    },
    shown: Boolean
  }
})
export default class PlantSizesAccordion extends Vue {
  public vm = new PlantSizesAccordionViewModel();
  private controller?: PlantSizesAccordionController;

  private initController(): PlantSizesAccordionController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new PlantSizesAccordionController(
      new PlantSizesAccordionPresenter(this.vm)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$props.plantType, this.$props.plantSizes);
  }
}
