import { FormResponse } from "@/forms/FormResponse";
import { PlantGroupStorageHandler } from "@/storage/storageHandlers/PlantGroupStorageHandler";
import {
  Report,
  ReportStorageHandler
} from "@/storage/storageHandlers/ReportStorageHandler";
import { DateUtils } from "@/utils/DateUtils";
import { CreateWorkOrderViewModel } from "../vms/CreateWorkOrderViewModel";
import { ParametersAccordionViewModel } from "../vms/ParametersAccordionViewModel";
import { PlantSizesAccordionViewModel } from "../vms/PlantSizesAccordionViewModel";
import { ReportAccordionViewModel } from "../vms/ReportAccordionViewModel";
import { SampleAccordionViewModel } from "../vms/SampleAccordionViewModel";

export class ReportTabController {
  private plantSizesViewModel?: PlantSizesAccordionViewModel;
  private parametersViewModel?: ParametersAccordionViewModel;
  private parametersIntakeViewModel?: ParametersAccordionViewModel;
  private reportViewModel?: ReportAccordionViewModel;
  private sampleViewModel?: SampleAccordionViewModel;

  public constructor(
    private presenter: IReportTabPresenter,
    private parentVm: CreateWorkOrderViewModel,
    plantType: string
  ) {
    const reportId = PlantGroupStorageHandler.getTypeReportIdFromId(plantType);
    const reportResponse = ReportStorageHandler.getReportObjectFromReportId(
      reportId
    );

    this.presenter.reportResponse = reportResponse;
  }

  public mounted(
    plantSizesViewModel: PlantSizesAccordionViewModel,
    parametersViewModel: ParametersAccordionViewModel,
    parametersIntakeViewModel: ParametersAccordionViewModel,
    reportViewModel: ReportAccordionViewModel,
    sampleViewModel: SampleAccordionViewModel,
    reportData: any
  ) {
    this.plantSizesViewModel = plantSizesViewModel;
    this.parametersViewModel = parametersViewModel;
    this.parametersIntakeViewModel = parametersIntakeViewModel;
    this.reportViewModel = reportViewModel;
    this.sampleViewModel = sampleViewModel;

    if (reportData && reportData.sampleData) {
      this.presenter.cannotTakeSample = reportData.sampleData.cannotTakeSample;
      this.presenter.substituteDate = reportData.sampleData.substituteDate;
      this.presenter.inspectionOnly = reportData.sampleData.inspectionOnly;
    }
  }

  public get data() {
    return this.presenter.cannotTakeSample
      ? {
          sampleData: {
            cannotTakeSample: this.presenter.cannotTakeSample,
            substituteDate: this.presenter.substituteDate
          }
        }
      : {
          plantSizes: this.plantSizes,
          parameters: this.parameters,
          parametersIntake: this.parametersIntake,
          sections: this.sections,
          sampleData: this.sampleData
        };
  }

  private get plantSizes() {
    return (this.plantSizesViewModel?.plantSizes ?? []).map(obj => ({
      name: obj.name,
      id: obj.id,
      value: obj.value
    }));
  }

  private get parameters() {
    return {
      enabled: this.parametersViewModel?.enabled,
      parameters: (this.parametersViewModel?.parameters ?? []).map(obj => ({
        id: obj.id,
        value: obj.value
      }))
    };
  }

  private get parametersIntake() {
    return {
      enabled: this.parametersIntakeViewModel?.enabled,
      parameters: (this.parametersIntakeViewModel?.parameters ?? []).map(
        obj => ({
          id: obj.id,
          value: obj.value
        })
      )
    };
  }

  private get sections() {
    const sections = [];

    for (const section of this.reportViewModel?.sections ?? []) {
      const fields = [];
      for (const fieldKey of Object.keys(section.fields)) {
        const field = section.fields[fieldKey] as any;
        const parsedField = {
          name: field.name,
          suffix: field.suffix,
          prefix: field.prefix,
          enabled: field.enabled,
          value: ""
        };
        if (field.enabled) {
          if (
            field.component === "ValidatedSelect" ||
            field.component === "ValidatedCombobox"
          ) {
            if (field.value && field.value.text) {
              parsedField.value = field.value.text;
            } else {
              parsedField.value = field.value;
            }
          } else if (field.component === "ValidatedDatepicker") {
            if (field.value && field.value.value) {
              parsedField.value = DateUtils.toISOString(field.value.value);
            }
          } else if (field.component === "ValidatedMonthpicker") {
            if (field.value && field.value.value) {
              parsedField.value = DateUtils.toISOStringMonthOnly(
                field.value.value
              );
            }
          } else if (field.component === "ValidatedSwitch") {
            parsedField.value = field.value ? "true" : "false";
          } else {
            parsedField.value = field.value;
          }
        }
        fields.push(parsedField);
      }
      sections.push({ name: section.name, enabled: section.enabled, fields });
    }

    return sections;
  }

  private get sampleData() {
    let sampleTime = this.sampleViewModel?.sampleTime;
    if (this.parentVm.workOrderNoPriceTab) {
      const dateTill = DateUtils.getDate(this.parentVm.workOrderDate);
      dateTill.setHours(0, 0, 0, 0);
      dateTill.setMinutes(
        DateUtils.timeToNumber(this.sampleViewModel?.sampleTimeFrom.text ?? "0")
      );
      dateTill.setMinutes(
        dateTill.getMinutes() +
          parseInt(this.parentVm.usedTime.value.toString(), 10)
      );

      sampleTime = DateUtils.format(this.parentVm.workOrderDate, "DD.MM.YYYY");
      sampleTime += ` ${this.sampleViewModel?.sampleTimeFrom.text}`;
      sampleTime += `-${DateUtils.format(dateTill, "HH:mm")}`;
    }

    return {
      cannotTakeSample: !!this.presenter.cannotTakeSample,
      inspectionOnly: !!this.presenter.inspectionOnly,
      substituteDate: this.presenter.substituteDate,
      sampleLocation: this.sampleViewModel?.sampleLocation.selected,
      sampleTime,
      sampleWeather: this.sampleViewModel?.sampleWeather.selected,
      sampleTemperature: this.sampleViewModel?.sampleTemperature,
      sampleIntakeEnabled: this.sampleViewModel?.sampleIntakeEnabled,
      sampleIntake: this.sampleViewModel?.sampleIntake.selected,
      sampleTaker: this.sampleViewModel?.sampleTaker,
      plantStatus: this.sampleViewModel?.plantStatus.selected,
      suggestedActions: this.sampleViewModel?.suggestedActions.selected
    };
  }
}

export interface IReportTabPresenter {
  cannotTakeSample: boolean;
  inspectionOnly: boolean;
  substituteDate: string;

  reportResponse: FormResponse<Report>;
}
