import { FormResponse } from "@/forms/FormResponse";
import { ISendReportToBhPresenter } from "../controllers/SendReportToBhController";
import { WorkOrder } from "../services/WorkOrderService";
import { SendReportToBhViewModel } from "../vms/SendReportToBhViewModel";

export class SendReportToBhPresenter implements ISendReportToBhPresenter {
  public constructor(private vm: SendReportToBhViewModel) {}

  public set getReportsResponse(response: FormResponse<WorkOrder[]>) {
    this.vm.tableLoading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.tableItems = response.data;
    }
  }

  public set sendReportsResponse(response: FormResponse<any>) {
    this.vm.requestLoading = response.loading;
    this.vm.requestError = response.error;

    if (!response.loading && !response.error) {
      if (!response.loading && !response.error) {
        if (response.data.hasFiles) {
          window.open(response.data.link, "_blank");
        }
      }
      this.vm.finished = true;
    }
  }

  public get ids(): string[] {
    return this.vm.tableItems.map(el => el.id);
  }

  public removeTableItem(id: string) {
    const itemIndex = this.vm.tableItems.findIndex(el => el.id === id);

    if (itemIndex >= 0) {
      this.vm.tableItems.splice(itemIndex, 1);
    }
  }
}
