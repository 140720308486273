import { PlantGraphQLService } from "../services/PlantGraphQLService";
import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { StringUtils } from "@/utils/StringUtils";

export class BmdPlantExport {
  public static async export() {
    const exporter = new BmdPlantExport();
    const exportedPlants = await exporter.getExportedPlants();
    exporter.downloadFile(exportedPlants);

    return {};
  }

  public plantService: PlantGraphQLService;

  public constructor() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    this.plantService = new PlantGraphQLService(connection);
  }

  private async getExportedPlants(): Promise<string> {
    return await this.plantService.exportPlants();
  }

  private downloadFile(exportedPlants: string) {
    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=ISO-8859-2," +
        StringUtils.escape_iso_8859_2(exportedPlants)
    );
    link.setAttribute("download", "BMD_ANLG.csv");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
