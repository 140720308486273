

































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import RequestButton from "@/components/basic/RequestButton.vue";

import { SystemTablesOverviewViewModel } from "@/system/vms/SystemTablesOverviewViewModel";
import { SystemTablesOverviewPresenter } from "@/system/presenters/SystemTablesOverviewPresenter";
import { SystemTablesOverviewController } from "@/system/controllers/SystemTablesOverviewController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { LocalStorage } from "@/storage/LocalStorage";

@Component<SystemTablesOverviewView>({
  components: {
    Page,
    Card,
    Container,
    Column,
    Row,
    RequestButton
  }
})
export default class SystemTablesOverviewView extends Vue {
  private vm = new SystemTablesOverviewViewModel();
  private controller?: SystemTablesOverviewController;

  private initController(): SystemTablesOverviewController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new SystemTablesOverviewController(
      new SystemTablesOverviewPresenter(this.vm),
      new LocalStorage()
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }

  private goToRoute(item: any) {
    this.$router.push({ name: "system-table", params: { tableId: item.id } });
  }
}
