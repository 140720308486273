























































































import Vue from "vue";
import Column from "@/components/layout/Column.vue";
import Row from "@/components/layout/Row.vue";
import Container from "@/components/layout/Container.vue";
import IconButton from "@/components/basic/IconButton.vue";

import SubmitTextfield from "@/components/basic/SubmitTextfield.vue";
import { OnlineCheckerFactory } from "../../common/utils/OnlineCheckerFactory";

export default Vue.extend({
  name: "Header",
  components: {
    Column,
    Row,
    Container,
    IconButton,
    SubmitTextfield
  },
  data() {
    return {
      expandSearch: false,
      searchText: ""
    };
  },
  props: {
    showRightSideBarButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    title() {
      return process.env.VUE_APP_TITLE;
    },
    subtitle() {
      return process.env.VUE_APP_SUBTITLE;
    },
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
    isMobile(): boolean {
      return !this.$vuetify.breakpoint.lgAndUp;
    },
    isOnline(): boolean {
      return this.$store.state.isOnline;
    }
  },
  methods: {
    navIconClicked() {
      this.$emit("navIconClicked");
    },
    emitSearch(searchText: string) {
      this.$emit("search", searchText);
    },
    emitChangeRightSidebar() {
      this.$emit("rightSideBarButtonClicked");
    },
    clearSearch() {
      this.searchText = "";
    }
  }
});
