






















































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";

import TabbedCard from "@/components/layout/TabbedCard.vue";
import Page from "@/components/layout/Page.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import ServerDatatable from "@/components/collection/ServerDatatable.vue";
import IconButton from "@/components/basic/IconButton.vue";
import CancelAppointmentDialog from "@/tourPlanner/views/CancelAppointmentDialog.vue";

import { WorkOrderListViewModel } from "@/report/vms/WorkOrderListViewModel";
import { WorkOrderListPresenter } from "@/report/presenters/WorkOrderListPresenter";
import { WorkOrderListController } from "@/report/controllers/WorkOrderListController";
import {
  WorkOrder,
  WorkOrderService
} from "@/report/services/WorkOrderService";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { PlantGraphQLService } from "../../plant/services/PlantGraphQLService";
import SubmitTextfield from "@/components/basic/SubmitTextfield.vue";

@Component<WorkOrderListView>({
  components: {
    Page,
    Card,
    Container,
    Column,
    Row,
    ServerDatatable,
    TabbedCard,
    IconButton,
    SubmitTextfield,
    CancelAppointmentDialog,
    WorkOrderMap: () => import("@/report/views/WorkOrderMap.vue")
  },
  watch: {
    "vm.reloadTab"(newValue: number) {
      if (newValue >= 0) {
        this.controller.loadTab(newValue);
        this.vm.reloadTab = -1;
      }
    }
  }
})
export default class WorkOrderListView extends Vue {
  private components: any[] = [];
  private vm = new WorkOrderListViewModel();
  private searchText = "";

  public goToWorkOrder(workOrderId: string) {
    this.$router.push({ name: "work-order", params: { workOrderId } });
  }

  protected downloadFile(path: string) {
    window.open(path, "_blank");
  }

  protected get controller(): WorkOrderListController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new WorkOrderListController(
      new WorkOrderListPresenter(this.vm),
      new WorkOrderService(connection),
      new PlantGraphQLService(connection)
    );
  }

  protected get isOnline(): boolean {
    return this.$store.state.isOnline;
  }

  protected get loading(): boolean {
    return (
      this.vm.openWorkOrdersLoading ||
      this.vm.toCheckWorkOrdersLoading ||
      this.vm.finishedWorkOrdersLoading ||
      this.vm.toInvoiceWorkOrdersLoading ||
      this.vm.archivedWorkOrdersLoading
    );
  }

  protected mounted() {
    this.controller.mounted();
  }

  protected openMap(workOrder: WorkOrder) {
    this.components.push({
      component: "WorkOrderMap",
      lat: workOrder.addressLat,
      lng: workOrder.addressLng
    });
  }

  protected goToCreateWorkOrder() {
    this.$router.push({ name: "create-work-order" });
  }
}
