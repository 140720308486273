















import Vue from "vue";
import Component from "vue-class-component";

// Don't rename to Switch or VSwitch, both don't work!!!
@Component<FormSwitch>({
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: false, error: "" };
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
    inset: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: String,
      default: "background"
    }
  }
})
export default class FormSwitch extends Vue {
  private get val() {
    return !!this.$props.value.value;
  }

  private emitChange(value: boolean) {
    this.$emit("change", !!value);
  }
}
