import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import de from "vuetify/src/locale/de";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de },
    current: "de"
  },
  icons: {
    iconfont: "md"
  },
  theme: {
    themes: {
      light: {
        primary: "#0081d9",
        secondary: "#2ab9d9",
        accent: "#d4d600",
        background: "#f0f0f0",
        surface: "#ffffff",
        error: "#b00020",
        on_primary: "#ffffff",
        on_secondary: "#ffffff",
        on_background: "#000000",
        on_surface: "#000000",
        on_error: "#ffffff"
      }
    },
    options: {
      customProperties: true,
    },
  }
});
