import {
  GraphQLConnection,
  GraphQLResponse,
  FieldValue,
  isFieldList,
  PaginatedGraphQLResponse,
  OrderByClause
} from "../GraphQLConnection";

import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from "axios";
import { ObjectUtils } from "@/utils/ObjectUtils";
import { Auth } from "@/common/utils/Auth";

export class AxiosGraphQLConnection implements GraphQLConnection {
  private axiosConnection: AxiosInstance;

  private logger: IAxiosLogger;

  constructor(logger: IAxiosLogger, baseUrl?: string) {
    this.logger = logger;

    const authHeader = process.env.VUE_APP_AUTH_HEADER || "Authorization";
    const headers: any = {};
    headers[authHeader] = Auth.token;

    this.axiosConnection = axios.create({
      baseURL: baseUrl || process.env.VUE_APP_BACKEND,
      timeout: 30000,
      headers
    });
  }

  public queryPaginated(
    name: string,
    count: number,
    page: number,
    fields: FieldValue[],
    search: string = "",
    searchFields: string[] = [],
    orderBy?: OrderByClause[],
    params?: object
  ): Promise<PaginatedGraphQLResponse> {
    const optionalSearch = !!search ? search : undefined;
    const optionalSearchFields =
      searchFields.length > 0 ? searchFields : undefined;
    const order = orderBy
      ? orderBy.map(ob => ({ field: ob.field, order: ob.order }))
      : undefined;

    const paginatedFields = [
      { name: "data", fields },
      { name: "paginatorInfo", fields: ["lastPage"] }
    ];

    return this.query(
      name,
      {
        search: optionalSearch,
        searchFields: optionalSearchFields,
        first: count,
        page,
        orderBy: order,
        ...params
      },
      paginatedFields
    ).then(response => this.extractPaginatedResponse(count, response));
  }

  public query(
    name: string,
    params: object,
    fields: FieldValue[]
  ): Promise<GraphQLResponse> {
    return this.request("query", name, params, fields);
  }

  public mutation(
    name: string,
    params: object,
    fields?: FieldValue[],
    file?: File
  ): Promise<GraphQLResponse> {
    if (!fields) {
      fields = ["id", "error", "stack"];
    }
    return this.request("mutation", name, params, fields, file);
  }

  public uploadFile(
    file: File,
    path: string,
    name: string,
    fields: FieldValue[],
    id: string = ""
  ): Promise<GraphQLResponse> {
    if (!file) {
      throw new Error("file-upload-is-null");
    }
    const convertedFields = this.convertFieldsToString(fields);

    const o = {
      query: `mutation ($file: Upload!) {
      upload (file: $file, path: "${path}", filename: "${name}", id: ${id}) {
        ${convertedFields}
      }
    }`,
      variables: {
        file: null
      }
    };
    const map = {
      0: ["variables.file"]
    };
    const data = new FormData();
    data.append("operations", JSON.stringify(o));
    data.append("map", JSON.stringify(map));
    data.append("0", file);

    return this.mutation("upload", data);
  }

  private request(
    method: string,
    name: string,
    params: object,
    fields: FieldValue[],
    file?: File
  ): Promise<GraphQLResponse> {
    const convertedParams = this.convertParamsToString(params);
    const convertedFields = this.convertFieldsToString(fields);

    let query: any;
    if (!file) {
      query = this.buildQuery(
        method,
        "",
        name,
        convertedParams,
        convertedFields
      );
    } else {
      query = this.buildFileQuery(
        method,
        "",
        name,
        convertedParams,
        convertedFields,
        file
      );
    }

    this.logger.logRequest(query);
    return this.axiosConnection
      .post("", query)
      .then(response => {
        this.logger.logResponse(response);
        return response.data;
      })
      .then(response => {
        response = this.buildResponse(name, response.data, response.errors);
        if (!response.data) {
          throw new Error(response.errors[0].message);
        }
        return response;
      })
      .then(response => {
        if (!response.data) {
          throw new Error("no-data-found");
        }
        if (!!response.data.error) {
          throw new Error(response.data.error);
        }
        return response;
      })
      .catch(reason => {
        this.logger.logError(reason);
        throw new Error(reason.message);
      });
  }

  private convertParamsToString(params: object) {
    let convertedParams = ObjectUtils.stringify(params);
    convertedParams = convertedParams.replace(
      /.*(?=undefined)undefined,?/g,
      ""
    );
    convertedParams = convertedParams.substring(1, convertedParams.length - 1);
    convertedParams =
      convertedParams.trim() === ""
        ? convertedParams
        : "(" + convertedParams + ")";

    convertedParams = convertedParams.replace(/"ASC"/g, "ASC");
    convertedParams = convertedParams.replace(/"DESC"/g, "DESC");

    return convertedParams;
  }

  private convertFieldsToString(fields: FieldValue[]) {
    let convertedFields = "";

    fields.forEach(field => {
      if (isFieldList(field)) {
        let subfields = this.convertFieldsToString(field.fields);
        if (field.fragment) {
          subfields = `... on ${field.fragment} {
            ${subfields}
          }`;
        }

        convertedFields += field.name;
        convertedFields += `{
          ${subfields}
        }`;
      } else {
        convertedFields += field + "\n";
      }
    });

    return convertedFields;
  }

  private buildQuery(
    operationType: string,
    operationName: string,
    type: string,
    params: string,
    fields: string
  ) {
    const body = !!fields ? `{ ${fields} }` : "";

    return {
      query: `
      ${operationType} ${operationName} {
        ${type}${params} ${body}
      }`
    };
  }

  private buildFileQuery(
    operationType: string,
    operationName: string,
    type: string,
    params: string,
    fields: string,
    file: File
  ) {
    const body = !!fields ? `{ ${fields} }` : "";
    params = params.substr(1, params.length - 2);

    const o = {
      query: `${operationType} ($file: Upload!) {
        ${type} (file: $file, ${params}) ${body}
      }`,
      variables: {
        file: null
      }
    };

    const map = {
      0: ["variables.file"]
    };
    const data = new FormData();
    data.append("operations", JSON.stringify(o));
    data.append("map", JSON.stringify(map));
    data.append("0", file);

    return data;
  }

  private extractPaginatedResponse(count: number, response: GraphQLResponse) {
    return {
      data: response.data.data,
      errors: response.errors,
      count: response.data.paginatorInfo.lastPage * count
    };
  }

  private buildResponse(name: string, data: any, errors: any) {
    return {
      data: data[name],
      errors
    };
  }
}

export interface IAxiosLogger {
  logRequest(query: string): void;
  logResponse(response: AxiosResponse<any>): void;
  logError(reason: any): void;
}
