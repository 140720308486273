import {
  ServiceEngineer,
  TourDataInput
} from "@/tourPlanner/services/ServiceEngineerGraphQLService";
import { Textfield, Selection, Time } from "@/forms/ViewModelFormTypes";
import { TourDataForm } from "@/tourPlanner/forms/TourDataForm";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { DateUtils } from "@/utils/DateUtils";
import { StringUtils } from "@/utils/StringUtils";

export class TourDataVMEntity {
  public static from(serviceEngineer: ServiceEngineer): TourDataVMEntity {
    return new TourDataVMEntity(
      serviceEngineer.id,
      serviceEngineer.firstName,
      serviceEngineer.lastName,
      serviceEngineer.startTimes,
      serviceEngineer.startLocationStreet,
      serviceEngineer.startLocationZip,
      serviceEngineer.startLocationCity,
      serviceEngineer.startLocationCountry,
      serviceEngineer.endLocationStreet,
      serviceEngineer.endLocationZip,
      serviceEngineer.endLocationCity,
      serviceEngineer.endLocationCountry
    );
  }

  public startTimeMonday: Time = {
    label: "Montag",
    loading: false,
    error: "",
    value: "09:00",
    text: ""
  };
  public startTimeTuesday: Time = {
    label: "Dienstag",
    loading: false,
    error: "",
    value: "09:00",
    text: ""
  };
  public startTimeWednesday: Time = {
    label: "Mittwoch",
    loading: false,
    error: "",
    value: "09:00",
    text: ""
  };
  public startTimeThursday: Time = {
    label: "Donnerstag",
    loading: false,
    error: "",
    value: "09:00",
    text: ""
  };
  public startTimeFriday: Time = {
    label: "Freitag",
    loading: false,
    error: "",
    value: "09:00",
    text: ""
  };

  public startLocationStreet: Textfield = {
    label: "Adresse",
    loading: false,
    error: "",
    value: ""
  };
  public startLocationZip: Textfield = {
    label: "PLZ",
    loading: false,
    error: "",
    value: ""
  };
  public startLocationCity: Textfield = {
    label: "Ort",
    loading: false,
    error: "",
    value: ""
  };
  public startLocationCountry: Selection = {
    label: "Land",
    loading: false,
    error: "",
    selected: "",
    items: CountriesStorageHandler.getAllCountries()
  };
  public endLocationStreet: Textfield = {
    label: "Adresse",
    loading: false,
    error: "",
    value: ""
  };
  public endLocationZip: Textfield = {
    label: "PLZ",
    loading: false,
    error: "",
    value: ""
  };
  public endLocationCity: Textfield = {
    label: "Ort",
    loading: false,
    error: "",
    value: ""
  };
  public endLocationCountry: Selection = {
    label: "Land",
    loading: false,
    error: "",
    selected: "",
    items: CountriesStorageHandler.getAllCountries()
  };

  public isValid: boolean = false;

  private tourDataForm: TourDataForm;

  public constructor(
    public id: string = "",
    public firstName: string = "",
    public lastName: string = "",
    startTimes: string[] = ["09:00", "09:00", "09:00", "09:00", "09:00"],
    startLocationStreet: string = "",
    startLocationZip: string = "",
    startLocationCity: string = "",
    startLocationCountry: string = "",
    endLocationStreet: string = "",
    endLocationZip: string = "",
    endLocationCity: string = "",
    endLocationCountry: string = ""
  ) {
    this.tourDataForm = new TourDataForm(this, this.tourDataValidated);

    this.tourDataForm.init();

    DateUtils.daysInEnglish.forEach((day, i) =>
      this.tourDataForm.setFieldValue(
        "startTime" + StringUtils.capitalizeFirstLetter(day),
        startTimes.length > i ? startTimes[i] : "09:00"
      )
    );

    this.tourDataForm.setFieldValue("startLocationStreet", startLocationStreet);
    this.tourDataForm.setFieldValue("startLocationZip", startLocationZip);
    this.tourDataForm.setFieldValue("startLocationCity", startLocationCity);
    this.tourDataForm.setFieldValue(
      "startLocationCountry",
      startLocationCountry
    );
    this.tourDataForm.setFieldValue("endLocationStreet", endLocationStreet);
    this.tourDataForm.setFieldValue("endLocationZip", endLocationZip);
    this.tourDataForm.setFieldValue("endLocationCity", endLocationCity);
    this.tourDataForm.setFieldValue("endLocationCountry", endLocationCountry);
  }

  public setTourDataField(fieldName: string, value: string) {
    this.tourDataForm.setFieldValue(fieldName, value);
  }

  public get title(): string {
    return `${this.firstName} ${this.lastName}'s Tourdaten`;
  }

  public toTourDataInput(): TourDataInput {
    return {
      id: this.id,
      startTimes: [
        this.startTimeMonday.value as string,
        this.startTimeTuesday.value as string,
        this.startTimeWednesday.value as string,
        this.startTimeThursday.value as string,
        this.startTimeFriday.value as string
      ],
      startLocationStreet: this.startLocationStreet.value,
      startLocationZip: this.startLocationZip.value,
      startLocationCity: this.startLocationCity.value,
      startLocationCountry: this.startLocationCountry.selected,
      endLocationStreet: this.endLocationStreet.value,
      endLocationZip: this.endLocationZip.value,
      endLocationCity: this.endLocationCity.value,
      endLocationCountry: this.endLocationCountry.selected
    };
  }

  private tourDataValidated(context: any, valid: boolean) {
    context.isValid = valid;
  }
}
