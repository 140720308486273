var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.element.value)?_c('Card',{attrs:{"title":_vm.element.label,"loading":_vm.appointmentsRequest.loading,"error":_vm.appointmentsRequest.error,"small":""},on:{"retry":function($event){return _vm.loadAppointments()}}},[(_vm.canEdit)?_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"x-small":"","text":"","icon":"","color":"white"},on:{"click":function($event){return _vm.openUpsertAppointmentDialog()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("add")])],1)],1):_vm._e(),_c('HoverableDatatable',{attrs:{"hide-default-footer":"","dense":"","multi-sort":"","items":_vm.appointments,"headers":_vm.appointmentTableHeaders,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getDate(item)))]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDuration(item.duration))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseType(item.type))+" ")]}},{key:"item.reason",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(_vm._s(item.reason))])]}},{key:"item.cancellationReason",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(item.cancellationReason)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('div',[_c('v-btn',{attrs:{"x-small":"","text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.openUpsertAppointmentDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1),_c('v-btn',{attrs:{"x-small":"","text":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.openDeleteAppointmentDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)],1):_vm._e()]}}],null,true)}),(_vm.upsertDialogShown)?_c('UpsertAppointmentView',{attrs:{"open":_vm.upsertDialogShown,"plant":_vm.plant,"appointment":_vm.appointmentToEdit},on:{"appointmentCreated":function($event){return _vm.handleAppointmentCreated($event)},"appointmentUpdated":function($event){return _vm.handleAppointmentUpdated($event)},"closed":function($event){_vm.upsertDialogShown = false}}}):_vm._e(),_c('v-dialog',{attrs:{"value":!!_vm.isDeleteDialogShown,"width":"300"}},[_c('v-card',{attrs:{"light":""}},[_c('v-card-title',[_vm._v("Termin stornieren")]),_c('v-card-text',[_vm._v(" Wollen Sie den Termin wirklich stornieren? "),_c('v-text-field',{attrs:{"label":"Stornierungsgrund"},model:{value:(_vm.cancellationReason),callback:function ($$v) {_vm.cancellationReason=$$v},expression:"cancellationReason"}})],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.isDeleteDialogShown = false}}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.deleteAppointment(_vm.toDeleteAppointment)}}},[_vm._v("Löschen")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }