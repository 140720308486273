import { Form, FormFieldType } from "@/forms/Form";

export class VpiCorrectionForm extends Form {
  protected definition = {
    newContractCost: {
      type: FormFieldType.Number,
      required: true
    },
    newSampleCost: { type: FormFieldType.Number, required: false },
    startIndex: { type: FormFieldType.Number, required: false }
  };
}
