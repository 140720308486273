import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { StockTakingItem } from "../services/StockTakingGraphQLService";

export class CreateStockTakingViewModel {
  public readonly = false;
  public visibleView = false;

  public loading = false;
  public error = "";

  public storeFilter: {
    selected: string;
    items: SelectionItem[];
  } = {
    selected: "",
    items: []
  };

  public stockTakingItems: StockTakingItem[] = [];

  public itemTemplates: {
    selected: string;
    items: SelectionItem[];
  } = {
    selected: "",
    items: []
  };
}
