import { Form, FormFieldType } from "@/forms/Form";
import regex from "@/data/regex";

export class EmployeeForm extends Form {
  protected definition = {
    abbreviation: { type: FormFieldType.Text, required: true },
    salutation: { type: FormFieldType.Selection, required: true },
    firstname: { type: FormFieldType.Text, required: true },
    lastname: { type: FormFieldType.Text, required: true },
    email: {
      type: FormFieldType.Text,
      required: false,
      regex: regex.email,
      error: "Ungültige Email"
    },
    svnr: {
      type: FormFieldType.Text,
      required: true,
      regex: regex.svnr,
      error: "Ungültige SVNR"
    },
    birthday: { type: FormFieldType.Date, required: true },
    dateOfJoining: { type: FormFieldType.Date, required: false },

    address: { type: FormFieldType.Text, required: true },
    zip: { type: FormFieldType.Text, required: true },
    city: { type: FormFieldType.Text, required: true },
    country: { type: FormFieldType.Selection, required: true },
    state: { type: FormFieldType.Selection, required: true },

    username: { type: FormFieldType.Text, required: false },
    password: {
      type: FormFieldType.Text,
      required: false,
      regex: regex.mediumPassword,
      error: "Mind. 8 Zeichen, davon eine Zahl, Klein- und Großbuchstabe"
    },
    role: { type: FormFieldType.Selection, required: true }
  };
}
