import { PlantStorage } from "@/plant/utils/PlantStorage";
import { WorkOrderStorage } from "@/report/utils/WorkOrderStorage";
import { ItemTemplateStorage } from "@/store/utils/ItemTemplateStorage";
import { StoreItemStorage } from "@/store/utils/StoreItemStorage";
import { StoreMovementStorage } from "@/store/utils/StoreMovementStorage";
import { StoreStorage } from "@/store/utils/StoreStorage";

export class StorageHolder {
  public static PlantStorage = new PlantStorage();
  public static WorkOrderStorage = new WorkOrderStorage();
  public static StoreItemStorage = new StoreItemStorage();
  public static StoreMovementStorage = new StoreMovementStorage();
  public static ItemTemplateStorage = new ItemTemplateStorage();
  public static StoreStorage = new StoreStorage();
}
