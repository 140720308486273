import { PlantGraphQLService } from "./PlantGraphQLService";
import { PlantLocalService } from "./PlantLocalService";
import { GraphQLConnection } from "@/gateways/graphql/GraphQLConnection";
import { OnlineCheckerFactory } from "@/common/utils/OnlineCheckerFactory";
import { CachedService } from "@/common/services/CachedService";
import { ServiceArgument } from "@/common/services/ServiceFacade";
import { SearchResult } from "@/common/services/SearchService";
import { Plants, VpiCalculation } from "../models/Plants";

export class PlantService implements IPlantService, CachedService {
  private onlineService: PlantGraphQLService;
  private offlineService: PlantLocalService;
  private service: PlantLocalService | PlantGraphQLService;

  constructor(connection: GraphQLConnection) {
    this.offlineService = new PlantLocalService();
    this.onlineService = new PlantGraphQLService(connection);

    if (OnlineCheckerFactory.isOnline) {
      this.service = this.onlineService;
    } else {
      this.service = this.offlineService;
    }
  }

  public get timestamp() {
    return this.offlineService.timestamp;
  }

  public getPlantById(id: string): Promise<Plant> {
    try {
      const plant = this.offlineService.getPlantById(id);

      return plant;
    } catch {
      return this.service.getPlantById(id);
    }
  }

  public getPlantByIndex(index: number): Promise<Plant> {
    this.updateOnlineState();
    return this.service.getPlantByIndex(index);
  }

  public getPlantsForVpiCalculation(
    vpi: string,
    thresholdPercent: number,
    targetIndex: number
  ): Promise<Plants> {
    this.updateOnlineState();
    return this.service.getPlantsForVpiCalculation(
      vpi,
      thresholdPercent,
      targetIndex
    );
  }

  public createPlant(plant: Plant): Promise<string> {
    this.updateOnlineState();
    return this.service.createPlant(plant);
  }

  public updatePlant(plant: Plant): Promise<string> {
    this.updateOnlineState();
    return this.service.updatePlant(plant);
  }

  public addPlantContactPerson(
    plant: Plant,
    contactPerson: PlantContactPerson
  ): Promise<string> {
    this.updateOnlineState();
    return this.service.addPlantContactPerson(plant, contactPerson);
  }

  public addPlantParameter(
    plant: Plant,
    parameter: PlantParameter
  ): Promise<string> {
    this.updateOnlineState();
    return this.service.addPlantParameter(plant, parameter);
  }

  public addPlantParameters(
    plant: Plant,
    parameters: PlantParameter[]
  ): Promise<string> {
    this.updateOnlineState();
    return this.service.addPlantParameters(plant, parameters);
  }

  public addPlantPart(plant: Plant, plantPart: PlantPart): Promise<string> {
    this.updateOnlineState();
    return this.service.addPlantPart(plant, plantPart);
  }

  public addPlantInstallationPart(
    plant: Plant,
    plantPart: PlantPart
  ): Promise<string> {
    this.updateOnlineState();
    return this.service.addPlantInstallationPart(plant, plantPart);
  }

  public upsertContract(
    plantId: string,
    contract: PlantContract
  ): Promise<string> {
    this.updateOnlineState();
    return this.service.upsertContract(plantId, contract);
  }

  public getPlantContractByPlantId(plantId: string): Promise<PlantContract> {
    this.updateOnlineState();
    return this.service.getPlantContractByPlantId(plantId);
  }

  public uploadPlantDocument(
    foreignId: string,
    isOperator: boolean,
    document: PlantDocumentPackage
  ): Promise<string> {
    this.updateOnlineState();
    return this.service.uploadPlantDocument(foreignId, isOperator, document);
  }

  public exportPlants(): Promise<string> {
    this.updateOnlineState();
    return this.service.exportPlants();
  }

  public findOther(id: string): Promise<SearchResult[]> {
    this.updateOnlineState();
    return this.service.findOther(id);
  }

  public updateContractPrices(
    id: string,
    calculation: VpiCalculation
  ): Promise<string> {
    this.updateOnlineState();
    return this.service.updateContractPrices(id, calculation);
  }

  public async download(arg?: ServiceArgument) {
    if (!arg || !arg.ids || arg.ids.length === 0) {
      return;
    }

    const plants: Plant[] = [];
    const promises = [];
    for (const id of arg.ids) {
      promises.push(
        this.onlineService.getPlantById(id).then(result => plants.push(result))
      );
    }

    await Promise.all(promises);

    this.offlineService.plants = plants;
  }

  public upload() {
    for (const plant of this.offlineService.getToSyncPlants()) {
      this.onlineService.updatePlant(plant.entity);
      plant.changed = false;
    }
    this.offlineService.save();
  }

  private updateOnlineState() {
    if (OnlineCheckerFactory.isOnline) {
      this.service = this.onlineService;
    } else {
      this.service = this.offlineService;
    }
  }
}

export interface IPlantService {
  getPlantById(id: string): Promise<Plant>;
  getPlantByIndex(index: number): Promise<Plant>;
  getPlantsForVpiCalculation(
    vpi: string,
    thresholdPercent: number,
    targetIndex: number
  ): Promise<Plants>;
  createPlant(plant: Plant): Promise<string>;
  updatePlant(plant: Plant): Promise<string>;
  addPlantContactPerson(
    plant: Plant,
    contactPerson: PlantContactPerson
  ): Promise<string>;
  addPlantParameter(plant: Plant, parameter: PlantParameter): Promise<string>;
  addPlantParameters(
    plant: Plant,
    parameters: PlantParameter[]
  ): Promise<string>;
  addPlantPart(plant: Plant, plantPart: PlantPart): Promise<string>;
  addPlantInstallationPart(plant: Plant, plantPart: PlantPart): Promise<string>;
  upsertContract(plantId: string, contract: PlantContract): Promise<string>;
  getPlantContractByPlantId(plantId: string): Promise<PlantContract>;
  uploadPlantDocument(
    foreignId: string,
    isOperator: boolean,
    document: PlantDocumentPackage
  ): Promise<string>;
  exportPlants(): Promise<string>;
  findOther(id: string): Promise<SearchResult[]>;
  updateContractPrices(
    id: string,
    calculation: VpiCalculation
  ): Promise<string>;
}

export interface Plant {
  id: string;
  protocol: string;
  addressRemarks: string;
  addressStreet: string;
  addressZip: string;
  addressCity: string;
  addressState: string;
  addressCountry: string;
  addressAccessRemarks?: string;
  addressLat?: string;
  addressLng?: string;
  group: string;
  type: string;
  plantSizes: string;
  sampleNeeded: boolean;
  // maintenanceInterval: number;
  // firstDate?: Date;

  districtAuthority: string;
  districtAuthorityNumber: string;
  remarks: string;

  operatorIds: string[];

  contactPersons: PlantContactPerson[];
  parameters: PlantParameter[];
  plantParts: PlantPart[];
  plantInstallationParts: PlantPart[];
  plantDocuments: PlantDocument[];
}

export interface PlantContactPerson {
  id?: string;
  salutation: string;
  firstName: string;
  lastName: string;
  company: string;
  eMail: string;
  phoneNumbers: PlantContactPersonPhoneNumber[];
}

export interface PlantContactPersonPhoneNumber {
  areaCode: string;
  phoneNumber: string;
  remarks: string;
  isStandard: boolean;
}

export interface PlantParameter {
  value: string;
  parameterId: string;
}

export interface PlantPart {
  amount: number;
  description: string;
  parameterId: string;
  index: number;
}

export interface PlantContract {
  hasContract: boolean;

  date: string;
  doneBy: string;
  maintenanceNumber: string;
  maintenanceCost: number;
  priceSample: number;

  baseIndex: string;
  startIndex: number;
  currentIndex: number;
  dueDate: string;
  firstAppointment: string;
  maintenanceInterval: number;

  contractQuitted: boolean;
  quitDate: string;
  remarks: string;
}

export interface PlantDocumentPackage {
  name: string;
  description: string;
  file: File;
}

export interface PlantDocument {
  name: string;
  description: string;
  uploadedAt: Date;
  path: string;
}
