export class LayoutViewModel {
  public menuItems: MenuItem[] = [];
  public search = "";
  public showNavigation = false;
}

export interface MenuItem {
  text: string;
  routeName?: string;
  routeParams?: any;
  route?: () => { routeName?: string; routeParams?: any };
  icon?: string;
  open?: boolean;
  items?: MenuItem[];
  visible?: boolean;
}
