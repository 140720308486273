import { SimpleStringStorage } from "./SimpleStringStorage";

export class LocalStorage implements SimpleStringStorage {
  private storage = window.localStorage;

  public set(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  public get(key: string): string {
    return this.storage.getItem(key) || "";
  }

  public remove(key: string): boolean {
    if (this.has(key)) {
      this.storage.removeItem(key);
      return true;
    }
    return false;
  }

  public has(key: string): boolean {
    return !!this.storage.getItem(key);
  }

  public clear(tablesToKeep: string[]): void {
    const tables = new Map();
    for (const key of tablesToKeep) {
      tables.set(key, this.get(key));
    }
    this.storage.clear();
    for (const [key, value] of tables) {
      this.storage.setItem(key, value);
    }
  }

  public empty(): boolean {
    return this.storage.length === 0;
  }

  public available(): boolean {
    let storage;
    try {
      storage = window.localStorage;
      const x = "__storage_test__";
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return (
        e instanceof DOMException &&
        (e.code === 22 ||
          e.code === 1014 ||
          e.name === "QuotaExceededError" ||
          e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
        !!storage &&
        storage.length !== 0
      );
    }
  }

  public findValue(table: string, key: string) {
    return JSON.parse(this.get(table)).find((field: any) => field.id === key);
  }

  public buildTopLevelSelection(
    table: string,
    textParam: string,
    valueParam?: string,
    customCallback?: (field: any) => any
  ): any[] {
    if (this.has(table)) {
      const fields = JSON.parse(this.get(table));

      if (!!customCallback) {
        return fields.map((field: any) => customCallback(field));
      } else {
        return fields.map((field: any) => ({
          text: field.values[textParam],
          value: !!valueParam ? field.values[valueParam] : field.id
        }));
      }
    }
    return [];
  }
}
