


























































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import ServerDatatable from "@/components/collection/ServerDatatable.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import IconButton from "@/components/basic/IconButton.vue";
import DeleteDialog from "@/components/basic/DeleteDialog.vue";
import CreateItemTemplateView from "@/store/views/CreateItemTemplateView.vue";

import { ItemTemplateViewModel } from "@/store/vms/ItemTemplateViewModel";
import { ItemTemplatePresenter } from "@/store/presenters/ItemTemplatePresenter";
import { ItemTemplateController } from "@/store/controllers/ItemTemplateController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { ItemTemplate } from "@/store/services/ItemTemplateGraphQLService";
import { BarcodeGenerator } from "../utils/BarcodeGenerator";
import { ItemTemplateService } from "../services/ItemTemplateService";

@Component<ItemTemplateView>({
  components: {
    Page,
    Section,
    ServerDatatable,
    Card,
    Container,
    IconButton,
    Column,
    Row,
    DeleteDialog,
    CreateItemTemplateView
  }
})
export default class ItemTemplateView extends Vue {
  private vm = new ItemTemplateViewModel();
  private controller?: ItemTemplateController = undefined;

  private isDeleteDialogShown = false;
  private toDeleteItemTemplate: any = {};
  private components: any[] = [];

  private initController(): ItemTemplateController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new ItemTemplateController(
      new ItemTemplatePresenter(this.vm),
      new ItemTemplateService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
    this.components = [];
  }

  private addItemTemplateButtonClicked(itemTemplateId?: string) {
    this.components.push({ id: itemTemplateId });
    // const route: RawLocation = { name: "item-template-create" };
    // if (itemTemplateId) {
    //   route.params = { itemTemplateId };
    // }

    // this.$router.push(route);
  }
  private clearComponents() {
    this.controller?.getItems();
  }

  private showDeleteDialog(itemTemplate: ItemTemplate) {
    this.toDeleteItemTemplate = itemTemplate;
    this.isDeleteDialogShown = true;
  }

  private deleteItemTemplate() {
    this.controller?.deleteItemTemplate(this.toDeleteItemTemplate.id);
    this.isDeleteDialogShown = false;
  }

  private downloadBarcodeButtonClicked(itemTemplate: ItemTemplate) {
    BarcodeGenerator.downloadBarcode(
      itemTemplate.itemNumber.toString(),
      itemTemplate.name
    );
  }
}
