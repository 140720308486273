







































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import ServerDatatable from "@/components/collection/ServerDatatable.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import IconButton from "@/components/basic/IconButton.vue";
import DeleteDialog from "@/components/basic/DeleteDialog.vue";

import { StoreViewModel } from "@/store/vms/StoreViewModel";
import { StorePresenter } from "@/store/presenters/StorePresenter";
import { StoreController } from "@/store/controllers/StoreController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { Store } from "@/store/services/StoreGraphQLService";
import { RawLocation } from "vue-router";
import { StoreService } from "../services/StoreService";

@Component<StoreView>({
  components: {
    Page,
    Section,
    ServerDatatable,
    Card,
    Container,
    IconButton,
    Column,
    Row,
    DeleteDialog
  }
})
export default class StoreView extends Vue {
  private vm = new StoreViewModel();
  private controller?: StoreController = undefined;

  private isDeleteDialogShown = false;
  private toDeleteStore: any = {};

  private initController(): StoreController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new StoreController(
      new StorePresenter(this.vm),
      new StoreService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }

  private addStoreButtonClicked(storeId?: string) {
    const route: RawLocation = { name: "store-create" };
    if (storeId) {
      route.params = { storeId };
    }

    this.$router.push(route);
  }

  private showDeleteDialog(store: Store) {
    this.toDeleteStore = store;
    this.isDeleteDialogShown = true;
  }

  private deleteStore() {
    this.controller?.deleteStore(this.toDeleteStore.id);
    this.isDeleteDialogShown = false;
  }
}
