import { ISignatureTabPresenter } from "../controllers/SignatureTabController";
import { SignatureTabViewModel } from "../vms/SignatureTabViewModel";

export class SignatureTabPresenter implements ISignatureTabPresenter {
  public constructor(private vm: SignatureTabViewModel) {}

  public set cannotTakeSignature(cannotTakeSignature: boolean) {
    this.vm.cannotTakeSignature = cannotTakeSignature;
  }

  public get cannotTakeSignature() {
    return this.vm.cannotTakeSignature;
  }

  public set signature(signature: string) {
    this.vm.signature = signature;
  }

  public get signature() {
    return this.vm.signature;
  }
}
