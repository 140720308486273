import { IWorkOrderListPresenter } from "../controllers/WorkOrderListController";
import { WorkOrderListViewModel } from "../vms/WorkOrderListViewModel";
import { Pagination, WorkOrder } from "../services/WorkOrderService";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { FormResponse } from "@/forms/FormResponse";
import { PaginatedList } from "@/datastructures/PaginatedList";

export class WorkOrderListPresenter implements IWorkOrderListPresenter {
  public constructor(private vm: WorkOrderListViewModel) {}

  public get activeTabIndex() {
    return this.vm.activeTabIndex;
  }

  public set activeTabIndex(activeTabIndex: number) {
    this.vm.activeTabIndex = activeTabIndex;
  }

  public set searchText(searchText: string) {
    this.vm.searchText = searchText;
  }

  public get searchText() {
    return this.vm.searchText;
  }

  public set openWorkOrdersPagination(pagination: Pagination) {
    this.vm.openWorkOrdersPagination = pagination;
  }

  public get openWorkOrdersPagination() {
    return this.vm.openWorkOrdersPagination;
  }

  public set toCheckWorkOrdersPagination(pagination: Pagination) {
    this.vm.toCheckWorkOrdersPagination = pagination;
  }

  public get toCheckWorkOrdersPagination() {
    return this.vm.toCheckWorkOrdersPagination;
  }

  public set finishedWorkOrdersPagination(pagination: Pagination) {
    this.vm.finishedWorkOrdersPagination = pagination;
  }

  public get finishedWorkOrdersPagination() {
    return this.vm.finishedWorkOrdersPagination;
  }

  public set toInvoiceWorkOrdersPagination(pagination: Pagination) {
    this.vm.toInvoiceWorkOrdersPagination = pagination;
  }

  public get toInvoiceWorkOrdersPagination() {
    return this.vm.toInvoiceWorkOrdersPagination;
  }

  public set archivedWorkOrdersPagination(pagination: Pagination) {
    this.vm.archivedWorkOrdersPagination = pagination;
  }

  public get archivedWorkOrdersPagination() {
    return this.vm.archivedWorkOrdersPagination;
  }

  public set openWorkOrdersOptions(options: any) {
    this.vm.openWorkOrdersOptions = options;
  }

  public get openWorkOrdersOptions() {
    return this.vm.openWorkOrdersOptions;
  }

  public set toCheckWorkOrdersOptions(options: any) {
    this.vm.toCheckWorkOrdersOptions = options;
  }

  public get toCheckWorkOrdersOptions() {
    return this.vm.toCheckWorkOrdersOptions;
  }

  public set finishedWorkOrdersOptions(options: any) {
    this.vm.finishedWorkOrdersOptions = options;
  }

  public get finishedWorkOrdersOptions() {
    return this.vm.finishedWorkOrdersOptions;
  }

  public set toInvoiceWorkOrdersOptions(options: any) {
    this.vm.toInvoiceWorkOrdersOptions = options;
  }

  public get toInvoiceWorkOrdersOptions() {
    return this.vm.toInvoiceWorkOrdersOptions;
  }

  public set archivedWorkOrdersOptions(options: any) {
    this.vm.archivedWorkOrdersOptions = options;
  }

  public get archivedWorkOrdersOptions() {
    return this.vm.archivedWorkOrdersOptions;
  }

  public get openWorkOrders(): WorkOrder[] {
    return this.vm.openWorkOrders;
  }

  public set openWorkOrders(openWorkOrders: WorkOrder[]) {
    this.vm.openWorkOrders = openWorkOrders;
    for (const workOrder of this.vm.openWorkOrders) {
      workOrder.addressCountry = CountriesStorageHandler.getCountryNameFromId(
        workOrder.addressCountry
      );
    }
  }

  public get toCheckWorkOrders(): WorkOrder[] {
    return this.vm.toCheckWorkOrders;
  }

  public set toCheckWorkOrders(toCheckWorkOrders: WorkOrder[]) {
    this.vm.toCheckWorkOrders = toCheckWorkOrders;
    for (const workOrder of this.vm.openWorkOrders) {
      workOrder.addressCountry = CountriesStorageHandler.getCountryNameFromId(
        workOrder.addressCountry
      );
    }
  }

  public get finishedWorkOrders(): WorkOrder[] {
    return this.vm.finishedWorkOrders;
  }

  public set finishedWorkOrders(finishedWorkOrders: WorkOrder[]) {
    this.vm.finishedWorkOrders = finishedWorkOrders;
    for (const workOrder of this.vm.openWorkOrders) {
      workOrder.addressCountry = CountriesStorageHandler.getCountryNameFromId(
        workOrder.addressCountry
      );
    }
  }

  public get statusColumn() {
    return this.vm.statusColumn;
  }

  public set openWorkOrdersLoading(openWorkOrdersLoading: boolean) {
    this.vm.openWorkOrdersLoading = openWorkOrdersLoading;
  }

  public set toCheckWorkOrdersLoading(toCheckWorkOrdersLoading: boolean) {
    this.vm.toCheckWorkOrdersLoading = toCheckWorkOrdersLoading;
  }

  public set finishedWorkOrdersLoading(finishedWorkOrdersLoading: boolean) {
    this.vm.finishedWorkOrdersLoading = finishedWorkOrdersLoading;
  }

  public set toInvoiceWorkOrdersLoading(toInvoiceWorkOrdersLoading: boolean) {
    this.vm.toInvoiceWorkOrdersLoading = toInvoiceWorkOrdersLoading;
  }

  public set archivedWorkOrdersLoading(archivedWorkOrdersLoading: boolean) {
    this.vm.archivedWorkOrdersLoading = archivedWorkOrdersLoading;
  }

  public set getOpenWorkOrdersResponse(
    response: FormResponse<PaginatedList<WorkOrder>>
  ) {
    if (!response.loading) {
      this.vm.openWorkOrdersPagination.totalItems = response.data.totalCount;
      this.vm.openWorkOrders = [];
      for (const workOrder of response.data.items) {
        workOrder.addressCountry = CountriesStorageHandler.getCountryNameFromId(
          workOrder.addressCountry
        );
        switch (workOrder.processingState) {
          case "OPEN":
            this.vm.openWorkOrders.push(workOrder);
            break;
          case "REJECTED":
            this.vm.openWorkOrders.push(workOrder);
            break;
        }
      }
    }
  }

  public set getToCheckWorkOrdersResponse(
    response: FormResponse<PaginatedList<WorkOrder>>
  ) {
    if (!response.loading) {
      this.vm.toCheckWorkOrdersPagination.totalItems = response.data.totalCount;
      this.vm.toCheckWorkOrders = [];
      for (const workOrder of response.data.items) {
        workOrder.addressCountry = CountriesStorageHandler.getCountryNameFromId(
          workOrder.addressCountry
        );
        this.vm.toCheckWorkOrders.push(workOrder);
      }
    }
  }

  public set getFinishedWorkOrdersResponse(
    response: FormResponse<PaginatedList<WorkOrder>>
  ) {
    if (!response.loading) {
      this.vm.finishedWorkOrdersPagination.totalItems =
        response.data.totalCount;
      this.vm.finishedWorkOrders = [];
      for (const workOrder of response.data.items) {
        workOrder.addressCountry = CountriesStorageHandler.getCountryNameFromId(
          workOrder.addressCountry
        );
        this.vm.finishedWorkOrders.push(workOrder);
      }
    }
  }

  public set getToInvoiceWorkOrdersResponse(
    response: FormResponse<PaginatedList<WorkOrder>>
  ) {
    if (!response.loading) {
      this.vm.toInvoiceWorkOrdersPagination.totalItems =
        response.data.totalCount;
      this.vm.toInvoiceWorkOrders = [];
      for (const workOrder of response.data.items) {
        workOrder.addressCountry = CountriesStorageHandler.getCountryNameFromId(
          workOrder.addressCountry
        );
        this.vm.toInvoiceWorkOrders.push(workOrder);
      }
    }
  }

  public set getArchivedWorkOrdersResponse(
    response: FormResponse<PaginatedList<WorkOrder>>
  ) {
    if (!response.loading) {
      this.vm.archivedWorkOrdersPagination.totalItems =
        response.data.totalCount;
      this.vm.archivedWorkOrders = [];
      for (const workOrder of response.data.items) {
        workOrder.addressCountry = CountriesStorageHandler.getCountryNameFromId(
          workOrder.addressCountry
        );
        this.vm.archivedWorkOrders.push(workOrder);
      }
    }
  }

  public set setWorkOrderToCheckResponse(response: FormResponse<string>) {
    if (!response.loading && !response.error) {
      this.vm.reloadTab = this.vm.activeTabIndex;
    }
  }

  public set setWorkOrderFinishedResponse(response: FormResponse<string>) {
    if (!response.loading && !response.error) {
      this.vm.reloadTab = this.vm.activeTabIndex;
    }
  }

  public set invoiceWorkOrderResponse(response: FormResponse<string>) {
    if (!response.loading && !response.error) {
      this.vm.reloadTab = this.vm.activeTabIndex;
    }
  }

  public set archiveWorkOrderResponse(response: FormResponse<string>) {
    if (!response.loading && !response.error) {
      this.vm.reloadTab = this.vm.activeTabIndex;
    }
  }

  public set cancelWorkOrderResponse(response: FormResponse<string>) {
    if (!response.loading && !response.error) {
      this.vm.reloadTab = this.vm.activeTabIndex;
    }
  }
}
