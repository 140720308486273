import {
  Textfield,
  Selection,
  Request,
  Date,
  Time,
  Checkbox,
  MultiSelect
} from "@/forms/ViewModelFormTypes";

export class CreateLooseAppointmentViewModel {
  public visibleView: boolean = false;
  public goBack: boolean = false;

  public createdAppointment: string = "";
  public createAppointmentRequest: Request = { loading: false, error: "" };

  public formStep: number = 1;
  public addressStepCompleted: boolean = false;

  public firstName = "";
  public lastName = "";
  public company = "";
  public phoneNumber = "";

  public operatorAddressRemarks = "";
  public operatorAddressStreet = "";
  public operatorAddressZip = "";
  public operatorAddressCity = "";
  public operatorAddressCountry: Selection = {
    selected: "",
    error: "",
    items: []
  };
  public operatorAddressState: Selection = {
    selected: "",
    error: "",
    items: []
  };

  public requestedBy = "";

  public differentAddress: Checkbox = {
    value: false,
    error: "",
    label: "Abweichende Adresse für diesen Termin"
  };
  public addressRemarks = "";
  public street = "";
  public zip = "";
  public city = "";
  public country: Selection = { selected: "", error: "", items: [] };
  public state: Selection = { selected: "", error: "", items: [] };

  public date: Date = { value: "", error: "", text: "" };
  public plantType: Selection = { selected: "", error: "", items: [] };
  public plantTypeAdditionalText = "";
  public reason: MultiSelect = { selected: [], error: "", items: [] };
  public duration: Time = { value: 0, error: "", text: "" };
  public sampleNeeded: Checkbox = {
    value: false,
    error: "",
    label: "Probe gefordert?"
  };
}
