import { TableHeader } from "@/forms/ViewModelFormTypes";
import { WorkOrder, Pagination } from "../services/WorkOrderService";

export class WorkOrderListViewModel {
  public activeTabIndex = 0;
  public reloadTab = -1;
  public searchText = "";
  public openWorkOrdersLoading = false;
  public toCheckWorkOrdersLoading = false;
  public finishedWorkOrdersLoading = false;
  public toInvoiceWorkOrdersLoading = false;
  public archivedWorkOrdersLoading = false;
  public openWorkOrders: WorkOrder[] = [];
  public toCheckWorkOrders: WorkOrder[] = [];
  public finishedWorkOrders: WorkOrder[] = [];
  public toInvoiceWorkOrders: WorkOrder[] = [];
  public archivedWorkOrders: WorkOrder[] = [];
  public openWorkOrdersPagination: Pagination = {
    itemsPerPage: 10,
    pageNumber: 1,
    totalItems: 10
  };
  public toCheckWorkOrdersPagination: Pagination = {
    itemsPerPage: 10,
    pageNumber: 1,
    totalItems: 10
  };
  public finishedWorkOrdersPagination: Pagination = {
    itemsPerPage: 10,
    pageNumber: 1,
    totalItems: 10
  };
  public toInvoiceWorkOrdersPagination: Pagination = {
    itemsPerPage: 10,
    pageNumber: 1,
    totalItems: 10
  };
  public archivedWorkOrdersPagination: Pagination = {
    itemsPerPage: 10,
    pageNumber: 1,
    totalItems: 10
  };
  public openWorkOrdersOptions = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: this.openWorkOrdersPagination.itemsPerPage,
    multiSort: true,
    mustSort: false,
    sortBy: ["plannedDate"],
    sortDesc: [false]
  };
  public toCheckWorkOrdersOptions = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: this.openWorkOrdersPagination.itemsPerPage,
    multiSort: true,
    mustSort: false,
    sortBy: ["plannedDate"],
    sortDesc: [false]
  };
  public finishedWorkOrdersOptions = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: this.openWorkOrdersPagination.itemsPerPage,
    multiSort: true,
    mustSort: false,
    sortBy: ["plannedDate"],
    sortDesc: [false]
  };
  public toInvoiceWorkOrdersOptions = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: this.openWorkOrdersPagination.itemsPerPage,
    multiSort: true,
    mustSort: false,
    sortBy: ["plannedDate"],
    sortDesc: [false]
  };
  public archivedWorkOrdersOptions = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: this.openWorkOrdersPagination.itemsPerPage,
    multiSort: true,
    mustSort: false,
    sortBy: ["plannedDate"],
    sortDesc: [true]
  };

  public statusColumn = {
    text: "Status",
    value: "status",
    width: "100px"
  };
  public workOrderHeaders: TableHeader[] = [
    {
      text: "Datum",
      value: "plannedDate"
    },
    {
      text: "Betreiber",
      value: "operatorName"
    },
    {
      text: "Adresse",
      value: "address",
      sortable: false
    },
    {
      text: "Anlagennummer",
      value: "plantIndex"
    },
    {
      text: "Service-Techniker",
      value: "serviceEngineerName"
    },
    {
      text: "Probe gefordert?",
      value: "sampleNeeded",
      align: "center"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "right",
      sortable: false
    }
  ];
  public workOrderTabletHeaders: TableHeader[] = [
    {
      text: "Datum",
      value: "plannedDate"
    },
    {
      text: "Addresse",
      value: "address"
    },
    {
      text: "Probe gefordert?",
      value: "sampleNeeded",
      align: "center"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "right"
    }
  ];
  public workOrderMobileHeaders: TableHeader[] = [
    {
      text: "Datum",
      value: "plannedDate"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "right"
    }
  ];
}
