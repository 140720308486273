import { DateUtils } from "@/utils/DateUtils";
import { EmployeeService } from "../services/EmployeeService";
import { FormResponse } from "@/forms/FormResponse";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { Week } from "../vms/TimeTrackingViewModel";
import {
  TimeTrackingDayService,
  TimeTrackingDay
} from "../services/TimeTrackingDayService";
import { Auth } from "@/common/utils/Auth";
import StorageKeys from "@/common/utils/StorageKeys";

export class TimeTrackingController {
  public constructor(
    private presenter: ITimeTrackingPresenter,
    private employeeService: EmployeeService,
    private timeTrackingDayService: TimeTrackingDayService
  ) {}

  public mounted() {
    if (this.canSelectServiceEngineer()) {
      this.getAllEmployees();
    } else {
      this.filterEmployeeSelected(Auth.userId);
    }
  }

  public canSelectServiceEngineer() {
    return Auth.hasPermission(StorageKeys.editTourPlanPermission);
  }

  public saveXLS() {
    let filterDateFrom = "";
    let filterDateTo = "";
    if (this.presenter.filterDateFrom && this.presenter.filterDateTo) {
      filterDateFrom = this.presenter.filterDateFrom;
      filterDateTo = this.presenter.filterDateTo;
    } else {
      const dateTo = DateUtils.getDate(this.presenter.filterWeekNumber);
      dateTo.setDate(dateTo.getDate() + 6);
      filterDateFrom = this.presenter.filterWeekNumber;
      filterDateTo = DateUtils.dateOnly(dateTo);
    }
    const request = this.timeTrackingDayService.saveXLS(
      this.presenter.filterEmployee,
      filterDateFrom,
      filterDateTo
    );

    FormRequestHandler.handle(
      request,
      response => (this.presenter.xlsResultResponse = response),
      "get-xls-download-failed"
    );
  }

  public filterEmployeeSelected(newValue: string) {
    this.presenter.filterEmployee = newValue;
    this.reloadData();
    this.makeWeeks();
  }

  public filterWeekNumberSelected(newValue: string) {
    this.presenter.filterWeekNumber = newValue;

    if (!!this.presenter.filterEmployee) {
      this.reloadData();
      this.makeWeeks();
    }
  }

  public filterDateFromChanged(newValue: string) {
    this.presenter.filterDateFrom = newValue;

    if (!!this.presenter.filterEmployee) {
      this.reloadData();
      this.makeWeeks();
    }
  }

  public filterDateToChanged(newValue: string) {
    this.presenter.filterDateTo = newValue;

    if (!!this.presenter.filterEmployee) {
      this.reloadData();
      this.makeWeeks();
    }
  }

  public makeWeeks() {
    let start: Date;
    let end: Date;
    if (this.presenter.filterDateFrom && this.presenter.filterDateTo) {
      start = new Date(this.presenter.filterDateFrom);
      end = new Date(this.presenter.filterDateTo);
    } else {
      start = new Date(this.presenter.filterWeekNumber);
      end = new Date(start);
      end.setDate(end.getDate() + 6);
    }

    let date = new Date(start);
    const weeks: { [id: string]: Week } = {};

    while (date <= end) {
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        if (!weeks[`${DateUtils.weekNumber(date)}/${date.getFullYear()}`]) {
          weeks[`${DateUtils.weekNumber(date)}/${date.getFullYear()}`] = {
            weekNumber: DateUtils.weekNumber(date),
            year: date.getFullYear(),
            days: []
          };
        }
        const day: TimeTrackingDay = { date };
        weeks[`${DateUtils.weekNumber(date)}/${date.getFullYear()}`].days.push(
          day
        );
      }

      date = new Date(date);
      date.setDate(date.getDate() + 1);
    }

    this.presenter.weeks = Object.values(weeks);
  }

  public formatDate(date: Date | string) {
    return DateUtils.format(date);
  }

  private getAllEmployees() {
    const request = this.employeeService.getAllActiveEmployees();

    FormRequestHandler.handle(
      request,
      response => (this.presenter.getEmployeesResponse = response),
      "get-employees-failed"
    );
  }

  private reloadData() {
    let filterDateFrom = "";
    let filterDateTo = "";
    if (this.presenter.filterDateFrom && this.presenter.filterDateTo) {
      filterDateFrom = this.presenter.filterDateFrom;
      filterDateTo = this.presenter.filterDateTo;
    } else {
      const dateTo = DateUtils.getDate(this.presenter.filterWeekNumber);
      dateTo.setDate(dateTo.getDate() + 6);
      filterDateFrom = this.presenter.filterWeekNumber;
      filterDateTo = DateUtils.dateOnly(dateTo);
    }
    const request = this.timeTrackingDayService.getFilteredDays(
      this.presenter.filterEmployee,
      filterDateFrom,
      filterDateTo
    );

    FormRequestHandler.handle(
      request,
      response => (this.presenter.getFilteredDaysResponse = response),
      "get-days-failed"
    );
  }
}

export interface ITimeTrackingPresenter {
  getEmployeesResponse: FormResponse<any[]>;
  getFilteredDaysResponse: FormResponse<any[]>;
  filterEmployee: string;
  filterWeekNumber: string;
  filterDateFrom: string;
  filterDateTo: string;
  weeks: Week[];
  xlsResultResponse: FormResponse<any>;
}
