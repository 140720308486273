import SystemKeys from "@/common/utils/SystemKeys";
import { LocalStorage } from "@/storage/LocalStorage";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";

export class ItemTemplateStorage {
  private localStorage = new LocalStorage();

  private itemsToSave: ItemTemplatesToSave = {
    timestamp: 0,
    itemTemplates: []
  };

  constructor() {
    const entries = this.localStorage.get(SystemKeys.storedItemTemplatesKey);
    if (!!entries) {
      this.itemsToSave = JSON.parse(entries);
    }
  }

  public set itemTemplates(itemTemplates: ItemTemplate[]) {
    this.itemsToSave.itemTemplates = itemTemplates.map(it => ({
      changed: false,
      entity: it
    }));
    this.itemsToSave.timestamp = new Date().getTime();
  }

  public get itemTemplates() {
    return this.itemsToSave.itemTemplates.map(it => {
      return it.entity;
    });
  }

  public get timestamp() {
    return this.itemsToSave.timestamp;
  }

  public save() {
    this.localStorage.set(
      SystemKeys.storedItemTemplatesKey,
      JSON.stringify(this.itemsToSave)
    );
  }

  public getToSyncStoreItems() {
    this.load();
    return this.itemsToSave.itemTemplates.filter(fe => fe.changed);
  }

  private load() {
    const entries = this.localStorage.get(SystemKeys.storedItemTemplatesKey);
    if (!!entries) {
      this.itemsToSave = JSON.parse(entries);
    }
  }
}

interface ItemTemplatesToSave {
  timestamp: number;
  itemTemplates: Array<FlaggedEntity<ItemTemplate>>;
}

interface FlaggedEntity<T> {
  changed: boolean;
  entity: T;
}
