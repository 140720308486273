import { GraphQLConnection } from "@/gateways/graphql/GraphQLConnection";

export class EmployeeGraphQLService {
  public constructor(private connection: GraphQLConnection) {}

  public async getAllEmployees(): Promise<Employee[]> {
    const request = await this.connection.query("storeEmployees", {}, [
      "id",
      "firstname",
      "lastname"
    ]);

    return request.data;
  }

  public async getEmployeeById(id: string) {
    const request = await this.connection.query(
      "storeEmployee",
      {
        id
      },
      ["id", "firstname", "lastname"]
    );

    return request.data;
  }
}

export interface Employee {
  id: string;
  firstname: string;
  lastname: string;
}
