


























































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ChangeTourDayPropsDialog from "@/tourPlanner/views/ChangeTourDayPropsDialog.vue";

import { TourDay } from "@/tourPlanner/services/TourWeekGraphQLService";

@Component<ScheduleHeader>({
  components: {
    Card,
    Container,
    Column,
    Row,
    ChangeTourDayPropsDialog
  },
  props: {
    showChangeTourDayPropsButton: Boolean,
    viewRouteMode: Boolean,
    tourWeek: Object
  }
})
export default class ScheduleHeader extends Vue {
  public shortenTime(raw: string): string {
    const splitTime = raw.split(":");
    return `${splitTime[0]}:${splitTime[1]}`;
  }

  public getWeekDay(index: number) {
    return ["MO", "DI", "MI", "DO", "FR"][index];
  }

  public getDate(index: number) {
    const date = new Date(this.$props.tourWeek.weekNumber);
    date.setDate(date.getDate() + index);
    return date.getDate();
  }
}
