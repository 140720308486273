import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { ArrayUtils } from "@/utils/ArrayUtils";
import { ObjectUtils } from "@/utils/ObjectUtils";

export class SystemTableUtils {
  public constructor(private storage: SimpleStringStorage) {}

  public loadSystemEntries(systemTableKey: string) {
    const systemEntriesAsString = this.storage.get(systemTableKey);

    if (!!systemEntriesAsString) {
      return JSON.parse(systemEntriesAsString);
    }

    return null;
  }

  public findFirstValueKey(systemTableKey: string): string {
    const systemEntries = this.loadSystemEntries(systemTableKey);
    if (
      !!systemEntries &&
      ArrayUtils.isArray(systemEntries) &&
      systemEntries.length > 0
    ) {
      const values = systemEntries[0].values;
      return ObjectUtils.getFirstObjectKey(values);
    }

    return "";
  }
}
