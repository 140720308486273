import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { OrderByClause, SortOrder } from "@/gateways/graphql/GraphQLConnection";
import { StoreService } from "../services/StoreService";

export class StoreController {
  public constructor(
    private presenter: IStorePresenter,
    private storeService: StoreService
  ) {}

  public mounted() {
    this.getItems();
  }

  public tableSorted(options: any) {
    this.presenter.options = options;

    if (!this.presenter.mounting) {
      this.getItems();
    }
  }

  public pageChanged(newPageNumber: number) {
    this.presenter.pageNumber = newPageNumber;

    if (!this.presenter.mounting) {
      this.getItems();
    }
  }

  public deleteStore(id: string) {
    FormRequestHandler.handle(
      this.storeService.deleteStore(id),
      response => {
        this.presenter.deleteRequest = response;

        if (!response.error && !response.loading) {
          this.tableSorted(this.presenter.options);
        }
      },
      "Konnte Tabelle nicht laden!"
    );
  }

  private getItems() {
    const order: OrderByClause[] = [];
    for (let i = 0; i < this.presenter.options.sortBy.length; i++) {
      order.push({
        field: this.presenter.options.sortBy[i],
        order: this.presenter.options.sortDesc[i]
          ? SortOrder.DESC
          : SortOrder.ASC
      });
    }

    const page = new Page(
      this.presenter.itemsPerPage,
      this.presenter.pageNumber
    );

    FormRequestHandler.handle(
      this.storeService.getAllStoresPaginated(
        page,
        order,
        this.presenter.search
      ),
      response => (this.presenter.tableRequest = response),
      "Konnte Tabelle nicht laden!"
    );
  }
}

export interface IStorePresenter {
  mounting: boolean;
  tableRequest: FormResponse<PaginatedList<any>>;
  deleteRequest: FormResponse<string>;
  pageNumber: number;
  itemsPerPage: number;
  options: any;
  search: string;
}
