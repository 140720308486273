
























import Vue from "vue";
import Component from "vue-class-component";

import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

@Component<Pagination>({
  components: {
    Grid,
    Row,
    Column
  },
  props: {
    value: {
      type: Number,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      default: 20
    },
    totalItems: {
      type: Number,
      default: 20
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    tileSize: {
      type: String,
      default: "xs12"
    }
  }
})
export default class Pagination extends Vue {
  private get pages() {
    if (!this.$props.totalItems || !this.$props.itemsPerPage) {
      return 0;
    }
    return Math.ceil(this.$props.totalItems / this.$props.itemsPerPage);
  }

  private emitInput(page: number) {
    if (page !== this.$props.value) {
      this.$emit("input", page);
    }
  }
}
