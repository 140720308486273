
























































































































































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Card from "@/components/layout/Card.vue";
import CustomDialog from "@/components/basic/CustomDialog.vue";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import GoogleMap from "@/components/google/GoogleMap.vue";
import GoogleMapMarker from "@/components/google/GoogleMapMarker.vue";

import AppointmentMapMarker from "./AppointmentMapMarker.vue";

import DatePicker from "@/components/form/DatePicker.vue";

import { AppointmentMapController } from "@/tourPlanner/controllers/AppointmentMapController";
import { AppointmentMapPresenter } from "@/tourPlanner/presenters/AppointmentMapPresenter";
import { AppointmentMapViewModel } from "@/tourPlanner/vms/AppointmentMapViewModel";
import { AxiosGoogleMapsConnection } from "@/gateways/AxiosGoogleMapsConnection";
import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { AppointmentGraphQLService } from "../services/AppointmentGraphQLService";

import { blueMarkerIcon } from "@/data/settings";
@Component<AppointmentMap>({
  components: {
    Container,
    Column,
    Row,
    Page,
    Card,
    DatePicker,
    CustomDialog,
    GoogleMap,
    GoogleMapMarker,
    AppointmentMapMarker
  },
  props: {
    serviceEngineerId: {
      type: String,
      required: true
    },
    weekDate: {
      type: String,
      required: true
    },
    filters: {
      type: String,
      required: false
    }
  }
})
export default class AppointmentMap extends Vue {
  private companyIcon = blueMarkerIcon;
  private vm = new AppointmentMapViewModel();
  private connection = new AxiosGraphQLConnection(
    new AxiosLogger(new ConsoleLogger())
  );
  private controller = new AppointmentMapController(
    new AppointmentMapPresenter(this.vm),
    new AxiosGoogleMapsConnection(new AxiosLogger(new ConsoleLogger())),
    new AppointmentGraphQLService(this.connection)
    // new ServiceEngineerGraphQLService(this.connection),
    // new TourPlannerGraphQLService(this.connection)
  );
  private filterShown = false;

  public mounted() {
    this.controller.mounted(this.$props.filters);
  }

  public changeFilter() {
    this.filterShown = !this.filterShown;
  }

  public onWeeklyScheduleStartButtonClicked() {
    this.$router.push({
      name: "weekly-schedule",
      params: {
        // originAddress: this.vm.centerAddress,
        serviceEngineerId: this.$props.serviceEngineerId,
        weekDate: this.$props.weekDate,
        filters: JSON.stringify(this.vm.filters)
      }
    });
  }
}
