import { StringUtils } from "@/utils/StringUtils";
import { NonFunctionProperties } from "@/utils/TypeUtils";
import { Operator, Operators, OperatorsProps } from "./Operators";

type PlantsProps = PlantProps[];
type PlantProps = Partial<Plant & { operators: OperatorsProps }>;
type PlantContractProps = Partial<PlantContract>;
type VpiCalculationProps = Partial<VpiCalculation>;

type PlantFilter = Partial<NonFunctionProperties<Plant>>;

export class Plants {
  private readonly plants: Plant[];

  public constructor(plants?: PlantsProps) {
    this.plants = plants?.map(p => new Plant(p)) ?? [];
  }

  public get(filter?: PlantFilter): Plant[] {
    if (!filter || Object.keys(filter).length === 0) {
      return this.plants;
    }

    return this.plants.filter(p => {
      let matches = true;

      for (const key of Object.keys(filter)) {
        const filterKey = (key as keyof PlantFilter)!;
        if (filter[filterKey]) {
          matches = matches && filter[filterKey] === p[filterKey];
        }
      }

      return matches;
    });
  }

  public remove(plant: Plant) {
    const index = this.plants.indexOf(plant);

    if (index >= 0) {
      this.plants.splice(index, 1);
    }
  }
}

export class Plant {
  public readonly id: string;
  public readonly index: number;
  public readonly addressStreet: string;
  public readonly addressZip: string;
  public readonly addressCity: string;
  public readonly addressState: string;
  public readonly addressCountry: string;
  public readonly operators?: Operators;
  public readonly plantContract: PlantContract;

  public constructor(props?: PlantProps) {
    this.id = props?.id ?? "";
    this.index = props?.index ?? -1;
    this.addressStreet = props?.addressStreet ?? "";
    this.addressZip = props?.addressZip ?? "";
    this.addressCity = props?.addressCity ?? "";
    this.addressState = props?.addressState ?? "";
    this.addressCountry = props?.addressCountry ?? "";
    this.operators = props?.operators
      ? new Operators(props.operators)
      : undefined;
    this.plantContract = new PlantContract(props?.plantContract);
  }

  public get mainOperator(): Operator | undefined {
    return this.operators?.first;
  }

  public get address(): string {
    return `${this.addressStreet}, ${this.addressZip} ${this.addressCity}`;
  }
}

export class PlantContract {
  public readonly baseIndex: string;
  public readonly startIndex: number;
  public readonly currentIndex: number;
  public readonly maintenanceCost: number;
  public readonly priceSample: number;
  public readonly remarks: string;

  public constructor(props?: PlantContractProps) {
    this.baseIndex = props?.baseIndex ?? "";
    this.startIndex = props?.startIndex ?? 0;
    this.currentIndex = props?.currentIndex ?? 0;
    this.maintenanceCost = props?.maintenanceCost ?? 0;
    this.priceSample = props?.priceSample ?? 0;
    this.remarks = props?.remarks ?? "";
  }

  public get maintenanceCostStr(): string {
    return StringUtils.toPriceString(this.maintenanceCost);
  }

  public get priceSampleStr(): string {
    return StringUtils.toPriceString(this.priceSample);
  }

  public calculateNewIndex(targetIndex: number): VpiCalculation {
    return new VpiCalculation({
      startIndex: this.startIndex,
      currentIndex: this.currentIndex,
      targetIndex,
      contractCost: this.maintenanceCost,
      sampleCost: this.priceSample
    });
  }
}

export class VpiCalculation {
  public readonly startIndex: number;
  public readonly currentIndex: number;
  public readonly targetIndex: number;
  public readonly contractCost: number;
  public readonly sampleCost: number;
  public readonly newContractCost: number;
  public readonly newSampleCost: number;

  public constructor(props?: VpiCalculationProps) {
    this.startIndex = props?.startIndex ?? 0;
    this.currentIndex = props?.currentIndex ?? 0;
    this.targetIndex = props?.targetIndex ?? 0;
    this.contractCost = props?.contractCost ?? 0;
    this.sampleCost = props?.sampleCost ?? 0;

    this.newContractCost =
      props?.newContractCost ??
      Math.round(this.contractCost * this.increaseFactor * 100) / 100;

    this.newSampleCost =
      props?.newSampleCost ??
      Math.round(this.sampleCost * this.increaseFactor * 100) / 100;
  }

  public get increasePercent(): number {
    const increase = this.increaseFactor - 1;

    return Math.round(increase * 10000) / 100;
  }

  public get newContractCostStr(): string {
    return StringUtils.toPriceString(this.newContractCost);
  }

  public get newSampleCostStr(): string {
    return StringUtils.toPriceString(this.newSampleCost);
  }

  private get increaseFactor(): number {
    if (this.currentIndex === 0) {
      return 1;
    }

    return this.targetIndex / this.currentIndex;
  }

  public applyValues(
    values: Pick<
      VpiCalculation,
      "newContractCost" | "newSampleCost" | "startIndex"
    >
  ): VpiCalculation {
    return new VpiCalculation({
      ...this,
      ...values
    });
  }
}
