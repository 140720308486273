





























































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";

import RequestButton from "@/components/basic/RequestButton.vue";

import { CreateStoreItemViewModel } from "@/store/vms/CreateStoreItemViewModel";
import { CreateStoreItemPresenter } from "@/store/presenters/CreateStoreItemPresenter";
import { CreateStoreItemController } from "@/store/controllers/CreateStoreItemController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { StoreItemService } from "../services/StoreItemService";
import { ItemTemplateService } from "../services/ItemTemplateService";
import { StoreService } from "../services/StoreService";

@Component<CreateStoreItemView>({
  components: {
    ValidationObserver,
    Card,
    Container,
    Column,
    Row,
    ValidatedTextfield,
    ValidatedSelect,
    RequestButton
  },
  props: {
    storeItemId: String
  },
  watch: {
    "vm.visibleView"(newValue) {
      if (!newValue) {
        setTimeout(() => {
          this.$emit("destroyed");
          this.$destroy();
        }, 500);
      }
    }
  }
})
export default class CreateStoreItemView extends Vue {
  private vm = new CreateStoreItemViewModel();
  private controller?: CreateStoreItemController;

  private formStep = 1;

  private initController(): CreateStoreItemController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreateStoreItemController(
      new CreateStoreItemPresenter(this.vm),
      new StoreItemService(connection),
      new ItemTemplateService(connection),
      new StoreService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$props.storeItemId);
    this.vm.isEdit = !!this.$props.storeItemId;
  }
}
