import { GraphQLConnection } from "@/gateways/graphql/GraphQLConnection";
import {
  Appointment,
  AppointmentGraphQLService
} from "./AppointmentGraphQLService";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { PlantGroupStorageHandler } from "@/storage/storageHandlers/PlantGroupStorageHandler";

export class TourWeekGraphQLService implements ITourWeekService {
  public static get tourWeekFields() {
    return ["id", "weekNumber", "serviceEngineerId", "processingState"];
  }

  public static get tourDayFields() {
    return [
      "index",
      "startTime",
      "startLocationStreet",
      "startLocationZip",
      "startLocationCity",
      "startLocationCountry",
      "endLocationStreet",
      "endLocationZip",
      "endLocationCity",
      "endLocationCountry",
      "tourWeekId",
      {
        name: "appointments",
        fields: AppointmentGraphQLService.appointmentFields
      }
    ];
  }

  public constructor(private connection: GraphQLConnection) {}

  public async getTourWeeks(): Promise<TourWeek[]> {
    const result = await this.connection.query("getTourWeeks", {}, [
      "id",
      "weekNumber",
      "serviceEngineerId",
      "createdByTourPlannerId",
      "processingState",
      { name: "appointments", fields: ["id"] }
    ]);
    return result.data;
  }

  public async getTourWeeksByServiceEngineerId(
    serviceEngineerId: string
  ): Promise<TourWeek[]> {
    const result = await this.connection.query(
      "getTourWeeksByServiceEngineerId",
      { serviceEngineerId },
      [
        "id",
        "weekNumber",
        "serviceEngineerId",
        "createdByTourPlannerId",
        "processingState"
      ]
    );
    return result.data;
  }

  public async tourWeekExistsByWeekNumberAndServiceEngineerId(
    weekNumber: string,
    serviceEngineerId: string
  ): Promise<boolean> {
    const result = await this.connection.query(
      "tourWeekExistsByWeekNumberAndServiceEngineerId",
      {
        weekNumber,
        serviceEngineerId
      },
      ["doesExist"]
    );

    return result.data.doesExist;
  }

  public async getTourWeekByWeekNumberAndServiceEngineerId(
    weekNumber: string,
    serviceEngineerId: string
  ): Promise<TourWeek> {
    const result = await this.connection.query(
      "getTourWeekByWeekNumberAndServiceEngineer",
      {
        weekNumber,
        serviceEngineerId
      },
      TourWeekGraphQLService.tourWeekFields
    );

    result.data.tourDays = await this.getTourDaysByTourWeekId(result.data.id);

    return result.data;
  }

  public async getTourWeekById(
    tourWeekId: string,
    tourPlannerId: string,
    serviceEngineerId: string
  ): Promise<TourWeek> {
    const result = await this.connection.query(
      "getTourWeekById",
      {
        id: tourWeekId
      },
      TourWeekGraphQLService.tourWeekFields
    );

    result.data.tourDays = await this.getTourDaysByTourWeekId(tourWeekId);

    return result.data;
  }

  public async getTourDaysByTourWeekId(tourWeekId: string): Promise<TourDay[]> {
    const result = await this.connection.query(
      "getTourDaysByTourWeekId",
      {
        id: tourWeekId
      },
      TourWeekGraphQLService.tourDayFields
    );

    return result.data.map((raw: any) => this.parseTourDay(raw));
  }

  public async upsertTourWeek(
    tourPlannerId: string,
    weekNumber: string,
    serviceEngineerId: string,
    tourDays: TourDay[],
    id?: string
  ): Promise<string> {
    const result = await this.connection.mutation(
      "upsertTourWeek",
      {
        input: {
          id,
          tourPlannerId,
          weekNumber,
          serviceEngineerId,
          processingState: "STARTED",
          tourDays: tourDays.map(tourDay => this.tourDayToDTO(tourDay))
        }
      },
      []
    );

    return result.data;
  }

  public async replaceTourDay(
    index: number,
    startTime: string,
    startLocationStreet: string,
    startLocationZip: string,
    startLocationCity: string,
    startLocationCountry: string,
    endLocationStreet: string,
    endLocationZip: string,
    endLocationCity: string,
    endLocationCountry: string,
    tourWeekId: string
  ): Promise<number> {
    const result = await this.connection.mutation(
      "replaceTourDay",
      {
        input: {
          index,
          startTime,
          startLocationStreet,
          startLocationZip,
          startLocationCity,
          startLocationCountry,
          endLocationStreet,
          endLocationZip,
          endLocationCity,
          endLocationCountry,
          tourWeekId
        }
      },
      []
    );
    return result.data;
  }

  public async requestTourWeekRoutes(tourWeekId: string): Promise<any[]> {
    const result = await this.connection.query(
      "getTourWeekRoutes",
      {
        tourWeekId
      },
      ["tourDayIndex", "output"]
    );
    return result.data;
  }

  public async requestTourWeekRoutesByWeekNumberAndServiceEngineer(
    weekNumber: string,
    serviceEngineerId: string
  ): Promise<any[]> {
    const result = await this.connection.query(
      "getTourWeekByWeekNumberAndServiceEngineer",
      {
        weekNumber,
        serviceEngineerId
      },
      TourWeekGraphQLService.tourWeekFields
    );

    if (result.data && result.data.id) {
      result.data.tourDays = await this.getTourDaysByTourWeekId(result.data.id);
      for (const tourDay of result.data.tourDays) {
        for (const appointment of tourDay.appointments) {
          if (appointment.plant) {
            appointment.plant.group = PlantGroupStorageHandler.getGroupNameFromId(
              appointment.plant.group
            );
            appointment.plant.type = PlantGroupStorageHandler.getTypeNameFromId(
              appointment.plant.type
            );
          }
        }
      }

      const routes = await this.connection.query(
        "getTourWeekRoutes",
        {
          tourWeekId: result.data.id
        },
        ["tourDayIndex", "output"]
      );

      for (const route of routes.data) {
        const tourDay = result.data.tourDays.find(
          (td: any) => td.index === route.tourDayIndex
        );
        tourDay.directionsResponse = route.output;

        for (let i = route.output.routes[0].legs.length - 1; i >= 0; i--) {
          const leg = route.output.routes[0].legs[i];
          if (leg.duration.value > 0) {
            tourDay.appointments.splice(i, 0, {
              duration: leg.duration.value,
              distance: leg.distance.value
            });
          }
        }
      }
    }
    return result.data;
  }

  public async finishTourWeek(tourWeekId: string): Promise<string> {
    const result = await this.connection.mutation(
      "finishTourWeek",
      { input: { tourWeekId } },
      []
    );
    return result.data;
  }

  public async setNotValidatedTourWeek(tourWeekId: string): Promise<string> {
    const result = await this.connection.mutation(
      "setNotValidatedTourWeek",
      { input: { tourWeekId } },
      []
    );
    return result.data;
  }

  private parseTourDay(rawTourDay: any) {
    return {
      index: rawTourDay.index,
      appointments: rawTourDay.appointments,
      startTime: rawTourDay.startTime,
      startLocation: {
        street: rawTourDay.startLocationStreet,
        zip: rawTourDay.startLocationZip,
        city: rawTourDay.startLocationCity,
        country: CountriesStorageHandler.getCountryNameFromId(
          rawTourDay.startLocationCountry
        )
      },
      endLocation: {
        street: rawTourDay.endLocationStreet,
        zip: rawTourDay.endLocationZip,
        city: rawTourDay.endLocationCity,
        country: CountriesStorageHandler.getCountryNameFromId(
          rawTourDay.endLocationCountry
        )
      },
      tourWeekId: rawTourDay.tourWeekId,
      polyLine: []
    };
  }

  private tourDayToDTO(tourDay: TourDay) {
    return {
      index: tourDay.index,
      startTime: tourDay.startTime,
      startLocation: tourDay.startLocation,
      endLocation: tourDay.endLocation
    };
  }
}

export interface TourWeek {
  id: string | undefined;
  weekNumber: string;
  serviceEngineerId: string;
  tourDays: TourDay[];
  processingState: string;
  appointments: Appointment[];
}

export interface TourDay {
  index: number;
  appointments: Array<Appointment | TourRoute>;
  startTime: string;
  startLocation: TourDayAddress;
  endLocation: TourDayAddress;
  tourWeekId: string;
  directionsResponse: any;
  date: Date;
  error: string;
}

export interface TourRoute {
  distance: number;
  duration: number;
}

export interface TourDayAddress {
  street: string;
  zip: string;
  city: string;
  country: string;
}

export interface ITourWeekService {
  tourWeekExistsByWeekNumberAndServiceEngineerId(
    weekNumber: string,
    serviceEngineerId: string
  ): Promise<boolean>;
  getTourWeekById(
    tourWeekId: string,
    tourPlannerId: string,
    serviceEngineerId: string
  ): Promise<TourWeek>;
  getTourWeeksByServiceEngineerId(
    serviceEngineerId: string
  ): Promise<TourWeek[]>;
  getTourDaysByTourWeekId(
    tourWeekId: string,
    tourPlannerId: string
  ): Promise<TourDay[]>;
  getTourWeeks(): Promise<TourWeek[]>;
  getTourWeekByWeekNumberAndServiceEngineerId(
    weekNumber: string,
    serviceEngineerId: string
  ): Promise<TourWeek>;
  replaceTourDay(
    index: number,
    startTime: string,
    startLocationStreet: string,
    startLocationZip: string,
    startLocationCity: string,
    startLocationCountry: string,
    endLocationStreet: string,
    endLocationZip: string,
    endLocationCity: string,
    endLocationCountry: string,
    tourWeekId: string
  ): Promise<number>;
  upsertTourWeek(
    tourPlannerId: string,
    weekNumber: string,
    serviceEngineerId: string,
    tourDays: TourDay[],
    id?: string
  ): Promise<string>;
  requestTourWeekRoutes(tourWeekId: string): Promise<any[]>;
  requestTourWeekRoutesByWeekNumberAndServiceEngineer(
    weekNumber: string,
    serviceEngineerId: string
  ): Promise<any>;

  finishTourWeek(id: string): Promise<string>;

  setNotValidatedTourWeek(id: string): Promise<string>;
}
