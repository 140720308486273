import { SelectionItem } from "@/forms/ViewModelFormTypes";

export class CreateStoreItemViewModel {
  public visibleView = false;
  public isEdit = false;

  public store: { selected: string | string[]; items: SelectionItem[] } = {
    selected: [],
    items: []
  };
  public itemTemplate: {
    selected: string | string[];
    items: SelectionItem[];
  } = {
    selected: [],
    items: []
  };
  public minimalAmount = "";

  public loading = false;
  public error = "";
}
