import { Form, FormFieldType } from "@/forms/Form";

export class TimeTrackingForm extends Form {
  protected definition = {
    startTime: {
      required: true,
      type: FormFieldType.Text,
      regex: /^\d+:\d{2}$/
    },
    endTime: { required: true, type: FormFieldType.Text, regex: /^\d+:\d{2}$/ },
    breakDuration: { required: true, type: FormFieldType.Number, precision: 2 }
  };
}
