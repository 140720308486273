export class PlantDataTabController {
  public constructor(private presenter: IPlantDataTabPresenter) {}

  public mounted(workOrder: any) {
    this.presenter.data = workOrder;
  }

  public countrySelected(country: string) {
    this.presenter.countrySelected(country);
  }

  public get data() {
    return this.presenter.data;
  }
}

export interface IPlantDataTabPresenter {
  data: any;
  countrySelected(value: string): void;
}
