import { Selection, Date as DateSelection } from "@/forms/ViewModelFormTypes";
import { DateUtils } from "@/utils/DateUtils";
import { TimeTrackingDay } from "../services/TimeTrackingDayService";

export class TimeTrackingViewModel {
  public loading = 0;
  public weeks: Week[] = [];
  public filterEmployee: Selection = {
    selected: "",
    error: "",
    items: [],
    label: "Mitarbeiter"
  };
  public filterWeekNumber: Selection = {
    selected: "",
    error: "",
    items: [],
    label: "Kalenderwoche"
  };
  public filterDateFrom: DateSelection = {
    value: "",
    error: "",
    text: "",
    label: "Von"
  };
  public filterDateTo: DateSelection = {
    value: "",
    error: "",
    text: "",
    label: "Bis"
  };

  public allowedDates(val: string) {
    const weekday = DateUtils.weekday(val);
    return weekday !== 0 && weekday !== 6;
  }
}

export interface Week {
  days: TimeTrackingDay[];
  weekNumber: number;
  year: number;
}
