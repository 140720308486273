var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{attrs:{"disabled":true,"title":"TOURENPLANUNG"}},[(_vm.showSidebar)?_c('RightSideBar',{staticStyle:{"padding":"120px 0"},attrs:{"shown":_vm.rightSideBarShown,"colorString":"background","width":500,"mobileWidth":500},on:{"shown-changed":function($event){_vm.sidebarShown = $event}}},[_c('WeeklyScheduleRightMenu',{attrs:{"controller":_vm.controller,"vm":_vm.vm},on:{"weekChanged":function($event){_vm.controller.weekDateSelected($event);
        _vm.propsChanged();},"serviceEngineerChanged":function($event){_vm.controller.serviceEngineerSelected($event);
        _vm.propsChanged();}}})],1):_vm._e(),_c('router-view'),[_c('Container',{attrs:{"margin":0,"fluid":""}},[_c('Row',[_c('Column',{attrs:{"xs12":"","lg9":"","pr-10":"","pl-4":"","my-1":""}},[(_vm.controller.canEdit())?_c('div',{staticStyle:{"float":"left"}},[_c('IconButton',{attrs:{"filled":true,"icon":"arrow_back","tooltipText":"Zurück zur Karte"},on:{"click":function($event){return _vm.backToMap()}}})],1):_vm._e(),(!_vm.vm.loading)?_c('div',{staticStyle:{"float":"right"}},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.vm.initialTourWeekLoading},on:{"click":function($event){_vm.controller.weekLeft();
                _vm.propsChanged();}}},[_c('v-icon',[_vm._v("keyboard_arrow_left")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.vm.initialTourWeekLoading},on:{"click":function($event){_vm.controller.weekRight();
                _vm.propsChanged();}}},[_c('v-icon',[_vm._v("keyboard_arrow_right")])],1),_c('font',{staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(_vm.controller.getMonthOfTourDays()))]),_c('v-chip',{staticClass:"mx-3",attrs:{"label":"","small":""}},[_vm._v(_vm._s(_vm.controller.getCalendarWeekOfSelected()))])],1):_vm._e()])],1),_c('Row',[(_vm.vm.error)?_c('Column',{staticStyle:{"border-radius":"20px","background-color":"white","text-align":"center"},attrs:{"xs12":"","lg8":"","mx-4":"","py-4":""}},[_vm._v(_vm._s(_vm.vm.error))]):_c('Column',{staticStyle:{"border-radius":"20px","background-color":"white"},attrs:{"xs12":"","lg9":"","mx-4":""}},[_c('Schedule',{attrs:{"loading":_vm.vm.loading,"draggable":true,"showChangeTourDayPropsButton":true,"tourWeek":_vm.vm.tourWeek,"viewRouteMode":_vm.vm.viewRouteMode || _vm.vm.initialTourWeekLoading,"absolute":false,"coloredCards":false},on:{"editTourDayPropsConfirmed":function($event){return _vm.controller.editTourDayPropsConfirmed(
                $event.tourDay,
                $event.args
              )},"tourDayChanged":function($event){return _vm.controller.tourDayChanged($event.tourDay, $event.event)},"removeAppointmentFromTourDays":function($event){return _vm.controller.removeAppointmentFromTourDays(
                $event.tourDay,
                $event.appointmentIndex,
                $event.cancellationReason
              )},"editAppointmentPropsConfirmed":function($event){return _vm.controller.editAppointmentPropsConfirmed(
                $event.appointment,
                $event.event
              )},"open-tour-day-map":function($event){return _vm.openTourDayMap($event)}}})],1),(!_vm.showSidebar)?_c('Column',{attrs:{"xs3":"","mx-4":""}},[_c('WeeklyScheduleRightMenu',{attrs:{"controller":_vm.controller,"vm":_vm.vm},on:{"weekChanged":function($event){_vm.controller.weekDateSelected($event);
              _vm.propsChanged();},"serviceEngineerChanged":function($event){_vm.controller.serviceEngineerSelected($event);
              _vm.propsChanged();}}})],1):_vm._e()],1)],1)],_c('Dialog',{attrs:{"value":!!_vm.vm.routeError,"maxWidth":"400"}},[_c('v-card',{attrs:{"light":""}},[_c('v-card-title',[_vm._v("Fehler bei der Routenberechnung")]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.vm.routeError)}}),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.vm.routeError = ''}}},[_vm._v("Bestätigen")])],1)],1)],1),_vm._l((_vm.vm.components),function(component,index){return _c(component.component,{key:("p-" + index),tag:"component",attrs:{"tourDay":component.props},on:{"finished":function($event){return _vm.controller.getAlreadySelectedAppointments()}}})}),(_vm.viewedTourDay)?_c('tour-day-map',{attrs:{"tourDay":_vm.viewedTourDay},on:{"closed":function($event){_vm.viewedTourDay = null}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }