import { ICreateOperatorPresenter } from "../controllers/CreateOperatorController";
import { AddressForm } from "@/forms/common/AddressForm";
import { FormResponse } from "@/forms/FormResponse";
import { OperatorDataForm } from "@/forms/operator/OperatorDataForm";
import { PhoneDataForm } from "@/forms/person/PhoneDataForm";
import { PhoneDataSubForm } from "@/forms/person/PhoneDataSubForm";
import { PersonDataForm } from "@/forms/person/PersonDataForm";
import { CreateOperatorViewModel } from "../vms/CreateOperatorViewModel";
import { CreateOperatorPhoneNumberViewModel } from "../vms/CreateOperatorPhoneNumberViewModel";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { Operator } from "../services/OperatorGraphQLService";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { SalutationStorageHandler } from "@/storage/storageHandlers/SalutationStorageHandler";
import { Form } from "@/forms/Form";

export class CreateOperatorPresenter implements ICreateOperatorPresenter {
  public personDataForm: PersonDataForm;
  public addressForm: AddressForm;
  public phoneDataForm: PhoneDataForm;
  public operatorDataForm: OperatorDataForm;

  private areaCodes: any[] = [];

  public constructor(
    private vm: CreateOperatorViewModel,
    private storage: SimpleStringStorage
  ) {
    this.personDataForm = new PersonDataForm(
      this.vm,
      this.setPersonDataFormValid
    );
    this.addressForm = new AddressForm(this.vm, this.setAddressFormValid);
    this.phoneDataForm = new PhoneDataForm(this.vm, this.setPhoneDataFormValid);
    this.operatorDataForm = new OperatorDataForm(
      this.vm,
      this.setOperatorDataFormValid
    );

    this.personDataForm.init();
    this.addressForm.init();
    this.addressForm.addFieldChangedListener(
      "country",
      this.countrySelected,
      this
    );
    this.phoneDataForm.init();
    this.operatorDataForm.init();

    this.initSelections();
  }

  public setPersonDataFormValid(context: any, valid: boolean) {
    context.openAddressDataButtonDisabled = !valid;
  }

  public setAddressFormValid(context: any, valid: boolean) {
    context.openPhoneDataButtonDisabled = !valid;
  }

  public setPhoneDataFormValid(context: any, valid: boolean) {
    context.openOperatorDataButtonDisabled = !valid;
  }

  public setOperatorDataFormValid(context: any, valid: boolean) {
    context.createOperatorButtonDisabled = !valid;
  }

  public set visibleView(visible: boolean) {
    this.vm.visibleView = visible;
    if (!visible) {
      setTimeout(() => (this.vm.goBack = true), 300);
    }
  }

  public set personDataStepCompleted(completed: boolean) {
    this.vm.personDataStepCompleted = completed;
    this.vm.formStep = completed ? 2 : 1;
  }

  public set addressDataStepCompleted(completed: boolean) {
    this.vm.addressDataStepCompleted = completed;
    this.vm.formStep = completed ? 3 : 2;
  }

  public set phoneDataStepCompleted(completed: boolean) {
    this.vm.phoneDataStepCompleted = completed;
    this.vm.formStep = completed ? 4 : 3;
  }

  public set operatorDataStepCompleted(completed: boolean) {
    this.vm.phoneDataStepCompleted = completed;
    this.vm.formStep = completed ? 5 : 4;
  }

  public set createOperatorResponse(response: FormResponse<string>) {
    this.vm.createOperatorRequest = response;

    if (!response.loading && !response.error) {
      this.vm.createdOperator = response.data;
    }
  }

  public addPhoneDataSubForm(): Form {
    const phoneNumberVm = new CreateOperatorPhoneNumberViewModel();
    phoneNumberVm.areaCode.items = this.areaCodes;
    const subForm = new PhoneDataSubForm(
      phoneNumberVm,
      () => undefined,
      this.phoneDataForm
    );
    subForm.init();
    this.phoneDataForm.addSubForm(subForm);
    this.vm.phoneNumbers.push(phoneNumberVm);
    this.vm.openOperatorDataButtonDisabled = true;
    return subForm;
  }

  public removePhoneDataSubForm(index: number) {
    this.phoneDataForm.removeSubFormByIndex(index);
    this.vm.phoneNumbers.splice(index, 1);
    if (
      this.vm.phoneNumbers.every(
        field => !!field.areaCode.selected && !!field.phoneNumber.value
      )
    ) {
      this.vm.openOperatorDataButtonDisabled = false;
    }
    if (this.vm.phoneNumbers.length === 0) {
      this.vm.openOperatorDataButtonDisabled = false;
    }
  }

  public standardChanged(checked: boolean, index: number) {
    this.vm.phoneNumbers.forEach((element, i) => {
      if (index === i) {
        element.isStandard.value = true;
      } else {
        element.isStandard.value = false;
      }
    });
  }

  public mounted(): void {
    this.vm.visibleView = true;
  }

  public get operator(): Operator {
    return {
      id: this.vm.id,

      firstName: this.vm.firstName.value,
      lastName: this.vm.lastName.value,
      degrees: this.vm.degrees.selected,
      company: this.vm.company.value,
      salutation: this.vm.salutation.selected,

      address: {
        remarks: this.vm.addressRemarks.value,
        street: this.vm.street.value,
        zip: this.vm.zip.value,
        city: this.vm.city.value,
        country: this.vm.country.selected,
        state: this.vm.state.selected
      },

      eMail: this.vm.mail.value,
      website: this.vm.website.value,
      uid: this.vm.uid.value,
      dateOfPayment: this.vm.dateOfPayment.value,
      cashBackPercent: this.vm.cashBackPercent.value,
      cashBackDays: this.vm.cashBackDays.value,

      phoneNumbers: this.vm.phoneNumbers.map(field => ({
        areaCode: field.areaCode.selected,
        phoneNumber: field.phoneNumber.value,
        remarks: field.phoneAddition.value,
        isStandard: field.isStandard.value
      })),

      vulgoName: this.vm.vulgoName.value,
      plantIds: this.vm.plants.selected
    };
  }

  public set operator(operator: Operator) {
    this.vm.id = operator.id;

    for (const degree of operator.degrees) {
      this.personDataForm.setFieldValue("degrees", degree);
    }
    this.personDataForm.setFieldValue("salutation", operator.salutation);
    this.personDataForm.setFieldValue("firstName", operator.firstName);
    this.personDataForm.setFieldValue("lastName", operator.lastName);
    this.personDataForm.setFieldValue("company", operator.company);
    this.personDataForm.setFieldValue("mail", operator.eMail);
    this.personDataForm.setFieldValue("website", operator.website);

    this.addressForm.setFieldValue("addressRemarks", operator.address.remarks);
    this.addressForm.setFieldValue("street", operator.address.street);
    this.addressForm.setFieldValue("zip", operator.address.zip);
    this.addressForm.setFieldValue("city", operator.address.city);
    this.addressForm.setFieldValue("country", operator.address.country);
    this.addressForm.setFieldValue("state", operator.address.state);

    for (const plantId of operator.plantIds) {
      this.operatorDataForm.setFieldValue("plants", plantId);
    }
    this.operatorDataForm.setFieldValue("vulgoName", operator.vulgoName);
    this.operatorDataForm.setFieldValue("uid", operator.uid);
    this.operatorDataForm.setFieldValue(
      "dateOfPayment",
      operator.dateOfPayment?.toString() ?? ""
    );
    this.operatorDataForm.setFieldValue(
      "cashBackPercent",
      operator.cashBackPercent?.toString() ?? ""
    );
    this.operatorDataForm.setFieldValue(
      "cashBackDays",
      operator.cashBackDays?.toString() ?? ""
    );

    for (const phoneNumber of operator.phoneNumbers) {
      const form = this.addPhoneDataSubForm();
      form.setFieldValue("areaCode", phoneNumber.areaCode);
      form.setFieldValue("phoneNumber", phoneNumber.phoneNumber);
      form.setFieldValue("phoneAddition", phoneNumber.remarks);
      form.setFieldValue(
        "isStandard",
        phoneNumber.isStandard ? "true" : "false"
      );
    }
  }

  private initSelections() {
    this.vm.country.items = CountriesStorageHandler.getAllCountries();

    this.vm.degrees.items = [{ value: "phd", text: "PhD" }];

    this.vm.salutation.items = SalutationStorageHandler.getAllSalutationLongForms();

    this.areaCodes = CountriesStorageHandler.getAreaCodes();
  }

  private countrySelected(context: any, value: string) {
    context.vm.state.items = CountriesStorageHandler.getStatesFromCountry(
      value
    );
  }
}
