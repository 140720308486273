var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.vm.sections && _vm.vm.sections.length > 0)?_c('Accordion',{directives:[{name:"show",rawName:"v-show",value:(_vm.shown),expression:"shown"}],staticClass:"pa-2",attrs:{"items":[_vm.vm.sections],"eager":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('h3',[_vm._v("Prüfbericht")])]}},{key:"content",fn:function(ref){
var sections = ref.item;
return [_c('Accordion',{staticClass:"pa-2",attrs:{"items":sections,"eager":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var item = ref.item;
return [_c('Container',{attrs:{"margin":0,"fluid":""}},[_c('Row',{attrs:{"align-center":""}},[(item.checkAvailability)?_c('Column',{attrs:{"shrink":"","pr-2":""}},[_c('validated-switch',{staticClass:"ma-0 pa-0",attrs:{"disabled":!_vm.editEnabled,"vid":((item.name) + "_enabled"),"rules":{ required: _vm.shown },"inset":"","hide-details":"","color":"primary","noTopPadding":true,"value":item.enabled},on:{"click":function($event){$event.stopPropagation();item.enabled = !item.enabled}}})],1):_vm._e(),_c('Column',[_c('h4',[_vm._v(_vm._s(item.name))])])],1)],1)]}},{key:"content",fn:function(ref){
var item = ref.item;
return [_c('Container',{attrs:{"margin":0,"fluid":""}},[(!item.checkAvailability || item.enabled)?_c('div',_vm._l((item.fields),function(field,fieldName){return _c('Row',{key:((item.name) + "-" + fieldName),attrs:{"align-center":"","pa-2":""}},[(field.checkAvailability)?_c('Column',{attrs:{"shrink":""}},[_c('validated-switch',{staticClass:"ma-0 pa-0",attrs:{"disabled":!_vm.editEnabled,"vid":((item.name) + "-" + fieldName + "_enabled"),"rules":{ required: _vm.shown },"inset":"","hide-details":"","color":"primary","noTopPadding":true,"value":field.enabled},on:{"click":function($event){$event.stopPropagation();field.enabled = !field.enabled}}})],1):_vm._e(),_c('Column',{attrs:{"xs4":"","lg3":""}},[_vm._v(_vm._s(field.name))]),_c('Column',{class:field.component === 'ValidatedSwitch' ? 'pl-4' : '',attrs:{"shrink":field.component === 'ValidatedSwitch'}},[_c(field.component,{tag:"component",staticClass:"ma-0 pa-0",attrs:{"items":field.items,"rules":{
                    required:
                      _vm.shown &&
                      (!item.checkAvailability || item.enabled) &&
                      (!field.checkAvailability || field.enabled)
                  },"readonly":!_vm.editEnabled || !field.enabled,"multiple":field.multiple,"color":"primary","outlined":"","inset":"","hideDetails":true,"suffix":field.suffix,"prefix":field.prefix,"label":undefined,"noTopPadding":true},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1),(field.component === 'ValidatedSwitch')?_c('Column',{attrs:{"grow":""}},[_c('div',{staticClass:"px-2"},[_vm._v(" "+_vm._s(field.value ? "(Ja)" : "(Nein)")+" ")])]):_vm._e()],1)}),1):_vm._e()])]}}],null,true)})]}}],null,false,1876666411)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }