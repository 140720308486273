import { GraphQLConnection } from "@/gateways/graphql/GraphQLConnection";

export class GraphQLPermissionService implements PermissionService {
  public constructor(private connection: GraphQLConnection) {}

  public async getAllPermissions() {
    const result = await this.connection.query("permissions", {}, [
      "id",
      "domain",
      "label"
    ]);
    return {
      permissions: result.data.map((item: any) => ({
        id: item.id,
        domain: item.domain,
        label: item.label
      }))
    };
  }
}

export interface PermissionService {
  getAllPermissions(): Promise<PermissionList>;
}

export interface PermissionList {
  permissions: Permission[];
}

export interface Permission {
  id: string;
  domain: string;
  label: string;
}
