import UserRoleListView from "@/employee/views/UserRoleListView.vue";
import UserRoleDetailView from "@/employee/views/UserRoleDetailView.vue";
import EmployeeListView from "@/employee/views/EmployeeListView.vue";
import EmployeeDetailView from "@/employee/views/EmployeeDetailView.vue";
import TimeTrackingView from "@/employee/views/TimeTracking.vue";
import { Permission } from "@/common/utils/Permissions";

export default [
  {
    path: "/user-roles",
    name: "user-roles",
    component: UserRoleListView,
    meta: {
      permission: Permission.EditRoles
    }
  },
  {
    path: "/user-role/:id?",
    name: "user-role",
    component: UserRoleDetailView,
    meta: {
      permission: Permission.EditRoles
    }
  },
  {
    path: "/employees",
    name: "employees",
    component: EmployeeListView,
    meta: {
      permission: Permission.EditEmployees
    }
  },
  {
    path: "/employee/:id?",
    name: "employee",
    component: EmployeeDetailView,
    meta: {
      permission: Permission.EditEmployees
    }
  },
  {
    path: "/time-tracking",
    name: "time-tracking",
    component: TimeTrackingView
  }
];
