import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { CustomerServiceReportStorageHandler } from "@/storage/storageHandlers/CustomerServiceReportStorageHandler";
import { DateUtils } from "@/utils/DateUtils";
import { IPlantDataTabPresenter } from "../controllers/PlantDataTabController";
import { PlantDataTabViewModel } from "../vms/PlantDataTabViewModel";

export class PlantDataTabPresenter implements IPlantDataTabPresenter {
  public constructor(private vm: PlantDataTabViewModel) {
    this.initSelections();
  }

  public countrySelected(value: string): void {
    this.vm.state.items = CountriesStorageHandler.getStatesFromCountry(value);
  }

  public set data(workOrder: any) {
    if (workOrder) {
      this.vm.addressRemarks = workOrder.addressRemarks;
      this.vm.street = workOrder.addressStreet;
      this.vm.zip = workOrder.addressZip;
      this.vm.city = workOrder.addressCity;
      this.vm.state.selected = workOrder.addressState;
      this.vm.country.selected = workOrder.addressCountry;
      this.countrySelected(this.vm.country.selected);
      this.vm.plantTypeData = workOrder.plantType;
      this.vm.reasonData = workOrder.reason;
      this.vm.plannedDate = DateUtils.getDate(
        workOrder.plannedDate
      ).toISOString();
    }
  }

  public get data() {
    let plantType = this.vm.plantTypeData;
    if (!plantType) {
      plantType = `${this.vm.plantType.selected} ${this.vm.plantTypeAdditionalText}`;
    }

    let reason = this.vm.reasonData;
    if (!reason) {
      const reasons = [];
      for (const r of this.vm.reason.selected) {
        if (typeof r === "string") {
          reasons.push(r);
        } else if (typeof r === "object") {
          let toAdd = (r as any).text;
          if ((r as any).value.additionalTextField) {
            toAdd += `: ${(r as any).value.additionalText}`;
          }
          reasons.push(toAdd);
        }
      }

      reason = reasons.join(", ");
    }

    let plannedDate = this.vm.plannedDate;
    if (!plannedDate) {
      plannedDate = new Date().toISOString();
    }

    return {
      addressRemarks: this.vm.addressRemarks,
      addressStreet: this.vm.street,
      addressZip: this.vm.zip,
      addressCity: this.vm.city,
      addressState: this.vm.state.selected,
      addressCountry: this.vm.country.selected,
      plantType,
      reason,
      plannedDate
    };
  }

  private initSelections() {
    this.vm.country.items = CountriesStorageHandler.getAllCountries();
    this.vm.reason.items = CustomerServiceReportStorageHandler.getAllReasons();
    this.vm.plantType.items = CustomerServiceReportStorageHandler.getAllPlantTypes();
  }
}
