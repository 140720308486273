import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { OrderByClause } from "@/gateways/graphql/GraphQLConnection";
import { StorageHolder } from "@/storage/StorageHolder";
import { ItemTemplate } from "./ItemTemplateGraphQLService";
import { IItemTemplateService } from "./ItemTemplateService";

export class ItemTemplateLocalService implements IItemTemplateService {
  private itemTemplateStorage = StorageHolder.ItemTemplateStorage;

  public get timestamp() {
    return this.itemTemplateStorage.timestamp;
  }

  public set itemTemplates(itemTemplates: ItemTemplate[]) {
    this.itemTemplateStorage.itemTemplates = itemTemplates;
    this.itemTemplateStorage.save();
  }

  public save() {
    this.itemTemplateStorage.save();
  }

  public async getAllItemTemplates(): Promise<ItemTemplate[]> {
    return this.itemTemplateStorage.itemTemplates;
  }

  public async getAllItemTemplatesPaginated(
    page: Page,
    order: OrderByClause[],
    search: string
  ): Promise<PaginatedList<ItemTemplate>> {
    throw new Error("Not available in offline mode");
  }

  public async getAllItemTemplatesBySupplier(
    supplierId: string
  ): Promise<ItemTemplate[]> {
    throw new Error("Not available in offline mode");
  }

  public async getItemTemplateById(id: string): Promise<ItemTemplate> {
    throw new Error("Not available in offline mode");
  }

  public async getItemTemplateByItemNumber(
    itemNumber: string
  ): Promise<ItemTemplate> {
    throw new Error("Not available in offline mode");
  }

  public async updateItemTemplate(input: any): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public async createItemTemplate(input: any): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public async deleteItemTemplate(id: string): Promise<string> {
    throw new Error("Not available in offline mode");
  }

  public getToSyncStoreItems() {
    return this.itemTemplateStorage.getToSyncStoreItems();
  }
}
