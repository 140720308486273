import { ISchedulePresenter } from "../controllers/ScheduleController";
import { ScheduleViewModel } from "../vms/ScheduleViewModel";

export class SchedulePresenter implements ISchedulePresenter {
  public constructor(private vm: ScheduleViewModel) {}

  public init() {
    // Init
  }
}
