

















































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import IconButton from "@/components/basic/IconButton.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

import ValidatedCombobox from "@/components/form/vee-validate/ValidatedCombobox.vue";
import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedTextarea from "@/components/form/vee-validate/ValidatedTextarea.vue";
import HoverableDatatable from "@/components/collection/HoverableDatatable.vue";

import { SendReportToLabViewModel } from "@/report/vms/SendReportToLabViewModel";
import { SendReportToLabPresenter } from "@/report/presenters/SendReportToLabPresenter";
import { SendReportToLabController } from "@/report/controllers/SendReportToLabController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { WorkOrderService } from "../services/WorkOrderService";

@Component<SendReportToLabView>({
  components: {
    Page,
    Card,
    Container,
    Column,
    Row,
    ValidatedCombobox,
    ValidatedTextfield,
    ValidatedTextarea,
    HoverableDatatable,
    IconButton,
    RequestButton
  },
  watch: {
    "vm.finished"(newVal) {
      if (newVal) {
        this.$router.push({ name: "work-order-meta" });
      }
    }
  }
})
export default class SendReportToLabView extends Vue {
  private vm = new SendReportToLabViewModel();
  private controller?: SendReportToLabController;

  private initController(): SendReportToLabController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new SendReportToLabController(
      new SendReportToLabPresenter(this.vm),
      new WorkOrderService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$route.params.week);
  }

  private openPdf(location: string) {
    window.open(location, "_blank");
  }
}
