import { FormResponse } from "@/forms/FormResponse";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { StoreItem } from "@/store/services/StoreItemGraphQLService";
import { DateUtils } from "@/utils/DateUtils";
import { IGeneralTabPresenter } from "../controllers/GeneralTabController";
import { GeneralTabViewModel } from "../vms/GeneralTabViewModel";

export class GeneralTabPresenter implements IGeneralTabPresenter {
  public constructor(private vm: GeneralTabViewModel) {}

  public changeUsedTime(changedAmount: number): void {
    if (this.vm.usedTime.value > -changedAmount) {
      const newTime = this.vm.usedTime.value + changedAmount;
      this.vm.usedTime = {
        value: newTime,
        text: DateUtils.timeToString(newTime)
      };
    } else {
      this.vm.usedTime = { value: 0, text: "00:00" };
    }
  }

  public set doneTasks(doneTasks: {
    selected: string[];
    items: SelectionItem[];
  }) {
    this.vm.doneTasks = { ...doneTasks };
  }

  public get doneTasks(): { selected: string[]; items: SelectionItem[] } {
    return this.vm.doneTasks;
  }

  public set usedMaterialLocalStorage(usedMaterial: SelectionItem[]) {
    this.vm.usedMaterialLocalStorage = usedMaterial;
    this.vm.usedMaterial.items = [
      ...this.vm.usedMaterialLocalStorage,
      ...this.vm.usedMaterialStore
    ];
  }

  public set getUsedMaterialResponse(response: FormResponse<StoreItem[]>) {
    if (!response.error && !response.loading) {
      this.vm.usedMaterialStore = response.data.map(el => ({
        text: el.itemTemplate.name,
        value: el.id
      }));
      this.vm.usedMaterial.items = [
        ...this.vm.usedMaterialLocalStorage,
        ...this.vm.usedMaterialStore
      ];

      for (let i = 0; i < this.vm.usedMaterial.selected.length; i++) {
        const entry = this.vm.usedMaterial.selected[i];
        const foundItemIndex = response.data.findIndex(
          el => el.id === entry.value || el.itemTemplateId === entry.value
        );
        if (foundItemIndex >= 0) {
          const item = response.data[foundItemIndex];
          this.vm.usedMaterial.selected.splice(i, 1, {
            text: item.itemTemplate.name,
            value: entry.value,
            amount: entry.amount
          });
        }
      }
    }
  }

  public set selectedUsedMaterial(selected: any[]) {
    this.vm.usedMaterial.selected = [];
    for (const mat of selected) {
      let toAdd = mat;
      if (!toAdd.value) {
        toAdd = { value: toAdd, text: toAdd };
      }
      if (!toAdd.amount) {
        toAdd.amount = 1;
      }
      this.vm.usedMaterial.selected.push(toAdd);
    }
    this.vm.usedMaterial.selected = selected;
  }

  public set initialUsedMaterial(material: { [key: string]: number }) {
    for (const key of Object.keys(material)) {
      this.vm.usedMaterial.selected.push({
        value: key,
        text: key,
        amount: material[key]
      });
    }
  }

  public get usedMaterial(): {
    selected: Array<{ value: string; text: string; amount: number }>;
    items: SelectionItem[];
  } {
    return this.vm.usedMaterial;
  }

  public set usedTime(usedTime: { value: number; text: string }) {
    this.vm.usedTime = usedTime;
  }

  public get usedTime(): { value: number; text: string } {
    return this.vm.usedTime;
  }

  public set usedTimeValueOnly(time: number) {
    this.vm.usedTime = {
      value: time,
      text: DateUtils.timeToString(time)
    };
  }
}
