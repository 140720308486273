import { ServiceFacade } from "../services/ServiceFacade";

export class LayoutController {
  private serviceFacade: ServiceFacade;

  public constructor(private presenter: ILayoutPresenter) {
    this.serviceFacade = new ServiceFacade();
  }

  public init() {
    // Init
  }

  public commitSearch(searchText: string) {
    this.presenter.search = searchText;
  }

  public navigationIconClicked() {
    this.presenter.showNavigation = !this.presenter.showNavigation;
  }
}

export interface ILayoutPresenter {
  search: string;
  showNavigation: boolean;
}
