




































































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";
import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";

import RequestButton from "@/components/basic/RequestButton.vue";

import { CreateSupplierOrderViewModel } from "@/store/vms/CreateSupplierOrderViewModel";
import { CreateSupplierOrderPresenter } from "@/store/presenters/CreateSupplierOrderPresenter";
import { CreateSupplierOrderController } from "@/store/controllers/CreateSupplierOrderController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { SupplierGraphQLService } from "../services/SupplierGraphQLService";

@Component<CreateSupplierOrderView>({
  components: {
    ValidationObserver,
    Card,
    Container,
    Column,
    Row,
    ValidatedSelect,
    ValidatedTextfield,
    RequestButton
  },
  watch: {
    "vm.visibleView"(newValue) {
      if (!newValue) {
        setTimeout(() => {
          this.$destroy();
          if (this.$router.currentRoute.name === "supplier-order-create") {
            this.$nextTick(() => this.$router.back());
          }
        }, 500);
      }
    }
  }
})
export default class CreateSupplierOrderView extends Vue {
  private vm = new CreateSupplierOrderViewModel();
  private controller?: CreateSupplierOrderController;

  private initController(): CreateSupplierOrderController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreateSupplierOrderController(
      new CreateSupplierOrderPresenter(this.vm),
      new SupplierGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(
      this.$route.params.supplierId,
      this.$route.params.supplierOrderId
    );
  }
}
