













































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import Column from "@/components/layout/Column.vue";
import Row from "@/components/layout/Row.vue";

import RequestButton from "@/components/basic/RequestButton.vue";
import IconButton from "@/components/basic/IconButton.vue";

import Textfield from "@/components/form/Textfield.vue";
import Select from "@/components/form/Select.vue";

import SystemEntryField from "@/system/components/SystemEntryField.vue";

import { SystemTableEntryController } from "@/system/controllers/SystemTableEntryController";
import { SystemTableEntryPresenter } from "@/system/presenters/SystemTableEntryPresenter";
import { SystemTableEntryViewModel } from "@/system/vms/SystemTableEntryViewModel";
import { GraphQLSystemTableService } from "../services/SystemTableService";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { LocalStorage } from "../../storage/LocalStorage";
import { SystemTableUtils } from "../services/SystemTableUtils";

@Component<SystemTableEntryView>({
  components: {
    Page,
    Section,
    Row,
    Column,
    RequestButton,
    SystemEntryField
  },
  watch: {
    "vm.savedEntry"(id: string) {
      if (!!id) {
        this.$router.push({
          name: "system-table",
          params: { tableId: this.tableId }
        });
      }
    },
    "vm.deletedEntry"(id: string) {
      if (!!id) {
        this.$router.push({
          name: "system-table",
          params: { tableId: this.tableId }
        });
      }
    }
  }
})
export default class SystemTableEntryView extends Vue {
  private vm = new SystemTableEntryViewModel();
  private controller: any;

  private get tableId(): string {
    return this.$route.params.tableId;
  }

  private get entryId(): string {
    return this.$route.params.entryId;
  }

  private created() {
    this.controller = this.createController();
  }

  private mounted() {
    this.controller.init(this.tableId, this.entryId);
  }

  private createController(): SystemTableEntryController {
    const storage = new LocalStorage();
    return new SystemTableEntryController(
      new SystemTableEntryPresenter(this.vm, new SystemTableUtils(storage)),
      new GraphQLSystemTableService(
        new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
      )
    );
  }
}
