import Vue, { VueConstructor } from "vue";
import Router, { Route as VueRoute } from "vue-router";
import { LocalStorage } from "./storage/LocalStorage";

// Routes
import employeeRoutes from "@/employee/router";
import systemRoutes from "@/system/router";
import tourPlanningRoutes from "@/tourPlanner/router";
import reportRoutes from "@/report/router";
import plantRoutes from "@/plant/router";
import storeRoutes from "@/store/router";

// Pages
import Dashboard from "@/common/views/DashboardView.vue";
import SearchView from "@/common/views/SearchView.vue";
import LoginView from "@/common/views/LoginView.vue";
import LogoutView from "@/common/views/LogoutView.vue";
import StorageKeys from "./common/utils/StorageKeys";
import { Auth } from "./common/utils/Auth";
import { LogoutController } from "./common/controllers/LogoutController";

const localStorage = new LocalStorage();

Vue.use(Router);

interface Route {
  path: string;
  name: string;
  component: VueConstructor<Vue>;
  props?: boolean;
  children?: Route[];
  meta?: any;
}

const mainRoutes: Route[] = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard
  },
  {
    path: "/home/:action?",
    name: "home",
    component: Dashboard
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      guest: true
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutView
  },
  {
    path: "/search/:searchText?",
    name: "search",
    component: SearchView
  }
];

const allRoutes: Route[] = mainRoutes.concat(
  employeeRoutes,
  tourPlanningRoutes,
  systemRoutes,
  reportRoutes,
  plantRoutes,
  storeRoutes
);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: allRoutes
});

const logoutIntervalMs =
  (process.env.VUE_APP_LOGOUT_TIMEOUT
    ? parseInt(process.env.VUE_APP_LOGOUT_TIMEOUT, 10)
    : 10800) * 1000;
let logoutTimer = setTimeout(logout, logoutIntervalMs);

if (
  !localStorage.has(StorageKeys.lastChangeTime) ||
  parseInt(localStorage.get(StorageKeys.lastChangeTime), 10) +
    logoutIntervalMs <
    Date.now()
) {
  logout();
}

router.beforeEach((routeTo, routeFrom, next) => {
  if (userHasPermission(routeTo) && userIsOwner(routeTo)) {
    if (isGuestRoute(routeTo)) {
      isGuest() ? next() : next({ name: "home" });
    } else {
      if (isGuest()) {
        next({ name: "login" });
      } else {
        clearTimeout(logoutTimer);
        localStorage.set(StorageKeys.lastChangeTime, Date.now().toString());
        logoutTimer = setTimeout(logout, logoutIntervalMs);
        next();
      }
    }
  } else {
    next(false);
  }
});

function logout() {
  LogoutController.logout(localStorage);
  if (router.currentRoute.name !== "login") {
    router.push({ name: "login" });
  }
}

function userHasPermission(route: VueRoute) {
  const requiredPermission = route.meta.permission;
  return !requiredPermission || Auth.hasPermission(requiredPermission);
}

function userIsOwner(route: VueRoute) {
  const skipPermission = route.meta.skipPermission;
  const userIdParam = route.meta.userIdParam;
  const routeId = route.params[userIdParam];
  return (
    !userIdParam ||
    !routeId ||
    Auth.userId === routeId ||
    (!!skipPermission && Auth.hasPermission(skipPermission))
  );
}

function isGuestRoute(route: VueRoute) {
  return route.meta.guest === true;
}

function isGuest() {
  if (localStorage.has(StorageKeys.validUntil)) {
    const validUntil = localStorage.get(StorageKeys.validUntil);
    const validUntilDate = new Date(validUntil);
    const now = new Date();

    return validUntilDate.getTime() < now.getTime();
  }

  return true;
}

export default router;
