import { Textfield } from "@/forms/ViewModelFormTypes";

export class LoginViewModel {
  public loggedIn = false;

  public loginButtonLoading = false;
  public loginButtonError = "";
  public loginButtonDisabled = false;

  public username: Textfield = {
    label: "Username",
    value: "",
    loading: false,
    error: ""
  };

  public password: Textfield = {
    label: "Passwort",
    value: "",
    loading: false,
    error: ""
  };
}
