import { MultiSelect, Selection } from "@/forms/ViewModelFormTypes";

export class PlantDataTabViewModel {
  public addressRemarks = "";
  public street = "";
  public zip = "";
  public city = "";
  public country: Selection = { selected: "", error: "", items: [] };
  public state: Selection = { selected: "", error: "", items: [] };

  public plantType: Selection = { selected: "", error: "", items: [] };
  public plantTypeAdditionalText = "";
  public plantTypeData = "";
  public reason: MultiSelect = { selected: [], error: "", items: [] };
  public reasonData = "";
  public plannedDate?: string;
}
