






















































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Grid from "@/components/layout/Grid.vue";
import RightSideBar from "@/components/layout/RightSideBar.vue";
import Card from "@/components/layout/Card.vue";
import TabbedCard from "@/components/layout/TabbedCard.vue";
import RightHandCard from "@/components/layout/RightHandCard.vue";
import Page from "@/components/layout/Page.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import Datatable from "@/components/collection/Datatable.vue";
import ConfirmDialog from "@/components/basic/ConfirmDialog.vue";
import Timer from "@/components/basic/Timer.vue";
import ValidatedTextarea from "@/components/form/vee-validate/ValidatedTextarea.vue";
import KeyValue from "@/plant/views/KeyValue.vue";

import PlantSurroundingMap from "@/plant/views/PlantSurroundingMap.vue";
import PlantDataCard from "@/plant/views/PlantDataCard.vue";
import OperatorDataCard from "@/plant/views/OperatorDataCard.vue";

import DefectsTableTab from "./workOrderSubViews/DefectsTableTab.vue";
import OperatorDataTab from "./workOrderSubViews/OperatorDataTab.vue";
import PlantDataTab from "./workOrderSubViews/PlantDataTab.vue";
import ReportTab from "./workOrderSubViews/ReportTab.vue";
import GeneralTab from "./workOrderSubViews/GeneralTab.vue";
import SignatureTab from "./workOrderSubViews/SignatureTab.vue";
import WorkOrderPriceTab from "./workOrderSubViews/WorkOrderPriceTab.vue";

import { CreateWorkOrderViewModel } from "@/report/vms/CreateWorkOrderViewModel";
import { CreateWorkOrderPresenter } from "@/report/presenters/CreateWorkOrderPresenter";
import { CreateWorkOrderController } from "@/report/controllers/CreateWorkOrderController";
import { WorkOrderService } from "../services/WorkOrderService";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { EmployeeGraphQLService } from "../../employee/services/EmployeeService";

@Component<WorkOrderView>({
  components: {
    Grid,
    ValidationObserver,
    RightHandCard,
    Card,
    TabbedCard,
    Page,
    Container,
    Column,
    Row,
    Datatable,
    ConfirmDialog,
    PlantDataCard,
    OperatorDataCard,
    RightSideBar,
    Timer,
    ValidatedTextarea,
    KeyValue,
    PlantSurroundingMap,

    OperatorDataTab,
    PlantDataTab,
    GeneralTab,
    DefectsTableTab,
    ReportTab,
    SignatureTab,
    WorkOrderPriceTab
  },

  props: {
    rightSideBarShown: {
      type: Boolean
    }
  },
  watch: {
    "vm.createdWorkOrder"() {
      this.$emit("finished");
      this.$router.push({
        name: "work-orders"
      });
    }
  }
})
export default class WorkOrderView extends Vue {
  private vm = new CreateWorkOrderViewModel();
  private controller?: CreateWorkOrderController;

  private initController(): CreateWorkOrderController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreateWorkOrderController(
      new CreateWorkOrderPresenter(this.vm),
      new WorkOrderService(connection),
      new EmployeeGraphQLService(connection)
    );
  }

  private get isOnline(): boolean {
    return this.$store.state.isOnline;
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$route.params.workOrderId);
  }

  private createWorkOrder(setToCheck: boolean) {
    this.controller?.createWorkOrder(
      setToCheck,
      this.vm.workOrder?.serviceEngineerId,
      (this.$refs.reportTab as ReportTab)?.controller,
      (this.$refs.generalTab as GeneralTab).controller,
      (this.$refs.signatureTab as SignatureTab).controller,
      (this.$refs.operatorDataTab as OperatorDataTab)?.controller,
      (this.$refs.plantDataTab as PlantDataTab)?.controller
    );
  }

  private acceptWorkOrder() {
    this.controller?.acceptWorkOrderButtonClicked(
      (this.$refs.generalTab as GeneralTab).controller
    );
  }

  private rejectWorkOrder() {
    this.controller?.rejectWorkOrderAcceptButtonClicked(
      (this.$refs.generalTab as GeneralTab).controller
    );
  }

  private stopStopwatch(time: number) {
    (this.$refs.generalTab as GeneralTab).controller?.timerStopClicked(time);
  }

  private get showSidebar() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  private get sidebarShown() {
    return this.$props.rightSideBarShown;
  }

  private set sidebarShown(newValue: boolean) {
    this.$emit("sidebar-shown-changed", newValue);
  }
}
