import { IServiceEngineerWeeklySchedulePresenter } from "../controllers/ServiceEngineerWeeklyScheduleController";
import { ServiceEngineerWeeklyScheduleViewModel } from "../vms/ServiceEngineerWeeklyScheduleViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { DateUtils } from "@/utils/DateUtils";
import { ServiceEngineer } from "@/tourPlanner/services/ServiceEngineerGraphQLService";
import { Auth } from "@/common/utils/Auth";
import { TourWeekStorage } from "../utils/TourWeekStorage";

export class ServiceEngineerWeeklySchedulePresenter
  implements IServiceEngineerWeeklySchedulePresenter {
  public constructor(private vm: ServiceEngineerWeeklyScheduleViewModel) {
    this.makeTimeFrameItems();
  }

  public set response(response: FormResponse<any[]>) {
    if (!response.loading) {
      if (response.data && !response.error) {
        this.vm.tourWeek = response.data;
      } else {
        const tourWeek: any = {
          weekNumber: this.vm.timeFrameItems.selected,
          tourDays: []
        };

        const incrDate = new Date(this.vm.timeFrameItems.selected);
        for (let i = 0; i < 5; i++) {
          const tourDay = {
            id: i,
            index: i,
            date: new Date(incrDate),
            appointments: [],
            startTime: "08:00"
          };
          tourWeek.tourDays.push(tourDay);
          incrDate.setDate(incrDate.getDate() + 1);
        }
        this.vm.tourWeek = tourWeek;
      }
    }

    this.vm.loading = response.loading;
  }

  public set processingStatesResponse(response: FormResponse<any[]>) {
    if (!response.loading) {
      if (response.data && !response.error) {
        for (const tourday of this.vm.tourWeek.tourDays) {
          for (const app of tourday.appointments) {
            const index = response.data.findIndex(el => el.id === app.id);
            if (index >= 0) {
              app.processingState = response.data[index].processingState;
            }
          }
        }

        const dateFrom = new Date(this.vm.timeFrameItems.selected);
        const dateTo = new Date(dateFrom);
        dateTo.setDate(dateTo.getDate() + 6);
        const now = new Date();
        if (
          now < dateTo &&
          now >= dateFrom &&
          this.vm.serviceEngineers.selected === Auth.userId
        ) {
          TourWeekStorage.save(this.vm.tourWeek);
        }
      }
    }

    this.vm.colorsLoading = response.loading;
  }

  public set timeFrameItem(dateFrom: string) {
    this.vm.timeFrameItems.selected = dateFrom;
  }

  public get timeFrameItem() {
    return this.vm.timeFrameItems.selected;
  }

  public set serviceEngineersResponse(
    response: FormResponse<ServiceEngineer[]>
  ) {
    if (!response.loading && !response.error) {
      this.vm.serviceEngineers.items = [];

      for (const se of response.data) {
        this.vm.serviceEngineers.items.push({
          text: `${se.firstName} ${se.lastName}`,
          value: se.id
        });
      }
    }
  }

  public set currentServiceEngineer(serviceEngineer: string) {
    this.vm.serviceEngineers.selected = serviceEngineer;
  }

  public get currentServiceEngineer() {
    return this.vm.serviceEngineers.selected;
  }

  public set colorsLoading(colorsLoading: boolean) {
    this.vm.colorsLoading = colorsLoading;
  }

  private makeTimeFrameItems() {
    const now = DateUtils.removeTimeStamp(new Date());
    let date = new Date(now);
    date.setFullYear(date.getFullYear() - 1);

    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    date.setDate(diff);

    const endDate = new Date(now);
    endDate.setFullYear(endDate.getFullYear() + 1);

    const items = [];
    const selected = DateUtils.dateOnly(
      DateUtils.weekMondayFromDate(new Date())
    );
    while (date < endDate) {
      const nextWeekDate = new Date(date);
      nextWeekDate.setDate(nextWeekDate.getDate() + 4);
      const item = `KW ${DateUtils.weekNumber(
        date
      )}/${date.getFullYear()} (${DateUtils.formatDate(
        date
      )} - ${DateUtils.formatDate(nextWeekDate)})`;
      items.push({ text: item, value: DateUtils.dateOnly(date) });
      nextWeekDate.setDate(nextWeekDate.getDate() + 3);
      date = nextWeekDate;
    }

    this.vm.timeFrameItems = { items, selected, error: "" };
  }
}
