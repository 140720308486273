




























































































import Vue from "vue";
import Component from "vue-class-component";

import Column from "@/components/layout/Column.vue";
import Row from "@/components/layout/Row.vue";
import Grid from "@/components/layout/Grid.vue";
import Accordion from "@/components/layout/Accordion.vue";

import RequestButton from "@/components/basic/RequestButton.vue";
import IconButton from "@/components/basic/IconButton.vue";

import Checkbox from "@/components/form/Checkbox.vue";
import Textfield from "@/components/form/Textfield.vue";
import Select from "@/components/form/Select.vue";

import { SystemKeyChain } from "@/system/types/SystemKeyChain";
import { LocalStorage } from "../../storage/LocalStorage";

@Component<SystemEntryField>({
  components: {
    Grid,
    Row,
    Column,
    Accordion,
    Textfield,
    Select,
    Checkbox,
    RequestButton,
    IconButton,
    SystemEntryField: () => import("@/system/components/SystemEntryField.vue")
  },
  props: {
    fields: {
      type: Object,
      default: () => ({})
    },
    addLineButtonText: {
      type: String,
      default: "Zeile hinzufügen"
    },
    deleteLineButtonText: {
      type: String,
      default: "Zeile löschen"
    }
  }
})
export default class SystemEntryField extends Vue {
  private storage = new LocalStorage();

  private fieldChanged(
    keyChain: SystemKeyChain,
    subChain: SystemKeyChain,
    value: string
  ) {
    keyChain.push(...subChain);
    this.$emit("fieldChanged", keyChain, value);
  }

  private positionMoved(
    keyChain: SystemKeyChain,
    subChain: SystemKeyChain,
    event: any
  ) {
    keyChain.push(...subChain);
    this.$emit("position-moved", keyChain, event);
  }

  private addLine(keyChain: SystemKeyChain, subChain: SystemKeyChain) {
    keyChain.push(...subChain);
    this.$emit("lineAdded", keyChain);
  }

  private deleteLine(
    keyChain: SystemKeyChain,
    subChain: SystemKeyChain,
    index: number
  ) {
    keyChain.push(...subChain);
    this.$emit("lineDeleted", keyChain, index);
  }

  private getValueOfFirstField(fields: object, defaultValue: string): any {
    const fieldData = Object.values(fields);

    if (fieldData.length > 0) {
      const data = fieldData[0];

      if (data.value) {
        return data.value;
      } else {
        const rawSetting = this.storage.get(data.name);

        if (rawSetting) {
          const setting = JSON.parse(rawSetting);
          const ref = setting.find((s: any) => s.id === data.selected);

          if (ref) {
            const refData = Object.values(ref.values);

            if (refData.length > 0) {
              return refData[0];
            } else {
              return data.selected;
            }
          } else {
            return data.selected;
          }
        }
      }
    } else {
      return defaultValue;
    }
  }
}
