import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { OrderByClause } from "@/gateways/graphql/GraphQLConnection";
import { StorageHolder } from "@/storage/StorageHolder";
import { IStoreMovementService } from "./StoreMovementService";

export class StoreMovementLocalService implements IStoreMovementService {
  private storeMovementStorage = StorageHolder.StoreMovementStorage;

  public get timestamp() {
    return this.storeMovementStorage.timestamp;
  }

  public set storeMovements(storeMovements: any[]) {
    this.storeMovementStorage.storeMovements = storeMovements;
    this.storeMovementStorage.save();
  }

  public save() {
    this.storeMovementStorage.save();
  }

  public async getStoreMovementsForOverviewPaginated(
    page: Page,
    order: OrderByClause[],
    search: string,
    storeFilter: string[],
    itemTemplateFilter: string[],
    isPlant = false
  ): Promise<PaginatedList<any[]>> {
    let filteredItems = this.storeMovementStorage.storeMovements;
    if (storeFilter && storeFilter.length > 0) {
      filteredItems = filteredItems.filter(
        fi => storeFilter.findIndex(s => s === fi.storeId) >= 0
      );
    }

    if (itemTemplateFilter && itemTemplateFilter.length > 0) {
      filteredItems = filteredItems.filter(
        fi => itemTemplateFilter.findIndex(it => it === fi.itemTemplateId) >= 0
      );
    }

    const slicedItems = filteredItems.slice(
      page.itemsPerPage * (page.page - 1),
      page.itemsPerPage * page.page
    );

    return Promise.resolve({
      items: slicedItems,
      totalCount: filteredItems.length
    });
  }

  public async getStoreMovementById(id: string): Promise<any> {
    throw new Error("Not available in offline mode");
  }

  public async createStoreMovement(input: any): Promise<string> {
    this.storeMovementStorage.add(input);
    return input.id;
  }

  public async exportStoreMovements(): Promise<any> {
    throw new Error("Not available in offline mode");
  }

  public async updateStoreMovement(data: any): Promise<any> {
    throw new Error("Not available in offline mode");
  }

  public async deleteStoreMovement(id: string): Promise<any> {
    throw new Error("Not available in offline mode");
  }

  public getToSyncStoreMovements() {
    return this.storeMovementStorage.getToSyncStoreMovements();
  }
}
