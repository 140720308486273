import {
  SystemTableService,
  SystemTableStructure,
  SystemTableEntry
} from "../services/SystemTableService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { DynamicForm } from "@/forms/DynamicForm";
import { EnvironmentUtils } from "@/utils/EnvironmentUtils";
import { SystemKeyChain } from "../types/SystemKeyChain";

export class SystemTableEntryController {
  public constructor(
    private presenter: ISystemTableEntryPresenter,
    private systemTableService: SystemTableService
  ) {}

  public async init(tableId: string, entryId: string) {
    this.presenter.tableId = tableId;
    this.presenter.entryId = entryId;

    await this.loadTableStructure(tableId);
    EnvironmentUtils.execOnNextTick(async () => await this.loadEntry(entryId));
  }

  public fieldChanged(keyChain: SystemKeyChain, value: string) {
    this.presenter.setFieldValue(keyChain, value);
  }

  public lineAdded(keyChain: SystemKeyChain) {
    this.presenter.addListLine(keyChain);
  }

  public lineDeleted(keyChain: SystemKeyChain, index: number) {
    this.presenter.removeListLine(keyChain, index);
  }

  public lineMoved(keyChain: SystemKeyChain, movementEvent: any) {
    this.presenter.reorderListLine(keyChain, movementEvent);
  }

  public saveEntryButtonClicked() {
    const request = this.systemTableService.saveTableEntry(
      this.presenter.entry
    );

    FormRequestHandler.handle(
      request,
      response => (this.presenter.saveEntryResponse = response),
      "Systemeintrag konnte nicht gespeichert werden"
    );
  }

  public deleteEntryButtonClicked() {
    const request = this.systemTableService.deleteTableEntry(
      this.presenter.entryId
    );

    FormRequestHandler.handle(
      request,
      response => (this.presenter.deleteEntryResponse = response),
      "Systemeintrag konnte nicht gelöscht werden"
    );
  }

  private loadTableStructure(tableId: string) {
    const request = this.systemTableService.getTableStructure(tableId);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadTableStructureResponse = response),
      "Tabellenstruktur konnte nicht geladen werden"
    );

    return request;
  }

  private loadEntry(entryId: string) {
    if (!entryId) {
      return;
    }

    const request = this.systemTableService.getTableEntry(entryId);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadEntryResponse = response),
      "Eintrag konnte nicht geladen werden"
    );

    return request;
  }
}

export interface ISystemTableEntryPresenter {
  entryId: string;
  tableId: string;

  entry: SystemTableEntry;
  // Forms
  form: DynamicForm;

  // Responses
  loadTableStructureResponse: FormResponse<SystemTableStructure>;
  loadEntryResponse: FormResponse<SystemTableEntry>;
  saveEntryResponse: FormResponse<string>;
  deleteEntryResponse: FormResponse<string>;

  // Methods
  setFieldValue(keyChain: SystemKeyChain, value: string): void;
  addListLine(keyChain: SystemKeyChain): void;
  removeListLine(keyChain: SystemKeyChain, index: number): void;
  reorderListLine(keyChain: SystemKeyChain, movementEvent: any): void;
}
