import SystemKeys from "@/common/utils/SystemKeys";
import { StorageHandler } from "./StorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";

export class CustomerServiceReportStorageHandler extends StorageHandler {
  public static getAllReasons(): SelectionItem[] {
    if (this.storage.has(SystemKeys.customerServiceReasonsTableKey)) {
      const fields = JSON.parse(
        this.storage.get(SystemKeys.customerServiceReasonsTableKey)
      );

      return fields?.map((field: any) => ({
        text:
          field[SystemKeys.values][SystemKeys.customerServiceReasonsNameKey],
        value: {
          id: field[SystemKeys.id],
          additionalTextField:
            field[SystemKeys.values][
              SystemKeys.customerServiceReasonsAdditionalFieldKey
            ],
          additionalText: ""
        }
      }));
    }

    return [];
  }

  public static stringifyReasons(
    reasons: Array<string | SelectionItem>
  ): string {
    const stringifiedReasons = [];

    for (const reason of reasons) {
      let reasonAsString = "";

      if (typeof reason === "string") {
        reasonAsString = reason;
      } else if (typeof reason === "object") {
        const reasonObj = reason as any;
        reasonAsString = reasonObj.text;

        if (reasonObj.value.additionalTextField) {
          reasonAsString += `: ${reasonObj.value.additionalText}`;
        }
      }

      stringifiedReasons.push(reasonAsString);
    }

    return stringifiedReasons.join(", ");
  }

  public static parseReasons(reasons: string): SelectionItem[] {
    return reasons
      .split(",")
      .map((reason: string) => reason.trim())
      .filter((reason: string) => !!reason)
      .map(CustomerServiceReportStorageHandler.parseReason);
  }

  public static parseReason(reason: string): SelectionItem {
    const reasons = CustomerServiceReportStorageHandler.getAllReasons();
    const foundReason = reasons.find(r => reason.startsWith(r.text));

    if (foundReason) {
      const additionalText = reason.replace(`${foundReason.text}:`, "").trim();
      (foundReason.value as any).additionalText = additionalText;

      return foundReason;
    } else {
      return {
        text: reason,
        value: {
          id: "",
          additionalTextField: "",
          additionalText: ""
        }
      } as any;
    }
  }

  public static getAllPlantTypes(): SelectionItem[] {
    if (this.storage.has(SystemKeys.customerServicePlantTypeTableKey)) {
      const fields = JSON.parse(
        this.storage.get(SystemKeys.customerServicePlantTypeTableKey)
      );

      return fields?.map((field: any) => ({
        text:
          field[SystemKeys.values][SystemKeys.customerServicePlantTypeNameKey],
        value:
          field[SystemKeys.values][SystemKeys.customerServicePlantTypeNameKey]
      }));
    }

    return [];
  }
}
