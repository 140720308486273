import { Request, TableHeader } from "@/forms/ViewModelFormTypes";
import { InitViewModel } from "@/common/vms/InitViewModel";

export class SystemTableListViewModel extends InitViewModel {
  // Data
  public addNewEntry: boolean = false;
  public entryToEdit: string = "";
  public entries: SystemTableEntryViewDTO[] = [];

  // Texts
  public title: string = "";
  public addEntryButtonText: string = "";
  public editSystemEntryButtonText: string = "";

  // Table
  public headers: TableHeader[] = [];
}

export interface SystemTableEntryViewDTO {
  id: string;
  [key: string]: string;
}
