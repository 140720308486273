import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { StockTakingGraphQLService } from "../services/StockTakingGraphQLService";

export class StockTakingExport {
  public static async export() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    const stockTakingService = new StockTakingGraphQLService(connection);
    const downloadLink = await stockTakingService.exportStockTaking();

    const link = document.createElement("a");
    link.setAttribute("href", downloadLink);
    link.setAttribute("download", "download");
    link.setAttribute("target", "_blank");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
