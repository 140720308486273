




































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import TruncatedText from "@/components/layout/TruncatedText.vue";

import RequestButton from "@/components/basic/RequestButton.vue";
import IconButton from "@/components/basic/IconButton.vue";

import Datatable from "@/components/collection/Datatable.vue";

import { UserRoleListController } from "@/employee/controllers/UserRoleListController";
import { UserRoleListPresenter } from "@/employee/presenters/UserRoleListPresenter";
import { UserRoleListViewModel } from "@/employee/vms/UserRoleListViewModel";
import { GraphQLUserRoleService } from "../services/UserRoleService";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";

@Component<UserRoleListView>({
  components: {
    Page,
    Section,
    Row,
    Column,
    TruncatedText,
    RequestButton,
    IconButton,
    Datatable
  },
  watch: {
    "vm.addNewRole"(add: boolean) {
      if (add) {
        this.$router.push({ name: "user-role" });
      }
    },
    "vm.userRoleToEdit"(id: string) {
      this.$router.push({ name: "user-role", params: { id } });
    }
  }
})
export default class UserRoleListView extends Vue {
  private vm = new UserRoleListViewModel();
  private controller: any;

  private created() {
    this.controller = this.createController();
  }

  private mounted() {
    this.controller.init();
  }

  private createController(): UserRoleListController {
    return new UserRoleListController(
      new UserRoleListPresenter(this.vm),
      new GraphQLUserRoleService(
        new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
      )
    );
  }
}
