import { ITimeTrackingCardPresenter } from "../controllers/TimeTrackingCardController";
import { TimeTrackingCardViewModel } from "../vms/TimeTrackingCardViewModel";
import { TimeTrackingForm } from "../forms/TimeTrackingForm";
import { TimeTrackingDataForm } from "../forms/TimeTrackingDataForm";
import { TimeTrackingDataViewModel } from "../vms/TimeTrackingDataViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { Form } from "@/forms/Form";
import { StringUtils } from "@/utils/StringUtils";

export class TimeTrackingCardPresenter implements ITimeTrackingCardPresenter {
  public timeTrackingForm: TimeTrackingForm;

  public constructor(private vm: TimeTrackingCardViewModel) {
    this.timeTrackingForm = new TimeTrackingForm(
      this.vm,
      this.setTimeTrackingFormValid
    );

    this.vm.startTime.value = "";
    this.vm.endTime.value = "";

    this.timeTrackingForm.init();
  }

  public setTimeTrackingFormValid(context: any, valid: boolean) {
    context.openAddressDataButtonDisabled = !valid;
  }

  public set date(date: string) {
    this.vm.date = date;
  }

  public set expanded(expanded: boolean) {
    this.vm.expanded = expanded;
  }

  public get expanded(): boolean {
    return this.vm.expanded;
  }

  public set loading(loading: boolean) {
    this.vm.loading = loading;
  }

  public set data(data: any) {
    if (!!data) {
      if (!!data.id) {
        this.vm.id = data.id;
      }
      if (!!data.startTime) {
        const parsedStartTime = parseInt(data.startTime, 10);
        const hours = Math.floor(parsedStartTime / 60);
        const minutes = parsedStartTime % 60;

        this.vm.startTime.value = `${StringUtils.toStringWithLeadingZeros(
          hours
        )}:${StringUtils.toStringWithLeadingZeros(minutes)}`;
      }
      if (!!data.endTime) {
        const parsedEndTime = parseInt(data.endTime, 10);
        const hours = Math.floor(parsedEndTime / 60);
        const minutes = parsedEndTime % 60;

        this.vm.endTime.value = `${StringUtils.toStringWithLeadingZeros(
          hours
        )}:${StringUtils.toStringWithLeadingZeros(minutes)}`;
      }
      if (!!data.breakDuration) {
        this.vm.breakDuration.value = data.breakDuration;
      }

      if (!!data.entries && data.entries.length > 0) {
        for (const entry of data.entries) {
          const subVm = new TimeTrackingDataViewModel();
          subVm.maintenanceDriveDuration.value = entry.maintenanceDriveDuration;
          subVm.maintenanceWorkDuration.value = entry.maintenanceWorkDuration;
          subVm.maintenanceBonus.value = entry.maintenanceBonus;
          subVm.maintenanceRegiments.value = entry.maintenanceRegiments;
          subVm.othersDriveDuration.value = entry.othersDriveDuration;
          subVm.othersWorkDuration.value = entry.othersWorkDuration;
          subVm.othersBonus.value = entry.othersBonus;
          subVm.othersRegiments.value = entry.othersRegiments;
          subVm.plantIndex.value = entry.plantIndex;
          subVm.operator.value = entry.operator;
          subVm.doneTasks.value = entry.doneTasks;

          const timeTrackingDataForm = new TimeTrackingDataForm(
            subVm,
            () => undefined,
            this.timeTrackingForm
          );
          timeTrackingDataForm.init();
          this.timeTrackingForm.addSubForm(timeTrackingDataForm);
          this.vm.data.push(subVm);
        }
      }
    }
  }

  public get data(): any {
    return {
      id: this.vm.id,
      date: this.vm.date,
      startTime: this.vm.startTime.value,
      endTime: this.vm.endTime.value,
      breakDuration: this.vm.breakDuration.value,
      entries: this.vm.data.map(entry => ({
        maintenanceDriveDuration: this.round(
          entry.maintenanceDriveDuration.value
        ),
        maintenanceWorkDuration: this.round(
          entry.maintenanceWorkDuration.value
        ),
        maintenanceBonus: this.round(entry.maintenanceBonus.value),
        maintenanceRegiments: this.round(entry.maintenanceRegiments.value),
        othersDriveDuration: this.round(entry.othersDriveDuration.value),
        othersWorkDuration: this.round(entry.othersWorkDuration.value),
        othersBonus: this.round(entry.othersBonus.value),
        othersRegiments: this.round(entry.othersRegiments.value),
        plantIndex: entry.plantIndex.value,
        operator: entry.operator.value,
        doneTasks: entry.doneTasks.value
      }))
    };
  }

  public set upsertTimeTrackingDayResponse(response: FormResponse<string>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.id = response.data;
    }
  }

  public addRow(): Form {
    const subVm = new TimeTrackingDataViewModel();
    const timeTrackingDataForm = new TimeTrackingDataForm(
      subVm,
      () => undefined,
      this.timeTrackingForm
    );
    timeTrackingDataForm.init();
    this.timeTrackingForm.addSubForm(timeTrackingDataForm);
    this.vm.data.push(subVm);
    return timeTrackingDataForm;
  }

  public deleteRow(index: number) {
    this.vm.data.splice(index, 1);
    this.timeTrackingForm.removeSubFormByIndex(index);
  }

  public plantResponse(index: number, response: FormResponse<any>) {
    const entry = this.vm.data[index].operator;
    entry.loading = response.loading;
    this.vm.loading = response.loading;
    entry.error = response.error;

    if (!response.loading && !response.error) {
      if (!!response.data.operators && response.data.operators.length > 0) {
        const operator = response.data.operators[0];
        if (operator.lastName || operator.firstName) {
          entry.value = `${operator.lastName.toUpperCase()} ${
            operator.firstName
          }`;
        } else {
          entry.value = operator.company;
        }
      } else {
        entry.value = "Keine Betreiber vorhanden!";
      }
    }
  }

  private round(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }
}
