import { EmployeeForm } from "../forms/EmployeeForm";
import { EmployeePhoneNumberForm } from "../forms/EmployeePhoneNumberForm";
import { EmployeeService, Employee } from "../services/EmployeeService";
import { UserRoleService, UserRoleList } from "../services/UserRoleService";
import { FormResponse } from "@/forms/FormResponse";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { EnvironmentUtils } from "@/utils/EnvironmentUtils";

export class EmployeeDetailController {
  public constructor(
    private presenter: IEmployeeDetailPresenter,
    private employeeService: EmployeeService,
    private roleService: UserRoleService
  ) {}

  public async init(id: string) {
    await this.loadUserRoles();
    EnvironmentUtils.execOnNextTick(async () => this.loadEmployee(id));
  }

  public formFieldValueChanged(fieldName: string, value: string) {
    this.presenter.employeeForm.setFieldValue(fieldName, value);
  }

  public phoneFormFieldValueChanged(
    index: number,
    fieldName: string,
    value: string
  ) {
    this.presenter.phoneNumberForms[index].setFieldValue(fieldName, value);
  }

  public addPhoneNumberButtonClicked() {
    this.presenter.addPhoneNumber();
  }

  public deletePhoneNumberButtonClicked(index: number) {
    this.presenter.deletePhoneNumber(index);
  }

  public signatureChanged(signature: string) {
    this.presenter.signature = signature;
  }

  public saveEmployeeButtonClicked() {
    const request = this.employeeService.saveEmployee(this.presenter.employee);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.saveEmployeeResponse = response),
      "Mitarbeiter konnte nicht gespeichert werden"
    );
  }

  public deleteEmployeeButtonClicked() {
    const request = this.employeeService.deleteEmployee(
      this.presenter.employee.id
    );

    FormRequestHandler.handle(
      request,
      response => (this.presenter.deleteEmployeeResponse = response),
      "Mitarbeiter konnte nicht gelöscht werden"
    );
  }

  public reactivateEmployeeButtonClicked() {
    const request = this.employeeService.reactivateEmployee(
      this.presenter.employee.id
    );

    FormRequestHandler.handle(
      request,
      response => (this.presenter.reactivateEmployeeResponse = response),
      "Mitarbeiter konnte nicht wiederhergestellt werden"
    );
  }

  private loadUserRoles() {
    const request = this.roleService.getUserRoleList();

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadUserRolesResponse = response),
      "Rollen konnten nicht geladen werden"
    );

    return request;
  }

  private loadEmployee(id: string) {
    if (!id) {
      return;
    }

    const request = this.employeeService.getEmployee(id);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadEmployeeResponse = response),
      "Mitarbeiter konnte nicht geladen werden"
    );

    return request;
  }
}

export interface IEmployeeDetailPresenter {
  // Forms
  employeeForm: EmployeeForm;
  phoneNumberForms: EmployeePhoneNumberForm[];

  // Data
  employee: Employee;
  signature: string;

  // Responses
  loadUserRolesResponse: FormResponse<UserRoleList>;
  loadEmployeeResponse: FormResponse<Employee>;
  saveEmployeeResponse: FormResponse<string>;
  deleteEmployeeResponse: FormResponse<string>;
  reactivateEmployeeResponse: FormResponse<string>;

  addPhoneNumber(): void;
  deletePhoneNumber(index: number): void;
}
