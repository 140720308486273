import SystemKeys from "@/common/utils/SystemKeys";
import { StorageHandler } from "./StorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";

export class TaskStorageHandler extends StorageHandler {
  public static getAllTasks(useIdValues: boolean = true): SelectionItem[] {
    const nameKey = useIdValues ? undefined : SystemKeys.taskNameKey;

    return this.buildTopLevelSelection(
      SystemKeys.taskTableKey,
      SystemKeys.taskNameKey,
      nameKey
    );
  }
}
