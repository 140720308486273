import { Form, FormFieldType } from "@/forms/Form";

export class OperatorDataForm extends Form {
  public definition = {
    plants: { required: false, type: FormFieldType.MultiSelect },
    vulgoName: { required: false, type: FormFieldType.Text },
    uid: { required: false, type: FormFieldType.Text },
    dateOfPayment: { required: false, type: FormFieldType.Number },
    cashBackPercent: { required: false, type: FormFieldType.Number },
    cashBackDays: { required: false, type: FormFieldType.Number }
  };
}
