import { GraphQLConnection } from "@/gateways/graphql/GraphQLConnection";
import { ISearchService, SearchResult } from "@/common/services/SearchService";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { Page } from "@/datastructures/Page";

export class OperatorGraphQLService
  implements IOperatorService, ISearchService {
  public static OperatorFields = [
    "id",
    "index",
    "addressRemarks",
    "addressStreet",
    "addressZip",
    "addressCity",
    "addressCountry",
    "addressState",
    {
      name: "phoneNumbers",
      fields: ["areaCode", "phoneNumber", "remarks", "isStandard"]
    },
    "firstName",
    "lastName",
    "company",
    "degrees",
    "salutation",
    "eMail",
    "website",
    "vulgoName",
    "uid",
    "dateOfPayment",
    "cashBackPercent",
    "cashBackDays",
    {
      name: "plants",
      fields: ["id"]
    },
    {
      name: "plantDocuments",
      fields: ["name", "description", "uploadedAt", "path", "immutable"]
    }
  ];

  public constructor(private connection: GraphQLConnection) {}

  public async search(
    searchText: string,
    page: Page
  ): Promise<PaginatedList<SearchResult>> {
    const request = await this.connection.queryPaginated(
      "searchOperators",
      page.itemsPerPage,
      page.page,
      [
        "id",
        "index",
        "firstName",
        "lastName",
        "company",
        "addressStreet",
        "addressZip",
        "addressCity",
        "addressCountry"
      ],
      searchText,
      []
    );

    return {
      items: request.data.map((el: any) => ({
        id: el.id,
        index: el.index,
        extra: this.formatCompanyToName(el.firstName, el.lastName, el.company),
        street: el.addressStreet,
        company: el.company,
        zip: el.addressZip,
        city: el.addressCity,
        country: el.addressCountry
      })),
      totalCount: request.count
    };
  }

  public formatCompanyToName(
    firstName: string,
    lastName: string,
    company: string
  ) {
    if (!!firstName || !!lastName) {
      return `${lastName} ${firstName}`;
    } else {
      return company;
    }
  }

  public async getOperatorById(id: string): Promise<Operator> {
    const request = await this.connection.query(
      "getOperatorById",
      {
        id
      },
      OperatorGraphQLService.OperatorFields
    );
    return this.parseOperator(request.data);
  }

  public async findOther(id: string): Promise<SearchResult[]> {
    const request = await this.connection.query(
      "findPlantsByOperatorId",
      { id },
      [
        "id",
        "index",
        "group",
        "type",
        "addressStreet",
        "addressZip",
        "addressCity",
        "addressCountry"
      ]
    );

    return request.data.map((el: any) => ({
      id: el.id,
      index: el.index,
      extra: el.group + " - " + el.type,
      street: el.addressStreet,
      zip: el.addressZip,
      city: el.addressCity,
      country: el.addressCountry
    }));
  }

  public async createOperator(operator: Operator): Promise<string> {
    const request = await this.connection.mutation(
      "upsertOperator",
      {
        input: {
          id: operator.id,
          degrees: operator.degrees,
          salutation: operator.salutation,
          firstName: operator.firstName,
          lastName: operator.lastName,
          company: operator.company,
          addressRemarks: operator.address.remarks,
          addressStreet: operator.address.street,
          addressZip: operator.address.zip,
          addressCity: operator.address.city,
          addressState: operator.address.state,
          addressCountry: operator.address.country,
          phoneNumberAreaCodes: operator.phoneNumbers.map(
            phoneNumber => phoneNumber.areaCode
          ),
          phoneNumbers: operator.phoneNumbers.map(
            phoneNumber => phoneNumber.phoneNumber
          ),
          phoneNumberRemarks: operator.phoneNumbers.map(
            phoneNumber => phoneNumber.remarks
          ),
          phoneNumberIsStandard: operator.phoneNumbers.map(
            phoneNumber => phoneNumber.isStandard
          ),
          eMail: operator.eMail,
          website: operator.website,
          vulgoName: operator.vulgoName,
          uid: operator.uid,
          dateOfPayment: operator.dateOfPayment,
          cashBackPercent: operator.cashBackPercent,
          cashBackDays: operator.cashBackDays,
          plantIds: operator.plantIds
        }
      },
      []
    );

    return request.data;
  }

  public async exportOperators(): Promise<string> {
    const request = await this.connection.query("exportOperators", {}, []);

    return request.data;
  }

  private parseOperator(raw: any): Operator {
    const operator: Operator = raw;

    operator.id = raw.id;
    operator.index = parseInt(raw.index, 10);
    operator.degrees = raw.degrees;
    operator.salutation = raw.salutation;
    operator.firstName = raw.firstName;
    operator.lastName = raw.lastName;
    operator.company = raw.company;
    if (!!raw.address) {
      operator.address = raw.address;
    } else {
      operator.address = {
        street: raw.addressStreet,
        zip: raw.addressZip,
        city: raw.addressCity,
        country: raw.addressCountry,
        state: raw.addressState,
        remarks: raw.addressRemarks
      };
    }
    operator.phoneNumbers = raw.phoneNumbers;
    operator.eMail = raw.eMail;
    operator.website = raw.website;
    operator.vulgoName = raw.vulgoName;
    operator.plantIds = raw.plants.map((plant: any) => plant.id);

    return operator;
  }
}

export interface IOperatorService {
  createOperator(operator: Operator): Promise<string>;
  getOperatorById(id: string): Promise<Operator>;
  exportOperators(): Promise<string>;
}

export interface Operator {
  id: string;
  index?: number;

  degrees: string[];
  salutation: string;
  firstName: string;
  lastName: string;
  company: string;

  address: Address;

  phoneNumbers: OperatorPhoneNumber[];
  eMail: string;
  website: string;
  uid: string;
  dateOfPayment: number;
  cashBackPercent: number;
  cashBackDays: number;

  vulgoName: string;
  plantIds: string[];
}

export interface Address {
  remarks: string;
  street: string;
  zip: string;
  city: string;
  state: string;
  country: string;
}

export interface OperatorPhoneNumber {
  areaCode: string;
  phoneNumber: string;
  remarks: string;
  isStandard: boolean;
}
