import { render, staticRenderFns } from "./RightHandCard.vue?vue&type=template&id=0341100e&scoped=true&"
import script from "./RightHandCard.vue?vue&type=script&lang=ts&"
export * from "./RightHandCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0341100e",
  null
  
)

export default component.exports