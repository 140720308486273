import { DateUtils } from "@/utils/DateUtils";
import { MathUtils } from "@/utils/MathUtils";
import { Dictionary } from "@/data/settings";
import { SelectionItem } from "./ViewModelFormTypes";
import { StringUtils } from "@/utils/StringUtils";

export abstract class Form {
  public static generateSelectionList<T extends SelectionItem>(
    dictionary: Dictionary<T>
  ): SelectionItem[] {
    return Object.entries(dictionary).map(item => item[1]);
  }

  public data: FormData = {};
  public subforms: Form[] = [];
  protected abstract definition: FormDefinition;
  private hooks: FormHooks = {};

  public constructor(
    protected fieldContext: any,
    public validatedEvent: (context: any, valid: boolean) => void,
    public parentForm?: Form,
    protected formContext?: any,
    public name?: string
  ) {
    if (!this.formContext) {
      this.formContext = fieldContext;
    }
  }

  public getFieldValue(fieldName: string) {
    return this.getField(fieldName).text;
  }

  public setFieldValue(fieldName: string, value: string) {
    const field = this.getField(fieldName);
    field.text = value;
    field.changedFirstTime = true;
    this.validateField(fieldName, field);
    this.fieldValueChanged(this.fieldContext, fieldName, field);
    this.validateForm();
  }

  public init() {
    if (Object.keys(this.definition).length === Object.keys(this.data).length) {
      return;
    }

    Object.keys(this.definition).forEach(fieldName => {
      if (!this.fieldAlreadyCreated(fieldName)) {
        this.createField(fieldName);
      }
    });

    this.validateAllFields();
    this.validateForm();
  }

  public findSubformByName(name: string) {
    return this.subforms.find(sf => sf.name === name);
  }

  public addSubForm(subform: Form) {
    subform.parentForm = this;
    this.subforms.push(subform);
  }

  public removeSubFormByIndex(index: number) {
    this.subforms.splice(index, 1);
  }

  public removeSubForm(subForm: Form) {
    this.subforms = this.subforms.filter(f => f !== subForm);
  }

  public addFieldChangedListener(
    fieldname: string,
    hook: (context: any, value: string) => void,
    context?: any
  ) {
    this.hooks[fieldname] = { hook, context };
  }

  public validateForm() {
    let valid = this.isFormValid();

    this.subforms.forEach(subform => {
      valid = valid && subform.isFormValid();
    });

    if (!!this.parentForm) {
      this.parentForm.validatedEvent(
        this.parentForm.formContext,
        this.parentForm.isFormValid()
      );
    }

    this.validatedEvent(this.formContext, valid);
  }

  public isFormValid(): boolean {
    return (
      Object.entries(this.data).every(field => {
        return (
          !field[1].error &&
          (field[1].changedFirstTime || !this.definition[field[0]].required)
        );
      }) && this.subforms.every(subform => subform.isFormValid())
    );
  }

  public fieldExists(fieldName: string) {
    return Object.keys(this.definition).some(field => field === fieldName);
  }

  public fieldAlreadyCreated(fieldName: string) {
    return Object.keys(this.data).some(field => field === fieldName);
  }

  public createField(fieldName: string) {
    this.data[fieldName] = {
      text: "",
      error: "",
      changedFirstTime: !this.needToObserveChange(fieldName)
    };

    if (this.shouldInitWithDefault(fieldName)) {
      const defaultValue = this.getDefaultValueForField(fieldName) as string;
      this.setFieldValue(fieldName, defaultValue);
    }
  }

  public getData() {
    // TODO: subforms as directory
    const data: any = {};

    for (const field of Object.entries(this.data)) {
      const [key, fieldData] = field;
      data[key] = fieldData.text;
    }

    return data;
  }

  protected validateAllFields() {
    Object.entries(this.data).forEach(field => {
      this.validateField(field[0], field[1]);
    });
  }

  protected validateField(fieldName: string, field: FormField) {
    const definition = this.definition[fieldName];

    if (definition.required) {
      field.error = !!field.text ? "" : "required";
      if (field.error) {
        return;
      }
    }
    if (definition.greaterThan !== undefined) {
      const value = parseInt(field.text, 10);
      field.error = isNaN(value)
        ? "not-a-number"
        : value > definition.greaterThan
        ? ""
        : "not-greater-than(" + definition.greaterThan + ")";
      if (field.error) {
        return;
      }
    }
    if (definition.regex !== undefined && !!field.text) {
      const pass = definition.regex.test(field.text);
      field.error = pass
        ? ""
        : !!definition.error
        ? definition.error
        : "invalid-characters";
      if (field.error) {
        return;
      }
    }

    field.error = "";
  }

  protected getField(fieldName: string) {
    this.throwErrorIfFieldNotExists(fieldName);
    this.init();
    return this.data[fieldName];
  }

  private fieldValueChanged(context: any, fieldName: string, field: FormField) {
    const definition = this.definition[fieldName];
    const contextField = context[fieldName];

    switch (definition.type) {
      case FormFieldType.Text:
        contextField.value = field.text;
        contextField.error = field.error;
        break;

      case FormFieldType.Number:
        contextField.value =
          parseFloat(field.text.replace(",", ".")) || contextField.default || 0;
        if (!!definition.precision) {
          contextField.value = parseFloat(
            contextField.value.toFixed(definition.precision)
          );
        }
        contextField.error = field.error;
        break;

      case FormFieldType.Selection:
        contextField.selected = field.text;
        contextField.error = field.error;
        break;

      case FormFieldType.MultiSelect:
        if (Array.isArray(field.text)) {
          contextField.selected = field.text;
        } else {
          const selectedIndex = contextField.selected.indexOf(field.text);
          if (selectedIndex > -1) {
            contextField.selected.splice(selectedIndex, 1);
          } else {
            contextField.selected.push(field.text);
          }
        }
        contextField.error = field.error;
        break;

      case FormFieldType.Date:
        contextField.value = DateUtils.toISOString(field.text);
        contextField.text = DateUtils.format(field.text);
        contextField.error = field.error;
        break;

      case FormFieldType.Time:
        if (StringUtils.isString(field.text) && field.text.indexOf(":") >= 0) {
          const onlyHHandMM = field.text
            .split(":")
            .slice(0, 2)
            .join(":");
          contextField.value = onlyHHandMM;
          contextField.text = onlyHHandMM;
        } else {
          contextField.value =
            parseInt(field.text, 10) || contextField.default || 0;
          const hours = Math.floor(contextField.value / 60);
          const minutes = contextField.value % 60;
          contextField.text = `${hours < 10 ? "0" + hours : hours}:${
            minutes < 10 ? "0" + minutes : minutes
          }`;
        }
        contextField.error = field.error;
        break;

      case FormFieldType.Checkbox:
        contextField.value = MathUtils.parseBoolean(field.text);
        break;

      case FormFieldType.File:
        contextField.value = field.text;
    }

    if (this.hookExists(fieldName)) {
      const hook = this.hooks[fieldName];
      hook.hook(hook.context || this.fieldContext, field.text);
    }
  }

  private needToObserveChange(fieldName: string) {
    return this.definition[fieldName].required;
  }

  private shouldInitWithDefault(fieldName: string) {
    return !!this.definition[fieldName].initWithDefault;
  }

  private getDefaultValueForField(fieldName: string) {
    const type = this.definition[fieldName].type;

    switch (type) {
      case FormFieldType.Selection:
      case FormFieldType.File:
      case FormFieldType.Text:
        return "";

      case FormFieldType.Number:
        return 0;
      case FormFieldType.Time:
        return "00:00";
      case FormFieldType.Checkbox:
        return false;
      case FormFieldType.Date:
        return new Date();
      case FormFieldType.MultiSelect:
        return [];

      default:
        break;
    }
  }

  private hookExists(hookName: string) {
    return Object.keys(this.hooks).some(hook => hook === hookName);
  }

  private throwErrorIfFieldNotExists(fieldName: string) {
    if (!this.fieldExists(fieldName)) {
      throw new Error("Field " + fieldName + " doesn't exist");
    }
  }
}

export interface FormData {
  [key: string]: FormField;
}

export interface FormField {
  text: string;
  error: string;
  changedFirstTime: boolean;
}

export enum FormFieldType {
  Text,
  Number,
  Date,
  Selection,
  MultiSelect,
  Checkbox,
  Time,
  File
}

export interface FormHooks {
  [key: string]: FieldHook;
}
export interface FieldHook {
  hook: (context: any, value: string) => void;
  context?: any;
}

export interface FormDefinition {
  [key: string]: FormFieldDefinition;
}
export interface FormFieldDefinition {
  required: boolean;
  type: FormFieldType;
  greaterThan?: number;
  precision?: number;
  regex?: RegExp;
  error?: string;
  initWithDefault?: boolean;
}
