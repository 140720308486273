export class SampleAccordionViewModel {
  public sampleLocation: { selected: string; items: string[] } = {
    items: [],
    selected: ""
  };
  public sampleTimeFrom = { value: "", text: "" };
  public sampleTime = "";
  public sampleWeather: { selected: string; items: string[] } = {
    items: [],
    selected: ""
  };
  public sampleTemperature = "";
  public sampleIntakeEnabled = false;
  public sampleIntake: { selected: string; items: string[] } = {
    items: [],
    selected: "Kein"
  };
  public sampleTaker = "";

  public plantStatus = {
    selected: "",
    items: [
      {
        text: "In Ordnung",
        value: "ok"
      },
      {
        text: "Nicht in Ordnung",
        value: "notOk"
      }
    ]
  };

  public suggestedActions: { selected: string; items: string[] } = {
    items: [],
    selected: ""
  };
}
