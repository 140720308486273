
































import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import GoogleMap from "@/components/google/GoogleMap.vue";
import GoogleMapMarker from "@/components/google/GoogleMapMarker.vue";

export default Vue.extend({
  name: "WorkOrderMap",
  components: {
    Container,
    Column,
    Row,
    GoogleMap,
    GoogleMapMarker
  },
  props: {
    lat: {
      type: Number,
      required: true
    },
    lng: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      visibleView: false
    };
  },
  methods: {
    close() {
      this.visibleView = false;
      this.$destroy();
    }
  },
  mounted() {
    this.visibleView = true;
  }
});
