import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import i18n from "./i18n";
import "./plugins/googlemaps.js";
import "./plugins/vuesignaturepad.js";
import "./plugins/vee-validate.js";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.config.productionTip = false;

// Vue.use(VueGoogleMaps, {
//   load: {
//     key: process.env.VUE_APP_MAPS_API_KEY,
//     // libraries: "places"
//   }
// });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
