import { IPlantSizesAccordionPresenter } from "../controllers/PlantSizesAccordionController";
import {
  PlantSize,
  PlantSizesAccordionViewModel
} from "../vms/PlantSizesAccordionViewModel";

export class PlantSizesAccordionPresenter
  implements IPlantSizesAccordionPresenter {
  public constructor(private vm: PlantSizesAccordionViewModel) {}

  public set plantSizes(plantSizes: PlantSize[]) {
    this.vm.plantSizes = plantSizes;
  }
}
