import { GraphQLConnection } from "@/gateways/graphql/GraphQLConnection";

export class EmployeeGraphQLService implements EmployeeService {
  public constructor(private connection: GraphQLConnection) {}

  public async getAllEmployees() {
    const result = await this.connection.query("employees", {}, [
      "id",
      "abbreviation",
      "firstname",
      "lastname",
      { name: "standardPhoneNumber", fields: ["areaCode", "phoneNumber"] },
      "active"
    ]);

    return result.data.map((employee: any) => {
      let phoneNumber = null;

      if (!!employee.standardPhoneNumber) {
        phoneNumber = {
          areaCode: employee.standardPhoneNumber.areaCode,
          phoneNumber: employee.standardPhoneNumber.phoneNumber
        };
      }

      return {
        id: employee.id,
        abbreviation: employee.abbreviation,
        firstname: employee.firstname,
        lastname: employee.lastname,
        areaCode: phoneNumber?.areaCode ?? "",
        phoneNumber: phoneNumber?.phoneNumber ?? "",
        active: employee.active
      };
    });
  }

  public async getAllActiveEmployees() {
    const result = await this.connection.query("activeEmployees", {}, [
      "id",
      "abbreviation",
      "firstname",
      "lastname",
      { name: "standardPhoneNumber", fields: ["areaCode", "phoneNumber"] },
      "active"
    ]);

    return result.data.map((employee: any) => {
      let phoneNumber = null;

      if (!!employee.standardPhoneNumber) {
        phoneNumber = {
          areaCode: employee.standardPhoneNumber.areaCode,
          phoneNumber: employee.standardPhoneNumber.phoneNumber
        };
      }

      return {
        id: employee.id,
        abbreviation: employee.abbreviation,
        firstname: employee.firstname,
        lastname: employee.lastname,
        areaCode: phoneNumber?.areaCode ?? "",
        phoneNumber: phoneNumber?.phoneNumber ?? "",
        active: employee.active
      };
    });
  }

  public async getEmployee(id: string) {
    const result = await this.connection.query("employee", { id }, [
      "id",
      "abbreviation",
      "salutation",
      "firstname",
      "lastname",
      "email",
      "svnr",
      "birthday",
      "dateOfJoining",
      "address",
      "zip",
      "city",
      "country",
      "state",
      "roleId",
      "active",
      {
        name: "phoneNumbers",
        fields: ["areaCode", "phoneNumber", "annotation"]
      },
      "signature"
    ]);

    return {
      id: result.data.id,
      active: result.data.active,
      abbreviation: result.data.abbreviation,
      salutation: result.data.salutation,
      firstname: result.data.firstname,
      lastname: result.data.lastname,
      email: result.data.email,
      svnr: result.data.svnr,
      birthday: result.data.birthday,
      dateOfJoining: result.data.dateOfJoining,
      address: result.data.address,
      zip: result.data.zip,
      city: result.data.city,
      country: result.data.country,
      state: result.data.state,
      phoneNumbers: result.data.phoneNumbers.map((num: any) => ({
        areaCode: num.areaCode,
        phoneNumber: num.phoneNumber,
        annotation: num.annotation
      })),
      username: "",
      password: "",
      roleId: result.data.roleId,
      signature: result.data.signature
    };
  }

  public async saveEmployee(employee: Employee) {
    const result = await this.connection.mutation(
      "upsertEmployee",
      {
        input: {
          id: employee.id,
          abbreviation: employee.abbreviation,
          salutation: employee.salutation,
          firstname: employee.firstname,
          lastname: employee.lastname,
          email: employee.email,
          svnr: employee.svnr,
          birthday: employee.birthday,
          dateOfJoining: employee.dateOfJoining,
          address: employee.address,
          zip: employee.zip,
          city: employee.city,
          country: employee.country,
          state: employee.state,
          username: employee.username,
          password: employee.password,
          phoneNumbers: employee.phoneNumbers.map(num => ({
            areaCode: num.areaCode,
            phoneNumber: num.phoneNumber,
            annotation: num.annotation
          })),
          role_id: employee.roleId,
          signature: employee.signature
        }
      },
      []
    );

    return result.data;
  }

  public async deleteEmployee(id: string) {
    const result = await this.connection.mutation(
      "deleteEmployee",
      { input: { employeeId: id } },
      []
    );
    return result.data;
  }

  public async reactivateEmployee(id: string) {
    const result = await this.connection.mutation(
      "reactivateEmployee",
      { input: { employeeId: id } },
      []
    );
    return result.data;
  }
}

export interface EmployeeService {
  getAllEmployees(): Promise<EmployeeTableRows>;
  getAllActiveEmployees(): Promise<EmployeeTableRows>;
  getEmployee(id: string): Promise<Employee>;

  saveEmployee(employee: Employee): Promise<string>;
  deleteEmployee(id: string): Promise<string>;
  reactivateEmployee(id: string): Promise<string>;
}

export type EmployeeTableRows = EmployeeTableRow[];

export interface EmployeeTableRow {
  id: string;
  abbreviation: string;
  firstname: string;
  lastname: string;
  areaCode: string;
  phoneNumber: string;
  active: boolean;
}

export interface Employee {
  id: string;
  active: boolean;
  abbreviation: string;
  salutation: string;
  firstname: string;
  lastname: string;
  email: string;
  svnr: string;
  birthday: string;
  dateOfJoining: string;
  address: string;
  zip: string;
  city: string;
  country: string;
  state: string;
  phoneNumbers: EmployeePhoneNumbers;
  username: string;
  password: string;
  roleId: string;
  signature: string;
}

export type EmployeePhoneNumbers = EmployeePhoneNumber[];

export interface EmployeePhoneNumber {
  areaCode: string;
  phoneNumber: string;
  annotation: string;
}
