
























































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import Page from "@/components/layout/Page.vue";
import RequestButton from "@/components/basic/RequestButton.vue";
import FormSwitch from "@/components/form/FormSwitch.vue";
import Textfield from "@/components/form/Textfield.vue";
import Textarea from "@/components/form/Textarea.vue";
import DatePicker from "@/components/form/DatePicker.vue";
import Select from "@/components/form/Select.vue";

import { PlantContractViewModel } from "@/plant/vms/PlantContractViewModel";
import { PlantContractPresenter } from "@/plant/presenters/PlantContractPresenter";
import { PlantContractController } from "@/plant/controllers/PlantContractController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { PlantGraphQLService } from "../services/PlantGraphQLService";

@Component<PlantContractView>({
  components: {
    Page,
    Card,
    Container,
    Column,
    Row,

    RequestButton,
    FormSwitch,
    Select,
    Textarea,
    Textfield,
    DatePicker
  },
  watch: {
    "vm.goBack"() {
      this.vm.visibleView = false;
      this.backToPlant();
    },
    "vm.createdContract"() {
      this.vm.visibleView = false;
      this.$emit("finished");
      this.backToPlant();
    }
  }
})
export default class PlantContractView extends Vue {
  private vm = new PlantContractViewModel();
  private controller?: PlantContractController;

  private initController(): PlantContractController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new PlantContractController(
      new PlantContractPresenter(this.vm),
      this.$route.params.plantId,
      new PlantGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }

  private backToPlant() {
    this.$router.push({
      name: "plant",
      params: { plantId: this.$route.params.plantId }
    });
  }
}
