






















































import Vue from "vue";
import Component from "vue-class-component";

import Container from "@/components/layout/Container.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import TimePicker from "@/components/form/TimePicker.vue";
import Textarea from "@/components/form/Textarea.vue";
import { Time, Textfield } from "../../forms/ViewModelFormTypes";
import { DateUtils } from "../../utils/DateUtils";

@Component<ChangeAppointmentPropsDialog>({
  components: {
    Container,
    Column,
    Row,
    TimePicker,
    Textarea
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    reason: String,
    duration: Number,
    remarks: String,
    readonly: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "edit"
    },
    commentReadonly: {
      type: Boolean,
      default: false
    }
  }
})
export default class ChangeAppointmentPropsDialog extends Vue {
  private open = false;
  private reason!: string;
  private duration!: number;
  private remarks!: string;
  private changedReason: string = "";
  private changedDuration: Time = {
    value: "",
    text: "",
    error: "",
    label: "Standzeit"
  };
  private changedComment: Textfield = {
    value: "",
    error: "",
    label: "Kommentar"
  };

  public mounted() {
    this.changedReason = this.reason;
    const hours =
      Math.floor(this.duration / 60) > 9
        ? "" + Math.floor(this.duration / 60)
        : "0" + Math.floor(this.duration / 60);
    const minutes =
      this.duration % 60 > 9
        ? "" + (this.duration % 60)
        : "0" + (this.duration % 60);
    this.changedDuration.text = `${hours}:${minutes}`;
    this.changedDuration.value = this.duration;
    this.changedComment.value = this.remarks;
  }

  private confirm() {
    this.open = false;
    this.$emit("confirm", {
      reason: this.changedReason,
      duration: this.changedDuration.value,
      remarks: this.changedComment.value
    });
  }

  private durationChanged(newValue: number) {
    this.changedDuration.value = newValue;
    this.changedDuration.text = DateUtils.timeToString(newValue);
  }

  private commentChanged(newValue: string) {
    this.changedComment.value = newValue;
  }
}
