export class MathUtils {
  public static parseBoolean(value: any): boolean {
    switch (value) {
      case "true":
      case true:
      case 1:
        return true;
    }
    return false;
  }
}
