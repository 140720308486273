import { WorkOrder } from "../services/WorkOrderService";
import { TableHeader, Checkbox, Selection } from "@/forms/ViewModelFormTypes";

export class WorkOrderMetaListViewModel {
  public loading = false;
  public reload = false;
  public error = "";
  public headers: TableHeader[] = [
    { text: "Anlagennummer", value: "plantIndex" },
    { text: "Kalenderwoche", value: "calendarWeek.text" },
    { text: "Datum", value: "plannedDate" },
    { text: "Leistungsbeschreibung", value: "tasksDone" },
    {
      text: "Ans Labor versendet?",
      value: "sentToLab",
      align: "center",
      width: "150px"
    },
    {
      text: "Laborbericht vorhanden?",
      value: "hasLabReport",
      align: "center",
      width: "150px"
    },
    {
      text: "Laborbericht hochladen",
      value: "labReportUpload",
      sortable: false,
      width: "150px",
      align: "center"
    },
    { text: "Berichte", value: "links", width: "150px", align: "center" },
    {
      text: "Prüfbericht versendet?",
      value: "alreadySent",
      align: "center",
      width: "150px"
    },
    {
      text: "Prüfbericht senden",
      value: "sendReport",
      align: "center",
      width: "150px"
    }
  ];

  public currentWorkOrder?: any;

  public options = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 10,
    multiSort: true,
    mustSort: false,
    sortBy: ["plannedDate"],
    sortDesc: [true]
  };

  public workOrders: WorkOrder[] = [];
  public allWorkOrders: WorkOrder[] = [];

  public withoutLabReportsFirst: Checkbox = {
    error: "",
    label: "Arbeitsaufträge ohne Laborbericht zuerst",
    value: false
  };

  public week: Selection = {
    items: [],
    selected: "",
    error: "",
    label: "Kalenderwoche"
  };
}
