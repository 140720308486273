import SystemTablesOverviewView from "@/system/views/SystemTablesOverviewView.vue";
import SystemTableListView from "@/system/views/SystemTableListView.vue";
import SystemTableEntryView from "@/system/views/SystemTableEntryView.vue";

export default [
  {
    path: "/system-tables",
    name: "system-tables",
    component: SystemTablesOverviewView
  },
  {
    path: "/system-table/:tableId",
    name: "system-table",
    component: SystemTableListView
  },
  {
    path: "/system-table/:tableId/entry/:entryId?",
    name: "system-table-entry",
    component: SystemTableEntryView
  }
];
