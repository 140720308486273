import moment from "moment";
import { TypeUtils } from "./TypeUtils";
import { Dictionary } from "@/datastructures/Dictionary";
import { StringUtils } from "./StringUtils";
moment.locale("de");

interface Day {
  de: string;
}

export class DateUtils {
  public static days: Dictionary<Day> = {
    monday: {
      de: "Montag"
    },
    tuesday: {
      de: "Dienstag"
    },
    wednesday: {
      de: "Mittwoch"
    },
    thursday: {
      de: "Donnerstag"
    },
    friday: {
      de: "Freitag"
    }
  };

  public static datetime(date: Date) {
    return (
      date.getUTCFullYear().toString() +
      "/" +
      (date.getUTCMonth() + 1).toString() +
      "/" +
      date.getUTCDate() +
      " " +
      date.getUTCHours() +
      ":" +
      date.getUTCMinutes() +
      ":" +
      date.getUTCSeconds()
    );
  }

  public static getDate(date: string | Date | number) {
    return this.getMoment(date).toDate();
  }

  public static format(date: string | Date | number, outputFormat?: string) {
    return this.getMoment(date).format(outputFormat ?? "DD. MMM YYYY");
  }

  public static dateOnly(date: Date | string) {
    return this.getMoment(date).format("YYYY-MM-DD");
  }

  public static parseDateFromTillFromReport(date: string) {
    return moment(date, "DD.MM.YYYY HH:mm").format("HH:mm");
  }

  public static weekMondayFromDate(date: Date | string): Date {
    const parsedDate = moment(date);
    const diff = -parsedDate.day() + (parsedDate.day() === 0 ? -6 : 1);
    parsedDate.date(parsedDate.date() + diff);
    return parsedDate.toDate();
  }

  public static weekNumber(date: Date | string) {
    return this.getMoment(date).week();
  }

  public static dateFromWeekNumber(weekNumber: number) {
    return DateUtils.removeTimeStamp(
      moment()
        .day("Monday")
        .week(weekNumber)
        .toDate()
    );
  }

  public static removeTimeStamp(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  public static formatDate(date: Date | number | string) {
    return moment(date).format("DD.MM.YYYY");
  }

  public static formatDateTime(date: Date | number | string) {
    return moment(date).format("DD.MM.YYYY HH:mm");
  }

  public static parseDate(date: string): Date {
    if (date.includes(".")) {
      return moment(date, "DD.MM.YYYY").toDate();
    }

    if (date.includes("/")) {
      return new Date(date);
    }

    throw new Error("Could not parse date!");
  }

  public static parseDateToISO(date: string): string {
    return moment(this.parseDate(date)).format("YYYY-MM-DD");
  }

  public static toISOString(date: Date | string | number) {
    return this.getMoment(date).format("YYYY-MM-DD");
  }

  public static toISOStringMonthOnly(date: Date | string | number) {
    return this.toISOString(date).substr(0, 7);
  }

  public static weekday(date: Date | string | number) {
    return moment(date)
      .toDate()
      .getDay();
  }

  public static getFirstOfYear(year?: number) {
    if (!year) {
      year = new Date().getFullYear();
    }

    return new Date(year.toString() + "-01-01");
  }

  public static weekdayToString(date: Date | string | number) {
    return ["SO", "MO", "DI", "MI", "DO", "FR", "SA"][this.weekday(date)];
  }

  public static endOfMonth(month: number, year?: number) {
    if (!year) {
      year = moment().year();
    }
    return moment([year, month, 1])
      .endOf("month")
      .toDate();
  }

  public static get daysInEnglish() {
    return Object.keys(this.days);
  }

  public static get daysInGerman() {
    return Object.values(this.days).map(day => day.de);
  }

  public static timeToNumber(time: string): number {
    const splitTime = time.split(":");

    const parsedHours = parseInt(splitTime[0], 10);
    const parsedMinutes = splitTime.length > 1 ? parseInt(splitTime[1], 10) : 0;

    const hours = isNaN(parsedHours) ? 0 : parsedHours;
    const minutes = isNaN(parsedMinutes) ? 0 : parsedMinutes;

    return hours * 60 + minutes;
  }

  public static timeToString(time: number): string {
    const hours = Math.floor(time / 60);
    const minutes = Math.round(time % 60);

    return `${StringUtils.pad(hours, 2)}:${StringUtils.pad(minutes, 2)}`;
  }

  public static timeToStringWSeconds(time: number): string {
    const hours = Math.floor(time / 60 / 60);
    const minutes = Math.round(Math.floor(time / 60) % 60);
    const seconds = Math.round(time % 60);

    return `${StringUtils.pad(hours, 2)}:${StringUtils.pad(
      minutes,
      2
    )}:${StringUtils.pad(seconds, 2)}`;
  }

  private static getMoment(date: Date | string | number) {
    if (TypeUtils.isString(date)) {
      if (/^\d*$/.test(date as string)) {
        return moment(parseInt(date as string, 10));
      }
    }
    const parsedDate = moment(date);
    if (parsedDate.isValid()) {
      return parsedDate;
    } else {
      return moment(date, "DD.MM.YYYY");
    }
  }
}
