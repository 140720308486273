





































import Vue from "vue";
import Component from "vue-class-component";

import ConfirmDialog from "@/components/basic/ConfirmDialog.vue";

@Component<RequestButton>({
  components: {
    ConfirmDialog
  },
  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "primary"
    },
    error: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    confirm: {
      type: Boolean,
      default: false
    },
    confirmTitle: {
      type: String,
      default: "Title"
    },
    confirmText: {
      type: String,
      default: "Text"
    },
    width: {
      type: String,
      default: undefined
    },
    block: {
      type: Boolean,
      default: false
    }
  }
})
export default class RequestButton extends Vue {
  private confirmDialogVisible = false;

  private get buttonColor() {
    return !this.$props.error ? this.$props.color : "error";
  }

  private buttonClicked() {
    if (this.$props.confirm && !this.$props.error) {
      this.confirmDialogVisible = true;
    } else {
      this.emitClick();
    }
  }

  private confirmRequest() {
    this.emitClick();
    this.closeDialog();
  }

  private closeDialog() {
    this.confirmDialogVisible = false;
  }
  private emitClick() {
    this.$emit("click");
  }
}
