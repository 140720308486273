






import Vue from "vue";

export default Vue.extend({
  name: "Container",
  inheritAttrs: false,
  props: {
    margin: {
      type: Number,
      default: 3
    }
  }
});
