




















































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";
import ValidatedCombobox from "@/components/form/vee-validate/ValidatedCombobox.vue";

import { PlantDataTabViewModel } from "@/report/vms/PlantDataTabViewModel";
import { PlantDataTabPresenter } from "@/report/presenters/PlantDataTabPresenter";
import { PlantDataTabController } from "@/report/controllers/PlantDataTabController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";

@Component<PlantDataTab>({
  components: {
    ValidationObserver,

    Card,
    Container,
    Column,
    Row,

    ValidatedTextfield,
    ValidatedSelect,
    ValidatedCombobox
  },
  props: ["parentController", "parentVm", "mini"]
})
export default class PlantDataTab extends Vue {
  public controller?: PlantDataTabController;
  private vm = new PlantDataTabViewModel();

  private initController(): PlantDataTabController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new PlantDataTabController(new PlantDataTabPresenter(this.vm));
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$props.parentVm.workOrder);
  }
}
