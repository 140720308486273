var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{attrs:{"title":"Versandstatus"}},[_c('Section',{staticClass:"ma-2"},[_c('h3',[_vm._v("Schnellsortierung")]),_c('Checkbox',{attrs:{"value":_vm.vm.withoutLabReportsFirst},on:{"change":function($event){return _vm.controller.withoutLabReportsFirst($event)}}}),_c('v-select',{attrs:{"value":_vm.vm.week.selected,"items":_vm.vm.week.items,"label":_vm.vm.week.label,"outlined":"","clearable":""},on:{"change":function($event){return _vm.controller.filterWorkOrders($event)}}})],1),_c('Section',{staticClass:"ma-2"},[_c('Container',{attrs:{"margin":0}},[_c('Row',[_c('Column',{attrs:{"xs6":"","pr-2":""}},[_c('request-button',{attrs:{"block":"","outlined":false,"text":"Ans Labor senden"},on:{"click":function($event){return _vm.goToSendReportToLab()}}})],1),_c('Column',{attrs:{"xs6":"","pl-2":""}},[_c('RequestButton',{attrs:{"block":"","outlined":false,"text":"An BHs versenden"},on:{"click":function($event){return _vm.goToSendReportToBh()}}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.vm.headers,"items":_vm.vm.workOrders,"options":_vm.vm.options,"multiSort":true,"loading":_vm.vm.loading},scopedSlots:_vm._u([{key:"item.plannedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.controller.parseDate(item.plannedDate)))]}},{key:"item.hasLabReport",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(!!item.hasLabReport ? "done" : "clear"))])]}},{key:"item.alreadySent",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(!!item.alreadySent ? "done" : "clear"))])]}},{key:"item.sentToLab",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(!!item.sentToLab ? "done" : "clear"))])]}},{key:"item.sendReport",fn:function(ref){
var item = ref.item;
return [_c('IconButton',{staticClass:"px-1",attrs:{"disabled":!item.reportPdfPath,"icon":"send","tooltipText":"Prüfbericht an BH senden"},on:{"click":function($event){return _vm.controller.sendClicked(item.id)}}})]}},{key:"item.links",fn:function(ref){
var item = ref.item;
return [_c('IconButton',{staticClass:"px-1",attrs:{"disabled":!item.workOrderPdfPath,"icon":"build","tooltipText":"Arbeitsbericht"},on:{"click":function($event){return _vm.openTab(item.workOrderPdfPath)}}}),_c('IconButton',{staticClass:"px-1",attrs:{"disabled":!item.reportPdfPath,"icon":"business_center","tooltipText":"Prüfbericht"},on:{"click":function($event){return _vm.openTab(item.reportPdfPath)}}})]}},{key:"item.labReportUpload",fn:function(ref){
var item = ref.item;
return [_c('IconButton',{staticClass:"px-1",attrs:{"icon":"backup","tooltipText":"Laborbericht hochladen"},on:{"click":function($event){return _vm.openFileDialog(item)}}})]}}],null,true)})],1),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":".pdf"},on:{"change":function($event){return _vm.controller.uploadLabReport(_vm.vm.currentWorkOrder, $event.target.files[0])}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }