import { Selection, Request } from "@/forms/ViewModelFormTypes";
import { ServiceEngineer } from "../services/ServiceEngineerGraphQLService";

export class WeeklyScheduleViewModel {
  public visibleView: boolean = false;
  public showServiceEngineerOverwriteDialog = false;
  public goBack: boolean = false;
  public viewRouteMode = false;
  public finishedRouteRequests = 5;

  public components: any[] = [];

  public finishWeekButtonDisabled = false;
  public generateWorkOrdersButtonDisabled = false;

  public initialTourWeekLoading = false;
  public loading = true;

  public tourWeekFinished: boolean = false;

  public tourWeekNotValidated: boolean = false;

  public selectedAppointments: any[] = [];
  public tourWeek: any = {};
  public serviceEngineersViewList: Selection = {
    selected: "",
    error: "",
    items: []
  };
  public serviceEngineers: ServiceEngineer[] = [];
  public timeFrameItems: Selection = { selected: "", error: "", items: [] };

  public finishTourWeekRequest: Request = { loading: false, error: "" };

  public setNotValidatedTourWeekRequest: Request = {
    loading: false,
    error: ""
  };

  public error = "";
  public routeError = "";
}
