import { StorageHandler } from "./StorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import SystemKeys from "@/common/utils/SystemKeys";

export class ParameterStorageHandler extends StorageHandler {
  public static getAllParameters(): SelectionItem[] {
    return this.buildTopLevelSelection(
      SystemKeys.parametersTableKey,
      SystemKeys.parametersNameKey
    );
  }

  public static getParameterFromId(
    parameterId: string,
    parameterValue?: string
  ): PlantParameter {
    if (this.storage.has(SystemKeys.parametersTableKey)) {
      const value = this.storage.findValue(
        SystemKeys.parametersTableKey,
        parameterId
      );

      if (!!value) {
        let selection =
          value[SystemKeys.values][SystemKeys.parametersOptionsKey];
        if (selection && selection.length > 0) {
          selection = selection.map(
            (el: any) => el[SystemKeys.parametersOptionsNameKey]
          );
        }
        return {
          id: parameterId,
          name: value[SystemKeys.values][SystemKeys.parametersNameKey],
          toCheck: value[SystemKeys.values][SystemKeys.parametersToCheckKey],
          unit: value[SystemKeys.values][SystemKeys.parametersUnitKey],
          limit: value[SystemKeys.values][SystemKeys.parametersLimitKey],
          type: value[SystemKeys.values][SystemKeys.parametersTypeKey],
          selection,
          value: parameterValue ?? ""
        };
      }
    }

    return {
      id: parameterId,
      value: ""
    };
  }
}

export interface PlantParameter {
  id: string;
  name?: string;
  toCheck?: boolean;
  unit?: string;
  limit?: string;
  value: string;
  type?: string;
  selection?: string[];
}
