import { TimeTrackingForm } from "../forms/TimeTrackingForm";
import { IPlantService, Plant } from "@/plant/services/PlantService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { Form } from "@/forms/Form";
import { TimeTrackingDayService } from "../services/TimeTrackingDayService";
import { DateUtils } from "@/utils/DateUtils";

export class TimeTrackingCardController {
  private employeeId: string = "";

  public constructor(
    private presenter: ITimeTrackingCardPresenter,
    private plantService: IPlantService,
    private timeTrackingDayService: TimeTrackingDayService
  ) {}

  public mounted(day: any, employeeId: string) {
    this.employeeId = employeeId;
    this.presenter.date = DateUtils.toISOString(day.date);

    if (!!day) {
      this.presenter.data = day;
    }
  }

  public expandView() {
    this.presenter.expanded = !this.presenter.expanded;
  }

  public startTimeChanged(newValue: string) {
    this.presenter.timeTrackingForm.setFieldValue("startTime", newValue);

    this.upsertData();
  }

  public endTimeChanged(newValue: string) {
    this.presenter.timeTrackingForm.setFieldValue("endTime", newValue);

    this.upsertData();
  }

  public breakDurationChanged(newValue: string) {
    this.presenter.timeTrackingForm.setFieldValue(
      "breakDuration",
      newValue.toString()
    );

    this.upsertData();
  }

  public addRow() {
    this.presenter.addRow();
  }

  public deleteRow(index: number) {
    this.presenter.deleteRow(index);

    this.upsertData();
  }

  public maintenanceDriveDurationChanged(index: number, newValue: string) {
    this.changeNumberValue(index, "maintenanceDriveDuration", newValue);

    this.upsertData();
  }

  public maintenanceWorkDurationChanged(index: number, newValue: string) {
    this.changeNumberValue(index, "maintenanceWorkDuration", newValue);

    this.upsertData();
  }

  public maintenanceBonusChanged(index: number, newValue: string) {
    this.presenter.timeTrackingForm.subforms[index].setFieldValue(
      "maintenanceBonus",
      newValue.toString()
    );

    this.upsertData();
  }

  public maintenanceRegimentsChanged(index: number, newValue: string) {
    this.presenter.timeTrackingForm.subforms[index].setFieldValue(
      "maintenanceRegiments",
      newValue.toString()
    );

    this.upsertData();
  }

  public othersDriveDurationChanged(index: number, newValue: string) {
    this.changeNumberValue(index, "othersDriveDuration", newValue);

    this.upsertData();
  }

  public othersWorkDurationChanged(index: number, newValue: string) {
    this.changeNumberValue(index, "othersWorkDuration", newValue);

    this.upsertData();
  }

  public othersBonusChanged(index: number, newValue: string) {
    this.presenter.timeTrackingForm.subforms[index].setFieldValue(
      "othersBonus",
      newValue.toString()
    );

    this.upsertData();
  }

  public othersRegimentsChanged(index: number, newValue: string) {
    this.presenter.timeTrackingForm.subforms[index].setFieldValue(
      "othersRegiments",
      newValue.toString()
    );

    this.upsertData();
  }

  public plantIdChanged(index: number, newValue: string) {
    this.presenter.timeTrackingForm.subforms[index].setFieldValue(
      "plantIndex",
      newValue
    );

    const parsedInt = parseInt(newValue, 10);
    if (!!parsedInt) {
      const request = this.plantService.getPlantByIndex(parsedInt);
      FormRequestHandler.handle(
        request,
        response => {
          this.presenter.plantResponse(index, response);
          if (!response.error && !response.loading) {
            this.upsertData();
          }
        },
        "get-plant-failed"
      );
    }
  }

  public operatorChanged(index: number, newValue: string) {
    this.presenter.timeTrackingForm.subforms[index].setFieldValue(
      "operator",
      newValue
    );

    this.upsertData();
  }

  public doneTasksChanged(index: number, newValue: string) {
    this.presenter.timeTrackingForm.subforms[index].setFieldValue(
      "doneTasks",
      newValue
    );

    this.upsertData();
  }

  public upsertData() {
    const request = this.timeTrackingDayService.upsertTimeTrackingDay({
      ...this.presenter.data,
      employeeId: this.employeeId
    });

    FormRequestHandler.handle(
      request,
      response => (this.presenter.upsertTimeTrackingDayResponse = response),
      "upsert-time-tracking-day-failed"
    );
  }

  private changeNumberValue(
    index: number,
    fieldName: string,
    newValue: string
  ) {
    let parsedValue = parseFloat(newValue.replace(",", "."));
    parsedValue *= 4;
    parsedValue = Math.ceil(parsedValue);
    parsedValue /= 4;
    this.presenter.timeTrackingForm.subforms[index].setFieldValue(
      fieldName,
      parsedValue.toString()
    );
  }
}

export interface ITimeTrackingCardPresenter {
  timeTrackingForm: TimeTrackingForm;
  date: string;
  expanded: boolean;
  loading: boolean;
  data: any;
  upsertTimeTrackingDayResponse: FormResponse<string>;

  addRow(): Form;
  deleteRow(index: number): void;
  plantResponse(index: number, response: FormResponse<Plant>): void;
}
