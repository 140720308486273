export enum Permission {
  EditPlantAndOperator = "editPlantAndOperator",
  ShowAndEditPlantPrizes = "showAndEditPlantPrizes",
  ShowTourPlan = "showTourPlan",
  EditTourPlan = "editTourPlan",
  ApproveTourPlan = "approveTourPlan",
  ApproveWorkOrders = "approveWorkOrders",
  EditWorkOrders = "editWorkOrders",
  SendReports = "sendReports",
  EditEmployees = "editEmployees",
  EditRoles = "editRoles",
  BMDExport = "bmdExport",
  EditStoreCoreData = "edit-core-data",
  ViewStoreEvaulation = "view-evaluation"
}

export enum SystemPermission {
  Salutations = "salutation",
  Countries = "country",
  PlantGroups = "plant-group",
  PlantTypes = "plant-type",
  InstallationParts = "installation-part",
  PlantComponents = "plant-component",
  Parameters = "parameter",
  InspectionReports = "inspection-report",
  PlantSizes = "plant-size",
  DistrictAuthorities = "district-authority",
  Materials = "material",
  WorkOrderTasks = "work-order-task",
  VPI = "vpi",
  Reseller = "reseller"
}
