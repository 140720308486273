import { FormResponse } from "@/forms/FormResponse";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { ICreateSupplierPresenter } from "../controllers/CreateSupplierController";
import { Supplier } from "../services/SupplierGraphQLService";
import { CreateSupplierViewModel } from "../vms/CreateSupplierViewModel";

export class CreateSupplierPresenter implements ICreateSupplierPresenter {
  public areaCodeItems: SelectionItem[] = [];

  public constructor(private vm: CreateSupplierViewModel) {}

  public set visibleView(visibleView: boolean) {
    this.vm.visibleView = visibleView;
  }

  public set getSupplierResponse(response: FormResponse<Supplier>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.salutation.selected = response.data.salutation;
      this.vm.company = response.data.company;
      this.vm.firstName = response.data.firstName;
      this.vm.lastName = response.data.lastName;
      this.vm.street = response.data.street;
      this.vm.zip = response.data.zip;
      this.vm.city = response.data.city;
      this.vm.country.selected = response.data.country;
      this.vm.state.selected = response.data.state;
      this.vm.email = response.data.email;
      this.vm.website = response.data.website;
      this.vm.uid = response.data.uid;
      this.vm.targetPayment = response.data.targetPayment;
      this.vm.skontoPercent = response.data.skontoPercent;
      this.vm.skontoDays = response.data.skontoDays;
      this.vm.phoneNumbers = response.data.phoneNumbers.map(el => ({
        areaCode: { selected: el.areaCode, items: this.areaCodeItems },
        phoneNumber: el.phoneNumber,
        isStandard: el.isStandard
      }));
    }
  }

  public set salutation(salutation: {
    selected: string;
    items: SelectionItem[];
  }) {
    this.vm.salutation = salutation;
  }

  public get salutation(): {
    selected: string;
    items: SelectionItem[];
  } {
    return this.vm.salutation;
  }

  public set country(country: { selected: string; items: SelectionItem[] }) {
    this.vm.country = country;
  }

  public get country(): {
    selected: string;
    items: SelectionItem[];
  } {
    return this.vm.country;
  }

  public set state(state: { selected: string; items: SelectionItem[] }) {
    this.vm.state = state;
  }

  public get state(): { selected: string; items: SelectionItem[] } {
    return this.vm.state;
  }

  public get data(): any {
    return {
      salutation: this.vm.salutation.selected,
      company: this.vm.company,
      firstName: this.vm.firstName,
      lastName: this.vm.lastName,
      street: this.vm.street,
      zip: this.vm.zip,
      city: this.vm.city,
      country: this.vm.country.selected,
      state: this.vm.state.selected,
      email: this.vm.email,
      website: this.vm.website,
      uid: this.vm.uid,
      targetPayment: this.vm.targetPayment,
      skontoPercent: this.vm.skontoPercent,
      skontoDays: this.vm.skontoDays,
      phoneNumbers: this.vm.phoneNumbers.map(el => ({
        areaCode: el.areaCode.selected,
        phoneNumber: el.phoneNumber,
        isStandard: el.isStandard
      }))
    };
  }

  public set createSupplierResponse(response: FormResponse<string>) {
    this.vm.loading = response.loading;
    this.vm.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.visibleView = false;
    }
  }

  public addPhoneNumber(): void {
    this.vm.phoneNumbers.push({
      areaCode: { selected: [], items: this.areaCodeItems },
      phoneNumber: "",
      isStandard: false
    });
  }

  public removePhoneNumber(index: number): void {
    this.vm.phoneNumbers.splice(index, 1);
  }
}
