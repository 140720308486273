



























































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import Draggable from "vuedraggable";
import RightHandCard from "@/components/layout/RightHandCard.vue";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import { WeeklyScheduleViewModel } from "../vms/WeeklyScheduleViewModel";
import { WeeklyScheduleController } from "../controllers/WeeklyScheduleController";

@Component<WeeklyScheduleRightMenu>({
  components: {
    Container,
    Column,
    Row,
    RightHandCard,
    Draggable,
    WeeklyScheduleRightMenu,
    TourDayMap: () => import("@/tourPlanner/views/TourDayMap.vue"),
    CreateLooseAppointmentView: () =>
      import("@/tourPlanner/views/CreateLooseAppointmentView.vue")
  },
  props: ["vm", "controller"]
})
export default class WeeklyScheduleRightMenu extends Vue {
  public vm!: WeeklyScheduleViewModel;
  public controller!: WeeklyScheduleController;

  private goToCreateLooseAppointment() {
    this.vm.components.push({
      component: "CreateLooseAppointmentView"
    });
  }
}
