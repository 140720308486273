import { SelectionItem } from "@/forms/ViewModelFormTypes";

export class CreateItemTemplateViewModel {
  public visibleView = false;

  public name = "";
  public itemNumber = "";
  public type: { selected: string | string[]; items: SelectionItem[] } = {
    selected: [],
    items: []
  };
  public group: { selected: string | string[]; items: SelectionItem[] } = {
    selected: [],
    items: []
  };

  public dimensions = "";
  public unit: { selected: string | string[]; items: SelectionItem[] } = {
    selected: [],
    items: []
  };
  public hk = "";
  public vk = "";
  public supplier: { selected: string | string[]; items: SelectionItem[] } = {
    selected: [],
    items: []
  };

  public loading = false;
  public error = "";
}
