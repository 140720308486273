



















import Vue from "vue";
import Component from "vue-class-component";

@Component<SubmitTextfield>({
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: "search"
    },
    outlined: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class SubmitTextfield extends Vue {
  private emitChange(text: string) {
    if (!text) {
      text = "";
    }
    this.$emit("change", text);
    this.$emit("input", text);
  }

  private clear() {
    this.$emit("submit", "");
  }

  private submit() {
    this.$emit("submit", this.$props.value);
  }
}
