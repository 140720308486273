import { FormResponse } from "@/forms/FormResponse";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { ICreateItemTemplatePresenter } from "../controllers/CreateItemTemplateController";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { CreateItemTemplateViewModel } from "../vms/CreateItemTemplateViewModel";

export class CreateItemTemplatePresenter
  implements ICreateItemTemplatePresenter {
  public constructor(private vm: CreateItemTemplateViewModel) {}

  public set visibleView(visibleView: boolean) {
    this.vm.visibleView = visibleView;
  }

  public set getItemTemplateResponse(response: FormResponse<ItemTemplate>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.name = response.data.name;
      this.vm.itemNumber = response.data.itemNumber.toString() || "";
      this.vm.type.selected = response.data.typeId;
      this.vm.group.selected = response.data.groupId;
      this.vm.dimensions = response.data.dimensions;
      this.vm.unit.selected = response.data.unitId;
      this.vm.hk = response.data.hk?.toString() || "";
      this.vm.vk = response.data.vk?.toString() || "";
      this.vm.supplier.selected = response.data.suppliers.map(el => el.id);
    }
  }

  public set getSuppliersResponse(response: FormResponse<any[]>) {
    if (!response.loading && !response.error) {
      this.vm.supplier.items = response.data.map((el: any) => ({
        text: el.name,
        value: el.id
      }));
    }
  }

  public set type(type: {
    selected: string | string[];
    items: SelectionItem[];
  }) {
    this.vm.type = type;
  }

  public get type(): {
    selected: string | string[];
    items: SelectionItem[];
  } {
    return this.vm.type;
  }

  public set group(group: {
    selected: string | string[];
    items: SelectionItem[];
  }) {
    this.vm.group = group;
  }

  public get group(): {
    selected: string | string[];
    items: SelectionItem[];
  } {
    return this.vm.group;
  }

  public set unit(unit: {
    selected: string | string[];
    items: SelectionItem[];
  }) {
    this.vm.unit = unit;
  }

  public get unit(): { selected: string | string[]; items: SelectionItem[] } {
    return this.vm.unit;
  }

  public get data(): any {
    return {
      name: this.vm.name,
      itemNumber: parseInt(this.vm.itemNumber, 10) || undefined,
      typeId: this.vm.type.selected,
      groupId: this.vm.group.selected,
      dimensions: this.vm.dimensions,
      unitId: this.vm.unit.selected,
      hk: parseInt(this.vm.hk, 10) || undefined,
      vk: parseInt(this.vm.vk, 10) || undefined,
      supplierIds: this.vm.supplier.selected
    };
  }

  public set createItemTemplateResponse(response: FormResponse<string>) {
    this.vm.loading = response.loading;
    this.vm.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.visibleView = false;
    }
  }
}
