import { CachedService } from "@/common/services/CachedService";
import { ServiceArgument } from "@/common/services/ServiceFacade";
import { OnlineCheckerFactory } from "@/common/utils/OnlineCheckerFactory";
import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import {
  GraphQLConnection,
  OrderByClause
} from "@/gateways/graphql/GraphQLConnection";
import { StoreMovementGraphQLService } from "./StoreMovementGraphQLService";
import { StoreMovementLocalService } from "./StoreMovementLocalService";

export class StoreMovementService
  implements IStoreMovementService, CachedService {
  private onlineService: StoreMovementGraphQLService;
  private offlineService: StoreMovementLocalService;
  private service: IStoreMovementService;

  constructor(connection: GraphQLConnection) {
    this.offlineService = new StoreMovementLocalService();
    this.onlineService = new StoreMovementGraphQLService(connection);

    if (OnlineCheckerFactory.isOnline) {
      this.service = this.onlineService;
    } else {
      this.service = this.offlineService;
    }
  }

  public get timestamp() {
    return this.offlineService.timestamp;
  }

  public async getStoreMovementsForOverviewPaginated(
    page: Page,
    order: OrderByClause[],
    search: string,
    storeFilter: string[],
    itemTemplateFilter: string[],
    isPlant = false
  ): Promise<PaginatedList<any[]>> {
    this.updateOnlineState();
    return this.service.getStoreMovementsForOverviewPaginated(
      page,
      order,
      search,
      storeFilter,
      itemTemplateFilter,
      isPlant
    );
  }

  public async getStoreMovementById(id: string): Promise<any> {
    this.updateOnlineState();
    return this.service.getStoreMovementById(id);
  }

  public async createStoreMovement(input: any): Promise<string> {
    this.updateOnlineState();
    return this.service.createStoreMovement(input);
  }

  public async exportStoreMovements(): Promise<any> {
    this.updateOnlineState();
    return this.service.exportStoreMovements();
  }

  public async updateStoreMovement(data: any): Promise<string> {
    this.updateOnlineState();
    return this.service.updateStoreMovement(data);
  }

  public async deleteStoreMovement(id: string): Promise<string> {
    this.updateOnlineState();
    return this.service.deleteStoreMovement(id);
  }

  public async download(arg?: ServiceArgument): Promise<void> {
    const storeMovements: any[] = [];
    if (arg && arg.ids) {
      const promises = [];
      for (const id of arg.ids) {
        promises.push(
          this.onlineService
            .getStoreMovementById(id)
            .then(result => storeMovements.push(result))
        );
      }

      await Promise.all(promises);
    }

    this.offlineService.storeMovements = storeMovements;
  }

  public upload() {
    for (const storeMovementFlaggedEntity of this.offlineService.getToSyncStoreMovements()) {
      const storeMovement = storeMovementFlaggedEntity.entity;
      this.onlineService.createStoreMovement(storeMovement);
      storeMovementFlaggedEntity.changed = false;
    }
    this.offlineService.save();
  }

  private updateOnlineState() {
    if (OnlineCheckerFactory.isOnline) {
      this.service = this.onlineService;
    } else {
      this.service = this.offlineService;
    }
  }
}

export interface IStoreMovementService {
  getStoreMovementsForOverviewPaginated(
    page: Page,
    order: OrderByClause[],
    search: string,
    storeFilter: string[],
    itemTemplateFilter: string[],
    isPlant?: boolean
  ): Promise<PaginatedList<any[]>>;
  getStoreMovementById(id: string): Promise<any>;
  createStoreMovement(input: any): Promise<string>;
  exportStoreMovements(): Promise<any>;
  updateStoreMovement(data: any): Promise<string>;
  deleteStoreMovement(id: string): Promise<string>;
}
