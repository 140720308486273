export class ParametersAccordionViewModel {
  public enabled = false;
  public parameters: Array<{
    name: string;
    id: string;
    value: string;
    suffix: string;
    type: string;
    selection: string[];
  }> = [];
}
