import { GraphQLConnection } from "@/gateways/graphql/GraphQLConnection";

export class GraphQLSystemTableService implements SystemTableService {
  public constructor(private connection: GraphQLConnection) {}

  public async getTableStructure(tableId: string) {
    const result = await this.connection.query("systemMeta", { id: tableId }, [
      "id",
      "labelSingular",
      "labelPlural",
      "fields"
    ]);

    return {
      id: result.data.id,
      labelSingular: result.data.labelSingular,
      labelPlural: result.data.labelPlural,
      fields: result.data.fields
    };
  }

  public async getTableEntry(id: string) {
    const result = await this.connection.query("systemEntry", { id }, [
      "id",
      "values"
    ]);
    return {
      id: result.data.id,
      metumId: "",
      values: result.data.values
    };
  }

  public async getAllTableEntries(tableId: string) {
    const result = await this.connection.query(
      "systemEntries",
      { metumId: tableId },
      ["id", "values"]
    );
    return result.data.map((item: any) => ({
      id: item.id,
      values: item.values
    }));
  }

  public async deleteTableEntry(id: string) {
    const result = await this.connection.mutation(
      "deleteSystemEntry",
      { id },
      []
    );
    return result.data;
  }

  public async saveTableEntry(entry: SystemTableEntry) {
    return !!entry.id
      ? this.updateTableEntry(entry)
      : this.createTableEntry(entry);
  }

  public async getAllSettingsTables() {
    const result = await this.connection.query("systemMetas", {}, [
      "id",
      "labelSingular",
      "labelPlural",
      "fields",
      "group"
    ]);
    return result.data;
  }

  public async getAllSettings() {
    const result = await this.connection.query("systemEntries", {}, [
      "id",
      "metumLabel",
      "values"
    ]);
    return result.data.map((item: any) => ({
      id: item.id,
      settingsTableName: item.metumLabel,
      values: item.values
    }));
  }

  private async createTableEntry(entry: SystemTableEntry) {
    const result = await this.connection.mutation(
      "createSystemEntry",
      {
        input: {
          id: entry.id,
          metaId: entry.metumId,
          values: entry.values
        }
      },
      []
    );

    return result.data;
  }

  private async updateTableEntry(entry: SystemTableEntry) {
    const result = await this.connection.mutation(
      "updateSystemEntry",
      {
        input: {
          id: entry.id,
          values: entry.values
        }
      },
      []
    );

    return result.data;
  }
}

export interface SystemTableService {
  getTableStructure(tableId: string): Promise<SystemTableStructure>;
  getTableEntry(id: string): Promise<SystemTableEntry>;
  getAllTableEntries(tableId: string): Promise<SystemTableEntries>;
  saveTableEntry(entry: SystemTableEntry): Promise<string>;
  deleteTableEntry(id: string): Promise<string>;
  getAllSettingsTables(): Promise<SettingsTableList>;
  getAllSettings(): Promise<SettingsList>;
}

export interface SystemTableStructure {
  id: string;
  labelSingular: string;
  labelPlural: string;
  fields: string;
}

export type SystemTableEntries = SystemTableEntry[];

export interface SystemTableEntry {
  id: string;
  metumId: string;
  values: string;
}

export type SettingsTableList = SettingsTable[];

export interface SettingsTable {
  id: string;
  labelSingular: string;
  labelPlural: string;
  fields: string;
}

export type SettingsList = Settings[];

export interface Settings {
  id: string;
  settingsTableName: string;
  values: string;
}
