



























import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

@Component<Section>({
  components: {
    Container,
    Column,
    Row
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: "surface"
    },
    maxWidth: {
      type: String,
      default: ""
    }
  }
})
export default class Section extends Vue {
  private opacity!: number;

  private get sectionStyle() {
    return {
      opacity: this.opacity
    };
  }
}
