import { StorageHandler } from "./StorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import SystemKeys from "@/common/utils/SystemKeys";

export class PlantSizeStorageHandler extends StorageHandler {
  public static getAllPlantSizes(): SelectionItem[] {
    return this.buildTopLevelSelection(
      SystemKeys.sizesTableKey,
      SystemKeys.sizesNameKey
    );
  }

  public static getPlantSizeFromId(parameterId: string): PlantParameter {
    if (this.storage.has(SystemKeys.sizesTableKey)) {
      const value = this.storage.findValue(
        SystemKeys.sizesTableKey,
        parameterId
      );

      if (!!value) {
        return {
          id: parameterId,
          name: value[SystemKeys.values][SystemKeys.sizesNameKey],
          toCheck: value[SystemKeys.values][SystemKeys.sizesCompareKey],
          unit: value[SystemKeys.values][SystemKeys.sizesUnitKey],
          value: ""
        };
      }
    }

    return {
      id: parameterId,
      value: ""
    };
  }
}

export interface PlantParameter {
  id: string;
  name?: string;
  toCheck?: boolean;
  unit?: string;
  limit?: string;
  value: string;
}
