













































import Vue from "vue";
import Component from "vue-class-component";

import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Section from "@/components/layout/Section.vue";
import Subtitle from "@/components/layout/Subtitle.vue";

import RequestButton from "@/components/basic/RequestButton.vue";
import Textfield from "@/components/form/Textfield.vue";

import { LoginViewModel } from "@/common/vms/LoginViewModel";
import { LoginController } from "@/common/controllers/LoginController";
import { LoginPresenter } from "@/common/presenters/LoginPresenter";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { LocalStorage } from "@/storage/LocalStorage";
import { AuthenticationGraphQLService } from "../interactors/AuthenticationService";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connections/AxiosGraphQLConnection";

@Component<LoginView>({
  components: {
    Grid,
    Row,
    Column,
    Section,
    Subtitle,
    Textfield,
    RequestButton
  },
  watch: {
    "vm.loggedIn"(loggedIn: boolean) {
      if (loggedIn) {
        this.$router.push({ name: "home", params: { action: "reload" } });
      }
    }
  }
})
export default class LoginView extends Vue {
  private vm = new LoginViewModel();
  private controller?: LoginController;

  private initController() {
    return new LoginController(
      new LoginPresenter(this.vm),
      new AuthenticationGraphQLService(
        new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
      ),
      new LocalStorage()
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.init();
  }
}
