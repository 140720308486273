import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { DateUtils } from "@/utils/DateUtils";
import {
  TourDay,
  ITourWeekService,
  TourWeek,
  TourRoute
} from "@/tourPlanner/services/TourWeekGraphQLService";
import { Selection } from "@/forms/ViewModelFormTypes";
import {
  IAppointmentService,
  Appointment
} from "../services/AppointmentGraphQLService";
import {
  IServiceEngineerService,
  ServiceEngineer
} from "../services/ServiceEngineerGraphQLService";
import { FormResponse } from "@/forms/FormResponse";
import { Auth } from "@/common/utils/Auth";
import StorageKeys from "@/common/utils/StorageKeys";
import { ErrorHandler } from "@/common/utils/ErrorHandler";
import { AppointmentHandler } from "../utils/AppointmentHandler";
import Vue from "vue";

export class WeeklyScheduleController {
  private appointmentHandler: AppointmentHandler;

  public constructor(
    private presenter: IWeeklySchedulePresenter,
    private appointmentService: IAppointmentService,
    private tourWeekService: ITourWeekService,
    private serviceEngineerService: IServiceEngineerService
  ) {
    this.appointmentHandler = new AppointmentHandler(appointmentService);
  }

  public async mounted(
    selectedServiceEngineerId: string,
    selectedWeekDate: string
  ) {
    this.presenter.timeFrameItems = this.makeTimeFrameItems(selectedWeekDate);

    this.getAlreadySelectedAppointments();
    await this.getServiceEngineers();

    if (!!selectedServiceEngineerId) {
      this.presenter.serviceEngineersViewList.selected = selectedServiceEngineerId;
    }

    this.makeTourWeek();
    if (!this.canEdit() && !this.canApprove()) {
      this.presenter.viewRouteMode = true;
    }

    this.presenter.visibleView = true;
  }

  public canEdit() {
    return Auth.hasPermission(StorageKeys.editTourPlanPermission);
  }

  public canApprove() {
    return Auth.hasPermission(StorageKeys.approveTourPlanPermission);
  }

  public formatDate(date: string) {
    return DateUtils.format(date);
  }

  public parseAddress(appointment: Appointment) {
    return `${appointment.addressStreet},\n${appointment.addressZip} ${appointment.addressCity},\n${appointment.addressCountry}`;
  }

  public weekDateSelected(selectedWeekDate: string) {
    this.presenter.timeFrameItems.selected = selectedWeekDate;
    this.makeTourWeek();
  }

  public serviceEngineerSelected(selectedServiceEngineerId: string) {
    this.presenter.serviceEngineersViewList.selected = selectedServiceEngineerId;
  }

  public removeAppointmentFromSelection(appointment: any, index: number) {
    const request = this.appointmentService.unselectAppointment(appointment.id);
    FormRequestHandler.handle(
      request,
      response => (this.presenter.unselectAppointmentResponse = response),
      "unselect-appointment-failed"
    );
  }

  public weekLeft() {
    const index = this.presenter.timeFrameItems.items.findIndex(
      el => el.value === this.presenter.timeFrameItems.selected
    );
    const newWeekDate = this.presenter.timeFrameItems.items[index - 1].value;
    if (index > 0) {
      this.presenter.timeFrameItems.selected = newWeekDate;
    }
    this.makeTourWeek();
  }

  public weekRight() {
    const index = this.presenter.timeFrameItems.items.findIndex(
      el => el.value === this.presenter.timeFrameItems.selected
    );

    if (index + 1 < this.presenter.timeFrameItems.items.length) {
      const newWeekDate = this.presenter.timeFrameItems.items[index + 1].value;
      this.presenter.timeFrameItems.selected = newWeekDate;
    }
    this.makeTourWeek();
  }

  public makeTourDayDate(index: number) {
    const date = new Date(this.presenter.timeFrameItems.selected);
    date.setDate(date.getDate() + index);
    date.setHours(23, 59, 59);
    return date;
  }

  public getMonthOfTourDays() {
    const startDate = new Date(this.presenter.timeFrameItems.selected);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 4);

    if (startDate.getMonth() === endDate.getMonth()) {
      return startDate.toLocaleString("de-DE", {
        month: "long",
        year: "numeric"
      });
    } else {
      return (
        startDate.toLocaleString("de-DE", { month: "long", year: "numeric" }) +
        " - " +
        endDate.toLocaleString("de-De", { month: "long", year: "numeric" })
      );
    }
  }

  public getCalendarWeekOfSelected() {
    return (
      "KW " +
      DateUtils.weekNumber(new Date(this.presenter.timeFrameItems.selected))
    );
  }

  public enableEditCalendar() {
    this.presenter.viewRouteMode = false;
    for (const tourDay of this.presenter.tourWeek.tourDays) {
      for (let i = tourDay.appointments.length - 1; i >= 0; i--) {
        const appointment = tourDay.appointments[i];
        if (!!(appointment as TourRoute).distance) {
          tourDay.appointments.splice(i, 1);
        }
      }
      tourDay.error = "";
    }
  }

  public calculateRoutes() {
    const request = this.tourWeekService.requestTourWeekRoutes(
      this.presenter.tourWeek.id!
    );

    FormRequestHandler.handle(
      request,
      response => (this.presenter.requestTourWeekRoutesResponse = response),
      "get-routes-failed"
    );
  }

  public getLoggedInTourPlannerId() {
    return Auth.userId;
  }

  public parseTime(time: string): number[] {
    return time.split(":").map(el => parseInt(el, 10));
  }

  public async editTourDayPropsConfirmed(tourDay: TourDay, args: any) {
    if (!this.presenter.tourWeek.id) {
      const upsertRequest = this.tourWeekService.upsertTourWeek(
        this.getLoggedInTourPlannerId(),
        this.presenter.tourWeek.weekNumber,
        this.presenter.tourWeek.serviceEngineerId,
        this.presenter.tourWeek.tourDays
      );
      const formResponse = await FormRequestHandler.handle(
        upsertRequest,
        response => (this.presenter.upsertTourWeekResponse = response),
        "upsert-tour-week-failed"
      );
      if (formResponse.error) {
        ErrorHandler.push("Create TourWeek failed\n" + formResponse.error);
      }
      if (!this.presenter.tourWeek.id) {
        ErrorHandler.push("Tourweek not existent");
        return;
      }
    }
    const request = this.tourWeekService.replaceTourDay(
      tourDay.index,
      args.startTime,
      args.startLocationStreet,
      args.startLocationZip,
      args.startLocationCity,
      args.startLocationCountry,
      args.endLocationStreet,
      args.endLocationZip,
      args.endLocationCity,
      args.endLocationCountry,
      this.presenter.tourWeek.id!
    );
    const argsForPresenter = {
      startLocationStreet: args.startLocationStreet,
      startLocationZip: args.startLocationZip,
      startLocationCity: args.startLocationCity,
      startLocationCountry: args.startLocationCountry,
      endLocationStreet: args.endLocationStreet,
      endLocationZip: args.endLocationZip,
      endLocationCity: args.endLocationCity,
      endLocationCountry: args.endLocationCountry,
      startTime: args.startTime
    };

    FormRequestHandler.handle(
      request,
      response =>
        (this.presenter.replaceTourDayResponse = {
          response,
          args: argsForPresenter
        }),
      "replace-tour-day-failed"
    );
  }

  public editAppointmentPropsConfirmed(appointment: any, args: any) {
    const request = this.appointmentService.updateAppointmentReasonDuration(
      appointment.id,
      args.reason,
      args.duration,
      args.remarks
    );

    FormRequestHandler.handle(
      request,
      response =>
        (this.presenter.updateAppointmentReasonDurationResponse = {
          response,
          args: { ...args, appointment }
        }),
      "update-appointment-failed"
    );
  }

  public removeAppointmentFromTourDays(
    tourDay: TourDay,
    appointmentIndex: number,
    cancellationReason: string
  ) {
    const appointment = tourDay.appointments[appointmentIndex] as Appointment;

    if (!cancellationReason) {
      const request = this.appointmentService.updateAppointmentTourDayIndex(
        appointment.id,
        null,
        null,
        null
      );
      FormRequestHandler.handle(
        request,
        response =>
          (this.presenter.removeAppointmentFromTourDaysResponse = {
            response,
            args: { tourDay, appointment }
          }),
        "remove-appointment-failed"
      );
    } else {
      const request = this.appointmentService.cancelAppointment(
        appointment.id,
        cancellationReason
      );
      FormRequestHandler.handle(
        request,
        response =>
          (this.presenter.cancelAppointmentResponse = {
            response,
            args: { tourDay, appointment }
          }),
        "cancel-appointment-failed"
      );
    }
  }

  public async updateTourDayAppointments(tourDay: TourDay) {
    if (!this.presenter.tourWeek.id) {
      const request = this.tourWeekService.upsertTourWeek(
        this.getLoggedInTourPlannerId(),
        this.presenter.tourWeek.weekNumber,
        this.presenter.tourWeek.serviceEngineerId,
        this.presenter.tourWeek.tourDays
      );
      const formResponse = await FormRequestHandler.handle(
        request,
        response => (this.presenter.upsertTourWeekResponse = response),
        "upsert-tour-week-failed"
      );
      if (formResponse.error) {
        ErrorHandler.push("Create TourWeek failed\n" + formResponse.error);
      }
      if (!this.presenter.tourWeek.id) {
        ErrorHandler.push("Tourweek not existent");
        return;
      }
    }

    const time = new Date(this.presenter.timeFrameItems.selected);
    time.setDate(time.getDate() + tourDay.index);
    const parsedStartTime = this.parseTime(tourDay.startTime);
    time.setHours(parsedStartTime[0], parsedStartTime[1]);

    for (const appointment of tourDay.appointments as Appointment[]) {
      appointment.plannedDate = new Date(time);
      time.setHours(
        time.getHours() + Math.floor(appointment.duration / 60),
        time.getMinutes() + (appointment.duration % 60)
      );

      await this.appointmentService
        .updateAppointmentTourDayIndex(
          appointment.id,
          this.presenter.tourWeek.id!,
          tourDay.index,
          appointment.plannedDate.toString()
        )
        .catch(() => {
          const boundFun = this.writeToAppointments.bind(this);
          this.appointmentHandler.addFunction(
            appointment,
            this.presenter.tourWeek.id!,
            tourDay.index,
            appointment.plannedDate.toString(),
            boundFun
          );
          throw new Error("Update Appointment failed");
        });
    }
  }

  public async tourDayChanged(tourDay: TourDay, event: any) {
    if (!!event.added) {
      const appointment = event.added.element;
      const newIndex = event.added.newIndex;
      Vue.set(appointment, "customColor", undefined);

      Vue.set(appointment, "loading", true);
      try {
        await this.updateTourDayAppointments(tourDay);
        appointment.updateSuccess = true;
        Vue.set(appointment, "loading", false);
        tourDay.appointments.splice(newIndex, 1, appointment);
      } catch (error) {
        appointment.updateSuccess = false;
        Vue.set(appointment, "customColor", "error");
        tourDay.appointments.splice(newIndex, 1, appointment);
      }
    } else if (!!event.moved) {
      const appointment = event.moved.element;

      Vue.set(appointment, "customColor", undefined);
      appointment.loading = true;
      try {
        await this.updateTourDayAppointments(tourDay);
        appointment.updateSuccess = true;
        Vue.set(appointment, "loading", false);
      } catch (error) {
        appointment.updateSuccess = false;
        Vue.set(appointment, "customColor", "error");
      }
    }
  }

  public async selectionChanged(event: any) {
    if (!!event.added) {
      const appointment = event.added.element;
      const newIndex = event.added.newIndex;
      Vue.set(appointment, "loading", false);

      if (!this.presenter.tourWeek.id) {
        const upsertRequest = this.tourWeekService.upsertTourWeek(
          this.getLoggedInTourPlannerId(),
          this.presenter.timeFrameItems.selected,
          this.presenter.serviceEngineersViewList.selected,
          this.presenter.tourWeek.tourDays
        );
        const formResponse = await FormRequestHandler.handle(
          upsertRequest,
          response => (this.presenter.upsertTourWeekResponse = response),
          "upsert-tour-week-failed"
        );
        if (formResponse.error) {
          ErrorHandler.push("Create TourWeek failed\n" + formResponse.error);
        }
        if (!this.presenter.tourWeek.id) {
          ErrorHandler.push("Tourweek not existent");
        }

        if (!this.presenter.tourWeek.id) {
          ErrorHandler.push("Tourweek not existent");
          return;
        }
        const getTourByIdRequest = this.tourWeekService.getTourWeekById(
          this.presenter.tourWeek.id,
          this.getLoggedInTourPlannerId(),
          this.presenter.serviceEngineersViewList.selected
        );
        FormRequestHandler.handle(
          getTourByIdRequest,
          response => {
            if (!response.loading && !response.error) {
              this.presenter.tourWeek = response.data;
            }
          },
          "get-tourweek-failed"
        );
      }

      const request = this.appointmentService.updateAppointmentTourDayIndex(
        appointment.id,
        null,
        null,
        null
      );
      appointment.tourDayIndex = null;

      await request
        .then(() => {
          appointment.updateSuccess = true;
          Vue.set(appointment, "loading", false);
          this.presenter.selectedAppointments.splice(newIndex, 1, appointment);
        })
        .catch(() => {
          appointment.updateSuccess = false;
          Vue.set(appointment, "customColor", "error");
          const boundFun = this.writeToAppointments.bind(this);
          this.appointmentHandler.addFunction(
            appointment,
            null,
            null,
            null,
            boundFun
          );
          this.presenter.selectedAppointments.splice(newIndex, 1, appointment);
        });
    }
  }

  public writeToAppointments(appointment: Appointment) {
    Vue.set(appointment, "customColor", undefined);
    appointment.updateSuccess = true;
    appointment.loading = false;
    if (
      this.presenter.selectedAppointments.some(ap => ap.id === appointment.id)
    ) {
      const index = this.presenter.selectedAppointments.findIndex(
        (ap: any) => ap.id === appointment.id
      );

      this.presenter.selectedAppointments.splice(index, 1, appointment);
    } else {
      for (const tourDay of this.presenter.tourWeek.tourDays) {
        if (tourDay.appointments.some((ap: any) => ap.id === appointment.id)) {
          const index = tourDay.appointments.findIndex(
            (ap: any) => ap.id === appointment.id
          );

          tourDay.appointments.splice(index, 1, appointment);
        }
      }
    }
  }

  public getAlreadySelectedAppointments() {
    const request = this.appointmentService.getAllSelectedAppointmentsByTourPlanner(
      this.getLoggedInTourPlannerId()
    );

    FormRequestHandler.handle(
      request,
      response =>
        (this.presenter.getAllSelectedAppointmentsByTourPlannerResponse = response),
      "get-selected-appointments-failed"
    );
  }

  public finishTourWeekButtonClicked(id: string) {
    const request = this.tourWeekService.finishTourWeek(id);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.finishTourWeekResponse = response),
      "finish-tourweek-impossible"
    );
  }

  public setNotValidatedTourWeekButtonClicked(id: string) {
    const request = this.tourWeekService.setNotValidatedTourWeek(id);

    FormRequestHandler.handle(
      request,
      response => (this.presenter.setNotValidatedTourWeekResponse = response),
      "wait-setValidate-tourweek-impossible"
    );
  }

  private async getServiceEngineers() {
    const request = this.serviceEngineerService.getServiceEngineers();
    await FormRequestHandler.handle(
      request,
      response => (this.presenter.getServiceEngineersResponse = response),
      "get-service-engineers-failed"
    );
  }

  private makeTimeFrameItems(selectedWeekDate: string): Selection {
    const now = DateUtils.removeTimeStamp(new Date());
    let date = new Date(now);
    date.setFullYear(date.getFullYear() - 1);

    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    date.setDate(diff);

    const endDate = new Date(now);
    endDate.setFullYear(endDate.getFullYear() + 1);

    const items = [];
    const selected = selectedWeekDate;
    while (date < endDate) {
      const nextWeekDate = new Date(date);
      nextWeekDate.setDate(nextWeekDate.getDate() + 4);
      const item = `KW ${DateUtils.weekNumber(
        date
      )}/${date.getFullYear()} (${DateUtils.formatDate(
        date
      )} - ${DateUtils.formatDate(nextWeekDate)})`;
      items.push({ text: item, value: DateUtils.dateOnly(date) });
      nextWeekDate.setDate(nextWeekDate.getDate() + 3);
      date = nextWeekDate;
    }

    return { items, selected, error: "" };
  }

  private async makeTourWeek() {
    if (
      await this.tourWeekService.tourWeekExistsByWeekNumberAndServiceEngineerId(
        this.presenter.timeFrameItems.selected,
        this.presenter.serviceEngineersViewList.selected
      )
    ) {
      await this.getTourWeekFromServer();
    } else {
      this.generateNewTourWeek();
    }
  }

  private async getTourWeekFromServer() {
    this.presenter.tourWeek = await this.tourWeekService.getTourWeekByWeekNumberAndServiceEngineerId(
      this.presenter.timeFrameItems.selected,
      this.presenter.serviceEngineersViewList.selected
      // this.getLoggedInTourPlannerId()
    );
    this.presenter.tourWeek.weekNumber = DateUtils.removeTimeStamp(
      new Date(this.presenter.tourWeek.weekNumber)
    ).toString();

    for (const tourDay of this.presenter.tourWeek.tourDays) {
      if (!tourDay.appointments) {
        tourDay.appointments = [];
      }
      tourDay.appointments.sort(
        (a: any, b: any) => a.plannedDate - b.plannedDate
      );
      tourDay.date = this.makeTourDayDate(tourDay.index);
    }

    this.presenter.tourWeek.tourDays.sort((a, b) => a.index - b.index);

    if (this.presenter.tourWeek.processingState !== "STARTED") {
      this.presenter.viewRouteMode = true;
      this.calculateRoutes();
    }
  }

  private generateNewTourWeek() {
    this.presenter.tourWeek = {
      id: undefined,
      weekNumber: this.presenter.timeFrameItems.selected,
      serviceEngineerId: this.presenter.serviceEngineersViewList.selected,
      tourDays: [],
      processingState: "STARTED",
      appointments: []
    };

    const serviceEngineer = this.presenter.serviceEngineers.find(
      el => el.id === this.presenter.serviceEngineersViewList.selected
    );

    if (!serviceEngineer) {
      this.presenter.error = "ServiceEngineer doesn't exist!";
      return;
    }

    for (let i = 0; i < 5; i++) {
      this.presenter.tourWeek.tourDays.push({
        index: i,
        startLocation: {
          street: serviceEngineer.startLocationStreet,
          zip: serviceEngineer.startLocationZip,
          city: serviceEngineer.startLocationCity,
          country: serviceEngineer.startLocationCountry
        },
        endLocation: {
          street: serviceEngineer.endLocationStreet,
          zip: serviceEngineer.endLocationZip,
          city: serviceEngineer.endLocationCity,
          country: serviceEngineer.endLocationCountry
        },
        appointments: [],
        startTime: serviceEngineer.startTimes[i],
        directionsResponse: {},
        tourWeekId: "",
        date: this.makeTourDayDate(i),
        error: ""
      });
    }
  }

  private parseAddressString(address: string) {
    const splitAddress = address.split(",");
    const splitZipCity = splitAddress[1].split(" ");

    return {
      street: splitAddress[0],
      zip: splitZipCity[0],
      city: splitZipCity[1],
      country: splitAddress[2]
    };
  }
}

export interface IWeeklySchedulePresenter {
  error: string;

  visibleView: boolean;
  viewRouteMode: boolean;
  selectedAppointments: any[];
  serviceEngineers: ServiceEngineer[];
  serviceEngineersViewList: Selection;
  timeFrameItems: Selection;
  showServiceEngineerOverwriteDialog: boolean;
  tourWeek: TourWeek;
  finishedRouteRequests: number;

  finishTourWeekResponse: FormResponse<string>;
  setNotValidatedTourWeekResponse: FormResponse<string>;
  upsertTourWeekResponse: FormResponse<string>;

  unselectAppointmentResponse: FormResponse<string>;
  requestTourWeekRoutesResponse: FormResponse<any[]>;
  replaceTourDayResponse: ResponseWithArgs<number>;
  updateAppointmentReasonDurationResponse: ResponseWithArgs<string>;
  removeAppointmentFromTourDaysResponse: ResponseWithArgs<string>;
  cancelAppointmentResponse: ResponseWithArgs<string>;
  getAllSelectedAppointmentsByTourPlannerResponse: FormResponse<Appointment[]>;
  getServiceEngineersResponse: FormResponse<ServiceEngineer[]>;
}

export interface ResponseWithArgs<T> {
  response: FormResponse<T>;
  args: any;
}
