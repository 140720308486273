















































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import Timer from "@/components/basic/Timer.vue";

import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedCombobox from "@/components/form/vee-validate/ValidatedCombobox.vue";
import ValidatedTimepicker from "@/components/form/vee-validate/ValidatedTimepicker.vue";

import { GeneralTabViewModel } from "@/report/vms/GeneralTabViewModel";
import { GeneralTabPresenter } from "@/report/presenters/GeneralTabPresenter";
import { GeneralTabController } from "@/report/controllers/GeneralTabController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { StoreItemService } from "@/store/services/StoreItemService";

@Component<GeneralTab>({
  components: {
    ValidationObserver,
    Row,
    Column,
    Container,
    ValidatedTextfield,
    ValidatedCombobox,
    ValidatedTimepicker,
    Timer
  },
  props: ["parentController", "parentVm", "usedTime", "materials", "tasksDone"]
})
export default class GeneralTab extends Vue {
  public controller?: GeneralTabController;
  private vm = new GeneralTabViewModel();

  private initController(): GeneralTabController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new GeneralTabController(
      new GeneralTabPresenter(this.vm),
      new StoreItemService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(
      this.$props.parentController.editEnabled(),
      this.$props.usedTime,
      this.$props.materials,
      this.$props.tasksDone
    );
  }
}
