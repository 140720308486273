import { Appointment } from "../services/AppointmentGraphQLService";
import { Request, MultiSelect, Date } from "@/forms/ViewModelFormTypes";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";
import { Dictionary } from "@/datastructures/Dictionary";

export class AppointmentMapViewModel {
  public appointments: Dictionary<any> = {};
  public selectedAppointments: Appointment[] = [];
  public center: object = { lat: 46.7869939, lng: 14.3810993 };
  public centerAddress: string = "";
  public tourWeekAlreadyStarted = false;

  public google?: any;
  public map?: any;
  public oms?: OverlappingMarkerSpiderfier;
  public clusterer?: MarkerClusterer;
  public infoWindows: any[] = [];

  public filterShown = false;
  public showMaintenances = true;
  public showAppointments = true;
  public startDate: Date = {
    value: "",
    text: "",
    loading: false,
    error: ""
  };
  public endDate: Date = {
    value: "",
    text: "",
    loading: false,
    error: ""
  };
  public states: MultiSelect = { selected: [], error: "", items: [] };

  public selectedTourPlannerId: string = "";

  public get filters() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      showMaintenances: this.showMaintenances,
      showAppointments: this.showAppointments,
      states: this.states.selected
    };
  }
}
