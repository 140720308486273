import { IPlantService } from "../services/PlantService";
import { MultiUploadPlantDocument } from "../vms/MultiUploadViewModel";

export class MultiUploadController {
  public constructor(
    private presenter: IMultiUploadPresenter,
    private plantService: IPlantService
  ) {}

  public addDocuments(documents: File[]) {
    for (const document of documents) {
      if (
        this.isValidDocument(document.name) &&
        this.presenter.documents.findIndex(d => d.file.name === document.name) <
          0
      ) {
        this.presenter.documents.push({
          plantId: this.getPlantId(document.name),
          documentName: this.getDocumentNameWithoutExtensionAndPlant(
            document.name
          ),
          file: document
        });
      }
    }
  }

  public removeDocument(document: MultiUploadPlantDocument) {
    const index = this.presenter.documents.findIndex(
      el =>
        el.documentName === document.documentName &&
        el.plantId === document.plantId
    );
    if (index >= 0) {
      this.presenter.documents.splice(index, 1);
    }
  }

  public async uploadDocuments(documents: MultiUploadPlantDocument[]) {
    this.presenter.loading = true;
    for (const document of documents) {
      const request = this.plantService.uploadPlantDocument(
        document.plantId,
        false,
        {
          name: document.documentName,
          description: "",
          file: document.file
        }
      );
      await request.catch(reason => {
        this.presenter.error = reason.message || "Upload fehlgeschlagen";
        this.presenter.loading = false;
      });
    }

    this.presenter.loading = false;
    this.presenter.documents = [];
  }

  private isValidDocument(documentName: string) {
    return (
      /^\d+_.*\..*$/.test(documentName) || /^\d+ .*\..*$/.test(documentName)
    );
  }

  private getPlantId(documentName: string) {
    return /^\d+_.*\..*$/.test(documentName)
      ? documentName.split("_")[0]
      : documentName.split(" ")[0];
  }

  private getDocumentNameWithoutExtensionAndPlant(documentName: string) {
    return /^\d+_.*\..*$/.test(documentName)
      ? documentName.replace(/\.[^/.]+$/, "").replace(/^[^_]+_/, "")
      : documentName.replace(/\.[^/.]+$/, "").replace(/^[^ ]+ /, "");
  }
}

export interface IMultiUploadPresenter {
  loading: boolean;
  error: string;
  documents: MultiUploadPlantDocument[];
}
