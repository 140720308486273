import { IPlantContractPresenter } from "../controllers/PlantContractController";
import { PlantContractViewModel } from "../vms/PlantContractViewModel";
import { ContractForm } from "../forms/ContractForm";
import { FormResponse } from "@/forms/FormResponse";
import { PlantContract } from "../services/PlantService";
import { VpiStorageHandler } from "@/storage/storageHandlers/VpiStorageHandler";
import { SalesPartnerStorageHandler } from "@/storage/storageHandlers/SalesPartnerStorageHandler";

export class PlantContractPresenter implements IPlantContractPresenter {
  public contractForm: ContractForm;

  public constructor(private vm: PlantContractViewModel) {
    this.contractForm = new ContractForm(this.vm, this.onContractFormValidated);
    this.contractForm.init();

    this.initSelections();
  }

  public set visibleView(visible: boolean) {
    this.vm.visibleView = visible;
    if (!visible) {
      setTimeout(() => (this.vm.goBack = true), 300);
    }
  }

  public get contract(): PlantContract {
    return {
      hasContract: this.vm.hasContract.value,

      date: this.vm.date.value,
      doneBy: this.vm.doneBy.selected,
      maintenanceNumber: this.vm.maintenanceNumber.value,
      maintenanceCost: this.vm.maintenanceCost.value,
      priceSample: this.vm.priceSample.value,

      baseIndex: this.vm.baseIndex.selected,
      startIndex: this.vm.startIndex.value,
      currentIndex: this.vm.currentIndex.value,
      dueDate: this.vm.dueDate.value,
      firstAppointment: this.vm.firstAppointment.value,
      maintenanceInterval: this.vm.maintenanceInterval.value,

      contractQuitted: this.vm.contractQuitted.value,
      quitDate: this.vm.quitDate.value,
      remarks: this.vm.remarks.value
    };
  }

  public set existingContractResponse(response: FormResponse<PlantContract>) {
    // this.vm.createContractRequest = response;

    if (!response.loading && !response.error && !!response.data) {
      for (const key of Object.keys(response.data)) {
        const value = (response.data as any)[key];
        if (!!value) {
          this.contractForm.setFieldValue(key, value.toString());
        }
      }
    }
  }

  public set createContractResponse(response: FormResponse<string>) {
    this.vm.createContractRequest = response;

    if (!response.loading && !response.error) {
      this.vm.createdContract = response.data;
    }
  }

  private initSelections() {
    this.vm.baseIndex.items = VpiStorageHandler.getAllVpis();
    this.vm.doneBy.items = SalesPartnerStorageHandler.getAllSalesPartners();
  }

  private onContractFormValidated(context: any, valid: boolean) {
    context.createContractButtonDisabled = context.hasContract.value;
    if (context.hasContract.value) {
      if (context.contractQuitted.value) {
        context.createContractButtonDisabled = !(
          valid && context.quitDate.value
        );
      } else {
        context.createContractButtonDisabled = !valid;
      }
    }
  }
}
