import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import {
  GraphQLConnection,
  OrderByClause
} from "@/gateways/graphql/GraphQLConnection";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";

export class SupplierGraphQLService {
  public constructor(private connection: GraphQLConnection) {}

  public async getSupplierNames() {
    const request = await this.connection.query("suppliers", {}, [
      "id",
      "firstName",
      "lastName",
      "company"
    ]);

    return request.data.map((el: any) => ({
      id: el.id,
      name: el.company || `${el.lastName.toLocaleUpperCase()}, ${el.firstName}`
    }));
  }

  public async getSupplierOverview(
    page: Page,
    order: OrderByClause[],
    search: string
  ): Promise<PaginatedList<any>> {
    const request = await this.connection.queryPaginated(
      "getSupplierOverview",
      page.itemsPerPage,
      page.page,
      [
        "id",
        "index",
        "firstName",
        "lastName",
        "company",
        "street",
        "zip",
        "city",
        "country",
        {
          name: "phoneNumbers",
          fields: ["areaCode", "phoneNumber", "isStandard"]
        }
      ],
      search,
      [
        "index",
        "firstName",
        "lastName",
        "company",
        "street",
        "zip",
        "city",
        "country"
      ],
      order
    );

    return {
      items: request.data.map((s: any) => this.toFlatSupplier(s)),
      totalCount: request.count
    };
  }

  public async getSupplierById(id: string) {
    const request = await this.connection.query(
      "supplier",
      {
        id
      },
      [
        "id",
        "index",
        "salutation",
        "company",
        "firstName",
        "lastName",
        "street",
        "zip",
        "city",
        "country",
        "state",
        "email",
        "website",
        "uid",
        "targetPayment",
        "skontoPercent",
        "skontoDays",
        {
          name: "phoneNumbers",
          fields: ["areaCode", "phoneNumber", "isStandard"]
        }
      ]
    );

    return request.data;
  }

  public async updateSupplier(input: any) {
    const request = await this.connection.mutation(
      "updateSupplier",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async createSupplier(input: any) {
    const request = await this.connection.mutation(
      "createSupplier",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async deleteSupplier(id: string) {
    const request = await this.connection.mutation(
      "deleteSupplier",
      {
        id
      },
      []
    );

    return request.data;
  }

  public async getSupplierOrderById(id: string) {
    const request = await this.connection.query(
      "supplierOrder",
      {
        id
      },
      [
        "id",
        "date",
        "employeeId",
        "supplierId",
        {
          name: "items",
          fields: ["itemTemplateId", "amount"]
        }
      ]
    );

    return request.data;
  }

  public async createSupplierOrder(input: any) {
    const request = await this.connection.mutation(
      "createSupplierOrder",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async getAllSupplierOrdersPaginated(
    page: Page,
    order: OrderByClause[],
    search: string,
    supplierFilter: string[]
  ) {
    const request = await this.connection.queryPaginated(
      "supplierOrdersPaginated",
      page.itemsPerPage,
      page.page,
      [
        "id",
        "date",
        "supplierId",
        {
          name: "supplier",
          fields: ["firstName", "lastName", "company"]
        },
        "employeeId",
        {
          name: "employee",
          fields: ["firstname", "lastname"]
        },
        {
          name: "items",
          fields: ["itemTemplateId", "amount"]
        }
      ],
      search,
      ["store.name"],
      order,
      {
        supplierFilter
      }
    );

    return {
      items: request.data,
      totalCount: request.count
    };
  }

  public async getSupplierWithItems(id: string) {
    const request = await this.connection.query(
      "supplierWithItems",
      {
        id
      },
      [
        "id",
        "index",
        "salutation",
        "company",
        "firstName",
        "lastName",
        "street",
        "zip",
        "city",
        "country",
        "state",
        "email",
        "website",
        "uid",
        "targetPayment",
        "skontoPercent",
        "skontoDays",
        {
          name: "phoneNumbers",
          fields: ["areaCode", "phoneNumber", "isStandard"]
        },
        {
          name: "itemTemplates",
          fields: ["id", "name", "hk", "vk"]
        }
      ]
    );

    return request.data;
  }

  private toFlatSupplier(supplier: Supplier): any {
    const address = `${supplier.street}, ${supplier.zip} ${
      supplier.city
    }, ${CountriesStorageHandler.getCountryNameFromId(supplier.country)}`;

    const standardPhoneNumberIndex = supplier.phoneNumbers.findIndex(
      pn => pn.isStandard
    );
    let standardPhoneNumber = "";

    if (standardPhoneNumberIndex >= 0) {
      standardPhoneNumber = `${CountriesStorageHandler.getAreaCodeById(
        supplier.phoneNumbers[standardPhoneNumberIndex].areaCode
      )} ${supplier.phoneNumbers[standardPhoneNumberIndex].phoneNumber}`;
    }

    return {
      id: supplier.id,
      index: supplier.index,
      name: supplier.company
        ? supplier.company
        : `${supplier.lastName.toLocaleUpperCase()}, ${supplier.firstName}`,
      address,
      standardPhoneNumber
    };
  }
}

export interface Supplier {
  id: string;
  index: number;
  salutation: string;
  company: string;
  firstName: string;
  lastName: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  state: string;
  email: string;
  website: string;
  uid: string;
  targetPayment: string;
  skontoPercent: string;
  skontoDays: string;
  phoneNumbers: SupplierPhoneNumber[];
  deleted: boolean;
}

export interface SupplierPhoneNumber {
  areaCode: string;
  phoneNumber: string;
  isStandard: boolean;
  remarks: string;
}

export interface SupplierOrder {
  id: string;
  date: string;
  employeeId: string;
  supplierId: string;
  items: SupplierOrderItem[];
}

export interface SupplierOrderItem {
  itemTemplateId: string;
  amount: number;
}
