import {
  SystemTableListViewModel,
  SystemTableEntryViewDTO
} from "../vms/SystemTableListViewModel";
import { ISystemTableListPresenter } from "../controllers/SystemTableListController";
import { FormResponse } from "@/forms/FormResponse";
import {
  SystemTableStructure,
  SystemTableEntries
} from "../services/SystemTableService";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { InitPresenter } from "@/common/presenters/InitPresenter";
import { Dictionary } from "@/datastructures/Dictionary";
import { SystemTableUtils } from "../services/SystemTableUtils";
import { StringUtils } from "@/utils/StringUtils";
import { ArrayUtils } from "@/utils/ArrayUtils";

export class SystemTableListPresenter
  extends InitPresenter<SystemTableListViewModel>
  implements ISystemTableListPresenter {
  private crossReferences: Dictionary<{ labelKey: string; refs: any }> = {};

  public constructor(
    vm: SystemTableListViewModel,
    storage: SimpleStringStorage,
    private systemTableUtils: SystemTableUtils
  ) {
    super(vm, storage);
    this.initStaticTexts();
  }

  public set loadTableStructureResponse(
    response: FormResponse<SystemTableStructure>
  ) {
    this.vm.loadPageRequest = response;

    if (response.success) {
      this.vm.title = response.data.labelPlural;
      this.vm.addEntryButtonText = response.data.labelSingular + " erstellen";
      this.buildTableHeader(response.data.fields);
    }
  }

  public set loadTableEntriesResponse(
    response: FormResponse<SystemTableEntries>
  ) {
    this.vm.loadPageRequest = response;

    if (response.success) {
      this.loadEntries(response.data);
    }
  }

  public set addNewEntry(add: boolean) {
    this.vm.addNewEntry = add;
  }

  public set entryToEdit(entryId: string) {
    this.vm.entryToEdit = entryId;
  }

  private buildTableHeader(rawFields: string) {
    const fields = JSON.parse(rawFields);

    this.vm.headers = Object.entries(fields)
      .filter(([name, fieldData]: [string, any]) => fieldData.type !== "list")
      .map(([name, fieldData]: [string, any]) => {
        if (fieldData.type === "selection") {
          if (!ArrayUtils.isArray(fieldData.items)) {
            const key = this.systemTableUtils.findFirstValueKey(
              fieldData.items
            );
            this.crossReferences[name] = {
              labelKey: key,
              refs: this.systemTableUtils.loadSystemEntries(fieldData.items)
            };
          }
        }

        return {
          text: name,
          value: name,
          align: fieldData.type === "number" ? "center" : "left"
        };
      });

    this.vm.headers.push({
      text: "Aktionen",
      value: "actions",
      align: "right"
    });
  }

  private loadEntries(entries: SystemTableEntries) {
    this.vm.entries = entries.map(entry => {
      const convertedEntry: SystemTableEntryViewDTO = { id: entry.id };

      const valuesAsObject = JSON.parse(entry.values);
      for (const rawValue of Object.entries(valuesAsObject)) {
        const [key, value]: [string, any] = rawValue;
        const crossReference = this.crossReferences[key];

        if (!crossReference) {
          convertedEntry[key] = this.convertValue(value);
        } else {
          const reference = crossReference.refs.find(
            (ref: any) => ref.id === value
          );
          if (!!reference) {
            convertedEntry[key] = reference.values[crossReference.labelKey];
          } else {
            convertedEntry[key] = key + " konnte nicht zugeordnet werden";
          }
        }
      }

      return convertedEntry;
    });
  }

  private convertValue(value: any) {
    if (value === true) {
      value = "Ja";
    } else if (value === false) {
      value = "Nein";
    }

    if (StringUtils.isString(value)) {
      // tslint:disable-next-line: quotemark
      value = value.replace(/''/g, '"');
    }

    return value;
  }

  private initStaticTexts() {
    this.vm.editSystemEntryButtonText = "Eintrag bearbeiten";
  }
}
