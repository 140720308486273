import { Form, FormFieldType } from "@/forms/Form";

export class PlantOtherForm extends Form {
  public definition = {
    districtAuthority: { required: false, type: FormFieldType.Selection },
    daNumber: { required: false, type: FormFieldType.Text },
    remark: { required: false, type: FormFieldType.Text },
    operators: { required: false, type: FormFieldType.MultiSelect }
  };
}
