import { Report } from "@/storage/storageHandlers/ReportStorageHandler";

export class ReportTabViewModel {
  public cannotTakeSample = false;
  public inspectionOnly = false;
  public substituteDate = { value: "", text: "" };
  public report?: Report;

  public reportLoading = true;
  public reportError = "";
}
