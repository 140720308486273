import { FormFieldType } from "@/forms/Form";
import { Report } from "@/storage/storageHandlers/ReportStorageHandler";
import { DateUtils } from "@/utils/DateUtils";
import { IReportAccordionPresenter } from "../controllers/ReportAccordionController";
import { Field } from "../vms/ReportAccordionViewModel";
import {
  ReportAccordionViewModel,
  Section
} from "../vms/ReportAccordionViewModel";

export class ReportAccordionPresenter implements IReportAccordionPresenter {
  public constructor(private vm: ReportAccordionViewModel) {}

  public set report(report: Report) {
    this.vm.sections = [];

    for (const section of report.sections) {
      const vmSection: Section = {
        name: section.name,
        checkAvailability: section.checkAvailability,
        enabled: true,
        fields: {}
      };

      for (const field of section.fields) {
        const type = this.findType(field.type);

        const fieldToAdd: Field = {
          prefix: field.prefix,
          suffix: field.suffix,
          component: this.findComponentFromType(field.type),
          multiple: type === FormFieldType.MultiSelect,
          name: field.name,
          checkAvailability: field.checkAvailability,
          enabled: true
        };
        if (
          type === FormFieldType.MultiSelect ||
          type === FormFieldType.Selection
        ) {
          fieldToAdd.items = field.options.map((el: any) => ({
            text: el.name,
            value: el.name
          }));
        }
        vmSection.fields[field.name] = fieldToAdd;
      }

      this.vm.sections.push(vmSection);
    }
  }

  public set reportData(reportData: any) {
    if (reportData && reportData.sections && reportData.sections.length > 0) {
      for (const section of reportData.sections) {
        const vmSectionIndex = this.vm.sections.findIndex(
          s => s.name === section.name
        );
        if (vmSectionIndex > -1) {
          const vmSection = this.vm.sections[vmSectionIndex];
          vmSection.enabled = section.enabled ?? true;
          for (const field of section.fields) {
            if (vmSection.fields[field.name]) {
              const vmField = vmSection.fields[field.name];
              vmField.enabled = field.enabled ?? true;
              if (vmField.component === "ValidatedDatepicker") {
                if (field.value) {
                  vmField.value = {
                    text: field.value,
                    value: DateUtils.toISOString(field.value)
                  };
                } else {
                  vmField.value = {
                    text: "",
                    value: ""
                  };
                }
              } else if (vmField.component === "ValidatedMonthpicker") {
                if (field.value) {
                  vmField.value = {
                    text: field.value,
                    value: DateUtils.toISOStringMonthOnly(field.value)
                  };
                } else {
                  vmField.value = {
                    text: "",
                    value: ""
                  };
                }
              } else if (vmField.component === "ValidatedSwitch") {
                vmField.value = field.value === "true";
              } else {
                vmField.value = field.value;
              }
            }
          }
        }
      }
    }
  }

  private findType(type: string): FormFieldType {
    switch (type) {
      case "Text":
        return FormFieldType.Text;
      case "Checkbox":
        return FormFieldType.Checkbox;
      case "Multiselect":
        return FormFieldType.MultiSelect;
      case "Dropdown":
        return FormFieldType.Selection;
      case "Combobox":
        return FormFieldType.Selection;
      case "Date":
        return FormFieldType.Date;
      case "Month":
        return FormFieldType.Date;
      default:
        return FormFieldType.Text;
    }
  }

  private findComponentFromType(type: string): string {
    switch (type) {
      case "Text":
        return "ValidatedTextfield";
      case "Checkbox":
        return "ValidatedSwitch";
      case "Multiselect":
        return "ValidatedSelect";
      case "Dropdown":
        return "ValidatedSelect";
      case "Combobox":
        return "ValidatedCombobox";
      case "Date":
        return "ValidatedDatepicker";
      case "Month":
        return "ValidatedMonthpicker";
      default:
        return "ValidatedTextfield";
    }
  }
}
