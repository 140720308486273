














import Vue from "vue";
import Component from "vue-class-component";

@Component<DeleteDialog>({
  props: {
    title: {
      type: String,
      default: "Titel"
    },
    text: {
      type: String,
      default: ""
    },
    confirmText: {
      type: String,
      default: "Löschen"
    },
    denyText: {
      type: String,
      default: "Abbrechen"
    },
    value: {
      type: Boolean,
      default: false
    }
  }
})
export default class DeleteDialog extends Vue {
  private confirm() {
    this.$emit("confirm");
  }

  private deny() {
    this.$emit("deny");
  }
}
