import { LoginViewModel } from "../vms/LoginViewModel";
import { ILoginPresenter } from "../controllers/LoginController";
import { LoginForm } from "../forms/LoginForm";
import { FormResponse } from "@/forms/FormResponse";
import { LoginPayload } from "../interactors/AuthenticationService";

export class LoginPresenter implements ILoginPresenter {
  public loginForm: LoginForm;

  public constructor(private vm: LoginViewModel) {
    this.loginForm = new LoginForm(this.vm, this.loginFormValidated);
    this.loginForm.init();
  }

  public set loginResponse(response: FormResponse<LoginPayload>) {
    this.vm.loginButtonLoading = response.loading;
    this.vm.loginButtonError = response.error;
    this.vm.loggedIn = response.success;
  }

  private loginFormValidated(context: any, valid: boolean) {
    context.loginButtonDisabled = !valid;
  }
}
