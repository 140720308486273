import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { OperatorGraphQLService } from "../services/OperatorGraphQLService";
import { StringUtils } from "@/utils/StringUtils";

export class BmdOperatorExport {
  public static async export() {
    const exporter = new BmdOperatorExport();
    const exportedPlants = await exporter.getExportedPlants();
    exporter.downloadFile(exportedPlants);

    return {};
  }

  public operatorService: OperatorGraphQLService;

  public constructor() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    this.operatorService = new OperatorGraphQLService(connection);
  }

  private async getExportedPlants(): Promise<string> {
    return await this.operatorService.exportOperators();
  }

  private downloadFile(exportedPlants: string) {
    const link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=ISO-8859-2," +
        StringUtils.escape_iso_8859_2(exportedPlants)
    );
    link.setAttribute("download", "BMD_KUND.csv");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
