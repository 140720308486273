



















import Vue from "vue";
import Component from "vue-class-component";
import { ValidationProvider } from "vee-validate";

@Component<ValidatedSwitch>({
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    vid: {
      type: String,
      default: undefined
    },
    value: {
      type: Boolean,
      default: false
    },
    noTopPadding: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  }
})
export default class ValidatedSwitch extends Vue {
  private innerValue = false;

  private created() {
    if (this.$props.value) {
      this.innerValue = this.$props.value;
    }
  }
}
