








































































import Vue from "vue";
import Component from "vue-class-component";

import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import RequestButton from "@/components/basic/RequestButton.vue";

import Textfield from "@/components/form/Textfield.vue";
import Select from "@/components/form/Select.vue";
import { Numberfield, Selection } from "@/forms/ViewModelFormTypes";

import { VpiCalculationForm as Form } from "./VpiCalculationForm";

import { VpiStorageHandler } from "@/storage/storageHandlers/VpiStorageHandler";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";

export interface VpiCalculationFormData {
  threshold?: number;
  baseIndex?: string;
  targetIndex?: number;
  country?: string;
  state?: string;
}

@Component<VpiCalculationForm>({
  components: {
    Grid,
    Row,
    Column,
    Textfield,
    Select,
    RequestButton
  },
  props: {
    dense: Boolean
  }
})
export default class VpiCalculationForm extends Vue {
  protected form = new Form(this, this.onInput);
  protected valid = false;
  protected dense!: boolean;

  protected threshold: Numberfield = {
    label: "Schwellenwert (%) *",
    value: 0,
    error: ""
  };

  protected baseIndex: Selection = {
    label: "Basisindex *",
    selected: "",
    loading: false,
    error: "",
    items: VpiStorageHandler.getAllVpis()
  };

  protected targetIndex: Numberfield = {
    label: "Neuer Index *",
    value: 0,
    error: ""
  };

  protected country: Selection = {
    label: "Land",
    selected: "",
    loading: false,
    error: "",
    items: CountriesStorageHandler.getAllCountries()
  };

  protected state: Selection = {
    label: "Bundesland",
    selected: "",
    loading: false,
    error: "",
    items: []
  };

  protected setFieldValue(fieldName: string, value: string) {
    this.form.setFieldValue(fieldName, value);

    if (this.dense) {
      this.submit();
    }
  }

  protected created() {
    this.init();
  }

  protected init() {
    this.form.init();
    this.setFieldValue("threshold", "5");
    this.setFieldValue("country", "austria");
  }

  protected submit() {
    this.$emit("submit", {
      threshold: this.threshold.value,
      baseIndex: this.baseIndex.selected,
      targetIndex: this.targetIndex.value,
      country: this.country.selected,
      state: this.state.selected
    });
  }

  protected onInput(_: VpiCalculationForm, valid: boolean) {
    this.valid = valid;
    this.state.items = CountriesStorageHandler.getStatesFromCountry(
      this.country.selected
    );
  }
}
