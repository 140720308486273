























import Vue from "vue";
import Component from "vue-class-component";

@Component<DatePicker>({
  inheritAttrs: false,
  props: {
    value: {
      type: undefined,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    label: {
      type: String,
      default() {
        return this.$t("date");
      }
    },
    icon: {
      type: String,
      default: "event"
    }
  }
})
export default class DatePicker extends Vue {
  private expanded = false;

  private chooseDate(date: string) {
    this.expanded = false;
    this.emitInput(date);
  }

  private emitInput(date: string) {
    this.$emit("input", date);
    this.$emit("change", date);
  }
}
