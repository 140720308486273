import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { OrderByClause, SortOrder } from "@/gateways/graphql/GraphQLConnection";
import { DeliveryWayStorageHandler } from "@/storage/storageHandlers/DeliveryWayStorageHandler";
import { DateUtils } from "@/utils/DateUtils";
import { IStoreMovementService } from "../services/StoreMovementService";

export class StoreItemCardController {
  private plantId = "";
  private deliveryWays = new Map();

  public constructor(
    private presenter: IStoreItemCardPresenter,
    private storeMovementService: IStoreMovementService
  ) {
    const deliveryWaySelectionItems = DeliveryWayStorageHandler.getAllDeliveryWays();
    for (const selectionItem of deliveryWaySelectionItems) {
      this.deliveryWays.set(selectionItem.value, selectionItem.text);
    }
  }

  public mounted(plantId: string) {
    this.plantId = plantId;

    this.getMovements();
  }

  public tableSorted(options: any) {
    this.presenter.options = options;

    this.getMovements();
  }

  public pageChanged(newPageNumber: number) {
    this.presenter.pageNumber = newPageNumber;

    this.getMovements();
  }

  public parseDeliveryWay(deliveryWay: string) {
    return this.deliveryWays.get(deliveryWay);
  }

  public parseDate(date: string) {
    return DateUtils.format(date);
  }

  public parseStore(storeMovement: any) {
    return storeMovement.destinationType === "PLANT"
      ? `Anlage Nr. ${storeMovement.storeId}`
      : storeMovement.store?.name || "";
  }

  private getMovements() {
    const order: OrderByClause[] = [];
    for (let i = 0; i < this.presenter.options.sortBy.length; i++) {
      order.push({
        field: this.presenter.options.sortBy[i],
        order: this.presenter.options.sortDesc[i]
          ? SortOrder.DESC
          : SortOrder.ASC
      });
    }

    const page = new Page(
      this.presenter.itemsPerPage,
      this.presenter.pageNumber
    );

    FormRequestHandler.handle(
      this.storeMovementService.getStoreMovementsForOverviewPaginated(
        page,
        order,
        "",
        [this.plantId],
        [],
        true
      ),
      response => (this.presenter.tableRequest = response),
      "Konnte Tabelle nicht laden!"
    );
  }
}

export interface IStoreItemCardPresenter {
  tableRequest: FormResponse<PaginatedList<any>>;
  pageNumber: number;
  itemsPerPage: number;
  options: any;
}
