export interface PlantSize {
  name: string;
  id: string;
  value: string;
  suffix: string;
}

export class PlantSizesAccordionViewModel {
  public plantSizes: PlantSize[] = [];
}
