
























































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import ServerDatatable from "@/components/collection/ServerDatatable.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import { StockTakingOverviewViewModel } from "@/store/vms/StockTakingOverviewViewModel";
import { StockTakingOverviewPresenter } from "@/store/presenters/StockTakingOverviewPresenter";
import { StockTakingOverviewController } from "@/store/controllers/StockTakingOverviewController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { StockTakingGraphQLService } from "../services/StockTakingGraphQLService";
import { StoreService } from "../services/StoreService";

@Component<StockTakingOverviewView>({
  components: {
    Page,
    Section,
    ServerDatatable,
    Card,
    Container,
    Column,
    Row
  }
})
export default class StockTakingOverviewView extends Vue {
  private vm = new StockTakingOverviewViewModel();
  private controller?: StockTakingOverviewController;

  private initController(): StockTakingOverviewController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new StockTakingOverviewController(
      new StockTakingOverviewPresenter(this.vm),
      new StockTakingGraphQLService(connection),
      new StoreService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }

  private openStockTaking(item: any) {
    this.$router.push({
      name: "stock-taking",
      params: { stockTakingId: item.id }
    });
  }
}
