import { Form, FormFieldType } from "@/forms/Form";
import regex from "@/data/regex";

export class LoginForm extends Form {
  protected definition = {
    username: { required: true, type: FormFieldType.Text },
    password: {
      required: true,
      type: FormFieldType.Text,
      regex: regex.mediumPassword,
      error: "Mind. 8 Zeichen, davon eine Zahl, Klein- und Großbuchstabe"
    }
  };
}
