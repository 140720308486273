
















































































import Vue from "vue";
import Component from "vue-class-component";

import Container from "@/components/layout/Container.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import Accordion from "@/components/layout/Accordion.vue";

import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";
import ValidatedCombobox from "@/components/form/vee-validate/ValidatedCombobox.vue";
import ValidatedSwitch from "@/components/form/vee-validate/ValidatedSwitch.vue";

import { ParametersAccordionViewModel } from "@/report/vms/ParametersAccordionViewModel";
import { ParametersAccordionPresenter } from "@/report/presenters/ParametersAccordionPresenter";
import { ParametersAccordionController } from "@/report/controllers/ParametersAccordionController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { WorkOrderService } from "@/report/services/WorkOrderService";

@Component<ParametersAccordion>({
  components: {
    Container,
    Column,
    Row,

    Accordion,
    ValidatedTextfield,
    ValidatedSelect,
    ValidatedCombobox,
    ValidatedSwitch
  },
  props: {
    plantId: String,
    editEnabled: Boolean,
    parameters: Object,
    title: {
      type: String,
      default: "Parameter"
    },
    toggleable: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: true
    },
    shown: Boolean
  }
})
export default class ParametersAccordion extends Vue {
  public vm = new ParametersAccordionViewModel();
  private controller?: ParametersAccordionController;

  private initController(): ParametersAccordionController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new ParametersAccordionController(
      new ParametersAccordionPresenter(this.vm),
      new WorkOrderService(connection),
      this.$props.plantId
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$props.parameters);
  }
}
