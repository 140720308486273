import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { MaterialRequestItem } from "../services/MaterialRequestGraphQLService";

export class CreateMaterialRequestViewModel {
  public readonly = false;
  public visibleView = false;

  public loading = false;
  public error = "";

  public materialRequestItems: MaterialRequestItem[] = [];

  public itemTemplates: {
    selected: string;
    items: SelectionItem[];
  } = {
    selected: "",
    items: []
  };
}
