var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RightHandCard',{staticClass:"ma-2",attrs:{"title":"Wochenplanung","color":"#4f4f4f","loading":_vm.vm.loading}},[(_vm.controller.canEdit() || _vm.controller.canApprove())?_c('Row',{attrs:{"align-center":""}},[_c('Column',{attrs:{"xs12":"","md12":"","lg5":"","mt-2":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("person")]),_c('b',{staticClass:"font-weight-bold"},[_vm._v("TECHNIKER:")])],1),_c('Column',{attrs:{"xs12":"","md12":"","lg7":""}},[_c('v-select',{staticClass:"select",attrs:{"menu-props":"auto","dense":"","hide-details":"","color":"primary","placeholder":"Techniker","item-color":"primary","items":_vm.vm.serviceEngineersViewList.items,"value":_vm.vm.serviceEngineersViewList.selected,"disabled":_vm.vm.initialTourWeekLoading},on:{"change":function($event){return _vm.$emit('serviceEngineerChanged', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text overflow-selection"},[_vm._v(_vm._s(item.text))])]}},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("keyboard_arrow_down")])]},proxy:true}],null,false,747890066)})],1)],1):_vm._e(),_c('Row',{attrs:{"mb-4":"","align-center":""}},[_c('Column',{attrs:{"xs12":"","md12":"","lg5":"","mt-2":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("date_range")]),_c('b',{staticClass:"font-weight-bold"},[_vm._v("KALENDERWOCHE:")])],1),_c('Column',{attrs:{"xs12":"","md12":"","lg7":""}},[_c('v-select',{staticClass:"select",attrs:{"menu-props":"auto","dense":"","hide-details":"","placeholder":"Kalenderwoche","value":_vm.vm.timeFrameItems.selected,"items":_vm.vm.timeFrameItems.items,"disabled":_vm.vm.initialTourWeekLoading},on:{"change":function($event){return _vm.$emit('weekChanged', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"primary--text overflow-selection"},[_vm._v(_vm._s(item.text))])]}},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("keyboard_arrow_down")])]},proxy:true}])})],1)],1),(_vm.controller.canEdit() || _vm.controller.canApprove())?_c('Row',{attrs:{"mt-8":"","align-center":""}},[_c('Column',{attrs:{"grow":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v("dns")]),_c('b',{staticClass:"font-weight-bold",staticStyle:{"vertical-align":"middle"}},[_vm._v("NOCH NICHT ZUGEORDNET")])],1),_c('Column',{attrs:{"shrink":""}},[(_vm.controller.canEdit())?_c('v-btn',{attrs:{"disabled":_vm.vm.viewRouteMode || _vm.vm.initialTourWeekLoading,"icon":"","color":"primary"},on:{"click":function($event){return _vm.goToCreateLooseAppointment()}}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1)],1):_vm._e(),(_vm.controller.canEdit() || _vm.controller.canApprove())?_c('Row',{attrs:{"mb-4":"","mt-1":""}},[_c('Draggable',{staticClass:"list-group list-group-selection",attrs:{"list":_vm.vm.selectedAppointments,"group":"selection","disabled":_vm.vm.viewRouteMode || _vm.vm.initialTourWeekLoading},on:{"change":function($event){return _vm.controller.selectionChanged($event)}}},_vm._l((_vm.vm.selectedAppointments),function(appointment,index){return _c('div',{key:("a-" + index),staticClass:"list-group-item list-group-selection-item",style:({
          lineHeight: '1 !important',
          cursor: _vm.vm.viewRouteMode ? 'not-allowed' : 'move'
        })},[_c('div',{staticClass:"centered-vertical-absolute",staticStyle:{"height":"24px"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("drag_indicator")])],1),_c('div',{staticStyle:{"margin":"0 30px"}},[(appointment.type == 'LOOSE' || !appointment.plant)?_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"line-height":"1.2 !important"}},[_vm._v(" Termin ("+_vm._s(_vm.controller.formatDate(appointment.date))+") ")]):_c('div',[_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"line-height":"1.2 !important"}},[_vm._v(" Wartung ("+_vm._s(_vm.controller.formatDate(appointment.date))+") bei Anlage Nr. "+_vm._s(appointment.plant.index)+" ")]),_vm._l((appointment.plant.operators),function(operator){return _c('div',{key:("o-" + (operator.id)),staticClass:"body-2"},[_vm._v(" "+_vm._s(operator.name)+" "),_c('br')])})],2),_c('font',[_vm._v(" "+_vm._s(appointment.addressStreet)+", "),_c('br'),_vm._v(" "+_vm._s(appointment.addressZip)+" "+_vm._s(appointment.addressCity)+", "),_c('br'),_vm._v(" "+_vm._s(appointment.addressCountry)+" ")])],1),_c('div',{staticClass:"centered-vertical-absolute",staticStyle:{"height":"49px","right":"5px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){return _vm.controller.showAppointmentInfo(appointment)}}},on),[_c('v-icon',[_vm._v("info")])],1)]}}],null,true)},[_c('span',[(!!appointment.plant)?_c('div',[_c('font',{staticClass:"font-weight-bold"},[_vm._v("Anlage Nr. "+_vm._s(appointment.plant.index))]),_c('br'),_vm._v(" "+_vm._s(appointment.plant.group)+" / "+_vm._s(appointment.plant.type)+" "),_c('br'),_c('br'),_c('font',{staticClass:"font-weight-bold"},[_vm._v("Betreiber:")]),_c('br'),_vm._l((appointment.plant.operators),function(operator){return _c('div',{key:("o-" + (operator.id))},[_vm._v(" "+_vm._s(operator.name)+" "),_c('br')])}),_c('br')],2):_vm._e(),_c('font',{staticClass:"font-weight-bold"},[_vm._v("Adresse:")]),_c('br'),_c('font',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.controller.parseAddress(appointment)))]),_c('br'),_c('br'),_c('font',{staticClass:"font-weight-bold"},[_vm._v("Wartung fällig:")]),_vm._v(" "+_vm._s(_vm.controller.formatDate(appointment.date))+" ")],1)]),(!_vm.vm.viewRouteMode)?_c('v-btn',{staticStyle:{"display":"table","width":"21px","height":"21px","margin-left":"4px"},attrs:{"fab":"","depressed":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.controller.removeAppointmentFromSelection(appointment, index)}}},[_c('v-icon',{attrs:{"size":"x-small"}},[_vm._v("delete")])],1):_vm._e()],1)])}),0)],1):_vm._e(),(_vm.controller.canEdit() || _vm.controller.canApprove())?_c('Row',{attrs:{"my-4":""}},[_c('v-btn',{staticClass:"row-button",attrs:{"outlined":"","tile":"","color":"primary","loading":_vm.vm.finishedRouteRequests < 5,"disabled":!_vm.vm.tourWeek.id},on:{"click":function($event){_vm.vm.viewRouteMode
          ? _vm.controller.enableEditCalendar()
          : _vm.controller.calculateRoutes()}}},[_c('v-icon',[_vm._v("navigation")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.vm.viewRouteMode ? "TERMINE VERSCHIEBEN" : "ROUTE BERECHNEN"))])],1)],1):_vm._e(),(_vm.controller.canEdit())?_c('Row',{attrs:{"my-4":""}},[_c('v-btn',{staticClass:"row-button text-none",attrs:{"disabled":_vm.vm.finishWeekButtonDisabled ||
          !_vm.vm.viewRouteMode ||
          _vm.vm.tourWeek.tourDays.some(function (td) { return !!td.error; }) ||
          !!_vm.vm.error ||
          _vm.vm.initialTourWeekLoading,"tile":"","color":"primary"},on:{"click":function($event){return _vm.controller.setNotValidatedTourWeekButtonClicked(_vm.vm.tourWeek.id)}}},[_c('div',{staticClass:"font-weight-bold pt-2",staticStyle:{"float":"center"}},[_vm._v(" Tourenplanung abschließen "),_c('br'),_c('div',{staticClass:"caption font-weight-light",staticStyle:{"color":"lightgray","line-height":"null","letter-spacing":"0em !important"}},[_vm._v(" Zur Bestätigung an Büro senden. ")])])])],1):_vm._e(),(_vm.controller.canApprove())?_c('Row',{attrs:{"my-4":""}},[_c('v-btn',{staticClass:"row-button text-none",attrs:{"disabled":_vm.vm.generateWorkOrdersButtonDisabled ||
          !_vm.vm.viewRouteMode ||
          _vm.vm.tourWeek.tourDays.some(function (td) { return !!td.error; }) ||
          !!_vm.vm.error ||
          _vm.vm.initialTourWeekLoading,"tile":"","color":"primary"},on:{"click":function($event){return _vm.controller.finishTourWeekButtonClicked(_vm.vm.tourWeek.id)}}},[_c('div',{staticClass:"font-weight-bold pt-2",staticStyle:{"float":"center"}},[_vm._v(" Arbeitsaufträge generieren "),_c('br'),_c('div',{staticClass:"caption font-weight-light",staticStyle:{"color":"lightgray","line-height":"null","letter-spacing":"0em !important"}},[_vm._v(" Die Arbeitsaufträge werden für den Mitarbeiter generiert. ")])])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }