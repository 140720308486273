
















































































































































































































import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Grid from "@/components/layout/Grid.vue";
import Container from "@/components/layout/Container.vue";
import KeyValue from "@/plant/views/KeyValue.vue";
import IconButton from "@/components/basic/IconButton.vue";
import Textarea from "@/components/form/Textarea.vue";

import RequestButton from "@/components/basic/RequestButton.vue";

import PlantSurroundingMap from "@/plant/views/PlantSurroundingMap.vue";

import { PlantService } from "@/plant/services/PlantService";
import { PlantDataViewModel } from "@/plant/vms/PlantDataViewModel";
import { PlantDataPresenter } from "@/plant/presenters/PlantDataPresenter";
import { PlantDataController } from "@/plant/controllers/PlantDataController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { OnlineCheckerFactory } from "../../common/utils/OnlineCheckerFactory";

@Component<PlantDataCard>({
  components: {
    Container,
    Column,
    Row,
    Grid,
    KeyValue,
    IconButton,
    RequestButton,
    PlantSurroundingMap,
    Textarea
  },
  props: {
    plantId: {
      type: String,
      required: true
    },
    showNumber: {
      type: Boolean,
      default: false
    },
    showDistrictAuthority: {
      type: Boolean,
      default: false
    },
    showAddress: {
      type: Boolean,
      default: false
    },
    showContactPersons: {
      type: Boolean,
      default: false
    },
    showAdditionalInfo: {
      type: Boolean,
      default: false
    },
    showPlantParts: {
      type: Boolean,
      default: false
    },
    showParameters: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    "vm.plant"(plant: any) {
      this.emitLoad(plant);
    }
  }
})
export default class PlantDataCard extends Vue {
  private vm = new PlantDataViewModel();

  private plantId!: string;

  private get controller(): PlantDataController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new PlantDataController(
      new PlantDataPresenter(this.vm),
      new PlantService(connection)
    );
  }

  private get isOnline(): boolean {
    return this.$store.state.isOnline;
  }

  public goToSeePlant() {
    this.$router.push({
      name: "plant",
      params: { plantId: this.plantId }
    });
  }

  private mounted() {
    this.controller.mounted(this.plantId);
  }

  private emitLoad(plant: any) {
    this.$emit("load", plant);
  }
}
