import { GraphQLConnection } from "@/gateways/graphql/GraphQLConnection";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";

export class ServiceEngineerGraphQLService implements IServiceEngineerService {
  public static get serviceEngineerFields() {
    return [
      "id",
      "firstName",
      "lastName",
      "startTimes",
      "startLocationStreet",
      "startLocationZip",
      "startLocationCity",
      "startLocationCountry",
      "endLocationStreet",
      "endLocationZip",
      "endLocationCity",
      "endLocationCountry"
    ];
  }

  public constructor(private connection: GraphQLConnection) {}

  public async getServiceEngineers(): Promise<ServiceEngineer[]> {
    const response = await this.connection.query(
      "getServiceEngineers",
      {},
      ServiceEngineerGraphQLService.serviceEngineerFields
    );
    return response.data.map((serviceEngineerRaw: any) =>
      this.parseServiceEngineer(serviceEngineerRaw)
    );
  }

  public async getServiceEngineerById(id: string): Promise<ServiceEngineer> {
    const response = await this.connection.query(
      "getServiceEngineerById",
      {
        id
      },
      ServiceEngineerGraphQLService.serviceEngineerFields
    );
    return this.parseServiceEngineer(response.data);
  }

  public async updateTourData(input: TourDataInput): Promise<string> {
    const response = await this.connection.mutation(
      "updateTourData",
      {
        input
      },
      []
    );
    return response.data;
  }

  private parseServiceEngineer(serviceEngineerRaw: any): ServiceEngineer {
    const serviceEngineer: ServiceEngineer = serviceEngineerRaw;
    // serviceEngineer.startLocationCountry = CountriesStorageHandler.getCountryNameFromId(
    //   serviceEngineerRaw.startLocationCountry
    // );
    // serviceEngineer.endLocationCountry = CountriesStorageHandler.getCountryNameFromId(
    //   serviceEngineerRaw.endLocationCountry
    // );

    return serviceEngineer;
  }
}

export interface ServiceEngineer {
  id: string;
  firstName: string;
  lastName: string;
  startTimes: string[];
  startLocationStreet: string;
  startLocationZip: string;
  startLocationCity: string;
  startLocationCountry: string;
  endLocationStreet: string;
  endLocationZip: string;
  endLocationCity: string;
  endLocationCountry: string;
}

export interface TourDataInput {
  id: string;
  startTimes: string[];
  startLocationStreet: string;
  startLocationZip: string;
  startLocationCity: string;
  startLocationCountry: string;
  endLocationStreet: string;
  endLocationZip: string;
  endLocationCity: string;
  endLocationCountry: string;
}

export interface IServiceEngineerService {
  getServiceEngineerById(id: string): Promise<ServiceEngineer>;
  getServiceEngineers(): Promise<ServiceEngineer[]>;
  updateTourData(input: TourDataInput): Promise<string>;
}
