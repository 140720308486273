import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import {
  Employee,
  EmployeeGraphQLService
} from "../services/EmployeeGraphQLService";
import { Store } from "../services/StoreGraphQLService";
import { StoreService } from "../services/StoreService";

export class CreateStoreController {
  private storeId?: string;

  public constructor(
    private presenter: ICreateStorePresenter,
    private storeService: StoreService,
    private employeeService: EmployeeGraphQLService
  ) {}

  public async mounted(storeId?: string) {
    FormRequestHandler.handle(
      this.employeeService.getAllEmployees(),
      response => (this.presenter.getEmployeesResponse = response),
      "Konnte Mitarbeiter nicht laden!"
    );

    if (storeId) {
      this.storeId = storeId;

      FormRequestHandler.handle(
        this.storeService.getStoreById(storeId),
        response => {
          this.presenter.getStoreResponse = response;

          if (!response.loading && !response.error) {
            this.presenter.visibleView = true;
          }
        },
        "Konnte Lagerort nicht laden!"
      );
    } else {
      this.presenter.visibleView = true;
    }
  }

  public closeOverlay() {
    this.presenter.visibleView = false;
  }

  public createStoreButtonClicked() {
    if (this.storeId) {
      const data = { id: this.storeId, ...this.presenter.data };

      FormRequestHandler.handle(
        this.storeService.updateStore(data),
        response => (this.presenter.createStoreResponse = response),
        "Could not update store!"
      );
    } else {
      FormRequestHandler.handle(
        this.storeService.createStore(this.presenter.data),
        response => (this.presenter.createStoreResponse = response),
        "Could not create store!"
      );
    }
  }
}

export interface ICreateStorePresenter {
  visibleView: boolean;

  getStoreResponse: FormResponse<Store>;
  getEmployeesResponse: FormResponse<Employee[]>;

  data: any;
  createStoreResponse: FormResponse<string>;
}
