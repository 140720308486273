import { AddressForm } from "@/forms/common/AddressForm";
import { PersonDataForm } from "@/forms/person/PersonDataForm";
import { PhoneDataForm } from "@/forms/person/PhoneDataForm";
import { OperatorDataForm } from "@/forms/operator/OperatorDataForm";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { IOperatorService, Operator } from "../services/OperatorGraphQLService";

export class CreateOperatorController {
  public constructor(
    private presenter: ICreateOperatorPresenter,
    private operatorService: IOperatorService
  ) {}

  public async mounted(operatorId: string) {
    if (!!operatorId) {
      const operator = await this.getOperatorById(operatorId);
      this.presenter.operator = operator;
    }
    this.presenter.visibleView = true;
  }
  public closeButtonClicked(): void {
    this.presenter.visibleView = false;
  }

  public completePersonDataStepButtonClicked(): void {
    this.presenter.personDataStepCompleted = true;
  }

  public completeAddressDataStepButtonClicked(): void {
    this.presenter.addressDataStepCompleted = true;
  }

  public completePhoneDataStepButtonClicked(): void {
    this.presenter.phoneDataStepCompleted = true;
  }

  public completeOperatorDataStepButtonClicked(): void {
    this.presenter.operatorDataStepCompleted = true;
  }

  public degreeSelected(degree: string): void {
    this.presenter.personDataForm.setFieldValue("degrees", degree);
  }

  public salutationSelected(salutation: string): void {
    this.presenter.personDataForm.setFieldValue("salutation", salutation);
  }

  public firstNameChanged(firstName: string): void {
    this.presenter.personDataForm.setFieldValue("firstName", firstName);
  }

  public lastNameChanged(lastName: string): void {
    this.presenter.personDataForm.setFieldValue("lastName", lastName);
  }

  public companyChanged(company: string): void {
    this.presenter.personDataForm.setFieldValue("company", company);
  }

  public mailChanged(mail: string): void {
    this.presenter.personDataForm.setFieldValue("mail", mail);
  }

  public websiteChanged(website: string): void {
    this.presenter.personDataForm.setFieldValue("website", website);
  }

  public addressRemarksTextChanged(newText: string): void {
    this.presenter.addressForm.setFieldValue("addressRemarks", newText);
  }

  public streetTextChanged(newText: string): void {
    this.presenter.addressForm.setFieldValue("street", newText);
  }

  public zipTextChanged(newText: string): void {
    this.presenter.addressForm.setFieldValue("zip", newText);
  }

  public cityTextChanged(newText: string): void {
    this.presenter.addressForm.setFieldValue("city", newText);
  }

  public countrySelected(country: string) {
    this.presenter.addressForm.setFieldValue("country", country);
  }

  public stateSelected(state: string) {
    this.presenter.addressForm.setFieldValue("state", state);
  }

  public closeAddressDataStepButtonClicked() {
    this.presenter.personDataStepCompleted = false;
  }

  public addPhoneDataRowButtonClicked() {
    this.presenter.addPhoneDataSubForm();
  }

  public removePhoneDataRowButtonClicked(index: number) {
    this.presenter.removePhoneDataSubForm(index);
  }

  public areaCodeSelected(areaCode: string, index: number) {
    this.presenter.phoneDataForm.subforms[index].setFieldValue(
      "areaCode",
      areaCode
    );
  }

  public phoneNumberChanged(phoneNumber: string, index: number) {
    this.presenter.phoneDataForm.subforms[index].setFieldValue(
      "phoneNumber",
      phoneNumber
    );
  }

  public phoneAdditionChanged(phoneAddition: string, index: number) {
    this.presenter.phoneDataForm.subforms[index].setFieldValue(
      "phoneAddition",
      phoneAddition
    );
  }

  public standardChanged(checked: boolean, index: number) {
    if (checked) {
      this.presenter.phoneDataForm.subforms.forEach((element, i) => {
        if (i === index) {
          element.setFieldValue("isStandard", "true");
        } else {
          element.setFieldValue("isStandard", "false");
        }
      });
    } else {
      this.presenter.phoneDataForm.subforms[index].setFieldValue(
        "isStandard",
        "false"
      );
    }
    this.presenter.standardChanged(checked, index);
  }

  public closePhoneDataStepButtonClicked() {
    this.presenter.addressDataStepCompleted = false;
  }

  public operatorSelected(plant: string) {
    this.presenter.operatorDataForm.setFieldValue("plants", plant);
  }

  public vulgoNameChanged(vulgoName: string) {
    this.presenter.operatorDataForm.setFieldValue("vulgoName", vulgoName);
  }

  public uidChanged(uid: string) {
    this.presenter.operatorDataForm.setFieldValue("uid", uid);
  }

  public dateOfPaymentChanged(dateOfPayment: string) {
    this.presenter.operatorDataForm.setFieldValue(
      "dateOfPayment",
      dateOfPayment
    );
  }

  public cashBackPercentChanged(cashBackPercent: string) {
    this.presenter.operatorDataForm.setFieldValue(
      "cashBackPercent",
      cashBackPercent
    );
  }

  public cashBackDaysChanged(cashBackDays: string) {
    this.presenter.operatorDataForm.setFieldValue("cashBackDays", cashBackDays);
  }

  public onPlantsSelected(plant: any) {
    this.presenter.operatorDataForm.setFieldValue("plants", plant.item.id);
  }

  public onAllPlantsSelected(plants: any[]) {
    for (const plant of plants) {
      this.presenter.operatorDataForm.setFieldValue("plants", plant.id);
    }
  }

  public closeOperatorDataStepButtonClicked() {
    this.presenter.phoneDataStepCompleted = false;
  }

  public createOperatorButtonClicked() {
    const request = this.operatorService.createOperator(
      this.presenter.operator
    );

    FormRequestHandler.handle(
      request,
      response => (this.presenter.createOperatorResponse = response),
      "create-operator-failed"
    );
  }

  private async getOperatorById(operatorId: string) {
    return await this.operatorService.getOperatorById(operatorId);
  }
}

export interface ICreateOperatorPresenter {
  visibleView: boolean;
  personDataStepCompleted: boolean;
  addressDataStepCompleted: boolean;
  phoneDataStepCompleted: boolean;
  operatorDataStepCompleted: boolean;

  personDataForm: PersonDataForm;
  addressForm: AddressForm;
  phoneDataForm: PhoneDataForm;
  operatorDataForm: OperatorDataForm;

  operator: Operator;
  createOperatorResponse: FormResponse<string>;

  addPhoneDataSubForm(): void;
  removePhoneDataSubForm(index: number): void;
  standardChanged(checked: boolean, index: number): void;
}
