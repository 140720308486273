





















































































import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import { DateUtils } from "../../../utils/DateUtils";

@Component<WorkOrderPriceTab>({
  components: {
    Row,
    Column,
    Container
  },
  props: ["parentController", "parentVm"]
})
export default class WorkOrderPriceTab extends Vue {
  private parseDate(date: string) {
    return DateUtils.formatDate(date);
  }
}
