import { IDefectsTablePresenter } from "../controllers/DefectsTableController";
import {
  DefectsTableViewModel,
  DefectData
} from "../vms/DefectsTableViewModel";
import { DefectsStorageHandler } from "@/storage/storageHandlers/DefectsStorageHandler";
import { Selection } from "@/forms/ViewModelFormTypes";

export class DefectsTablePresenter implements IDefectsTablePresenter {
  public constructor(private vm: DefectsTableViewModel) {
    this.vm.newDefect.items = DefectsStorageHandler.getAllDefects();
  }

  public set defects(defects: DefectData[]) {
    this.vm.defects = defects;
  }

  public get defects(): DefectData[] {
    return this.vm.defects;
  }

  public set newDefect(newDefect: Selection) {
    this.vm.newDefect = newDefect;
  }

  public get newDefect(): Selection {
    return this.vm.newDefect;
  }
}
