








































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationProvider } from "vee-validate";

import { DateUtils } from "@/utils/DateUtils";

@Component<ValidatedMonthpicker>({
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: Object
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    icon: {
      type: String,
      default: "event"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    extraDense: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(newVal) {
      this.innerValue = newVal.value;
      this.parsedValue = newVal.text;
    },
    innerValue(newVal) {
      this.chooseDate(newVal);
      this.$emit("input", {
        text: this.parsedValue,
        value: newVal
      });
    }
  }
})
export default class ValidatedMonthpicker extends Vue {
  private expanded = false;
  private innerValue = "";
  private parsedValue = "";

  private created() {
    if (this.$props.value) {
      this.innerValue = this.$props.value.value;
      this.parsedValue = this.$props.value.text;
    }
  }

  private chooseDate(date: string) {
    if (date) {
      this.expanded = false;
      this.innerValue = date;
      this.parsedValue = DateUtils.format(date, "MM.YYYY");
    }
  }
}
