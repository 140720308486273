import { Employee, EmployeeService } from "@/employee/services/EmployeeService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { Report } from "@/storage/storageHandlers/ReportStorageHandler";

export class SampleAccordionController {
  public constructor(
    private presenter: ISampleAccordionPresenter,
    private employeeService: EmployeeService
  ) {}

  public mounted(
    sampleData: any,
    report: Report,
    serviceEngineerId: string,
    serviceEngineerName: string
  ) {
    this.presenter.sampleData = {
      ...sampleData,
      sampleTaker: serviceEngineerName
    };
    this.presenter.report = report;

    if (!serviceEngineerName) {
      FormRequestHandler.handle(
        this.employeeService.getEmployee(serviceEngineerId),
        response => (this.presenter.serviceEngineerResponse = response),
        `Konnte den Service-Ingenieur mit ID ${serviceEngineerId} nicht auslesen.`
      );
    }
  }
}

export interface ISampleAccordionPresenter {
  sampleData: any;
  report: Report;
  serviceEngineerResponse: FormResponse<Employee>;
}
