import { CreatePlantViewModel } from "../vms/CreatePlantViewModel";
import { ICreatePlantPresenter } from "../controllers/CreatePlantController";
import { AddressForm } from "@/forms/common/AddressForm";
import { PlantDataForm } from "@/forms/plant/PlantDataForm";
import { PlantOtherForm } from "@/forms/plant/PlantOtherForm";
import { FormResponse } from "@/forms/FormResponse";
import { Plant } from "../services/PlantService";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { PlantGroupStorageHandler } from "@/storage/storageHandlers/PlantGroupStorageHandler";
import { DistrictAuthorityStorageHandler } from "@/storage/storageHandlers/DistrictAuthorityStorageHandler";
import { PlantSizeStorageHandler } from "@/storage/storageHandlers/PlantSizeStorageHandler";

export class CreatePlantPresenter implements ICreatePlantPresenter {
  public addressForm: AddressForm;
  public plantDataForm: PlantDataForm;
  public otherForm: PlantOtherForm;

  public constructor(
    private vm: CreatePlantViewModel,
    private storage: SimpleStringStorage
  ) {
    this.addressForm = new AddressForm(this.vm, this.setAddressFormValid);
    this.addressForm.addFieldChangedListener(
      "country",
      this.countrySelected,
      this
    );
    this.plantDataForm = new PlantDataForm(this.vm, this.setPlantDataFormValid);
    this.plantDataForm.addFieldChangedListener(
      "group",
      this.groupSelected,
      this
    );
    this.plantDataForm.addFieldChangedListener("type", this.typeSelected, this);
    this.otherForm = new PlantOtherForm(this.vm, this.setOtherFormValid);

    this.addressForm.init();
    this.plantDataForm.init();
    this.otherForm.init();

    this.initSelections();
  }

  public init() {
    // this.vm.firstDateVisible = true;
  }

  public set visibleView(visible: boolean) {
    this.vm.visibleView = visible;
    if (!visible) {
      setTimeout(() => (this.vm.goBack = true), 300);
    }
  }

  public set addressStepCompleted(completed: boolean) {
    this.vm.addressStepCompleted = completed;
    this.vm.formStep = completed ? 2 : 1;
  }

  public setAddressFormValid(context: any, valid: boolean) {
    context.openPlantDataStepButtonDisabled = !valid;
  }

  public setPlantDataFormValid(context: any, valid: boolean) {
    context.openOtherStepButtonDisabled = !valid;
  }

  public set plantDataStepCompleted(completed: boolean) {
    this.vm.plantDataStepCompleted = completed;
    this.vm.formStep = completed ? 3 : 2;
  }

  public setOtherFormValid(context: any, valid: boolean) {
    context.createPlantButtonDisabled = !valid;
  }

  public set plantSizes(additionalFields: any[]) {
    this.vm.plantSizes = additionalFields;
  }

  public get plantSizes(): any[] {
    return this.vm.plantSizes;
  }

  public set createPlantResponse(response: FormResponse<string>) {
    this.vm.createPlantRequest = response;

    if (!response.loading && !response.error) {
      this.vm.createdPlant = response.data;
    }
  }

  public mounted(): void {
    this.vm.visibleView = true;
  }

  public get plant(): Plant {
    return {
      id: this.vm.id,

      addressRemarks: this.vm.addressRemarks.value,
      addressStreet: this.vm.street.value,
      addressZip: this.vm.zip.value,
      addressCity: this.vm.city.value,
      addressCountry: this.vm.country.selected,
      addressState: this.vm.state.selected,
      protocol: "",
      group: this.vm.group.selected,
      type: this.vm.type.selected,
      plantSizes: JSON.stringify(
        this.vm.plantSizes.map(el => ({ id: el.id, value: el.value }))
      ),
      // maintenanceInterval: this.vm.maintenanceInterval.value,
      sampleNeeded: this.vm.sampleNeeded.value,
      // firstDate: !!this.vm.firstDate.value
      //   ? new Date(this.vm.firstDate.value)
      //   : undefined,

      districtAuthority: this.vm.districtAuthority.selected,
      districtAuthorityNumber: this.vm.daNumber.value,
      remarks: this.vm.remark.value,

      operatorIds: this.vm.operators.selected,
      contactPersons: [],
      parameters: [],
      plantParts: [],
      plantInstallationParts: [],
      plantDocuments: []
    };
  }

  public set plant(plant: Plant) {
    this.vm.id = plant.id;

    this.addressForm.setFieldValue("addressRemarks", plant.addressRemarks);
    this.addressForm.setFieldValue("street", plant.addressStreet);
    this.addressForm.setFieldValue("zip", plant.addressZip);
    this.addressForm.setFieldValue("city", plant.addressCity);
    this.addressForm.setFieldValue("country", plant.addressCountry);
    this.addressForm.setFieldValue("state", plant.addressState);

    this.plantDataForm.setFieldValue("group", plant.group);
    if (plant.type && this.hasType(plant.type)) {
      this.plantDataForm.setFieldValue("type", plant.type);
    }

    this.plantDataForm.setFieldValue(
      "sampleNeeded",
      plant.sampleNeeded ? "true" : "false"
    );

    this.otherForm.setFieldValue("districtAuthority", plant.districtAuthority);
    this.otherForm.setFieldValue("daNumber", plant.districtAuthorityNumber);
    this.otherForm.setFieldValue("remark", plant.remarks);

    for (const operator of (plant as any).operators) {
      this.otherForm.setFieldValue("operators", operator.id);
    }

    this.vm.plantSizes = [];

    if (!!plant.plantSizes) {
      const rawPlantSizes = JSON.parse(plant.plantSizes);
      for (const rawPlantSize of rawPlantSizes) {
        const plantSize = PlantSizeStorageHandler.getPlantSizeFromId(
          rawPlantSize.id
        );
        plantSize.value = rawPlantSize.value;
        this.vm.plantSizes.push(plantSize);
      }
    }
  }

  private initSelections() {
    this.vm.country.items = CountriesStorageHandler.getAllCountries();
    this.vm.group.items = PlantGroupStorageHandler.getAllGroups();
    this.vm.districtAuthority.items = DistrictAuthorityStorageHandler.getAllDistrictAuthorities();
  }

  private countrySelected(context: any, value: string) {
    context.vm.state.items = CountriesStorageHandler.getStatesFromCountry(
      value
    );
  }

  private groupSelected(context: any, value: string) {
    context.vm.type.items = PlantGroupStorageHandler.getTypesFromGroup(value);
  }

  private typeSelected(context: any, value: string) {
    const sizeIds = PlantGroupStorageHandler.getPlantSizesFromType(value);
    context.vm.plantSizes = [];
    for (const sizeId of sizeIds) {
      context.vm.plantSizes.push(
        PlantGroupStorageHandler.getSizeFromId(sizeId)
      );
    }
  }

  private hasType(typeId: string) {
    return this.vm.type.items.some(el => el.value === typeId);
  }
}
