import { Request, TableHeader } from "@/forms/ViewModelFormTypes";

export class EmployeeListViewModel {
  // Data
  public employees: EmployeeViewData[] = [];
  public addNewEmployee = false;
  public employeeToEdit = "";
  public selectedTourDataEmployee = "";

  // Texts
  public title = "";
  public addNewEmployeeButtonText = "";
  public editEmployeeButtonText = "";
  public editTourDataButtonText = "";

  // States
  public tourDataDialogVisible = false;

  // Requests
  public loadEmployeesRequest: Request = { loading: false, error: "" };

  // Table Headers
  public headers: TableHeader[] = [
    {
      text: "Kürzel",
      value: "abbreviation"
    },
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Telefonnummer",
      value: "phoneNumber"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "right"
    }
  ];
  public tabletHeaders: TableHeader[] = [
    {
      text: "Kürzel",
      value: "abbreviation"
    },
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Telefonnummer",
      value: "phoneNumber"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "right"
    }
  ];
  public mobileHeaders: TableHeader[] = [
    {
      text: "Name",
      value: "name"
    },
    {
      text: "Aktionen",
      value: "actions",
      align: "right"
    }
  ];
}

interface EmployeeViewData {
  id: string;
  abbreviation: string;
  name: string;
  phoneNumber: string;
  active: boolean;
}
