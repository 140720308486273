/* tslint:disable:ban-types */

type NonFunctionPropertyNames<T> = {
  [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];
export type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

export class TypeUtils {
  public static isString(obj: any): obj is string {
    return typeof obj === "string";
  }

  public static isError(obj: any): obj is Error {
    return obj instanceof Error;
  }

  public static isDate(obj: any): obj is Date {
    return obj instanceof Date;
  }
}
