import { Auth } from "@/common/utils/Auth";
import { FormResponse } from "@/forms/FormResponse";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { DateUtils } from "@/utils/DateUtils";
import { ICreateMaterialRequestPresenter } from "../controllers/CreateMaterialRequestController";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { CreateMaterialRequestViewModel } from "../vms/CreateMaterialRequestViewModel";

export class CreateMaterialRequestPresenter
  implements ICreateMaterialRequestPresenter {
  public itemTemplateDict: { [key: string]: string } = {};

  public constructor(private vm: CreateMaterialRequestViewModel) {}

  public set readonly(readonly: boolean) {
    this.vm.readonly = readonly;
  }

  public get readonly() {
    return this.vm.readonly;
  }

  public set visibleView(visibleView: boolean) {
    this.vm.visibleView = visibleView;
  }

  public set getMaterialRequestResponse(response: FormResponse<any>) {
    if (!response.loading && !response.error) {
      this.vm.materialRequestItems = response.data.items.map((el: any) => ({
        name: el.name,
        amount: el.amount || "0"
      }));
    }
  }

  public set getItemTemplatesResponse(response: FormResponse<ItemTemplate[]>) {
    if (!response.loading && !response.error) {
      for (const itemTemplate of response.data) {
        this.itemTemplateDict[itemTemplate.id] = itemTemplate.name;
      }

      this.vm.itemTemplates.items = response.data.map(el => ({
        text: el.name,
        value: el.id
      }));
    }
  }

  public itemTemplateSelected(selected: SelectionItem & string) {
    this.vm.materialRequestItems.push({
      name: selected.text || selected,
      amount: 0
    });

    const itemTemplateIndex = this.vm.itemTemplates.items.findIndex(
      el => el.value === selected.value
    );
    if (itemTemplateIndex >= 0) {
      this.vm.itemTemplates.items.splice(itemTemplateIndex, 1);
    }

    this.vm.itemTemplates.selected = "";
  }

  public get data() {
    return {
      date: DateUtils.dateOnly(new Date()),
      employeeId: Auth.userId,
      items: this.vm.materialRequestItems.map(el => ({
        name: el.name,
        amount: parseInt(el.amount.toString(), 10)
      }))
    };
  }

  public set createMaterialRequestResponse(response: FormResponse<string>) {
    this.vm.loading = response.loading;
    this.vm.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.visibleView = false;
    }
  }
}
