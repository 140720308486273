




































import Vue from "vue";
import Component from "vue-class-component";

import GoogleMap from "@/components/google/GoogleMap.vue";
import GoogleMapMarker from "@/components/google/GoogleMapMarker.vue";
import GoogleMapInfoWindow from "@/components/google/GoogleMapInfoWindow.vue";

@Component<PlantSurroundingMap>({
  components: {
    GoogleMap,
    GoogleMapMarker,
    GoogleMapInfoWindow
  },
  props: {
    lat: {
      required: true,
      type: Number
    },
    lng: {
      required: true,
      type: Number
    },
    draggable: {
      type: Boolean,
      default: false
    }
  }
})
export default class PlantSurroundingMap extends Vue {
  private infoWindowShown = true;
}
