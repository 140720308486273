import { Form, FormFieldType } from "@/forms/Form";

export class TimeTrackingDataForm extends Form {
  protected definition = {
    maintenanceDriveDuration: {
      required: false,
      type: FormFieldType.Number,
      precision: 2
    },
    maintenanceWorkDuration: {
      required: false,
      type: FormFieldType.Number,
      precision: 2
    },
    maintenanceBonus: {
      required: false,
      type: FormFieldType.Number,
      precision: 2
    },
    maintenanceRegiments: {
      required: false,
      type: FormFieldType.Number,
      precision: 2
    },
    othersDriveDuration: {
      required: false,
      type: FormFieldType.Number,
      precision: 2
    },
    othersWorkDuration: {
      required: false,
      type: FormFieldType.Number,
      precision: 2
    },
    othersBonus: { required: false, type: FormFieldType.Number, precision: 2 },
    othersRegiments: {
      required: false,
      type: FormFieldType.Number,
      precision: 2
    },
    plantIndex: { required: true, type: FormFieldType.Number },
    operator: { required: false, type: FormFieldType.Text },
    doneTasks: { required: true, type: FormFieldType.Text }
  };
}
