import { Form, FormFieldType } from "@/forms/Form";

export class AppointmentForm extends Form {
  protected definition = {
    date: { required: true, type: FormFieldType.Date },
    reason: { required: false, type: FormFieldType.Text },
    duration: { required: true, type: FormFieldType.Time },
    sampleNeeded: { required: false, type: FormFieldType.Checkbox }
  };
}
