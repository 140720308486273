





































import Vue from "vue";
import Component from "vue-class-component";
import { StringUtils } from "../../utils/StringUtils";
import { DateUtils } from "../../utils/DateUtils";

@Component<TimePicker>({
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default() {
        return { value: "", text: "", label: "Datum", error: "" };
      }
    },
    icon: {
      type: String,
      default: "access_time"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    extraDense: {
      type: Boolean,
      default: false
    },
    allowedMinutes: {
      type: Array,
      default() {
        return undefined;
      }
    },
    allowedHours: {
      type: Array,
      default() {
        return undefined;
      }
    }
  },
  watch: {
    expanded(val: boolean) {
      if (val && this.$props.birthday) {
        setTimeout(() => {
          const picker = this.$refs.picker as any;
          picker.activePicker = "YEAR";
        });
      }
    }
  }
})
export default class TimePicker extends Vue {
  private expanded = false;
  private time: string = "";

  private mounted() {
    if (StringUtils.isString(this.$props.value.value)) {
      this.time = this.$props.value.value;
    } else {
      this.time = DateUtils.timeToString(this.$props.value.value);
    }
  }

  // private hourClicked(hour: string) {
  //   this.time = (parseInt(hour, 10) < 10 ? "0" + hour : hour) + ":00";
  // }

  private chooseTime(time: string) {
    this.expanded = false;

    if (StringUtils.isString(this.$props.value.value)) {
      this.emitInput(this.time);
    } else {
      this.emitInput(DateUtils.timeToNumber(this.time));
    }
  }

  private emitInput(time: number | string) {
    this.$emit("input", time);
    this.$emit("change", time);
  }
}
