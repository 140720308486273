var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{attrs:{"title":"Arbeitsaufträge"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('SubmitTextfield',{attrs:{"flat":"","solo":"","outlined":true,"disabled":_vm.loading,"label":"Suche"},on:{"submit":function($event){return _vm.controller.searchWorkOrders($event)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})]},proxy:true}])},[_c('Container',{attrs:{"fluid":""}},[_c('TabbedCard',{attrs:{"tabs":_vm.controller.canApproveWorkOrders
          ? [
              'Offen',
              'Zu Prüfen',
              'Abgeschlossen',
              'Fakturiert',
              'Archiviert'
            ]
          : ['Offen', 'Zu Prüfen'],"activeTabIndex":_vm.vm.activeTabIndex},on:{"activeTabChanged":function($event){return _vm.controller.loadTab($event)}}},[_c('v-tab-item',[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":function($event){return _vm.goToCreateWorkOrder()}}},[_vm._v("Kundendienstbericht erstellen")]),_c('ServerDatatable',{attrs:{"headers":_vm.controller.getOpenWorkOrdersHeaders(_vm.vm.workOrderHeaders),"tabletHeaders":_vm.controller.getOpenWorkOrdersHeaders(_vm.vm.workOrderTabletHeaders),"mobileHeaders":_vm.controller.getOpenWorkOrdersHeaders(_vm.vm.workOrderMobileHeaders),"itemsPerPage":_vm.vm.openWorkOrdersPagination.itemsPerPage,"loading":_vm.vm.openWorkOrdersLoading,"value":_vm.vm.openWorkOrdersPagination.pageNumber,"totalItems":_vm.vm.openWorkOrdersPagination.totalItems,"items":_vm.vm.openWorkOrders,"options":_vm.vm.openWorkOrdersOptions,"multisort":true},on:{"sort":function($event){return _vm.controller.openWorkOrdersSorted($event)},"input":function($event){return _vm.controller.openWorkOrdersPageChanged($event)}},scopedSlots:_vm._u([{key:"item.sampleNeeded",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.sampleNeeded ? "done" : "clear"))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('IconButton',{attrs:{"icon":"edit","filled":"","tooltipText":"Bearbeiten"},on:{"click":function($event){return _vm.goToWorkOrder(item.id)}}}),(item.processingState === 'OPEN')?_c('CancelAppointmentDialog',{attrs:{"canDelete":false,"big":true},on:{"confirm":function($event){return _vm.controller.cancelWorkOrder(item.id, $event.cancellationReason)}}}):_vm._e()]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [(item.processingState === 'REJECTED')?_c('v-chip',{attrs:{"color":"red","small":"","outlined":""}},[_vm._v("Abgelehnt")]):_vm._e(),(item.syncing)?_c('v-chip',{attrs:{"color":"primary","small":"","outlined":""}},[_vm._v("Synchronisiert...")]):_vm._e()]}},{key:"item.address",fn:function(ref){
          var item = ref.item;
return [_c('div',{class:[_vm.isOnline ? 'fill-out' : ''],on:{"click":function($event){_vm.isOnline ? _vm.openMap(item) : ''}}},[_vm._v(" "+_vm._s(_vm.controller.parseAddress(item))+" ")])]}},{key:"item.plannedDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.controller.parseDate(item))+" ")]}}],null,true)}),_c('div',{staticStyle:{"position":"absolute","bottom":"10px","right":"10px","display":"table"}},[_c('font',{staticStyle:{"display":"table-cell","vertical-align":"middle"}},[_vm._v("Letzter Download: "+_vm._s(_vm.controller.getLastDownloadDate()))]),_c('IconButton',{staticClass:"pa-2",attrs:{"icon":"get_app","tooltipText":"Arbeitsaufträge herunterladen","filled":""},on:{"click":function($event){return _vm.controller.storeData(true)}}})],1)],1),_c('v-tab-item',[_c('ServerDatatable',{attrs:{"headers":_vm.vm.workOrderHeaders,"tabletHeaders":_vm.vm.workOrderTabletHeaders,"mobileHeaders":_vm.vm.workOrderMobileHeaders,"items":_vm.vm.toCheckWorkOrders,"itemsPerPage":_vm.vm.toCheckWorkOrdersPagination.itemsPerPage,"loading":_vm.vm.toCheckWorkOrdersLoading,"value":_vm.vm.toCheckWorkOrdersPagination.pageNumber,"totalItems":_vm.vm.toCheckWorkOrdersPagination.totalItems,"options":_vm.vm.toCheckWorkOrdersOptions,"multisort":true},on:{"sort":function($event){return _vm.controller.toCheckWorkOrdersSorted($event)},"input":function($event){return _vm.controller.toCheckWorkOrdersPageChanged($event)}},scopedSlots:_vm._u([{key:"item.sampleNeeded",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.sampleNeeded ? "done" : "clear"))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('IconButton',{attrs:{"icon":"edit","filled":"","tooltipText":"Bearbeiten"},on:{"click":function($event){return _vm.goToWorkOrder(item.id)}}})]}},{key:"item.address",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.controller.parseAddress(item))+" ")]}},{key:"item.plannedDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.controller.parseDate(item))+" ")]}}],null,true)})],1),(_vm.controller.canApproveWorkOrders)?_c('div',[_c('v-tab-item',[_c('ServerDatatable',{attrs:{"headers":_vm.vm.workOrderHeaders,"tabletHeaders":_vm.vm.workOrderTabletHeaders,"mobileHeaders":_vm.vm.workOrderMobileHeaders,"items":_vm.vm.finishedWorkOrders,"itemsPerPage":_vm.vm.finishedWorkOrdersPagination.itemsPerPage,"loading":_vm.vm.finishedWorkOrdersLoading,"value":_vm.vm.finishedWorkOrdersPagination.pageNumber,"totalItems":_vm.vm.finishedWorkOrdersPagination.totalItems,"options":_vm.vm.finishedWorkOrdersOptions,"multisort":true},on:{"sort":function($event){return _vm.controller.finishedWorkOrdersSorted($event)},"input":function($event){return _vm.controller.finishedWorkOrdersPageChanged($event)}},scopedSlots:_vm._u([{key:"item.sampleNeeded",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.sampleNeeded ? "done" : "clear"))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('IconButton',{attrs:{"icon":"fast_rewind","filled":"","tooltipText":"Zurücksetzen"},on:{"click":function($event){return _vm.controller.setWorkOrderToCheck(item.id)}}}),_c('IconButton',{staticClass:"ml-2",attrs:{"icon":"edit","filled":"","tooltipText":"Bearbeiten"},on:{"click":function($event){return _vm.goToWorkOrder(item.id)}}}),(item.reportPdfPath)?_c('IconButton',{staticClass:"ml-2",attrs:{"icon":"visibility","filled":"","tooltipText":"Bericht-Pdf öffnen"},on:{"click":function($event){return _vm.downloadFile(item.reportPdfPath)}}}):_vm._e(),(item.workOrderPdfPath)?_c('IconButton',{staticClass:"ml-2",attrs:{"icon":"visibility","filled":"","tooltipText":"Arbeitsauftrag-Pdf öffnen"},on:{"click":function($event){return _vm.downloadFile(item.workOrderPdfPath)}}}):_vm._e(),_c('IconButton',{staticClass:"ml-2",attrs:{"icon":"euro","filled":"","tooltipText":"Fakturiert"},on:{"click":function($event){return _vm.controller.invoiceWorkOrder(item.id)}}})]}},{key:"item.address",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.controller.parseAddress(item))+" ")]}},{key:"item.plannedDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.controller.parseDate(item))+" ")]}}],null,true)})],1),_c('v-tab-item',[_c('ServerDatatable',{attrs:{"headers":_vm.vm.workOrderHeaders,"tabletHeaders":_vm.vm.workOrderTabletHeaders,"mobileHeaders":_vm.vm.workOrderMobileHeaders,"items":_vm.vm.toInvoiceWorkOrders,"itemsPerPage":_vm.vm.toInvoiceWorkOrdersPagination.itemsPerPage,"loading":_vm.vm.toInvoiceWorkOrdersLoading,"value":_vm.vm.toInvoiceWorkOrdersPagination.pageNumber,"totalItems":_vm.vm.toInvoiceWorkOrdersPagination.totalItems,"options":_vm.vm.toInvoiceWorkOrdersOptions,"multisort":true},on:{"sort":function($event){return _vm.controller.toInvoiceWorkOrdersSorted($event)},"input":function($event){return _vm.controller.toInvoiceWorkOrdersPageChanged($event)}},scopedSlots:_vm._u([{key:"item.sampleNeeded",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.sampleNeeded ? "done" : "clear"))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('IconButton',{attrs:{"icon":"fast_rewind","filled":"","tooltipText":"Zurücksetzen"},on:{"click":function($event){return _vm.controller.setWorkOrderFinished(item.id)}}}),_c('IconButton',{staticClass:"ml-2",attrs:{"icon":"edit","filled":"","tooltipText":"Bearbeiten"},on:{"click":function($event){return _vm.goToWorkOrder(item.id)}}}),(item.reportPdfPath)?_c('IconButton',{staticClass:"ml-2",attrs:{"icon":"visibility","filled":"","tooltipText":"Bericht-Pdf öffnen"},on:{"click":function($event){return _vm.downloadFile(item.reportPdfPath)}}}):_vm._e(),(item.workOrderPdfPath)?_c('IconButton',{staticClass:"ml-2",attrs:{"icon":"visibility","filled":"","tooltipText":"Arbeitsauftrag-Pdf öffnen"},on:{"click":function($event){return _vm.downloadFile(item.workOrderPdfPath)}}}):_vm._e(),_c('IconButton',{staticClass:"ml-2",attrs:{"icon":"move_to_inbox","filled":"","tooltipText":"Archivieren"},on:{"click":function($event){return _vm.controller.archiveWorkOrder(item.id)}}})]}},{key:"item.address",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.controller.parseAddress(item))+" ")]}},{key:"item.plannedDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.controller.parseDate(item))+" ")]}}],null,true)})],1),_c('v-tab-item',[_c('ServerDatatable',{attrs:{"headers":_vm.vm.workOrderHeaders,"tabletHeaders":_vm.vm.workOrderTabletHeaders,"mobileHeaders":_vm.vm.workOrderMobileHeaders,"items":_vm.vm.archivedWorkOrders,"itemsPerPage":_vm.vm.archivedWorkOrdersPagination.itemsPerPage,"loading":_vm.vm.archivedWorkOrdersLoading,"value":_vm.vm.archivedWorkOrdersPagination.pageNumber,"totalItems":_vm.vm.archivedWorkOrdersPagination.totalItems,"options":_vm.vm.archivedWorkOrdersOptions,"multisort":true},on:{"sort":function($event){return _vm.controller.archivedWorkOrdersSorted($event)},"input":function($event){return _vm.controller.archivedWorkOrdersPageChanged($event)}},scopedSlots:_vm._u([{key:"item.sampleNeeded",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',[_vm._v(_vm._s(item.sampleNeeded ? "done" : "clear"))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('IconButton',{attrs:{"icon":"fast_rewind","filled":"","tooltipText":"Zurücksetzen"},on:{"click":function($event){return _vm.controller.invoiceWorkOrder(item.id)}}}),_c('IconButton',{staticClass:"ml-2",attrs:{"icon":"edit","filled":"","tooltipText":"Bearbeiten"},on:{"click":function($event){return _vm.goToWorkOrder(item.id)}}}),(item.reportPdfPath)?_c('IconButton',{staticClass:"ml-2",attrs:{"icon":"visibility","filled":"","tooltipText":"Bericht-Pdf öffnen"},on:{"click":function($event){return _vm.downloadFile(item.reportPdfPath)}}}):_vm._e(),(item.workOrderPdfPath)?_c('IconButton',{staticClass:"ml-2",attrs:{"icon":"visibility","filled":"","tooltipText":"Arbeitsauftrag-Pdf öffnen"},on:{"click":function($event){return _vm.downloadFile(item.workOrderPdfPath)}}}):_vm._e()]}},{key:"item.address",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.controller.parseAddress(item))+" ")]}},{key:"item.plannedDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.controller.parseDate(item))+" ")]}}],null,true)})],1)],1):_vm._e()],1)],1),_vm._l((_vm.components),function(component,index){return _c(component.component,{key:("p-" + index),tag:"component",attrs:{"lat":component.lat,"lng":component.lng}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }