




















































import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

@Component<Card>({
  components: {
    Row,
    Column
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
    icon: {
      type: String,
      default: ""
    },
    borderRadius: {
      type: Number,
      default: 20
    },
    titleMargin: {
      type: Number,
      default: 0
    },
    error: {
      type: String,
      default: ""
    },
    small: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    }
  }
})
export default class Card extends Vue {
  private get totalBorderRadius() {
    return this.$props.borderRadius + "px !important";
  }
  private get topBorderRadius() {
    const radius = this.$props.borderRadius;
    return `${radius}px ${radius}px 0px 0px`;
  }

  private get bottomBorderRadius() {
    const radius = this.$props.borderRadius;
    return `0px 0px ${radius}px ${radius}px`;
  }

  private get margin() {
    return this.$props.titleMargin + "px !important";
  }

  private emitAction() {
    this.$emit("action");
  }

  private emitRetry() {
    this.$emit("retry");
  }
}
