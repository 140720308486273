import { IWorkOrderMetaListPresenter } from "../controllers/WorkOrderMetaListController";
import { WorkOrderMetaListViewModel } from "../vms/WorkOrderMetaListViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { DateUtils } from "@/utils/DateUtils";

export class WorkOrderMetaListPresenter implements IWorkOrderMetaListPresenter {
  public constructor(private vm: WorkOrderMetaListViewModel) {}

  public set getWorkOrdersResponse(response: FormResponse<any[]>) {
    this.vm.loading = response.loading;
    this.vm.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.allWorkOrders = response.data;

      for (const wo of response.data) {
        const date = DateUtils.getDate(wo.plannedDate);
        const weekNumber = DateUtils.weekNumber(date);
        const monday = DateUtils.weekMondayFromDate(date);
        wo.calendarWeek = {
          text: `KW ${weekNumber} / ${date.getFullYear()}`,
          value: DateUtils.dateOnly(monday)
        };
      }

      this.vm.week.items = this.vm.allWorkOrders
        .map((el: any) => el.calendarWeek)
        .filter(
          (value, index, self) =>
            self.findIndex(el => el.value === value.value) === index
        );
      this.vm.week.items.sort((a, b) => a.text.localeCompare(b.text));
      if (this.vm.week.selected) {
        this.vm.workOrders = this.vm.allWorkOrders.filter(
          (wo: any) => wo.calendarWeek.value === this.vm.week.selected
        );
      } else {
        this.vm.workOrders = this.vm.allWorkOrders;
      }
    }
  }

  public set week(week: string) {
    this.vm.week.selected = week;
    if (week) {
      this.vm.workOrders = this.vm.allWorkOrders.filter(
        (wo: any) => wo.calendarWeek.value === week
      );
    } else {
      this.vm.workOrders = this.vm.allWorkOrders;
    }
  }

  public set uploadLabReportResponse(response: FormResponse<string>) {
    this.vm.loading = response.loading;
    this.vm.error = response.error;

    if (!response.loading && !response.error) {
      this.vm.currentWorkOrder.reportPdfPath = response.data;
      this.vm.currentWorkOrder.hasLabReport = true;
    }
  }

  public get options() {
    return this.vm.options;
  }

  public set options(options: any) {
    this.vm.options = options;
  }

  public set sendReportsResponse(response: FormResponse<any>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      if (response.data.hasFiles) {
        window.open(response.data.link, "_blank");
      }
    }
  }
}
