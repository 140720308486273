

















































































































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Section from "@/components/layout/Section.vue";
import ServerDatatable from "@/components/collection/ServerDatatable.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import IconButton from "@/components/basic/IconButton.vue";
import DeleteDialog from "@/components/basic/DeleteDialog.vue";
import CreateStoreItemView from "@/store/views/CreateStoreItemView.vue";

import { StoreItemsListViewModel } from "@/store/vms/StoreItemsListViewModel";
import { StoreItemsListPresenter } from "@/store/presenters/StoreItemsListPresenter";
import { StoreItemsListController } from "@/store/controllers/StoreItemsListController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { StoreItem } from "../services/StoreItemGraphQLService";
import { BarcodeGenerator } from "../utils/BarcodeGenerator";
import { StoreItemService } from "../services/StoreItemService";
import { StoreService } from "../services/StoreService";
import { ItemTemplateService } from "../services/ItemTemplateService";

@Component<StoreItemsListView>({
  components: {
    Page,
    Section,
    ServerDatatable,
    Card,
    Container,
    Column,
    Row,
    IconButton,
    DeleteDialog,
    CreateStoreItemView
  }
})
export default class StoreItemsListView extends Vue {
  private vm = new StoreItemsListViewModel();
  private controller?: StoreItemsListController;

  private isDeleteDialogShown = false;
  private toDeleteMinimalAmount: any = {};
  private components: any[] = [];

  private initController(): StoreItemsListController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new StoreItemsListController(
      new StoreItemsListPresenter(this.vm),
      new StoreItemService(connection),
      new StoreService(connection),
      new ItemTemplateService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }

  private addMinimalAmountButtonClicked(storeItemId?: string) {
    this.components.push({ id: storeItemId });
  }

  private clearComponents() {
    this.controller?.getItems();
    this.components = [];
  }

  private showDeleteDialog(storeItem: StoreItem) {
    this.toDeleteMinimalAmount = {
      id: storeItem.id,
      name: storeItem.itemTemplate.name
    };
    this.isDeleteDialogShown = true;
  }

  private deleteMinimalAmount() {
    this.controller?.deleteMinimalAmount(this.toDeleteMinimalAmount.id);
    this.isDeleteDialogShown = false;
  }

  private rowColor(item: StoreItem) {
    const amount = parseInt(item.amount, 10);
    const minimalAmount = parseInt(item.minimalAmount, 10);

    if (amount < minimalAmount) {
      return "store-items-list-critical-row";
    } else if (amount < minimalAmount + minimalAmount / 10) {
      return "store-items-list-danger-row";
    }
    return "store-items-list-normal-row";
  }

  private rowClicked(item: any) {
    this.$router.push({
      name: "store-movements",
      params: {
        storeId: item.storeId,
        itemTemplateId: item.itemTemplate.id
      }
    });
  }

  private downloadBarcodeButtonClicked(item: StoreItem) {
    BarcodeGenerator.downloadBarcode(
      item.itemTemplate.itemNumber.toString(),
      item.itemTemplate.name,
      item.itemTemplate.dimensions
    );
  }
}
