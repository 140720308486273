
















































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import DeleteDialog from "@/components/basic/DeleteDialog.vue";
import HoverableDatatable from "@/components/collection/HoverableDatatable.vue";

import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { LocalStorage } from "../../../storage/LocalStorage";
import SystemKeys from "../../../common/utils/SystemKeys";
import { PlantGraphQLService } from "@/plant/services/PlantGraphQLService";
import { Plant, PlantPart } from "@/plant/services/PlantService";
import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import StorageKeys from "../../../common/utils/StorageKeys";
import { Auth } from "../../../common/utils/Auth";

@Component<PlantInstallationPartsData>({
  components: {
    Card,
    Grid,
    Row,
    Column,
    DeleteDialog,
    HoverableDatatable,
    CreatePlantPart: () => import("@/plant/views/CreatePlantPart.vue")
  },
  props: ["plant", "plantRequest", "element"]
})
export default class PlantInstallationPartsData extends Vue {
  private components: any[] = [];
  private storage = new LocalStorage();
  private loading = false;
  private plant!: Plant;
  private isDeleteDialogShown = false;
  private toDeletePlantPart: any = {};
  private plantPartHeaders = [
    { text: "Einbauteil", value: "name", sortable: true },
    { text: "Anzahl", value: "amount", sortable: true },
    { text: "Beschreibung", value: "description", sortable: false },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "right",
      width: "80px"
    }
  ];

  private connection = new AxiosGraphQLConnection(
    new AxiosLogger(new ConsoleLogger())
  );
  private plantService = new PlantGraphQLService(this.connection);

  private retry(event: any) {
    this.components = [];
    this.$emit("retry", event);
  }

  private addPlantPartsButtonClicked() {
    this.components.push({ component: "CreatePlantPart" });
  }

  private editPlantPartsButtonClicked(plantPart: PlantPart) {
    this.components.push({ component: "CreatePlantPart", plantPart });
  }

  private showDeleteDialog(plantPart: PlantPart) {
    this.toDeletePlantPart = plantPart;
    this.isDeleteDialogShown = true;
  }

  private deletePlantPartButtonClicked(plantPart: PlantPart) {
    this.loading = true;
    const request = this.plantService.removePlantInstallationPart(
      this.plant,
      plantPart
    );
    FormRequestHandler.handle(
      request,
      response => {
        this.isDeleteDialogShown = false;
        if (!response.loading && !response.error) {
          this.toDeletePlantPart = {};
          this.retry(plantPart);
          this.loading = false;
        }
      },
      "update-plant-failed"
    );
  }

  private plantPartAdded(event: any) {
    this.retry(event);
  }

  private clearComponents() {
    this.components = [];
  }

  private getPlantPartNameById(plantPartId: string) {
    if (this.storage.has(SystemKeys.plantInstallationPartsTableKey)) {
      const entries = JSON.parse(
        this.storage.get(SystemKeys.plantInstallationPartsTableKey)
      );
      const index = entries.findIndex((el: any) => el.id === plantPartId);

      if (index >= 0) {
        return entries[index].values[SystemKeys.plantInstallationPartsNameKey];
      }
    }
  }

  private canEdit() {
    return Auth.hasPermission(StorageKeys.editPlantAndOperatorPermission);
  }
}
