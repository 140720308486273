import { Request, Textfield, MultiSelect } from "@/forms/ViewModelFormTypes";

export class UserRoleDetailViewModel {
  // Data
  public userRoleId: string = "";
  public permissionGroups: MultiSelect[] = [];
  public savedRoleId: string = "";
  public deletedRoleId: string = "";

  // States
  public deleteRoleButtonVisible: boolean = false;
  public saveRoleButtonDisabled: boolean = true;

  // Texts
  public title: string = "";
  public deleteRoleButtonText: string = "";
  public saveRoleButtonText: string = "";
  public deleteRoleConfirmTitle: string = "";
  public deleteRoleConfirmText: string = "";

  // Requests
  public loadPageRequest: Request = { loading: false, error: "" };
  public saveRoleRequest: Request = { loading: false, error: "" };
  public deleteRoleRequest: Request = { loading: false, error: "" };

  // Input
  public name: Textfield = {
    label: "",
    value: "",
    loading: false,
    error: ""
  };
}
