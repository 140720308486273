
















































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import DeleteDialog from "@/components/basic/DeleteDialog.vue";
import HoverableDatatable from "@/components/collection/HoverableDatatable.vue";

import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { LocalStorage } from "../../../storage/LocalStorage";
import SystemKeys from "../../../common/utils/SystemKeys";
import { PlantGraphQLService } from "@/plant/services/PlantGraphQLService";
import { Plant } from "@/plant/services/PlantService";
import { PlantParameter } from "@/storage/storageHandlers/ParameterStorageHandler.ts";
import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { Auth } from "../../../common/utils/Auth";
import StorageKeys from "../../../common/utils/StorageKeys";

@Component<PlantLimitsData>({
  components: {
    Card,
    Grid,
    Row,
    Column,
    DeleteDialog,
    HoverableDatatable,
    CreateParameter: () => import("@/plant/views/CreateParameter.vue")
  },
  props: ["plant", "plantRequest", "element"]
})
export default class PlantLimitsData extends Vue {
  private components: any[] = [];
  private storage = new LocalStorage();
  private loading = false;
  private plant!: Plant;
  private isDeleteDialogShown = false;
  private toDeleteParameter: any = {};
  private parameterHeaders = [
    { text: "Parameter", value: "name", sortable: true },
    { text: "Wert", value: "value", sortable: true },
    {
      text: "",
      value: "actions",
      sortable: false,
      align: "right",
      width: "80px"
    }
  ];

  private connection = new AxiosGraphQLConnection(
    new AxiosLogger(new ConsoleLogger())
  );
  private plantService = new PlantGraphQLService(this.connection);

  private retry(event: any) {
    this.components = [];
    this.$emit("retry", event);
  }

  private clearComponents() {
    this.components = [];
  }

  private addParametersButtonClicked() {
    this.components.push({ component: "CreateParameter" });
  }

  private editParametersButtonClicked(parameter: PlantParameter) {
    this.components.push({
      component: "CreateParameter",
      parameter: {
        parameterId: parameter.id,
        value: parameter.value
      }
    });
  }

  private showDeleteDialog(parameter: PlantParameter) {
    this.toDeleteParameter = parameter;
    this.isDeleteDialogShown = true;
  }

  private deleteParameterButtonClicked(parameter: PlantParameter) {
    this.loading = true;
    const request = this.plantService.removePlantParameter(this.plant, {
      parameterId: parameter.id,
      value: parameter.value
    });
    FormRequestHandler.handle(
      request,
      response => {
        this.isDeleteDialogShown = false;
        if (!response.loading && !response.error) {
          this.toDeleteParameter = {};
          this.retry(parameter);
          this.loading = false;
        }
      },
      "update-plant-failed"
    );
  }

  private parameterAdded(event: any) {
    this.retry(event);
  }

  private getParameterNameById(parameterId: string) {
    return this.getTableValue(parameterId, SystemKeys.parametersNameKey);
  }

  private getParameterUnitById(parameterId: string) {
    return this.getTableValue(parameterId, SystemKeys.parametersUnitKey);
  }

  private getTableValue(parameterId: string, parameterNameKey: string) {
    if (this.storage.has(SystemKeys.parametersTableKey)) {
      const entries = JSON.parse(
        this.storage.get(SystemKeys.parametersTableKey)
      );
      const index = entries.findIndex((el: any) => el.id === parameterId);

      if (index >= 0) {
        return entries[index].values[parameterNameKey];
      }
    }
  }

  private canEdit() {
    return Auth.hasPermission(StorageKeys.editPlantAndOperatorPermission);
  }
}
