import {
  required,
  email,
  max,
  required_if,
  min_value
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "Das Feld ist erforderlich!"
});

extend("max", {
  ...max,
  message: "Das Feld darf maximal {length} Zeichen haben!"
});

extend("email", {
  ...email,
  message: "Das Feld muss eine valide E-Mail-Adresse sein!"
});

extend("numeric", {
  validate: value => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false
      };
    }

    return {
      valid: /^-?\d+([.,]\d+)?$/.test(value)
    };
  },
  message: "Das Feld darf nur Zahlen aufweisen!"
});

extend("required_if", {
  ...required_if,
  message: "Das Feld ist erforderlich!"
});

extend("min_value", {
  ...min_value,
  message: (_field, params) =>
    `Der Wert muss größer als ${params.min - 1} sein!`
});

extend("time_required", {
  getMessage: () => "Zeit benötigt!",
  validate: value => {
    if (!value) return false;
    if (typeof value !== "string") return false;

    const splittedTime = value.split(":");

    if (splittedTime.length < 2) return false;

    const allZero = splittedTime
      .map(t => parseInt(t, 10))
      .every(t => t === 0 || isNaN(t));

    if (allZero) return false;

    return true;
  }
});
