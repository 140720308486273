












































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import { OperatorGraphQLService } from "@/plant/services/OperatorGraphQLService";
import { OperatorDataViewModel } from "@/plant/vms/OperatorDataViewModel";
import { OperatorDataPresenter } from "@/plant/presenters/OperatorDataPresenter";
import { OperatorDataController } from "@/plant/controllers/OperatorDataController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";

@Component<OperatorDataCard>({
  components: {
    Card,
    Container,
    Column,
    Row
  },
  props: {
    operatorId: {
      type: String,
      required: true
    }
  },
  watch: {
    "vm.operator"(operator: any) {
      this.emitLoad(operator);
    }
  }
})
export default class OperatorDataCard extends Vue {
  private vm = new OperatorDataViewModel();

  private get controller(): OperatorDataController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new OperatorDataController(
      new OperatorDataPresenter(this.vm),
      new OperatorGraphQLService(connection)
    );
  }

  private mounted() {
    this.controller.mounted(this.$props.operatorId);
  }

  private emitLoad(operator: any) {
    this.$emit("load", operator);
  }
}
