import { Form, FormFieldType } from "@/forms/Form";

export class AddressForm extends Form {
  protected definition = {
    addressRemarks: { required: false, type: FormFieldType.Text },
    street: { required: true, type: FormFieldType.Text },
    zip: { required: true, type: FormFieldType.Text },
    city: { required: true, type: FormFieldType.Text },
    country: { required: true, type: FormFieldType.Selection },
    state: { required: true, type: FormFieldType.Selection }
  };
}
