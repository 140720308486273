import { IInitPresenter } from "../controllers/InitController";
import { InitViewModel } from "../vms/InitViewModel";
import { FormResponse } from "@/forms/FormResponse";
import {
  SettingsTableList,
  SettingsList
} from "@/system/services/SystemTableService";
import StorageKeys from "../utils/StorageKeys";
import { ArrayUtils } from "@/utils/ArrayUtils";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";

export class InitPresenter<TViewModel extends InitViewModel>
  implements IInitPresenter {
  public constructor(
    protected vm: TViewModel,
    protected storage: SimpleStringStorage
  ) {}

  public set loadSettingsTables(response: FormResponse<SettingsTableList>) {
    this.vm.loadPageRequest = response;

    if (response.success) {
      const settingsAsString = JSON.stringify(response.data);
      this.storage.remove(StorageKeys.settingsTables);
      this.storage.set(StorageKeys.settingsTables, settingsAsString);
      this.clearSettings(response.data);
    }
  }

  public set loadSettings(response: FormResponse<SettingsList>) {
    this.vm.loadPageRequest = response;

    if (response.success) {
      const settingsMap = ArrayUtils.partition(
        response.data,
        item => item.settingsTableName
      );
      settingsMap.forEach((settings, key) => {
        settings.forEach(
          setting => (setting.values = JSON.parse(setting.values))
        );
        this.storage.set(key, JSON.stringify(settings));
      });
    }
  }

  private clearSettings(settings: SettingsTableList) {
    settings.forEach(setting => this.storage.remove(setting.labelSingular));
  }
}
