import { PaginatedList } from "@/datastructures/PaginatedList";
import { FormResponse } from "@/forms/FormResponse";
import { ISupplierPresenter } from "../controllers/SupplierController";
import { SupplierViewModel } from "../vms/SupplierViewModel";

export class SupplierPresenter implements ISupplierPresenter {
  public mounting = true;

  public constructor(private vm: SupplierViewModel) {}

  public set tableRequest(response: FormResponse<PaginatedList<any>>) {
    this.vm.loading = response.loading;

    if (!response.loading && !response.error) {
      this.vm.items = response.data.items;
      this.vm.totalItems = response.data.totalCount;

      this.mounting = false;
    }
  }

  public get pageNumber() {
    return this.vm.pageNumber;
  }

  public set pageNumber(pageNumber: number) {
    this.vm.pageNumber = pageNumber;
  }

  public get itemsPerPage() {
    return this.vm.itemsPerPage;
  }

  public get options() {
    return this.vm.options;
  }

  public set options(options: any) {
    this.vm.options = options;
  }

  public set deleteRequest(response: FormResponse<string>) {
    this.vm.loading = response.loading;
  }

  public get search() {
    return this.vm.search;
  }
}
