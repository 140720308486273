export class ScheduleViewModel {
  public visibleView: boolean = false;
  public showServiceEngineerOverwriteDialog = false;
  public finishedRouteRequests = 5;

  public components: any[] = [];

  public loading = false;

  public error = "";
}
