import { ILayoutPresenter } from "../controllers/LayoutController";
import { LayoutViewModel, MenuItem } from "../vms/LayoutViewModel";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import StorageKeys from "../utils/StorageKeys";
import { Auth } from "../utils/Auth";
import { DateUtils } from "@/utils/DateUtils";
import { BmdPlantExport } from "@/plant/utils/BmdPlantExport";
import { BmdOperatorExport } from "@/plant/utils/BmdOperatorExport";
import { OnlineCheckerFactory } from "../utils/OnlineCheckerFactory";
import { Permission } from "../utils/Permissions";
import { StoreMovementsExport } from "@/store/utils/StoreMovementsExport";
import { StoreItemsExport } from "@/store/utils/StoreItemsExport";
import { StockTakingExport } from "@/store/utils/StockTakingExport";
import { EmptyDataSheetExport } from "@/report/utils/EmptyDataSheetExport";

export class LayoutPresenter implements ILayoutPresenter {
  public constructor(
    private vm: LayoutViewModel,
    private storage: SimpleStringStorage
  ) {
    this.initMenuItems();
    OnlineCheckerFactory.create(this, [this.initMenuItems]);
    OnlineCheckerFactory.addCallback(this, this.initMenuItems, false, true);
  }

  public set search(text: string) {
    this.vm.search = text;
  }

  public get showNavigation() {
    return this.vm.showNavigation;
  }
  public set showNavigation(show: boolean) {
    this.vm.showNavigation = show;
  }

  private initMenuItems() {
    this.vm.menuItems = [];

    if (OnlineCheckerFactory.isOnline) {
      this.initDashboardMenuItems();
      this.initPlantsAndOperatorsMenuItems();
      this.initTourPlanningMenuItems();
    }
    this.initReportMenuItems();
    this.initStoreMenuItems();
    if (OnlineCheckerFactory.isOnline) {
      this.initSystemMenuItems();
    }
    this.initLogoutMenuItem();

    this.vm.menuItems = this.cleanUpMenuItems(this.vm.menuItems);
  }

  private initDashboardMenuItems() {
    this.vm.menuItems.push({
      text: "Dashboard",
      icon: "apps",
      routeName: "home"
    });
  }

  private initPlantsAndOperatorsMenuItems() {
    this.vm.menuItems.push({
      text: "Kunden & Anlagen",
      icon: "person",
      open: false,
      items: [
        {
          text: "Alle Kunden und Anlagen",
          routeName: "search"
        },
        {
          text: "Kunde hinzufügen",
          routeName: "operator-create",
          visible: Auth.hasPermission(Permission.EditPlantAndOperator)
        },
        {
          text: "Anlage hinzufügen",
          routeName: "plant-create",
          visible: Auth.hasPermission(Permission.EditPlantAndOperator)
        },
        {
          text: "Indexberechnung",
          routeName: "vpi-calculation",
          visible: Auth.hasPermission(Permission.ShowAndEditPlantPrizes)
        }
      ]
    });
  }

  private initTourPlanningMenuItems() {
    this.vm.menuItems.push({
      text: "Tourenplanung",
      icon: "near_me",
      visible:
        Auth.hasPermission(Permission.ShowTourPlan) ||
        Auth.hasPermission(Permission.ApproveTourPlan) ||
        Auth.hasPermission(Permission.ApproveWorkOrders),
      route: () => {
        if (
          Auth.hasPermission(StorageKeys.editTourPlanPermission) ||
          Auth.hasPermission(StorageKeys.approveTourPlanPermission)
        ) {
          return { routeName: "planning-overview" };
        } else {
          return {
            routeName: "weekly-schedule",
            routeParams: {
              serviceEngineerId: Auth.userId,
              weekDate: DateUtils.toISOString(
                DateUtils.weekMondayFromDate(new Date())
              )
            }
          };
        }
      }
    });
  }

  private initReportMenuItems() {
    const items: MenuItem[] = [
      {
        text: "Arbeitsaufträge",
        routeName: "work-orders",
        visible: Auth.hasPermission(Permission.EditWorkOrders)
      },
      {
        text: "Wochenplan",
        routeName: "service-engineer-weekly-schedule",
        routeParams: { serviceEngineerId: Auth.userId },
        visible: Auth.hasPermission(Permission.EditWorkOrders)
      }
    ];

    if (OnlineCheckerFactory.isOnline) {
      items.push(
        {
          text: "Versandstatus",
          routeName: "work-order-meta",
          visible: Auth.hasPermission(Permission.SendReports)
        },
        { text: "Zeiterfassung", routeName: "time-tracking" },
        {
          text: "BMD-Export",
          visible: Auth.hasPermission(Permission.BMDExport),
          items: [
            {
              text: "BMD-Kunden-Export",
              route: () => {
                BmdOperatorExport.export();
                return {};
              }
            },
            {
              text: "BMD-Anlagen-Export",
              route: () => {
                BmdPlantExport.export();
                return {};
              }
            }
          ]
        },
        {
          text: "Vordrucke",
          items: [
            {
              text: "Datenblatt",
              route: () => {
                EmptyDataSheetExport.export();
                return {};
              }
            }
          ]
        }
      );
    }

    this.vm.menuItems.push({
      text: "Berichte",
      icon: "post_add",
      open: false,
      items
    });
  }

  private initStoreMenuItems() {
    const items: MenuItem[] = [
      {
        text: "Lagerverwaltung",
        open: false,
        items: [
          {
            text: "Übersicht",
            routeName: "store-list"
            // visible: Auth.hasPermission(Permission.EditPlantAndOperator)
          },
          {
            text: "Barcode scannen",
            routeName: "barcode-scanner"
            // visible: Auth.hasPermission(Permission.EditPlantAndOperator)
          },
          {
            text: "Bewegungen",
            routeName: "store-movements"
            // visible: Auth.hasPermission(Permission.EditPlantAndOperator)
          }
        ]
      }
    ];
    if (OnlineCheckerFactory.isOnline) {
      items.push(
        ...[
          {
            text: "Materialanforderung",
            open: false,
            items: [
              {
                text: "Materialanforderung Erstellen",
                routeName: "material-request"
                // visible: Auth.hasPermission(Permission.EditPlantAndOperator)
              },
              {
                text: "Materialanforderungen",
                routeName: "material-request-overview"
                // visible: Auth.hasPermission(Permission.EditPlantAndOperator)
              }
            ]
          },
          {
            text: "Inventur",
            open: false,
            items: [
              {
                text: "Inventur Erstellen",
                routeName: "stock-taking"
                // visible: Auth.hasPermission(Permission.EditPlantAndOperator)
              },
              {
                text: "Übersicht",
                routeName: "stock-taking-overview"
                // visible: Auth.hasPermission(Permission.EditPlantAndOperator)
              }
            ]
          },
          {
            text: "Bestellungen",
            open: false,
            visible: Auth.hasPermission(Permission.EditStoreCoreData),
            items: [
              {
                text: "Bestellung anlegen",
                routeName: "store-suppliers"
                // visible: Auth.hasPermission(Permission.EditPlantAndOperator)
              },
              {
                text: "Bestellverlauf",
                routeName: "supplier-order-overview"
                // visible: Auth.hasPermission(Permission.EditPlantAndOperator)
              }
            ]
          },
          {
            text: "Stammdaten",
            open: false,
            visible: Auth.hasPermission(Permission.EditStoreCoreData),
            items: [
              {
                text: "Artikel",
                routeName: "item-templates"
                // visible: Auth.hasPermission(Permission.EditPlantAndOperator)
              },
              {
                text: "Lagerorte",
                routeName: "stores"
                // visible: Auth.hasPermission(Permission.EditPlantAndOperator)
              },
              {
                text: "Lieferanten",
                routeName: "store-suppliers"
                // visible: Auth.hasPermission(Permission.EditPlantAndOperator)
              }
            ]
          },
          {
            text: "Auswertungen",
            open: false,
            visible: Auth.hasPermission(Permission.ViewStoreEvaulation),
            items: [
              {
                text: "Artikel",
                route: () => {
                  StoreItemsExport.export();
                  return {};
                }
              },
              {
                text: "Lagerbewegungen",
                route: () => {
                  StoreMovementsExport.export();
                  return {};
                }
              },
              {
                text: "Inventuren",
                route: () => {
                  StockTakingExport.export();
                  return {};
                }
              }
            ]
          }
        ]
      );
    }

    this.vm.menuItems.push({
      text: "Lager",
      icon: "store",
      open: false,
      items
    });
  }

  private initSystemMenuItems() {
    const settingsTablesAsString = this.storage.get(StorageKeys.settingsTables);

    if (!settingsTablesAsString) {
      return;
    }

    const tables = JSON.parse(settingsTablesAsString);
    const items = tables.map((t: any) => ({
      text: t.labelPlural,
      routeName: "system-table",
      routeParams: { tableId: t.id },
      visible: Auth.hasSystemPermission(t.id)
    }));

    this.vm.menuItems.push({
      text: "Einstellungen",
      icon: "build",
      open: false,
      items: [
        {
          text: "Mitarbeiter",
          routeName: "employees",
          visible: Auth.hasPermission(Permission.EditEmployees)
        },
        {
          text: "Rollen",
          routeName: "user-roles",
          visible: Auth.hasPermission(Permission.EditRoles)
        },
        {
          text: "Systemtabellen",
          routeName: "system-tables"
        },
        {
          text: "Massenupload",
          routeName: "multi-upload",
          visible: Auth.hasPermission(Permission.EditPlantAndOperator)
        }
      ]
    });
  }

  private initLogoutMenuItem() {
    this.vm.menuItems.push({
      text: "Abmelden",
      icon: "power_settings_new",
      open: false,
      routeName: "logout"
    });
  }

  private cleanUpMenuItems(menuItems?: MenuItem[]): MenuItem[] {
    if (!menuItems) {
      return [];
    }

    menuItems.forEach(
      menuItem => (menuItem.items = this.cleanUpMenuItems(menuItem.items))
    );

    return menuItems.filter(
      menuItem =>
        !!menuItem.route ||
        !!menuItem.routeName ||
        (!!menuItem.items &&
          menuItem.items.some(item => item.visible !== false))
    );
  }
}
