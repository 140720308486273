import { Time, Textfield, Selection } from "@/forms/ViewModelFormTypes";

export class ChangeTourDayPropsDialogViewModel {
  public dialogOpen = false;
  public startTime: Time = {
    label: "Startzeit",
    value: "",
    error: "",
    text: ""
  };
  public startLocationStreet: Textfield = {
    value: "",
    error: "",
    label: "Startort-Straße"
  };
  public startLocationZip: Textfield = {
    value: "",
    error: "",
    label: "Startort-PLZ"
  };
  public startLocationCity: Textfield = {
    value: "",
    error: "",
    label: "Startort-Stadt"
  };
  public startLocationCountry: Selection = {
    selected: "",
    items: [],
    error: "",
    label: "Startort-Land"
  };
  public endLocationStreet: Textfield = {
    value: "",
    error: "",
    label: "Endort-Straße"
  };
  public endLocationZip: Textfield = {
    value: "",
    error: "",
    label: "Endort-PLZ"
  };
  public endLocationCity: Textfield = {
    value: "",
    error: "",
    label: "Endort-Stadt"
  };
  public endLocationCountry: Selection = {
    selected: "",
    items: [],
    error: "",
    label: "Endort-Land"
  };

  public acceptButtonDisabled = true;
}
