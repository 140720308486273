import Vue from "vue";
import Component from "vue-class-component";

@Component<UpdateMixin>({})
export default class UpdateMixin extends Vue {
  public refreshing = false;
  public registration: any = null;
  public updateExists = false;

  public created() {
    // Listen for our custom event from the SW registration
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) {
        return;
      }
      this.refreshing = true;

      // new content clear cache so user gets the new version
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      });
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  }

  public updateAvailable(event: any) {
    this.registration = event.detail;
    this.updateExists = true;
  }

  // Called when the user accepts the update
  public refreshApp() {
    this.updateExists = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) {
      return;
    }
    // send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
  }
}
