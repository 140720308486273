


















































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedTextarea from "@/components/form/vee-validate/ValidatedTextarea.vue";
import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";
import ValidatedCombobox from "@/components/form/vee-validate/ValidatedCombobox.vue";
import ValidatedSwitch from "@/components/form/vee-validate/ValidatedSwitch.vue";

import { OperatorDataTabViewModel } from "@/report/vms/OperatorDataTabViewModel";
import { OperatorDataTabPresenter } from "@/report/presenters/OperatorDataTabPresenter";
import { OperatorDataTabController } from "@/report/controllers/OperatorDataTabController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";

@Component<OperatorDataTab>({
  components: {
    ValidationObserver,

    Card,
    Container,
    Column,
    Row,

    ValidatedTextfield,
    ValidatedTextarea,
    ValidatedSelect,
    ValidatedCombobox,
    ValidatedSwitch
  },
  props: ["parentController", "parentVm", "mini"]
})
export default class OperatorDataTab extends Vue {
  public controller?: OperatorDataTabController;
  private vm = new OperatorDataTabViewModel();

  private initController(): OperatorDataTabController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new OperatorDataTabController(new OperatorDataTabPresenter(this.vm));
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(this.$props.parentVm.workOrder);
  }
}
