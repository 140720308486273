




























import Vue from "vue";
import Component from "vue-class-component";

@Component<YearPicker>({
  watch: {
    menu(value) {
      // tslint:disable-next-line no-unused-expression
      value && this.$nextTick(() => ((this.$refs.picker as any).activePicker = "YEAR"));
    }
  },
  props: {
    minYear: String,
    maxYear: String,
    value: String
  }
})
export default class YearPicker extends Vue {
  private menu = false;
  private modal = false;
  private year = new Date().getFullYear().toString();

  private mounted() {
    if (!!this.$props.value) {
      this.year = this.$props.value;
    }
  }

  private get date() {
    return this.year;
  }

  private set date(newDate: string) {
    this.year = newDate.slice(0, -8);
    this.$emit("input", this.year);
  }
}
