// tslint:disable: member-ordering
export class EventHandler {
  private static events: { [id: number]: Event } = {};

  public static addEvent(
    context: any,
    action: () => void,
    interval: number = 0,
    local: boolean = true
  ): number {
    const boundAction = action.bind(context);
    boundAction();

    if (interval > 0) {
      const handle = setInterval(boundAction, interval) as any;
      this.events[handle] = { name: action.name, local };
      return handle;
    }

    return -1;
  }

  public static removeEvent(handle: number) {
    if (this.events[handle] !== undefined) {
      clearInterval(handle);
      delete this.events[handle];
    }
  }

  public static removeEventsByName(name: string) {
    for (const eventKeyString of Object.keys(this.events)) {
      const eventKey = parseInt(eventKeyString, 10);
      if (this.events[eventKey].name === name) {
        this.removeEvent(eventKey);
      }
    }
  }

  public static removeLocalEvents() {
    for (const eventKeyString of Object.keys(this.events)) {
      const eventKey = parseInt(eventKeyString, 10);
      if (this.events[eventKey].local) {
        this.removeEvent(eventKey);
      }
    }
  }

  public static removeAllEvents() {
    for (const eventKeyString of Object.keys(this.events)) {
      const eventKey = parseInt(eventKeyString, 10);
      this.removeEvent(eventKey);
    }
  }
}

interface Event {
  name: string;
  local: boolean;
}
