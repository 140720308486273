import { GraphQLConnection } from "@/gateways/graphql/GraphQLConnection";

export class GraphQLUserRoleService implements UserRoleService {
  public constructor(private connection: GraphQLConnection) {}

  public async getUserRoleList(): Promise<UserRoleList> {
    const result = await this.connection.query("userRoles", {}, ["id", "name"]);
    return {
      roles: result.data.map((item: any) => ({
        id: item.id,
        name: item.name
      }))
    };
  }

  public async getUserRole(id: string): Promise<UserRole> {
    const result = await this.connection.query("userRole", { id }, [
      "id",
      "name",
      { name: "permissions", fields: ["id", "domain"] }
    ]);
    return {
      id: result.data.id,
      name: result.data.name,
      permissions: result.data.permissions.map((perm: any) => ({
        id: perm.id,
        domain: perm.domain
      }))
    };
  }

  public async createUserRole(role: UserRoleInput): Promise<string> {
    const result = await this.connection.mutation("createUserRole", {
      input: {
        roleName: role.name,
        permissions: role.permissionIds
      }
    });
    return result.data;
  }

  public async updateUserRole(role: UserRoleInput): Promise<string> {
    const result = await this.connection.mutation("updateUserRole", {
      input: {
        roleId: role.id,
        roleName: role.name,
        permissions: role.permissionIds
      }
    });
    return result.data;
  }

  public async deleteUserRole(id: string): Promise<string> {
    const result = await this.connection.mutation(
      "deleteUserRole",
      {
        input: {
          roleId: id
        }
      },
      []
    );
    return result.data;
  }
}

export interface UserRoleService {
  getUserRoleList(): Promise<UserRoleList>;
  getUserRole(id: string): Promise<UserRole>;
  createUserRole(role: UserRoleInput): Promise<string>;
  updateUserRole(role: UserRoleInput): Promise<string>;
  deleteUserRole(id: string): Promise<string>;
}

export interface UserRoleList {
  roles: UserRoleListRow[];
}

export interface UserRoleListRow {
  id: string;
  name: string;
}

export interface UserRole {
  id: string;
  name: string;
  permissions: UserRolePermissionList;
}

type UserRolePermissionList = UserRolePermission[];

interface UserRolePermission {
  id: string;
  domain: string;
}

export interface UserRoleInput {
  id?: string;
  name: string;
  permissionIds: string[];
}
