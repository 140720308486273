var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{attrs:{"title":"Mail-Versand"}},[_c('Container',{attrs:{"fluid":""}},[_c('Row',{attrs:{"wrap":""}},[_c('Column',{attrs:{"xs12":"","xl6":"","pr-2":""}},[_c('validated-combobox',{attrs:{"items":_vm.vm.mailAddress.items,"label":"Empfänger E-Mail-Adresse","multiple":true},on:{"blur":function($event){if ($event.srcElement.value)
              { _vm.vm.mailAddress.selected.push($event.srcElement.value); }}},model:{value:(_vm.vm.mailAddress.selected),callback:function ($$v) {_vm.$set(_vm.vm.mailAddress, "selected", $$v)},expression:"vm.mailAddress.selected"}}),_c('validated-textfield',{attrs:{"label":"Betreff","outlined":""},model:{value:(_vm.vm.subject),callback:function ($$v) {_vm.$set(_vm.vm, "subject", $$v)},expression:"vm.subject"}}),_c('validated-textarea',{attrs:{"label":"Nachricht","outlined":""},model:{value:(_vm.vm.text),callback:function ($$v) {_vm.$set(_vm.vm, "text", $$v)},expression:"vm.text"}}),_c('request-button',{staticClass:"pb-5",attrs:{"block":"","outlined":false,"loading":_vm.vm.requestLoading,"error":_vm.vm.requestError,"text":"Ans Labor senden"},on:{"click":function($event){return _vm.controller.sendReports()}}})],1),_c('Column',{attrs:{"xs12":"","xl6":"","pl-2":""}},[_vm._v(" Prüfberichte von folgenden Arbeitsaufträgen werden angehängt: "),_c('hoverable-datatable',{staticClass:"mt-2",attrs:{"headers":_vm.vm.tableHeaders,"tabletHeaders":_vm.vm.tableHeaders,"mobileHeaders":_vm.vm.tableHeaders,"items":_vm.vm.tableItems,"loading":_vm.vm.tableLoading,"externalPagination":false},scopedSlots:_vm._u([{key:"item.fileSize",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(_vm.controller.parseFileSize(item.fileSize)))]}},{key:"item.plannedDate",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(_vm.controller.parseDate(item.plannedDate)))]}},{key:"item.weekNumber",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(_vm.controller.weekNumber(item.plannedDate)))]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('icon-button',{staticClass:"pr-1",attrs:{"icon":"visibility","tooltipText":"Bericht öffnen"},on:{"click":function($event){return _vm.openPdf(item.reportPdfPath)}}}),_c('icon-button',{staticClass:"pr-1",attrs:{"icon":"visibility","tooltipText":"Arbeitsauftrag öffnen"},on:{"click":function($event){return _vm.openPdf(item.workOrderPdfPath)}}}),_c('icon-button',{staticClass:"pr-1",attrs:{"icon":"delete","tooltipText":"Entfernen"},on:{"click":function($event){return _vm.controller.removeTableItem(item.id)}}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }