






























































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import { DateUtils } from "../../../utils/DateUtils";
import { SalesPartnerStorageHandler } from "../../../storage/storageHandlers/SalesPartnerStorageHandler";
import SystemKeys from "../../../common/utils/SystemKeys";
import { Auth } from "../../../common/utils/Auth";
import StorageKeys from "../../../common/utils/StorageKeys";

@Component<PlantMaintenanceContractData>({
  components: {
    Card,
    Grid,
    Row,
    Column
  },
  props: ["plant", "plantRequest", "element"]
})
export default class PlantMaintenanceContractData extends Vue {
  public plant!: any;
  private retry(event: any) {
    this.$emit("retry", event);
  }

  private addContractButtonClicked() {
    this.$router.push({
      name: "plant-contract",
      params: { plantId: this.$route.params.plantId }
    });
  }

  private formatDate(date: string) {
    return DateUtils.format(date);
  }

  private getName(id: string) {
    return SalesPartnerStorageHandler.getName(
      SystemKeys.salesPartnerTableKey,
      id
    );
  }

  private canEdit() {
    return Auth.hasPermission(StorageKeys.editPlantAndOperatorPermission);
  }
}
