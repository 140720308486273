import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import {
  GraphQLConnection,
  OrderByClause
} from "@/gateways/graphql/GraphQLConnection";

export class StockTakingGraphQLService {
  public constructor(private connection: GraphQLConnection) {}

  public async getAllStockTakingsPaginated(
    page: Page,
    order: OrderByClause[],
    search: string,
    storeFilter: string[]
  ): Promise<PaginatedList<StockTaking>> {
    const request = await this.connection.queryPaginated(
      "stockTakingsPaginated",
      page.itemsPerPage,
      page.page,
      [
        "id",
        "date",
        "storeId",
        {
          name: "store",
          fields: ["id", "name"]
        },
        "employeeId",
        {
          name: "items",
          fields: ["itemTemplateId", "storedAmount", "reportedAmount"]
        }
      ],
      search,
      ["store.name"],
      order,
      {
        storeFilter
      }
    );

    return {
      items: request.data,
      totalCount: request.count
    };
  }

  public async getStockTakingById(id: string) {
    const request = await this.connection.query(
      "stockTaking",
      {
        id
      },
      [
        "id",
        "date",
        "storeId",
        {
          name: "store",
          fields: ["id", "name"]
        },
        "employeeId",
        {
          name: "items",
          fields: ["itemTemplateId", "storedAmount", "reportedAmount"]
        }
      ]
    );

    return request.data;
  }

  public async updateStockTaking(input: any) {
    const request = await this.connection.mutation(
      "updateStockTaking",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async createStockTaking(input: any) {
    const request = await this.connection.mutation(
      "createStockTaking",
      {
        input
      },
      []
    );

    return request.data;
  }

  public async deleteStockTaking(id: string) {
    const request = await this.connection.mutation(
      "deleteStockTaking",
      {
        id
      },
      []
    );

    return request.data;
  }

  public async exportStockTaking() {
    const request = await this.connection.query("exportStockTaking", {}, []);

    return request.data;
  }
}

export interface StockTaking {
  id: string;
  date: string;
  storeId: string;
  employeeId: string;
  items: StockTakingItem[];
}

export interface StockTakingItem {
  itemTemplateId: string;
  reportedAmount: number;
  storedAmount: number;
}
