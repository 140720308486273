import { IOperatorService } from "../services/OperatorGraphQLService";
import { OperatorViewModel } from "../vms/OperatorViewModel";
import { Operator } from "../services/OperatorGraphQLService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";

export class OperatorViewController {
  public constructor(
    private presenter: IOperatorViewPresenter,
    private operatorService: IOperatorService,
    private operatorId: string
  ) {}

  public init() {
    this.getOperatorById();
  }

  public plantLoaded(plantId: string, plant: any) {
    if (plant) {
      this.presenter.setPlantNumber(plantId, plant.index);
    }
  }

  public getOperatorById() {
    const request = this.operatorService.getOperatorById(this.operatorId);
    FormRequestHandler.handle(
      request,
      response => {
        this.presenter.getOperatorResponse = response;
      },
      "get-operator-failed"
    );
  }
}

export interface IOperatorViewPresenter {
  operator: Operator;
  getOperatorResponse: FormResponse<Operator>;

  setPlantNumber(plantId: string, plantNumber: number): void;
}
