import { AddressForm } from "@/forms/common/AddressForm";
import { PlantDataForm } from "@/forms/plant/PlantDataForm";
import { PlantOtherForm } from "@/forms/plant/PlantOtherForm";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { IPlantService, Plant } from "../services/PlantService";
import { IPresenter } from "@/common/presenters/Presenter";
import { Controller } from "@/common/controllers/Controller";

export class CreatePlantController extends Controller<ICreatePlantPresenter> {
  public constructor(
    presenter: ICreatePlantPresenter,
    private plantServices: IPlantService
  ) {
    super(presenter);
  }

  public async mounted(plantId: string) {
    if (!!plantId) {
      const plant = await this.getPlantById(plantId);
      this.presenter.plant = plant;
    }
    this.presenter.visibleView = true;
  }

  public closeButtonClicked(): void {
    this.presenter.visibleView = false;
  }

  public completeAddressStepButtonClicked(): void {
    this.presenter.addressStepCompleted = true;
  }

  public addressAdditionTextChanged(newText: string): void {
    this.presenter.addressForm.setFieldValue("addressRemarks", newText);
  }

  public streetTextChanged(newText: string): void {
    this.presenter.addressForm.setFieldValue("street", newText);
  }

  public zipTextChanged(newText: string): void {
    this.presenter.addressForm.setFieldValue("zip", newText);
  }

  public cityTextChanged(newText: string): void {
    this.presenter.addressForm.setFieldValue("city", newText);
  }

  public countrySelected(country: string) {
    this.presenter.addressForm.setFieldValue("country", country);
  }

  public stateSelected(state: string) {
    this.presenter.addressForm.setFieldValue("state", state);
  }

  public groupSelected(group: string) {
    this.presenter.plantDataForm.setFieldValue("group", group);
  }

  public typeSelected(type: string) {
    this.presenter.plantDataForm.setFieldValue("type", type);
  }

  public plantSizesChanged(value: string, index: number) {
    this.presenter.plantSizes[index].value = value;
  }

  public maintenanceIntervalTextChanged(text: string) {
    this.presenter.plantDataForm.setFieldValue("maintenanceInterval", text);
  }

  public sampleNeededChanged(value: boolean) {
    this.presenter.plantDataForm.setFieldValue(
      "sampleNeeded",
      !!value ? "true" : "false"
    );
  }

  public firstDateChanged(date: string) {
    this.presenter.plantDataForm.setFieldValue("firstDate", date);
  }

  public closePlantDataStepButtonClicked() {
    this.presenter.addressStepCompleted = false;
  }

  public completePlantDataStepButtonClicked(): void {
    this.presenter.plantDataStepCompleted = true;
  }

  public districtAuthorityChanged(text: string) {
    this.presenter.otherForm.setFieldValue("districtAuthority", text);
  }

  public daNumberChanged(text: string) {
    this.presenter.otherForm.setFieldValue("daNumber", text);
  }

  public remarkChanged(text: string) {
    this.presenter.otherForm.setFieldValue("remark", text);
  }

  public onOperatorsSelected(operator: any) {
    this.presenter.otherForm.setFieldValue("operators", operator.item.id);
  }

  public onAllOperatorsSelected(operators: any[]) {
    for (const operator of operators) {
      this.presenter.otherForm.setFieldValue("operators", operator.id);
    }
  }

  public closeOtherStepButtonClicked() {
    this.presenter.plantDataStepCompleted = false;
  }

  public createPlantButtonClicked() {
    const request = this.plantServices.createPlant(this.presenter.plant);
    FormRequestHandler.handle(
      request,
      response => (this.presenter.createPlantResponse = response),
      "create-plant-failed"
    );
  }

  public async getPlantById(plantId: string) {
    return await this.plantServices.getPlantById(plantId);
  }
}

export interface ICreatePlantPresenter extends IPresenter {
  visibleView: boolean;
  addressStepCompleted: boolean;
  plantDataStepCompleted: boolean;

  addressForm: AddressForm;

  plantDataForm: PlantDataForm;
  plantSizes: any[];

  otherForm: PlantOtherForm;

  plant: Plant;
  createPlantResponse: FormResponse<string>;
}
