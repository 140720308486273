

























































import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";
import Dialog from "@/components/layout/Dialog.vue";
import Section from "@/components/layout/Section.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import TruncatedText from "@/components/layout/TruncatedText.vue";

import RequestButton from "@/components/basic/RequestButton.vue";
import IconButton from "@/components/basic/IconButton.vue";

import Datatable from "@/components/collection/Datatable.vue";

import TourDataView from "@/tourPlanner/views/TourDataView.vue";

import { EmployeeListController } from "@/employee/controllers/EmployeeListController";
import { EmployeeListPresenter } from "@/employee/presenters/EmployeeListPresenter";
import { EmployeeListViewModel } from "@/employee/vms/EmployeeListViewModel";
import { GraphQLUserRoleService } from "../services/UserRoleService";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { EmployeeGraphQLService } from "../services/EmployeeService";

@Component<EmployeeListView>({
  components: {
    Page,
    Dialog,
    Section,
    Row,
    Column,
    TruncatedText,
    RequestButton,
    IconButton,
    Datatable,
    TourDataView
  },
  watch: {
    "vm.addNewEmployee"(add: boolean) {
      if (add) {
        this.$router.push({ name: "employee" });
      }
    },
    "vm.employeeToEdit"(id: string) {
      this.$router.push({ name: "employee", params: { id } });
    }
  }
})
export default class EmployeeListView extends Vue {
  private vm = new EmployeeListViewModel();
  private controller: any;

  private created() {
    this.controller = this.createController();
  }

  private mounted() {
    this.controller.init();
  }

  private createController(): EmployeeListController {
    return new EmployeeListController(
      new EmployeeListPresenter(this.vm),
      new EmployeeGraphQLService(
        new AxiosGraphQLConnection(new AxiosLogger(new ConsoleLogger()))
      )
    );
  }
}
