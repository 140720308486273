










import Vue from "vue";
import Component from "vue-class-component";

@Component<GoogleMapInfoWindow>({
  props: {
    value: {
      type: Boolean,
      required: true
    }
  }
})
export default class GoogleMapInfoWindow extends Vue {}
