import { FormResponse } from "@/forms/FormResponse";
import {
  EmployeeTableRows,
  EmployeeService
} from "../services/EmployeeService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";

export class EmployeeListController {
  public constructor(
    private presenter: IEmployeeListPresenter,
    private employeeService: EmployeeService
  ) {}

  public init() {
    this.loadEmployees();
  }

  public addNewEmployeeButtonClicked() {
    this.presenter.addNewEmployee = true;
  }

  public editEmployeeButtonClicked(id: string) {
    this.presenter.employeeToEdit = id;
  }

  public editTourDataButtonClicked(employeeId: string) {
    this.presenter.selectedTourDataEmployee = employeeId;
  }

  public tourDataDialogClosed() {
    this.presenter.selectedTourDataEmployee = "";
  }

  private loadEmployees() {
    const request = this.employeeService.getAllEmployees();

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadEmployeesResponse = response),
      "Mitarbeiter konnten nicht geladen werden"
    );
  }
}

export interface IEmployeeListPresenter {
  // Data
  addNewEmployee: boolean;
  employeeToEdit: string;

  // States
  selectedTourDataEmployee: string;

  // Responses
  loadEmployeesResponse: FormResponse<EmployeeTableRows>;
}
