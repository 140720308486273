var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{attrs:{"title":"Mail-Versand an BH"}},[_c('Container',{attrs:{"fluid":""}},[_c('Row',{attrs:{"wrap":""}},[_c('Column',{attrs:{"xs12":"","pr-2":""}},[_c('request-button',{staticClass:"pb-5",attrs:{"block":"","outlined":false,"loading":_vm.vm.requestLoading,"error":_vm.vm.requestError,"text":"Ans BHs senden"},on:{"click":function($event){return _vm.controller.sendReports()}}})],1),_c('Column',{attrs:{"xs12":"","pl-2":""}},[_vm._v(" Prüfberichte von folgenden Arbeitsaufträgen werden angehängt: "),_c('hoverable-datatable',{staticClass:"mt-2",attrs:{"headers":_vm.vm.tableHeaders,"tabletHeaders":_vm.vm.tableHeaders,"mobileHeaders":_vm.vm.tableHeaders,"items":_vm.vm.tableItems,"loading":_vm.vm.tableLoading,"externalPagination":false},scopedSlots:_vm._u([{key:"item.fileSize",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.controller.parseFileSize(item.fileSize)))]}},{key:"item.plannedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.controller.parseDate(item.plannedDate)))]}},{key:"item.weekNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.controller.weekNumber(item.plannedDate)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('icon-button',{staticClass:"pr-1",attrs:{"icon":"visibility","tooltipText":"Bericht öffnen"},on:{"click":function($event){return _vm.openPdf(item.reportPdfPath)}}}),_c('icon-button',{staticClass:"pr-1",attrs:{"icon":"visibility","tooltipText":"Arbeitsauftrag öffnen"},on:{"click":function($event){return _vm.openPdf(item.workOrderPdfPath)}}}),_c('icon-button',{staticClass:"pr-1",attrs:{"icon":"delete","tooltipText":"Entfernen"},on:{"click":function($event){return _vm.controller.removeTableItem(item.id)}}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }