import {
  IAppointmentMapPresenter,
  MapAppointment
} from "../controllers/AppointmentMapController";
import { FormResponse } from "@/forms/FormResponse";
import { AppointmentMapViewModel } from "../vms/AppointmentMapViewModel";
import { Appointment } from "../services/AppointmentGraphQLService";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { AppointmentMapFilterForm } from "../forms/AppointmentMapFilterForm";
import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";
import MarkerClusterer from "@googlemaps/markerclustererplus";

export class AppointmentMapPresenter implements IAppointmentMapPresenter {
  public filterForm: AppointmentMapFilterForm;

  public constructor(private vm: AppointmentMapViewModel) {
    this.initSelections();

    this.filterForm = new AppointmentMapFilterForm(
      this.vm,
      this.filterFormValidated
    );

    this.filterForm.init();
  }

  public set google(google: any) {
    this.vm.google = google;
  }

  public set map(map: any) {
    this.vm.map = map;

    if (!this.vm.oms) {
      this.vm.oms = new OverlappingMarkerSpiderfier(map, {
        markersWontMove: true, // we promise not to move any markers, allowing optimizations
        markersWontHide: true, // we promise not to change visibility of any markers, allowing optimizations
        basicFormatEvents: true, // allow the library to skip calculating advanced formatting information
        keepSpiderfied: true,
        // event: "mouseover",
        legWeight: 3,
        nudgeStackedMarkers: true,
        nudgeRadius: 10,
        spiderfiedShadowColor: "white",
        lineToCenter: true,
        nearbyDistance: 5
      } as any);
    }

    if (!this.vm.clusterer) {
      this.vm.clusterer = new MarkerClusterer(map, [], {
        imagePath:
          "https://cdn.rawgit.com/googlemaps/js-marker-clusterer/gh-pages/images/m",
        maxZoom: 9
      });
    }
  }

  public set getMapAppointmentsResponse(
    response: FormResponse<MapAppointment[]>
  ) {
    if (!response.error && !response.loading) {
      if (this.vm.appointments.length === 0) {
        this.mountAllMarkers(response.data);
      } else {
        this.removeOldMarkers(response.data);
        this.mountOnlyNewMarkers(response.data);
      }
    }
  }

  public get selectedAppointments() {
    return this.vm.selectedAppointments;
  }

  public set selectedAppointments(selectedAppointments: Appointment[]) {
    this.vm.selectedAppointments = selectedAppointments;
  }

  public set center(center: object) {
    this.vm.center = center;
  }

  public get center() {
    return this.vm.center;
  }

  public set centerAddress(centerAddress: string) {
    this.vm.centerAddress = centerAddress;
  }

  public set tourWeekAlreadyStarted(tourWeekAlreadyStarted: boolean) {
    this.vm.tourWeekAlreadyStarted = tourWeekAlreadyStarted;
  }

  public set filterShown(filterShown: boolean) {
    this.vm.filterShown = filterShown;
  }

  public set showMaintenances(isChecked: boolean) {
    this.vm.showMaintenances = isChecked;
  }

  public get showMaintenances() {
    return this.vm.showMaintenances;
  }

  public set showAppointments(isChecked: boolean) {
    this.vm.showAppointments = isChecked;
  }

  public get showAppointments() {
    return this.vm.showAppointments;
  }

  public get startDate() {
    return this.vm.startDate.value;
  }

  public get endDate() {
    return this.vm.endDate.value;
  }

  public get states() {
    return this.vm.states;
  }

  public get selectedTourPlannerId() {
    return this.vm.selectedTourPlannerId;
  }

  public set selectedTourPlannerId(selectedTourPlannerId: string) {
    this.vm.selectedTourPlannerId = selectedTourPlannerId;
  }

  public removeMarker(appointmentId: string): void {
    const marker = this.vm.appointments[appointmentId];

    if (marker) {
      this.vm.oms?.forgetMarker(marker);
    }

    this.vm.clusterer?.removeMarker(marker);
  }

  public clearAllMarkers(): void {
    this.vm.oms?.forgetAllMarkers();
    this.vm.clusterer?.clearMarkers();
    this.vm.appointments = [];
  }

  private initSelections() {
    this.initStates();
  }

  private initStates() {
    const states = [
      { text: "Burgenland", value: "Burgenland" },
      { text: "Wien", value: "Wien" },
      { text: "Tirol", value: "Tirol" },
      { text: "Vorarlberg", value: "Vorarlberg" },
      { text: "Salzburg", value: "Salzburg" },
      { text: "Niederösterreich", value: "Niederösterreich" },
      { text: "Oberösterreich", value: "Oberösterreich" },
      { text: "Kärnten", value: "Kärnten" },
      { text: "Steiermark", value: "Steiermark" }
    ];
    this.vm.states.items.push(...states);
  }

  private filterFormValidated(context: any, valid: boolean) {
    // Validate
  }

  private mountAllMarkers(appointments: MapAppointment[]) {
    const markers = appointments.map(app => {
      const marker = new google.maps.Marker(app);
      marker.addListener("click", () => {
        this.vm.infoWindows.push(marker);
        google.maps.event.clearListeners(marker, "click");
      });
      this.vm.oms?.addMarker(marker, () => {
        if (this.vm.oms !== null) {
          this.vm.oms?.unspiderfy();
        }
      });
      return marker;
    });

    this.vm.clusterer?.addMarkers(markers);

    this.vm.appointments = markers;
  }

  private removeOldMarkers(appointments: MapAppointment[]) {
    const toRemoveKeys = Object.keys(this.vm.appointments).filter(
      key => appointments.findIndex(app => app.id === key) < 0
    );

    const toRemoveValues = [];
    for (const key of toRemoveKeys) {
      const marker = this.vm.appointments[key];
      toRemoveValues.push(marker);
      this.vm.oms?.forgetMarker(marker);
      delete this.vm.appointments[key];
    }

    // this call is much faster than removing every single marker
    this.vm.clusterer?.removeMarkers(toRemoveValues);
  }

  private mountOnlyNewMarkers(appointments: MapAppointment[]) {
    for (const appointment of appointments) {
      if (!this.vm.appointments[appointment.id]) {
        const marker = new google.maps.Marker(appointment);
        marker.addListener("click", () => {
          this.vm.infoWindows.push(marker);
          google.maps.event.clearListeners(marker, "click");
        });
        this.vm.oms?.addMarker(marker, () => {
          if (this.vm.oms !== null) {
            this.vm.oms?.unspiderfy();
          }
        });
        this.vm.clusterer?.addMarker(marker);
        this.vm.appointments[appointment.id] = marker;
      }
    }
  }
}
