var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white py-3 mb-4",style:({
    borderRadius: _vm.totalBorderRadius,
    border: 'var(--v-secondary-base) 1px solid !important'
  })},[_c('div',{staticClass:"py-2 px-4",style:({
      borderRadius: _vm.topBorderRadius,
      height: _vm.small ? '30px' : '52px'
    })},[_c('Row',{style:({ marginLeft: _vm.margin, marginRight: _vm.margin }),attrs:{"align-center":""}},[_c('Column',{attrs:{"shrink":""}},[_c('v-icon',{style:({ color: 'black' })},[_vm._v(_vm._s(_vm.icon))])],1),(!!_vm.title)?_c('Column',{attrs:{"grow":""}},[_c('h3',{staticClass:"text-uppercase",class:{
            'font-weight-bold': !_vm.small,
            'subtitle-1': !_vm.small,
            'body-2': _vm.small,
            'py-1': !_vm.small
          }},[_vm._v(_vm._s(_vm.title))])]):_vm._e(),_c('Column',{attrs:{"grow":""}},[_vm._t("title")],2),_c('Column',{attrs:{"shrink":"","pa-0":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.show ? "keyboard_arrow_up" : "keyboard_arrow_down")+" ")])],1)],1)],1)],1),_c('div',{class:'white pr-4 ' + (_vm.show ? 'pb-4' : ''),style:({
      borderRadius: _vm.bottomBorderRadius,
      paddingLeft: _vm.aligned ? '25px !important' : ''
    })},[_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_vm._t("default")],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }