



















import Vue from "vue";
import Component from "vue-class-component";

import Container from "@/components/layout/Container.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import { TimerStorageHandler } from "@/storage/storageHandlers/TimerStorageHandler";
import { DateUtils } from "@/utils/DateUtils";

@Component<Timer>({
  props: {
    workOrderId: String,
    iconColor: {
      type: String,
      default: "primary"
    },
    showText: {
      type: Boolean,
      default: true
    },
    textSize: {
      type: String,
      default: "1rem"
    }
  },
  components: {
    Container,
    Row,
    Column
  }
})
export default class Timer extends Vue {
  public started = false;
  public timer?: NodeJS.Timeout;
  public start = 0;
  public text = "00:00:00";

  private mounted() {
    this.started = !!TimerStorageHandler.getValue(this.$props.workOrderId);
    if (this.started) {
      this.start = TimerStorageHandler.getValue(this.$props.workOrderId);
      this.timer = setInterval(this.updateText, 1000);
    }
  }

  private changeStarted() {
    if (!this.started) {
      TimerStorageHandler.start(this.$props.workOrderId);
      this.timer = setInterval(this.updateText, 1000);
      this.start = Date.now();
    } else {
      this.$emit("stop", Date.now() - this.start);
      TimerStorageHandler.stop(this.$props.workOrderId);
      this.start = 0;
      if (this.timer) {
        clearInterval(this.timer);
      }
    }

    this.started = !this.started;
  }

  private updateText() {
    this.text = DateUtils.timeToStringWSeconds(
      (Date.now() - this.start) / 1000
    );
  }
}
