


































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import Page from "@/components/layout/Page.vue";
import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";
import ValidatedCombobox from "@/components/form/vee-validate/ValidatedCombobox.vue";
import DatePicker from "@/components/basic/DatePicker.vue";
import TimePicker from "@/components/basic/TimePicker.vue";
import SimpleCheckbox from "@/components/form/SimpleCheckbox.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

import { CountriesStorageHandler } from "../../storage/storageHandlers/CountriesStorageHandler";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { LocalStorage } from "@/storage/LocalStorage";

import { CreateLooseAppointmentController } from "@/tourPlanner/controllers/CreateLooseAppointmentController";
import { CreateLooseAppointmentPresenter } from "@/tourPlanner/presenters/CreateLooseAppointmentPresenter";
import { AppointmentGraphQLService } from "@/tourPlanner/services/AppointmentGraphQLService";
import { CreateLooseAppointmentViewModel } from "@/tourPlanner/vms/CreateLooseAppointmentViewModel";

@Component<CreateLooseAppointmentView>({
  components: {
    ValidationObserver,
    Container,
    Column,
    Row,
    Page,
    Card,
    DatePicker,
    TimePicker,
    SimpleCheckbox,
    RequestButton,
    ValidatedTextfield,
    ValidatedSelect,
    ValidatedCombobox
  },
  watch: {
    "vm.goBack"() {
      this.vm.visibleView = false;
      this.$emit("closed");
      this.$destroy();
    },
    "vm.createdAppointment"() {
      this.vm.visibleView = false;
      this.$emit("finished");
      this.$destroy();
    }
  },
  props: ["plant"]
})
export default class CreateLooseAppointmentView extends Vue {
  protected step = 1;
  protected vm = new CreateLooseAppointmentViewModel();
  protected plant!: any;

  protected get hasPlant() {
    return this.plant && this.plant.index;
  }

  protected get controller(): CreateLooseAppointmentController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreateLooseAppointmentController(
      new CreateLooseAppointmentPresenter(
        this.vm,
        new LocalStorage(),
        this.$props.plant
      ),
      new AppointmentGraphQLService(connection)
    );
  }

  public mounted() {
    this.controller.mounted();
  }

  protected parseCountry(countryId: string) {
    return CountriesStorageHandler.getCountryNameFromId(countryId);
  }
}
