import {
  SystemTableEntryViewModel,
  EntryFields
} from "../vms/SystemTableEntryViewModel";
import { ISystemTableEntryPresenter } from "../controllers/SystemTableEntryController";
import { FormResponse } from "@/forms/FormResponse";
import {
  SystemTableStructure,
  SystemTableEntry
} from "../services/SystemTableService";
import { DynamicForm } from "@/forms/DynamicForm";
import { FormDefinition, FormFieldType } from "@/forms/Form";
import { ArrayUtils } from "@/utils/ArrayUtils";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { SystemTableUtils } from "../services/SystemTableUtils";
import { SystemKeyChain } from "../types/SystemKeyChain";
import { StringUtils } from "@/utils/StringUtils";
import { ObjectUtils } from "@/utils/ObjectUtils";

interface NestedForm {
  form: DynamicForm;
  subForms: NestedForms;
}
interface NestedForms {
  [key: string]: NestedForm[];
}

export class SystemTableEntryPresenter implements ISystemTableEntryPresenter {
  private systemTableLabel: string = "";
  private fieldStructure: any = {};
  private forms: NestedForm;

  public constructor(
    private vm: SystemTableEntryViewModel,
    private systemTableUtils: SystemTableUtils
  ) {
    this.forms = {
      form: new DynamicForm(
        {},
        this.vm.fields,
        this.formValidated,
        undefined,
        this.vm
      ),
      subForms: {}
    };

    this.initStaticTexts();
    this.updateDynamicTexts();
  }

  public get form() {
    return this.forms.form;
  }

  public set entryId(id: string) {
    this.vm.entryId = id;
  }
  public get entryId(): string {
    return this.vm.entryId;
  }

  public set tableId(id: string) {
    this.vm.tableId = id;
  }
  public get tableId(): string {
    return this.vm.tableId;
  }

  public get entry(): SystemTableEntry {
    return {
      id: this.entryId,
      metumId: this.tableId,
      values: JSON.stringify(this.buildEntry(this.vm.fields))
    };
  }

  public set loadTableStructureResponse(
    response: FormResponse<SystemTableStructure>
  ) {
    this.vm.loadPageRequest = response;

    if (response.success) {
      this.systemTableLabel = response.data.labelSingular;
      this.fieldStructure = JSON.parse(response.data.fields);

      this.vm.fields = this.buildFieldList(this.fieldStructure);
      this.forms.form = this.buildForm(
        this.fieldStructure,
        this.vm.fields,
        this.formValidated
      );
    }

    this.updateDynamicTexts();
  }

  public set loadEntryResponse(response: FormResponse<SystemTableEntry>) {
    this.vm.loadPageRequest = response;

    if (response.success) {
      const values = JSON.parse(response.data.values);
      this.readEntry(values, this.forms, []);
      this.vm.deleteEntryButtonVisible = true;
      this.form.validateForm();
    }

    this.updateDynamicTexts();
  }

  public set saveEntryResponse(response: FormResponse<string>) {
    this.vm.saveEntryRequest = response;

    if (response.success) {
      this.vm.savedEntry = response.data;
    }
  }

  public set deleteEntryResponse(response: FormResponse<string>) {
    this.vm.deleteEntryRequest = response;

    if (response.success) {
      this.vm.deletedEntry = response.data;
    }
  }

  public setFieldValue(keyChain: SystemKeyChain, value: string) {
    const lastKey = keyChain[keyChain.length - 1];
    const nestedForm = this.getForm(keyChain);
    nestedForm.form.setFieldValue(lastKey, value);
    this.form.validateForm();
  }

  public addListLine(keyChain: SystemKeyChain) {
    const lastKey = keyChain[keyChain.length - 1];
    const fieldStructure = this.getSubFieldStructure(keyChain);
    const firstFieldStructureKey = Object.keys(fieldStructure)[0];
    const list = this.getField(keyChain).fields;
    const fields = this.buildFieldList(fieldStructure);
    list.push(fields);

    const parentForm = this.getForm(keyChain);
    const subForm = {
      form: this.buildForm(
        fieldStructure,
        fields,
        this.subFormValidated,
        parentForm.form
      ),
      subForms: []
    };

    if (!parentForm.subForms[lastKey]) {
      parentForm.subForms[lastKey] = [];
    }

    const newLength = parentForm.subForms[lastKey].push(subForm);

    const chainCopy = this.copyChainAndAddKey(keyChain, [
      newLength - 1,
      firstFieldStructureKey
    ]);

    this.setFieldValue(chainCopy, "Neue " + lastKey);
    this.form.validateForm();
  }

  public removeListLine(keyChain: SystemKeyChain, index: number) {
    const lastKey = keyChain[keyChain.length - 1];
    const parentForm = this.getForm(keyChain);
    const list = this.getField(keyChain).fields;
    list.splice(index, 1);
    parentForm.form.removeSubForm(parentForm.subForms[lastKey][index].form);
    parentForm.subForms[lastKey].splice(index, 1);
    this.form.validateForm();
  }

  public reorderListLine(keyChain: SystemKeyChain, movementEvent: any) {
    const keyField = this.getField(keyChain);
    if (keyField) {
      const list = keyField.fields;
      const el = list.splice(movementEvent.moved.oldIndex, 1)[0];
      list.splice(movementEvent.moved.newIndex, 0, el);
      this.form.validateForm();
    }
  }

  private buildFieldList(fieldList: any) {
    const fields: EntryFields = {};

    for (const field of Object.entries(fieldList)) {
      const [name, data]: [string, any] = field;
      let selectionItems: SelectionItem[] = [];

      if (data.type === "selection") {
        selectionItems = this.generateSelectionItems(data.items);
      }

      const defaultValue = this.getDefaultValueForType(data.type);

      fields[name] = {
        name,
        label: name,
        type: data.type,
        info: data.info,
        selected: "",
        value: defaultValue,
        error: "",
        items: selectionItems,
        loading: false,
        fields: []
      };
    }

    return fields;
  }

  private generateSelectionItems(items: string | string[]): SelectionItem[] {
    if (StringUtils.isString(items)) {
      const systemEntries = this.systemTableUtils.loadSystemEntries(items);
      const firstValueKey = this.systemTableUtils.findFirstValueKey(items);

      if (!!firstValueKey) {
        return systemEntries.map((entry: any) => ({
          text: entry.values[firstValueKey],
          value: entry.id
        }));
      }
    } else if (ArrayUtils.isArray(items)) {
      return items.map(item => ({
        text: item,
        value: item
      }));
    }

    return [];
  }

  private buildForm(
    fieldData: any,
    fieldContext: any,
    validatedCallback: (context: any, valid: boolean) => void,
    parentForm?: DynamicForm
  ) {
    const newForm = new DynamicForm(
      this.buildFormDefinition(fieldData),
      fieldContext,
      validatedCallback,
      parentForm,
      this.vm
    );

    if (!!parentForm) {
      parentForm.addSubForm(newForm);
    }

    newForm.init();

    return newForm;
  }

  private buildFormDefinition(fieldData: any) {
    const formDefinition: FormDefinition = {};
    for (const field of Object.entries(fieldData)) {
      const [name, data]: [string, any] = field;

      if (data.type === "list") {
        continue;
      }

      const fieldType =
        data.type === "selection"
          ? FormFieldType.Selection
          : FormFieldType.Text;

      formDefinition[name] = {
        required: data.required,
        type: fieldType
      };
    }

    return formDefinition;
  }

  private buildEntry(fields: EntryFields): any {
    const values: any = {};

    for (const [key, field] of Object.entries(fields)) {
      const generalField = field as any;
      if (generalField.type === "list") {
        values[key] = generalField.fields.map((subFields: EntryFields) =>
          this.buildEntry(subFields)
        );
      } else if (generalField.type === "selection") {
        values[key] = (field as any).selected;
      } else {
        if (StringUtils.isString(generalField.value)) {
          generalField.value = generalField.value.replace(/"/g, "''");
        }
        values[key] = generalField.value;
      }
    }

    return values;
  }

  private readEntry(values: any, form: NestedForm, keyChain: SystemKeyChain) {
    for (let [key, field] of Object.entries(values)) {
      if (ArrayUtils.isArray(field)) {
        const chainCopy = this.copyChainAndAddKey(keyChain, [key]);

        field.forEach((value, index) => {
          const nestedCopy = this.copyChainAndAddKey(chainCopy, [index]);
          this.addListLine(chainCopy);
          this.readEntry(value, this.getForm(nestedCopy), nestedCopy);
        });
      } else {
        if (StringUtils.isString(field)) {
          // tslint:disable-next-line: quotemark
          field = field.replace(/''/g, '"');
        }
        form.form.setFieldValue(key, field as string);
      }
    }
  }

  private getField(keyChain: SystemKeyChain) {
    let field = this.vm.fields as any;

    for (const key of keyChain) {
      if (StringUtils.isString(key)) {
        field = field[key];
      } else {
        field = field.fields[key];
      }
    }

    return field;
  }

  private getSubFieldStructure(keyChain: SystemKeyChain) {
    let fieldStructure = this.fieldStructure;

    for (const key of this.getChainWithoutIndexes(keyChain)) {
      fieldStructure = fieldStructure[key].fields;
    }

    return fieldStructure;
  }

  private getForm(keyChain: SystemKeyChain) {
    let form = this.forms as any;

    for (let i = 0; i < keyChain.length - 1; i += 2) {
      const fieldName = keyChain[i];
      const index = keyChain[i + 1];
      form = form.subForms[fieldName][index];
    }

    return form;
  }

  private getChainWithoutIndexes(keyChain: SystemKeyChain): string[] {
    return keyChain.filter(key => StringUtils.isString(key)) as string[];
  }

  private copyChainAndAddKey(baseChain: SystemKeyChain, keys: SystemKeyChain) {
    const chainCopy = ObjectUtils.deepCopy(baseChain);
    chainCopy.push(...keys);
    return chainCopy;
  }

  private formValidated(context: any, valid: boolean) {
    context.saveEntryButtonDisabled = !valid;
  }
  private subFormValidated(context: any, valid: boolean) {
    // Something
  }

  private initStaticTexts() {
    this.vm.deleteEntryButtonText = "Löschen";
    this.vm.addLineButtonText = "Zeile hinzufügen";
    this.vm.deleteLineButtonText = "Zeile entfernen";
  }

  private updateDynamicTexts() {
    this.vm.saveEntryButtonText = !!this.vm.entryId ? "Speichern" : "Erstellen";
    this.vm.title =
      this.systemTableLabel +
      (!!this.vm.entryId ? " bearbeiten" : " erstellen");
  }

  private getDefaultValueForType(type: string) {
    switch (type) {
      case "text":
      case "string":
        return "";
      case "number":
        return 0;
      case "boolean":
        return false;
    }

    return "";
  }
}
