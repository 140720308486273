export class StoreItemCardViewModel {
  public itemsPerPage = 25;
  public headers = [
    { text: "Datum", value: "date" },
    { text: "Mitarbeiter", value: "employee.lastname" },
    { text: "Quelle", value: "source.name" },
    { text: "Artikel", value: "itemName" },
    { text: "Menge", value: "amount" },
    { text: "Lieferweg", value: "deliveryWay" }
  ];
  public items: any[] = [];
  public totalItems = 0;
  public options = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: this.itemsPerPage,
    multiSort: true,
    mustSort: false,
    sortBy: ["date"],
    sortDesc: [true]
  };
  public loading = true;
  public pageNumber = 1;
}
