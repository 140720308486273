import { Form, FormFieldType } from "@/forms/Form";

export class ContractForm extends Form {
  protected definition = {
    hasContract: { type: FormFieldType.Checkbox, required: false },

    date: { type: FormFieldType.Date, required: true },
    doneBy: { type: FormFieldType.Selection, required: true },
    maintenanceNumber: { type: FormFieldType.Text, required: false },
    maintenanceCost: { type: FormFieldType.Number, required: true },
    priceSample: { type: FormFieldType.Number, required: false },

    baseIndex: { type: FormFieldType.Selection, required: true },
    startIndex: { type: FormFieldType.Number, required: false },
    currentIndex: { type: FormFieldType.Number, required: false },
    dueDate: {
      type: FormFieldType.Text,
      required: false,
      regex: /^\d{4}$/,
      error: "Bitte vier Ziffern eingeben, die das Jahr markieren"
    },
    firstAppointment: { type: FormFieldType.Date, required: true },
    maintenanceInterval: {
      type: FormFieldType.Number,
      required: true,
      greaterThan: 0
    },

    contractQuitted: { type: FormFieldType.Checkbox, required: false },
    quitDate: { type: FormFieldType.Date, required: false },
    remarks: { type: FormFieldType.Text, required: false }
  };
}
