

































































































import Vue from "vue";
import Component from "vue-class-component";

import { SampleAccordionViewModel } from "@/report/vms/SampleAccordionViewModel";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import Accordion from "@/components/layout/Accordion.vue";

import ValidatedCombobox from "@/components/form/vee-validate/ValidatedCombobox.vue";
import ValidatedTextfield from "@/components/form/vee-validate/ValidatedTextfield.vue";
import ValidatedTextarea from "@/components/form/vee-validate/ValidatedTextarea.vue";
import ValidatedSelect from "@/components/form/vee-validate/ValidatedSelect.vue";
import ValidatedSwitch from "@/components/form/vee-validate/ValidatedSwitch.vue";
import ValidatedTimepicker from "@/components/form/vee-validate/ValidatedTimepicker.vue";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { SampleAccordionController } from "@/report/controllers/SampleAccordionController";
import { SampleAccordionPresenter } from "@/report/presenters/SampleAccordionPresenter";
import { EmployeeGraphQLService } from "@/employee/services/EmployeeService";

@Component<SampleAccordion>({
  components: {
    Container,
    Row,
    Column,

    Accordion,

    ValidatedCombobox,
    ValidatedTextfield,
    ValidatedSelect,
    ValidatedTextarea,
    ValidatedSwitch,
    ValidatedTimepicker
  },
  props: {
    editEnabled: {
      type: Boolean,
      required: true
    },
    sampleData: {
      type: Object
    },
    report: {
      type: Object
    },
    serviceEngineerId: {
      type: String
    },
    serviceEngineerName: {
      type: String
    },
    shown: Boolean
  }
})
export default class SampleAccordion extends Vue {
  public vm = new SampleAccordionViewModel();
  private controller?: SampleAccordionController;

  private initController(): SampleAccordionController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new SampleAccordionController(
      new SampleAccordionPresenter(this.vm),
      new EmployeeGraphQLService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted(
      this.$props.sampleData,
      this.$props.report,
      this.$props.serviceEngineerId,
      this.$props.serviceEngineerName
    );
  }
}
