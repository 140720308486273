import { StorageHandler } from "./StorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import SystemKeys from "@/common/utils/SystemKeys";

export class PartStorageHandler extends StorageHandler {
  public static getAllPlantParts(): SelectionItem[] {
    return super.buildTopLevelSelection(
      SystemKeys.plantPartsTableKey,
      SystemKeys.plantPartsNameKey
    );
  }

  public static getAllPlantInstallationParts(): SelectionItem[] {
    return super.buildTopLevelSelection(
      SystemKeys.plantInstallationPartsTableKey,
      SystemKeys.plantInstallationPartsNameKey
    );
  }

  public static getPlantPartNameById(parameterId: string) {
    return this.getName(SystemKeys.plantPartsTableKey, parameterId);
  }

  public static getPlantInstallationPartNameById(parameterId: string) {
    return this.getName(SystemKeys.plantInstallationPartsTableKey, parameterId);
  }
}
