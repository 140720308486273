import CreatePlantView from "@/plant/views/CreatePlantView.vue";
import PlantView from "@/plant/views/PlantView.vue";
import CreateOperatorView from "@/plant/views/CreateOperatorView.vue";
import OperatorView from "@/plant/views/OperatorView.vue";
import PlantContract from "@/plant/views/PlantContract.vue";
import MultiUpload from "@/plant/views/MultiUpload.vue";
import VpiCalculationView from "@/plant/views/vpiCalculation/VpiCalculationView.vue";
import { Permission } from "@/common/utils/Permissions";

export default [
  {
    path: "/plant/:plantId?",
    name: "plant",
    component: PlantView,
    props: true,
    children: [
      {
        path: "create",
        name: "plant-create",
        component: CreatePlantView
      }
    ]
  },
  {
    path: "/operator/:operatorId?",
    name: "operator",
    component: OperatorView,
    children: [
      {
        path: "create",
        name: "operator-create",
        component: CreateOperatorView
      }
    ]
  },
  {
    path: "/plant/:plantId?/plant-contract",
    name: "plant-contract",
    component: PlantContract,
    meta: {
      permission: Permission.ShowAndEditPlantPrizes
    }
  },
  {
    path: "/multi-upload",
    name: "multi-upload",
    component: MultiUpload
  },
  {
    path: "/plants/vpi/calculation",
    name: "vpi-calculation",
    component: VpiCalculationView
  }
];
