import SystemKeys from "@/common/utils/SystemKeys";
import { StorageHandler } from "./StorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";

export class CountriesStorageHandler extends StorageHandler {
  public static getAllCountries(): SelectionItem[] {
    if (this.storage.has(SystemKeys.countriesTableKey)) {
      const fields = JSON.parse(this.storage.get(SystemKeys.countriesTableKey));

      return fields?.map((field: any) => ({
        text: field[SystemKeys.values][SystemKeys.countriesNameKey],
        value: field[SystemKeys.id]
      }));
    }

    return [];
  }

  public static getStatesFromCountry(countryId: string): SelectionItem[] {
    if (this.storage.has(SystemKeys.countriesTableKey)) {
      const fields = JSON.parse(this.storage.get(SystemKeys.countriesTableKey));

      const foundCountry = fields.find(
        (field: any) => field[SystemKeys.id] === countryId
      );

      if (!!foundCountry) {
        const states =
          foundCountry[SystemKeys.values][SystemKeys.statesTableKey];
        return states?.map((field: any) => ({
          text: field[SystemKeys.statesNameKey],
          value: field[SystemKeys.statesNameKey]
        }));
      }
    }
    return [];
  }

  public static getCountryNameFromId(countryId: string): string {
    if (this.storage.has(SystemKeys.countriesTableKey)) {
      const value = this.storage.findValue(
        SystemKeys.countriesTableKey,
        countryId
      );
      return value ? value.values[SystemKeys.countriesNameKey] : countryId;
    }

    return countryId;
  }

  public static getCountryIdFromName(countryName: string): string {
    const allCountries = this.getAllCountries();

    const country = allCountries.find(c => c.text === countryName);

    return country?.value || countryName;
  }

  public static getAreaCodes(): SelectionItem[] {
    return this.buildTopLevelSelection(
      SystemKeys.countriesTableKey,
      SystemKeys.countriesAreaCodeKey
    );
  }

  public static getAreaCodeById(areaCodeId: string): string {
    if (this.storage.has(SystemKeys.countriesTableKey)) {
      const value = this.storage.findValue(
        SystemKeys.countriesTableKey,
        areaCodeId
      );
      return value ? value.values[SystemKeys.countriesAreaCodeKey] : areaCodeId;
    }

    return areaCodeId;
  }
}
