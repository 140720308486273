














import Vue from "vue";
import Component from "vue-class-component";
import { ValidationProvider } from "vee-validate";

@Component<ValidatedAutocomplete>({
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    value: {
      default() {
        return [];
      }
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  }
})
export default class ValidatedAutocomplete extends Vue {
  private innerValue?: string[];

  private created() {
    if (this.$props.value) {
      this.innerValue = this.$props.value;
    } else {
      this.innerValue = [];
    }
  }
}
