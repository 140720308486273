import { CachedService } from "@/common/services/CachedService";
import { ServiceArgument } from "@/common/services/ServiceFacade";
import { OnlineCheckerFactory } from "@/common/utils/OnlineCheckerFactory";
import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import {
  GraphQLConnection,
  OrderByClause
} from "@/gateways/graphql/GraphQLConnection";
import { Store, StoreGraphQLService } from "./StoreGraphQLService";
import { StoreLocalService } from "./StoreLocalService";

export class StoreService implements IStoreService, CachedService {
  private onlineService: StoreGraphQLService;
  private offlineService: StoreLocalService;
  private service: IStoreService;

  constructor(connection: GraphQLConnection) {
    this.offlineService = new StoreLocalService();
    this.onlineService = new StoreGraphQLService(connection);

    if (OnlineCheckerFactory.isOnline) {
      this.service = this.onlineService;
    } else {
      this.service = this.offlineService;
    }
  }

  public get timestamp() {
    return this.offlineService.timestamp;
  }

  public async getAllStores(): Promise<Store[]> {
    this.updateOnlineState();
    return this.service.getAllStores();
  }

  public async getAllStoresPaginated(
    page: Page,
    order: OrderByClause[],
    search: string
  ): Promise<PaginatedList<Store>> {
    this.updateOnlineState();
    return this.service.getAllStoresPaginated(page, order, search);
  }

  public async getStoreById(id: string): Promise<Store> {
    this.updateOnlineState();
    return this.service.getStoreById(id);
  }

  public async updateStore(input: any): Promise<string> {
    this.updateOnlineState();
    return this.service.updateStore(input);
  }

  public async createStore(input: any): Promise<string> {
    this.updateOnlineState();
    return this.service.createStore(input);
  }

  public async deleteStore(id: string): Promise<string> {
    this.updateOnlineState();
    return this.service.deleteStore(id);
  }

  public async download(arg?: ServiceArgument): Promise<void> {
    let stores: Store[] = [];
    if (!arg || !arg.ids) {
      stores = await this.onlineService.getAllStores();
    } else {
      const promises = [];
      for (const id of arg.ids) {
        promises.push(
          this.onlineService
            .getStoreById(id)
            .then(result => stores.push(result))
        );
      }

      await Promise.all(promises);
    }

    this.offlineService.stores = stores;
  }

  public upload(): void {
    for (const storeFlaggedEntity of this.offlineService.getToSyncStores()) {
      const store = storeFlaggedEntity.entity;
      this.onlineService.updateStore(store);
      storeFlaggedEntity.changed = false;
    }
    this.offlineService.save();
  }

  private updateOnlineState() {
    if (OnlineCheckerFactory.isOnline) {
      this.service = this.onlineService;
    } else {
      this.service = this.offlineService;
    }
  }
}

export interface IStoreService {
  getAllStores(): Promise<Store[]>;
  getAllStoresPaginated(
    page: Page,
    order: OrderByClause[],
    search: string
  ): Promise<PaginatedList<Store>>;
  getStoreById(id: string): Promise<Store>;
  updateStore(input: any): Promise<string>;
  createStore(input: any): Promise<string>;
  deleteStore(id: string): Promise<string>;
}
