
















































import Vue from "vue";
import Component from "vue-class-component";
import { ValidationObserver } from "vee-validate";

import DefectsTable from "@/report/views/workOrderSubViews/DefectsTable.vue";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import { CreateWorkOrderViewModel } from "../../vms/CreateWorkOrderViewModel";

@Component<DefectsTableTab>({
  components: {
    ValidationObserver,

    Row,
    Column,
    Container,
    DefectsTable
  },
  props: ["parentController", "parentVm"],
  watch: {
    "parentVm.defects"(newValue) {
      this.parentVm.openGeneralDataStepButtonDisabled = newValue.length <= 0;
    }
  }
})
export default class DefectsTableTab extends Vue {
  private parentVm!: CreateWorkOrderViewModel;
}
