// tslint:disable: member-ordering
export class ErrorHandler {
  private static errors: string[] = [];

  public static push(error: string) {
    // tslint:disable-next-line: no-console
    console.error(error);
    this.errors.push(error);
  }
}
