import { IOperatorViewPresenter } from "../controllers/OperatorViewController";
import { OperatorViewModel } from "../vms/OperatorViewModel";
import { IOperatorService, Operator } from "../services/OperatorGraphQLService";
import { FormResponse } from "@/forms/FormResponse";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { ObjectUtils } from "@/utils/ObjectUtils";

export class OperatorViewPresenter implements IOperatorViewPresenter {
  public constructor(private vm: OperatorViewModel) {}

  public set getOperatorResponse(response: FormResponse<Operator>) {
    this.vm.pageLoading = response.loading;
    this.vm.pageLoadingError = response.error;

    if (response.success) {
      this.vm.title = "Kunde #" + response.data.index;
      response.data.degrees = this.formatDegress(response.data.degrees);
      this.vm.operator = response.data;

      this.vm.operator.company = this.vm.operator.company.replace(
        "\n",
        "<br />"
      );

      if (!this.vm.operator.firstName && !this.vm.operator.lastName) {
        this.vm.operator.firstName = this.vm.operator.company;
        this.vm.operator.company = "";
      }

      this.vm.operator.addressCountry = CountriesStorageHandler.getCountryNameFromId(
        this.vm.operator.addressCountry
      );

      this.vm.operator.plants.forEach((p: any) => (p.title = "Anlage"));
    }
  }
  public set operator(operator: Operator) {
    this.vm.operator = operator;
  }

  public get operator(): Operator {
    return this.vm.operator;
  }

  public setPlantNumber(plantId: string, plantNumber: number) {
    const plant = this.vm.operator.plants.find((p: any) => p.id === plantId);

    if (plant) {
      plant.title = "Anlage #" + plantNumber.toString();
      this.vm.operator.plants = ObjectUtils.deepCopy(this.vm.operator.plants);
    }
  }

  public formatDegress(degrees: string[]): string[] {
    return degrees.map(
      degree => degree.charAt(0).toUpperCase() + degree.slice(1)
    );
  }
}
