























































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

import OperatorSearch from "@/components/layout/OperatorSearch.vue";

import Select from "@/components/form/Select.vue";
import Combobox from "@/components/form/Combobox.vue";
import DatePicker from "@/components/basic/DatePicker.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

import { SearchViewModel } from "@/common/vms/SearchViewModel";
import { SearchPresenter } from "@/common/presenters/SearchPresenter";
import { SearchController } from "@/common/controllers/SearchController";
import { SearchInteractor } from "@/common/interactors/SearchInteractor";

import { LocalStorage } from "../../storage/LocalStorage";
import { CreatePlantViewModel } from "@/plant/vms/CreatePlantViewModel";
import { CreatePlantPresenter } from "@/plant/presenters/CreatePlantPresenter";
import { CreatePlantController } from "@/plant/controllers/CreatePlantController";
import { PlantInteractor } from "@/secretary/interactors/PlantInteractor";
import { PlantGraphQLService } from "@/plant/services/PlantGraphQLService";
import { Plant } from "../services/PlantService";
import { PlantGraphQLGateway } from "@/gateways/graphql/PlantGraphQLGateway";

import { OperatorGraphQLGateway } from "@/gateways/graphql/OperatorGraphQLGateway";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { OperatorGraphQLService } from "../services/OperatorGraphQLService";

@Component<CreatePlantView>({
  components: {
    Container,
    Column,
    Row,
    DatePicker,
    RequestButton,
    OperatorSearch,
    Select,
    Combobox,
    CreateOperatorView: () => import("@/plant/views/CreateOperatorView.vue")
  },
  props: {
    showOperatorSearch: {
      type: Boolean,
      default() {
        return true;
      }
    },
    plantId: {
      type: String,
      default() {
        return "";
      }
    }
  },
  watch: {
    "vm.goBack"() {
      this.vm.visibleView = false;
      this.$destroy();
      if (this.$router.currentRoute.name === "plant-create") {
        this.$router.back();
      }
    },
    "vm.createdPlant"() {
      this.vm.visibleView = false;
      this.$emit("finished");
      this.$destroy();
      if (this.$router.currentRoute.name === "plant-create") {
        this.$router.push({
          name: "plant",
          params: { plantId: this.vm.createdPlant }
        });
      }
    }
  }
})
export default class CreatePlantView extends Vue {
  private vm = new CreatePlantViewModel();
  private step = 1;
  private searchVm = new SearchViewModel();
  private components: any[] = [];
  private showOperatorSearch!: boolean;
  private plant!: Plant;

  private get searchText() {
    return this.$route.params.searchText;
  }

  private get searchController() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new SearchController(
      new SearchPresenter(this.searchVm),
      new OperatorGraphQLService(connection)
    );
  }

  private get controller(): CreatePlantController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreatePlantController(
      new CreatePlantPresenter(this.vm, new LocalStorage()),
      new PlantGraphQLService(connection)
    );
  }

  private mounted() {
    this.controller.mounted(this.$props.plantId);
    if (this.showOperatorSearch) {
      this.searchController.init(this.searchText);
    }
  }

  private goToOperatorCreate() {
    this.components.push("CreateOperatorView");
  }
}
