import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import {
  SupplierGraphQLService,
  SupplierOrder
} from "../services/SupplierGraphQLService";

export class CreateSupplierOrderController {
  public constructor(
    private presenter: ICreateSupplierOrderPresenter,
    private supplierService: SupplierGraphQLService
  ) {}

  public mounted(supplierId: string, supplierOrderId?: string) {
    this.presenter.readonly = !!supplierOrderId;

    FormRequestHandler.handle(
      this.supplierService.getSupplierWithItems(supplierId),
      response => (this.presenter.getItemTemplatesResponse = response),
      "Konnte Artikel nicht laden!"
    );

    FormRequestHandler.handle(
      this.supplierService.getSupplierById(supplierId),
      response => (this.presenter.getSupplierResponse = response),
      "Konnte Lieferanten nicht laden!"
    );

    this.presenter.supplierId = supplierId;

    if (supplierOrderId) {
      this.presenter.readonly = true;

      FormRequestHandler.handle(
        this.supplierService.getSupplierOrderById(supplierOrderId),
        response => {
          this.presenter.getSupplierOrderResponse = response;

          if (!response.loading && !response.error) {
            this.presenter.visibleView = true;
          }
        },
        "Konnte Bestellung nicht laden!"
      );
    } else {
      this.presenter.visibleView = true;
    }
  }

  public closeOverlay() {
    this.presenter.visibleView = false;
  }

  public getTemplateName(id: string) {
    return this.presenter.itemTemplateDict[id].name;
  }

  public getTemplatePrice(id: string) {
    const itemTemplate = this.presenter.itemTemplateDict[id];
    return `VK: ${itemTemplate.vk}, HK: ${itemTemplate.hk}`;
  }

  public itemTemplateSelected(itemTemplateId: string) {
    this.presenter.itemTemplateSelected(itemTemplateId);
  }

  public createSupplierOrderButtonClicked() {
    FormRequestHandler.handle(
      this.supplierService.createSupplierOrder(this.presenter.data),
      response => (this.presenter.createSupplierOrderResponse = response),
      "Could not create stock taking!"
    );
  }
}

export interface ICreateSupplierOrderPresenter {
  visibleView: boolean;
  readonly: boolean;

  itemTemplateDict: { [key: string]: any };
  supplierId: string;

  getSupplierResponse: FormResponse<any>;
  getSupplierOrderResponse: FormResponse<SupplierOrder>;
  getItemTemplatesResponse: FormResponse<ItemTemplate[]>;

  data: any;
  createSupplierOrderResponse: FormResponse<string>;

  itemTemplateSelected(itemTemplateId: string): void;
}
