import { StorageHandler } from "./StorageHandler";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import SystemKeys from "@/common/utils/SystemKeys";

export class ItemTemplateStorageHandler extends StorageHandler {
  public static getAllItemTemplateGroups(): SelectionItem[] {
    return this.buildTopLevelSelection(
      SystemKeys.itemTemplateGroupTableKey,
      SystemKeys.itemTemplateGroupNameKey
    );
  }

  public static getGroupNameById(id: string): string {
    if (this.storage.has(SystemKeys.itemTemplateGroupTableKey)) {
      const value = this.storage.findValue(
        SystemKeys.itemTemplateGroupTableKey,
        id
      );
      return value ? value.values[SystemKeys.itemTemplateGroupNameKey] : id;
    }

    return id;
  }

  public static getAllItemTemplateTypes(): SelectionItem[] {
    return this.buildTopLevelSelection(
      SystemKeys.itemTemplateTypeTableKey,
      SystemKeys.itemTemplateTypeNameKey
    );
  }

  public static getTypeNameById(id: string): string {
    if (this.storage.has(SystemKeys.itemTemplateTypeTableKey)) {
      const value = this.storage.findValue(
        SystemKeys.itemTemplateTypeTableKey,
        id
      );
      return value ? value.values[SystemKeys.itemTemplateTypeNameKey] : id;
    }

    return id;
  }

  public static getAllItemTemplateUnits(): SelectionItem[] {
    return this.buildTopLevelSelection(
      SystemKeys.itemTemplateUnitTableKey,
      SystemKeys.itemTemplateUnitNameKey
    );
  }

  public static getUnitNameById(id: string): string {
    if (this.storage.has(SystemKeys.itemTemplateUnitTableKey)) {
      const value = this.storage.findValue(
        SystemKeys.itemTemplateUnitTableKey,
        id
      );
      return value ? value.values[SystemKeys.itemTemplateUnitNameKey] : id;
    }

    return id;
  }
}
