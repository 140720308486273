import { FormResponse } from "@/forms/FormResponse";
import {
  ParameterStorageHandler,
  PlantParameter
} from "@/storage/storageHandlers/ParameterStorageHandler";
import { IParametersAccordionPresenter } from "../controllers/ParametersAccordionController";
import { ParametersAccordionViewModel } from "../vms/ParametersAccordionViewModel";

export class ParametersAccordionPresenter
  implements IParametersAccordionPresenter {
  public constructor(private vm: ParametersAccordionViewModel) {}

  public plantParametersResponse(
    response: FormResponse<PlantParameter[]>,
    parametersFromWorkOrder: any
  ) {
    if (!response.loading && !response.error) {
      const parameters = response.data
        .map(p => ParameterStorageHandler.getParameterFromId(p.id, p.value))
        .filter(p => p.toCheck);

      if (parametersFromWorkOrder) {
        this.vm.enabled = parametersFromWorkOrder.enabled;
      }
      for (const param of parameters) {
        let value = "";

        if (parametersFromWorkOrder && parametersFromWorkOrder.parameters) {
          const foundIndex = parametersFromWorkOrder.parameters.findIndex(
            (p: PlantParameter) => p.id === param.id
          );
          if (foundIndex > -1) {
            value = parametersFromWorkOrder.parameters[foundIndex].value;
          }
        }

        this.vm.parameters.push({
          name: param.name ?? "",
          suffix: param.unit ?? "",
          id: param.id,
          value,
          type: param.type ?? "Text",
          selection: param.selection ?? []
        });
      }
    }
  }
}
