







































































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import DeleteDialog from "@/components/basic/DeleteDialog.vue";

import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { LocalStorage } from "../../../storage/LocalStorage";
import SystemKeys from "../../../common/utils/SystemKeys";
import { PlantGraphQLService } from "@/plant/services/PlantGraphQLService";
import { Plant, PlantContactPerson } from "@/plant/services/PlantService";
import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { Auth } from "../../../common/utils/Auth";
import StorageKeys from "../../../common/utils/StorageKeys";

@Component<PlantContactPersonData>({
  components: {
    Card,
    Grid,
    Row,
    Column,
    DeleteDialog,
    CreateContactPerson: () => import("@/plant/views/CreateContactPerson.vue")
  },
  props: ["plant", "plantRequest", "element"]
})
export default class PlantContactPersonData extends Vue {
  private components: any[] = [];
  private storage = new LocalStorage();
  private editMode = false;
  private plant!: Plant;
  private isDeleteDialogShown = false;
  private toDeleteContactPerson: any = {};

  private retry(event: any) {
    this.$emit("retry", event);
    if (this.plant.contactPersons.length === 0) {
      this.editMode = false;
    }
  }

  private clearComponents() {
    this.components = [];
  }

  private addContactPersonsButtonClicked() {
    this.components.push({ component: "CreateContactPerson" });
  }

  private editContactPersonsButtonClicked(contactPerson: PlantContactPerson) {
    this.components.push({ component: "CreateContactPerson", contactPerson });
  }

  private showDeleteDialog(contactPerson: PlantContactPerson) {
    this.toDeleteContactPerson = contactPerson;
    this.isDeleteDialogShown = true;
  }

  private deleteContactPersonButtonClicked(contactPerson: PlantContactPerson) {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    const plantService = new PlantGraphQLService(connection);
    const request = plantService.removePlantContactPerson(
      this.plant,
      contactPerson
    );
    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading && !response.error) {
          this.isDeleteDialogShown = false;
          this.retry(contactPerson);
        }
      },
      "update-plant-failed"
    );
  }

  private personAdded(event: any) {
    this.components = [];
    this.retry(event);
  }

  private findSalutation(id: string) {
    if (!!id) {
      return this.storage.findValue(SystemKeys.salutationsTableKey, id).values[
        SystemKeys.salutationsShortFormKey
      ];
    }
  }

  private findPhoneAreaCode(id: string) {
    if (!!id) {
      return this.storage.findValue(SystemKeys.countriesTableKey, id).values[
        SystemKeys.countriesAreaCodeKey
      ];
    }
  }

  private getPhoneTitle(phoneNumberCount: number) {
    let toReturn = "Telefonnummer";
    if (phoneNumberCount > 1) {
      toReturn += "n";
    }
    return toReturn + ":";
  }

  private canEdit() {
    return Auth.hasPermission(StorageKeys.editPlantAndOperatorPermission);
  }
}
