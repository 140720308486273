import stringifyObject from "stringify-object";
import { Dictionary } from "@/datastructures/Dictionary";

export class ObjectUtils {
  public static stringify(obj: object) {
    return stringifyObject(obj, {
      indent: "  ",
      singleQuotes: false
    });
  }

  public static deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }

  public static isObject(obj: any): obj is Dictionary<any> {
    return typeof obj === "object" && obj !== null;
  }

  public static getFirstObjectKey(obj: Dictionary<any>) {
    return this.getFirstObjectEntry(obj)[0];
  }

  public static getFirstObjectEntry(obj: Dictionary<any>): [string, any] {
    if (!!obj && this.isObject(obj)) {
      const splittedValues = Object.entries(obj);

      if (splittedValues.length > 0) {
        return splittedValues[0];
      }
    }

    return ["", null];
  }
}
