import { OnlineChecker } from "./OnlineChecker";
import isOnline from "is-online";
import store from "../../store";

export class IsOnlineOnlineChecker extends OnlineChecker {
  protected async checkIfOnline() {
    {
      // this.isOnlineChanged = false;
      // this.mIsOnline = false;
      // return;

      let newOnline = this.mIsOnline;
      if (!!navigator && !navigator.onLine) {
        newOnline = false;
        this.setOnline(newOnline);
        return;
      }

      newOnline = await isOnline({ timeout: 5000 });
      this.setOnline(newOnline);
    }
  }

  private setOnline(newOnline: boolean) {
    this.isOnlineChanged = newOnline !== this.mIsOnline;
    this.mIsOnline = newOnline;
    store.state.isOnline = newOnline;
  }
}
