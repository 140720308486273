import axios, { AxiosResponse } from "axios";
import { Address } from "@/entities/Address";

const corsAnywhere = "https://cors-anywhere.herokuapp.com/";

const baseUrlGeocode = "https://maps.googleapis.com/maps/api/geocode/";
const baseUrlDirections = "https://maps.googleapis.com/maps/api/directions";
const key = "AIzaSyDIAY2zKroURMcJT7yQ8aWJDBtK3c4Rmv4";

export class AxiosGoogleMapsConnection {
  private logger: IAxiosLogger;

  constructor(logger: IAxiosLogger) {
    this.logger = logger;
  }

  public requestAddress(
    address: Address | string
  ): Promise<any> {
    let parsedAddress = "";
    if (address instanceof Address) {
      parsedAddress = this.parseAddress(address);
    } else {
      parsedAddress = address;
    }
    const url = `${baseUrlGeocode}json?address=${parsedAddress}&key=${key}`;
    return axios.get(url)
      .then(response => {
        this.logger.logResponse(response);
        return response.data.results[0].geometry.location;
      })
      .catch(reason => {
        this.logger.logError(reason);
        throw new Error(reason);
      });
  }

  public requestCoordinates(coords: any): Promise<string> {
    if (coords.lat !== undefined && coords.lng !== undefined) {
      const url = `${baseUrlGeocode}json?latlng=${coords.lat},${coords.lng}&key=${key}`;
      return axios.get(url)
        .then(response => {
          this.logger.logResponse(response);
          return response.data.results[0].formatted_address;
        })
        .catch(reason => {
          this.logger.logError(reason);
          throw new Error(reason);
        });
    } else {
      return new Promise(() => "");
    }
  }

  private parseAddress(address: Address): string {
    return `${address.street}, ${address.zip} ${address.city}, ${address.country}`;
  }
}

export interface IAxiosLogger {
  logRequest(query: string): void;
  logResponse(response: AxiosResponse<any>): void;
  logError(reason: any): void;
}
