import { Auth } from "@/common/utils/Auth";
import { Permission } from "@/common/utils/Permissions";
import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { SelectionItem } from "@/forms/ViewModelFormTypes";
import { OrderByClause, SortOrder } from "@/gateways/graphql/GraphQLConnection";
import { DateUtils } from "@/utils/DateUtils";
import { ItemTemplate } from "../services/ItemTemplateGraphQLService";
import { ItemTemplateService } from "../services/ItemTemplateService";
import { Store } from "../services/StoreGraphQLService";
import { StoreMovementService } from "../services/StoreMovementService";
import { StoreService } from "../services/StoreService";

export class StoreMovementsController {
  public constructor(
    private presenter: IStoreMovementsPresenter,
    private storeMovementService: StoreMovementService,
    private storeService: StoreService,
    private itemTemplateService: ItemTemplateService
  ) {}

  public mounted(storeId?: string, itemTemplateId?: string) {
    FormRequestHandler.handle(
      this.storeService.getAllStores(),
      response => (this.presenter.getStoresResponse = response),
      "Konnte Lagerorte nicht laden!"
    );

    FormRequestHandler.handle(
      this.itemTemplateService.getAllItemTemplates(),
      response => (this.presenter.getItemTemplatesResponse = response),
      "Konnte Artikel nicht laden!"
    );

    if (storeId) {
      this.presenter.storeFilter.selected = [storeId];
    }

    if (itemTemplateId) {
      this.presenter.itemTemplateFilter.selected = [itemTemplateId];
    }

    this.getMovements();
  }

  public tableSorted(options: any) {
    this.presenter.options = options;

    if (!this.presenter.mounting) {
      this.getMovements();
    }
  }

  public pageChanged(newPageNumber: number) {
    this.presenter.pageNumber = newPageNumber;

    if (!this.presenter.mounting) {
      this.getMovements();
    }
  }

  public parseDirection(direction: string) {
    switch (direction) {
      case "FROM":
        return "AB";
      case "TO":
        return "ZU";
      case "CORRECTION":
        return "KORREKTUR";
      case "TRANSFER":
        return "UMBUCHUNG";
    }
  }

  public parseDate(date: string) {
    return DateUtils.format(date);
  }

  public parseStore(storeMovement: any) {
    return storeMovement.destinationType === "PLANT"
      ? `Anlage Nr. ${storeMovement.plantIndex}`
      : storeMovement.store?.name || "";
  }

  public showDeleteEditButtons(item: any) {
    return (
      Auth.hasPermission(Permission.EditStoreCoreData) ||
      item.employeeId === Auth.userId
    );
  }

  public getMovements() {
    const order: OrderByClause[] = [];
    for (let i = 0; i < this.presenter.options.sortBy.length; i++) {
      order.push({
        field: this.presenter.options.sortBy[i],
        order: this.presenter.options.sortDesc[i]
          ? SortOrder.DESC
          : SortOrder.ASC
      });
    }

    const page = new Page(
      this.presenter.itemsPerPage,
      this.presenter.pageNumber
    );

    FormRequestHandler.handle(
      this.storeMovementService.getStoreMovementsForOverviewPaginated(
        page,
        order,
        this.presenter.search,
        this.presenter.storeFilter.selected,
        this.presenter.itemTemplateFilter.selected
      ),
      response => (this.presenter.tableRequest = response),
      "Konnte Tabelle nicht laden!"
    );
  }

  public deleteStoreMovement(id: string) {
    FormRequestHandler.handle(
      this.storeMovementService.deleteStoreMovement(id),
      response => {
        this.presenter.deleteRequest = response;

        if (!response.error && !response.loading) {
          this.tableSorted(this.presenter.options);
        }
      },
      "Konnte Tabelle nicht laden!"
    );
  }
}

export interface IStoreMovementsPresenter {
  mounting: boolean;
  getStoresResponse: FormResponse<Store[]>;
  getItemTemplatesResponse: FormResponse<ItemTemplate[]>;
  tableRequest: FormResponse<PaginatedList<any>>;
  pageNumber: number;
  itemsPerPage: number;
  options: any;
  search: string;
  storeFilter: {
    selected: string[];
    items: SelectionItem[];
  };
  itemTemplateFilter: {
    selected: string[];
    items: SelectionItem[];
  };

  deleteRequest: FormResponse<string>;
}
