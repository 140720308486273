
































































import Vue from "vue";
import Component from "vue-class-component";

@Component<Select>({
  props: {
    value: {
      type: Object,
      default() {
        return { label: "Label", value: "", error: "", items: [] };
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    prefix: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    },
    extraDense: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    autocomplete: {
      type: Boolean,
      default: false
    }
  }
})
export default class Select extends Vue {}
