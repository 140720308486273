















































































































































































































import Vue from "vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import { setTimeout } from "timers";

export default Vue.extend({
  name: "SideBar",
  components: {
    Row,
    Column,
    Container
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      minimized: false
    };
  },
  computed: {
    isMobile(): boolean {
      return !this.$vuetify.breakpoint.lgAndUp;
    },
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
    mini: {
      get(): boolean {
        return this.minimized && !this.isMobile;
      },
      set(newValue: boolean) {
        this.minimized = newValue;
      }
    }
  },
  methods: {
    toggleMini() {
      this.mini = !this.mini;
      this.items.forEach((item: any) => (item.open = false));
    },
    goToRoute(
      name: string,
      params: any,
      route?: () => { routeName: string; routeParams: any }
    ) {
      if (!!route) {
        const { routeName, routeParams } = route();
        if (!routeName) {
          return;
        }
        name = routeName;
        params = routeParams;
      }
      this.$router.push({ name, params });
    },
    hasSubItems(item: any) {
      return item.items && item.items.length > 0;
    }
  }
});
