











import Vue from "vue";

import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";

export default Vue.extend({
  name: "KeyValue",
  components: {
    Row,
    Column,
    Container
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: String
  }
});
