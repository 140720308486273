












































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

import RequestButton from "@/components/basic/RequestButton.vue";
import { AxiosGraphQLConnection } from "../../../gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "../../../logging/AxiosLogger";
import { ConsoleLogger } from "../../../logging/ConsoleLogger";
import { PlantGraphQLService } from "../../services/PlantGraphQLService";
import { FormRequestHandler } from "../../../forms/FormRequestHandler";

@Component<PlantPrintTemplatesData>({
  components: {
    Card,
    Grid,
    Row,
    Column,
    RequestButton
  },
  props: ["plant", "plantRequest", "element"]
})
export default class PlantPrintTemplatesData extends Vue {
  private connection = new AxiosGraphQLConnection(
    new AxiosLogger(new ConsoleLogger())
  );
  private plantService = new PlantGraphQLService(this.connection);

  private loadingReport = false;
  private loadingWorkOrder = false;
  private loadingFolderFrontPage = false;
  private loadingDataSheet = false;
  private reportError = "";
  private workOrderError = "";
  private folderFrontPageError = "";
  private dataSheetError = "";

  private retry(event: any) {
    this.$emit("retry", event);
  }

  private downloadReportTemplate() {
    this.loadingReport = true;
    this.reportError = "";
    const request = this.plantService.createReportTemplatePdf(
      this.$props.plant.id
    );

    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading) {
          this.loadingReport = false;
          if (!!response.error) {
            this.reportError = response.error;
          } else {
            window.open(response.data, "_blank");
          }
        }
      },
      "create-report-pdf-failed"
    );
  }

  private downloadWorkOrderTemplate() {
    this.loadingWorkOrder = true;
    this.workOrderError = "";
    const request = this.plantService.createWorkOrderTemplatePdf(
      this.$props.plant.id
    );

    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading) {
          this.loadingWorkOrder = false;
          if (!!response.error) {
            this.workOrderError = response.error;
          } else {
            window.open(response.data, "_blank");
          }
        }
      },
      "create-work-order-pdf-failed"
    );
  }

  private downloadFolderFrontPage() {
    this.loadingFolderFrontPage = true;
    this.workOrderError = "";
    const request = this.plantService.createFolderFrontPagePdf(
      this.$props.plant.id
    );

    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading) {
          this.loadingFolderFrontPage = false;
          if (!!response.error) {
            this.folderFrontPageError = response.error;
          } else {
            window.open(response.data, "_blank");
          }
        }
      },
      "create-folder-front-page-failed"
    );
  }

  private downloadDataSheet() {
    this.loadingDataSheet = true;
    this.workOrderError = "";
    const request = this.plantService.createDataSheetPdf(this.$props.plant.id);

    FormRequestHandler.handle(
      request,
      response => {
        if (!response.loading) {
          this.loadingDataSheet = false;
          if (!!response.error) {
            this.dataSheetError = response.error;
          } else {
            window.open(response.data, "_blank");
          }
        }
      },
      "create-data-sheet-failed"
    );
  }
}
