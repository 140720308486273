


import Vue from "vue";
import Component from "vue-class-component";

import { LogoutViewModel } from "@/common/vms/LogoutViewModel";
import { LogoutPresenter } from "@/common/presenters/LogoutPresenter";
import { LogoutController } from "@/common/controllers/LogoutController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { LocalStorage } from "../../storage/LocalStorage";

@Component<LogoutView>({
  watch: {
    "vm.loggedOut"(loggedOut: boolean) {
      if (loggedOut) {
        this.$router.push({ name: "login" });
      }
    }
  }
})
export default class LogoutView extends Vue {
  private vm = new LogoutViewModel();
  private controller?: LogoutController;

  private initController(): LogoutController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new LogoutController(
      new LogoutPresenter(this.vm),
      new LocalStorage()
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }
}
