import {
  Selection,
  Textfield,
  Request,
  MultiSelect,
  Numberfield
} from "@/forms/ViewModelFormTypes";
import { CreateOperatorPhoneNumberViewModel } from "./CreateOperatorPhoneNumberViewModel";

export class CreateOperatorViewModel {
  public visibleView: boolean = false;
  public goBack: boolean = false;
  public createdOperator: string = "";

  public formStep: number = 1;
  public personDataStepCompleted: boolean = false;
  public addressDataStepCompleted: boolean = false;
  public phoneDataStepCompleted: boolean = false;
  public operatorDataStepCompleted: boolean = false;
  public documentsDataStepCompleted: boolean = false;

  public openAddressDataButtonDisabled: boolean = true;
  public openPhoneDataButtonDisabled: boolean = true;
  public openOperatorDataButtonDisabled: boolean = false;
  public openDocumentsDataButtonDisabled: boolean = true;
  public createOperatorButtonDisabled: boolean = true;

  public createOperatorRequest: Request = { loading: false, error: "" };

  public id = "";

  public degrees: MultiSelect = { selected: [], error: "", items: [] };
  public salutation: Selection = { selected: "", error: "", items: [] };
  public firstName: Textfield = { value: "", error: "" };
  public lastName: Textfield = { value: "", error: "" };
  public company: Textfield = { value: "", error: "" };

  public mail: Textfield = { value: "", error: "" };
  public website: Textfield = { value: "", error: "" };
  public uid: Textfield = { value: "", error: "" };
  public dateOfPayment: Numberfield = { value: 0, error: "" };
  public cashBackPercent: Numberfield = { value: 0.0, error: "" };
  public cashBackDays: Numberfield = { value: 0, error: "" };

  public addressRemarks: Textfield = { value: "", error: "" };
  public street: Textfield = { value: "", error: "" };
  public zip: Textfield = { value: "", error: "" };
  public city: Textfield = { value: "", error: "" };
  public country: Selection = { selected: "", error: "", items: [] };
  public state: Selection = { selected: "", error: "", items: [] };

  public standardPhoneNumber: number = 0;
  public phoneNumbers: CreateOperatorPhoneNumberViewModel[] = [];

  public vulgoName: Textfield = { value: "", error: "" };
  public plants: MultiSelect = { selected: [], error: "", items: [] };
}
