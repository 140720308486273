





















































































































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import ConfirmDialog from "@/components/basic/ConfirmDialog.vue";
import IconButton from "@/components/basic/IconButton.vue";
import KeyValue from "@/plant/views/KeyValue.vue";
import Textarea from "@/components/form/Textarea.vue";

import PlantSurroundingMap from "@/plant/views/PlantSurroundingMap.vue";

import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { AxiosGraphQLConnection } from "../../../gateways/graphql/connections/AxiosGraphQLConnection";
import { PlantGraphQLService } from "../../services/PlantGraphQLService";
import { Plant } from "../../services/PlantService";
import { ConsoleLogger } from "../../../logging/ConsoleLogger";
import { AxiosLogger } from "../../../logging/AxiosLogger";
import { PlantGroupStorageHandler } from "../../../storage/storageHandlers/PlantGroupStorageHandler";
import { DistrictAuthorityStorageHandler } from "../../../storage/storageHandlers/DistrictAuthorityStorageHandler";
import { PlantSizeStorageHandler } from "../../../storage/storageHandlers/PlantSizeStorageHandler";
import { DateUtils } from "../../../utils/DateUtils";
import { Auth } from "../../../common/utils/Auth";
import StorageKeys from "../../../common/utils/StorageKeys";

@Component<PlantData>({
  components: {
    Card,
    Grid,
    Row,
    Column,
    ConfirmDialog,
    PlantSurroundingMap,
    IconButton,
    KeyValue,
    Textarea,

    CreatePlantView: () => import("@/plant/views/CreatePlantView.vue")
  },
  props: ["plant", "plantRequest", "element"]
})
export default class PlantData extends Vue {
  private mapEditMode = false;
  private confirmMapEditDialogVisible = false;
  private plant!: Plant;
  private components: any[] = [];
  private changedData: string[] = [];

  private connection = new AxiosGraphQLConnection(
    new AxiosLogger(new ConsoleLogger())
  );
  private plantService = new PlantGraphQLService(this.connection);

  private retry(event: any) {
    this.confirmMapEditDialogVisible = false;
    this.mapEditMode = false;
    this.components = [];
    this.$emit("retry", event);
  }

  private parseCountry(countryId: string): string {
    return CountriesStorageHandler.getCountryNameFromId(countryId);
  }

  private parseGroup(groupId: string): string {
    return PlantGroupStorageHandler.getGroupNameFromId(groupId);
  }

  private parseType(typeId: string): string {
    return PlantGroupStorageHandler.getTypeNameFromId(typeId);
  }

  private parseDa(daId: string): string {
    return DistrictAuthorityStorageHandler.getDistrictAuthorityNameById(daId);
  }

  private parsePlantSizes(sizes: string): any[] {
    if (!sizes) {
      return [];
    }

    const plantSizesRaw = JSON.parse(sizes);
    const plantSizes = [];
    for (const plantSizeRaw of plantSizesRaw) {
      const plantSize = PlantSizeStorageHandler.getPlantSizeFromId(
        plantSizeRaw.id
      );
      plantSize.value = plantSizeRaw.value;
      plantSizes.push(plantSize);
    }

    return plantSizes;
  }

  private parseDate(date: Date | string) {
    return DateUtils.format(date);
  }

  private editPlant() {
    this.components.push("CreatePlantView");
  }

  private changeMapEditMode() {
    this.mapEditMode = !this.mapEditMode;
    if (!this.mapEditMode) {
      this.confirmMapEditDialogVisible = true;
    }
  }

  private locationDragged(event: any) {
    this.plant.addressLat = event.latLng.lat();
    this.plant.addressLng = event.latLng.lng();
    this.changedData.push("Adress-Marker");
  }

  private getChangedData() {
    return this.changedData.length > 0
      ? `(${this.changedData.join(", ")})`
      : "";
  }

  private updatePlant() {
    this.plantService
      .updatePlant(this.plant)
      .then(() => this.retry(this.plant));
  }

  private canEdit() {
    return Auth.hasPermission(StorageKeys.editPlantAndOperatorPermission);
  }

  private parsePlantSizeLabel(plantSize: any) {
    if (!!plantSize) {
      const unit = !!plantSize.unit ? " (" + plantSize.unit + ")" : "";
      return plantSize.name + unit;
    }
    return "";
  }
}
