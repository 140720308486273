



















import Vue from "vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import { setTimeout } from "timers";

export default Vue.extend({
  name: "SideBar",
  components: {
    Row,
    Column,
    Container
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    colorString: {
      type: String,
      default: "primary"
    },
    shown: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 256
    },
    mobileWidth: {
      type: Number,
      default: 256
    }
  },
  data() {
    return {
      minimized: false
    };
  },
  computed: {
    isMobile(): boolean {
      return !this.$vuetify.breakpoint.lgAndUp;
    },
    mini: {
      get(): boolean {
        return this.minimized && !this.isMobile;
      },
      set(newValue: boolean) {
        this.minimized = newValue;
      }
    },
    isShown: {
      get(): boolean {
        return this.$props.shown;
      },
      set(newValue: boolean) {
        this.$emit("shown-changed", newValue);
      }
    }
  },
  methods: {
    toggleMini() {
      this.mini = !this.mini;
      this.items.forEach((item: any) => (item.open = false));
    },
    goToRoute(routeName: string, routeParams: any) {
      this.$router.push({ name: routeName, params: routeParams });
    }
  }
});
