import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import DirectionsRenderer from "../components/google/DirectionsRenderer";
import GoogleMapMarkerWithLabel from "../components/google/GoogleMapMarkerWithLabel";
import GMMWLInfoWindow from "../components/google/GMMWLInfoWindow";

Vue.component("GmapCluster", GmapCluster);
Vue.component("DirectionsRenderer", DirectionsRenderer);
Vue.component("GoogleMapMarkerWithLabel", GoogleMapMarkerWithLabel);
Vue.component("GMMWLInfoWindow", GMMWLInfoWindow);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
    libraries: ["directions", "geometry"]
  }
});
