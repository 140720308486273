import {
  SystemTableService,
  SettingsTableList,
  SettingsList
} from "@/system/services/SystemTableService";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";

export class InitController<TPresenter extends IInitPresenter> {
  public constructor(
    protected presenter: TPresenter,
    protected systemTableService: SystemTableService
  ) {}

  protected loadSettingsTables() {
    const request = this.systemTableService.getAllSettingsTables();

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadSettingsTables = response),
      "Systemtabellen konnten nicht geladen werden"
    );

    return request;
  }

  protected loadSettings() {
    const request = this.systemTableService.getAllSettings();

    FormRequestHandler.handle(
      request,
      response => (this.presenter.loadSettings = response),
      "Systemeinträge konnten nicht geladen werden"
    );

    return request;
  }
}

export interface IInitPresenter {
  loadSettingsTables: FormResponse<SettingsTableList>;
  loadSettings: FormResponse<SettingsList>;
}
