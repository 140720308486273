import {
  IPlantService,
  Plant,
  PlantContactPerson,
  PlantParameter,
  PlantPart,
  PlantContract,
  PlantDocumentPackage
} from "./PlantService";
import { StorageHolder } from "@/storage/StorageHolder";
import { SearchResult } from "@/common/services/SearchService";
import { Plants, VpiCalculation } from "../models/Plants";

export class PlantLocalService implements IPlantService {
  private plantStorage = StorageHolder.PlantStorage;

  public set plants(plants: Plant[]) {
    this.plantStorage.plants = plants;
    this.plantStorage.save();
  }

  public get timestamp() {
    return this.plantStorage.timestamp;
  }

  public save() {
    this.plantStorage.save();
  }

  public getPlantById(id: string): Promise<Plant> {
    return Promise.resolve(this.plantStorage.getPlantById(id));
  }

  public getPlantByIndex(index: number): Promise<Plant> {
    throw new Error("Method not implemented.");
  }

  public getPlantsForVpiCalculation(
    vpi: string,
    thresholdPercent: number,
    targetIndex: number
  ): Promise<Plants> {
    throw new Error("Method not implemented.");
  }

  public createPlant(plant: Plant): Promise<string> {
    throw new Error("Method not implemented.");
  }

  public updatePlant(plant: Plant): Promise<string> {
    this.plantStorage.updatePlant(plant);

    return Promise.resolve(plant.id);
  }

  public addPlantContactPerson(
    plant: Plant,
    contactPerson: PlantContactPerson
  ): Promise<string> {
    throw new Error("Method not implemented.");
  }

  public addPlantParameter(
    plant: Plant,
    parameter: PlantParameter
  ): Promise<string> {
    throw new Error("Method not implemented.");
  }

  public addPlantParameters(
    plant: Plant,
    parameter: PlantParameter[]
  ): Promise<string> {
    throw new Error("Method not implemented.");
  }

  public addPlantPart(plant: Plant, plantPart: PlantPart): Promise<string> {
    throw new Error("Method not implemented.");
  }

  public addPlantInstallationPart(
    plant: Plant,
    plantPart: PlantPart
  ): Promise<string> {
    throw new Error("Method not implemented.");
  }

  public upsertContract(
    plantId: string,
    contract: PlantContract
  ): Promise<string> {
    throw new Error("Method not implemented.");
  }

  public getPlantContractByPlantId(plantId: string): Promise<PlantContract> {
    throw new Error("Method not implemented.");
  }

  public uploadPlantDocument(
    foreignId: string,
    isOperator: boolean,
    document: PlantDocumentPackage
  ): Promise<string> {
    throw new Error("Method not implemented.");
  }

  public exportPlants(): Promise<string> {
    throw new Error("Method not implemented.");
  }

  public findOther(id: string): Promise<SearchResult[]> {
    throw new Error("Method not implemented.");
  }

  public getToSyncPlants() {
    return this.plantStorage.getToSyncPlants();
  }

  public updateContractPrices(
    id: string,
    calculation: VpiCalculation
  ): Promise<string> {
    throw new Error("Method not implemented.");
  }
}
