












import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Grid from "@/components/layout/Grid.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";

@Component<PlantOperatorsData>({
  components: {
    Card,
    Grid,
    Row,
    Column,
  },
  props: ["plant", "plantRequest", "element"]
})
export default class PlantOperatorsData extends Vue {
  private retry(event: any) {
    this.$emit("retry", event);
  }
}
