import { Dictionary } from "@/datastructures/Dictionary";
import { Page } from "@/datastructures/Page";
import { PaginatedList } from "@/datastructures/PaginatedList";
import { FormRequestHandler } from "@/forms/FormRequestHandler";
import { FormResponse } from "@/forms/FormResponse";
import { OrderByClause, SortOrder } from "@/gateways/graphql/GraphQLConnection";
import { ItemTemplateStorageHandler } from "@/storage/storageHandlers/ItemTemplateStorageHandler";
import { ItemTemplateService } from "../services/ItemTemplateService";

export class ItemTemplateController {
  public constructor(
    private presenter: IItemTemplatePresenter,
    private itemTemplateService: ItemTemplateService
  ) {}

  public mounted() {
    const allGroups = ItemTemplateStorageHandler.getAllItemTemplateGroups();
    for (const group of allGroups) {
      this.presenter.groupDictionary[group.value] = group.text;
    }

    const allTypes = ItemTemplateStorageHandler.getAllItemTemplateTypes();
    for (const type of allTypes) {
      this.presenter.typeDictionary[type.value] = type.text;
    }

    this.getItems();
  }

  public tableSorted(options: any) {
    this.presenter.options = options;

    if (!this.presenter.mounting) {
      this.getItems();
    }
  }

  public pageChanged(newPageNumber: number) {
    this.presenter.pageNumber = newPageNumber;

    if (!this.presenter.mounting) {
      this.getItems();
    }
  }

  public deleteItemTemplate(id: string) {
    FormRequestHandler.handle(
      this.itemTemplateService.deleteItemTemplate(id),
      response => {
        this.presenter.deleteRequest = response;

        if (!response.error && !response.loading) {
          this.tableSorted(this.presenter.options);
        }
      },
      "Konnte Tabelle nicht laden!"
    );
  }

  public getItems() {
    const order: OrderByClause[] = [];
    for (let i = 0; i < this.presenter.options.sortBy.length; i++) {
      order.push({
        field: this.presenter.options.sortBy[i],
        order: this.presenter.options.sortDesc[i]
          ? SortOrder.DESC
          : SortOrder.ASC
      });
    }

    const page = new Page(
      this.presenter.itemsPerPage,
      this.presenter.pageNumber
    );

    FormRequestHandler.handle(
      this.itemTemplateService.getAllItemTemplatesPaginated(
        page,
        order,
        this.presenter.search
      ),
      response => (this.presenter.tableRequest = response),
      "Konnte Tabelle nicht laden!"
    );
  }
}

export interface IItemTemplatePresenter {
  mounting: boolean;
  groupDictionary: Dictionary<string>;
  typeDictionary: Dictionary<string>;

  tableRequest: FormResponse<PaginatedList<any>>;
  deleteRequest: FormResponse<string>;
  pageNumber: number;
  itemsPerPage: number;
  options: any;
  search: string;
}
