import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { StoreMovementService } from "../services/StoreMovementService";

export class StoreMovementsExport {
  public static async export() {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    const storeMovementService = new StoreMovementService(connection);
    const downloadLink = await storeMovementService.exportStoreMovements();

    const link = document.createElement("a");
    link.setAttribute("href", downloadLink);
    link.setAttribute("download", "download");
    link.setAttribute("target", "_blank");

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
