









import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";

import { DashboardController } from "@/common/controllers/DashboardController";
import { DashboardPresenter } from "@/common/presenters/DashboardPresenter";
import { DashboardViewModel } from "@/common/vms/DashboardViewModel";
import { AxiosGraphQLConnection } from "../../gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "../../logging/AxiosLogger";
import { ConsoleLogger } from "../../logging/ConsoleLogger";
import { LocalStorage } from "../../storage/LocalStorage";
import { GraphQLSystemTableService } from "../../system/services/SystemTableService";

@Component<DashboardView>({
  components: {
    Page
  },
  watch: {
    "vm.reload"(reload: boolean) {
      if (reload) {
        this.$router.push({ name: "home" });
        location.reload();
      }
    }
  }
})
export default class DashboardView extends Vue {
  private vm = new DashboardViewModel();
  private controller: any;

  private get action() {
    return this.$route.params.action;
  }

  private created() {
    this.controller = this.createController();
  }

  private mounted() {
    this.controller.init(this.action);
  }

  private createController(): DashboardController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new DashboardController(
      new DashboardPresenter(this.vm, new LocalStorage()),
      new GraphQLSystemTableService(connection)
    );
  }
}
