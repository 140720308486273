







import Vue from "vue";
import Component from "vue-class-component";

import Page from "@/components/layout/Page.vue";

import { BarcodeScannerViewModel } from "@/store/vms/BarcodeScannerViewModel";
import { BarcodeScannerPresenter } from "@/store/presenters/BarcodeScannerPresenter";
import { BarcodeScannerController } from "@/store/controllers/BarcodeScannerController";

import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { ItemTemplateService } from "../services/ItemTemplateService";

@Component<BarcodeScannerView>({
  components: {
    Page
  }
})
export default class BarcodeScannerView extends Vue {
  private vm = new BarcodeScannerViewModel();
  private controller?: BarcodeScannerController;

  private initController(): BarcodeScannerController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new BarcodeScannerController(
      new BarcodeScannerPresenter(this.vm, this.$router),
      new ItemTemplateService(connection)
    );
  }

  private created() {
    this.controller = this.initController();
  }

  private mounted() {
    this.controller?.mounted();
  }

  private destroyed() {
    this.controller?.close();
  }
}
